import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from "@mui/material";
import { DatePicker } from "antd";
import { getBusinessHour, liveChatContactsView } from '../../store/actions/tenantAction';
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import dayjs from 'dayjs';
import RightSidePanel from '../../components/RightSidePanel';
import LiveChatAudienceDetails from '../../layouts/tenant/LiveChatAudienceDetails';
import { Dropdown } from 'flowbite-react';
import PopUp from '../../components/PopUp';
const { RangePicker } = DatePicker;

function LiveChatAudience({ showSidebar }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);
    const [showDownloadProcessing, setShowDownloadProcessing] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState({});
    const [chatData, setChatData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchText, setSearchText] = useState("");
    const globalReducer = useSelector((state) => state.globalReducer);
    const [dateFilter, setDateFilter] = useState({
        property: "lastMessageDate",
        operator: "RANGE",
        lowerBound: Date.parse(dayjs().startOf("month").toDate()),
        upperBound: Date.parse(dayjs().add(1, "hour").toDate())
    });
    const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
    const [filter, setFilter] = useState({
        criteria: { tenantId: globalReducer.loginInfo.tenantId },
    });
    const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if (globalReducer.waba?.id) {
            chatDataSearch();
        }
    }, [currentPage, globalReducer.waba]);

    const preparedFilter = () => {
        const fl = [];
        if (searchText) {
            fl.push({ clause: "OPEN_BRACKET" },
                {
                    property: "contact.whatsappNumber",
                    operator: "CONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.email",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.name",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.location",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.category",
                    operator: "IN",
                    value: searchText,
                }, { clause: "CLOSE_BRACKET" }
            );
        }
        if (dateFilter && searchText) {
            filter.filter = [...fl, { clause: "AND" }, dateFilter];
        } else if (searchText) {
            filter.filter = fl;
        } else if (dateFilter) {
            filter.filter = [dateFilter];
        } else {
            filter.filter = [];
        }
    }

    const chatDataSearch = () => {
        preparedFilter();
        dispatch(liveChatContactsView({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId,
            count: true,
            size: itemParPage,
            offset: (currentPage - 1) * itemParPage,
            orderBy: [{ property: "id", direction: "DESC" }]
        }, (d) => {
            setChatData(d?.data ?? []);
            setTotalPages(Math.ceil(d?.count / itemParPage));
        }));
    };

    const onSearch = () => {
        if (currentPage === 1) {
            chatDataSearch();
        } else {
            setCurrentPage(1);
        }
    };

    const selectDateRange = (dates) => {
        if (dates && dates.length > 0) {
            const [start, end] = dates;
            if (start && end) {
                setDateFilter({
                    property: "lastMessageDate",
                    operator: "RANGE",
                    lowerBound: Date.parse(start.toDate()),
                    upperBound: Date.parse(end.add(1, "hour").toDate()),
                });
            } else {
                setDateFilter(null);
            }
        } else {
            setDateFilter(null);
        }
    };

    const exportReport = () => {
        setShowDownloadProcessing(true);
        preparedFilter();
        dispatch(liveChatContactsView({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId,
            count: true,
            size: 1000000,
            offset: 0,
            orderBy: [{ property: "id", direction: "DESC" }]
        }, (d) => {
            dispatch(getBusinessHour({
                tenantId: globalReducer.loginInfo?.tenantId, size: 100000, offset: 0,
                criteria: { active: true }
            }, hours => {
                const data = d?.data?.map(m => {
                    return {
                        name: m?.contact?.name ?? "Anonymous",
                        phone: m?.contact?.whatsappNumber,
                        ID: m.id,
                        LastMessageText: ["image", "location", "reaction", "sticker", "audio", "video"].includes(m.lastMessage) ? "The customer sent a " + m.lastMessage : m.lastMessage,
                        LastMessageReceived: m.lastMessageDate,
                        FirstMessageReceived: m.firstMessageDate,
                        Type: m.messageType,
                        BusinessHours: checkBusinessHours(hours?.data, m.lastMessageDate)
                    }
                })
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(data ?? []);
                XLSX.utils.book_append_sheet(workbook, worksheet, "live_chat");
                XLSX.writeFile(workbook, 'live_chat_report.xlsx');
            }));
        }));
    };

    const checkBusinessHours = (hours, date) => {
        let flag = false;
        if (hours?.some(f => f.availability === "OFFLINE")) {
            flag = false;
        }
        if (hours?.some(f => f.availability === "AVAILABLE_24X7")) {
            flag = true;
        }
        if (hours?.filter(f => f.availability === "HOLIDAY").some(s => moment.utc(date).isBetween(
            moment.utc(s.startDateTime),
            moment.utc(s.endDateTime), null, "[]"))) {
            flag = false;
        }
        if (hours?.filter(f => f.availability === "AVAILABLE_CUSTOM").some(s => moment.utc(date).isBetween(
            moment.utc(s.startDateTime).format("HH:mm"),
            moment.utc(s.endDateTime).format("HH:mm"), null, "[]"))) {
            flag = true;
        }
        return flag;
    }

    return (
        <div className="h-screen">
            <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
                <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <i className="fa-solid fa-file-fragment text-[14px] text-theme"></i>
                        <div className="text-[14px] font-medium">
                            {t("Inbox Data")}
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button type='button' onClick={() => exportReport()} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]">
                            {t('Download as Report')}
                        </button>
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
                    <div className="grid grid-cols-12 gap-4 items-end">
                        <div className={`flex flex-col col-span-3`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t("Enter Contact Details")}</label>
                            </div>
                            <input
                                type="text"
                                placeholder={t("Enter Contact Details")}
                                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`flex flex-col col-span-4`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Date Range')}</label>
                            </div>
                            <RangePicker defaultValue={[dayjs().startOf("month"), dayjs()]}
                                className={"h-10"}
                                onChange={selectDateRange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                        <div className={`flex flex-col w-full col-span-2`}>
                            <button type="button"
                                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                                onClick={onSearch} >
                                {t("Search")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col h-full justify-between grow">
                <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
                    {chatData && chatData.length > 0 &&
                        <>
                            <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                                <thead className="text-[14px] text-midBlack bg-gray-200">
                                    <tr>
                                        <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                            {t('Sr. No.')}
                                        </th>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            <div className="flex gap-2">
                                                <div>{t('Name')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            <div className="flex gap-2">
                                                <div>{t('Phone')}</div>
                                            </div>
                                        </th>
                                        {/* <th scope="col" className="font-[500] py-3 pl-2">
                                            <div className="flex gap-2">
                                                <div>{t('Email')}</div>
                                            </div>
                                        </th> */}
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>{t('Last Message Received')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>{t('First Message Received')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>{t('Action')}</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chatData.map((p, i) => {
                                        return (
                                            <tr className="bg-white border-b" key={i}>
                                                <td className="py-3 text-[13px] pl-2">
                                                    {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                                                </td>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.contact?.name ?? "Anonymous"}
                                                </td>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    +{p?.contact?.whatsappNumber ?? "-"}
                                                </td>
                                                {/* <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.contact?.email ?? "-"}
                                                </td> */}
                                                <td className="py-3 text-[13px] pl-2  font-normal">
                                                    {moment.utc(p.lastMessageDate).tz(wabaTimezone).format("DD-MM-yyyy hh:mm:ss a")}
                                                </td>
                                                <td className="py-3 text-[13px] pl-2  font-normal">
                                                    {moment.utc(p?.firstMessageDate).tz(wabaTimezone).format("DD-MM-yyyy hh:mm:ss a")}
                                                </td>
                                                <td className=" py-3 text-[13px] font-normal">
                                                    <Dropdown
                                                        inline
                                                        arrowIcon={false}
                                                        inputMode
                                                        as="button"
                                                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                        label={<i className="fa-solid fa-ellipsis"></i>}
                                                    >
                                                        <Dropdown.Item
                                                            className="hover:text-theme"
                                                            onClick={() => { setSelectedMessage(p); setShowDetails(true); }}>
                                                            {t('Detail')}
                                                        </Dropdown.Item>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>}
                </div>
                {totalPages > 1 && chatData && chatData.length > 0 && (
                    <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
                        <div className="flex overflow-x-auto">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    </div>
                )}
            </div>
            <RightSidePanel
                CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showDetails}
                setIsShow={setShowDetails}
                ModalTitle={t("Details")}
                Component={
                    <LiveChatAudienceDetails setIsShow={setShowDetails} conversation={selectedMessage} selectedWaba={globalReducer.waba} />
                }
            />
            <PopUp
                ModalHeader={<div className="font-medium text-[18px] text-black"> </div>}
                isShowModal={showDownloadProcessing}
                setIsShowModal={setShowDownloadProcessing}
                ModalBody={
                    <div className="justify-center item-center text-center p-3">
                        <label>{t("Download in Process")}</label>
                        <p className='text-gray-500'>{t("Your download is currently processing, please wait.")}</p>
                        <div className='flex justify-center py-2'>
                            <button className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#f76565]"
                                onClick={() => setShowDownloadProcessing(false)}>
                                {t("OK")}
                            </button>
                        </div>
                    </div>
                }
                size="sm"
            />
        </div>
    );
}

export default LiveChatAudience