import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserStatus, getTenantUsers, sendTenantCredentials } from '../../store/actions/tenantAction';
import PopUp from '../../components/PopUp';
import { Dropdown } from 'flowbite-react';
import AddTenantUser from '../AddTenantUser';
import moment from "moment-timezone";
import { SHOW_SNACKBAR } from '../../utils/types';
import { getForgotLink } from '../../store/actions/loginAction';
import SendCredentials from '../SendCredentials';

function ManageTenantUser({ tenant, setIsShow }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [tenantUsers, setTenantUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [editUser, setEditUser] = useState(false);
    const [sendCredentials, setSendCredentials] = useState(false);
    const partnerReducer = useSelector((state) => state.partnerReducer);
    const partnerTimezone =
        partnerReducer.partnerDetails?.timezone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        onSearch();
    }, []);
    const onSearch = () => {
        dispatch(getTenantUsers({ tenantId: tenant.id }, (a) => setTenantUsers(a?.data)));
    };

    return (<div className="height30 flex flex-col justify-between pb-3">
        <div className="h-dvh overflow-y-auto p-4">
            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                    <tr>
                        <th scope="col" className="font-[500] py-3 pl-2">
                            <div className="flex gap-2">
                                <div> {t('Name')}</div>
                            </div>
                        </th>
                        <th scope="col" className="font-[500]">
                            <div className="flex gap-2">
                                <div> {t('Phone')}</div>
                            </div>
                        </th>
                        <th scope="col" className="font-[500]">
                            <div className="flex gap-2">
                                <div> {t('Username')}</div>
                            </div>
                        </th>
                        <th scope="col" className="font-[500]">
                            <div className="flex gap-2">
                                <div> {t('Email')}</div>
                            </div>
                        </th>
                        <th scope="col" className="font-[500]">
                            <div className="flex gap-2">
                                <div> {t('Role')}</div>
                            </div>
                        </th>
                        {/* <th scope="col" className="font-[500]">
                            <div className="flex gap-2">
                                <div>{t('Created Date & Time')}</div>
                            </div>
                        </th> */}
                        <th scope="col" className="font-[500] w-[160px]">
                            <div className="flex gap-2">
                                <div>{t('Status')}</div>
                            </div>
                        </th>
                        <th scope="col" className="font-[500]">
                            <div className="flex gap-2">
                                <div>{t('Action')}</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tenantUsers?.map((user, j) => {
                        return (
                            <tr key={j} className="bg-white border-b">
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {user?.name ?? "---"}
                                </td>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    +{user?.phoneCode} {user?.phone ?? "---"}
                                </td>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {user?.username ?? "---"}
                                </td>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {user?.email ?? "---"}
                                </td>
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {user?.roles?.join(",")}
                                </td>
                                {/* <td className="py-3 text-[13px] font-normal">
                                    {moment.utc(user?.createdAt)
                                        .tz(partnerTimezone)
                                        .format("YYYY-MM-DD hh:mm A") ?? "-"}
                                </td> */}
                                <td className={"py-3 text-[13px] font-normal"}>
                                    {user?.active ? (
                                        <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                                            Active
                                        </label>
                                    ) : (
                                        <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                                            Not Active
                                        </label>
                                    )}
                                </td>
                                <td className="py-3 font-normal text-[13px]">
                                    <Dropdown inline arrowIcon={false} inputMode as="button"
                                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                        label={<i className="fa-solid fa-ellipsis"></i>}>
                                        {user?.active ? (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    dispatch(changeUserStatus(
                                                        {
                                                            id: user?.uid,
                                                            tenantId: tenant.id,
                                                            isActive: false,
                                                        }, (a) => onSearch()
                                                    ));
                                                }}>
                                                {t('Deactivate')}
                                            </Dropdown.Item>
                                        ) : (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    dispatch(
                                                        changeUserStatus(
                                                            {
                                                                id: user?.uid,
                                                                tenantId: tenant.id,
                                                                isActive: true,
                                                            },
                                                            (a) => onSearch()
                                                        )
                                                    );
                                                }}
                                            >
                                                {t('Activate')}
                                            </Dropdown.Item>
                                        )}
                                        <Dropdown.Item
                                            onClick={() => {
                                                setSelectedUser({ ...user, confirmPassword: user.password });
                                                setEditUser(true);
                                            }}
                                        >
                                            {t('Edit')}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => { setSendCredentials(true); setSelectedUser(user); }}>
                                            {t('Send Credentials')}
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            dispatch(getForgotLink(
                                                {
                                                    address: user?.username,
                                                    addressType: "USERNAME",
                                                    tenantId: tenant.id,
                                                }, "iomnihub-tenant",
                                                (a) => {
                                                    dispatch({ type: SHOW_SNACKBAR, payload: { type: "success", message: "Reset Password Link have been sent to the user.", open: true } });
                                                }
                                            ));
                                        }}>
                                            {t('Reset Password')}
                                        </Dropdown.Item>
                                    </Dropdown>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <PopUp
                ModalHeader={
                    <span className="font-medium text-[18px] text-black">
                        {t('Update User')}
                    </span>
                }
                isShowModal={editUser}
                setIsShowModal={setEditUser}
                ModalBody={
                    <AddTenantUser
                        setIsShow={setEditUser}
                        onAdd={onSearch}
                        defaultUser={selectedUser}
                        isEdit={true}
                        tenantId={tenant.id}
                    />
                }
                size="2xl"
            />
            <PopUp
                ModalHeader={
                    <span className="font-medium text-[18px] text-black">
                        {t('Send Credentials')}
                    </span>
                }
                isShowModal={sendCredentials}
                setIsShowModal={setSendCredentials}
                ModalBody={
                    <SendCredentials setIsShow={setSendCredentials} type="TENANT" tenantId={tenant.id} selectedUser={selectedUser} />
                }
                size="sm"
            />
        </div>
    </div>)
}

export default ManageTenantUser