import React from "react";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LiveChatUserInfo = ({ setViewUserInfo, contact }) => {
  const { t } = useTranslation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <div className="flex justify-between items-center p-[12px] border-b-[0.8px] border-[#eeeeee]">
        <div className="flex gap-2 items-center">
          <div className="px-3 py-2 rounded-full bg-[#E4E7EB] text-[#4B5562]">
            <i className="fa-solid fa-user"></i>
          </div>
          <div>
            <div className="text-[14px] text-[#374151]">
              {contact?.contact?.name}
            </div>
            {/* <div className="text-[12px] text-theme">Infocomm</div> */}
          </div>
        </div>
        <div
          className="border w-9 h-9 p-3 border-gray-300 rounded-md cursor-pointer"
          onClick={() => setViewUserInfo(false)}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.74995 1.0835C8.08325 0.750196 8.58325 0.750196 8.91655 1.0835C9.24995 1.4168 9.24995 1.9168 8.91655 2.2502L6.16655 5.0002L8.91655 7.7502C9.08325 7.9168 9.16655 8.0835 9.16655 8.3335C9.16655 8.8335 8.83325 9.1668 8.33325 9.1668C8.08325 9.1668 7.91655 9.0835 7.74995 8.9168L4.41655 5.5835C4.08325 5.2502 4.08325 4.7502 4.41655 4.4168L7.74995 1.0835ZM1.66655 0.833496C2.16655 0.833496 2.49995 1.1668 2.49995 1.6668V8.3335C2.49995 8.8335 2.16655 9.1668 1.66655 9.1668C1.16655 9.1668 0.833252 8.8335 0.833252 8.3335V1.6668C0.833252 1.1668 1.16655 0.833496 1.66655 0.833496Z"
              fill="#6B7280"
            />
          </svg>
        </div>
      </div>
      <div className="p-4">
        <div className="p-[12px] border-[0.8px] border-[#eee] rounded-[4px] flex flex-col gap-[12px]">
          <div className="text-[#111827] text-[14px] font-semibold">
            {t('Customer Information')}
          </div>
          <div className="flex gap-[8px] items-center">
            <img src="/images/user_1.svg" alt="" className="h-[18px]" />
            <div className="text-[#374151]">{contact?.contact?.name}</div>
          </div>
          <div className="flex gap-[8px] items-center">
            <img src="/images/email_1.svg" alt="" className="h-[18px]" />
            <div className="text-[#6B7280]">{contact?.contact?.email?.replace("0", "") ?? ""}</div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-[8px] items-center">
              <i className="fa-solid fa-phone"></i>
              <div className="text-[#374151]">{contact?.contact?.whatsappNumber}</div>
            </div>
            <div className="flex gap-[12px] items-center">
              <div
                className="text-[#6B7280]"
                onClick={() =>
                  navigator.clipboard.writeText(contact?.contact?.whatsappNumber)
                }
              >
                <a target="_blank" href={`https://api.whatsapp.com/send/?phone=${contact?.contact?.whatsappNumber}&text&type=phone_number&app_absent=0`}><i className="fa-brands fa-whatsapp text-[20px] pr-3 cursor-pointer"></i></a>
                <i className="fa-solid fa-copy cursor-pointer"></i>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-[8px] items-center">
              <img src="/images/ticket_1.svg" alt="" className="h-[18px]" />
              <div className="text-[#374151]">#{contact?.ticketNumber}</div>
            </div>
            <div className="flex gap-[12px] items-center">
              {/* <img src="/images/wsapp.svg" className="h-[18px]" alt="" /> */}
              <div
                className="text-[#6B7280]"
                onClick={() =>
                  navigator.clipboard.writeText(contact?.ticketNumber)
                }
              >
                <i className="fa-solid fa-copy cursor-pointer"></i>
              </div>
            </div>
          </div>
          <div className="flex gap-[8px] items-center">
            <img src="/images/location_1.svg" alt="" className="h-[18px]" />
            <div className="text-[#374151]">{contact?.contact?.location}</div>
          </div>
          <div className="flex gap-[8px] items-center">
            <img src="/images/calender_1.svg" alt="" className="h-[18px]" />
            <div className="text-[#374151]">
              {t('Customer since')}{" "}
              {moment
                .utc(contact?.contact?.createdAt)
                .tz(wabaTimezone)
                .format("DD MMM YYYY")}
            </div>
          </div>
          <div className="flex gap-[8px] items-center">
            <i className="fa-regular fa-clipboard text-gray-500"></i>
            <div className="text-[#374151]">{t('Notes')}</div>
          </div>
          <textarea
            disabled
            rows={5}
            value={contact?.detail?.notes}
            className="text-[#6B7280] w-full border-none outline-none resize-none skipthis"
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default LiveChatUserInfo;
