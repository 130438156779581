import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMessageType } from "../../store/actions/partnerAction";
import { useTranslation } from "react-i18next";

function ViewPartnerRateCard({ setIsShow, selectedRateCard }) {
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const [messageTypes, setMessageTypes] = useState([]);
  const [rateCard, setRateCard] = useState(selectedRateCard ?? []);

  useEffect(() => {
    dispatch(getMessageType(setMessageTypes));
  }, []);

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="height30 flex flex-col justify-between pb-3">
      <div className="h-dvh overflow-y-auto p-4 pb-6">
        {rateCard?.length > 0 && (
          <>
            <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mb-1">
              {t('Domestic Rate')}
            </div>
            <table className="text-sm text-left rtl:text-right text-midBlack">
              <thead className="text-[14px] text-midBlack">
                <tr className="h-9 bg-gray-100">
                  <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                    <div>{t('Countries')}</div>
                  </th>
                  {messageTypes.map((m, i) => (
                    <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                      <div>{m.messageType}</div>
                    </th>
                  ))}
                </tr>
                {rateCard
                  ?.filter((f) => f.currency === "NATIVE")
                  .map((f, r) => (
                    <tr key={r}>
                      <td className="py-2 font-normal text-[13px] pr-2">
                        {f?.countryName ?? "Domestic"} ({f?.currencyCode})
                      </td>
                      {messageTypes.map((m, i) => (
                        <td
                          key={i}
                          className="py-2 font-normal text-[13px] pr-1"
                        >
                          <input
                            data-maxlength={8}
                            onInput={OnInput}
                            type="number"
                            className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                            value={f?.rates?.[m.messageType]}
                            disabled
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                <tr>
                  <td colSpan={messageTypes.length + 1}>
                    <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-1 mb-1 flex justify-between">
                      <label className="mt-1">
                        {t('International')} ({rateCard[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder={t("Search Countries")}
                        className="border border-gray-300 rounded-md h-9 py-1"
                        onChange={(e) => {
                          const cn = e.target.value;
                          setRateCard(
                            rateCard.map((f) => {
                              if (
                                f.countryCode?.includes(cn) ||
                                f.countryName?.includes(cn)
                              ) {
                                return { ...f, isHide: false };
                              } else return { ...f, isHide: true };
                            })
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {rateCard?.map((d, c) => (
                  <tr
                    className={
                      "border " +
                      (d.isHide || d.currency === "NATIVE" ? "hidden" : "")
                    }
                    key={c}
                  >
                    <td className="py-1 font-normal text-[13px] pl-2 pr-2">
                      {d?.countryName}
                    </td>
                    {messageTypes.map((m, i) => (
                      <td key={i} className="py-1 font-normal text-[13px] pr-1">
                        <input
                          data-maxlength={8}
                          onInput={OnInput}
                          type="number"
                          className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                          value={d?.rates?.[m.messageType]}
                          disabled
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}

export default ViewPartnerRateCard;
