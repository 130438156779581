import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { loginAsTenantUser, partnerSearchWaba } from "../../store/actions/partnerAction";
import { changeTenantWabaStatus } from "../../store/actions/tenantAction";
import RightSidePanel from "../../components/RightSidePanel";
import MapTenantRateCard from "../../layouts/partner/MapTenantRateCard";
import { FiCreditCard } from "react-icons/fi";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import AddWaba from "../../layouts/partner/AddWaba";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import ViewWaba from "../../layouts/partner/ViewWaba";
const { RangePicker } = DatePicker;

function ManageWaba({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAddWaba, setshowAddWaba] = useState(false);
  const [showViewWaba, setShowViewWaba] = useState(false);
  const [viewMapRateCard, setViewMapRateCard] = useState(false);
  const [Wabas, setWabas] = useState([]);
  const [searchText, setSearchText] = useState();
  const [dateFilter, setDateFilter] = useState();
  const [selectedWaba, setSelectedWaba] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ filter: [], criteria: {} });
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const partnerTimezone =
    partnerReducer.partnerDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    Wabasearch();
  }, [currentPage]);

  const Wabasearch = () => {
    const fi = [
      { clause: "OPEN_BRACKET" },
      {
        property: "companyName",
        operator: "ICONTAINS",
        value: searchText,
      },
      { clause: "OR" },
      {
        property: "teamName",
        operator: "ICONTAINS",
        value: searchText,
      },
      { clause: "OR" },
      {
        property: "phone",
        operator: "EQ",
        value: searchText,
      },
      { clause: "OR" },
      {
        property: "wabaNumber",
        operator: "EQ",
        value: searchText,
      }, { clause: "OR" },
      {
        property: "bspName",
        operator: "ICONTAINS",
        value: searchText,
      }, { clause: "CLOSE_BRACKET" }];
    if (dateFilter && searchText) {
      filter.filter = [...fi, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fi;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    dispatch(
      partnerSearchWaba(
        {
          ...filter,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setWabas(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      Wabasearch();
    } else {
      setCurrentPage(1);
    }
  };

  const LoginAsUser = (customer) => {
    dispatch(loginAsTenantUser(customer, d => {
      localStorage.setItem("sessionToken", d?.access_token);
      localStorage.setItem("refreshToken", d?.refresh_token);
      window.location.replace(JSON.parse(process.env.REACT_APP_TENANT_PORTAL_CONFIG).redirect_uri);
    }));
  }
  const selectDate = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };
  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular text-[14px] fa-solid fa-Waba-plus text-theme"></i>
            <div className="text-[14px] font-medium">{t('Manage WABA')}</div>
          </div>
          <div className="flex gap-2">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedWaba(null);
                setshowAddWaba(true);
              }}
            >
              {t('Add WABA')}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('WABA Details')}</label>
              </div>
              <input
                type="text"
                placeholder="Enter WABA Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Status')}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Country')}</label>
              </div>
              <RangePicker className="h-10 col-span-4" onChange={selectDate} format="YYYY-MM-DD" />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {Wabas && Wabas.length > 0 ? (
            <div className="h-full">
              <div className="bg-white">
                <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                  <thead className="text-[14px] text-midBlack bg-gray-200">
                    <tr>
                      <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                        {t('Sr. No.')}
                      </th>
                      <th scope="col" className="font-[500] py-3 pl-2">
                        <div className="flex gap-2">
                          <div>{t('Company Name')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t('Team Name')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t('WABA Phone Number')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div>{t('BSP')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t('Country')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t('Created Date & Time')}</div>
                        </div>
                      </th>

                      {/* <th scope="col" className="font-[500]">
                                                <div className="flex gap-2">
                                                    <div>Balance</div>
                                                </div>
                                            </th> */}
                      <th scope="col" className="font-[500] w-[160px]">
                        <div className="flex gap-2">
                          <div>{t('Status')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div>{t('Action')}</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Wabas.map((p, i) => {
                      return (
                        <tr className="bg-white border-b" key={i}>
                          <td className="py-3 text-[13px] pl-2">
                            {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                          </td>
                          <td className="py-3 text-[13px] pl-2 font-normal">
                            {p?.companyName ?? "-"}
                          </td>
                          <td
                            className="py-3 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                            onClick={() => {
                              setSelectedWaba({
                                ...p,
                                isEdit: true,
                                isView: true,
                              });
                              setshowAddWaba(true);
                            }}
                          >
                            {p?.teamName ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            +{p?.phoneCode} {p?.phone}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.bspName ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.countryName ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {moment.utc(p?.createdAt)
                              .tz(partnerTimezone)
                              .format("YYYY-MM-DD hh:mm A") ?? "-"}
                          </td>
                          {/* <td className="py-3 text-[13px] font-normal">
                                                        {p?.accountType === "POSTPAID" ? "Credit Limit " + p?.currencyCode + " " + p?.walletLimit : "Wallet Balance " + p?.currencyCode + " " + p?.walletLimit}
                                                    </td> */}
                          <td className="py-3 text-[13px] font-normal">
                            {p?.active ? (
                              <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                                Active
                              </label>
                            ) : (
                              <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                                Not Active
                              </label>
                            )}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            <Dropdown
                              inline
                              arrowIcon={false}
                              inputMode
                              as="button"
                              className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                              label={<i className="fa-solid fa-ellipsis"></i>}
                            >
                              {p?.active ? (
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    dispatch(
                                      changeTenantWabaStatus(
                                        {
                                          id: p.id,
                                          tenantId: p.tenantId,
                                          isActive: false,
                                        },
                                        onSearch
                                      )
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-link-slash pr-2"></i>
                                  {t('Deactivate')}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    dispatch(
                                      changeTenantWabaStatus(
                                        {
                                          id: p?.id,
                                          tenantId: p.tenantId,
                                          isActive: true,
                                        },
                                        onSearch
                                      )
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-link pr-2"></i>
                                  {t('Activate')}
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  setSelectedWaba(p);
                                  setShowViewWaba(true);
                                }}>
                                <FiCreditCard className="mr-2" />
                                {t('View WABA')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  setSelectedWaba({ ...p, isEdit: true });
                                  setshowAddWaba(true);
                                }}
                              >
                                <i className="fa-regular fa-pen-to-square pr-2"></i>
                                {t('Edit WABA')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  setSelectedWaba(p);
                                  setViewMapRateCard(true);
                                }}
                              >
                                <FiCreditCard className="mr-2" />
                                {t('Map Rate Card')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => LoginAsUser({ id: p.tenantId })}>
                                <i className="fa-solid fa-right-to-bracket mr-2"></i>
                                {t('Login as User')}
                              </Dropdown.Item>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {totalPages > 1 ? (
                  <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                    <Pagination
                      page={currentPage}
                      count={totalPages}
                      onChange={(event, page) => setCurrentPage(page)}
                      shape="rounded"
                      color="secondary"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t('The space is empty . Add a Company Waba to get started.')}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => setshowAddWaba(true)}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t('Add Waba')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <RightSidePanel
        ModalTitle={
          selectedWaba?.isEdit
            ? selectedWaba?.isView
              ? "View WABA"
              : "Update WABA"
            : "Add WABA"
        }
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showAddWaba}
        setIsShow={setshowAddWaba}
        Component={
          <AddWaba
            waba={selectedWaba}
            setIsShow={setshowAddWaba}
            onAdd={Wabasearch}
            isEdit={selectedWaba?.isEdit}
            isView={selectedWaba?.isView}
            onEditRateCard={() => {
              setshowAddWaba(false);
              setViewMapRateCard(true);
            }}
          />
        }
      />
      <RightSidePanel
        ModalTitle="View WABA"
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showViewWaba}
        setIsShow={setShowViewWaba}
        Component={
          <ViewWaba waba={selectedWaba} setIsShow={setShowViewWaba} />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[60%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewMapRateCard}
        setIsShow={setViewMapRateCard}
        ModalTitle="Map Rate Card"
        Component={
          <MapTenantRateCard
            setIsShow={setViewMapRateCard}
            tenant={{ id: selectedWaba?.tenantId }}
            waba={selectedWaba}
            onAdd={Wabasearch}
          />
        }
      />
    </div>
  );
}

export default ManageWaba;
