import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../utils/routesConstants';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'flowbite-react';
import { switchLanguage } from '../store/reducers/languageReducer';
import i18n from '../i18n';
import { publicTenantOnboard } from '../store/actions/partnerAction';
import SelectCountry from '../components/Dropdowns/SelectCountry';
import MobileInput from '../components/MobileInput';
import Password from '../components/Password';
import { getCountry } from '../store/actions/globalAction';
import { SHOW_SNACKBAR } from '../utils/types';
import { validatePassword } from '../utils/helper';
import CustomCaptcha from '../components/CustomCaptcha';

function SignUp() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector(
    (state) => state.languageReducer.currentLanguage
  );
  const [tenant, setTenant] = useState({ shortName: "" });
  const [users, setUsers] = useState([{}]);
  const [passwordError, setPasswordError] = useState();
  const globalReducer = useSelector((state) => state.globalReducer);
  const captcha = useRef(null);

  useEffect(() => {
    dispatch(getCountry({}, c => { }));
  }, [])

  const handleChange = (index, value, datakey) => {
    setUsers((prevDataArray) => {
      const newDataArray = [...prevDataArray];
      newDataArray[index] = { ...newDataArray[index], [datakey]: value };
      return newDataArray;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordError) {
      return;
    }
    if (!captcha.current.validate()) {
      return;
    }
    tenant.isOwnDb = false;
    users[0].roles = ["TENANT_ADMIN"];
    users[0].isPrimary = true;
    tenant.tenantUsers = users;
    tenant.contactPerson = users[0].name;
    tenant.contactEmail = users[0].email;
    tenant.contactPhone = users[0].phone;
    tenant.contactPhoneCode = users[0].phoneCode;
    tenant.partnerId = "P2XP59A1F77C4";
    tenant.shortName = tenant.name?.replace(/[^a-z0-9_]/g, "");
    tenant.status = "PENDING";
    dispatch(
      publicTenantOnboard(tenant, (a) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "error",
            message: "account created successfully.",
            open: true,
          },
        });
        setUsers([{}]);
        setTenant({});
      })
    );
  }

  const passwordMessage = (password, confirmPassword) => {
    setPasswordError(validatePassword(password, confirmPassword));
  };
  const handleLanguageChange = (value) => {
    dispatch(switchLanguage(value));
    i18n.changeLanguage(value);
  };
  const gotoLogin = () => navigate(ROUTES.LOGIN);
  return (
    <>
      <div className="flex flex-col h-screen bg-slate-100 max-md:px-5 p-4">
        <div className="flex justify-between">
          <div>
            <img
              alt=""
              loading="lazy"
              src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`}
              className=" w-40 max-w-full aspect-[3.85] max-md:mt-10"
            />
          </div>
          <div className="flex gap-5 px-5 items-center text-[14px] font-normal">
            <div className="flex gap-2"> Language
              <Dropdown
                arrowIcon={false}
                inline
                size="sm"
                label={
                  language === "en" ? (
                    <span className="px-2 bg-white rounded-md ring-2 p-0.5 text-[15px] ring-gray-300">
                      English
                    </span>
                  ) : language === "ar" ? (
                    <span className="px-2 bg-white rounded-md ring-2 p-0.5 text-[15px] ring-gray-300">
                      Arabic
                    </span>
                  ) : (
                    <i className="fa-solid fa-language rounded-md px-3 bg-white ring-2 p-2 text-[32px] ring-gray-300"></i>
                  )
                }
              >
                <Dropdown.Item
                  className="py-1 text-[16px]"
                  onClick={() => handleLanguageChange("en")}
                >
                  English
                </Dropdown.Item>
                <Dropdown.Item
                  className="py-1 text-[16px]"
                  onClick={() => handleLanguageChange("ar")}
                >
                  Arabic
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="flex flex-col min-h-[88vh] justify-center items-center bg-slate-100 max-md:px-5">
          <div className="flex justify-center items-center">
            <div className="flex flex-col gap-2 px-7 py-5 bg-white rounded-[16px] shadow-input max-w-[850px] max-md:px-5 w-[550px]">
              <div className="justify-center items-center pb-[20px] text-[24px] font-[600] text-center text-black max-md:px-5 max-md:max-w-full tracking-[-0.02em] leading-[28px]">
                {t('Create a new account!')}<br /> <span className='text-[14px]'>Or </span><label className='text-[14px] text-theme tracking-[-0.02em] cursor-pointer' onClick={gotoLogin}> {t("login to your account here")}</label>
              </div>
              <div>
                <form className="flex flex-col justify-between" onSubmit={handleSubmit}>
                  <div className="">
                    <div className="grid grid-cols-12 gap-2">
                      <div className="flex flex-col col-span-6">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                          <label>{t('Company Name')}<span className="text-red-500 text-[14px]">*</span></label>
                        </div>
                        <input
                          type="text"
                          placeholder={t('Company Name')}
                          value={tenant?.name}
                          onChange={(e) => {
                            setTenant({ ...tenant, name: e.target.value });
                          }}
                          className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                          required
                        />
                      </div>
                      <div className="flex flex-col col-span-6">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                          <label>{t('Country Name')}<span className="text-red-500 text-[14px]">*</span></label>
                        </div>
                        <SelectCountry
                          required={true}
                          selectedCode={tenant?.countryCode}
                          countryList={globalReducer.country}
                          onSelectCountry={(country) => {
                            if (country) {
                              tenant.countryCode = country.code;
                              users[0].phoneCode = country.phoneCode;
                            } else {
                              delete tenant.countryCode;
                            }
                            setTenant({ ...tenant });
                          }}
                          placeholder={('Select Country')}
                        />
                      </div>
                      <div className="flex flex-col col-span-12">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                          <label>{t('Business URL')}<span className="text-red-500 text-[14px]">*</span></label>
                        </div>
                        <input
                          type="text" required
                          placeholder={t("Your website/social account link")}
                          value={tenant.website}
                          onChange={(e) =>
                            setTenant({ ...tenant, website: e.target.value })
                          }
                          className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                        />
                      </div>
                    </div>
                    <div className="font-medium text-[13px] my-[10px] text-[#2A2E34]">
                      {t('Contact Details')}
                    </div>
                    {users.map((u, i) => (
                      <div className="grid grid-cols-12 gap-2 w-full">
                        <div className="col-span-6">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label> {t('Name')}<span className="text-red-500 text-[14px]">*</span></label>
                          </div>
                          <input
                            type="text"
                            placeholder={t("Enter Name")}
                            name="name"
                            value={u.name}
                            onChange={(e) => handleChange(i, e.target.value, "name")}
                            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                            required
                          />
                        </div>
                        <div className="col-span-6">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t('Phone Number')}<span className="text-red-500 text-[14px]">*</span></label>
                          </div>
                          <MobileInput
                            required={true}
                            countryList={globalReducer.country}
                            phoneCode={u.phoneCode}
                            phone={u.phone}
                            setPhoneCode={(phone) => handleChange(0, phone, "phoneCode")}
                            setPhone={(phone) => handleChange(i, phone, "phone")}
                            placeholder={t("Enter Phone Number")}
                          />
                        </div>
                        <div className="col-span-6">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t('Email')}<span className="text-red-500 text-[14px]">*</span></label>
                          </div>
                          <input
                            type="email"
                            placeholder={t("Enter Email")}
                            name="email"
                            value={u.email}
                            onChange={(e) => handleChange(i, e.target.value, "email")}
                            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                            required
                          />
                        </div>
                        <div className="col-span-6">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t('Username')}<span className="text-red-500 text-[14px]">*</span></label>
                          </div>
                          <input
                            type="text"
                            placeholder={t("Enter Username")}
                            value={u.username}
                            onChange={(e) => handleChange(i, e.target.value, "username")}
                            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                            required
                          />
                        </div>
                        <div className="col-span-6">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t('Password')}<span className="text-red-500 text-[14px]">*</span></label>
                          </div>
                          <Password
                            isError={passwordError ? true : false}
                            onChange={(a) => {
                              passwordMessage(a, u.confirmPassword);
                              handleChange(i, a, "password");
                            }}
                            value={u.password}
                            required={true}
                            placeholder={t("Enter Password")}
                          />
                        </div>
                        <div className="col-span-6">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t('Confirm Password')}<span className="text-red-500 text-[14px]">*</span></label>
                          </div>
                          <Password
                            isError={passwordError ? true : false}
                            onChange={(a) => {
                              passwordMessage(u.password, a);
                              handleChange(i, a, "confirmPassword");
                            }}
                            value={u.confirmPassword}
                            required={true}
                            placeholder={t("Enter Confirm Password")}
                          />
                        </div>
                        <div className="col-span-12 h-1 mt-[-10px]">
                          {passwordError && (
                            <label className="text-[12px] text-[#f10808]">
                              {passwordError}
                            </label>
                          )}
                        </div>
                      </div>
                    ))}
                    <div className="mt-[12px]">
                      <CustomCaptcha ref={captcha} className="h-8"/>
                    </div>
                  </div>
                  <div className="mt-2 bg-white border-grayish">
                    <div className="flex justify-end">
                      <button type="submit" className="w-full text-center px-[16px] py-2 text-white font-medium text-[13px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                        {t('Sign Up')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp