import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { whatsAppDoc } from "../../apidoc/whatsapp";
import { useTranslation } from "react-i18next";

function CustomerApiDoc() {
  const {t} = useTranslation();
  const [docType, setDocType] = useState("WHATSAPP");
  const [apiKey, setApiKey] = useState({});

  return (
    <div className="h-screen">
      <div
        className={`fullWidth1 transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="text-[14px] fa-regular fa-file-code text-theme"></i>
            <div className="text-[14px] font-medium">{t('API Documentation')}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 mb-[20px]">
          <div className="px-4 bg-white mb-2 pb-4 rounded-[8px] shadow-lg">
            <div className="p-3">
              <div className="flex justify-between">
                <div className="text-[16px] font-semibold">
                  {t('APIs For WhatsApp')}
                </div>
                <div className="flex gap-4 text-[14px]">
                  {/* <button type='button' className='text-white bg-theme px-2 py-1 rounded-[4px]'>Authorize</button> */}
                  <a
                    download={"iomnihub-api-example.postman_collection.json"}
                    href={`${process.env.PUBLIC_URL}/files/iomnihub-api-example.postman_collection.json`}
                    className="text-white bg-theme min-w-[76px] text-center px-[16px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer"
                  >
                    {t('Download Postman Collection')}
                  </a>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[14px] text-gray-500 max-w-[480px]">
                 {t(' Our developer-friendly REST APIs are super-easy to integrate and have bearer authentication mechanism. You can integrate these APIs with your own programming languages.')}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white mb-5 rounded-[8px] shadow-lg">
            <SwaggerUI spec={whatsAppDoc()} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerApiDoc;
