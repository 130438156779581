import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { planUpsert } from "../../store/actions/partnerAction";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import { allIntegrationList } from "../../utils/helper";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

function AddPlan({ setIsShow, onAdd, isEdit = false, defaultPlan }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [plan, setPlan] = useState(
    defaultPlan ?? {
      billingFrequency: "YEARLY", durationMonth: 12, messageParMinute: 60,
      numContacts: 5000, numLiveChatAgent: 2, numSessions: 3, numWabaNumbers: 1,
      availableIntegrations: ["WHATSAPP"]
    }
  );
  const [permissions, setPermissions] = useState(
    defaultPlan?.permissions ?? []
  );
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    if (!isEdit) {
      const country = globalReducer.country.find(
        (f) => f.code === partnerReducer.partnerDetails.countryCode
      );
      if (country) {
        setPlan({
          ...plan,
          countryCode: country.code,
          currency: country.currencyCode,
        });
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    plan.permissions = permissions;
    dispatch(
      planUpsert(plan, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="height30 flex flex-col justify-between pb-3"
      onSubmit={handleSubmit}
    >
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-3 w-full mapPlan">
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Plan Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="text"
              placeholder={t("Enter Plan Name")}
              value={plan?.name}
              onChange={(e) => setPlan({ ...plan, name: e.target.value })}
              className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
              required
            />
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t("Country Name")}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <SelectCountry
              required={true}
              selectedCode={plan?.countryCode}
              countryList={globalReducer.country}
              onSelectCountry={(country) => {
                if (country) {
                  plan.countryCode = country.code;
                  plan.currency = country.currencyCode;
                } else {
                  delete plan.countryCode;
                  delete plan.currency;
                }
                setPlan({ ...plan });
              }}
              placeholder="Select Country"
            />
          </div>
          <div className="col-span-4">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t("Amount")}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <div className="flex border border-gray-300 rounded-md text-[14px]">
              <label className="border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-1.5">
                {plan?.currency}
              </label>
              <input
                type="number"
                placeholder={t("Enter Amount")}
                value={plan?.rate}
                data-maxlength={8}
                onInput={OnInput}
                onChange={(e) => setPlan({ ...plan, rate: e.target.value })}
                className="border-0 rounded-md py-1 w-[100%] h-9 text-[13px]"
                required
              />
            </div>
          </div>
          <div className="flex flex-col col-span-4">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Billing Frequency')} <span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select required
              className="border border-gray-300 rounded-md py-1 h-9 px-4 cursor-pointer text-[14px]"
              value={plan?.billingFrequency}
              onChange={(e) => {
                setPlan({ ...plan, billingFrequency: e.target.value });
              }}
            >
              <option value="MONTHLY">
                {t('Monthly')}
              </option>
              <option value="QUARTERLY">{t("Quarterly")}</option>
              <option value="YEARLY" selected>{t("Yearly")}</option>
            </select>
          </div>
          <div className="col-span-4">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Tenure(Months)')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="number"
              data-maxlength={3}
              onInput={OnInput}
              placeholder={t("Enter Duration")}
              value={plan?.durationMonth}
              onChange={(e) =>
                setPlan({ ...plan, durationMonth: e.target.value })
              }
              className="border border-gray-300 rounded-md py-1 w-[100%] h-9 text-[13px]"
              required
            />
          </div>
          <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
            <div className="flex justify-between">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      if (!permissions?.some((f) => f == "FINANCE_ACCESS")) {
                        permissions.push("FINANCE_ACCESS");
                        setPermissions([...permissions]);
                      }
                    } else {
                      setPermissions([
                        ...permissions?.filter((f) => f != "FINANCE_ACCESS"),
                      ]);
                    }
                  }}
                  checked={permissions?.some((f) => f == "FINANCE_ACCESS")}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                <span className="ms-3 text-sm font-medium text-gray-900">
                  {t("Finance & Report")}
                </span>
              </label>
            </div>
          </div>
          <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
            <div className="flex justify-between">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      if (!permissions?.some((f) => f == "API")) {
                        permissions.push("API");
                        setPermissions([...permissions]);
                      }
                    } else {
                      setPermissions([
                        ...permissions?.filter((f) => f != "API"),
                      ]);
                    }
                  }}
                  checked={permissions?.some((f) => f == "API")}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                <span className="ms-3 text-sm font-medium text-gray-900">
                  {t("API")}
                </span>
              </label>
              <div className="flex gap-2 items-center">
                {permissions?.some((f) => f == "API") && (
                  <>
                    <label className="text-midBlack text-[13px] font-normal">
                      {t('Messages/Minute')}<span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder={t("Messages/Minute")}
                      value={plan?.messageParMinute}
                      data-maxlength={8}
                      onInput={OnInput}
                      onChange={(e) =>
                        setPlan({ ...plan, messageParMinute: e.target.value })
                      }
                      className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                      required
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
            <div className="flex justify-between">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      if (!permissions?.some((f) => f == "CAMPAIGNS")) {
                        permissions.push("CAMPAIGNS");
                        setPermissions([...permissions]);
                      }
                    } else {
                      setPermissions([
                        ...permissions?.filter((f) => f != "CAMPAIGNS"),
                      ]);
                    }
                  }}
                  checked={permissions?.some((f) => f == "CAMPAIGNS")}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 ">
                  {t('Campaigns')}
                </span>
              </label>
              <div className="flex gap-2 items-center">
                {permissions?.some((f) => f == "CAMPAIGNS") && (
                  <>
                    <label className="text-midBlack text-[13px] font-normal">
                      {t("Number of Contacts")}<span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder={t("Enter Number of Contacts")}
                      value={plan?.numContacts}
                      data-maxlength={8}
                      onInput={OnInput}
                      onChange={(e) =>
                        setPlan({ ...plan, numContacts: e.target.value })
                      }
                      className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                      required
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
            <div className="flex justify-between">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      if (!permissions?.some((f) => f == "LIVE_CHAT")) {
                        permissions.push("LIVE_CHAT");
                        setPermissions([...permissions]);
                      }
                    } else {
                      setPermissions([
                        ...permissions?.filter((f) => f != "LIVE_CHAT"),
                      ]);
                    }
                  }}
                  checked={permissions?.some((f) => f == "LIVE_CHAT")}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 ">
                  {t("Live Chat")}
                </span>
              </label>
              <div className="flex gap-2 items-center">
                {/* {permissions?.some((f) => f == "LIVE_CHAT") && (
                  <>
                    <label className="text-midBlack text-[13px] font-normal">
                      {t("Number of Live Chat Agent")}<span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder={t("Enter Number of Live Chat Agent")}
                      value={plan?.numLiveChatAgent}
                      data-maxlength={6}
                      onInput={OnInput}
                      onChange={(e) =>
                        setPlan({ ...plan, numLiveChatAgent: e.target.value })
                      }
                      className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                      required
                    />
                  </>
                )} */}
              </div>
            </div>
          </div>

          <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
            <div className="flex justify-between">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    if (e.target.checked === true) {
                      if (!permissions?.some((f) => f == "CHATBOT")) {
                        permissions.push("CHATBOT");
                        setPermissions([...permissions]);
                      }
                    } else {
                      setPermissions([
                        ...permissions?.filter((f) => f != "CHATBOT"),
                      ]);
                    }
                  }}
                  checked={permissions?.some((f) => f == "CHATBOT")}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-theme"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 ">
                  {t("Chatbot")}
                </span>
              </label>
              <div className="flex gap-2 items-center">
                {/* {permissions?.some((f) => f == "CHATBOT") && (
                  <>
                    <label className="text-midBlack text-[13px] font-normal">
                      {t('Number of Sessions')}<span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder={t("Enter Number of Sessions")}
                      value={plan?.numSessions}
                      data-maxlength={5}
                      onInput={OnInput}
                      onChange={(e) =>
                        setPlan({ ...plan, numSessions: e.target.value })
                      }
                      className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                      required
                    />
                  </>
                )} */}
              </div>
            </div>
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Users')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="number"
              placeholder={t("Enter Users")}
              value={plan?.numUsers}
              data-maxlength={5}
              onInput={OnInput}
              onChange={(e) => setPlan({ ...plan, numUsers: e.target.value })}
              className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
              required
            />
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Number of WABA Account')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="number"
              placeholder={t("Enter WABA")}
              value={plan?.numWabaNumbers}
              data-maxlength={8}
              onInput={OnInput}
              onChange={(e) =>
                setPlan({ ...plan, numWabaNumbers: e.target.value })
              }
              className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
              required
            />
          </div>
          <div className="col-span-12">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Available Integrations')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <Autocomplete
              multiple={true}
              aria-required={true}
              value={allIntegrationList?.filter((m) =>
                plan.availableIntegrations?.some((s) => s === m.type)
              )}
              options={allIntegrationList}
              isOptionEqualToValue={(option, value) =>
                option?.type === value?.type
              }
              getOptionLabel={(option) => option?.type ?? ""}
              onChange={(e, value) => {
                setPlan({
                  ...plan,
                  availableIntegrations: value?.map((c) => c.type) ?? [],
                });
              }}
              sx={{
                "& .MuiAutocomplete-inputRoot": {
                  padding: "0px",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category"
                  className="custom-input"
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "0px",
                      "&.Mui-focused fieldset": {
                        borderColor: "none",
                        border: "0px",
                      },
                      fontSize: "14px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#FFFFFF",
                      "& fieldset": {
                        borderColor: "#ccc",
                      },
                      "&:hover fieldset": {
                        borderColor: "#888",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#1976d2",
                      },
                    },
                  }}
                />
              )}
            />
          </div>
          {/* <div className="col-span-12">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Plan HTML')}</label>
            </div>
            <textarea
              placeholder={t("Enter HTML")}
              rows="2"
              value={plan?.planHtml}
              onChange={(e) => setPlan({ ...plan, planHtml: e.target.value })}
              className="border border-gray-300 rounded-md text-[13px] px-3 h-[200px] py-1 w-[100%]"
            />
          </div> */}
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="submit"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddPlan;
