import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { unblockAccount, validateForgotLink } from '../store/actions/loginAction';
import ROUTES from '../utils/routesConstants';

function UnblockAccountLink() {
    const { t } = useTranslation();
    const { link } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [inValid, setInValid] = useState(true);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (link) {
            dispatch(
                validateForgotLink(link, (a) => {
                    setInValid(a === false);
                })
            );
        } else {
            setInValid(true);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (link) {
            dispatch(
                unblockAccount({}, link, (a) => {
                    setSuccess(a);
                })
            );
        }
    };

    const gotoLogin = () => {
        navigate(ROUTES.LOGIN);
    };
    return (
        <div className="flex flex-col h-screen bg-slate-100 max-md:px-5 p-4">
            <div className="flex justify-between">
                <div>
                    <img
                        alt=""
                        loading="lazy"
                        src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`}
                        className=" w-40 max-w-full aspect-[3.85] max-md:mt-10"
                    />
                </div>
            </div>
            <div className="flex justify-center">
                {inValid ? (
                    <div className="py-4 mt-15">
                        <p className="mt-5 ml-3">
                            <i className="fa-solid fa-xmark text-[50px] text-theme"></i>
                        </p>
                        <p className="text-[18px]">
                            {t('It looks like the password reset link you tried to use is invalid. This can happen if:')}
                            <ul className="text-gray-500 list-disc ml-7">
                                <li>{t('The link has already been used.')}</li>
                                <li>{t('The link has expired.')}</li>
                                <li>{t('The link was copied incorrectly.')}</li>
                            </ul>
                        </p>
                        <p className="text-[16px] text-gray-500 mt-3">
                            {t('Don’t worry! You can request a new password reset link.')}
                        </p>
                    </div>
                ) : success ? (
                    <div className="py-4 mt-15">
                        <p className="mt-5 ml-3">
                            <i className="fa-regular fa-circle-check text-[50px] text-theme"></i>
                        </p>
                        <p className="text-[18px] mt-2">
                            {t('We are pleased to inform you that your account has been successfully unblocked. You can now log in and access your account as usual.')}
                            <br />
                            {t('If you encounter any issues, please feel free to reach out to our support team.')}
                        </p>
                        <button
                            type="button"
                            onClick={gotoLogin}
                            className="justify-center cursor-pointer items-center p-[12px] mt-7 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-[200px]"
                        >
                            {t('Log In to Your Account')}
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col min-h-[80vh] justify-center items-center bg-slate-100 max-md:px-5">
                        <div className="flex justify-center items-center">
                            <form onSubmit={handleSubmit} action="POST">
                                <div className="flex flex-col gap-2 p-[30px] bg-white rounded-[16px] shadow-input max-w-[750px] max-md:px-5 w-[450px]">
                                    <div>
                                        <label>{t('please click the button below to unblock your account:')}</label>
                                        <br />
                                        <label>{t('If you did not request this action, please ignore this.')}</label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="justify-center cursor-pointer items-center p-[12px] mt-7 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                                    >
                                        {t('Click to Unblock My Account')}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="w-full flex  flex-col justify-center items-center mt-[20px]">
                            <a href="https://www.i-grp.com/" target="blank">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/Infocomm-Logo-on-Powered.svg`}
                                    width="128"
                                    height="128"
                                    alt="hello"
                                />
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default UnblockAccountLink