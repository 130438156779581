import React, { useEffect, useState } from "react";
import WhatsAppTemplatePreview from "../../components/WhatsAppTemplatePreview";
import {
  whatsAppTemplateTypes,
  whatsAppLanguages,
  getAddress,
} from "../../utils/helper";
import FileUploader from "../../components/FileUploader";
import {
  whatsAppMsgTemplateCreate,
  whatsAppMsgTemplateUpdate,
} from "../../store/actions/tenantAction";
import { useDispatch } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { SHOW_SNACKBAR } from "../../utils/types";
import { useTranslation } from "react-i18next";
import LocationPicker from "react-leaflet-location-picker";

function CreateWhatsAppTemplate({
  setIsShow,
  selectedTemplate,
  isEdit,
  isView,
  onAdd,
  tenantId,
  waba,
}) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [carouselTemplate, setCarouselTemplate] = useState([
    { buttons: [{ type: "QUICK_REPLY" }], format: "IMAGE" },
  ]);
  const [mapLocation, setMapLocation] = useState({
    latitude: 21.4292064,
    longitude: 50.852764,
  });
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [template, setTemplate] = useState({
    templateType: "GENERIC",
    category: "MARKETING",
    language: "en_US",
    header: {
      type: "HEADER",
      format: "",
    },
    body: "",
    footer: "",
    buttons: [],
  });

  useEffect(() => {
    const tpl = {};
    const carouselTpl = [];
    if (isEdit) {
      selectedTemplate?.components?.map((c) => {
        switch (c.type.toLowerCase()) {
          case "header":
            tpl["header"] = {
              type: "HEADER",
              format: c.format,
              text: c.text,
            };
            if (c.format && c?.example) {
              if (c.format === "TEXT" && Array.isArray(c?.example?.header_text))
                tpl.header.headerVariables = c.example.header_text.map((h) => {
                  return { text: h };
                });
              else if (Array.isArray(c?.example?.header_handle)) {
                tpl.header["example"] = c.example;
              }
            }
            if (c.location) {
              tpl.header["location"] = c.location;
              setMapLocation(c.location);
            }
            break;
          case "body":
            tpl["body"] = c.text;
            if (selectedTemplate.messageType === "AUTHENTICATION") {
              tpl["add_security_recommendation"] =
                c.add_security_recommendation;
              tpl["body"] = `{{1}} is your verification code. ${
                c.add_security_recommendation
                  ? "For your security, do not share this code."
                  : ""
              }`;
            }
            if (c?.example && Array.isArray(c?.example?.body_text)) {
              tpl.bodyVariables = c.example.body_text[0].map((h) => {
                return { text: h };
              });
            }
            break;
          case "footer":
            tpl["footer"] = c.text;
            if (selectedTemplate.messageType === "AUTHENTICATION") {
              tpl["code_expiration_minutes"] = c.code_expiration_minutes;
              if (c.code_expiration_minutes) {
                tpl["isExpireIn"] = true;
                tpl["footer"] = `This code expires in ${
                  c.code_expiration_minutes ?? ""
                } minutes.`;
              }
            }
            break;
          case "buttons":
            tpl["buttons"] = c.buttons;
            if (c.buttons?.length > 0) {
              c.buttons.map((b) => {
                if (b.type === "URL" && Array.isArray(b.example)) {
                  b["variableValues"] = b.example;
                  b["isVariable"] = true;
                }
              });
            }
            break;
          case "carousel":
            c.cards?.map((crl) => {
              const cul = {};
              crl.components?.map((p) => {
                switch (p.type.toLowerCase()) {
                  case "header":
                    cul["format"] = p.format;
                    if (
                      p.format &&
                      p?.example &&
                      Array.isArray(p?.example?.header_handle)
                    ) {
                      cul["example"] = p.example;
                    }
                    break;
                  case "body":
                    cul["body"] = p.text;
                    break;
                  case "buttons":
                    cul["buttons"] = p.buttons;
                    if (p.buttons?.length > 0) {
                      p.buttons.map((b) => {
                        if (p.type === "URL" && Array.isArray(p.example)) {
                          p["variableValues"] = p.example;
                          b["isVariable"] = true;
                        }
                      });
                    }
                    break;
                }
              });
              carouselTpl.push(cul);
            });
            break;
        }
      });
      setTemplate({
        ...tpl,
        name: selectedTemplate.name,
        language: selectedTemplate.language,
        templateType: selectedTemplate.templateType,
        category: selectedTemplate.messageType,
        languageDirection: whatsAppLanguages.find(
          (f) => f.key == selectedTemplate.language
        )?.direction,
      });
      setCarouselTemplate(carouselTpl);
    }
  }, [selectedTemplate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const components = [];
      if (template.category === "AUTHENTICATION") {
        components.push({
          type: "BODY",
          add_security_recommendation: template.add_security_recommendation,
          //code_expiration_minutes: template.code_expiration_minutes
          example: {
            body_text: [["123456"]],
          },
        });
        if (template.code_expiration_minutes) {
          components.push({
            type: "FOOTER",
            code_expiration_minutes: template.code_expiration_minutes,
          });
        }
        components.push({
          type: "BUTTONS",
          buttons: [
            {
              type: "OTP",
              otp_type: "COPY_CODE",
            },
          ],
        });
      } else if (template.templateType === "CAROUSEL") {
        const body = {
          type: "BODY",
          text: template.body,
        };
        if (template?.bodyVariables?.length > 0) {
          body["example"] = {
            body_text: [template?.bodyVariables?.map((b) => b.text)],
          };
        }
        components.push(body);
        const cards = [];
        carouselTemplate.map((m) => {
          const comp = [];
          if (m?.format && m.format != "") {
            const header = {
              type: "HEADER",
              format: m?.format,
            };
            if (m.example && Object.keys(m.example).length > 0) {
              header.example = m.example;
            }
            comp.push(header);
          }
          comp.push({
            type: "BODY",
            text: m.body,
          });
          m.buttons?.map((b, bi) => {
            if (!b.type) {
              dispatch({
                type: SHOW_SNACKBAR,
                payload: {
                  type: "error",
                  message: `Please add at least one button in tab ${bi + 1}`,
                  open: true,
                },
              });
              throw new Error(
                `Please add at least one button in tab ${bi + 1}`
              );
            }
            if (b.type === "URL" && b.variableValues?.length > 0) {
              b["example"] = b.variableValues;
            }
          });
          comp.push({
            type: "BUTTONS",
            buttons: m.buttons,
          });
          cards.push({
            components: comp,
          });
        });
        components.push({
          type: "CAROUSEL",
          cards: cards,
        });
      } else {
        if (template.header?.format && template.header?.format != "") {
          const header = {
            type: "HEADER",
            format: template.header?.format,
          };
          if (template.header?.format === "TEXT") {
            header["text"] = template.header.text;
            if (template.header?.headerVariables?.length > 0) {
              header["example"] = {
                header_text: template.header?.headerVariables?.map(
                  (h) => h.text
                ),
              };
            }
          } else if (template.header?.format === "LOCATION") {
            const address = await getAddress(
              template.header?.location?.latitude,
              template.header?.location?.longitude
            );
            template.header.location.address = address.display_name;
            template.header.location.name = address.name;
            header["location"] = template.header.location;
          } else if (
            template.header?.example &&
            Object.keys(template.header?.example).length > 0
          ) {
            header["example"] = template.header?.example;
          }
          components.push(header);
        }
        const body = {
          type: "BODY",
          text: template.body,
        };
        if (template?.bodyVariables?.length > 0) {
          body["example"] = {
            body_text: [template?.bodyVariables?.map((b) => b.text)],
          };
        }
        components.push(body);
        if (template.footer) {
          components.push({
            type: "FOOTER",
            text: template.footer,
          });
        }
        if (template.buttons?.length > 0) {
          template.buttons.map((b) => {
            if (b.type === "URL" && b.variableValues?.length > 0) {
              b["example"] = b.variableValues;
            }
          });
          components.push({
            type: "BUTTONS",
            buttons: template.buttons,
          });
        }
      }
      const Request = {
        tenantId: tenantId,
        tenantWabaId: waba.id,
        waba: waba,
        name: template.name,
        language: template.language,
        messageType: template.category,
        templateType: template.templateType,
        components: components,
      };
      if (isEdit) {
        Request["referenceId"] = selectedTemplate?.referenceId;
        Request["id"] = selectedTemplate?.id;
        dispatch(
          whatsAppMsgTemplateUpdate(Request, (a) => {
            onAdd(a);
            setIsShow(false);
          })
        );
      } else {
        dispatch(
          whatsAppMsgTemplateCreate(Request, (a) => {
            onAdd(a);
            setIsShow(false);
          })
        );
      }
    } catch {}
  };

  const getButtonTemplate = (b, index) => {
    switch (b?.type) {
      case "PHONE_NUMBER":
        return (
          <div className="grid grid-cols-2 gap-2 w-full">
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                required
                value={b?.text}
                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight"
                placeholder={t("Enter button title")}
                onChange={(e) => {
                  template.buttons[index].text = e.target.value;
                  setTemplate({ ...template, buttons: [...template?.buttons] });
                }}
                data-maxlength={25}
                onInput={OnInput}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                  {b?.text?.length}/25
                </p>
              </div>
            </div>
            <div className="flex items-center rounded-md relative">
              <input
                type="number"
                required
                value={b?.phone_number}
                className="w-full bg-white text-gray-700 rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 sm:text-sm"
                placeholder="Enter phone number with country code"
                onChange={(e) => {
                  template.buttons[index].phone_number = e.target.value;
                  setTemplate({ ...template, buttons: [...template?.buttons] });
                }}
                data-maxlength={14}
                onInput={OnInput}
              />
            </div>
          </div>
        );
      case "QUICK_REPLY":
        return (
          <div className="grid grid-cols-2 gap-2 w-full">
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                required
                value={b?.text}
                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight"
                placeholder={t("Enter button title")}
                onChange={(e) => {
                  template.buttons[index].text = e.target.value;
                  setTemplate({ ...template, buttons: [...template?.buttons] });
                }}
                data-maxlength={25}
                onInput={OnInput}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                  {b?.text?.length}/25
                </p>
              </div>
            </div>
          </div>
        );
      case "URL":
        return (
          <div className="flex flex-col gap-2">
            <div className="relative rounded-md shadow-sm">
              <input
                autoComplete="false"
                required
                value={b?.text}
                type="text"
                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight"
                placeholder={t("Enter button title")}
                onChange={(e) => {
                  template.buttons[index].text = e.target.value;
                  setTemplate({ ...template, buttons: [...template?.buttons] });
                }}
                data-maxlength={25}
                onInput={OnInput}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                  {b?.text?.length}/25
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center rounded-md relative">
                <span className="absolute ml-[2px] rtl:mr-0.5 flex justify-center items-center p-2 bg-gray-100 h-[92%] rounded-l-md rtl:rounded-r-md text-sm text-gray-500">
                  https://
                </span>
                <input
                  required
                  autoComplete="false"
                  //disabled={b?.isVariable}
                  value={b?.url?.replace("https://", "")}
                  type="text"
                  className="w-full bg-white text-gray-700 pl-[75px] rtl:pr-[75px] rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 sm:text-sm"
                  placeholder="Enter URL"
                  onChange={(e) => {
                    template.buttons[index].url = e.target.value?.includes(
                      "https://"
                    )
                      ? e.target.value
                      : `https://${e.target.value ?? ""}`;
                    setTemplate({
                      ...template,
                      buttons: [...template?.buttons],
                    });
                  }}
                />
              </div>
              <div>
                <div className="flex justify-end visible">
                  {b?.isVariable ? (
                    <input
                      type="text"
                      required
                      value={
                        Array.isArray(b?.variableValues)
                          ? b?.variableValues[0]
                          : ""
                      }
                      placeholder="Type URL variable value"
                      name="header-variable-value"
                      className="w-full text-[14px] border-gray-300 rounded-md bg-white"
                      onChange={(e) => {
                        template.buttons[index].variableValues = [
                          e.target.value,
                        ];
                        setTemplate({
                          ...template,
                          buttons: [...template?.buttons],
                        });
                      }}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        template.buttons[index].url =
                          (template?.buttons[index].url ?? "") + "{{1}}";
                        template.buttons[index].isVariable = true;
                        setTemplate({
                          ...template,
                          buttons: [...template?.buttons],
                        });
                      }}
                      className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8] hover:bg-[#ffeeee]"
                    >
                      <i className="fa-solid fa-plus"></i>
                      {t("Add Variables")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      case "COPY_CODE":
        break;
    }
  };

  const getcarouselButtonTemplate = (b, index) => {
    switch (b?.type) {
      case "PHONE_NUMBER":
        return (
          <div className="grid grid-cols-2 gap-2 w-full">
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                required
                value={b?.text}
                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight"
                placeholder={t("Enter button title")}
                onChange={(e) => {
                  carouselTemplate[carouselIndex].buttons[index].text =
                    e.target.value;
                  setCarouselTemplate([...carouselTemplate]);
                }}
                data-maxlength={25}
                onInput={OnInput}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                  {b?.text?.length}/25
                </p>
              </div>
            </div>
            <div className="flex items-center rounded-md relative">
              <input
                type="number"
                required
                value={b?.phone_number}
                className="w-full bg-white text-gray-700 rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 sm:text-sm"
                placeholder={t("Enter phone number with country code")}
                onChange={(e) => {
                  carouselTemplate[carouselIndex].buttons[index].phone_number =
                    e.target.value;
                  setCarouselTemplate([...carouselTemplate]);
                }}
                data-maxlength={14}
                onInput={OnInput}
              />
            </div>
          </div>
        );
      case "QUICK_REPLY":
        return (
          <div className="grid grid-cols-2 gap-2 w-full">
            <div className="relative rounded-md shadow-sm">
              <input
                type="text"
                required
                value={b?.text}
                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight"
                placeholder={t("Enter button title")}
                onChange={(e) => {
                  carouselTemplate[carouselIndex].buttons[index].text =
                    e.target.value;
                  setCarouselTemplate([...carouselTemplate]);
                }}
                data-maxlength={25}
                onInput={OnInput}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                  {b?.text?.length}/25
                </p>
              </div>
            </div>
          </div>
        );
      case "URL":
        return (
          <div className="flex flex-col gap-2">
            <div className="relative rounded-md shadow-sm">
              <input
                autoComplete="false"
                required
                value={b?.text}
                type="text"
                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight"
                placeholder="Enter button title"
                onChange={(e) => {
                  carouselTemplate[carouselIndex].buttons[index].text =
                    e.target.value;
                  setCarouselTemplate([...carouselTemplate]);
                }}
                data-maxlength={25}
                onInput={OnInput}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                  {b?.text?.length}/25
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center rounded-md relative">
                <span className="absolute ml-[2px] rtl:mr-0.5 flex justify-center items-center p-2 bg-gray-100 h-[92%] rounded-l-md rtl:rounded-r-md text-sm text-gray-500">
                  https://
                </span>
                <input
                  autoComplete="false"
                  required
                  //disabled={b?.isVariable}
                  value={b?.url?.replace("https://", "")}
                  type="text"
                  className="w-full bg-white text-gray-700 pl-[75px] rtl:pr-[75px] rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 sm:text-sm"
                  placeholder="Enter URL"
                  onChange={(e) => {
                    carouselTemplate[carouselIndex].buttons[index].url =
                      e.target.value?.includes("https://")
                        ? e.target.value
                        : `https://${e.target.value ?? ""}`;
                    setCarouselTemplate([...carouselTemplate]);
                  }}
                />
              </div>
              <div>
                <div className="flex justify-end visible">
                  {b?.isVariable ? (
                    <input
                      type="text"
                      required
                      value={
                        Array.isArray(b?.variableValues)
                          ? b?.variableValues[0]
                          : ""
                      }
                      placeholder={t("Type URL variable value")}
                      name="header-variable-value"
                      className="w-full text-[14px] border-gray-300 rounded-md bg-white"
                      onChange={(e) => {
                        carouselTemplate[carouselIndex].buttons[
                          index
                        ].variableValues = [e.target.value];
                        setCarouselTemplate([...carouselTemplate]);
                      }}
                    />
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        carouselTemplate[carouselIndex].buttons[index].url =
                          (carouselTemplate[carouselIndex]?.buttons[index]
                            .url ?? "") + "{{1}}";
                        carouselTemplate[carouselIndex].buttons[
                          index
                        ].isVariable = true;
                        setCarouselTemplate([...carouselTemplate]);
                      }}
                      className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                    >
                      <i className="fa-solid fa-plus"></i>
                      {t("Add Variables")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  const updateAuthenticationValue = (
    add_security_recommendation,
    isExpireIn,
    code_expiration_minutes,
    category
  ) => {
    setTemplate({
      ...template,
      category: category,
      code_expiration_minutes: code_expiration_minutes,
      add_security_recommendation: add_security_recommendation,
      isExpireIn: isExpireIn,
      body: `{{1}} is your verification code. ${
        add_security_recommendation
          ? "For your security, do not share this code."
          : ""
      }`,
      footer: isExpireIn
        ? `This code expires in ${code_expiration_minutes ?? ""} minutes.`
        : null,
      buttons: [{ type: "COPY_CODE", text: "Copy Code" }],
      header: {},
      bodyVariables: [{ text: "" }],
    });
  };
  const updateVariableIndex = (text = "", index) => {
    if (!text) return text;
    let txt = text?.replace(`{{${index + 1}}}`, "");
    txt?.match(/{{[0-9]}}/g)?.map((m, i) => {
      txt = txt.replace(m, `{{${i + 1}}}`);
    });
    return txt;
  };

  const bodyVariableChange = (text = "") => {
    if (!text) return text;
    text?.match(/{{[0-9]}}/g)?.map((m, i) => {
      text = text.replace(m, `{{${i + 1}}}`);
    });
    return text;
  };

  const updateVariableIndexBodyChange = (text = "") => {
    const arr = [];
    if (text) {
      text?.match(/{{[0-9]}}/g)?.map((m, i) => {
        arr.push(i);
      });
    }
    return arr;
  };

  const insertText = (index, text, newText) => {
    if (text?.length >= 1024) return text;
    return `${text.slice(0, index)}${newText}${text.slice(index)}`;
  };

  const onEnterTemplateName = (e) => {
    let name = e.target.value?.toLowerCase()?.replace(/ /g, "_");
    const regex = /^[a-z][a-z0-9_]*$/;
    if (!name || regex.test(name)) {
      setTemplate({ ...template, name: name });
    } else if (name) {
      setTemplate({ ...template, name: name?.replace(e.nativeEvent.data, "") });
    }
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };

  const disabledSaveBtn = () => {
    if (template?.category !== "AUTHENTICATION") {
      if (template.body?.length < 1) return true;
      if (template?.templateType === "GENERIC") {
        if (template?.header?.format === "TEXT" && !template?.header?.text)
          return true;
        if (
          ["DOCUMENT", "VIDEO", "IMAGE"].includes(template?.header?.format) &&
          (!Array.isArray(template?.header?.example?.header_handle) ||
            template?.header?.example?.header_handle?.length === 0)
        )
          return true;
        if (template?.buttons?.some((b) => !b.text)) return true;
      }
      if (template?.templateType === "CAROUSEL") {
        if (
          carouselTemplate?.some(
            (s) =>
              s.buttons?.length < 1 ||
              s.buttons?.some((b) => !b.text) ||
              !Array.isArray(s.example?.header_handle) ||
              s.example?.header_handle?.length === 0
          )
        )
          return true;
      }
    }
    return false;
  };
  const pointMode = {
    banner: true,
    control: {
      values: [[mapLocation.latitude, mapLocation.longitude]],
      onClick: async (point) => {
        setMapLocation({ latitude: point[0], longitude: point[1] });
        template.header["location"] = {
          latitude: point[0],
          longitude: point[1],
        };
        setTemplate({ ...template });
      },
      onRemove: (point) =>
        console.log("I've just been clicked for removal :(", point),
    },
  };
  const circleMode = { banner: false };
  return (
    <form
      disabled={isView}
      id="createWhatsAppTemplate"
      className="height30 flex flex-col justify-between pb-3"
      onSubmit={handleSubmit}
    >
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-4 border p-3 rounded-lg mb-4">
          <div className="col-span-8">
            <div className="" style={{ height: "calc(-150px + 100vh)" }}>
              <div className="grid grid-cols-12 gap-3 w-full">
                <div className="col-span-6">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                      {t("Template Name")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                  </div>
                  <input
                    disabled={isEdit}
                    type="text"
                    placeholder={t("Enter Name")}
                    value={template?.name}
                    className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] inputEqHeight"
                    required
                    onChange={onEnterTemplateName}
                    data-maxlength={50}
                    onInput={OnInput}
                  />
                </div>
                <div className="col-span-6">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                      {t("Language")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                  </div>
                  <select
                    disabled={isEdit}
                    required
                    value={template?.language}
                    className="border w-full text-[14px] border-gray-300 rounded-md py-2 px-4 cursor-pointer inputEqHeight"
                    onChange={(e) => {
                      setTemplate({
                        ...template,
                        language: e.target.value,
                        languageDirection: whatsAppLanguages.find(
                          (f) => f.key == e.target.value
                        )?.direction,
                      });
                    }}
                  >
                    {whatsAppLanguages.map((l, i) => (
                      <option value={l.key}>{l.value}</option>
                    ))}
                  </select>
                </div>
                <div className="col-span-6">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                      {t("Template Type")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                  </div>
                  <select
                    disabled={isEdit}
                    value={template?.templateType}
                    required
                    className="border w-full text-[14px] border-gray-300 rounded-md py-2 px-4 cursor-pointer inputEqHeight"
                    onChange={(e) => {
                      if (e.target.value === "GENERIC") {
                        setTemplate({
                          ...template,
                          templateType: e.target.value,
                          category: "MARKETING",
                          code_expiration_minutes: false,
                          add_security_recommendation: false,
                          expireIn: null,
                          body: null,
                          footer: null,
                          buttons: [],
                          header: {},
                          bodyVariables: null,
                        });
                      } else {
                        setTemplate({
                          ...template,
                          templateType: e.target.value,
                          category: "MARKETING",
                          code_expiration_minutes: false,
                          add_security_recommendation: false,
                          expireIn: null,
                          body: null,
                          footer: null,
                          buttons: [],
                          header: {},
                          bodyVariables: null,
                        });
                      }
                    }}
                  >
                    {whatsAppTemplateTypes.map((wt, i) => (
                      <option value={wt.key} key={i} selected={i === 0}>
                        {wt.value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-6">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                      {t("Category")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                  </div>
                  <select
                    disabled={isEdit}
                    required
                    value={template?.category}
                    className="border w-full text-[13px] border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                    onChange={(e) => {
                      if (e.target.value === "AUTHENTICATION") {
                        updateAuthenticationValue(
                          false,
                          false,
                          null,
                          e.target.value
                        );
                      } else {
                        setTemplate({
                          ...template,
                          category: e.target.value,
                          code_expiration_minutes: false,
                          add_security_recommendation: false,
                          expireIn: null,
                          body: null,
                          footer: null,
                          buttons: [],
                          header: { format: "" },
                          bodyVariables: null,
                        });
                      }
                    }}
                  >
                    {whatsAppTemplateTypes
                      .filter((f) => f.key == template.templateType)[0]
                      ?.categories?.map((cat, i) => (
                        <option value={cat.key} key={i} selected={i === 0}>
                          {cat.value}
                        </option>
                      ))}
                  </select>
                </div>
                {template?.category === "AUTHENTICATION" ? (
                  <>
                    <div className="col-span-12">
                      <div className="text-midBlack text-[13px] font-normal mb-1 flex justify-between">
                        <label>
                          {t("Body")}
                          <span className="text-red-500 text-[14px]">*</span>
                        </label>
                        <label className="pr-2">
                          {template?.body?.length ?? 0}/1024
                        </label>
                      </div>
                      <textarea
                        disabled
                        placeholder="{{verification_code}} is your verification code."
                        value={template?.body}
                        className="border border-gray-300 rounded-md p-1 w-[100%] bg-gray-100 text-gray-500"
                        required
                        rows={4}
                        data-maxlength={1024}
                        onInput={OnInput}
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center">
                        <input
                          id="add_security_recommendation"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          onChange={(e) => {
                            updateAuthenticationValue(
                              e.target.checked === true,
                              template?.isExpireIn,
                              template?.code_expiration_minutes,
                              template?.category
                            );
                          }}
                          checked={template?.add_security_recommendation}
                        />
                        <label
                          htmlFor="add_security_recommendation"
                          className="ms-2 text-sm font-medium text-gray-900"
                        >
                          {t("Add security recommendation")}
                          <br />
                          <span className="text-[12px] text-gray-400">
                            {t(
                              "By enabling security recommendation will show a additional security text in the message body."
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="flex items-center">
                        <input
                          id="code_isExpireIn"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          onChange={(e) => {
                            updateAuthenticationValue(
                              template?.add_security_recommendation,
                              e.target.checked === true,
                              template?.code_expiration_minutes,
                              template?.category
                            );
                          }}
                          checked={template?.isExpireIn}
                        />
                        <label
                          htmlFor="code_isExpireIn"
                          className="ms-2 text-sm font-medium text-gray-900"
                        >
                          {t("Add expiration time for the code")}
                          <br />
                          <span className="text-[12px] text-gray-400">
                            {t(
                              "After the code expires, the autofill button will be disabled."
                            )}
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t("Expire in")}</label>
                      </div>
                      <div className="flex items-center rounded-md relative">
                        <input
                          type="number"
                          disabled={!template?.isExpireIn}
                          placeholder={t("Enter Value")}
                          value={template?.code_expiration_minutes}
                          className="w-full bg-white py-1 pl-2 rounded-md border border-gray-300  focus:ring-primary focus:border-primary placeholder-gray-400 text-sm"
                          required
                          onChange={(e) => {
                            updateAuthenticationValue(
                              template?.add_security_recommendation,
                              template?.isExpireIn,
                              e.target.value,
                              template?.category
                            );
                          }}
                          data-maxlength={2}
                          onInput={OnInput}
                        />
                        <span className="absolute mr-0.5 rtl:ml-0.5 h-[92%] right-0 rtl:left-0 flex justify-center items-center px-2 bg-gray-100 rounded-r-md rtl:rounded-l-md text-sm text-gray-500">
                          Minutes
                        </span>
                      </div>
                      <div className="h-1">
                        {template?.code_expiration_minutes > 90 && (
                          <label className="text-[12px] text-[#f10808]">
                            {t("Maximum time limit is 90 minutes.")}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="col-span-6">
                      <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t("Button Text")}</label>
                      </div>
                      <input
                        type="text"
                        disabled
                        placeholder={"Copy Code"}
                        className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%] bg-gray-100"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {template?.templateType !== "CAROUSEL" && (
                      <>
                        <div className="col-span-12">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t("Header Type (Optional)")}</label>
                          </div>
                          <select
                            value={template?.header?.format}
                            className="border w-full text-[14px] border-gray-300 rounded-md py-2 px-4 cursor-pointer inputEqHeight"
                            onChange={(e) => {
                              setTemplate({
                                ...template,
                                header: {
                                  ...template.header,
                                  format: e.target.value,
                                  example: {
                                    filename: null,
                                    thumbnailUrl: null,
                                  },
                                },
                              });
                            }}
                          >
                            <option value="" selected className="text-gray-500">
                              {t("None")}
                            </option>
                            <option value="TEXT">{t("Text")}</option>
                            <option value="DOCUMENT">{t("Document")}</option>
                            <option value="IMAGE">{t("Image")}</option>
                            <option value="VIDEO">{t("Video")}</option>
                            <option value="LOCATION">{t("Location")}</option>
                          </select>
                        </div>
                        <div className="col-span-12">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            {template?.header?.format === "TEXT" ? (
                              <div className="text-midBlack text-[13px] font-normal mb-1 flex justify-between">
                                <label>{t("Header Text")}</label>
                                <label className="pr-2">
                                  {template?.header?.text?.length ?? 0}/60
                                </label>
                              </div>
                            ) : (
                              <label>
                                {template?.header?.format?.toLowerCase()}
                              </label>
                            )}
                          </div>
                          {template?.header?.format === "TEXT" ? (
                            <>
                              <input
                                type="text"
                                dir={template?.languageDirection}
                                value={template?.header?.text}
                                placeholder="Type your header text"
                                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] inputEqHeight"
                                data-maxlength={60}
                                onInput={OnInput}
                                required
                                onChange={(e) => {
                                  setTemplate({
                                    ...template,
                                    header: {
                                      ...template.header,
                                      text: e.target.value,
                                    },
                                  });
                                }}
                              />
                              {template?.header?.headerVariables?.length >=
                              1 ? (
                                <input
                                  maxLength={60}
                                  type="text"
                                  value={
                                    Array.isArray(
                                      template.header?.headerVariables
                                    )
                                      ? template.header?.headerVariables[0]
                                          ?.text
                                      : ""
                                  }
                                  placeholder={`Type header variable value`}
                                  className="border mt-2 w-full flex-grow border-gray-300 py-2 focus:border-none text-sm rounded-md bg-white"
                                  onChange={(e) => {
                                    template.header.headerVariables[0].text =
                                      e.target.value;
                                    setTemplate({ ...template });
                                  }}
                                />
                              ) : (
                                <div className="flex justify-end visible mt-1">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (
                                        !Array.isArray(
                                          template?.header?.headerVariables
                                        )
                                      ) {
                                        template.header.headerVariables = [];
                                      }
                                      template.header?.headerVariables?.push({
                                        text: "",
                                      });
                                      template.header.text =
                                        (template.header.text ?? "") +
                                        `{{${
                                          template.header?.headerVariables
                                            ?.length ?? 1
                                        }}}`;
                                      setTemplate({ ...template });
                                    }}
                                    className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                                  >
                                    <i className="fa-solid fa-plus"></i>
                                    {t("Add Variables")}
                                  </button>
                                </div>
                              )}
                            </>
                          ) : template?.header?.format === "LOCATION" ? (
                            <div className="mapDesign">
                              <LocationPicker
                                pointMode={pointMode}
                                circleMode={circleMode}
                              />
                            </div>
                          ) : (
                            <FileUploader
                              wabaId={waba?.id}
                              tenantId={tenantId}
                              isSessionUploder={true}
                              type={template?.header?.format}
                              selectedFileUrl={
                                template.header?.example?.thumbnailUrl
                              }
                              name={template.header?.example?.filename}
                              filePrefix="template_media"
                              onUploaded={(file) => {
                                template.header["example"] = {
                                  ...file,
                                  header_handle: [file?.h],
                                };
                                setTemplate({ ...template });
                              }}
                            />
                          )}
                        </div>
                        <div className="col-span-12">
                          <div className="text-midBlack text-[13px] font-normal mb-1 flex justify-between">
                            <label>{t("Body")}</label>
                            <label className="pr-2">
                              {template?.body?.length ?? 0}/1024
                            </label>
                          </div>
                          <div className="relative">
                            <textarea
                              dir={template?.languageDirection}
                              value={template?.body}
                              className="border border-gray-300 rounded-md p-1 w-[100%] text-[14px] skipped"
                              required
                              rows={4}
                              data-maxlength={1024}
                              onInput={OnInput}
                              onChange={(e) => {
                                const tv = updateVariableIndexBodyChange(
                                  e.target.value
                                );
                                if (!Array.isArray(template?.bodyVariables)) {
                                  template.bodyVariables = [];
                                }
                                const len = template.bodyVariables?.length;
                                if (tv?.length > len) {
                                  template.bodyVariables[len] = { text: "" };
                                } else if (len > tv?.length) {
                                  template.bodyVariables?.splice(len - 1, 1);
                                }
                                setTemplate({
                                  ...template,
                                  body: bodyVariableChange(e.target.value),
                                });
                              }}
                              onClick={(e) =>
                                setTemplate({
                                  ...template,
                                  bodyTextCurrentIndex: e.target.selectionStart,
                                })
                              }
                              onKeyUp={(e) =>
                                setTemplate({
                                  ...template,
                                  bodyTextCurrentIndex: e.target.selectionStart,
                                })
                              }
                            ></textarea>
                            <div style={{ position: "absolute", zIndex: 1000 }}>
                              <EmojiPicker
                                open={showEmojiPicker}
                                autoFocusSearch
                                onEmojiClick={(em) => {
                                  //setTimeout(() => {
                                  setTemplate({
                                    ...template,
                                    body: insertText(
                                      template.bodyTextCurrentIndex ?? 0,
                                      template.body ?? "",
                                      em.emoji
                                    ),
                                    bodyTextCurrentIndex:
                                      (template.bodyTextCurrentIndex ?? 0) +
                                      em.emoji?.length,
                                  });
                                  //}, 1);
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end visible gap-2">
                            <button
                              disabled={template?.body?.length >= 1024}
                              type="button"
                              onClick={() => {
                                setShowEmojiPicker(!showEmojiPicker);
                              }}
                              className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                            >
                              <i className="fa-solid fa-plus"></i>
                              {showEmojiPicker ? "Hide" : "Add"} Emoji
                            </button>
                            <button
                              disabled={template?.body?.length >= 1024}
                              type="button"
                              onClick={() => {
                                if (!Array.isArray(template?.bodyVariables)) {
                                  template.bodyVariables = [];
                                }
                                template.bodyVariables.push({ text: "" });
                                setTemplate({
                                  ...template,
                                  body: insertText(
                                    template.bodyTextCurrentIndex ?? 0,
                                    template.body ?? "",
                                    `{{${
                                      template?.bodyVariables?.length ?? 1
                                    }}}`
                                  ),
                                  bodyVariables: [...template?.bodyVariables],
                                });
                              }}
                              className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                            >
                              <i className="fa-solid fa-plus"></i>
                              {t("Add Variables")}
                            </button>
                          </div>
                        </div>
                        {template?.bodyVariables?.length > 0 && (
                          <div className="col-span-12 border-t border-gray-300">
                            <div className="text-gray-800 text-[13px] font-normal my-2 flex justify-between">
                              <label>{t("Sample Variable Value")}</label>
                            </div>
                            {template?.bodyVariables?.map((b, i) => (
                              <div
                                key={i}
                                className="flex gap-2 w-full items-center"
                              >
                                <div className="text-indigo-400">
                                  {`{{${i + 1}}}`}
                                </div>
                                <div className="group relative w-full h-fit m-[2px]">
                                  <input
                                    dir={template?.languageDirection}
                                    required
                                    maxLength={60}
                                    type="text"
                                    value={b.text}
                                    placeholder={`Type value for ${i + 1}`}
                                    className="border skipped w-full flex-grow border-gray-300 py-2 focus:border-none text-sm rounded-md bg-white"
                                    onChange={(e) => {
                                      template.bodyVariables[i].text =
                                        e.target.value;
                                      setTemplate({ ...template });
                                    }}
                                  />
                                  <button
                                    type="button"
                                    onClick={() => {
                                      template.bodyVariables?.splice(i, 1);
                                      template.body = updateVariableIndex(
                                        template.body,
                                        i
                                      );
                                      setTemplate({ ...template });
                                    }}
                                    className="items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow absolute hidden group-hover:block top-[-8px] right-[-8px] hover:bg-red-500 hover:text-white h-fit rounded-full border text-[#374151] border-gray-300 bg-white p-0.5"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={24}
                                      height={24}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="lucide lucide-x w-3.5 h-3.5 "
                                    >
                                      <path d="M18 6 6 18" />
                                      <path d="m6 6 12 12" />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        <div className="col-span-12">
                          <div className="text-midBlack text-[13px] font-normal mb-1 flex justify-between">
                            <label>{t("Footer(Optional)")}</label>
                            <label className="pr-2">
                              {template?.footer?.length ?? 0}/60
                            </label>
                          </div>
                          <input
                            type="text"
                            dir={template?.languageDirection}
                            value={template?.footer}
                            placeholder="Type your footer text"
                            className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] inputEqHeight"
                            data-maxlength={60}
                            onInput={OnInput}
                            onChange={(e) => {
                              setTemplate({
                                ...template,
                                footer: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="col-span-12">
                          <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>{t("Button Properties")}</label>
                          </div>
                          <div className="bg-[#EFF6FF] p-2 flex">
                            <i className="fa-solid fa-circle-info text-[#60A5FA] pt-1"></i>
                            <div className="text-[13px] text-[#1E40AF] ml-2">
                              {t(
                                "You can add up to 10 buttons of different types together, with maximum of 1 Call Phone Number type, 2 URL type and 7 sequence type. How you arrange the buttons can impact the template approval process! Ad Sequence type buttons one after another. For example, (Sequence, Sequence, URL, Call Phone Number) or (URL, Call Phone Number, Sequence, Sequence). Avoid arrangements such as (Sequence, URL, Call Phone Number, Sequence). And 2 URL type buttons will always show one after another to the recipient."
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-12">
                          <div className="flex flex-col gap-3">
                            {template?.buttons?.map((b, i) => (
                              <div className="w-full" key={i}>
                                <div className="flex-1 w-full border flex flex-col gap-2 border-gray-300 rounded-md p-3 button-container">
                                  <div className="flex justify-between gap-2">
                                    <div className="flex-1">
                                      <div className="relative">
                                        <select
                                          required
                                          value={b?.type}
                                          className="border w-full text-[14px] border-gray-300 rounded-md py-2 px-4 cursor-pointer inputEqHeight"
                                          onChange={(e) => {
                                            template.buttons[i].type =
                                              e.target.value;
                                            setTemplate({
                                              ...template,
                                              buttons: [...template?.buttons],
                                            });
                                          }}
                                        >
                                          <option value="QUICK_REPLY">
                                            {t("Quick Reply")}
                                          </option>
                                          <option
                                            value="PHONE_NUMBER"
                                            disabled={template?.buttons?.some(
                                              (s) => s.type === "PHONE_NUMBER"
                                            )}
                                          >
                                            {t("Call Phone Number")}
                                            <span className="text-[12px] text-gray-300">
                                              {t("(Can be added once)")}
                                            </span>
                                          </option>
                                          <option
                                            value="URL"
                                            disabled={
                                              template?.buttons?.filter(
                                                (s) => s.type === "URL"
                                              ).length >= 2
                                            }
                                          >
                                            {t("URL")}
                                            <label className="text-[12px] text-gray-300">
                                              {t("(Can be added twice)")}
                                            </label>
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <span className="flex-1 flex justify-end items-center">
                                      <button
                                        type="button"
                                        className="rounded-md px-3 py-2 border border-gray-300 bg-white hover:text-theme"
                                        onClick={() => {
                                          template?.buttons?.splice(i, 1);
                                          setTemplate({
                                            ...template,
                                            buttons: [...template?.buttons],
                                          });
                                        }}
                                      >
                                        <i className="fa-solid fa-trash-can"></i>
                                      </button>
                                    </span>
                                  </div>
                                  {getButtonTemplate(b, i)}
                                </div>
                              </div>
                            ))}
                          </div>
                          {template?.buttons?.length < 10 && (
                            <button
                              type="button"
                              onClick={() =>
                                setTemplate({
                                  ...template,
                                  buttons: [
                                    ...template?.buttons,
                                    { type: "QUICK_REPLY" },
                                  ],
                                })
                              }
                              className="px-3 py-2 my-2 flex gap-2 items-center border rounded-md border-gray-300 hover:text-theme hover:border-theme text-sm font-medium text-gray-700"
                            >
                              <i className="fa-solid fa-plus"></i>
                              {t("Add Buttons")}
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
                {template?.templateType === "CAROUSEL" && (
                  <>
                    <div className="col-span-12">
                      <div className="text-midBlack text-[13px] font-normal mb-1 flex justify-between">
                        <label>{t("Body")}</label>
                        <label className="pr-2">
                          {template?.body?.length ?? 0}/1024
                        </label>
                      </div>
                      <div className="relative">
                        <textarea
                          dir={template?.languageDirection}
                          value={template?.body}
                          className="border border-gray-300 rounded-md p-1 w-[100%] text-[14px] skipped"
                          required
                          rows={4}
                          data-maxlength={1024}
                          onInput={OnInput}
                          onChange={(e) => {
                            const tv = updateVariableIndexBodyChange(
                              e.target.value
                            );
                            if (!Array.isArray(template?.bodyVariables)) {
                              template.bodyVariables = [];
                            }
                            const len = template.bodyVariables?.length;
                            if (tv?.length > len) {
                              template.bodyVariables[len] = { text: "" };
                            } else if (len > tv?.length) {
                              template.bodyVariables?.splice(len - 1, 1);
                            }
                            setTemplate({
                              ...template,
                              body: bodyVariableChange(e.target.value),
                            });
                          }}
                          onClick={(e) =>
                            setTemplate({
                              ...template,
                              bodyTextCurrentIndex: e.target.selectionStart,
                            })
                          }
                          onKeyUp={(e) =>
                            setTemplate({
                              ...template,
                              bodyTextCurrentIndex: e.target.selectionStart,
                            })
                          }
                        ></textarea>
                        <div style={{ position: "absolute", zIndex: 1000 }}>
                          <EmojiPicker
                            open={showEmojiPicker}
                            autoFocusSearch
                            onEmojiClick={(em) => {
                              //setTimeout(() => {
                              setTemplate({
                                ...template,
                                body: insertText(
                                  template.bodyTextCurrentIndex ?? 0,
                                  template.body ?? "",
                                  em.emoji
                                ),
                                bodyTextCurrentIndex:
                                  (template.bodyTextCurrentIndex ?? 0) +
                                  em.emoji?.length,
                              });
                              //}, 1);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex justify-end visible gap-2">
                        <button
                          type="button"
                          onClick={() => {
                            setShowEmojiPicker(!showEmojiPicker);
                          }}
                          className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                        >
                          <i className="fa-solid fa-plus"></i>
                          {showEmojiPicker ? "Hide" : "Add"} {t("Emoji")}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            if (!Array.isArray(template?.bodyVariables)) {
                              template.bodyVariables = [];
                            }
                            template.bodyVariables.push({ text: "" });
                            setTemplate({
                              ...template,
                              body: insertText(
                                template.bodyTextCurrentIndex ?? 0,
                                template.body ?? "",
                                `{{${template?.bodyVariables?.length ?? 1}}}`
                              ),
                              bodyVariables: [...template?.bodyVariables],
                            });
                          }}
                          className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                        >
                          <i className="fa-solid fa-plus"></i>
                          {t("Add Variables")}
                        </button>
                      </div>
                    </div>
                    {template?.bodyVariables?.length > 0 && (
                      <div className="col-span-12 border-t border-gray-300">
                        <div className="text-gray-800 text-[13px] font-normal my-2 flex justify-between">
                          <label>{t("Sample Variable Value")}</label>
                        </div>
                        {template?.bodyVariables?.map((b, i) => (
                          <div
                            key={i}
                            className="flex gap-2 w-full items-center"
                          >
                            <div className="text-indigo-400">{`{{${
                              i + 1
                            }}}`}</div>
                            <div className="group relative w-full h-fit">
                              <input
                                dir={template?.languageDirection}
                                required
                                maxLength={60}
                                type="text"
                                value={b.text}
                                placeholder={`Type value for ${i + 1}`}
                                className="border mt-2 w-full flex-grow border-gray-300 py-2 focus:border-none text-sm rounded-md bg-white"
                                onChange={(e) => {
                                  template.bodyVariables[i].text =
                                    e.target.value;
                                  setTemplate({ ...template });
                                }}
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  template.bodyVariables?.splice(i, 1);
                                  template.body = updateVariableIndex(
                                    template.body,
                                    i
                                  );
                                  setTemplate({ ...template });
                                }}
                                className="items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow absolute hidden group-hover:block top-[-8px] right-[-8px] hover:bg-red-500 hover:text-white h-fit rounded-full border text-[#374151] border-gray-300 bg-white p-0.5"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="lucide lucide-x w-3.5 h-3.5 "
                                >
                                  <path d="M18 6 6 18" />
                                  <path d="m6 6 12 12" />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="col-span-12">
                      <div className="flex gap-2">
                        {carouselTemplate?.map((c, i) => {
                          if (i == 0)
                            return (
                              <div className="group relative w-fit rounded-md h-fit">
                                <button
                                  type="button"
                                  onClick={() => setCarouselIndex(i)}
                                  className={
                                    "inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 px-4 py-2 text-gray-700 border border-gray-300 shadow-sm h-10 w-10 " +
                                    (i === carouselIndex
                                      ? " bg-theme text-white"
                                      : " bg-white hover:bg-gray-50")
                                  }
                                >
                                  {1}
                                </button>
                              </div>
                            );
                          else
                            return (
                              <div className="group relative w-fit rounded-md h-fit">
                                <button
                                  type="button"
                                  onClick={() => setCarouselIndex(i)}
                                  className={
                                    "inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 px-4 py-2 text-gray-700 border border-gray-300 shadow-sm h-10 w-10 " +
                                    (i === carouselIndex
                                      ? " bg-theme text-white"
                                      : " bg-white hover:bg-gray-50")
                                  }
                                >
                                  {i + 1}
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    carouselTemplate?.splice(i, 1);
                                    setCarouselIndex(0);
                                    setCarouselTemplate([...carouselTemplate]);
                                  }}
                                  className="items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow absolute hidden group-hover:block top-[-8px] right-[-8px] hover:bg-red-500 hover:text-white h-fit rounded-full border text-[#374151] border-gray-300 bg-white p-0.5"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-x w-3.5 h-3.5 "
                                  >
                                    <path d="M18 6 6 18" />
                                    <path d="m6 6 12 12" />
                                  </svg>
                                </button>
                              </div>
                            );
                        })}
                        {carouselTemplate?.length < 10 && (
                          <button
                            type="button"
                            className="inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 px-4 py-2 bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 hover:text-textPrimary shadow-sm h-10 w-10"
                            onClick={() => {
                              setCarouselTemplate([
                                ...carouselTemplate,
                                {
                                  buttons: [{ type: "QUICK_REPLY", text: "" }],
                                  format: "IMAGE",
                                  example: {},
                                },
                              ]);
                            }}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        )}
                      </div>
                      <div className="bg-[#EFF6FF] p-2 flex">
                        <div className="text-[13px] text-[#1E40AF] ml-2">
                          {t(
                            "design up to 10 captivating carousels, maintaining consistency in media header format and button types. Remember, Meta approval relies on this compliance so keep it uniform for a hassle-free validation process."
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t("Header Type")}</label>
                      </div>
                      <div className="flex gap-3">
                        <div
                          className={
                            "flex gap-3 items-center border p-2 rounded-md " +
                            (carouselTemplate[carouselIndex]?.format !== "VIDEO"
                              ? "border-theme"
                              : "border-gray-300")
                          }
                        >
                          <input
                            checked={
                              carouselTemplate[carouselIndex]?.format ===
                              "IMAGE"
                            }
                            id="carosuel-card-header-image"
                            type="radio"
                            className="without-ring h-4 w-4 text-theme border-gray-300"
                            name="carosuel-card-header"
                            onChange={(e) => {
                              carouselTemplate[carouselIndex].format =
                                e.target.checked === true ? "IMAGE" : "VIDEO";
                              setCarouselTemplate([...carouselTemplate]);
                            }}
                          />
                          <label
                            htmlFor="carosuel-card-header-image"
                            className="flex gap-2 text-sm font-medium text-gray-700"
                          >
                            <i className="fa-solid fa-image text-[18px] hover:text-theme mt-0.5"></i>
                            <span className="text-sm font-medium">
                              {t("Image")}
                            </span>
                          </label>
                        </div>
                        <div
                          className={
                            "flex gap-3 items-center border p-2 rounded-md " +
                            (carouselTemplate[carouselIndex]?.format === "VIDEO"
                              ? "border-theme"
                              : "border-gray-300")
                          }
                        >
                          <input
                            id="carosuel-card-header-video"
                            checked={
                              carouselTemplate[carouselIndex]?.format ===
                              "VIDEO"
                            }
                            type="radio"
                            className="without-ring h-4 w-4 text-theme border-gray-300"
                            name="carosuel-card-header"
                            onChange={(e) => {
                              carouselTemplate[carouselIndex].format =
                                e.target.checked === true ? "VIDEO" : "IMAGE";
                              setCarouselTemplate([...carouselTemplate]);
                            }}
                          />
                          <label
                            htmlFor="carosuel-card-header-video"
                            className="flex gap-2 text-sm font-medium text-gray-700"
                          >
                            <i className="fa-solid fa-video text-[18px] hover:text-theme mt-0.5"></i>
                            <span className="text-sm font-medium">
                              {t("Video")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t("image")}</label>
                      </div>
                      <div className="border border-gray-400 rounded-md border-dashed">
                        {
                          <FileUploader
                            wabaId={waba?.id}
                            tenantId={tenantId}
                            isSessionUploder={true}
                            selectedFileUrl={
                              carouselTemplate[carouselIndex]?.example
                                ?.thumbnailUrl
                            }
                            name={
                              carouselTemplate[carouselIndex]?.example?.filename
                            }
                            type={carouselTemplate[carouselIndex]?.format}
                            filePrefix="template_media"
                            onUploaded={(file) => {
                              carouselTemplate[carouselIndex].example = {
                                ...file,
                                header_handle: [file?.h],
                              };
                              setCarouselTemplate([...carouselTemplate]);
                            }}
                          />
                        }
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="text-midBlack text-[13px] font-normal mb-1 flex justify-between">
                        <label>{t("Carousel Text")}</label>
                        <label className="pr-2">
                          {carouselTemplate[carouselIndex]?.body?.length ?? 0}
                          /160
                        </label>
                      </div>
                      <textarea
                        dir={template?.languageDirection}
                        value={carouselTemplate[carouselIndex]?.body ?? ""}
                        className="border border-gray-300 text-[14px] rounded-md p-1 w-[100%]"
                        required
                        rows={2}
                        data-maxlength={160}
                        onInput={OnInput}
                        onChange={(e) => {
                          carouselTemplate[carouselIndex].body = e.target.value;
                          setCarouselTemplate([...carouselTemplate]);
                        }}
                      ></textarea>
                    </div>
                    <div className="col-span-12">
                      <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t("Button Properties")}</label>
                      </div>
                      <div className="bg-[#EFF6FF] p-2 flex">
                        <i className="fa-solid fa-circle-info text-[#60A5FA] pt-1"></i>
                        <div className="text-[13px] text-[#1E40AF] ml-2">
                          {t(
                            "You have to add at least 1 button and you can add up to two buttons. Once you add buttons in the first card, you can't change button type or delete button from subsequent cards."
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-span-12">
                      <div className="flex flex-col gap-3">
                        {carouselTemplate[carouselIndex]?.buttons?.map(
                          (b, i) => (
                            <div className="w-full" key={i}>
                              <div className="flex-1 w-full border flex flex-col gap-2 border-gray-300 rounded-md p-3 button-container">
                                <div className="flex justify-between gap-2">
                                  <div className="flex-1">
                                    <div className="relative">
                                      <select
                                        value={b?.type}
                                        className="border w-full text-[14px] border-gray-300 rounded-md py-2 px-4 cursor-pointer inputEqHeight"
                                        onChange={(e) => {
                                          carouselTemplate[
                                            carouselIndex
                                          ].buttons[i].type = e.target.value;
                                          setCarouselTemplate([
                                            ...carouselTemplate,
                                          ]);
                                        }}
                                      >
                                        <option value="QUICK_REPLY">
                                          {t("Quick Reply")}
                                        </option>
                                        <option
                                          value="PHONE_NUMBER"
                                          disabled={carouselTemplate[
                                            carouselIndex
                                          ]?.buttons?.some(
                                            (s) => s.type === "PHONE_NUMBER"
                                          )}
                                        >
                                          {t("Call Phone Number")}
                                          <span className="text-[12px] text-gray-300">
                                            {t("(Can be added once)")}
                                          </span>
                                        </option>
                                        <option
                                          value="URL"
                                          disabled={
                                            carouselTemplate[
                                              carouselIndex
                                            ]?.buttons?.filter(
                                              (s) => s.type === "URL"
                                            ).length >= 2
                                          }
                                        >
                                          {t("URL")}
                                          <label className="text-[12px] text-gray-300">
                                            {t("(Can be added twice)")}
                                          </label>
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  {i !== 0 && (
                                    <span className="flex-1 flex justify-end items-center">
                                      <button
                                        type="button"
                                        className="rounded-md px-3 py-2 border border-gray-300 bg-white hover:text-theme"
                                        onClick={() => {
                                          carouselTemplate[
                                            carouselIndex
                                          ]?.buttons?.splice(i, 1);
                                          setCarouselTemplate([
                                            ...carouselTemplate,
                                          ]);
                                        }}
                                      >
                                        <i className="fa-solid fa-trash-can"></i>
                                      </button>
                                    </span>
                                  )}
                                </div>
                                {getcarouselButtonTemplate(b, i)}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      {carouselTemplate[carouselIndex]?.buttons?.length < 2 && (
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              !Array.isArray(
                                carouselTemplate[carouselIndex].buttons
                              )
                            ) {
                              carouselTemplate[carouselIndex].buttons = [];
                            }
                            carouselTemplate[carouselIndex].buttons.push({
                              type: "PHONE_NUMBER",
                            });
                            setCarouselTemplate([...carouselTemplate]);
                          }}
                          className="px-3 py-2 mt-2 flex gap-2 items-center border rounded-md border-gray-300 hover:text-theme text-sm font-medium text-gray-700"
                        >
                          <i className="fa-solid fa-plus"></i>
                          {t("Add Buttons")}
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="relative flex flex-col gap-2 items-center bg-white px-3 col-span-4"
            style={{ height: "calc(-130px + 100vh)" }}
          >
            <h1 className="text-lg w-[94%] max-w-xs font-medium leading-7 capitalize text-gray-900">
              {t("Preview")}
            </h1>
            <WhatsAppTemplatePreview
              waba={waba}
              wabaIco={waba?.teamImage}
              chatPhone={`+${waba.phoneCode} ${waba.phone}`}
              template={template}
              carouselTemplate={carouselTemplate}
            />
          </div>
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        {!isView && (
          <div className="flex justify-end gap-2 my-2">
            <button
              disabled={disabledSaveBtn()}
              type="submit"
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              {t("Save & Request Approval")}
            </button>
          </div>
        )}
      </div>
    </form>
  );
}

export default CreateWhatsAppTemplate;
