import React, { useEffect, useState } from "react";
import {
  broadcastStatusColor,
  getPercentage,
  msgStatusColor,
  msgStatusTextColor,
} from "../../utils/helper";
import WhatsAppTemplatePreview from "../../components/WhatsAppTemplatePreview";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignReport } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import Tooltip from "@mui/material/Tooltip";
import { setSelectedTemplateMessage } from "../../utils/TemplateHelper";
import { useTranslation } from "react-i18next";

function BroadcastReport({ setIsShow, campaign, waba }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [broadcast, setBroadcast] = useState({});
  const [Contacts, setContacts] = useState([]);
  const [carouselTemplate, setCarouselTemplate] = useState([]);
  const [template, setTemplate] = useState({
    templateType: "GENERIC",
    category: "MARKETING",
    language: "English",
    header: { type: "HEADER", format: "" },
    body: "",
    footer: "",
    buttons: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone =
    waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(
      getCampaignReport(campaign.tenantId, campaign.id, (w) => {
        setBroadcast(w);
        setTotalPages(Math.ceil(w?.contacts?.length / itemParPage));
        onSelectTemplate(w?.template, w?.messageText);
      })
    );
  }, []);

  useEffect(() => {
    if (broadcast?.contacts?.length > 0) {
      const startIndex = (currentPage - 1) * itemParPage;
      const endIndex = startIndex + itemParPage;
      setContacts(broadcast?.contacts.slice(startIndex, endIndex));
    }
  }, [currentPage, broadcast?.contacts]);

  const onSearchText = (text) => {
    if (text) {
      const txt = text?.toLowerCase();
      const con = broadcast?.contacts?.filter(
        (f) =>
          f.name?.toLowerCase()?.includes(txt) ||
          f.whatsappNumber?.includes(txt) || f.status?.toLowerCase()?.includes(txt)
      );
      setContacts(con?.slice(0, itemParPage));
      setTotalPages(Math.ceil(con?.length / itemParPage));
    } else {
      setContacts(broadcast?.contacts.slice(0, itemParPage));
      setTotalPages(Math.ceil(broadcast?.contacts?.length / itemParPage));
    }
  };

  const onSelectTemplate = (st, messageText) => {
    const messageValue = JSON.parse(messageText ?? "{}");
    setSelectedTemplateMessage(
      st,
      messageValue,
      setTemplate,
      setCarouselTemplate
    );
  };

  const exportReport = () => {
    const tab1 = [
      {
        Scheduled: broadcast?.totalScheduled,
        Delivered: broadcast?.totalDelivered,
        Read: broadcast?.totalRead,
        Replied: broadcast?.totalReplied,
        Failed: broadcast?.totalFailed,
        Invalid: broadcast?.totalInvalid,
      },
    ];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(tab1);
    const worksheet1 = XLSX.utils.json_to_sheet(broadcast?.contacts);
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `Broadcast +${waba?.phoneCode} ${waba?.phone}`
    );
    XLSX.utils.book_append_sheet(workbook, worksheet1, "Contacts");
    XLSX.writeFile(workbook, "broadcast_report.xlsx");
  };
  const showReport =
    campaign?.campaignType?.toUpperCase() === "INTERNAL" ||
    (campaign?.campaignType?.toUpperCase() === "EXTERNAL" &&
      globalReducer.loginInfo?.roleDetails?.some(
        (s) => s.name === "TENANT_SUPER_ADMIN"
      ));
  return (
    <div className="height0 flex flex-col pb-[0px]">
      <div className="py-2.5 px-4 border-b-[1px] sticky top-0 w-full text-[16px] flex items-center justify-between  border-[#F0F1F3]">
        <div className="text-[14px] font-medium text-black">
          <div>
            {campaign?.campaignName}{" "}
            <label
              className={`p-1 mr-1 text-[12px] rounded-lg bg-[${broadcastStatusColor[campaign?.status?.toLowerCase()]
                }]`}
            >
              {campaign?.status ?? "-"}
            </label>
            <span className="text-gray-400">{t("sent from")}</span> +
            {waba?.phoneCode} {waba?.phone}{" "}
            <span className="text-gray-400">on</span>{" "}
            {moment
              .utc(campaign?.scheduledTime)
              .tz(wabaTimezone)
              .format("YYYY-MM-DD hh:mm A") ?? "-"}
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => {
              setIsShow(false);
            }}
            type="button"
            className="text-gray-400 z-50 bg-transparent hover:bg-gray-200 hover:text-black rounded text-sm w-8 h-8 inline-flex items-center justify-center"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">{t("Close menu")}</span>
          </button>
        </div>
      </div>

      <div className="height30 relative flex flex-col justify-between pb-3">
        <div className="h-dvh overflow-y-auto p-4">
          <div>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-8">
                <div className="pb-2 font-semibold">
                  <label>{t("Broadcast Statistics")}</label>
                </div>
                <div className="grid grid-cols-2 gap-1.5">
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Scheduled")}
                        <Tooltip title="Total Scheduled Messages" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg`}
                      ></label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalScheduled)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Queued")}
                        <Tooltip title="Total Queued Messages" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#D8FAE2] px-2`}
                      >
                        <span className={`text-[#488260]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalScheduled,
                            broadcast?.totalQueued
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalQueued)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Delivered")}
                        <Tooltip title="Total Delivered Messages" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#D8FAE2] px-2`}
                      >
                        <span className={`text-[#488260]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalScheduled,
                            broadcast?.totalDelivered
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalDelivered)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Read")}
                        <Tooltip title="Read Messages" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#EBE6FB] px-2`}
                      >
                        <span className={`text-[#704ac9]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalDelivered,
                            broadcast?.totalRead
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalRead)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Replied")}
                        <Tooltip title="Total Replied Messages" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#EBE6FB] px-2`}
                      >
                        <span className={`text-[#704ac9]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalScheduled,
                            broadcast?.totalReplied
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalReplied)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Failed")}
                        <Tooltip title="Total Failed Messages" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#F7E3E1] px-2`}
                      >
                        <span className={`text-[#ea4f4f]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalScheduled,
                            broadcast?.totalFailed
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalFailed)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Invalid")}
                        <Tooltip title="Invalid Contact" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#F7E3E1] px-2`}
                      >
                        <span className={`text-[#ea4f4f]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalScheduled,
                            broadcast?.totalInvalid
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalInvalid)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Opted Out")}
                        <Tooltip title="Opted Out Contact" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                      <label
                        className={`p-1 mr-1 text-[12px] rounded-lg bg-[#F7E3E1] px-2`}
                      >
                        <span className={`text-[#ea4f4f]`}>
                          {" "}
                          {getPercentage(
                            broadcast?.totalScheduled,
                            broadcast?.totalOptedOut
                          )}
                        </span>
                      </label>
                    </div>
                    <label>{new Intl.NumberFormat("en-US").format(broadcast?.totalOptedOut)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Amount Spent")}
                        <Tooltip title="Amount Spent" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                    </div>
                    <label className="text-[14px]">{globalReducer.waba?.currencyCode}{" "} {parseFloat(broadcast?.amountSpent ?? 0).toFixed(3)}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Cost per Read")}
                        <Tooltip title="Cost per Read" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                    </div>
                    <label className="text-[14px]">{globalReducer.waba?.currencyCode}{" "} {broadcast?.totalReplied && broadcast?.totalReplied !== 0 ? parseFloat((broadcast?.amountSpent ?? 0) / (broadcast?.totalReplied ?? 0)).toFixed(3) : "0"}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Cost per Click")}
                        <Tooltip title="Cost per Click based on the total clicks for all the buttons" arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                    </div>
                    <label className="text-[14px]">{globalReducer.waba?.currencyCode}{" "} {broadcast?.outboundClicks && broadcast?.outboundClicks !== 0 ? parseFloat((broadcast?.amountSpent ?? 0) / (broadcast?.outboundClicks ?? 0)).toFixed(3) : "0"}</label>
                  </div>
                  <div className="px-2 py-2 bg-[#f7f8fc] rounded-lg flex flex-col py-0.5 border-[1px]">
                    <div className="flex text-gray-600 justify-between">
                      <div>
                        {t("Click-through Rate")}
                        <Tooltip title="Click-through Rate – based on the total clicks for all the buttons " arrow>
                          <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                        </Tooltip>
                      </div>
                    </div>
                    <label className="text-[14px]">{broadcast?.totalDelivered && broadcast?.totalDelivered !== 0 ? parseFloat(((broadcast?.outboundClicks ?? 0) / (broadcast?.totalDelivered ?? 0)) / 100) : "0"} %</label>
                  </div>
                  <div className="col-span-2 px-2 text-[14px]">
                    <label className="font-bold">Note: </label>
                    <label className="text-gray-500">The Delivered and Read counts displayed here may be lower than the actual numbers in Meta Business Manager due to small variations in data processing. Credit adjustments will be made to the customer's account every 15 days.</label>
                  </div>
                </div>
              </div>
              <div className="col-span-4">
                <div className="overflow-auto z-[1] scrollthumb h-full">
                  <div className="h-full">
                    <WhatsAppTemplatePreview
                      timezone={wabaTimezone}
                      waba={waba}
                      wabaIco={waba?.teamImage}
                      chatPhone={`+${waba?.phoneCode} ${waba?.phone}`}
                      template={template}
                      carouselTemplate={carouselTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
            {showReport && (
              <>
                <div className="py-4 flex justify-between">
                  <div className="pl-1">
                    <input
                      type="text"
                      placeholder={t("Search Contacts")}
                      className="text-gray-500 py-[4px] px-[12px] text-[14px] h-9 font-normal border-gray-300 w-full min-w-[350px] rounded"
                      onChange={(e) => {
                        onSearchText(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => exportReport()}
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]"
                  >
                    {t("Download as Report")}
                  </button>
                </div>
                <div className="mt-1">
                  <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                    <thead className="text-[14px] text-midBlack bg-gray-100 sticky top-[-16px]">
                      <tr>
                        <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                          {t('Sr. No.')}
                        </th>
                        <th scope="col" className="font-[500] py-3 pl-2">
                          {t("Name")}
                        </th>
                        <th scope="col" className="font-[500]">
                          {t("Phone")}
                        </th>
                        <th scope="col" className="font-[500] w-[160px]">
                          {t("Status")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Contacts?.map((p, i) => {
                        return (
                          <tr className="bg-white border-b" key={i}>
                            <td className="py-3 text-[13px] pl-2">
                              {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                            </td>
                            <td className={"py-3 text-[13px] pl-2 font-normal"}>
                              {p?.name ?? "-"}
                            </td>
                            <td className="py-3 font-normal text-[13px]">
                              +{p?.whatsappNumber ?? "-"}
                            </td>
                            <td className="py-3 font-normal text-[13px]">
                              <label
                                className={`p-1.5 mr-1 text-[12px] rounded-lg bg-[${msgStatusColor[p?.status?.toLowerCase()]
                                  }]`}
                              >
                                <span
                                  className={`text-[${msgStatusTextColor[p?.status?.toLowerCase()]
                                    }]`}
                                >
                                  {" "}
                                  {p?.status?.toLowerCase() === "sent" ? "delivered" : p?.status?.toLowerCase()}
                                </span>
                              </label>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
          {totalPages > 1 && showReport && (
            <div className="flex sm:justify-center">
              <div className="fixed overflow-x-auto bottom-0 mb-[0.5rem]">
                <Pagination
                  page={currentPage}
                  count={totalPages}
                  onChange={(event, page) => setCurrentPage(page)}
                  shape="rounded"
                  color="secondary"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BroadcastReport;
