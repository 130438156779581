import { combineReducers } from "redux";
import globalReducer from "./globalReducer";
import partnerReducer from "./partnerReducer";
import tenantReducer from "./tenantReducer";
import SnackBarReducer from "./SnackBarReducer";
import LoaderReducer from "./LoaderReducer";
import languageReducer from "./languageReducer";

export default combineReducers({
    globalReducer,
    partnerReducer,
    tenantReducer,
    SnackBarReducer,
    LoaderReducer,
    languageReducer
});