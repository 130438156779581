export const whatsAppDoc = (tenantId, apikKey) => {
    return {
        "openapi": "3.0.1",
        "servers": [{ "url": process.env.REACT_APP_PRODUCT_APIURL }],
        "tags": [
            {
                "name": "API",
                "description": "WhatsApp integration journey"
            }
        ],
        "paths": {
            "/oauth2/token?client_id=iomnihub-tenant": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "get token",
                    "description": "Generate token for api access",
                    "operationId": "get-token",
                    "parameters": [
                        {
                            "name": "x-tenant-id",
                            "in": "header",
                            "description": "x-tenant-id id",
                            "required": true,
                            "value": tenantId ? tenantId : "tenantId",
                            "schema": {
                                "type": "string"
                            }
                        },
                        {
                            "name": "x-api-key",
                            "in": "header",
                            "description": "x-api-key Key",
                            "required": true,
                            "value": apikKey ? apikKey : "apikKey",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "access_token": {
                                                "type": "string"
                                            },
                                            "refresh_token": {
                                                "type": "string"
                                            },
                                            "token_type": {
                                                "type": "string"
                                            },
                                            "expires_in": {
                                                "type": "int64"
                                            }
                                        },
                                        "example": {
                                            "access_token": "eyJhbGciOiJSUzI1NiJ9.ufu",
                                            "refresh_token": "eyJhbGciOiJSUzI1NiJ9.eyJh",
                                            "token_type": "bearer",
                                            "expires_in": 72000,
                                        }
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant/contact/client/upsert": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "contact upsert",
                    "description": "create contact",
                    "operationId": "contact-upsert",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Appointment object",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "customerId": {
                                            "type": "string"
                                        },
                                        "name": {
                                            "type": "string"
                                        },
                                        "gender": {
                                            "type": "string",
                                            "default": "Male",
                                            "enum": [
                                                "Male",
                                                "Female",
                                                "Both"
                                            ],
                                            "example": "Male"
                                        },
                                        "whatsappNumber": {
                                            "type": "string",
                                            "pattern": "^\\d10$",
                                            "required": true,
                                            "example": "1234567890"
                                        },
                                        "email": {
                                            "type": "string",
                                            "pattern": "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2, 4}$",
                                            "example": "demo@gmail.com"
                                        },
                                        "location": {
                                            "type": "string"
                                        },
                                        "category": {
                                            "type": "array",
                                            "items": {
                                                "type": "string"
                                            }
                                        },
                                    }
                                }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "id": {
                                                "type": "integer",
                                            },
                                            "customerId": {
                                                "type": "string"
                                            },
                                            "name": {
                                                "type": "string"
                                            },
                                            "gender": {
                                                "type": "string",
                                                "default": "Male",
                                                "enum": [
                                                    "Male",
                                                    "Female",
                                                    "Both"
                                                ],
                                                "example": "Male"
                                            },
                                            "whatsappNumber": {
                                                "type": "string",
                                                "pattern": "^\\d10$",
                                                "required": true,
                                                "example": "1234567890"
                                            },
                                            "email": {
                                                "type": "string",
                                                "pattern": "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2, 4}$",
                                                "example": "demo@gmail.com"
                                            },
                                            "location": {
                                                "type": "string"
                                            },
                                            "category": {
                                                "type": "array",
                                                "items": {
                                                    "type": "string"
                                                }
                                            },
                                        }
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant-waba/client": {
                "get": {
                    "tags": [
                        "API"
                    ],
                    "summary": "get waba accounts",
                    "description": "get waba accounts",
                    "operationId": "get-waba-account",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "responses": {
                        "200": {
                            "description": "successful",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "array",
                                        "items": {
                                            "type": "object",
                                            "properties": {
                                                "id": {
                                                    "type": "integer"
                                                },
                                                "tenantId": {
                                                    "type": "string"
                                                },
                                                "wabaNumber": {
                                                    "type": "string"
                                                },
                                                "phone": {
                                                    "type": "string"
                                                },
                                                "phoneCode": {
                                                    "type": "integer"
                                                },
                                                "teamName": {
                                                    "type": "string"
                                                },
                                                "teamDescription": {
                                                    "type": "string"
                                                },
                                                "countryCode": {
                                                    "type": "string"
                                                },
                                                "timezone": {
                                                    "type": "string"
                                                },
                                                "phoneNumberId": {
                                                    "type": "string"
                                                },
                                                "active": {
                                                    "type": "boolean"
                                                },
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "400": {
                            "description": "Invalid planCode",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/400ApiResponse"
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/storage/media/uploader": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "Upload media file",
                    "description": "Uploads an image or video and returns an upload session",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Multipart file to upload",
                        "required": true,
                        "content": {
                            "multipart/form-data": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "file": { "type": "string", "format": "binary" },
                                        "tenantId": { "type": "string" },
                                        "wabaId": { "type": "string" }
                                    }
                                }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "Media uploaded successfully",
                            "content": {
                                "application/json": {
                                    "schema": { "$ref": "#/components/schemas/UploadSession" }
                                }
                            }
                        },
                        "400": { "description": "Invalid file format" },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "tenant/whatsapp-template/client/get-template": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "Get WhatsApp template",
                    "description": "Fetches a WhatsApp template based on filters",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Template request payload",
                        "required": true,
                        "content": {
                            "application/json": {
                                "schema": { "$ref": "#/components/schemas/TemplateRequest" }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "Template retrieved successfully",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "type": "object",
                                        "properties": {
                                            "data": {
                                                "type": "array",
                                                "items": {
                                                    "$ref": "#/components/schemas/WhatsAppTemplate"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        "403": { "description": "Not allowed" },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant/whatsapp-message/client/send-template-message": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "Send a WhatsApp message",
                    "description": "Sends a WhatsApp message using a predefined template",
                    "operationId": "send-template-message",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Request model for sending a WhatsApp template message",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object",
                                    "properties": {
                                        "whatsappNumber": {
                                            "type": "string",
                                            "description": "WhatsApp number of the recipient",
                                            "example": "918345678910"
                                        },
                                        "to": {
                                            "type": "string",
                                            "description": "Recipient's WhatsApp ID",
                                            "example": "918317618914"
                                        },
                                        "templateName": {
                                            "type": "string",
                                            "description": "Template Name",
                                            "example": "order_confirmation"
                                        },
                                        "components": {
                                            "type": "array",
                                            "items": {
                                                "$ref": "#/components/schemas/component"
                                            }
                                        }
                                    },
                                    "example": {
                                        "whatsappNumber": "919608311111",
                                        "to": "917073811111",
                                        "templateName": "insurance_premium",
                                        "components": [
                                            {
                                                "type": "button",
                                                "index": 0,
                                                "sub_type": "QUICK_REPLY",
                                                "parameters": [
                                                    {
                                                        "type": "text",
                                                        "text": "send"
                                                    }
                                                ]
                                            },
                                            {
                                                "type": "body",
                                                "parameters": [
                                                    {
                                                        "type": "text",
                                                        "text": "₹950000"
                                                    },
                                                    {
                                                        "type": "image",
                                                        "image": {
                                                            "id": "img_001"
                                                        }
                                                    },
                                                    {
                                                        "type": "audio",
                                                        "audio": {
                                                            "id": "audio_001"
                                                        }
                                                    },
                                                    {
                                                        "type": "video",
                                                        "video": {
                                                            "id": "video_001"
                                                        }
                                                    },
                                                    {
                                                        "type": "document",
                                                        "document": {
                                                            "id": "document_001"
                                                        }
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "Message sent successfully",
                            "content": {
                                "application/json": {
                                    "schema": { "$ref": "#/components/schemas/ConversationMessages" }
                                }
                            }
                        },
                        "400": {
                            "description": "Invalid planCode",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/400ApiResponse"
                                    }
                                }
                            }
                        },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "/tenant/whatsapp-message/client/send-message": {
                "post": {
                    "tags": [
                        "API"
                    ],
                    "summary": "Send a WhatsApp message",
                    "description": "Sends a WhatsApp message or Media",
                    "parameters": [
                        {
                            "name": "Authorization",
                            "in": "header",
                            "description": "bearer token",
                            "required": true,
                            "value": "bearer ",
                            "schema": {
                                "type": "string"
                            }
                        }
                    ],
                    "requestBody": {
                        "description": "Message request payload",
                        "required": true,
                        "content": {
                            "application/json": {
                                "schema": { "$ref": "#/components/schemas/SendMessageRequest" }
                            }
                        }
                    },
                    "responses": {
                        "200": {
                            "description": "Message sent successfully",
                            "content": {
                                "application/json": {
                                    "schema": { "$ref": "#/components/schemas/ConversationMessages" }
                                }
                            }
                        },
                        "403": { "description": "Not allowed" },
                        "401": {
                            "description": "Unauthorized",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/UnauthorizedApiResponse"
                                    }
                                }
                            }
                        },
                        "500": {
                            "description": "Internal server error",
                            "content": {
                                "application/json": {
                                    "schema": {
                                        "$ref": "#/components/schemas/500ApiResponse"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        "components": {
            "schemas": {
                "component": {
                    "type": "object",
                    "description": "Message component",
                    "example": {
                        "type": "button",
                        "index": 0,
                        "sub_type": "QUICK_REPLY",
                        "parameters": [
                            {
                                "type": "text",
                                "text": "send"
                            }
                        ]
                    },
                    "properties": {
                        "type": {
                            "type": "string",
                            "example": "body",
                            "enum": [
                                "body",
                                "button",
                                "header",
                                "carousel"
                            ]
                        },
                        "index": {
                            "type": "integer",
                            "example": "0"
                        },
                        "sub_type": {
                            "type": "string",
                            "example": "QUICK_REPLY",
                            "enum": [
                                "URL",
                                "QUICK_REPLY",
                                "PHONE_NUMBER",
                                "COPY_CODE"
                            ]
                        },
                        "parameters": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "type": {
                                        "type": "string",
                                        "default": "text",
                                        "enum": [
                                            "text",
                                            "image",
                                            "video",
                                            "document"
                                        ]
                                    },
                                    "text": {
                                        "type": "string",
                                        "example": "₹950000"
                                    },
                                    "image": {
                                        "$ref": "#/components/schemas/Media"
                                    },
                                    "audio": {
                                        "$ref": "#/components/schemas/Media"
                                    },
                                    "video": {
                                        "$ref": "#/components/schemas/Media"
                                    },
                                    "document": {
                                        "$ref": "#/components/schemas/Media"
                                    }
                                }
                            }
                        },
                        "cards": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "card_index": {
                                        "type": "integer",
                                        "example": "0"
                                    },
                                    "components": {
                                        "type": "array",
                                        "items": {
                                            "$ref": "#/components/schemas/component"
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                "componentWithButton": {
                    "type": "object",
                    "properties": {
                        "type": {
                            "type": "string",
                            "example": "body"
                        },
                        "sub_type": {
                            "type": "string",
                            "example": "url"
                        },
                        "index": {
                            "type": "integer",
                            "example": "1"
                        },
                        "parameters": {
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "type": "object",
                                    "type": {
                                        "type": "string",
                                        "default": "text"
                                    },
                                    "text": {
                                        "type": "string",
                                        "example": "₹950000"
                                    },
                                }
                            }
                        }
                    }
                },
                "UploadSession": {
                    "type": "object",
                    "description": "Represents an upload session response",
                    "properties": {
                        "id": {
                            "type": "string",
                            "description": "The unique ID of the uploaded media",
                            "example": "12345"
                        },
                        "url": {
                            "type": "string",
                            "description": "URL where the media is stored",
                            "example": "https://example.com/media/12345.jpg"
                        },
                        "thumbnailUrl": {
                            "type": "string",
                            "description": "Thumbnail URL (for images/videos)",
                            "example": "https://example.com/thumbnail/12345.png"
                        },
                        "contentType": {
                            "type": "string",
                            "description": "MIME type of the uploaded media",
                            "example": "image/png"
                        }
                    }
                },
                "TemplateRequest": {
                    "type": "object",
                    "description": "Request model for fetching WhatsApp templates",
                    "properties": {
                        "wabaId": { "type": "integer", "required": true, "example": 14 },
                        "filter": {
                            "type": "object",
                            "example": {
                                "status": "APPROVED",
                                "name": "mental_health_123",
                                "referenceId": "2005706529896109",
                                "language": "en_US",
                                "messageType": "MARKETING",
                                "templateType": "GENERIC"
                            }
                        }
                    }
                },
                "WhatsAppTemplate": {
                    "type": "object",
                    "description": "Model representing a WhatsApp Template",
                    "properties": {
                        "id": { "type": "integer", "example": 6 },
                        "tenantId": { "type": "string", "example": "T8003XT9BB3C6" },
                        "tenantWabaId": { "type": "integer", "example": 14 },
                        "referenceId": { "type": "string", "example": "2005706529896109" },
                        "name": { "type": "string", "example": "mental_health_123" },
                        "language": { "type": "string", "example": "en_US" },
                        "messageType": { "type": "string", "example": "MARKETING" },
                        "templateType": { "type": "string", "example": "GENERIC" },
                        "template": { "type": "string", "example": "[{\"type\":\"BODY\",\"text\":\"Take a moment for self-care. Talk to someone you trust, practice mindfulness, or take a break. Your mental health matters 👩‍⚕️👨‍⚕️\"}]" },
                        "status": {
                            "type": "string",
                            "example": "approved",
                            "enum": [
                                "APPROVED",
                                "REJECTED",
                                "REQUESTED"
                            ]
                        },
                        "remarks": { "type": "string", "example": "Reviewed by admin" },
                        "active": { "type": "boolean", "example": true },
                        "createdAt": { "type": "string", "format": "yyy-MM-dd'T'HH:mm:ss", "example": "2025-01-12T10:11:45.893562" }
                    }
                },
                "SendMessageRequest": {
                    "type": "object",
                    "description": "Request model for sending a WhatsApp message",
                    "properties": {
                        "whatsappNumber": {
                            "type": "string",
                            "description": "WhatsApp number of the recipient",
                            "example": "913657980213"
                        },
                        "to": {
                            "type": "string",
                            "description": "Recipient's WhatsApp ID",
                            "example": "913456789023"
                        },
                        "message": {
                            "$ref": "#/components/schemas/Message"
                        }
                    },
                    "required": ["whatsappNumber", "to", "message"]
                },
                "Message": {
                    "type": "object",
                    "description": "Message payload for WhatsApp",
                    "properties": {
                        "type": {
                            "type": "string",
                            "default": "text",
                            "description": "Message type",
                            "example": "text",
                            "enum": [
                                "text",
                                "image",
                                "video",
                                "document"
                            ]
                        },
                        "text": {
                            "$ref": "#/components/schemas/Text"
                        },
                        "image": {
                            "$ref": "#/components/schemas/Media"
                        },
                        "audio": {
                            "$ref": "#/components/schemas/Media"
                        },
                        "video": {
                            "$ref": "#/components/schemas/Media"
                        },
                        "document": {
                            "$ref": "#/components/schemas/Media"
                        }
                    }
                },
                "Text": {
                    "type": "object",
                    "description": "Text message details",
                    "properties": {
                        "body": {
                            "type": "string",
                            "description": "Message body",
                            "example": "Hello, your order has been shipped!"
                        }
                    }
                },
                "Media": {
                    "type": "object",
                    "description": "Media file details",
                    "properties": {
                        "id": {
                            "type": "string",
                            "description": "Media ID",
                            "example": "img_001"
                        }
                    }
                },
                "ConversationMessages": {
                    "type": "object",
                    "description": "Model representing a WhatsApp conversation message",
                    "properties": {
                        "id": {
                            "type": "integer",
                            "description": "Unique message ID",
                            "example": 2250
                        },
                        "tenantId": {
                            "type": "string",
                            "description": "Tenant ID",
                            "example": "T8003XT9BB3C6"
                        },
                        "referenceId": {
                            "type": "string",
                            "description": "Reference ID",
                            "example": "wamid.HBgMOTE3MDczODk2Mjk3FQIAERgSMDlGNkY4REVFOTY5MDgyMjg1AA=="
                        },
                        "messageText": {
                            "type": "string",
                            "description": "Message text content",
                            "example": "{\"name\":null,\"language\":null,\"components\":[{\"type\":\"button\",\"parameters\":[{\"type\":\"text\",\"text\":\"send\"}],\"sub_type\":\"QUICK_REPLY\",\"index\":\"0\"}]}"
                        },
                        "messageStatus": {
                            "type": "string",
                            "description": "Message status",
                            "example": "sent",
                            "enum": [
                                "accepted",
                                "delivered",
                                "sent",
                                "read",
                                "failed",
                                "invalid"
                            ]
                        },
                        "type": {
                            "type": "string",
                            "description": "message type",
                            "example": "template",
                            "enum": [
                                "template",
                                "text",
                                "image",
                                "video",
                                "document"
                            ]
                        }
                    }
                },
                "UnauthorizedApiResponse": {
                    "type": "object",
                    "properties": {
                        "code": {
                            "type": "string",
                            "example": "AUTH4041"
                        },
                        "message": {
                            "type": "string",
                            "example": "user not found"
                        },
                        "description": {
                            "type": "string",
                            "example": "api key does not exist"
                        }
                    }
                },
                "400ApiResponse": {
                    "type": "object",
                    "properties": {
                        "code": {
                            "type": "string",
                            "example": "Z4001"
                        },
                        "message": {
                            "type": "string",
                            "example": "Invalid Argument"
                        },
                        "description": {
                            "type": "string",
                            "example": "Invalid Argument"
                        }
                    }
                },
                "500ApiResponse": {
                    "type": "object",
                    "properties": {
                        "status": {
                            "type": "string",
                            "example": ""
                        },
                        "message": {
                            "type": "string",
                            "example": "Internal server error"
                        },
                        "statusCode": {
                            "type": "string",
                            "example": "Internal server error"
                        }
                    }
                }
            }
        }
    }
};