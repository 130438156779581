import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allPlanPermissions } from "../../utils/helper";
import { useTranslation } from "react-i18next";

function PlanDetails({ setIsShow, details }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [plan, setplan] = useState(details ?? {});
  const [allPermissions, setAllPermissions] = useState(allPlanPermissions);

  return (
    <div>
      <div className="flex px-4">
        <button className="border-b-2 text-[14px] font-medium border-theme pb-1 pt-3">
          {t('Plan Details')}
        </button>
      </div>
      <hr />

      <div className="h-dvh overflow-y-auto p-4">
        <div className="flex flex-col text-xs font-medium text-black bg-white rounded max-w-[438px]">
          <div className="flex gap-5 pr-5 ">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-box-archive text-theme"></i>
              <div>{t('Plan Code:')}</div>
            </div>
            <div className="flex-auto">{plan?.code ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-box-archive text-theme"></i>
              <div>{t('Plan Name:')}</div>
            </div>
            <div className="flex-auto">{plan?.name ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-calendar-days text-theme"></i>
              <div>{t('Tenure(Months)')}:</div>
            </div>
            <div className="flex-auto">{plan?.durationMonth ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-wallet text-theme"></i>
              <div>{t('Amount')}:</div>
            </div>
            <div className="flex-auto">{plan?.currency} {plan?.rate ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-file-invoice text-theme"></i>
              <div>{t('Billing Frequency:')}</div>
            </div>
            <div className="flex-auto">{plan?.billingFrequency ?? "-"}</div>
          </div>
          {/* <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-credit-card text-theme"></i>
                            <div>Credit Limit:</div>
                        </div>
                        <div className="flex-auto">
                            {plan?.creditLimit ?? "-"}
                        </div>
                    </div>
                    <div className="flex gap-5 pr-5 mt-2">
                        <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                            <i className="fa-solid fa-credit-card text-theme"></i>
                            <div>Account Balance:</div>
                        </div>
                        <div className="flex-auto">
                            {plan?.accountBalance ?? "-"}
                        </div>
                    </div> */}
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-globe mt-1 text-theme"></i>
              <div> {t('Country')}:</div>
            </div>
            <div className="flex-auto">{plan?.countryCode ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-globe mt-1 text-theme"></i>
              <div> {t('Currency')}:</div>
            </div>
            <div className="flex-auto">{plan?.currency ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
              <div> {t('Number of Live Chat Agent:')}</div>
            </div>
            <div className="flex-auto">{plan?.numLiveChatAgent ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-regular fa-address-book text-theme mt-1"></i>
              <div> {t('Number of Contacts:')}</div>
            </div>
            <div className="flex-auto">{plan?.numContacts ?? "-"}</div>
          </div>
          {/* <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
              <div> {t('Number of Sessions:')}</div>
            </div>
            <div className="flex-auto">{plan?.numSessions ?? "-"}</div>
          </div> */}
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-users text-theme mt-1"></i>
              <div> {t('Number of Users:')}</div>
            </div>
            <div className="flex-auto">{plan?.numUsers ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-money-check text-theme mt-1"></i>
              <div>{t('Number of WABA Account:')}</div>
            </div>
            <div className="flex-auto">{plan?.numWabaNumbers ?? "-"}</div>
          </div>
          {allPermissions.map((m, i) => (
            <div className="flex gap-5 pr-5 mt-2">
              <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
                <i className="fa-solid fa-universal-access text-theme mt-1"></i>
                <div> {m.value}:</div>
              </div>
              <div className="flex-auto">
                {plan?.permissions?.some((s) => s === m.key) ? "Yes" : "No"}
              </div>
            </div>
          ))}
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-regular fa-flag mt-1 text-theme"></i>
              <div>{t('Status:')}</div>
            </div>
            <div className="flex-auto">
              {plan?.active ? "Active" : "Not Active"}
            </div>
          </div>
          {/* <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-comments mt-1 text-theme"></i>
              <div> {t('Plan Html:')}</div>
            </div>
            <div className="flex-auto">{plan?.planHtml ?? "-"}</div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PlanDetails;
