import React, { useEffect, useState } from "react";
import PopUp from "../../components/PopUp";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import {
  optOutContactBulkUpload,
  getOptOutContacts,
  optOutContactDelete,
  bulkOptOutCContactDelete,
} from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import { DatePicker } from "antd";
import { SHOW_SNACKBAR } from "../../utils/types";
import RightSidePanel from "../../components/RightSidePanel";
import ImportExcel from "../../components/ImportExcel";
import { findCountryByPhone } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import AddOptOutContact from "../../layouts/tenant/AddOptOutContact";
const { RangePicker } = DatePicker;

function OptOutContact({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const [deleteContact, setDeleteContact] = useState(false);
  const [showAddContact, setshowAddContact] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [OptOutContacts, setOptOutContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [dateFilter, setDateFilter] = useState();
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo?.tenantId },
  });
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const columnValidation = [
    { name: "phone", displayName: "Phone", type: "numbar", isMandatory: true },
  ];
  const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    Contactsearch();
  }, [currentPage]);

  const Contactsearch = () => {
    const fl = [];
    if (searchText) {
      fl.push({
        property: "phone",
        operator: "CONTAINS",
        value: searchText
      });
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    dispatch(
      getOptOutContacts(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantId: globalReducer.loginInfo?.tenantId,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
        },
        (d) => {
          setOptOutContacts(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      Contactsearch();
    } else {
      setCurrentPage(1);
    }
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="text-[14px] fa-regular fa-address-card text-theme"></i>
            <div className="text-[14px] font-medium">{t('OptOut Contacts')}</div>
          </div>
          <div className="flex gap-2">
            <div>
              <Dropdown
                inline
                arrowIcon={false}
                inputMode
                as="button"
                className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer"
                label={<div className="text-white bg-theme py-2 px-2"><span>{t("Add Contacts")}</span><i className="fa-regular ml-2 fa-square-caret-down"></i></div>}
              >
                <Dropdown.Item
                  className="hover:text-theme"
                  onClick={() => {
                    setSelectedContact({});
                    setshowAddContact(true);
                  }}>
                  <i className="fa-solid fa-person pr-2"></i>
                  {t('Manual Add')}
                </Dropdown.Item>
                <Dropdown.Item
                  className="hover:text-theme"
                  onClick={() => {
                    setSelectedContact({});
                    setShowBulkUpload(true);
                  }}
                >
                  <i className="fa-solid fa-file-excel pr-2"></i>
                  {t('Upload CSV/Excel')}
                </Dropdown.Item>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Contact Details')}</label>
              </div>
              <input
                type="text"
                placeholder={t('Enter Contact Details')}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => { setSearchText(e.target.value) }}
              />
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        {selectedContacts?.length > 0 && <div className="p-[16px] bg-white rounded-[4px] text-[14px] mt-4 dropdown-shadow absolute w-[290px] min-h-[100px] top-[35px] z-[99] left-[280px]">
          <div className="flex items-center justify-between">
            <div className="font-semibold text-[16px] text-[#374151]">
              {selectedContacts?.length} {t('Contacts selected')}
            </div>
          </div>
          <ul className="mt-2 w-full gap-2 text-[#374151]">
            {/* <li className="w-full py-1.5 px-2 hover:bg-theme hover:text-white bg-gray-50 cursor-pointer" onClick={() =>
              dispatch(changeOptOutContactStatus({ ids: selectedContacts.map(m => m.id), isActive: false }, (a) => {
                Contactsearch();
                setSelectedContacts([]);
              }))
            }>
              <div className="flex items-center gap-2">
                <i className="fa-solid fa-link-slash pr-2"></i>
                <div className="text-[14px]">{t('Deactivate')}</div>
              </div>
            </li> */}
            <li className="w-full py-1.5 px-2 hover:bg-theme hover:text-white bg-gray-50 cursor-pointer" onClick={() => {
              setDeleteContact(true);
            }}>
              <div className="flex items-center gap-2">
                <i className="fa-solid fa-trash-can pr-2"></i>
                <div className="text-[14px]">{t('Delete')}</div>
              </div>
            </li>
          </ul>
        </div>}
        <div className="px-6 py-2 mt-[10.5rem] mb-[20px]">
          {OptOutContacts && OptOutContacts.length > 0 ? (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2"></th>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Phone No.')}</div>
                      </div>
                    </th>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Date Time')}
                    </th>
                    {/* <th scope="col" className="font-[500] w-[160px]">
                      <div className="flex gap-2">
                        <div>{t('Status')}</div>
                      </div>
                    </th> */}
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Action')}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {OptOutContacts.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 pl-3 text-[13px] w-7">
                          <input id="checked-checkbox" type="checkbox" checked={selectedContacts.some((m) => m.id === p.id)} className="w-4 h-4 text-theme cursor-pointer bg-gray-100 border-gray-300 rounded-sm"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedContacts([...selectedContacts, p]);
                              } else {
                                setSelectedContacts(selectedContacts.filter((m) => m.id !== p.id));
                              }
                            }} />
                        </td>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {`+${p?.phoneCode} ${p?.phone}`}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {moment.utc(p?.createdAt).tz(wabaTimezone).format("YYYY-MM-DD hh:mm A")}
                        </td>
                        {/* <td className="py-3 text-[13px] font-normal">
                          {p?.active ? (
                            <label className="bg-[#D4F8D3] p-2 rounded-lg">
                              Active
                            </label>
                          ) : (
                            <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                              Not Active
                            </label>
                          )}
                        </td> */}
                        <td className=" py-3 text-[13px] font-normal">
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            {/*{p?.active ? (
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  dispatch(
                                    changeOptOutContactStatus(
                                      { id: p?.id, isActive: false },
                                      (a) => Contactsearch()
                                    )
                                  );
                                }}
                              >
                                <i className="fa-solid fa-link-slash pr-2"></i>
                                {t('Deactivate')}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  dispatch(
                                    changeOptOutContactStatus(
                                      { id: p?.id, isActive: true },
                                      (a) => Contactsearch()
                                    )
                                  );
                                }}
                              >
                                <i className="fa-solid fa-link pr-2"></i>
                                {t('Activate')}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedContact({ ...p, isEdit: true });
                                setshowAddContact(true);
                              }}
                            >
                              <i className="fa-regular fa-pen-to-square pr-2"></i>
                              {t('Edit')}
                            </Dropdown.Item>*/}
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedContact({ ...p });
                                setDeleteContact(true);
                              }}
                            >
                              <i className="fa-regular fa-pen-to-square pr-2"></i>
                              {t('Delete')}
                            </Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t('The space is empty . Add a Contact to get started.')}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => setshowAddContact(true)}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t('Add Contact')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {selectedContact?.isEdit
              ? t("Update OptOut Contacts")
              : t("Add OptOut Contacts")}
          </span>
        }
        isShowModal={showAddContact}
        setIsShowModal={setshowAddContact}
        ModalBody={
          <AddOptOutContact setIsShow={setshowAddContact} onAdd={Contactsearch} defaultContact={selectedContact}
            isEdit={selectedContact?.isEdit} />
        }
        size="2xl"
      />
      <RightSidePanel
        ModalTitle={"Upload OptOut Contacts"}
        setIsShow={setShowBulkUpload}
        isShow={showBulkUpload}
        downloadFile="optout_contact_template.xlsx"
        fileUrl="/files/optout_contact_template.xlsx"
        Component={
          <div>
            <ImportExcel
              bodyHeight={"h-[82vh]"}
              Title={t('"Upload OptOut Contacts"')}
              setIsShow={setShowBulkUpload}
              columnValidation={columnValidation}
              onImportClick={(con) => {
                let inValid = false;
                const contactList = [];
                con.forEach((m) => {
                  const countryWithPhone = findCountryByPhone(
                    m.phone,
                    globalReducer.country
                  );
                  if (countryWithPhone) {
                    contactList.push({
                      tenantId: globalReducer.loginInfo?.tenantId,
                      phone: countryWithPhone.phoneNumber,
                      phoneCode: countryWithPhone.country.phoneCode,
                    });
                  } else {
                    inValid = true;
                    return;
                  }
                });
                if (inValid) {
                  dispatch({
                    type: SHOW_SNACKBAR,
                    payload: {
                      type: "error",
                      message: "mobile number should be with country code",
                      open: true,
                    },
                  });
                  return;
                }
                dispatch(
                  optOutContactBulkUpload(contactList, (a) => {
                    Contactsearch();
                    setShowBulkUpload(false);
                  })
                );
              }}
            />
          </div>
        }
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
      />
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t('Are you sure you want to delete?')}
            </div>
          </>
        }
        isShowModal={deleteContact}
        setIsShowModal={setDeleteContact}
        ModalBody={
          <>
            <div className="flex justify-end gap-4 p-6">
              <button
                className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                onClick={() => setDeleteContact(false)}
              >
                {t('Cancel')}
              </button>
              <button
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                onClick={() => {
                  if (selectedContacts?.length > 0) {
                    dispatch(
                      bulkOptOutCContactDelete({ ids: selectedContacts.map(m => m.id) }, (a) => {
                        setDeleteContact(false);
                        Contactsearch();
                        setSelectedContacts([]);
                      })
                    );
                  } else {
                    dispatch(
                      optOutContactDelete(selectedContact?.id, (a) => {
                        setDeleteContact(false);
                        Contactsearch();
                      })
                    );
                  }
                }}
              >
                {t('Delete')}
              </button>
            </div>
          </>
        }
        size="md"
      />
    </div>
  );
}

export default OptOutContact;
