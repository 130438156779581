import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryQuery,
  tenantContactUpsert,
} from "../../store/actions/tenantAction";
import MobileInput from "../../components/MobileInput";
import { Autocomplete, TextField } from "@mui/material";
import { getPhoneWithCode } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import { SHOW_SNACKBAR } from "../../utils/types";

function AddContact({
  setIsShow,
  onAdd,
  isEdit = false,
  defaultContact = {},
  onlyGetContact = false,
}) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [Contact, setContact] = useState(defaultContact ?? {});
  const [categories, setCategories] = useState([]);
  const globalReducer = useSelector((state) => state.globalReducer);
  const tenantReducer = useSelector((state) => state.tenantReducer);
  useEffect(() => {
    const filter = [{ clause: "OPEN_BRACKET" },
    {
      property: "permission",
      operator: "CONTAINS",
      value: "PUBLIC",
    },
    { clause: "OR" },
    { clause: "OPEN_BRACKET" },
    {
      property: "permission",
      operator: "CONTAINS",
      value: "PRIVATE",
    },
    { clause: "AND" },
    {
      property: "uid",
      operator: "EQ",
      value: globalReducer.loginInfo?.uid,
    },
    { clause: "CLOSE_BRACKET" },
    { clause: "CLOSE_BRACKET" }];
    if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
      if (filter?.length > 0) {
        filter.push({ clause: "AND" });
      }
      filter.push({ clause: "OPEN_BRACKET" }, {
        property: "categoryName",
        operator: "IN",
        not: true,
        value: "GENERIC_EXTERNAL",
      }, { clause: "CLOSE_BRACKET" });
    }
    dispatch(
      categoryQuery(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: 100,
          filter: filter,
        },
        (d) => {
          setCategories(d?.data ?? []);
        }
      )
    );
    if (!isEdit) {
      const country = globalReducer.country.find(
        (f) => f.code === tenantReducer.tenantDetails.countryCode
      );
      if (country) {
        setContact({ ...Contact, phoneCode: country.phoneCode });
      }
    } else {
      const wa = getPhoneWithCode(
        defaultContact.whatsappNumber,
        globalReducer.country
      );
      setContact({ ...Contact, phoneCode: wa.phoneCode, phone: wa.phone });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEdit) {
      Contact.tenantId = globalReducer.loginInfo?.tenantId;
      Contact.uid = globalReducer.loginInfo?.uid;
    }
    Contact.whatsappNumber = Contact.phoneCode + Contact.phone;
    if (!Contact?.category || Contact?.category?.length < 1) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "please select category",
          open: true,
        },
      });
      return;
    }
    if (onlyGetContact) {
      onAdd(Contact);
      setIsShow(false);
      return;
    }
    dispatch(
      tenantContactUpsert(Contact, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-12 gap-3 w-full">
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Contact No.')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <MobileInput
            className={"py-1 h-10 text-[16px]"}
            required={true}
            countryList={globalReducer.country}
            phoneCode={Contact?.phoneCode}
            phone={Contact?.phone}
            setPhoneCode={(code) => setContact({ ...Contact, phoneCode: code })}
            setPhone={(phone) => setContact({ ...Contact, phone: phone })}
            placeholder={t('Enter Phone Number')}
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label> {t('Customer ID')}</label>
          </div>
          <input
            type="text"
            placeholder={t("Enter customer id")}
            name="name"
            value={Contact?.customerId}
            onChange={(e) =>
              setContact({ ...Contact, customerId: e.target.value })
            }
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label> {t('Name')}</label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Name")}
            name="name"
            value={Contact?.name}
            onChange={(e) => setContact({ ...Contact, name: e.target.value })}
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label> {t('Gender')}</label>
          </div>
          <select
            value={Contact?.gender}
            className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
            onChange={(e) => {
              setContact({ ...Contact, gender: e.target.value });
            }}
          >
            <option value="" selected disabled>
              {t('Select Gender.')}
            </option>
            <option value="Male">{t('Male')}</option>
            <option value="Female">{t('Female')}</option>
            <option value="Other">{t('Other')}</option>
          </select>
        </div><div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Location')}</label>
          </div>
          <input
            type="text"
            placeholder={t("Enter location")}
            name="location"
            value={Contact.location}
            onChange={(e) =>
              setContact({ ...Contact, location: e.target.value })
            }
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Email')}</label>
          </div>
          <input
            type="email"
            placeholder={t("Enter Email")}
            name="email"
            value={Contact.email}
            onChange={(e) => setContact({ ...Contact, email: e.target.value })}
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-12">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Category')}</label>
          </div>
          <Autocomplete
            multiple={true}
            aria-required={true}
            value={categories?.filter((m) =>
              Contact.category?.some((s) => s === m.categoryName)
            )}
            options={categories}
            isOptionEqualToValue={(option, value) =>
              option?.categoryName === value?.categoryName
            }
            getOptionLabel={(option) => option?.categoryName ?? ""}
            onChange={(e, value) => {
              setContact({
                ...Contact,
                categories: value,
                category: value?.map((c) => c.categoryName) ?? [],
              });
            }}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "2px",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Category"
                className="custom-input"
                sx={{
                  "& .MuiInputBase-root": {
                    padding: "2px",
                    height: "40px",
                    "&.Mui-focused fieldset": {
                      borderColor: "none",
                      border: "0px",
                    },
                    fontSize: "14px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#FFFFFF",
                    "& fieldset": {
                      borderColor: "#ccc",
                    },
                    "&:hover fieldset": {
                      borderColor: "#888",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#1976d2",
                    },
                  },
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <button
          type="button"
          className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
          onClick={() => setIsShow(false)}
        >
          {t('Cancel')}
        </button>
        <button
          type="submit"
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
        >
          {t('Save')}
        </button>
      </div>
    </form>
  );
}

export default AddContact;
