import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getTenantUsers,
  changeUserStatus,
  sendTenantCredentials,
} from "../../store/actions/tenantAction";
import { getTenantDetails } from "../../store/actions/partnerAction";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import AddTenantUser from "../AddTenantUser";
import { SHOW_SNACKBAR } from "../../utils/types";
import { useTranslation } from "react-i18next";

function TenantDetails({ setIsShow, details }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tenant, setTenant] = useState(details ?? {});
  const [tenantUsers, setTenantUsers] = useState([]);
  const [primeryUser, setPrimeryUser] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [editUser, setEditUser] = useState(false);

  useEffect(() => {
    dispatch(getTenantDetails({ tenantId: details.id }, setTenant));
    onSearch();
  }, []);

  const onSearch = () => {
    dispatch(
      getTenantUsers(
        {
          tenantId: details.id,
        },
        (a) => {
          setPrimeryUser(a?.data?.filter((f) => f.isPrimary)[0]);
          setTenantUsers(a?.data?.filter((item) => !item.isPrimary));
        }
      )
    );
  };

  return (
    <div className="height30 flex flex-col justify-between pb-3">
      <div className="h-dvh overflow-y-auto p-4">
        <div className="flex flex-col text-[13px] font-medium text-black bg-white rounded">
          <div className="flex gap-5 pr-5 ">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-regular fa-user text-theme"></i>
              <div>{t('Name:')}</div>
            </div>
            <div className="flex-auto">{tenant?.name ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-solid fa-globe mt-1 text-theme"></i>
              <div> {t('Country:')}</div>
            </div>
            <div className="flex-auto">{tenant?.country?.name ?? "-"}</div>
          </div>
          <div className="flex gap-5 pr-5 mt-2">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-regular fa-flag mt-1 text-theme"></i>
              <div> {t('Status:')}</div>
            </div>
            <div className="flex-auto">
              {tenant?.active ? "Active" : "Not Active"}
            </div>
          </div>
          <div className="flex gap-5 pr-5 mt-2 aa">
            <div className="flex gap-1 whitespace-nowrap justify-center items-center ">
              <i className="fa-brands fa-font-awesome mt-1 text-theme"></i>
              <div> {t('Logo:')}</div>
            </div>
            <div className="flex-auto">
              <img
                alt=""
                className="w-fit h-[37.5px] rounded p-1 box-border bg-white"
                src={tenant?.logo}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}/images/default.png`;
                }}
              />
            </div>
          </div>
          {/* <div className="flex gap-5 pr-5 mt-2 whitespace-nowrap justify-center items-center ">
            <div className="flex gap-1">
              <i className="fa-solid fa-location-dot mt-1 text-theme"></i>
              <div>{t('Domain:')}</div>
            </div>
            <div className="flex-auto">{tenant?.domain ?? "-"}</div>
          </div> */}
          <div className="flex gap-5 pr-5 mt-2 whitespace-nowrap justify-center items-center ">
            <div className="flex gap-1">
              <i className="fa-solid fa-location-dot mt-1 text-theme"></i>
              <div>{t('Website:')}</div>
            </div>
            <div className="flex-auto">{tenant?.website ?? "-"}</div>
          </div>
          <div className="flex gap-5 self-start pr-5 mt-2 whitespace-nowrap justify-center items-center ">
            <div className="flex flex-1 gap-1">
              <i className="fa-solid fa-pen-to-square mt-1 text-theme"></i>
              <div>{t('BSP:')}</div>
            </div>
            <div className="my-auto">
              {tenant?.bsp?.id}, {tenant?.bsp?.name}
            </div>
          </div>
          <div className="flex gap-5 self-start pr-5 mt-2 whitespace-nowrap justify-center items-center ">
            <div className="flex flex-1 gap-1">
              <i className="fa-solid fa-pen-to-square mt-1 text-theme"></i>
              <div>{t('Brand Color:')}</div>
            </div>
            <div className="my-auto">
              {tenant?.brandColor1}, {tenant?.brandColor2}
            </div>
          </div>
          <div className="flex gap-5 self-start pr-5 mt-2 whitespace-nowrap justify-center items-center ">
            <div className="flex flex-1 gap-1">
              <i className="fa-solid fa-address-book mt-1 text-theme"></i>
              <div>{t('Contact Details:')}</div>
            </div>
            <div className="my-auto">
              {primeryUser?.name}, {primeryUser?.email}, +
              {tenant?.country?.phoneCode} {primeryUser?.phone}
            </div>
          </div>
        </div>
        <div className="flex mt-4 border-b">
          <button className="border-b-2 text-[14px] font-medium border-theme pb-1 pt-3">
            {t('User Details')}
          </button>
        </div>
        <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
          {tenantUsers?.map((user, j) => {
            return (
              <tr key={j} className="bg-white border-b">
                <td className={"py-3 text-[13px] font-normal"}>
                  {user?.name ?? "---"}
                </td>
                <td className={"py-3 text-[13px] font-normal"}>
                  {user?.email ?? "---"}
                </td>
                <td className={"py-3 text-[13px] font-normal"}>
                  +{user?.phoneCode} {user?.phone ?? "---"}
                </td>
                <td className={"py-3 text-[13px] font-normal"}>
                  {user?.roles?.join(",")}
                </td>
                <td className={"py-3 text-[13px] font-normal"}>
                  {user?.active ? (
                    <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                      Active
                    </label>
                  ) : (
                    <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                      Not Active
                    </label>
                  )}
                </td>
                <td className="py-3 font-normal text-[13px]">
                  <Dropdown
                    inline
                    arrowIcon={false}
                    inputMode
                    as="button"
                    className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                    label={<i className="fa-solid fa-ellipsis"></i>}
                  >
                    {user?.active ? (
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(
                            changeUserStatus(
                              {
                                id: user?.uid,
                                tenantId: tenant.id,
                                isActive: false,
                              },
                              (a) => onSearch()
                            )
                          );
                        }}
                      >
                        {t('Deactivate')}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(
                            changeUserStatus(
                              {
                                id: user?.uid,
                                tenantId: tenant.id,
                                isActive: true,
                              },
                              (a) => onSearch()
                            )
                          );
                        }}
                      >
                        {t('Activate')}
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      onClick={() => {
                        setSelectedUser({ ...user, confirmPassword: user.password });
                        setEditUser(true);
                      }}
                    >
                      {t('Edit')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      dispatch(sendTenantCredentials({ tenantId: tenant.id, uid: user?.uid }, a => {
                        dispatch({ type: SHOW_SNACKBAR, payload: { type: "success", message: "Credentials have been sent to the user.", open: true } });
                      }));
                    }}>
                      {t('Send Credentials')}
                    </Dropdown.Item>
                  </Dropdown>
                </td>
                <td className="w-4"></td>
              </tr>
            );
          })}
        </table>
        <PopUp
          ModalHeader={
            <span className="font-medium text-[18px] text-black">
              {t('Update User')}
            </span>
          }
          isShowModal={editUser}
          setIsShowModal={setEditUser}
          ModalBody={
            <AddTenantUser
              setIsShow={setEditUser}
              onAdd={onSearch}
              defaultUser={selectedUser}
              isEdit={true}
              tenantId={tenant.id}
            />
          }
          size="2xl"
        />
      </div>
    </div>
  );
}

export default TenantDetails;
