import { SWITCH_LANGUAGE } from "../../utils/types";

const initialState = {
    currentLanguage: 'en', // Default language
  };
    
  export const switchLanguage = (language) => ({
    type: SWITCH_LANGUAGE,
    payload: language,
  });
  
  const languageReducer = (state = initialState, action) => {
    switch (action.type) {
      case SWITCH_LANGUAGE:
        return {
          ...state,
          currentLanguage: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default languageReducer;
  