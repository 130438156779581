import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TemplatePreview from "../../components/TemplatePreview";
import { getFileMediaId } from "../../store/actions/globalAction";
import mime from "mime";
import { setSelectedTemplateMessage } from "../../utils/TemplateHelper";

function WhatsappMessage({ message, isReply = false, reaction }) {
  const [previewfile, setPreviewfile] = useState();
  const [isDownLoading, setIsDownLoading] = useState(false);
  const [carouselTemplate, setCarouselTemplate] = useState([]);
  const [template, setTemplate] = useState({
    templateType: "GENERIC",
    category: "MARKETING",
    language: "English",
    header: { type: "HEADER", format: "" },
    body: "",
    footer: "",
    buttons: [],
  });

  const globalReducer = useSelector((state) => state.globalReducer);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (!message) return;
    const msg = message?.messageText && Array.isArray(message.messageText)
      ? message.messageText[0]
      : message.messageText ?? {};
    switch (message?.type?.toLowerCase()) {
      case "template":
        onSelectTemplate(message?.template, message?.messageText);
        break;
      case "image":
        getFileMediaId(
          globalReducer.waba?.tenantId,
          globalReducer.waba?.id,
          msg?.image?.id
        ).then((fmd) => {
          setPreviewfile(fmd);
        });
        break;
      case "sticker":
        if (!msg?.sticker?.animated) {
          getFileMediaId(
            globalReducer.waba?.tenantId,
            globalReducer.waba?.id,
            msg?.sticker?.id
          ).then((fmd) => {
            setPreviewfile(fmd);
          });
        }
        break;
      case "video":
        break;
    }
  }, [message]);

  const onFileDownloading = (id) => {
    setIsDownLoading(true);
    getFileMediaId(
      globalReducer.waba?.tenantId,
      globalReducer.waba?.id,
      id
    ).then((fd) => {
      setPreviewfile(fd);
      setIsDownLoading(false);
    });
  };

  const onFileDocumentDownloading = (id) => {
    setIsDownLoading(true);
    getFileMediaId(
      globalReducer.waba?.tenantId,
      globalReducer.waba?.id,
      id
    ).then((fd) => {
      saveBlobFile(
        fd,
        moment.utc(message?.createdAt).tz(wabaTimezone).format("YYYYMMMMDDhhmm")
      );
      setIsDownLoading(false);
    });
  };

  const saveBlobFile = (blobUrl, filename) => {
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = filename; // Set file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl); // Clean up
  };

  const onSelectTemplate = (st, messageText) => {
    try {
      st.components = JSON.parse(st?.template ?? "[]");
      setSelectedTemplateMessage(
        st,
        messageText,
        setTemplate,
        setCarouselTemplate
      );
    } catch (e) {
      console.log(e);
    }
  };

  function latLngToTile(lat, lng, zoom) {
    const x = Math.floor(((lng + 180) / 360) * Math.pow(2, zoom));
    const y = Math.floor(
      ((1 -
        Math.log(
          Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
        ) /
        Math.PI) /
        2) *
      Math.pow(2, zoom)
    );
    return `https://b.tile.openstreetmap.org/${zoom}/${x}/${y}.png`; //`http://b.tile.osm.org/${zoom}/${x}/${y}.png`;
  }
  const getExtension = (mimeType) => mime.getExtension(mimeType) || "Document";
  const getMessage = () => {
    if (!message) return null;
    const msg = message?.messageText && Array.isArray(message.messageText)
      ? message.messageText[0]
      : message.messageText ?? {};
    switch (message?.type?.toLowerCase()) {
      case "text":
        return (
          <div className="flex justify-between w-full">
            <p className="w-full h-auto sm:text-sm break-words whitespace-pre-wrap">
              {msg?.text?.body}
            </p>
          </div>
        );
      case "reaction":
        return (
          <div className="flex justify-between w-full">
            <p className="w-full h-auto sm:text-sm break-words whitespace-pre-wrap">
              {msg?.reaction?.emoji}
            </p>
          </div>
        );
      case "image":
        return (
          <div className="relative w-full">
            <div className="h-auto w-full mx-auto pb-5">
              <div className="h-[120px] min-w-[200px]">
                {previewfile ? (
                  <a href={previewfile} download>
                    <img
                      className={"h-full w-full object-contain rounded-md "}
                      src={previewfile}
                    />
                  </a>
                ) : (
                  <div className="flex justify-center pt-10">
                    <i className="fa-solid fa-image text-[24px] text-gray-400"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      case "audio":
        return (
          <div className="w-full mx-auto min-w-[260px]">
            {previewfile ? (
              <audio
                className="object-fill rounded-md w-full mx-auto pb-5"
                controls
              >
                <source src={previewfile} type={msg?.audio?.mime_type} />
              </audio>
            ) : (
              <div className="relative cursor-pointer h-[50px] w-full mx-auto">
                {isDownLoading && (
                  <div className="">
                    <div className="absolute pt-2 pl-5">
                      <div className="w-9 h-9 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />
                    </div>
                  </div>
                )}
                <div
                  onClick={() => onFileDownloading(msg?.audio?.id)}
                  className="absolute bg-gray-500 bg-opacity-40 rounded-md h-[50px] w-full pl-5 pt-2 flex"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="text-white w-9 h-9"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <label className="ml-4 mt-1.5">Audio</label>
                </div>
              </div>
            )}
          </div>
        );
      case "video":
        return (
          <div className="w-full mx-auto h-[140px] min-w-[260px]">
            {previewfile ? (
              <video
                className="object-fill rounded-md h-[140px] w-full mx-auto pb-5"
                controls
              >
                <source src={previewfile} type={msg?.video?.mime_type} />
              </video>
            ) : (
              <div className="relative cursor-pointer w-full mx-auto">
                {isDownLoading && (
                  <div className="flex justify-center item-center">
                    <div className="absolute pt-11">
                      <div className="w-9 h-9 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />
                    </div>
                  </div>
                )}
                <div
                  onClick={() => onFileDownloading(msg?.video?.id)}
                  className="absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="text-white w-9 h-9"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            )}
          </div>
        );
      case "sticker":
        return (
          <div className="relative w-full">
            <div className="h-auto w-full mx-auto pb-5">
              <div className="h-[120px] min-w-[200px]">
                {previewfile ? (
                  <a href={previewfile}>
                    <img
                      className={"h-full w-full object-contain rounded-md"}
                      src={previewfile}
                    />
                  </a>
                ) : (
                  <div className="flex justify-center pt-10">
                    <i className="fa-solid fa-image text-[24px] text-gray-400"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      case "document":
        return (
          <div className="w-full mx-auto h-[140px] min-w-[260px]">
            <div
              className="w-full flex flex-col items-center rounded-md px-4 py-6"
              onClick={() => onFileDocumentDownloading(msg?.document?.id)}
            >
              {isDownLoading && (
                <div className="flex justify-center item-center">
                  <div className="absolute pt-11">
                    <div className="w-9 h-9 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />
                  </div>
                </div>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                className="w-9 h-9 text-gray-400 hover:text-theme"
              >
                <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
                <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
              </svg>
              <div className="mt-2 w-full">
                <p className="text-xs text-center font-medium leading-5 text-gray-600 truncate">
                  {msg?.document?.caption ?? getExtension(msg?.document?.mime_type)}
                  {/* {getExtension(msg?.document?.mime_type)} */}
                </p>
              </div>
            </div>
          </div>
        );
      case "contacts":
        return Array.isArray(msg.contacts) ? (
          msg.contacts?.map((cm, ci) => (
            <div
              key={ci}
              className="flex justify-between item-center w-full p-2 py-2 pr-14 gap-4"
            >
              <i className="fa-solid fa-user text-gray-500 text-[24px] text-center pt-2"></i>
              <div>
                <p className="w-full h-auto text-gray-900 text-[16px] sm:text-sm break-words whitespace-pre-wrap">
                  {cm.name?.formatted_name}
                </p>
                <p className="w-full h-auto text-gray-900 text-[16px] sm:text-sm break-words whitespace-pre-wrap">
                  {Array.isArray(cm?.phones)
                    ? cm?.phones[0]?.phone
                    : cm?.phones?.phone}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-between w-full p-2 py-2 pr-14 gap-4">
            <i className="fa-solid fa-user text-gray-500 text-[24px] text-center pt-2"></i>
            <div>
              <p className="w-full h-auto text-gray-900 text-[16px] sm:text-sm break-words whitespace-pre-wrap">
                {msg.contacts?.name?.formatted_name}
              </p>
              <p className="w-full h-auto text-gray-900 text-[16px] sm:text-sm break-words whitespace-pre-wrap">
                {Array.isArray(msg.contacts?.phones)
                  ? msg.contacts?.phones[0]?.phone
                  : msg.contacts?.phones?.phone}
              </p>
            </div>
          </div>
        );
      case "location":
        return (
          <div className="relative w-full mx-auto h-[120px] min-w-[260px]">
            <img
              className={"absolute h-full w-full rounded-md z-0"}
              src={latLngToTile(
                parseFloat(msg?.location?.latitude),
                parseFloat(msg?.location?.longitude),
                13
              )}
            />
            <div
              className="relative cursor-pointer w-full mx-auto z-1"
              onClick={() => {
                window.open(
                  `https://www.google.com/maps?q=${msg?.location?.latitude},${msg?.location?.longitude}&z=17&hl=en`
                );
              }}
            >
              <div className="absolute hover:bg-gray-50 bg-opacity-40 hover:bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center">
                <i className="fa-solid fa-location-dot text-theme text-[30px]"></i>
              </div>
            </div>
          </div>
        );
      case "template":
        return (
          <div className="relative mr-[-20px]">
            <TemplatePreview
              template={template}
              carouselTemplate={carouselTemplate}
              timezone={wabaTimezone}
              waba={globalReducer.waba}
              width="w-full"
              isHideTime={true}
            />
          </div>
        );
    }
  };

  return (
    <div className="relative">
      {message?.messageStatus?.toLowerCase() === "failed" && <><div className='top-0 left-0 z-10 font-bold leading-none flex items-center'>
        <span className='text-[10px] text-theme'>{message.remarks}</span>
      </div>
        <div className='absolute right-0 z-10'>
          <i className="fa-solid fa-triangle-exclamation text-[14px] text-theme"></i>
        </div></>}
      <div className={`overflow-hidden flex gap-2 justify-between relative ${isReply || message?.type === "sticker" ? "" : "rounded-xl bg-[#f7f8fc]"} w-full pt-[4px] pr-[12px] pb-[8px] pl-[8px]`}>
        <div className='w-[80%] text-wrap min-w-[140px]'>{getMessage()}</div>
        {!isReply &&
          <p className="p-[0px] sm:text-[10px] text-gray-500 flex items-end absolute bottom-[0px] right-[8px] z-[9]">
            {moment.utc(message?.createdAt).tz(wabaTimezone).format('hh:mm a')}
          </p>}
        {/* {reaction &&
          <span className="absolute bottom-[0px] right-[60px] z-[9] rounded-lg">{reaction?.emoji}</span>
        } */}
      </div>
    </div>
  );
}

export default WhatsappMessage;
