import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import { SHOW_SNACKBAR, TENANT_DETAILS } from "../../utils/types";
import UploaderBase64 from "../../components/UploaderBase64";
import { tenantUpdate } from "../../store/actions/partnerAction";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "../../utils/helper";
import dayjs from 'dayjs';
import { TimePicker, DatePicker } from "antd";
import { getBusinessHour, upsertBusinessHour } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
const { RangePicker } = TimePicker;
const { RangePicker: DateRangePicker } = DatePicker;

function CompanySetting({ showSidebar }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const [company, setCompany] = useState({});
  const [operationalStatus, setOperationalStatus] = useState(false);
  const [isCustomBusinessHours, setIsCustomBusinessHours] = useState(false);
  const [dayofWeek, setDayofWeek] = useState([{ dayOfWeek: "SUNDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] }, { dayOfWeek: "MONDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] },
  { dayOfWeek: "TUESDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] }, { dayOfWeek: "WEDNESDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] },
  { dayOfWeek: "THURSDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] }, { dayOfWeek: "FRIDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] }, { dayOfWeek: "SATURDAY", checked: true, timeRanges: [{ startDateTime: "00:00", endDateTime: "23:59" }] }]);
  const [holidays, setHolidays] = useState([]);
  const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  const DAYS_ORDER = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];

  useEffect(() => {
    if (tenantReducer.tenantDetails) {
      setCompany(tenantReducer.tenantDetails);
    }
  }, [tenantReducer.tenantDetails]);

  useEffect(() => {
    getBusinessHourDetails();
  }, [])

  const getBusinessHourDetails = () => {
    dispatch(getBusinessHour({
      tenantId: globalReducer.loginInfo?.tenantId, size: 100000, offset: 0,
      criteria: { active: true }
    }, a => {
      if (a?.data && Array.isArray(a.data)) {
        setHolidays(a.data?.filter(f => f.availability === "HOLIDAY")?.map(date => {
          return {
            ...date, startDateTime: moment.utc(date?.startDateTime).tz(wabaTimezone).format("YYYY-MM-DD"),
            endDateTime: moment.utc(date?.endDateTime).tz(wabaTimezone).format("YYYY-MM-DD")
          }
        }));
        setOperationalStatus(a.data?.some(f => f.availability === "OFFLINE"));
        setIsCustomBusinessHours(!a.data?.some(f => f.availability === "AVAILABLE_24X7"));
        const available = a.data?.filter(f => f.availability === "AVAILABLE_CUSTOM");
        if (available?.length > 0) {
          const groupedData = available.reduce((acc, item) => {
            if (!acc[item.dayOfWeek]) {
              acc[item.dayOfWeek] = [];
            }
            acc[item.dayOfWeek].push(item);
            return acc;
          }, {});
          setDayofWeek(Object.keys(groupedData)?.map(grp => {
            return {
              dayOfWeek: grp, checked: true, timeRanges: groupedData[grp]?.map(time => {
                return {
                  ...time, startDateTime: moment.utc(time?.startDateTime).tz(wabaTimezone).format("HH:mm"),
                  endDateTime: moment.utc(time?.endDateTime).tz(wabaTimezone).format("HH:mm")
                }
              })
            }
          }))
        }
      }
    }))
  }

  const onSaveSubmit = (e) => {
    e.preventDefault();
    if (company?.id) {
      dispatch(
        tenantUpdate(company, (a) => {
          dispatch({ type: TENANT_DETAILS, payload: { ...company } });
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "success",
              message: "saved successfully",
              open: true,
            },
          });
        })
      );
    }
    const businessHour = [];
    holidays.map(m => {
      if (m.startDateTime && m.endDateTime) {
        businessHour.push({ ...m, startDateTime: new Date(m.startDateTime).toISOString(), endDateTime: new Date(m.endDateTime).toISOString(), tenantId: globalReducer.loginInfo?.tenantId, availability: "HOLIDAY" });
      }
    })
    if (operationalStatus) {
      businessHour.push({ tenantId: globalReducer.loginInfo?.tenantId, availability: "OFFLINE" });
    } else {
      if (isCustomBusinessHours) {
        const selectedDays = dayofWeek.filter(f => f.checked);
        selectedDays.map(m => {
          m.timeRanges?.map(time => {
            if (time.startDateTime && time.endDateTime) {
              businessHour.push({ ...time, startDateTime: dayjs(time.startDateTime, "HH:mm").toDate().toISOString(), endDateTime: dayjs(time.endDateTime, "HH:mm").toDate().toISOString(), dayOfWeek: m.dayOfWeek, tenantId: globalReducer.loginInfo?.tenantId, availability: "AVAILABLE_CUSTOM" });
            }
          })
        })
      } else {
        businessHour.push({ tenantId: globalReducer.loginInfo?.tenantId, availability: "AVAILABLE_24X7" });
      }
    }
    if (businessHour?.length > 0) {
      dispatch(upsertBusinessHour(businessHour, a => {
        getBusinessHourDetails();
      }));
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="my-auto font-medium text-black cursor-pointer text-left">
            <div className="text-[14px] font-medium">
              {t("Company information")}
            </div>
            <label className="text-[12px] text-gray-600">
              {t("Change setting like name, logo changes etc")}
            </label>
          </div>
          <div className="flex gap-2"></div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <form onSubmit={onSaveSubmit}>
          <div className="h-[80vh] px-5 pt-5 mt-[6rem] overflow-x-auto">
            <div className="grid grid-cols-12 gap-4 border p-3 rounded-lg">
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Name")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={company?.name}
                  required
                  placeholder={t("Enter name")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setCompany({ ...company, name: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Website")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={company?.website}
                  required
                  placeholder={t("Enter Website")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setCompany({ ...company, website: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div disabled className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Country")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <SelectCountry
                  height="40px"
                  required={true}
                  selectedCode={company?.countryCode}
                  countryList={globalReducer.country}
                  onSelectCountry={(country) => {
                    if (country) {
                      company.countryCode = company.code;
                    } else {
                      delete company.countryCode;
                    }
                    setCompany({ ...company });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
            </div>
            <div className="gap-4 border p-3 rounded-lg mt-3">
              <div className="w-[50%]">
                <div className="">
                  <p className="text-[14px] font-bold">{t("Branding")}</p>
                  <label className="text-[12px]">{t("Change Logo")}</label>
                </div>
                <div className="mt-2">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label> {t("Add Logo")}</label>
                  </div>
                  <div className="flex w-full text-[12px]">
                    <UploaderBase64
                      selectedBase64={company?.logo}
                      onUploaded={(d) => {
                        setCompany({ ...company, logo: d });
                      }}
                      accept="image/png,image/jpeg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-border-light rounded-lg mt-3">
              <div className="w-full">
                <div className="px-4 pt-2.5 pb-[9px] mb-6">
                  <h2 className="text-[14px] font-bold">{t("Business Hours")}</h2>
                </div>
              </div>
              <div>
                <div className="h-auto px-5 pb-5 rounded w-full">
                  <div className="flex gap-4">
                    <div className="flex justify-start w-[69%] py-4">
                      <label className="text-[14px] ml-1">{t("Operational Status")}</label>
                    </div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input checked={operationalStatus} className="sr-only peer" type="checkbox" onChange={(e) => { setOperationalStatus(e.target.checked) }} />
                      <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                      <span className="ms-3 text-sm font-medium text-gray-900"> </span>
                    </label>
                  </div>
                  <div>
                    {operationalStatus ? <div className="text-gray-600 text-[14px] p-3 bg-gray-200 w-3/4 rounded-lg">
                      {t("As you have turned off operational hours, you may need to modify the welcome message from Integrations and Automation for away time on all connected channels.")}
                    </div> :
                      <div className="flex w-3/4">
                        <div className="grid grid-cols-1 gap-4 divide-y">
                          <div className="grid grid-cols-1 gap-4">
                            <div className="radio border border-[#D1D5DB] rounded-lg">
                              <div className="px-4 mt-4">
                                <input type="radio" name="bHour" checked={!isCustomBusinessHours} onChange={(e) => setIsCustomBusinessHours(false)} className="focus:ring-primary text-primary" />
                                <label className="px-2 text-[15px] py-2 font-medium"> {t("24/7 Business Hours")}
                                  <p className="px-7 text-[13px] text-gray-500 font-normal">
                                    {t("Your agents are available 24 hours. The default welcome message will be sent when a customer initiates a conversation. You can edit your welcome message from")}
                                  </p>
                                </label>
                              </div>
                            </div>
                            <div className="radio border rounded-lg border-[#D1D5DB]">
                              <div className="grid w-full grid-cols-1 divide-y ">
                                <div className="px-4 mt-4">
                                  <input type="radio" name="bHour" checked={isCustomBusinessHours} onChange={(e) => setIsCustomBusinessHours(true)} className="focus:ring-primary text-primary" />
                                  <label className="px-2 text-[15px] font-medium"> {t("Custom Business Hours")}
                                    <p className="flex items-center px-7 text-[13px] text-gray-500 font-normal">
                                      {t("Set your business availability as per your convenience.")}
                                    </p>
                                  </label>
                                </div>
                                {isCustomBusinessHours &&
                                  <div>
                                    <div className="px-4 py-4">
                                      <div className="flex gap-4">
                                        <div className="flex w-full">
                                          <div className="flex justify-start w-[95%] text-sm text-gray-500 font-medium px-2">
                                            {t("Day of Week")}
                                          </div>
                                          <div className="flex justify-start w-[60%] text-sm text-gray-500 font-medium px-2">
                                            {t("Working Hour")}
                                          </div>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="mt-4">
                                          <div className="text-sm text-gray-500 px-2 w-full">
                                            {dayofWeek?.sort((a, b) => DAYS_ORDER.indexOf(a.dayOfWeek) - DAYS_ORDER.indexOf(b.dayOfWeek))?.map((day, i) =>
                                              <div className="w-full py-2" key={i}>
                                                <div className="flex justify-start w-[95%] items-start mt-2">
                                                  <div className="w-[5%] mt-2">
                                                    <input type="checkbox" checked={day.checked} onChange={(e) => { day.checked = e.target.checked; setDayofWeek([...dayofWeek]) }} className="flex justify-start align-middle py-1 mt-1 ltr:mr-4 rtl:ml-4 w-4 h-4 border-gray-300 rounded outline-none focus:ring-0 ring-offset-0 text-primary"
                                                    />
                                                  </div>
                                                  <div className="flex justify-start gap-2 item-center w-[35%] text text-gary-600 text-sm font-medium mt-1 py-1">
                                                    {toTitleCase(day.dayOfWeek)}
                                                  </div>
                                                  <div disabled={!day.checked} className="flex flex-col gap-2 w-[60%] justify-end">
                                                    {day?.timeRanges?.map((time, j) =>
                                                      <div className="flex justify-end gap-2 w-full" key={j}>
                                                        <RangePicker format="HH:mm" onChange={(dates) => {
                                                          if (dates && dates.length > 0) {
                                                            const [start, end] = dates;
                                                            if (start && end) {
                                                              dayofWeek[i].timeRanges[j].startDateTime = start?.format("HH:mm");
                                                              dayofWeek[i].timeRanges[j].endDateTime = end?.format("HH:mm");
                                                              setDayofWeek([...dayofWeek]);
                                                            }
                                                          }
                                                        }}
                                                          defaultValue={[dayjs(time?.startDateTime, "HH:mm"), dayjs(time?.endDateTime, "HH:mm")]}
                                                        />
                                                        {j === 0 ? <div className="flex gap-2 justify-end relative w-[15%] text text-gray-500">
                                                          <button type="button" className="bg-white px-2.5 py-2.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white"
                                                            onClick={() => { day?.timeRanges.push({ startDateTime: "00:00", endDateTime: "23:59" }); setDayofWeek([...dayofWeek]) }}>
                                                            <i className="fa-solid fa-plus text-theme text-[16px]"></i>
                                                          </button>
                                                        </div> :
                                                          <div className="flex gap-2 justify-end relative w-[15%] text text-gray-500">
                                                            <button type="button" className="px-2.5 py-2.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-white"
                                                              onClick={() => { day?.timeRanges?.splice(j, 1); setDayofWeek([...dayofWeek]) }}>
                                                              <i className="fa-solid fa-trash text-theme text-[16px]"></i>
                                                            </button>
                                                          </div>}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>}
                  </div>
                </div>
              </div>
              <div className="p-4">
                <div className="border border-[#D1D5DB] rounded-lg px-6 w-3/4">
                  <div className="flex justify-start flex-col gap-4 mt-6">
                    <div className="flex justify-start w-full">
                      <label className="text-[15px] ml-1">
                        {t("Holiday and Day Off")}
                        <p className="text-[13px] text-gray-500 font-normal">
                          {t("Set up the dates your business is closed for public holidays any other day off. Your customers will receive the offline message when they want to chat.")}
                        </p>
                      </label>
                    </div>
                    <div className="flex w-full">
                      <div className="w-full flex flex-col gap-2">
                        {holidays?.map((hd, i) =>
                          <div key={i} className="flex gap-2 w-1/2 p-4 bg-gray-50 w-full border border-gray-200 rounded-md ">
                            <div className="flex w-3/4">
                              <div className="flex justify-start flex-col gap-2">
                                <label className="text-[13px]">Title</label>
                                <input type="text" value={hd?.remarks} placeholder={t("e.g. Christmas")} className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                                  onChange={(e) => {
                                    holidays[i].remarks = e.target.value;
                                    setHolidays([...holidays])
                                  }}
                                />
                                <div className="flex flex-row w-full">
                                  <DateRangePicker defaultValue={[dayjs(hd?.startDateTime), dayjs(hd?.endDateTime)]}
                                    className={"h-10"} format="YYYY-MM-DD" onChange={(dates) => {
                                      if (dates && dates.length > 0) {
                                        const [start, end] = dates;
                                        if (start && end) {
                                          holidays[i].startDateTime = start.toDate();
                                          holidays[i].endDateTime = end?.toDate();
                                        } else {
                                          holidays[i].startDateTime = null;
                                          holidays[i].endDateTime = null;
                                        }
                                      } else {
                                        holidays[i].startDateTime = null;
                                        holidays[i].endDateTime = null;
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-end w-1/4 pt-5">
                              <i className="fa-solid fa-trash text-theme text-[16px] cursor-pointer" onClick={() => { holidays?.splice(i, 1); setHolidays([...holidays]) }}></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-start ml-1">
                      <button type="button" className="p-1 border border-grey-500 rounded-md text-[14px] bg-theme p-2"
                        onClick={() => setHolidays([...holidays, {}])}>
                        <div className="flex flex-row justify-start gap-2 text-white">
                          <i className="fa-solid fa-plus text-[16px]"></i>
                          <div>{t("Add off days")}</div>
                        </div>
                      </button>
                    </div>
                    <div />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 flex gap-2 sticky bottom-0 justify-end w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
            <button type="submit" className="min-w-[100px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CompanySetting;
