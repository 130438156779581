import React from 'react'
import moment from "moment-timezone";
import { useSelector } from 'react-redux';

function ViewTenantWalletTransfer({ setIsShow, transfer }) {
    const partnerReducer = useSelector((state) => state.partnerReducer);
    const partnerTimezone =
        partnerReducer.partnerDetails?.timezone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (
        <div className="h-[85vh] overflow-y-auto p-4">
            <div className="grid grid-cols-2 gap-3 w-full">
                <div className='text-[14px]'>
                    <p className='text-gray-500'>From Company Name</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{transfer?.fromCompanyName}</label>
                    </div>
                </div>
                <div className='text-[14px]'>
                    <p className='text-gray-500'>To Company Name</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{transfer?.toCompanyName}</label>
                    </div>
                </div>
                <div className='text-[14px]'>
                    <p className='text-gray-500'>From WABA</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{`${transfer.fromTeamName} (+${transfer.fromPhoneCode} ${transfer.fromPhone})`}</label>
                    </div>
                </div>
                <div className='text-[14px]'>
                    <p className='text-gray-500'>To WABA</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{`${transfer.toTeamName} (+${transfer.toPhoneCode} ${transfer.toPhone})`}</label>
                    </div>
                </div>
                <div className='text-[14px]'>
                    <p className='text-gray-500'>Amount</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{transfer?.currencyCode} {transfer?.amount}</label>
                    </div>
                </div>
                <div className='text-[14px]'>
                    <p className='text-gray-500'>Date & Time</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{moment.utc(transfer?.createdAt)
                            .tz(partnerTimezone)
                            .format("YYYY-MM-DD hh:mm A") ?? "-"}</label>
                    </div>
                </div>
                <div className='text-[14px]'>
                    <p className='text-gray-500'>Created By (User who topped up)</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{transfer?.partnerUserName}</label>
                    </div>
                </div>
                {/* <div className='text-[14px]'>
                    <p className='text-gray-500'>IP Address</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{transfer?.accountType}</label>
                    </div>
                </div> */}
                <div className='text-[14px]'>
                    <p className='text-gray-500'>Remarks</p>
                    <div className='bg-gray-100 py-1.5 px-2'>
                        <label className='text-gray-900'>{transfer?.remarks}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewTenantWalletTransfer