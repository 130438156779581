import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from "react";

const CustomCaptcha = forwardRef(({ className = "h-10" }, ref) => {
    const [captchaText, setCaptchaText] = useState("");
    const [userInput, setUserInput] = useState("");
    const [message, setMessage] = useState("");
    const [isValidate, setIsValidate] = useState(false);
    const canvasRef = useRef(null);

    // Function to generate a random CAPTCHA string
    const generateCaptcha = () => {
        const chars = "0123456789";
        let captcha = "";
        for (let i = 0; i < 6; i++) {
            captcha += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        setCaptchaText(captcha);
        return captcha; // Return the generated CAPTCHA for immediate use
    };

    // Function to draw CAPTCHA on the canvas
    const drawCaptcha = (text) => {
        if (!canvasRef?.current) return; // ✅ Ensure the canvas exists

        const canvas = canvasRef.current;
        const ctx = canvas?.getContext("2d");

        if (!ctx) return; // ✅ Ensure we get the context before using it

        // Clear previous drawings
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Set background color
        ctx.fillStyle = "#f4f4f4";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Add random noise (lines)
        for (let i = 0; i < 3; i++) {
            ctx.beginPath();
            ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
            ctx.strokeStyle = "rgba(0,0,0,0.2)";
            ctx.lineWidth = 2;
            ctx.stroke();
        }
        const fontSize = canvas.width / 4; // Adjust font size based on canvas width
        ctx.font = `${fontSize}px bold Arial`;
        // Draw CAPTCHA text
        ctx.fillStyle = "#333";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    };

    // Use useEffect to ensure the canvas is available before drawing
    useEffect(() => {
        setTimeout(() => {
            const captcha = generateCaptcha();
            drawCaptcha(captcha);
        }, 100); // ✅ Add small delay to ensure canvas is mounted
    }, []);

    // Function to refresh CAPTCHA
    const refreshCaptcha = () => {
        drawCaptcha(generateCaptcha());
    };

    // Handle form submission
    const validate = () => {
        if (userInput && userInput.toLowerCase() === captchaText.toLowerCase()) {
            setMessage("✅ CAPTCHA Matched!");
            refreshCaptcha();
            setIsValidate(true);
        } else {
            setMessage("❌ Incorrect CAPTCHA, try again.");
            refreshCaptcha(); // Regenerate CAPTCHA if incorrect
            setIsValidate(false);
            return false;
        }
        setUserInput(""); // Clear input field
        return true;
    };
    useImperativeHandle(ref, () => ({
        validate,
    }));

    return (
        <div>
            <div className={`flex w-full justify-between gap-5 ${className}`}>
                <div className="flex">
                    <canvas ref={canvasRef} style={{ border: "1px solid #D1D5DB", borderRadius: "5px", minWidth: "100px" }}></canvas>
                    <button type="button" onClick={refreshCaptcha} className="pr-2 pl-[1px]">🔄</button>
                </div>
                <div className="w-full flex justify-end">
                    <span className="py-2">{isValidate ? "✅" : message ? "❌" : ""}</span>
                    <input required type="text" placeholder="Enter CAPTCHA" className={`${isValidate ? "border-none" : message ? "border-1 border-theme" : "border-none"} max-w-[150px] min-w-[100px] text-[14px] py-1 w-[100%] justify-center font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white`}
                        value={userInput} onChange={(e) => setUserInput(e.target.value)} />
                </div>
            </div>
            {/* <p className="text-[12px]">{message}</p> */}
        </div>
    );
});

export default CustomCaptcha;
