import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { sessionUploader, getFile, getSessionId, uploadFile, mediaUploader } from '../store/actions/globalAction';
import { v4 as uuidv4 } from 'uuid';

function FileUploader({ type, onUploaded, filePrefix, selectedFileUrl, name, isSessionUploder = false, tenantId, wabaId }) {
    let dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState(name ?? "");
    const [previewfile, setPreviewfile] = useState();
    const [error, setError] = useState(null);
    const IMAGE_MAX_FILE_SIZE = 5;
    const VIDEO_MAX_FILE_SIZE = 16;
    const DOCUMENT_MAX_FILE_SIZE = 100;

    useEffect(() => {
        if (selectedFileUrl && selectedFileUrl != "" && type?.toUpperCase() != "DOCUMENT") {
            getFile(selectedFileUrl).then(sf => { setPreviewfile(sf) });
        }
    }, [selectedFileUrl])

    useEffect(() => {
        setError(null);
        setFileName(null);
    }, [type])

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            let fileSize = 0;
            switch (type?.toUpperCase()) {
                case "IMAGE":
                    fileSize = IMAGE_MAX_FILE_SIZE;
                    break;
                case "VIDEO":
                    fileSize = VIDEO_MAX_FILE_SIZE;
                    break;
                case "DOCUMENT":
                    fileSize = DOCUMENT_MAX_FILE_SIZE;
                    break;
            }
            if (files[0].size > (fileSize * 1024 * 1024)) {
                setError(`File size exceeds the limit of ${fileSize} MB.`);
            } else {
                setError(null);
                const formData = new FormData();
                files.forEach((file) => {
                    formData.append('file', file);
                });
                setFileName(files[0].name);
                setUploading(true);
                if (isSessionUploder) {
                    dispatch(getSessionId({ tenantId: tenantId, wabaId: wabaId, name: files[0].name, length: files[0].size, contentType: files[0].type }, a => {
                        if (a.id) {
                            dispatch(sessionUploader(formData, tenantId, wabaId, a.id, f => {
                                setUploading(false);
                                if (f.h) {
                                    onUploaded({ ...f, filename: files[0].name });
                                }
                            }))
                        } else setUploading(false);
                    }));
                } else {
                    // const newUuid = uuidv4();
                    // const key = `${(filePrefix ? (filePrefix + "/") : "")}${newUuid}.${files[0].name.split('.').pop()}`;
                    dispatch(mediaUploader(formData, tenantId, wabaId, a => {
                        setUploading(false);
                        if (a) {
                            onUploaded({ ...a, filename: files[0].name });
                        }
                    }));
                }
            }
        }
    };

    const removeFile = () => {
        onUploaded({});
    }

    const getUi = () => {
        switch (type?.toUpperCase()) {
            case "IMAGE":
                return <div className=''>{selectedFileUrl ?
                    <>
                        <div className='flex justify-end relative'>
                            <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                            </button>
                        </div>
                        <img src={previewfile} className="w-fit rounded-t-lg border-none max-h-[130px]" />
                    </>
                    :
                    <label tabIndex={-1} htmlFor="UploadImage" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[130px]">
                        <input id="UploadImage" onChange={handleUpload} accept="image/png,image/jpeg" type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                        <div className='flex gap-2'>
                            <i className="fa-solid fa-image text-[24px] hover:text-theme"></i>
                            {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                        </div>
                        <p className="w-[80%] text-center text-[14px]">
                            Upload or drag &amp; drop your image here. Maximum size 5 MB allowed &amp;
                            image type must be PNG or JPEG.
                        </p>
                        <p className="text-center text-[14px] text-theme"> {error}</p>
                        <p className="text-center text-[14px] text-[#1E40AF]"> {selectedFileUrl}</p>
                    </label>}
                </div>;
            case "VIDEO":
                return <div className=''>{selectedFileUrl ?
                    <>
                        <div className="relative cursor-pointer w-full mx-auto p-[4px]">
                            <div className='relative w-[120px] overflow-hidden'>
                                <div className="absolute bg-opacity-40 rounded-md left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[full] flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="text-white w-9 h-9">
                                        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                <img src={previewfile} className="aa w-[120px] h-[120px] rounded-md border-none" />
                            </div>
                            <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                            </button>
                        </div>
                    </>
                    :
                    <label tabIndex={-1} htmlFor="UploadVideo" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[130px]">
                        <input id='UploadVideo' onChange={handleUpload} accept="video/mp4" type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                        <div className='flex gap-2'>
                            <i className="fa-solid fa-video text-[24px] hover:text-theme"></i>
                            {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                        </div>
                        <p className="w-[80%] text-center text-[14px]">
                            Upload or drag &amp; drop your video here. Maximum size 16 MB allowed &amp;
                            video type must be MP4.
                        </p>
                        <p className="text-center text-[14px] text-theme"> {error}</p>
                        <p className="text-center text-[14px] text-[#1E40AF]"> {selectedFileUrl}</p>
                    </label>}
                </div>;
            case "DOCUMENT":
                return <div>{selectedFileUrl ? <>
                    <div className='flex justify-end relative'>
                        <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                        </button>
                    </div>
                    <div className='gap-2 p-2 text-center py-2'>
                        <i className="fa-solid fa-file text-[30px] text-[#8d99ae] hover:text-theme"></i>
                        <p className="text-center text-[14px] mt-4 text-[#1E40AF]"> {selectedFileUrl}</p>
                    </div>
                </> :
                    <label tabIndex={-1} htmlFor="UploadDocument" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[138px]">
                        <input id="UploadDocument" onChange={handleUpload} accept=".pdf, .doc, .docx" type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                        <div className='flex gap-2'>
                            <i className="fa-solid fa-file text-[24px] hover:text-theme"></i>
                            {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                        </div>
                        <p className="w-[80%] text-center text-[14px] text-gray-500">
                            Upload or drag &amp; drop your document here. Maximum size 100 MB allowed &amp; document type must be PDF.
                        </p>
                        <p className="text-center text-[14px] text-theme"> {error}</p>
                        <p className="text-center text-[14px] text-[#1E40AF]"> {fileName ?? selectedFileUrl}</p>
                    </label>}</div>;
        }
    }

    return (
        type && <div className="border border-gray-400 rounded-md border-dashed">
            {getUi()}
        </div>
    )
}

export default FileUploader