import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Password from '../../components/Password';
import { getTenantUserDetail, tenantUserUpsert } from '../../store/actions/tenantAction';
import { SHOW_SNACKBAR } from '../../utils/types';
import MobileInput from '../../components/MobileInput';
import { useTranslation } from 'react-i18next';

function Settings({ showSidebar }) {
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const [passwordError, setPasswordError] = useState();
  const [user, setUser] = useState({});

  useEffect(() => {
    dispatch(getTenantUserDetail(globalReducer.loginInfo?.uid, a => {
      setUser(a);
    }))
  }, []);

  const passwordMessage = (password, confirmPassword) => {
    var msg = "";
    if (password && confirmPassword) {
      if (password !== confirmPassword) {
        msg = "password and confirm password should be same";
      }
      if (password.length < 6 || confirmPassword.length < 6) {
        msg = msg ? msg + ", password should be minimum 6 characters" : "password should be minimum 6 characters";
      }
    }
    setPasswordError(msg);
  }

  const onSaveSubmit = (e) => {
    e.preventDefault();
    var msg = "";
    if (user?.oldPassword) {
      if (!user?.confirmPassword || user?.confirmPassword === '') {
        msg = "confirm password should not be empty";
        return;
      }
      if (!user?.password || user?.confirmPassword === '') {
        msg = "password should not be empty";
        return;
      }
      setPasswordError(msg);
    }
    dispatch(tenantUserUpsert(user, (a) => {
      dispatch({ type: SHOW_SNACKBAR, payload: { type: "success", message: "saved successfully", open: true } });
    }))
  }

  return (<div className='h-screen'>
    <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
      <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
        <div className="my-auto font-medium text-black cursor-pointer text-left">
          <div className="text-[14px] font-medium">
            {t('User information')}
          </div>
          <label className='text-[12px] text-gray-600'>{t('User information like name, avatar changes etc')}</label>
        </div>
        <div className="flex gap-2"></div>
      </div>
    </div>
    <form className='px-6 mt-[-30px]' onSubmit={onSaveSubmit}>
      <div className="grid grid-cols-12 gap-4 border p-3 rounded-lg">
        <div className="flex flex-col col-span-6">
          <div className="text-[#656F7D] text-[13px] font-normal mb-1">
            <label>{t('Name')}<span className="text-red-500 text-[14px]">*</span></label>

          </div>
          <input type="text" value={user?.name} required placeholder={t("Enter name")} className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
            onChange={(e) => { setUser({ ...user, name: e.target.value }); }} />
        </div>
        <div className='col-span-6'></div>
        <div className="flex flex-col col-span-6">
          <div className="text-[#656F7D] text-[13px] font-normal mb-1">
            <label>{t('Email')}<span className="text-red-500 text-[14px]">*</span></label>

          </div>
          <input type="email" value={user?.email} required placeholder={t("Enter Email")} className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
            onChange={(e) => { setUser({ ...user, email: e.target.value }); }} />
        </div>
        <div className='col-span-6'></div>
        <div className="col-span-6 w-full">
          <div className="text-[#656F7D] text-[13px] font-normal">
            <label>{t('Phone Number')}<span className="text-red-500 text-[14px]">*</span></label>

          </div>
          <MobileInput className={"py-1.5 text-[14px]"} required={true} placeholder={t('Enter Phone Number')} countryList={globalReducer.country} phoneCode={user.phoneCode} phone={user.phone} setPhoneCode={(code) => setUser({ ...user, phoneCode: code })} setPhone={(phone) => setUser({ ...user, phone: phone })} />
        </div>
        <div className='col-span-6'></div>
      </div>
      <div className="gap-4 border p-3 rounded-lg mt-3">
        <div className='flex justify-between'>
          <div className=''>
            <p className='text-[14px] font-bold'>{t('Enable Two Step Verification')}</p>
            <label className='text-[12px]'>{t('Require an authentication code when you log in with an email and password')}</label>
          </div>
          <div className='mt-2'>
            <label className="inline-flex items-center cursor-pointer">
              <input type="checkbox" onChange={(e) => {
                setUser({ ...user, isTwoFactorAuth: e.target.checked === true });
              }} className="sr-only peer" />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
              <span className="ms-3 text-sm font-medium text-gray-900">{t('Active')}</span>
            </label>
          </div>
        </div>
      </div>
      <div className="gap-4 border p-3 rounded-lg mt-3">
        <p className='text-[16px] font-bold'>{t('Change Password')}</p>
        <label className='text-[12px]'>{t('User Password change hare')}</label>
        <div className="w-[30%] mt-2">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Old Password')}</label>
          </div>
          <Password onChange={(a) => { setUser({ ...user, oldPassword: a }); }} />
        </div>
        <div className="w-[30%] mt-2">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Password')}</label>
          </div>
          <Password isError={passwordError ? true : false} onChange={(a) => {
            passwordMessage(a, user.confirmPassword);
            setUser({ ...user, password: a });
          }} value={user?.password} />
        </div>
        <div className="w-[30%] mt-2">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Confirm Password')}</label>
          </div>
          <Password isError={passwordError ? true : false} onChange={(a) => {
            passwordMessage(user.password, a);
            setUser({ ...user, confirmPassword: a });
          }} value={user?.confirmPassword} />
        </div>
        <div className="col-span-12 h-1 mt-[1px]">
          {passwordError && <label className='text-[12px] text-[#f10808]'>{passwordError}</label>}
        </div>
      </div>
      {/* <div className="gap-4 border p-3 rounded-lg mt-3">
        <div className="flex flex-col col-span-12">
          <div className="text-[#656F7D] text-[13px] font-normal mb-1">
            <label>User Avatar</label>
            
          </div>
          <label htmlFor="avatar-dropzone-file" className="flex flex-col items-center justify-center w-full h-25 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-1 pb-3">
              <svg className="w-6 h-6 text-theme" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
              </svg>
              <p className="mb-1 text-sm text-gray-500">
                <span className="hover:underline text-theme">Click to upload</span> or drag and drop
              </p>
              <p className="text-xs text-gray-500">
                *Please Upload File in png, jpeg, jpg Format Only.
              </p>
               {ReportData?.name ? <p className="text-xs text-gray-500">File Name : {ReportData?.name}</p> : ""} 
            </div>
            <input id="avatar-dropzone-file" required type="file" className="hidden" accept=".png,.jpeg,.jpg" multiple={false} onChange={(e) => {

            }} />
          </label>
        </div>
      </div> */}
      <div className="flex justify-end mt-1">
        <button type='submit' className="min-w-[100px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
          {t('Save')}
        </button>
      </div>
      <div className='h-7'></div>
    </form>
  </div>)
}

export default Settings