import React, { useEffect, useState } from "react";
import {
  changeTenantStatus,
  getTenants,
  loginAsTenantUser,
} from "../../store/actions/partnerAction";
import { Dropdown } from "flowbite-react";
import AddAccount from "../../layouts/partner/AddAccount";
import PopUp from "../../components/PopUp";
import RightSidePanel from "../../components/RightSidePanel";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddTenantUser from "../../layouts/AddTenantUser";
import TenantDetails from "../../layouts/partner/TenantDetails";
import ViewWABAAccount from "../../layouts/tenant/ViewWABAAccount";
import moment from "moment-timezone";
import { DatePicker } from "antd";
import MapTenantPlan from "../../layouts/partner/MapTenantPlan";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import { useTranslation } from "react-i18next";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import MobileInput from "../../components/MobileInput";
import ManageTenantUser from "../../layouts/partner/ManageTenantUser";
import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;

function CompanyManagement({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [showAccountDtails, setShowAccountDetails] = useState(false);
  const [showAddCompanyUser, setShowAddCompanyUser] = useState(false);
  const [viewWABAAccount, setViewWABAAccount] = useState(false);
  const [showManageTenantUser, setShowManageTenantUser] = useState(false);
  const [viewMapPlan, setViewMapPlan] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [autoSuggestCompany, setAutoSuggestCompany] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({ criteria: {} });
  const [dateFilter, setDateFilter] = useState();
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const partnerTimezone =
    partnerReducer.partnerDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

  useEffect(() => {
    dispatch(getTenants(
      {
        select: ["id", "name"],
        size: 100000,
        offset: 0,
      }, (d) => setAutoSuggestCompany(d?.data)
    ));
    if (location?.state?.isEditPlan) {
      setSelectedAccount({ id: location?.state?.selectedWaba?.tenantId });
      setViewMapPlan(true);
    }
  }, []);

  useEffect(() => {
    companySearch();
  }, [currentPage]);

  const companySearch = () => {
    if (dateFilter && searchText) {
      filter.filter = [
        { property: "name", operator: "ICONTAINS", value: searchText },
        { clause: "AND" },
        dateFilter,
      ];
    } else if (searchText) {
      filter.filter = [
        { property: "name", operator: "ICONTAINS", value: searchText },
      ];
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    Object.keys(filter.criteria).map(m => {
      if (!filter.criteria[m] || filter.criteria[m] == "" || filter.criteria[m] == 0) {
        delete filter.criteria[m];
      }
    })
    if (filter.criteria["contactPhoneCode"] && !filter.criteria["contactPhone"]) {
      delete filter.criteria.contactPhoneCode;
    }
    if (filter.criteria["wabaPhoneCode"] && !filter.criteria["wabaPhone"]) {
      delete filter.criteria.wabaPhoneCode;
    }
    dispatch(
      getTenants(
        {
          ...filter,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "createdAt", direction: "DESC" }]
        },
        (d) => {
          setCompanyList(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const selectHolidayDate = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  const LoginAsUser = (customer) => {
    dispatch(loginAsTenantUser(customer, d => {
      localStorage.setItem("sessionToken", d?.access_token);
      localStorage.setItem("refreshToken", d?.refresh_token);
      window.location.replace(JSON.parse(process.env.REACT_APP_TENANT_PORTAL_CONFIG).redirect_uri);
    }));
  }

  const onSearch = () => {
    setAdvanceSearch(false);
    if (currentPage === 1) {
      companySearch();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-10 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular text-[14px] fa fa-handshake-o text-theme"></i>
            <div className="text-[14px] font-medium">{t('Company Management')}</div>
          </div>
          <div className="flex gap-2">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedAccount({});
                setShowAddAccount(true);
              }}
            >
              {t('Add Company')}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-12 gap-2 items-end">
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Company Name')}</label>

              </div>
              <TypeableSelect list={autoSuggestCompany} selected={filter.criteria} labelField="name" valueField="id" required={true} placeholder={t("Company Name")} height="40px" onSelect={(s) => {
                filter.criteria.id = s?.id;
                setFilter({ ...filter });
                onSearch();
              }} />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Country')}</label>

              </div>
              <SelectCountry height={"40px"}
                countryList={globalReducer.country}
                onSelectCountry={(country) => {
                  if (country) {
                    filter.criteria.countryCode = country.code;
                  } else {
                    delete filter.criteria.countryCode;
                  }
                  setFilter({ ...filter });
                }}
                placeholder={t('Select Country')}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Status')}</label>

              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-2`}>
              <button type="button" className="justify-center items-center flex px-2 h-10 py-1 rounded cursor-pointer text-white bg-theme "
                onClick={() => setAdvanceSearch(!advanceSearch)}>
                {/* <i className="fa-brands fa-searchengin text-[24px]"></i> */}
                Advance Search
              </button>
              {advanceSearch && <div className="relative">
                <div className="px-[16px] pb-[16px] bg-white rounded-[4px] text-[14px] mt-1 dropdown-shadow absolute w-[420px] min-h-[100px] top-0 z-[99] right-0">
                  {/* <div className="flex mt-1 justify-end">
                    <label className="cursor-pointer text-theme" onClick={() => setFilter({ criteria: {} })}>Clear Filter</label>
                  </div> */}
                  <div className="grid grid-cols-6 gap-3 mt-1">
                    <div className="col-span-2 text-[#656F7D] py-2 text-[13px] font-normal">
                      <label className="whitespace-nowrap">{t('Created Date')}</label>
                    </div>
                    <RangePicker
                      className="h-10 col-span-4"
                      onChange={selectHolidayDate}
                      format="YYYY-MM-DD"
                    />
                    <div className="col-span-2 text-[#656F7D] py-2 text-[13px] font-normal">
                      <label className="whitespace-nowrap">{t('Contact Person')}</label>
                    </div>
                    <input type="text" placeholder={t("Enter Contact Person name")} value={filter.criteria?.contactPerson}
                      className="col-span-4 text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                      onChange={(e) => setFilter({ ...filter, criteria: { ...filter.criteria, contactPerson: e.target.value ?? null } })} />
                    <div className="col-span-2 text-[#656F7D] py-2 text-[13px] font-normal">
                      <label className="whitespace-nowrap">{t('Contact Number')}</label>
                    </div>
                    <div className="col-span-4">
                      <MobileInput className="h-9" required={true} countryList={globalReducer.country} phoneCode={filter.criteria?.contactPhoneCode} phone={filter.criteria?.contactPhone}
                        setPhoneCode={(phone) => setFilter({ ...filter, criteria: { ...filter.criteria, contactPhoneCode: phone ?? null } })}
                        setPhone={(phone) => setFilter({ ...filter, criteria: { ...filter.criteria, contactPhone: phone ?? null } })}
                        placeholder={t("Enter Contact Phone Number")}
                      />
                    </div>
                    <div className="col-span-2 text-[#656F7D] py-2 text-[13px] font-normal">
                      <label className="whitespace-nowrap">{t('WABA Phone Number')}</label>
                    </div>
                    <div className="col-span-4">
                      <MobileInput className="h-9" required={true} countryList={globalReducer.country} phoneCode={filter.criteria?.wabaPhoneCode} phone={filter.criteria?.wabaPhone}
                        setPhoneCode={(phone) => setFilter({ ...filter, criteria: { ...filter.criteria, wabaPhoneCode: phone ?? null } })}
                        setPhone={(phone) => setFilter({ ...filter, criteria: { ...filter.criteria, wabaPhone: phone ?? null } })}
                        placeholder={t("Enter WABA Phone Number")}
                      />
                    </div>
                    <div className="col-span-2 text-[#656F7D] py-2 text-[13px] font-normal">
                      <label className="whitespace-nowrap">{t('WABA ID')}</label>
                    </div>
                    <div className="col-span-4">
                      <input type="text" placeholder={t("Enter WABA ID")} value={filter.criteria?.wabaNumber}
                        className="col-span-4 text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                        onChange={(e) => setFilter({ ...filter, criteria: { ...filter.criteria, wabaNumber: e.target.value ?? null } })} />
                    </div>
                  </div>
                </div>
              </div>}
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-4 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {companyList && companyList.length > 0 ? (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200 gap-2">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      <div className="flex gap-2">
                        <div> {t('Sr. No.')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div> {t('Company ID')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Company Name')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Contact Person')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Contact Number')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Created Date & Time')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Country')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Account Status')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] w-[80px]">
                      <div className="flex gap-2">
                        <div>{t('Status')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Action')}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {companyList.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className="py-3 text-[13px] pl-2">
                          {p?.id ?? "-"}
                        </td>
                        <th
                          scope="row"
                          className="py-3 text-[13px] font-medium hover:underline hover:text-theme cursor-pointer text-theme"
                          onClick={() => {
                            setSelectedAccount(p);
                            setShowAccountDetails(true);
                          }}
                        >
                          {p?.name ?? "-"}
                        </th>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.contactPerson ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          +{(p?.contactPhoneCode ?? "") + (p?.contactPhone ?? "-")}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {moment.utc(p?.createdAt)
                            .tz(partnerTimezone)
                            .format("YYYY-MM-DD hh:mm A") ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.country?.name ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.status ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal w-[80px]">
                          {p?.active ? (
                            <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                              Active
                            </label>
                          ) : (
                            <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                              Not Active
                            </label>
                          )}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            {p?.active ? (
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(
                                    changeTenantStatus(
                                      { id: p?.id, isActive: false },
                                      (a) => companySearch()
                                    )
                                  );
                                }}
                              >
                                {t('Deactivate')}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(
                                    changeTenantStatus(
                                      { id: p?.id, isActive: true },
                                      (a) => companySearch()
                                    )
                                  );
                                }}
                              >
                                {t('Activate')}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedAccount({ ...p, isEdit: true });
                                setShowAddAccount(true);
                              }}
                            >
                              {t('Edit')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedAccount(p);
                                setShowAddCompanyUser(true);
                              }}
                            >
                              {t('Add User')}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { setSelectedAccount(p); setShowManageTenantUser(true) }}>
                              {t('Manage User')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedAccount(p);
                                setViewWABAAccount(true);
                              }}
                            >
                              {t('View WABA Account')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedAccount(p);
                                setViewMapPlan(true);
                              }}
                            >
                              {t('Map Plan')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                LoginAsUser(p);
                              }}
                            >
                              {t('Login as User')}
                            </Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 ? (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t('The space is empty . Add a Company to get started.')}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => setShowAddAccount(true)}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t('Add Company')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showAddAccount}
        setIsShow={setShowAddAccount}
        ModalTitle={selectedAccount.isEdit ? t("Edit Company") : t("Add Company")}
        Component={
          <AddAccount
            setIsShow={setShowAddAccount}
            onAdd={companySearch}
            isEdit={selectedAccount.isEdit}
            defaultAccount={selectedAccount}
          />
        }
      />

      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showAccountDtails}
        setIsShow={setShowAccountDetails}
        ModalTitle={t("Company Details")}
        Component={
          <TenantDetails
            setIsShow={setShowAccountDetails}
            details={selectedAccount}
          />
        }
      />

      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewWABAAccount}
        setIsShow={setViewWABAAccount}
        ModalTitle={("WABA Account")}
        Component={
          <ViewWABAAccount
            setIsShow={setViewWABAAccount}
            tenant={selectedAccount}
          />
        }
      />

      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t('Add Company User')}
          </span>
        }
        isShowModal={showAddCompanyUser}
        setIsShowModal={setShowAddCompanyUser}
        ModalBody={
          <AddTenantUser
            setIsShow={setShowAddCompanyUser}
            onAdd={() => { }}
            tenantId={selectedAccount.id}
            tenant={selectedAccount}
          />
        }
        size="2xl"
      />
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewMapPlan}
        setIsShow={setViewMapPlan}
        ModalTitle={t("Map Plan")}
        Component={
          <MapTenantPlan
            setIsShow={setViewMapPlan}
            onAdd={() => { }}
            tenant={selectedAccount}
          />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[60%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showManageTenantUser}
        setIsShow={setShowManageTenantUser}
        ModalTitle="Manage User"
        Component={
          <ManageTenantUser setIsShow={setShowManageTenantUser} tenant={selectedAccount} />
        }
      />
    </div>
  );
}

export default CompanyManagement;
