import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { walletHistoryQuery } from "../../store/actions/tenantAction";
import { Pagination } from "@mui/material";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function AccountStatement({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [wallets, setWallets] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    property: "createdAt",
    operator: "RANGE",
    lowerBound: Date.parse(dayjs().startOf("month").toDate()),
    upperBound: Date.parse(dayjs().toDate()),
  });
  const [filter, setFilter] = useState({ criteria: {} });
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (globalReducer.loginInfo?.tenantId) {
      onSearch();
    }
  }, [currentPage, globalReducer.loginInfo]);

  const onSearch = () => {
    filter.filter = [dateFilter];
    dispatch(
      walletHistoryQuery(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantWabaId: globalReducer.waba?.id,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setWallets(d?.data ?? []);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const exportReport = () => {
    filter.filter = [dateFilter];
    dispatch(
      walletHistoryQuery(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantWabaId: globalReducer.waba?.id,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: 10000,
          offset: 0,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          const data = d?.data?.map((p, index) => {
            return {
              "Sr. No.": index,
              Date: p.createdAt,
              Debit:
                p?.walletTransactionType === "DEBIT"
                  ? p?.currencyCode + " " + p?.transactionAmount
                  : "",
              Credit:
                p?.walletTransactionType !== "DEBIT"
                  ? p?.currencyCode + " " + p?.transactionAmount
                  : "",
              Balance: Math.abs(p.balanceAmount),
            };
          });
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data ?? []);
          XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            "Account Statement"
          );
          XLSX.writeFile(workbook, "sccount_statement.xlsx");
        }
      )
    );
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };
  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-receipt text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">
              {t("Account Statement")}
            </div>
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={() => exportReport()}
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]"
            >
              {t("Download as Report")}
            </button>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Date Range")}</label>
              </div>
              <RangePicker
                defaultValue={[dayjs().startOf("month"), dayjs()]}
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={() => {
                  if (currentPage === 1) {
                    onSearch();
                  } else {
                    setCurrentPage(1);
                  }
                }}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {wallets && wallets.length > 0 && (
            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto mt-5">
              <thead className="text-[14px] text-midBlack bg-gray-200">
                <tr>
                  <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                    {t("Sr. No.")}
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>{t("Date")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500] py-3 pl-2">
                    <div className="flex gap-2">
                      <div>{t("Debit")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>{t("Credit")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>
                        {globalReducer.waba?.accountType === "POSTPAID"
                          ? t("Used Balance")
                          : t("Remaining Balance")}
                      </div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div>
                      {t("Source")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {wallets.map((p, i) => {
                  return (
                    <tr className="bg-white border-b" key={i}>
                      <td className="py-3 text-[13px] pl-2">
                        {currentPage === 1
                          ? i + 1
                          : itemParPage * (currentPage - 1) + (i + 1)}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {moment
                          .utc(p?.createdAt)
                          .tz(wabaTimezone)
                          .format("YYYY-MM-DD hh:mm A")}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {p?.walletTransactionType === "DEBIT" && (
                          <label className="text-[16px] text-[#D92D20]">
                            {p?.currencyCode} {p?.transactionAmount}
                          </label>
                        )}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {p?.walletTransactionType !== "DEBIT" && (
                          <label className="text-[16px] text-[#008743]">
                            {p?.currencyCode} {p?.transactionAmount}
                          </label>
                        )}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {Math.abs(p.balanceAmount)}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {p.amountSource}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
          {totalPages > 1 && (
            <div className="flex overflow-x-auto">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AccountStatement;
