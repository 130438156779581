import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MobileInput from '../components/MobileInput';
import { getCountry } from '../store/actions/globalAction';
import { forgotUsername } from '../store/actions/loginAction';
import { SHOW_SNACKBAR } from '../utils/types';
import CustomCaptcha from '../components/CustomCaptcha';

function ForgotUsername({ setIsShow }) {
    const dispatch = useDispatch();
    const [addressType, setAddressType] = useState("PHONE");
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState({ phoneCode: 968 });
    const globalReducer = useSelector((state) => state.globalReducer);
    const captcha = useRef(null);

    useEffect(() => {
        dispatch(getCountry({}, c => { }));
    }, [])

    const sendForgotLink = (e) => {
        e.preventDefault();
        if (!captcha.current.validate()) {
            return;
        }
        const request = { addressType: addressType };
        if (addressType === "PHONE") {
            request.address = mobile?.phone;
            request.phoneCode = mobile?.phoneCode;
        } else {
            request.address = email;
        }
        dispatch(forgotUsername(request, "iomnihub", a => {
            setIsShow(false);
            dispatch({
                type: SHOW_SNACKBAR,
                payload: {
                    type: "success",
                    message: "username sent on registered " + (addressType === "PHONE" ? "phone number" : "email address"),
                    open: true,
                },
            });
        }))
    }

    return (
        <div>
            <div className='py-2 text-center'>
                <label className='text-[14px] text-gray-500'>Select a recovery method below</label>
            </div>
            <form className="py-2 px-6" onSubmit={sendForgotLink}>
                <div className="flex items-center">
                    <input id="addressType-1" checked={addressType === "PHONE"} type="radio" name="addressType" className="w-4 h-4 text-theme bg-gray-300 shadow-input border-none focus:ring-transparent focus:ring-none"
                        onChange={(e) => {
                            setAddressType("PHONE");
                        }} />
                    <label for="addressType-1" className="ms-2 text-sm font-medium text-gray-600 cursor-pointer">
                        The username will be sent to the registered mobile numbar via SMS. Please enter the mobile number provided at the time of account creation
                    </label>
                </div>
                <div className='py-3' disabled={addressType !== "PHONE"}>
                    <MobileInput className={"py-2 text-[16px] phone-dropdown"} required={addressType === "PHONE"} countryList={globalReducer.country} phoneCode={mobile?.phoneCode} phone={mobile?.phone} setPhoneCode={(code) => setMobile({ ...mobile, phoneCode: code })} setPhone={(phone) => setMobile({ ...mobile, phone: phone })} />
                </div>
                <div className="flex items-center">
                    <input id="addressType-2" checked={addressType === "EMAIL"} type="radio" name="addressType" className="w-4 h-4 text-theme bg-gray-300 shadow-input border-none focus:ring-transparent focus:ring-none"
                        onChange={(e) => {
                            setAddressType("EMAIL");
                        }} />
                    <label for="addressType-2" className="ms-2 text-sm font-medium text-gray-600 cursor-pointer">
                        The username will be sent to the registered email address. please enter the email address provided at the time of account creation
                    </label>
                </div>
                <div className='py-3' disabled={addressType !== "EMAIL"}>
                    <input required={addressType === "EMAIL"} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Enter email address"
                        className="text-[16px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[400] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white" />
                </div>
                <div className="mt-[5px]">
                    <CustomCaptcha ref={captcha} />
                </div>
                <button type="submit" className="justify-center cursor-pointer items-center p-[12px] mt-5 mb-3 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full">Submit</button>
            </form>
        </div>
    )
}

export default ForgotUsername