import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLoginHistory } from "../store/actions/tenantAction";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { DatePicker } from "antd";
import moment from "moment-timezone";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function LoginHistory({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const globalReducer = useSelector((state) => state.globalReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [history, setHistory] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    property: "loginAt",
    operator: "RANGE",
    lowerBound: Date.parse(dayjs().startOf("month").toDate()),
    upperBound: Date.parse(dayjs().toDate()),
  });
  const [filter, setFilter] = useState({ criteria: {} });
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (globalReducer.loginInfo?.tenantId || globalReducer.loginInfo?.partnerId) {
      onSearchLoginHistory();
    }
  }, [currentPage]);

  const onSearchLoginHistory = () => {
    onFilter();
    dispatch(getLoginHistory(
      {
        ...filter,
        tenantId: globalReducer.loginInfo.tenantId,
        criteria: { ...filter?.criteria, tenantId: globalReducer.loginInfo?.tenantId },
        count: true,
        size: itemParPage,
        offset: (currentPage - 1) * itemParPage,
        orderBy: [{ property: "id", direction: "DESC" }],
      },
      (d) => {
        setHistory(d?.data ?? []);
        setTotalPages(Math.ceil(d?.count / itemParPage));
      }
    ));
  };

  const onSearch = () => {
    if (currentPage === 1) {
      onSearchLoginHistory();
    } else {
      setCurrentPage(1);
    }
  };

  const onFilter = () => {
    const fi = [
      {
        property: "username",
        operator: "ICONTAINS",
        value: searchText,
      }];
    if (dateFilter && searchText) {
      filter.filter = [...fi, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fi;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
  }

  const exportReport = () => {
    onFilter();
    dispatch(getLoginHistory({
      ...filter,
      criteria: { ...filter?.criteria, tenantId: globalReducer.loginInfo?.tenantId, partnerId: globalReducer.loginInfo?.partnerId },
      tenantId: globalReducer.loginInfo?.tenantId,
      count: true,
      size: 10000,
      offset: 0,
      orderBy: [{ property: "id", direction: "DESC" }]
    }, (d) => {
      const data = d?.data?.map((p, index) => {
        return {
          "Sr. No.": index,
          Username: p.username,
          LoginIp: p.loginIp,
          LoginDate: p.loginAt,
          LoginStatus: p.status
        }
      })
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data ?? []);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Login History");
      XLSX.writeFile(workbook, 'login_history.xlsx');
    }));
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "loginAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };
  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-clock-rotate-left text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('Login History')}</div>
          </div>
          <div className="flex gap-2">
            <button type='button' onClick={() => exportReport()} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]">
              {t('Download as Report')}
            </button>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker defaultValue={[dayjs().startOf("month"), dayjs()]}
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Username')}</label>
              </div>
              <input
                type="text"
                placeholder="Enter Username"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}>
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-6 pt-2 mt-[9rem] mb-[20px]">
          {history && history.length > 0 &&
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto mt-5">
                <thead className="text-[14px] text-midBlack bg-gray-100">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 px-2">
                      {t('Username')}
                    </th>
                    <th scope="col" className="font-[500] py-3 px-2">
                      {t('Login IP')}
                    </th>
                    <th scope="col" className="font-[500] py-3 px-2">
                      {t('Login Date / Time')}
                    </th>
                    <th scope="col" className="font-[500] py-3 px-2">
                      {t('Login Status')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {history?.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className={"pl-2 py-3 font-normal text-[13px]"}>
                          {p?.username}
                        </td>
                        <td className="pl-2 py-3 font-normal text-[13px]">
                          {p?.loginIp}
                        </td>
                        <td className="pl-2 py-3 font-normal text-[13px]">
                          {moment.utc(p?.loginAt).tz(wabaTimezone).format("YYYY-MM-DD hh:mm A")}
                        </td>
                        <td className="pl-2 py-3 font-normal text-[13px]">
                          {p?.status}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 mt-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
            </>}
        </div>
      </div>
    </div>
  );
}

export default LoginHistory;
