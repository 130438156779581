import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  searchWhatsAppMsgTemplate,
  sendWhatsAppMessage,
} from "../../store/actions/tenantAction";
import {
  getVariableComponent,
  onSelectTemplate,
} from "../../utils/TemplateHelper";
import { SHOW_SNACKBAR } from "../../utils/types";
import SetTemplateVariableValue from "./SetTemplateVariableValue";
import WhatsAppTemplatePreview from "../../components/WhatsAppTemplatePreview";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function QuickSingleMessage({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const [phoneNumbar, setPhoneNumbar] = useState("");
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState({
    templateType: "GENERIC",
    category: "MARKETING",
    language: "English",
    header: { type: "HEADER", format: "" },
    body: "",
    footer: "",
    buttons: [],
  });
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [carouselTemplate, setCarouselTemplate] = useState([]);

  useEffect(() => {
    if (location?.state?.isSend) {
      setPhoneNumbar(location?.state?.whatsappNumber);
    }
    dispatch(
      searchWhatsAppMsgTemplate(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          criteria: {
            active: true,
            tenantWabaId: globalReducer.waba?.id,
            status: "APPROVED",
          },
        },
        (swt) => {
          setTemplates(swt.data ?? []);
        }
      )
    );
  }, [globalReducer.waba]);

  useEffect(() => {
    if (selectedTemplateId && templates?.length > 0) {
      onSelectTemplate(
        templates,
        setSelectedTemplate,
        setTemplate,
        setCarouselTemplate,
        selectedTemplateId
      );
    }
  }, [selectedTemplateId, templates]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    getVariableComponent(
      dispatch,
      globalReducer.loginInfo,
      globalReducer.waba,
      template,
      carouselTemplate
    ).then((componentsVal) => {
      if (componentsVal) {
        phoneNumbar?.split(",")?.map((phone) => {
          dispatch(sendWhatsAppMessage({
            tenantId: globalReducer.loginInfo?.tenantId,
            contact: {
              uid: globalReducer.loginInfo?.uid,
              tenantId: globalReducer.loginInfo?.tenantId,
              whatsappNumber: parseInt((phone + "")?.replace("+", ""))?.toString(),
            },
            tenantWabaId: globalReducer.waba?.id,
            templateId: parseInt(selectedTemplateId),
            template: selectedTemplate,
            languageCode: selectedTemplate.language,
            messageType: selectedTemplate.messageType,
            type: "template",
            templateValue: { components: componentsVal },
          }, (a) => {
            dispatch({
              type: SHOW_SNACKBAR,
              payload: {
                type: "success",
                message: `Message sent`,
                open: true,
              },
            });
          }));
        });
      }
    });
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-message text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t("Quick Message")}</div>
          </div>
          <div className="flex gap-2"></div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <form
          className="mt-[5rem] mb-[20px] bg-gray-50"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-12">
            <div className="px-3 py-6 overflow-y-auto col-span-7">
              <div className="" style={{ height: "calc(-230px + 100vh)" }}>
                <div className="grid gap-3 w-full pb-2">
                  <div className="w-full">
                    <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                      {t("Enter WhatsApp Numbers with country code comma separated - Maximum 20")}
                      <span className="text-red-500 text-[14px]">*</span>
                      <p className="text-[12px] text-gray-500">
                        {t("(Example: 96899999999, 96888888888)")}
                      </p>
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        required
                        value={phoneNumbar}
                        type="text"
                        className="block w-full rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-primary focus:outline-none sm:text-sm"
                        placeholder="Enter WhatsApp Numbers with country code"
                        onChange={(e) => setPhoneNumbar(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <label
                      className="text-sm text-gray-600 font-medium"
                      id="headlessui-listbox-label-:rq:"
                    >
                      {t("Message Template")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <TypeableSelect
                      list={templates}
                      selected={selectedTemplate}
                      labelField="name"
                      valueField="id"
                      required={true}
                      placeholder="Select Template"
                      onSelect={(s) => {
                        if (s) {
                          setSelectedTemplateId(s?.id);
                          setSelectedTemplate(s);
                        }
                      }}
                    />
                  </div>
                  <div className="w-full mt-3 pt-3 border-t border-gray-400 mapDesign">
                    <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                      {t("Set Template Variable Value")}
                    </label>
                    <SetTemplateVariableValue
                      tenantId={globalReducer.loginInfo?.tenantId}
                      wabaId={globalReducer.waba?.id}
                      setTemplate={setTemplate}
                      template={template}
                      carouselTemplate={carouselTemplate}
                      setCarouselTemplate={setCarouselTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="relative flex flex-col gap-2 items-center bg-white p-2 col-span-5"
              style={{ height: "calc(-135px + 100vh)" }}
            >
              <h1 className="text-[14px] flex justify-end font-medium leading-7 capitalize text-gray-900">
                {t("Preview")}
              </h1>
              <div className="overflow-auto">
                <WhatsAppTemplatePreview
                  wabaIco={globalReducer.waba?.teamImage}
                  timezone={globalReducer.waba?.timezone}
                  chatPhone={`+${globalReducer.waba?.phoneCode} ${globalReducer.waba?.phone}`}
                  template={template}
                  carouselTemplate={carouselTemplate}
                />
              </div>
            </div>
          </div>
          <div className="px-5 flex gap-2 sticky bottom-0 justify-end w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
            <button
              type="submit"
              className="min-w-[100px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              {t("Send")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuickSingleMessage;
