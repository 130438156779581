import React, { useEffect, useState } from "react";
import { FiBriefcase } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import { changePlanStatus, getPLans } from "../../store/actions/partnerAction";
import moment from "moment-timezone";
import PopUp from "../../components/PopUp";
import AddPlan from "../../layouts/partner/AddPlan";
import PlanDetails from "../../layouts/partner/PlanDetails";
import RightSidePanel from "../../components/RightSidePanel";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function Plans({ showSidebar }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [showPlanDetails, setShowPlanDetails] = useState(false);
  const [addPlan, setAddPlan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({});
  const [dateFilter, setDateFilter] = useState();
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const partnerTimezone =
    partnerReducer.partnerDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

  useEffect(() => {
    planSearch();
  }, [currentPage]);

  const planSearch = () => {

    const fi = [
      { clause: "OPEN_BRACKET" },
      {
        property: "name",
        operator: "ICONTAINS",
        value: searchText,
      },
      { clause: "OR" },
      {
        property: "code",
        operator: "ICONTAINS",
        value: searchText,
      }, { clause: "CLOSE_BRACKET" }];

    if (dateFilter && searchText) {
      filter.filter = [...fi, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fi;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    dispatch(
      getPLans(
        {
          ...filter,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "createdAt", direction: "DESC" }]
        },
        (d) => {
          setPlanList(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      planSearch();
    } else {
      setCurrentPage(1);
    }
  };

  const selectCreatedDate = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div
            className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer"
            onClick={() => setAddPlan(false)}
          >
            <FiBriefcase className="text-theme text-[14px]" />
            <div className="text-[14px] font-medium">{t('Configurations')}</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedPlan(null);
                setAddPlan(true);
              }}
            >
              {t('Add Plan')}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Plan Details')}</label>
              </div>
              <input
                type="text"
                placeholder="Plan Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Status')}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date')}</label>
              </div>
              <RangePicker
                className="h-10"
                onChange={selectCreatedDate}
                format="YYYY-MM-DD"
              />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {planList && planList.length > 0 ? (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div> {t('Plan Name')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Country')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Plan Amount')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Billing Frequency')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t('Tenure(Months)')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Date & time')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] w-[160px]">
                      <div className="flex gap-2">
                        <div>{t('Status')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Action')}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {planList.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <th
                          scope="row"
                          className="py-3 font-medium text-[13px] pl-2 hover:underline hover:text-theme cursor-pointer text-theme"
                          onClick={() => {
                            setSelectedPlan(p);
                            setShowPlanDetails(true);
                          }}
                        >
                          {p?.name ?? "-"}
                        </th>
                        <td className="py-3 font-normal text-[13px]">
                          {p?.countryCode ?? "-"}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                          {p?.currency + "  "} {p?.rate ?? "-"}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                          {p?.billingFrequency ?? "-"}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                          {p?.durationMonth ?? "-"}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                          {moment.utc(p?.createdAt)
                            .tz(partnerTimezone)
                            .format("YYYY-MM-DD hh:mm A") ?? "-"}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                          {p?.active ? (
                            <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                              Active
                            </label>
                          ) : (
                            <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                              Not Active
                            </label>
                          )}
                        </td>
                        <td className=" py-3 font-normal text-[13px]">
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            {p?.active ? (
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(
                                    changePlanStatus(
                                      { id: p?.code, isActive: false },
                                      planSearch
                                    )
                                  );
                                }}
                              >
                                {t('Deactivate')}
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() => {
                                  dispatch(
                                    changePlanStatus(
                                      { id: p?.code, isActive: true },
                                      planSearch
                                    )
                                  );
                                }}
                              >
                                {t('Activate')}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedPlan({ ...p, isEdit: true });
                                setAddPlan(true);
                              }}
                            >
                              {t('Edit')}
                            </Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 ? (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="ml-[4rem] mr-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[250px] "
                />
                <p className="my-[24px] ">
                  {t('The space is empty . Add a Plan to get started.')}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => {
                        setSelectedPlan(null);
                        setAddPlan(true);
                      }}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t('Add Plan')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={addPlan}
        setIsShow={setAddPlan}
        ModalTitle={selectedPlan?.isEdit ? "Edit Plan" : " Add Plan"}
        Component={
          <AddPlan
            setIsShow={setAddPlan}
            onAdd={planSearch}
            isEdit={selectedPlan?.isEdit}
            defaultPlan={selectedPlan}
          />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showPlanDetails}
        setIsShow={setShowPlanDetails}
        ModalTitle=" Plan Details"
        Component={
          <PlanDetails
            setIsShow={setShowPlanDetails}
            details={selectedPlan}
          />
        }
      />
    </div>
  );
}

export default Plans;
