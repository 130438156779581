import './App.css';
import './assets/css/style.css';
import Snackbar from "./components/Snackbar";
import ApiLoader from "./components/ApiLoader";
import UnAuth from "./pages/UnAuth";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NotFound from "./pages/NotFound";
import { Route, Routes } from 'react-router-dom';
import PrivateRouteAdmin from './routes/PrivateRouteAdmin';
import PasswordResetLink from './pages/PasswordResetLink';
import CustomerApiDoc from './pages/doc/CustomerApiDoc';
import UnblockAccountLink from './pages/UnblockAccountLink';

function App() {
  return (
    <>
      <Snackbar />
      <ApiLoader />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/login/:clientId" element={<Login />} />
        {/* <Route exact path="/signup" element={<SignUp />} /> */}
        <Route
          exact
          path="/partner/:tabName?/:subtabName?"
          element={<PrivateRouteAdmin category="PARTNER" />}
        />
        <Route
          exact
          path="/tenant/:tabName?/:subtabName?"
          element={<PrivateRouteAdmin category="TENANT" />}
        />
        <Route
          exact
          path="/admin/:tabName?/:subtabName?"
          element={<PrivateRouteAdmin category="GLOBAL_ADMIN" />}
        />

        <Route
          exact
          path="/password-reset-link/:link"
          element={<PasswordResetLink />}
        />
        <Route
          exact
          path="/unblock-account-link/:link"
          element={<UnblockAccountLink />}
        />
        <Route path="/register" element={<SignUp />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/unauthorised" element={<UnAuth />} />
        <Route path="/customer-api-doc" element={<CustomerApiDoc />} />
      </Routes>
    </>
  );
}

export default App;
