import { min } from "moment";

export const downloadBlob = (blob, filename) => {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
};
export const base64ToUint8 = (str) =>
  Uint8Array.from(atob(str), (v) => v.charCodeAt(0));

export const getRandomString = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export function removeStringByIndex(str, startIndex, length) {
  return str.slice(0, startIndex) + str.slice(startIndex + length);
}

export function getPhoneWithCode(phoneWithCode, countryList) {
  let four = phoneWithCode.substring(0, 4);
  let country = countryList?.find((f) => f.phoneCode == four);
  let phoneNumber = removeStringByIndex(phoneWithCode, 0, 4);
  if (!country) {
    let three = phoneWithCode.substring(0, 3);
    country = countryList?.find((f) => f.phoneCode == three);
    phoneNumber = removeStringByIndex(phoneWithCode, 0, 3);
  }
  if (!country) {
    let two = phoneWithCode.substring(0, 2);
    country = countryList?.find((f) => f.phoneCode == two);
    phoneNumber = removeStringByIndex(phoneWithCode, 0, 2);
  }
  if (!country) {
    let one = phoneWithCode.substring(0, 1);
    country = countryList?.find((f) => f.phoneCode == one);
    phoneNumber = removeStringByIndex(phoneWithCode, 0, 1);
  }
  if (country) {
    return { phoneCode: country.phoneCode, phone: phoneNumber };
  }
  return {};
}

export const dayMap = [
  { key: "sunday", value: "Sunday", id: 0 },
  { key: "monday", value: "Monday", id: 1 },
  { key: "tuesday", value: "Tuesday", id: 2 },
  { key: "wednesday", value: "Wednesday", id: 3 },
  { key: "thursday", value: "Thursday", id: 4 },
  { key: "friday", value: "Friday", id: 5 },
  { key: "saturday", value: "Saturday", id: 6 },
];

export function getPreviousMonths(count = 7) {
  let mons = [];
  for (var i = count - 1; i >= 0; i--) {
    mons.push(getPreviousMonth(i));
  }
  return mons;
}

export function getPreviousMonth(subtract = 1) {
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - subtract);
  return previousMonthDate;
}

export const allPlanPermissions = [
  { key: "LIVE_CHAT", value: "Live Chat" },
  { key: "API", value: "Api" },
  { key: "CAMPAIGNS", value: "Campaigns" },
  { key: "FINANCE_ACCESS", value: "Finance Access" },
  { key: "CHATBOT", value: "chatbot" },
];

export const allTenantRolePermissions = [
  { key: "DASHBOARD", value: "Dashboard" },
  { key: "INTEGRATION", value: "Integration" },
  { key: "LIVE_CHAT", value: "Live Chat" },
  { key: "CHATBOT", value: "Chatbot" },
  { key: "CONTACTS", value: "Contacts" },
  { key: "TEMPLATES", value: "Templates" },
  { key: "CAMPAIGNS", value: "Campaigns" },
  { key: "API", value: "API" },
  { key: "REPORTS", value: "Reports" },
  { key: "SETTINGS", value: "Settings" },
  { key: "TEAM_SETTINGS", value: "Team Settings" },
];

export const allIntegrationList = [
  {
    name: "WhatsApp Business",
    type: "WHATSAPP",
    title:
      "Reach out to customers on WhatsApp for higher CTRs and engagement. Schedule campaigns and send push notifications based on customer actions.",
    iconPath: `${process.env.PUBLIC_URL}/images/WhatsApp-Business.png`,
  },
  {
    name: "Facebook Feed",
    type: "FACEBOOK_FEED",
    title:
      "Respond to customer comments and mentions faster from iOmnihub inbox and automate common replies to save time.",
    iconPath: `${process.env.PUBLIC_URL}/images/facebook_feed.svg`,
  },
  {
    name: "Instagram Feed",
    type: "INSTAGRAM_FEED",
    title:
      "Engage your customers on Instagram by quickly responding to all your comments and mentions from one inbox.",
    iconPath: `${process.env.PUBLIC_URL}/images/instagram_feed.svg`,
  },
  {
    name: "Instagram Chat",
    type: "INSTAGRAM_CHAT",
    title:
      "Quickly reply to customer messages on Instagram, share product images, and automate repetitive queries.",
    iconPath: `${process.env.PUBLIC_URL}/images/instagram_messenger.svg`,
  },
  {
    name: "Messenger",
    type: "MESSENGER",
    title:
      "Quickly reply to customer messages on Messenger, share product images, and automate repetitive queries.",
    iconPath: `${process.env.PUBLIC_URL}/images/facebook_messenger.svg`,
  },
];

export const whatsAppTemplateTypes = [
  {
    key: "GENERIC",
    value: "Generic",
    categories: [
      { key: "MARKETING", value: "MARKETING" },
      { key: "AUTHENTICATION", value: "AUTHENTICATION" },
      { key: "UTILITY", value: "UTILITY" },
    ],
  },
  {
    key: "CAROUSEL",
    value: "Carousel",
    categories: [
      { key: "MARKETING", value: "MARKETING" },
      { key: "UTILITY", value: "UTILITY" },
    ],
  },
];

export const whatsAppLanguages = [
  { value: "Afrikaans", key: "af", direction: "ltr" },
  { value: "Albanian", key: "sq", direction: "ltr" },
  { value: "Arabic", key: "ar", direction: "rtl" },
  { value: "Arabic (EGY)", key: "ar_EG", direction: "rtl" },
  { value: "Arabic (UAE)", key: "ar_AE", direction: "rtl" },
  { value: "Arabic (LBN)", key: "ar_LB", direction: "rtl" },
  { value: "Arabic (MAR)", key: "ar_MA", direction: "rtl" },
  { value: "Arabic (QAT)", key: "ar_QA", direction: "rtl" },
  { value: "Azerbaijani", key: "az", direction: "ltr" },
  { value: "Belarusian", key: "be_BY", direction: "ltr" },
  { value: "Bengali", key: "bn", direction: "ltr" },
  { value: "Bengali (IND)", key: "bn_IN", direction: "ltr" },
  { value: "Bulgarian", key: "bg", direction: "ltr" },
  { value: "Catalan", key: "ca", direction: "ltr" },
  { value: "Chinese (CHN)", key: "zh_CN", direction: "ltr" },
  { value: "Chinese (HKG)", key: "zh_HK", direction: "ltr" },
  { value: "Chinese (TAI)", key: "zh_TW", direction: "ltr" },
  { value: "Croatian", key: "hr", direction: "ltr" },
  { value: "Czech", key: "cs", direction: "ltr" },
  { value: "Danish", key: "da", direction: "ltr" },
  { value: "Dari", key: "prs_AF", direction: "rtl" },
  { value: "Dutch", key: "nl", direction: "ltr" },
  { value: "Dutch (BEL)", key: "nl_BE", direction: "ltr" },
  { value: "English", key: "en", direction: "ltr" },
  { value: "English (UK)", key: "en_GB", direction: "ltr" },
  { value: "English (US)", key: "en_US", direction: "ltr" },
  { value: "English (UAE)", key: "en_AE", direction: "ltr" },
  { value: "English (AUS)", key: "en_AU", direction: "ltr" },
  { value: "English (CAN)", key: "en_CA", direction: "ltr" },
  { value: "English (GHA)", key: "en_GH", direction: "ltr" },
  { value: "English (IRL)", key: "en_IE", direction: "ltr" },
  { value: "English (IND)", key: "en_IN", direction: "ltr" },
  { value: "English (JAM)", key: "en_JM", direction: "ltr" },
  { value: "English (MYS)", key: "en_MY", direction: "ltr" },
  { value: "English (NZL)", key: "en_NZ", direction: "ltr" },
  { value: "English (QAT)", key: "en_QA", direction: "ltr" },
  { value: "English (SGP)", key: "en_SG", direction: "ltr" },
  { value: "English (UGA)", key: "en_UG", direction: "ltr" },
  { value: "English (ZAF)", key: "en_ZA", direction: "ltr" },
  { value: "Estonian", key: "et", direction: "ltr" },
  { value: "Filipino", key: "fil", direction: "ltr" },
  { value: "Finnish", key: "fi", direction: "ltr" },
  { value: "French", key: "fr", direction: "ltr" },
  { value: "Hebrew", key: "he", direction: "rtl" },
  { value: "Hindi", key: "hi", direction: "ltr" },
  { value: "Kurdish", key: "ku", direction: "rtl" },
  { value: "Persian", key: "fa", direction: "rtl" },
  { value: "Pashto", key: "ps_AF", direction: "rtl" },
  { value: "Sindhi", key: "sd", direction: "rtl" },
  { value: "Urdu", key: "ur", direction: "rtl" },
  { value: "Yiddish", key: "yi", direction: "rtl" },
];


export const systemLanguages = [
  { value: "Arabic", key: "ar", direction: "rtl" },
  { value: "English", key: "en", direction: "ltr" }
];

export const broadcastStatusColor = {
  draft: "#F3F4F6",
  pending: "#F3F4F6",
  completed: "#D8FAE2",
  in_progress: "#FFC107",
  failed: "#F7E3E1",
  suspend: "#9E9E9E",
  call_off: "#FFCDD2",
  cancel: "#FFCDD2",
};

export const msgStatusColor = {
  scheduled: "#FFFFFF",
  delivered: "#D8FAE2",
  sent: "#D8FAE2",
  queued: "#F0ECBF",
  read: "#EBE6FB",
  replied: "#EBE6FB",
  failed: "#F7E3E1",
  invalid: "#F7E3E1",
};

export const msgStatusTextColor = {
  scheduled: "#BFC1C4",
  delivered: "#488260",
  sent: "#488260",
  queued: "#488260",
  read: "#704ac9",
  replied: "#704ac9",
  failed: "#ea4f4f",
  invalid: "#ea4f4f",
};

export const priorityTextColor = {
  LOW: "[#008E4B]",
  MEDIUM: "[#6466F1]",
  HIGH: "[#F59E0C]",
  URGENT: "[#FF2322]",
};

export const allTags = [
  { text: "New Lead" },
  { text: "Follow-up Required" },
  { text: "Qualified Lead" },
  { text: "Lost Lead" },
  { text: "High Priority" },
  { text: "Pending Resolution" },
  { text: "Resolved" },
  { text: "Escalated" },
];

export const phoneLength = {
  1: { min: 10, max: 10 },
  44: { min: 10, max: 11 },
  91: { min: 10, max: 10 },
  61: { min: 9, max: 9 },
  49: { min: 5, max: 11 },
  33: { min: 9, max: 9 },
  86: { min: 11, max: 11 },
  81: { min: 10, max: 11 },
  55: { min: 10, max: 11 },
  52: { min: 10, max: 10 },
  7: { min: 10, max: 10 },
  27: { min: 9, max: 9 },
  39: { min: 9, max: 10 },
  34: { min: 9, max: 9 },
  971: { min: 9, max: 9 },
  62: { min: 9, max: 11 },
  968: { min: 8, max: 8 },
  971: { min: 9, max: 9 },
};

export const toTitleCase = (str) => {
  if (!str) return '';
  return str
    .toLowerCase() // Convert to lowercase
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

export const getMonthsBetween = (fromDate, toDate) => {
  const months = [];
  let current = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
  const end = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
  while (current <= end) {
    months.push(current.toLocaleString("default", { month: "long", year: "numeric" }));
    current.setMonth(current.getMonth() + 1);
  }
  return months;
};

export function findCountryByPhone(phoneWithCode, countries) {
  // Remove all non-digit characters from the phone number
  const cleanedNumber = phoneWithCode.toString().replace(/\D/g, "");

  // Iterate through the list of countries
  for (const country of countries) {
    const phoneCode = country.phoneCode?.toString();
    if (phoneCode && cleanedNumber.startsWith(phoneCode)) {
      // Return the modified phone number and the matching country
      return {
        phoneNumber: cleanedNumber.replace(new RegExp(`^${phoneCode}`), ""),
        country: country,
      };
    }
  }

  // Return null if no match is found
  return null;
}

export async function getAddress(latitude, longitude) {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    const data = await response.json();
    return data; //data.display_name
  } catch (error) {
    console.error("Error fetching address:", error);
    return {};
  }
}

export const validatePassword = (password, confirmPassword) => {
  if (password) {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!/\d/.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!/[@$!%*?&]/.test(password)) {
      return "Password must contain at least one special character (@, $, !, %, *, ?, &).";
    }
  }
  if (confirmPassword) {
    if (password !== confirmPassword) {
      return "password and confirm password should be same";
    }
  }
  return ""; // No error, valid password
};

export const getPercentage = (total, value) => {
  try {
    if (total === 0) return "0%";
    return `${Math.round((parseInt(value) / parseInt(total)) * 100)}%`;
  } catch {
    return "0%";
  }
};