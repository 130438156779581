import React, { useEffect, useState } from "react";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import { useDispatch, useSelector } from "react-redux";
import {
  getTenants,
  partnerWabaUpsert,
  searchBsp,
} from "../../store/actions/partnerAction";
import SelectTimezone from "../../components/Dropdowns/SelectTimezone";
import MobileInput from "../../components/MobileInput";
import SelectDateTime from "../../components/SelectDateTime";
import FetchApiKey from "./FetchApiKey";
import PopUp from "../../components/PopUp";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";
import { getWalletInfo } from "../../store/actions/tenantAction";
import { useNavigate } from "react-router-dom";
import { SHOW_SNACKBAR } from "../../utils/types";

function AddWaba({ setIsShow, isEdit, waba, isView, onAdd, onEditRateCard, onEditPlan }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedWaba, setSelectedWaba] = useState(waba ?? { config: {} });
  const [integrationType, setIntegrationType] = useState("");
  const [walletInfo, setWalletInfo] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [bspList, setBspList] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [showGetApiKey, setShowGetApiKey] = useState(false);
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    dispatch(searchBsp({}, (b) => setBspList(b.data)));
    if (!isEdit) {
      const country = globalReducer.country.find(
        (f) => f.code === partnerReducer.partnerDetails.countryCode
      );
      if (country) {
        selectedWaba.countryCode = country.code;
        selectedWaba.currencyCode = country.currencyCode;
        selectedWaba.phoneCode = country?.phoneCode;
        selectedWaba.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    } else {
      dispatch(getWalletInfo(waba?.tenantId, { tenantWabaId: waba?.id }, setWalletInfo));
    }
    dispatch(
      getTenants(
        {
          criteria: { active: true },
          count: true,
          size: 500,
          offset: 0,
        },
        (d) => {
          setCompanyList(d?.data);
          if (d?.data?.length > 0) {
            if (!isEdit) {
              selectedWaba.tenantId = d?.data[0].id;
              selectedWaba.bspId = d?.data[0]?.bspId;
              selectedWaba.countryCode = d?.data[0]?.countryCode;
            }
          }
        }
      )
    );
    setSelectedWaba({ ...selectedWaba });
    setCurrencyList(globalReducer.country.filter((item, index, self) =>
      index === self.findIndex((fid) => fid["currencyCode"] === item["currencyCode"] && item["currencyCode"])
    ))
  }, []);

  useEffect(() => {
    if (bspList?.length > 0 && selectedWaba?.bspId) {
      const bsp = bspList?.find((f) => f.id === selectedWaba?.bspId);
      setIntegrationType(bsp?.integrationType);
      setSelectedWaba({ ...selectedWaba, bsp: bsp });
    }
  }, [selectedWaba?.bspId, bspList]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isView) {
      dispatch(
        partnerWabaUpsert(selectedWaba, (d) => {
          setIsShow(false);
          onAdd(d);
        })
      );
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setSelectedWaba({ ...selectedWaba, teamImage: fileReader.result });
      };
      fileReader.onerror = (error) => { };
    }
  };

  const handleCopy = (data) => {
    navigator.clipboard
      .writeText(data)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 5000); // Reset after 2 seconds
      })
      .catch((error) => console.error("Copy failed", error));
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (<div>
    <form
      disabled={isView}
      className="height30 flex flex-col justify-between pb-3"
      onSubmit={handleSubmit}
    >
      <div className="h-[85vh] overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-3 w-full updateWaba">
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Company Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <TypeableSelect disabled={isView} height="40px" list={companyList} selected={selectedWaba} labelField="name" selectedValueField="tenantId" valueField="id" required={true} placeholder={t("Company Name")} onSelect={(s) => {
              setSelectedWaba({
                ...selectedWaba,
                tenantId: s?.id,
                bspId: s?.bspId,
                countryCode: s?.countryCode,
              });
            }} />
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Team/WhatsApp Account Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="text"
              placeholder={t("Enter Team Name")}
              value={selectedWaba?.teamName}
              onChange={(e) =>
                setSelectedWaba({
                  ...selectedWaba,
                  teamName: e.target.value,
                  whatsappDisplayName: e.target.value,
                })
              }
              className="border border-gray-300 rounded-md py-2 h-10 w-[100%] text-[13px]"
              required
            />
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Account Type')}</label>
            </div>
            <select required className="border border-gray-300 rounded-md min-w-[200px] py-2 px-4 cursor-pointer text-[14px]"
              value={selectedWaba?.accountType}
              onChange={(e) => {
                setSelectedWaba({ ...selectedWaba, accountType: e.target.value });
              }}>
              <option value="PREPAID">{t('Prepaid')}</option>
              <option value="POSTPAID">{t('Postpaid')}</option>
            </select>
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Country Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <SelectCountry height="40px" disabled={isView}
              required={true}
              selectedCode={selectedWaba?.countryCode}
              countryList={globalReducer.country}
              onSelectCountry={(country) => {
                if (country) {
                  selectedWaba.countryCode = country.code;
                  selectedWaba.currencyCode = country.currencyCode;
                } else {
                  delete selectedWaba.countryCode;
                  delete selectedWaba.currencyCode;
                }
                setSelectedWaba({ ...selectedWaba });
              }}
              placeholder={t("Select Country")}
            />
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Currency Type')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <div className="w-full border-1 border gray-300 text-gray-500 py-1.5 px-3">
              {currencyList?.find(f => f.currencyCode === selectedWaba.currencyCode)?.currency}
            </div>
            {/* <TypeableSelect disabled={true} height="40px" list={currencyList} selected={selectedWaba} labelField="currency" selectedValueField="currencyCode" valueField="currencyCode" required={true} placeholder={t("Select Currency")} onSelect={(s) => {
              setSelectedWaba({ ...selectedWaba, currencyCode: s?.currencyCode });
            }} /> */}
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Plan Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <div className="flex gap-3 py-1">
              <label className="text-[14px]">{walletInfo?.planName ?? "Plan Name"}</label>
              <button type="button" className="text-theme rounded-[4px]"
                onClick={() => {
                  navigate("/partner/company-management", {
                    state: { isEditPlan: true, selectedWaba: selectedWaba },
                  });
                }}>
                {"Edit"}
              </button>
            </div>
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Rate Card Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <div className="flex gap-3 py-1">
              <label className="text-[14px]">{walletInfo?.rateCardName ?? "Rate Card Name"}</label>
              <button type="button" className="text-theme rounded-[4px]"
                onClick={() => {
                  if (!selectedWaba?.id) {
                    dispatch({
                      type: SHOW_SNACKBAR,
                      payload: {
                        type: "error",
                        message: "Rate Card is not available.",
                        open: true,
                      },
                    });
                  } else {
                    onEditRateCard()
                  }
                }}>
                {"Edit"}
              </button>
            </div>
          </div>
          <div className="col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Timezone')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <SelectTimezone height="40px" disabled={isView}
              required={true}
              TimezoneList={globalReducer.timezone}
              selectedZone={selectedWaba?.timezone}
              onSelectTimezone={(zone) => {
                if (zone) {
                  selectedWaba.timezone = zone.name;
                } else {
                  delete selectedWaba.timezone;
                }
                setSelectedWaba({ ...selectedWaba });
              }}
            />
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('BSP')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select
              disabled
              required
              className="border border-gray-300 rounded-md py-1 px-4 cursor-pointer h-[40px]"
              value={selectedWaba?.bspId}
              onChange={(e) => {
                setSelectedWaba({ ...selectedWaba, bspId: e.target.value });
              }}
            >
              <option value="" selected disabled>
                {t('Select BSP')}
              </option>
              {bspList?.map((option) => {
                return <option value={option?.id}>{option?.name}</option>;
              })}
            </select>
          </div>
          {/* {(integrationType !== "PINNACLE" || isEdit === true) && (
            <>

              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Phone Number')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <MobileInput
                  disabled={isEdit}
                  className="py-2"
                  required={true}
                  countryList={globalReducer.country}
                  phoneCode={selectedWaba.phoneCode}
                  phone={selectedWaba.phone}
                  setPhoneCode={(phone) =>
                    setSelectedWaba({ ...selectedWaba, phoneCode: phone })
                  }
                  setPhone={(phone) =>
                    setSelectedWaba({ ...selectedWaba, phone: phone })
                  }
                  placeholder={t("Enter Phone Number")}
                />
              </div>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Waba Number')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="text"
                  disabled={isEdit}
                  placeholder={t("Enter Waba Number")}
                  value={selectedWaba?.wabaNumber}
                  onChange={(e) =>
                    setSelectedWaba({
                      ...selectedWaba,
                      wabaNumber: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded-md py-2 h-10 w-[100%] text-[13px]"
                  required
                />
              </div>
            </>
          )} */}
          {/* {(integrationType !== "PINNACLE" || isEdit === true) && (
            <>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Phone NumberId')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="text"
                  disabled={isEdit}
                  placeholder={t("Enter Phone NumberId")}
                  value={selectedWaba?.phoneNumberId}
                  onChange={(e) =>
                    setSelectedWaba({
                      ...selectedWaba,
                      phoneNumberId: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded-md py-2 h-10 w-[100%] text-[13px]"
                  required
                />
              </div>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Template Namespace')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="text"
                  placeholder={t("Enter Template Namespace")}
                  value={selectedWaba?.messageTemplateNamespace}
                  onChange={(e) =>
                    setSelectedWaba({
                      ...selectedWaba,
                      messageTemplateNamespace: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded-md py-2 h-10 w-[100%] text-[13px]"
                />
              </div>
            </>
          )} */}
          {integrationType === "PINNACLE" ? (
            <div className="col-span-6">
              <div className="flex justify-between">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Api key')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                {!selectedWaba?.config?.apiKey && <div onClick={() => setShowGetApiKey(true)}>
                  <label className="text-theme text-[14px] underline ease-in-out rounded cursor-pointer">{t('Get Api key')}</label>
                </div>}
              </div>
              <div className='flex gap-2'>
                <input
                  type="text"
                  required readOnly={isEdit}
                  placeholder={t("Enter Api key")}
                  value={selectedWaba?.config?.apiKey}
                  onChange={(e) => {
                    if (!selectedWaba?.config) {
                      selectedWaba.config = {};
                    }
                    selectedWaba.config.apiKey = e.target.value;
                    setSelectedWaba({ ...selectedWaba });
                  }}
                  className="border border-gray-300 rounded-md py-2 h-10 w-[100%] text-[13px]"
                />
                <button type="button" className="text-green-500 rounded-[4px]"
                  onClick={() => handleCopy(selectedWaba?.config?.apiKey)}>
                  {isCopied ? "Copied" : "Copy"}
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Access Token')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <div className='flex gap-2'>
                  <input
                    type="text" readOnly={isEdit}
                    placeholder={t("Enter Access Token")}
                    required
                    value={selectedWaba?.accessToken}
                    onChange={(e) => {
                      setSelectedWaba({
                        ...selectedWaba,
                        accessToken: e.target.value,
                      });
                    }}
                    className="border border-gray-300 rounded-md py-2 h-10 w-[100%] text-[13px]"
                  />
                  <button type="button" className="text-green-500 rounded-[4px]"
                    onClick={() => handleCopy(selectedWaba?.accessToken)}>
                    {isCopied ? "Copied" : "Copy"}
                  </button>
                </div>
              </div>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Token Expires')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <div className="border border-gray-300 rounded-md">
                  <SelectDateTime
                    timezone={selectedWaba?.timezone}
                    required={true}
                    value={selectedWaba?.tokenExpires}
                    onChange={(e) =>
                      setSelectedWaba({ ...selectedWaba, tokenExpires: e })
                    }
                  />
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Team Description')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <textarea
              required
              placeholder={t("Enter team description")}
              rows="3"
              value={selectedWaba?.teamDescription}
              onChange={(e) =>
                setSelectedWaba({
                  ...selectedWaba,
                  teamDescription: e.target.value,
                })
              }
              className="border border-gray-300 rounded-md text-[13px] px-1 py-1 w-[100%]"
            />
          </div>
          <div className="gap-4 border p-2 rounded-lg col-span-12">
            <div className="flex flex-col col-span-12">
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Team Avatar')}</label>
              </div>
              {selectedWaba?.teamImage ? (
                <div className="border-2 border-gray-300 border-dashed rounded-lg w-full max-h-[220px]">
                  <div className="flex justify-end relative uploadLogo">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedWaba({ ...selectedWaba, teamImage: null });
                      }}
                      className="absolute inline-block right-[10px] top-[10px]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-x w-3.5 h-3.5 "
                      >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </button>
                  </div>
                  <img
                    src={selectedWaba?.teamImage}
                    alt="Preview"
                    className="mt-1 max-h-[210px]"
                    style={{ height: "auto", borderRadius: "10px" }}
                  />
                </div>
              ) : (
                <label
                  htmlFor="avatar-dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-45 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-5">
                    <svg
                      className="w-8 h-8 text-theme"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="hover:underline text-theme">
                        {t('Click to upload')}&nbsp;
                      </span>
                      {t('or drag and drop')}
                    </p>
                    <p className="text-xs text-gray-500">
                      {t('*Please Upload File in png, jpeg, jpg Format Only.')}
                    </p>
                  </div>
                  <input
                    id="avatar-dropzone-file"
                    type="file"
                    className="hidden"
                    accept=".png,.jpeg,.jpg"
                    multiple={false}
                    onChange={handleImageChange}
                  />
                </label>
              )}
            </div>
          </div>
          <div className="col-span-4 item-end mt-[2px]">
            <label className="inline-flex items-center cursor-pointer">
              <input type="checkbox" onChange={(e) => {
                setSelectedWaba({ ...selectedWaba, active: e.target.checked === true })
              }} checked={selectedWaba?.active} className="sr-only peer" />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 ">Active</span>
            </label>
          </div>
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          {!isView && (
            <button
              type="submit"
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              {t('Save')}
            </button>
          )}
        </div>
      </div>
    </form>
    <PopUp
      ModalHeader={
        <span className="font-medium text-[18px] text-black">
          {t('Fetch Api Key')}
        </span>
      }
      isShowModal={showGetApiKey}
      setIsShowModal={setShowGetApiKey}
      ModalBody={<FetchApiKey setIsShow={setShowGetApiKey} selectedWaba={selectedWaba} onGetApiKey={setSelectedWaba} />}
      size="sm"
    />
  </div>);
}

export default AddWaba;
