import { mediaUploaderByUrl } from "../store/actions/globalAction";
import { whatsAppLanguages } from "./helper";
import { IS_LOADING, SHOW_SNACKBAR } from "./types";

export const onSelectTemplate = (templates, setSelectedTemplate, setTemplate, setCarouselTemplate, templateId) => {
    const t = templates.find(f => f.id == templateId);
    setSelectedTemplate(t);
    const carouselT = [];
    if (t) {
        const tpl = {};
        t?.components?.map(c => {
            switch (c.type.toLowerCase()) {
                case "header":
                    tpl["header"] = {
                        type: "HEADER",
                        format: c.format,
                        text: c.text
                    };
                    if (c.format && c?.example) {
                        if (c.format === "TEXT" && Array.isArray(c?.example?.header_text))
                            tpl.header.headerVariables = c.example.header_text.map(h => { return { text: h } });
                        else if (Array.isArray(c?.example?.header_handle)) {
                            tpl.header["example"] = c.example;
                        }
                    }
                    else if (c.location) {
                        tpl.header["location"] = c.location;
                    }
                    break;
                case "body":
                    tpl["body"] = c.text;
                    if (t.messageType === "AUTHENTICATION") {
                        tpl["add_security_recommendation"] = c.add_security_recommendation;
                        tpl["body"] = `{{1}} is your verification code. ${c.add_security_recommendation ? "For your security, do not share this code." : ""}`;
                    }
                    if (c?.example && Array.isArray(c?.example?.body_text)) {
                        tpl.bodyVariables = c.example.body_text[0].map(h => { return { text: h } });
                    }
                    break;
                case "footer":
                    tpl["footer"] = c.text;
                    if (t.messageType === "AUTHENTICATION") {
                        tpl["code_expiration_minutes"] = c.code_expiration_minutes;
                        if (c.code_expiration_minutes) {
                            tpl["isExpireIn"] = true;
                            tpl["footer"] = `This code expires in ${c.code_expiration_minutes ?? ""} minutes.`;
                        }
                    }
                    break;
                case "buttons":
                    tpl["buttons"] = c.buttons;
                    if (c.buttons?.length > 0) {
                        c.buttons.map(b => {
                            if (b.type === "URL" && Array.isArray(b.example)) {
                                b["variableValues"] = b.example;
                                b["isVariable"] = true;
                            }
                        });
                    }
                    break;
                case "carousel":
                    c.cards?.map(t => {
                        const cul = {};
                        t.components?.map(p => {
                            switch (p.type.toLowerCase()) {
                                case "header":
                                    cul["format"] = p.format;
                                    if (p.format && p?.example && Array.isArray(p?.example?.header_handle)) {
                                        cul["example"] = p.example;
                                    }
                                    break;
                                case "body":
                                    cul["body"] = p.text;
                                    break;
                                case "buttons":
                                    cul["buttons"] = p.buttons;
                                    if (p.buttons?.length > 0) {
                                        p.buttons.map(b => {
                                            if (p.type === "URL" && Array.isArray(p.example)) {
                                                p["variableValues"] = p.example;
                                                b["isVariable"] = true;
                                            }
                                        });
                                    }
                                    break;
                            }
                        });
                        carouselT.push(cul);
                    });
                    break;
            }
        });
        setTemplate({ ...tpl, name: t.name, language: t.language, templateType: t.templateType, category: t.messageType, languageDirection: whatsAppLanguages.find(f => f.key == t.language)?.direction });
        if (t.templateType === "CAROUSEL") {
            setCarouselTemplate(carouselT);
        } else {
            setCarouselTemplate([]);
        }
    }
}

export const getVariableComponent = async (dispatch, loginInfo, waba, template, carouselTemplate) => {
    try {
        dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: true } });
        const components = [];
        const bodyVariables = [];
        const headerVariables = [];
        template?.bodyVariables?.map(v => {
            bodyVariables.push({ type: "text", text: v.text });
        });
        if (bodyVariables.length > 0) {
            components.push({ type: "body", parameters: bodyVariables });
        }
        if (template?.header?.format === "TEXT") {
            template?.header?.headerVariables?.map(v => {
                headerVariables.push({ type: "text", text: v.text });
            });
            if (headerVariables.length > 0) {
                components.push({ type: "header", parameters: headerVariables });
            }
        } else if (template?.header?.format === "LOCATION") {
            components.push({
                type: "header", parameters: [{
                    type: template?.header?.format?.toLowerCase(),
                    [template?.header?.format?.toLowerCase()]: { ...template?.header?.location, name: template?.header?.location?.name ?? template?.header?.location?.address }
                }]
            });
        }
        else if (template?.header?.format) {
            const media = await mediaUploaderByUrl(loginInfo?.tenantId, waba?.id, template?.header?.example?.url, template?.header?.example?.filename ?? "");
            components.push({
                type: "header", parameters: [{
                    type: template?.header?.format?.toLowerCase(),
                    [template?.header?.format?.toLowerCase()]: {
                        "id": template?.header?.example?.id ? template?.header?.example?.id : media?.id,
                        caption: template?.header?.example?.filename,
                        filename: template?.header?.example?.filename
                    }
                }]
            });
        }
        if (template?.buttons?.length > 0) {
            template?.buttons?.map((b, i) => {
                if (b.type === "URL" && b.variableValues?.length > 0) {
                    let btnVariables = [];
                    b.variableValues.map(v => {
                        btnVariables.push({ type: "text", text: v });
                    });
                    components.push({ type: "button", sub_type: "URL", index: i, parameters: btnVariables });
                } else if (b.type === "OTP" && b.otp_type === "COPY_CODE") {
                    let btnVariables = [{ type: "text", text: bodyVariables[0].text }];
                    components.push({ type: "button", sub_type: "URL", index: i, parameters: btnVariables });
                }
            });
        }
        const cards = [];
        if (carouselTemplate.length > 0) {
            await Promise.all(carouselTemplate.map(async (m, i) => {
                const cardComponents = [];
                if (m.format) {
                    const media = await mediaUploaderByUrl(loginInfo?.tenantId, waba?.id, m?.example?.url, m?.example?.filename ?? "");
                    cardComponents.push({
                        type: "header",
                        parameters: [
                            {
                                "type": m.format?.toLowerCase(),
                                [m.format?.toLowerCase()]: {
                                    "id": m?.example?.id ? m?.example?.id : media?.id,
                                    caption: m?.example?.filename,
                                    filename: m?.example?.filename
                                }
                            }
                        ]
                    });
                }
                if (m.buttons?.length > 0) {
                    m.buttons?.map(b => {
                        if (b.type === "URL" && b.variableValues?.length > 0) {
                            const btnVariables = [];
                            b.variableValues.map(d => {
                                btnVariables.push({ type: "text", text: d });
                            });
                            cardComponents.push({ type: "button", sub_type: "url", index: i, parameters: btnVariables });
                        }
                    });
                }
                cards.push({ card_index: i, components: cardComponents });
            }));
            components.push({ type: "carousel", cards: cards });
        }
        dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: false } });
        return components;
    } catch (e) {
        dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: false } });
        return null;
    }
}

export const setSelectedTemplateMessage = (t, messageValue, setTemplate, setCarouselTemplate) => {
    let headerValue = [];
    let carouselHeaderValue = [];
    let bodyValues = [];
    const carouselTemplate = [];
    if (messageValue?.components?.length > 0) {
        headerValue = messageValue?.components?.filter(f => f.type?.toLowerCase() === "header");
        carouselHeaderValue = messageValue?.components?.filter(f => f.type?.toLowerCase() === "carousel");
        bodyValues = messageValue?.components?.filter(f => f.type?.toLowerCase() === "body");
    }
    if (t) {
        const tpl = {};
        t?.components?.map(c => {
            switch (c.type.toLowerCase()) {
                case "header":
                    tpl["header"] = {
                        type: "HEADER",
                        format: c.format,
                        text: c.text
                    };
                    if (c.format && c?.example) {
                        if (c.format === "TEXT" && Array.isArray(c?.example?.header_text))
                            tpl.header.headerVariables = c.example.header_text.map(h => { return { text: h } });
                        else if (Array.isArray(c?.example?.header_handle)) {
                            tpl.header["example"] = c.example;
                            if (headerValue?.length > 0) {
                                const meId = headerValue[0]?.parameters[0][c.format?.toLowerCase()];
                                tpl.header["example"] = { ...c.example, mediaId: meId?.id, filename: meId?.filename };
                            }
                        }
                    }
                    else if (c.location) {
                        tpl.header["location"] = c.location;
                    }
                    break;
                case "body":
                    tpl["body"] = c.text;
                    if (t.messageType === "AUTHENTICATION") {
                        tpl["add_security_recommendation"] = c.add_security_recommendation;
                        tpl["body"] = `{{1}} is your verification code. ${c.add_security_recommendation ? "For your security, do not share this code." : ""}`;
                    }
                    if (bodyValues?.length > 0 && Array.isArray(bodyValues[0]?.parameters) && bodyValues[0]?.parameters.length > 0) {
                        tpl.bodyVariables = bodyValues[0]?.parameters.map(h => { return { text: h.text } });
                    }
                    else if (c?.example && Array.isArray(c?.example?.body_text)) {
                        tpl.bodyVariables = c.example.body_text[0].map(h => { return { text: h } });
                    }
                    break;
                case "footer":
                    tpl["footer"] = c.text;
                    if (t.messageType === "AUTHENTICATION") {
                        tpl["code_expiration_minutes"] = c.code_expiration_minutes;
                        if (c.code_expiration_minutes) {
                            tpl["isExpireIn"] = true;
                            tpl["footer"] = `This code expires in ${c.code_expiration_minutes ?? ""} minutes.`;
                        }
                    }
                    break;
                case "buttons":
                    tpl["buttons"] = c.buttons;
                    if (c.buttons?.length > 0) {
                        c.buttons.map(b => {
                            if (b.type === "URL" && Array.isArray(b.example)) {
                                b["variableValues"] = b.example;
                                b["isVariable"] = true;
                            }
                        });
                    }
                    break;
                case "carousel":
                    c.cards?.map(t => {
                        const cul = {};
                        t.components?.map((p, ind) => {
                            switch (p.type.toLowerCase()) {
                                case "header":
                                    cul["format"] = p.format;
                                    if (p.format && p?.example && Array.isArray(p?.example?.header_handle)) {
                                        cul["example"] = p.example;
                                        if (carouselHeaderValue?.length > 0) {
                                            cul["example"] = { ...p.example, mediaId: carouselHeaderValue[0]?.cards?.find(fc => fc.card_index === ind)?.components[0]?.parameters[0][p.format?.toLowerCase()]?.id }
                                        }
                                    }
                                    break;
                                case "body":
                                    cul["body"] = p.text;
                                    break;
                                case "buttons":
                                    cul["buttons"] = p.buttons;
                                    if (p.buttons?.length > 0) {
                                        p.buttons.map(b => {
                                            if (p.type === "URL" && Array.isArray(p.example)) {
                                                p["variableValues"] = p.example;
                                                b["isVariable"] = true;
                                            }
                                        });
                                    }
                                    break;
                            }
                        });
                        carouselTemplate.push(cul);
                    });
                    break;
            }
        });
        setTemplate({ ...tpl, name: t.name, language: t.language, templateType: t.templateType, category: t.messageType, languageDirection: whatsAppLanguages.find(f => f.key == t.language)?.direction });
        setCarouselTemplate([...carouselTemplate]);
    }
}