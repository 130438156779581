import { HttpUtil } from "../../utils/http-utils";
import {
    SHOW_SNACKBAR,
    IS_LOADING,
    getToken,
    getRefreshToken,
    TENANT_DETAILS
} from "../../utils/types";

const getApiLoaderDispatch = (res, dispatch) => {
    dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

function postAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOST(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch((e) => {
            console.log("error", e);
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function postParamsAction(dispatch, url, isApiLoader, request, Params, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOSTwithParams(url, request, Params, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeGET(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function deleteAction(dispatch, url, isApiLoader, params, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeDELETE(url, params, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

export const getTenantDetails = (request, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/${request.tenantId}/details`, true, {}, { Authorization: `bearer ${getToken()}` }, (a) => {
        if (a) {
            dispatch({ type: TENANT_DETAILS, payload: a });
            action(a);
        }
    });

export const getTenantUsers = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantUserDetail = (id, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/detail/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const tenantUserUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeUserStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/${request.tenantId}/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const searchTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getAllTenantWaba = (action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const upsertTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateTenantWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateTeam = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/team/${request.tenantId}/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeTenantWabaStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/${request.tenantId}/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantPlans = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/plan/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantPlanUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/plan/${request.tenantId}/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantRateCardDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/rate-card/${request.tenantId}/details`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertTenantRateCardDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/rate-card/${request.tenantId}/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantContacts = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantOptOutContacts = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/${request.tenantId}/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantContactUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantContactBulkUpload = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/bulk-upload`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeContactStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const embeddedSignup = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-waba/facebook-embedded-signup`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeWhatsAppMsgTemplateStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWhatsAppMsgTemplate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateTemplateStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/template-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const searchWhatsAppMsgTemplate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const whatsAppMsgTemplateCreate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/create`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const whatsAppMsgTemplateUpdate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const deleteWhatsAppMsgTemplate = (request, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-template/${request.tenantId}/delete/${request.id}`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const sendWhatsAppMessage = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-message/${request.tenantId}/send`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const sendLiveChatMessage = (request, action, isApiLoader = true) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/live-chat/send`, isApiLoader, request, { Authorization: `bearer ${getToken()}` }, action);

export const initiateLiveChat = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/live-chat/initiate-live-chat`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/${request.tenantId}/category-query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryView = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/${request.tenantId}/category-query-view`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/category-upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const categoryDelete = (id, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/category-delete/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const bulkCategoryDelete = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/bulk-category-delete`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const contactDelete = (id, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/contact-delete/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const bulkContactDelete = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/contact/bulk-contact-delete`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const campaignQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const campaignCreate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/create`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const saveAsDraft = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/save-as-draft`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateCampaignStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/update-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const checkAndGenerateApiKey = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/api-access/${request.tenantId}/check-generate`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateApiKey = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/api-access/${request.tenantId}/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWalletByWaba = (request, tenantId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/${tenantId}/wallet-by-waba`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const calculateBroadcastPayment = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${request.tenantId}/calculate-broadcast-payment`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const messageCount = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/dashboard/message-count`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const messageCountChart = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/dashboard/message-count-chart`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const walletHistoryQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/${request.tenantId}/wallet-history`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getAccountInformation = (action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/plan/account-information`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getLoginHistory = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/login/history`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getDashboardInfo = (wabaId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/dashboard/info/${wabaId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getCampaignReport = (tenantId, campaignId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${tenantId}/${campaignId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getConversation = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/whatsapp-message/${request.tenantId}/conversation`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWebhookLog = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/api-log/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getCampaignContact = (tenantId, campaignId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/campaign/${tenantId}/campaign-contact/${campaignId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getOptOutContacts = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const optOutContactUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const optOutContactBulkUpload = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/bulk-upload`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeOptOutContactStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const optOutContactDelete = (id, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/opt-out-contact-delete/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const bulkOptOutCContactDelete = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/opt-out-contact/bulk-opt-out-contact-delete`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const initiateTransaction = (request, type, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/transaction/initiate/${type}`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTransactionHistory = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/transaction/history/${request.tenantId}`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTransaction = (tenantId, transactionId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/transaction/${tenantId}?transactionId=${transactionId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const receiptStatusUpdate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/transaction/receipt-status-update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const walletTopUpByPartner = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/top-up-partner`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWalletTransfers = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/transfers`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const addWalletTransfers = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/add-transfers`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWalletTopUp = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/top-up`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getPartnerBank = (partnerId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/bank/${partnerId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const getChatHistory = (request, tenantWabaId, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/live-chat/${tenantWabaId}/history`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const liveChatGetContacts = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/live-chat/get-contacts`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const liveChatContactsView = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/live-chat/contacts-view`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const liveChatUpdateContacts = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/live-chat/update-contacts`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getChatbotResponses = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/chatbot/responses/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const chatbotResponsesUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/chatbot/responses/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const chatbotResponsesChangeStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/chatbot/responses/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const chatbotResponsesDelete = (id, action) => (dispatch) =>
    deleteAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/chatbot/responses/delete/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const sendTenantCredentials = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/send-credentials`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWalletInfo = (tenantId, request, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/wallet/${tenantId}/info`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantNotificationQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/notification/tenant-query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantNotificationViewed = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/notification/tenant-viewed`, false, request, { Authorization: `bearer ${getToken()}` }, action);

export const getConversationsCount = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/report/conversations-count`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getConversationsCountByDates = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/report/conversations-count-date`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getAllMessageCount = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/report/all-message-count`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getAllMessageCountByDates = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/report/all-message-count-date`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getWhatsAppTemplateReport = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/report/whatsApp-template-report`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertBusinessHour = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/business-hour/bulk-upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getBusinessHour = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/business-hour/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getagentMetricsReport = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/report/agent-metrics`, true, request, { Authorization: `bearer ${getToken()}` }, action);
