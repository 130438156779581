import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { upsertMessageType } from "../../store/actions/adminAction";
import { useTranslation } from "react-i18next";

function AddMessageType({
  setIsShow,
  onAdd,
  isEdit = false,
  defaultMessageType = {},
}) {
  let dispatch = useDispatch();
  const [MessageType, setMessageType] = useState(defaultMessageType ?? {});
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      upsertMessageType(MessageType, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-12 gap-3 w-full">
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Type')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Type")}
            value={MessageType?.messageType}
            onChange={(e) =>
              setMessageType({ ...MessageType, messageType: e.target.value })
            }
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Full Description')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Description")}
            value={MessageType?.descr}
            onChange={(e) =>
              setMessageType({ ...MessageType, descr: e.target.value })
            }
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <button
          type="button"
          className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
          onClick={() => setIsShow(false)}
        >
          {t("Cancel")}
        </button>
        <button
          type="submit"
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
        >
          {t("Save")}
        </button>
      </div>
    </form>
  );
}

export default AddMessageType;
