import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import { Pagination } from "@mui/material";
import { getWebhookLog } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import RightSidePanel from "../../components/RightSidePanel";
import WebhookLogDetail from "../../layouts/tenant/WebhookLogDetail";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function WebhookLog({ showSidebar }) {
  const { t } = useTranslation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const [webhookApiLog, setWebhookApiLog] = useState([]);
  const [selectedWebhookApiLog, setSelectedWebhookApiLog] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo?.tenantId },
  });
  const [dateFilter, setDateFilter] = useState();
  const itemParPage = 10;
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    onSearch();
  }, [currentPage]);

  const onSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push(
        {
          property: "message",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "logText",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "wabaNumber",
          operator: "CONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "actionType",
          operator: "ICONTAINS",
          value: searchText,
        }
      );
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "OR" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }

    dispatch(
      getWebhookLog(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantId: globalReducer.loginInfo?.tenantId,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setWebhookApiLog(d?.data ?? []);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${
          showSidebar ? "fullWidth" : "fullWidth1"
        } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-warehouse text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('Activity Log')}</div>
          </div>
          <div className="flex gap-2"></div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Log Details')}</label>
              </div>
              <input
                type="text"
                placeholder="Enter Log Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>

            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {webhookApiLog && webhookApiLog.length > 0 && (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto mt-5">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="font-[500] py-3 pl-2 w-[250px]">
                      <div className="flex gap-2">
                        <div>{t('Activity')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Log')}</div>
                      </div>
                    </th>
                    {/* <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>Action</div>
                                            </div>
                                        </th> */}
                  </tr>
                </thead>
                <tbody>
                  {webhookApiLog.map((p, i) => {
                    return (
                      <tr className="bg-white border-b">
                        <td className={"py-3 text-[13px] pl-2 font-normal"}>
                          <p>
                            {t('Waba Number:')}
                            <label className="text-black">
                              {p?.wabaNumber ?? "-"}
                            </label>
                          </p>
                          <p>
                            {t('Action Type:')}
                            <label className="text-black">
                              {p?.actionType ?? "-"}
                            </label>
                          </p>
                          <p>
                            {t('Date Time:')}
                            <label className="text-black">
                              {moment
                                .utc(p?.createdAt)
                                .tz(wabaTimezone)
                                .format("YYYY-MM-DD hh:mm A")}
                            </label>
                          </p>
                        </td>
                        <td className="py-3 text-[13px] font-normal px-2">
                          <textarea
                            value={p?.logText}
                            rows={5}
                            disabled
                            className="border-0 w-full"
                          ></textarea>
                        </td>
                        {/* <td className="py-3 text-[13px] font-normal">
                                                    <Dropdown
                                                        inline
                                                        arrowIcon={false}
                                                        inputMode
                                                        as="button"
                                                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                        label={<i className="fa-solid fa-ellipsis"></i>}
                                                    >
                                                        <Dropdown.Item
                                                            className="hover:text-theme"
                                                            onClick={() => { setSelectedWebhookApiLog(p); setShowDetail(true) }}>
                                                            Detail
                                                        </Dropdown.Item>
                                                    </Dropdown>
                                                </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
        {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
        </div>
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showDetail}
        setIsShow={setShowDetail}
        ModalTitle={t("Details")}
        Component={
          <WebhookLogDetail
            setIsShow={setShowDetail}
            log={selectedWebhookApiLog}
          />
        }
      />
    </div>
  );
}

export default WebhookLog;
