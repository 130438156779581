import React, { useEffect, useState } from "react";
import {
  getToken,
  removeToken,
  getInfo,
  getWaba,
  LOGIN_INFO,
  WABA,
  NOTIFICATIONS,
} from "../utils/types";
import ROUTES from "../utils/routesConstants";
import PartnerLeftPanel from "../layouts/partner/PartnerLeftPanel";
import TenantLeftPanel from "../layouts/tenant/TenantLeftPanel";
import PopUp from "../components/PopUp";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountry,
  getTimezone,
  saveSelectedWaba,
} from "../store/actions/globalAction";
import SelectWaba from "../layouts/tenant/SelectWaba";
import { getAllTenantWaba, tenantNotificationQuery } from "../store/actions/tenantAction";
import { Dropdown } from "flowbite-react";
import { switchLanguage } from "../store/reducers/languageReducer";
import i18n from "../i18n";
import Notifications from "../layouts/tenant/Notifications";
import { systemLanguages } from "../utils/helper";
import { useTranslation } from "react-i18next";
import { loginBackToSuperAdmin } from "../store/actions/partnerAction";

function PrivateRouteAdmin({ category }) {
  const auth = getToken();
  let dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { tabName, subtabName = null } = useParams();
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [showSelectWaba, setShowSelectWaba] = useState(false);
  const loginInfo = getInfo();
  const globalReducer = useSelector((state) => state.globalReducer);
  const language = useSelector(
    (state) => state.languageReducer.currentLanguage
  );
  const authorizeRoute = () => {
    if (auth) {
      if (
        !(
          loginInfo &&
          loginInfo.roles &&
          Array.isArray(loginInfo.roles) &&
          loginInfo.roles?.length > 0
        )
      ) {
        return false;
      }
      if (!loginInfo.partnerId && !loginInfo.tenantId) {
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    if (!globalReducer.country || globalReducer.country?.length <= 0) {
      dispatch(getCountry({}, (c) => { }));
    }
    if (!globalReducer.timezone || globalReducer.timezone?.length <= 0) {
      dispatch(getTimezone((c) => { }));
    }
    dispatch({ type: LOGIN_INFO, payload: loginInfo });
    if (category === "TENANT") {
      const waba = getWaba();
      dispatch({ type: WABA, payload: waba });
      dispatch(
        getAllTenantWaba((d) => {
          if (Array.isArray(d)) {
            if (waba && Object.keys(waba).length > 0) {
              const wa = d.find((f) => f.id === waba?.id);
              dispatch(saveSelectedWaba(wa));
            } else {
              dispatch(saveSelectedWaba(d[0]));
            }
          }
        })
      );
      dispatch(tenantNotificationQuery({
        size: 100,
        offset: 0,
        criteria: { tenantId: loginInfo?.tenantId },
        orderBy: [{ property: "createdAt", direction: "DESC" }]
      }, (nfy) => {
        setNotificationCount(nfy?.data?.filter(f => f.status !== "VIEWED")?.length);
        dispatch({ type: NOTIFICATIONS, payload: nfy?.data });
      }));
    }
  }, []);

  const handleLanguageChange = (value) => {
    dispatch(switchLanguage(value.key));
    i18n.changeLanguage(value.key);
    document.body.dir = value.direction;
  };


  useEffect(() => {
    if (!auth) {
      navigate(ROUTES.LOGIN);
    }
    if (!authorizeRoute()) {
      navigate(ROUTES.UN_AUTH);
    }
  }, [tabName]);

  const logOut = () => {
    removeToken();
    navigate(ROUTES.LOGIN);
  };

  return auth ? (
    <>
      <div>
        <header className="flex gap-[10px] items-center fixed top-0 bg-[#EA2F30] w-[100%] z-[99999] justify-between h-[30px] animate__animated animate__fadeInDown animate__faster">
          <div className="pl-5 flex w-full justify-between">
            <div className="flex items-center gap-10">
              <img
                src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`}
                className="px-1 bg-white h-[24px] rounded-[4px]"
                alt="Logo"
              />
              {category === "TENANT" && globalReducer.loginInfo?.roles?.includes("TENANT_SUPER_ADMIN") && (
                <label
                  onClick={() => {
                    dispatch(loginBackToSuperAdmin({}, (res) => {
                      if (res) {
                        localStorage.setItem("sessionToken", res?.access_token);
                        localStorage.setItem("refreshToken", res?.refresh_token);
                        window.location.replace(JSON.parse(process.env.REACT_APP_PARTNER_PORTAL_CONFIG).redirect_uri);
                      }
                    }));
                  }}
                  className="flex cursor-pointer text-white"
                >
                  <i className="fa-regular fa-circle-left pr-2 pt-0.5 cursor-pointer"></i>
                  <span className="text-[14px]">{t("Back to Super Admin")}</span>
                </label>
              )}
            </div>
            {category === "TENANT" && (
              <>
                <div className="text-white">
                  <label
                    className="py-1 px-2 hover:bg-[#bf2015] cursor-pointer hover:text-gray-50"
                    onClick={() => setShowSelectWaba(true)}
                  >
                    <span>
                      {t("Teams")}<i className="fa-solid fa-sort text-white pl-1"></i>
                    </span>
                    &nbsp;
                    {`${globalReducer.waba?.teamName ?? ""}  ${globalReducer.waba?.phoneCode
                      ? "+" + globalReducer.waba?.phoneCode
                      : ""
                      } ${globalReducer.waba?.phone ?? ""}`}
                  </label>
                </div>
              </>
            )}
          </div>
          {category === "TENANT" && (<div>
            <div className="text-white px-2 py-1 hover:bg-[#bf2015]  cursor-pointer" onClick={() => setShowNotification(!showNotification)}>
              <div className="flex">
                <i className="fa-solid fa-bell text-[16px]"></i>
                {notificationCount > 0 && <div className="w-2 h-2 bg-[#F7F700] rounded-full border-2 border-[#F7F700] text-[8px] text-black mt-[-2px]"></div>}
              </div>
            </div>
            {showNotification && <Notifications />}
          </div>)}
          <div className="flex gap-5 items-center px-5 text-sm ">
            <div className="flex justify-end py-1 text-[18px] cursor-pointer">
              <Dropdown
                arrowIcon={false}
                inline
                size="sm"
                label={
                  language === "en" ? (
                    <span className="px-2 bg-white rounded-md ring-0 p-0.5 text-[15px] ring-gray-300">
                      English
                    </span>
                  ) : language === "ar" ? (
                    <span className="px-2 bg-white rounded-md ring-0 p-0.5 text-[15px] ring-gray-300">
                      Arabic
                    </span>
                  ) : (
                    <i className="fa-solid fa-language rounded-md px-3 bg-white ring-0 p-0.5 text-[15px] ring-gray-300"></i>
                  )
                }
              >
                {systemLanguages.map((lang, index) =>
                  <Dropdown.Item key={index}
                    className="py-1 text-[16px]"
                    onClick={() => handleLanguageChange(lang)}
                  >
                    {lang?.value}
                  </Dropdown.Item>)}
              </Dropdown>
            </div>
          </div>
          <div className="flex justify-center items-center one">
            <i
              className="fas fa-sign-out-alt text-white pr-5 cursor-pointer"
              onClick={() => setLogoutPopup(true)}
            ></i>
          </div>
        </header>
        <>
          {category === "PARTNER" || category === "GLOBAL_ADMIN" ? (
            <PartnerLeftPanel
              category={category}
              tabName={tabName}
              subtabName={subtabName}
            />
          ) : (
            <TenantLeftPanel tabName={tabName} subtabName={subtabName} />
          )}
        </>
      </div>
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t("Are you want to quit?")}
            </div>
            <div className="font-medium text-[14px] text-[#6E6C6D]">
              {t("Any unsaved changes will be lost.")}
            </div>
          </>
        }
        isShowModal={logoutPopup}
        setIsShowModal={setLogoutPopup}
        ModalBody={
          <>
            <div className="flex justify-end gap-4 p-6">
              <button
                className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                onClick={() => setLogoutPopup(false)}
              >
                {t("Cancel")}
              </button>
              <button
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                onClick={() => logOut()}
              >
                {t("Logout")}
              </button>
            </div>
          </>
        }
        size="md"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Choose Team")}
          </span>
        }
        isShowModal={showSelectWaba}
        setIsShowModal={setShowSelectWaba}
        ModalBody={<SelectWaba setIsShow={setShowSelectWaba} />}
        size="md"
      />
    </>
  ) : (
    <Navigate to="/" replace />
  );
}

export default PrivateRouteAdmin;
