import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import moment from "moment-timezone";
import PopUp from "../../components/PopUp";
import {
  chatbotResponsesChangeStatus,
  chatbotResponsesDelete,
  getChatbotResponses,
} from "../../store/actions/tenantAction";
import AddChatbotResponses from "../../layouts/tenant/AddChatbotResponses";
import RightSidePanel from "../../components/RightSidePanel";
import { useTranslation } from "react-i18next";

const Chatbot = ({ showSidebar }) => {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [showAddChatbotResponse, setShowAddChatbotResponse] = useState(false);
  const [deleteChatbotResponse, setDeleteChatbotResponse] = useState(false);
  const [chatbotResponses, setChatbotResponses] = useState([]);
  const [selectedShatbotResponses, setSelectedChatbotResponses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = useState();
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const globalReducer = useSelector((state) => state.globalReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    Categorysearch();
  }, [currentPage]);

  const Categorysearch = () => {
    var fl = [];
    if (searchText) {
      fl.push(
        {
          property: "intentName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "keywords",
          operator: "IN",
          value: searchText,
        }
      );
    }
    filter.filter = fl;
    dispatch(
      getChatbotResponses(
        {
          ...filter,
          tenantId: globalReducer.loginInfo?.tenantId,
          criteria: {
            ...filter?.criteria,
            tenantWabaId: globalReducer.waba?.id,
          },
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setChatbotResponses(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      Categorysearch();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-code-branch text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">
              {t("Manage Chatbot Response")}
            </div>
          </div>
          <div className="flex gap-2">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedChatbotResponses({});
                setShowAddChatbotResponse(true);
              }}
            >
              {" "}
              {t("Add Chatbot Response")}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Chatbot Details")}</label>
              </div>
              <input
                type="text"
                placeholder={t("Enter Intent Name, Keywords")}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Status")}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t("Active")}
                </option>
                <option value={false}>{t("Not Active")}</option>
              </select>
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {chatbotResponses && chatbotResponses.length > 0 ? (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t("Intent Name")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Keywords")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] w-[160px]">
                      <div className="flex gap-2">
                        <div>{t("Status")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t("DateTime")}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Action")}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {chatbotResponses.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className={"py-3 text-[13px] pl-2 font-normal"}>
                          {p?.intentName ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.keywords?.join(", ") ?? ""}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.active ? (
                            <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                              Active
                            </label>
                          ) : (
                            <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                              Not Active
                            </label>
                          )}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {moment
                            .utc(p?.createdAt)
                            .tz(wabaTimezone)
                            .format("YYYY-MM-DD hh:mm A")}
                        </td>
                        <td className="py-3 text-[13px] font-normal flex">
                          <div>
                            <Dropdown
                              inline
                              arrowIcon={false}
                              inputMode
                              as="button"
                              className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                              label={<i className="fa-solid fa-ellipsis"></i>}
                            >
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  setSelectedChatbotResponses({
                                    ...p,
                                    isEdit: true,
                                  });
                                  setShowAddChatbotResponse(true);
                                }}
                              >
                                {" "}
                                {t("Edit")}
                              </Dropdown.Item>
                              {p?.active ? (
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    dispatch(
                                      chatbotResponsesChangeStatus(
                                        { id: p?.id, isActive: false },
                                        (a) => onSearch()
                                      )
                                    );
                                  }}
                                >
                                  {" "}
                                  {t("Deactivate")}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    dispatch(
                                      chatbotResponsesChangeStatus(
                                        { id: p?.id, isActive: true },
                                        (a) => onSearch()
                                      )
                                    );
                                  }}
                                >
                                  {t("Activate")}
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  setSelectedChatbotResponses({ ...p });
                                  setDeleteChatbotResponse(true);
                                }}
                              >
                                {" "}
                                {t("Delete")}
                              </Dropdown.Item>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
            </>
          ) : (
            <div className="m-[4rem] text-center flex flex-col items-center gap-2">
              <img
                src={`${process.env.PUBLIC_URL}/images/noList.png`}
                alt="No List"
                className="w-[486px] h-[278px] "
              />
              <p className="my-[24px] ">
                {t('The space is empty . Add a Chatbot Response to get started.')}
              </p>
              <div className="flex gap-2.5 justify-center text-base font-medium">
                <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                  <div
                    className="flex gap-2"
                    onClick={() => setShowAddChatbotResponse(true)}
                  >
                    <div className="">
                      <span className="mr-2">
                        <i className="fa-solid fa-plus"></i>
                      </span>
                      {t('Add Chatbot Response')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PopUp
        ModalHeader={
          <div className="font-medium text-[18px] text-black">
            {t('Are you sure you want to delete?')}
          </div>
        }
        isShowModal={deleteChatbotResponse}
        setIsShowModal={setDeleteChatbotResponse}
        ModalBody={
          <div className="flex justify-end gap-4 p-6">
            <button
              className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
              onClick={() => setDeleteChatbotResponse(false)}
            >
              {t('Cancel')}
            </button>
            <button
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                dispatch(
                  chatbotResponsesDelete(selectedShatbotResponses?.id, (a) => {
                    onSearch();
                    setDeleteChatbotResponse(false);
                  })
                );
              }}
            >
              {t('Delete')}
            </button>
          </div>
        }
        size="md"
      />
      <RightSidePanel
        ModalTitle={t("Add Chatbot Responses")}
        setIsShow={setShowAddChatbotResponse}
        isShow={showAddChatbotResponse}
        Component={
          <AddChatbotResponses
            setIsShow={setShowAddChatbotResponse}
            isEdit={selectedShatbotResponses?.isEdit}
            onAdd={onSearch}
            selectedResponse={selectedShatbotResponses}
          />
        }
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
      />
    </div>
  );
};

export default Chatbot;
