import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import moment from "moment";
import { getTenantPlanView, tenantQuery } from "../../store/actions/partnerAction";

function UpcomingAccount({ setIsShow, type }) {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const partnerReducer = useSelector((state) => state.partnerReducer);
    const itemParPage = 7;
    const partnerTimezone = partnerReducer.partnerDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        onSearch(type);
    }, [currentPage]);

    const onSearch = (filterType) => {
        const filter = [];
        switch (filterType) {
            case "PENDING":
                dispatch(tenantQuery({
                    criteria: { partnerId: partnerReducer.partnerDetails?.partnerId, status: "PENDING" },
                    filter: filter,
                    count: true,
                    size: itemParPage,
                    offset: (currentPage - 1) * itemParPage,
                    orderBy: [{ property: "createdAt", direction: "DESC" }],
                }, (d) => {
                    setAccounts(d?.data ?? []);
                    setTotalPages(Math.ceil(d?.count / itemParPage));
                }));
                break;
            case "UPCOMING":
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() + 1);
                dispatch(getTenantPlanView({
                    criteria: {},
                    filter: [{ property: "tenantPlan.toDate", operator: "LT", value: newDate.getTime() }, { clause: "AND" }, { property: "tenantPlan.toDate", operator: "GT", value: Date.now() }],
                    count: true,
                    size: itemParPage,
                    offset: (currentPage - 1) * itemParPage,
                    orderBy: [{ property: "createdAt", direction: "DESC" }],
                }, (d) => {
                    setAccounts(d?.data ?? []);
                    setTotalPages(Math.ceil(d?.count / itemParPage));
                }));
                break;
            case "EXPIRED":
                dispatch(getTenantPlanView({
                    criteria: {},
                    filter: [{ property: "tenantPlan.toDate", operator: "LT", value: Date.now() }],
                    count: true,
                    size: itemParPage,
                    offset: (currentPage - 1) * itemParPage,
                    orderBy: [{ property: "createdAt", direction: "DESC" }],
                }, (d) => {
                    setAccounts(d?.data ?? []);
                    setTotalPages(Math.ceil(d?.count / itemParPage));
                }));
                break;
        }

    };

    return (
        <>
            <div className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto">
                <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                    <thead className="text-[14px] text-midBlack bg-gray-100">
                        <tr>
                            <th scope="col" className="font-[500] py-3 px-2">
                                Company ID
                            </th>
                            <th scope="col" className="font-[500] py-3 px-2">
                                Company Name
                            </th>
                            <th scope="col" className="font-[500] py-3 px-2">
                                Website
                            </th>
                            <th scope="col" className="font-[500] py-3 px-2">
                                Date & time
                            </th>
                            {(type === "EXPIRED" || type === "UPCOMING") &&
                                <th scope="col" className="font-[500] py-3 px-2">
                                    Plan expiry date
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {accounts?.map((p, i) => {
                            return (
                                <tr className="bg-white border-b">
                                    <td className={"pl-2 py-3 font-normal text-[13px]"}>
                                        {p?.id ?? "-"}
                                    </td>
                                    <td className="pl-2 py-3 font-normal text-[13px]">
                                        {p?.name}
                                    </td>
                                    <td className="pl-2 py-3 font-normal text-[13px]">
                                        {p?.website}
                                    </td>
                                    <td className="pl-2 py-3 font-normal text-[13px]">
                                        {moment.utc(p?.createdAt).tz(partnerTimezone).format("YYYY-MM-DD hh:mm A")}
                                    </td>
                                    {(type === "EXPIRED" || type === "UPCOMING") &&
                                        <td className="pl-2 py-3 font-normal text-[13px]">
                                            {moment.utc(p?.tenantPlan?.toDate).tz(partnerTimezone).format("YYYY-MM-DD hh:mm A")}
                                        </td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {totalPages > 1 && (
                    <div className="flex overflow-x-auto sm:justify-center bottom-0 mt-2">
                        <Pagination
                            page={currentPage}
                            count={totalPages}
                            onChange={(event, page) => setCurrentPage(page)}
                            shape="rounded"
                            color="secondary"
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default UpcomingAccount;
