import React, { useEffect, useRef, useState } from "react";
import { phoneLength, removeStringByIndex } from "../utils/helper";

const MobileInput = ({
  countryList,
  setPhoneCode,
  setPhone,
  phoneCode,
  phone,
  required,
  className = "",
  phoneWithCode,
  disabled = false,
}) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showList, setShowList] = useState(false);
  const [countries, setCountries] = useState(countryList ?? []);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowList(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (phoneWithCode && countryList?.length > 0 && !phoneCode && !phone) {
      let four = phoneWithCode.substring(0, 4);
      let country = countryList?.find((f) => f.phoneCode == four);
      let phoneNumber = removeStringByIndex(phoneWithCode, 0, 4);
      if (!country) {
        let three = phoneWithCode.substring(0, 3);
        country = countryList?.find((f) => f.phoneCode == three);
        phoneNumber = removeStringByIndex(phoneWithCode, 0, 3);
      }
      if (!country) {
        let two = phoneWithCode.substring(0, 2);
        country = countryList?.find((f) => f.phoneCode == two);
        phoneNumber = removeStringByIndex(phoneWithCode, 0, 2);
      }
      if (!country) {
        let one = phoneWithCode.substring(0, 1);
        country = countryList?.find((f) => f.phoneCode == one);
        phoneNumber = removeStringByIndex(phoneWithCode, 0, 1);
      }
      if (country) {
        setPhoneCode(country.phoneCode);
        setPhone(phoneNumber);
        setSelectedCountry({ ...country });
      }
    }
  }, [phoneWithCode, countryList]);

  useEffect(() => {
    if (phoneCode) {
      setSelectedCountry({
        ...countryList?.find((f) => f.phoneCode === phoneCode),
      });
    }
  }, [countryList, phoneCode]);

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };

  const OnPhoneInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
    const { value } = e.target;
    const minLength = phoneLength[phoneCode]?.min ?? 10;
    const maxLength = phoneLength[phoneCode]?.max ?? 10;

    // Restrict max length
    if (value.length > maxLength) {
      e.target.value = value.slice(0, maxLength);
    }

    // Prevent form submission if below min length
    if (value.length < minLength) {
      e.target.setCustomValidity(`Phone number must be at least ${minLength} digits`);
    } else {
      e.target.setCustomValidity("");
    }
  };
  return (
    <div className="mx-auto" ref={dropdownRef}>
      <div className="flex items-center relative ">
        <button
          disabled={disabled}
          className={`${className} flex-shrink-0 inline-flex items-center py-1 px-1 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-[5px] hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 `}
          type="button"
          onFocus={() => {
            setCountries(countryList);
            setShowList(true);
          }}
        >
          {selectedCountry?.phoneCode || selectedCountry?.phoneCode == 0
            ? "+"
            : ""}
          <input
            readOnly
            className="border-0 text-[14px] bg-transparent p-0 max-w-[33px] border-transparent focus:border-transparent focus:ring-0 ring-0 focus:outline-none outline-none"
            type="number"
            placeholder="+968"
            value={selectedCountry?.phoneCode}
            required={required}
            data-maxlength={4}
            onInput={OnInput}
          />
          <svg
            className="w-2.5 h-2.5 ms-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div className="relative w-full">
          <input
            type="number"
            data-maxlength={phoneLength[phoneCode]?.max ?? 10}
            min={10 ** (phoneLength[phoneCode]?.min - 1)}  // Ensures a minimum digit count
            max={10 ** phoneLength[phoneCode]?.max - 1}   // Restricts maximum length
            onInput={OnPhoneInput}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={`${className} text-[14px] block py-1 px-2 w-full text-gray-900 rounded-e-[5px] border-s-0 border border-gray-300 focus:ring-blue-500 focus:border-blue-500`}
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            placeholder="Enter Phone Number"
            required={required}
            disabled={disabled}
          />
        </div>
        {showList && !disabled && (
          <div className="absolute mt-8 z-10 divide-y w-inherit">
            <div className="fixed bg-white min-w-52 w-inherit max-w-[285px] divide-gray-100 rounded-[5px] shadow-lg ring-1 ring-black ring-opacity-10">
              <div className="p-[1px]">
                <input
                  type="text"
                  placeholder="Search.."
                  className="border border-gray-300 rounded-md py-1 w-[100%]"
                  onChange={(e) => {
                    const v = e.target.value?.toLowerCase();
                    setCountries(
                      v
                        ? countryList.filter(
                          (f) =>
                            f.code.toLowerCase().includes(v) ||
                            f.name.toLowerCase().includes(v) ||
                            f.phoneCode == v
                        )
                        : countryList
                    );
                  }}
                />
              </div>
              <div className="h-[300px] overflow-y-auto bg-white">
                <ul className="py-1 text-sm text-gray-700">
                  {countries?.map((c, i) => (
                    <li>
                      <button
                        type="button"
                        className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 "
                        role="menuitem"
                        onClick={() => {
                          setSelectedCountry(c);
                          setPhoneCode(c.phoneCode);
                          setShowList(false);
                        }}
                      >
                        <span className="inline-flex items-center">
                          {c.name} (+{c.phoneCode})
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileInput;
