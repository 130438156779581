import React from "react";
import { useNavigate } from "react-router-dom";
import ROUTES from "../utils/routesConstants";
import { getInfo } from "../utils/types";
import { useTranslation } from "react-i18next";

const UnAuth = () => {
  const {t} = useTranslation();
  const InfoParse = getInfo();
  const navigate = useNavigate();

  const gotoHome = () => {
    if (InfoParse && InfoParse.roles && Array.isArray(InfoParse.roles)) {
      if (InfoParse.roles.some(s => s === "GLOBAL_ADMIN")) {
        navigate(ROUTES.GLOBAL_ADMIN);
      } else if (InfoParse.roles.some(s => s === "SUPER_ADMIN")) {
        navigate(ROUTES.PARTNER);
      }
      else {
        navigate(ROUTES.TENANT);
      }
    }
  }

  return (
    <section className="bg-white flex items-center h-screen">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme">
            401
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            {t('Unauthorized Access.')}
          </p>

          <p className="mb-4 text-lg font-light text-gray-500">
            {t('You are not authorized to view this page.')}
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            {t('Please contact your administrator for access.')}
          </p>
          <button
            className="inline-flex text-white bg-theme hover:bg-theme-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4"
            onClick={gotoHome}
          >
            {t('Back to Homepage')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default UnAuth;
