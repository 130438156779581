import React, { useEffect, useRef } from "react";
import WhatsappMessage from "./WhatsappMessage";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

function LiveChatPreview({ messageList, contact, setCanSendMsg }) {
  const lastItemRef = useRef(null);
  const globalReducer = useSelector((state) => state.globalReducer);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setTimeout(() => focusLastItem(), 50);
  }, [messageList?.length]);

  const groupedData = messageList?.reduce((acc, item) => {
    const date = moment(item.createdAt).format("YYYY-MM-DD");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  useEffect(() => {
    const date = moment().utc().subtract(24, "hours");
    const smsList = messageList.filter((item) => moment.utc(item.createdAt).isAfter(date));
    if (smsList?.length > 0) {
      if (smsList?.some((item) => ((Array.isArray(item.messageText) && item?.messageText[0]?.from)
        || (item.type?.toLowerCase() === "template" && !["failed", "invalid"].includes(item.messageStatus?.toLowerCase()))))) {
        {
          setCanSendMsg(true);
        }
      } else setCanSendMsg(false);
    } else setCanSendMsg(false);
  }, [groupedData]);

  const formatDate = (date) => {
    const givenDate = moment.utc(date).tz(wabaTimezone).startOf("day");
    const today = moment().utc().tz(wabaTimezone).startOf("day");
    const tomorrow = moment()
      .utc()
      .tz(wabaTimezone)
      .add(-1, "day")
      .startOf("day");

    if (givenDate.isSame(today, "day")) {
      return "Today";
    } else if (givenDate.isSame(tomorrow, "day")) {
      return "Yesterday";
    } else {
      return givenDate.format("dddd, MMMM D, YYYY");
    }
  };
  const focusLastItem = () => {
    lastItemRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };
  const getWhatsappMessage = (selectedMessage) => {
    if (!selectedMessage) return <></>;
    const msgText = selectedMessage?.messageText && Array.isArray(selectedMessage.messageText)
      ? selectedMessage.messageText[0]
      : selectedMessage.messageText ?? {};
    if (msgText?.context?.from) {
      const msg = messageList?.find(
        (f) => f.referenceId === msgText?.context?.id
      );
      return (
        <div className="bg-[#f7f8fc] text-black rounded-xl pt-[6px] pr-[7px] pb-[8px] pl-[9px]">
          <div className="bg-[#eceef6] rounded-[8px] border-l-[3px] border-theme flex text-gray-500">
            <WhatsappMessage message={msg} isReply={true} />
          </div>
          <WhatsappMessage message={selectedMessage} />
        </div>
      );
    }
    return <WhatsappMessage message={selectedMessage} />;
  };
  return (
    <div className="flex flex-col w-auto">
      {/* <div className="flex flex-col">
            <div className="relative pt-3">
                <div className="absolute inset-0 top-[30%] flex items-center">
                    <div className="w-full border-b border-gray-100" />
                </div>
                <div className="relative flex justify-center">
                    <div className="flex px-2.5 content-center bg-white">
                        <div
                            className="bg-white text-xs font-medium text-gray-500"
                            data-testid="text-action-bar"
                        >
                            A new ticket was created.
                        </div>
                        <div className="bg-white px-1 text-[8px] font-medium text-gray-400">
                            |
                        </div>
                        <div className="bg-white text-xs font-normal text-gray-400">
                            4:52 PM
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      {Object.keys(groupedData)?.map((date, d) => (
        <div key={date}>
          <div className="relative py-3">
            <div className="absolute inset-0 top-[30%] flex items-center mb-[12px]">
              <div className="w-full border-b border-gray-100" />
            </div>
            <div className="relative flex justify-center">
              <div className="border-[1px] h-7 bg-white border-gray-300 content-center rounded-full">
                <div className="flex content-center px-3">
                  <div className="text-xs font-medium text-gray-700 bg-white">
                    {formatDate(date)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="relative">
              {groupedData[date].map((item, index) => (
                <div
                  key={index}
                  ref={
                    Object.keys(groupedData).length - 1 === d &&
                      index === groupedData[date].length - 1
                      ? lastItemRef
                      : null
                  }
                  className={`relative group flex ${item.messageText[0]?.from ? "flex-start" : "justify-end"
                    } w-full h-auto py-1 pb-1.5`}
                >
                  <div className="max-w-[60%]">
                    <div className="flex gap-2 flex-start">
                      {/* <div className="my-1.5 w-auto h-auto font-bold sm:text-sm text-green-500">
                                        {contact?.name}
                                    </div> */}
                    </div>
                    <div className="flex items-start flex-col">
                      {getWhatsappMessage(item)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
      {/* <div className="flex flex-col">
            <div className="relative">
                <div className="relative group flex flex-start w-full h-auto pt-0.5 pb-1.5 hover:bg-[#F8F8F9]">
                    <div className="relative false">
                        <img
                            className="ml-5 mr-5 mr-3 ml-3 mt-2 object-cover w-8 h-8 content-between rounded-full"
                            src={`${process.env.PUBLIC_URL}/images/default-avatar.png`}
                        />
                    </div>
                    <div className="max-w-[60%]">
                        <div className="flex gap-2 flex-start">
                            <div className="my-1.5 w-auto h-auto font-bold sm:text-sm text-green-500">
                                {contact?.name}
                            </div>
                        </div>
                        <div className="flex items-start flex-col">
                            <WhatsappMessage />
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
  );
}

export default LiveChatPreview;
