import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import { getConversation } from '../../store/actions/tenantAction';
import moment from "moment-timezone";
import RightSidePanel from '../../components/RightSidePanel';
import * as XLSX from "xlsx";
import { msgStatusColor, msgStatusTextColor } from '../../utils/helper';
import ConversationDetail from '../../layouts/tenant/ConversationDetail';
import dayjs from 'dayjs';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function MessagePushedReport({ showSidebar }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);
    const [selectedConversation, setSelectedConversation] = useState({});
    const [conversationData, setConversationData] = useState([]);
    //const [types, setTypes] = useState([{ id: "template", name: "template" }, { id: "image", name: "image" }, { id: "location", name: "location" }, { id: "reaction", name: "reaction" }, { id: "sticker", name: "sticker" }, { id: "audio", name: "audio" }, { id: "text", name: "text" }, { id: "video", name: "video" }]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchText, setSearchText] = useState("");
    const globalReducer = useSelector((state) => state.globalReducer);
    const itemParPage = 100;
    const [filter, setFilter] = useState({
        criteria: { tenantId: globalReducer.loginInfo.tenantId },
    });
    const [dateFilter, setDateFilter] = useState({
        property: "createdAt",
        operator: "RANGE",
        lowerBound: Date.parse(dayjs().startOf("month").toDate()),
        upperBound: Date.parse(dayjs().add(1, "hour").toDate())
    });
    const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if (globalReducer.waba?.id) {
            templateDataSearch();
        }
    }, [currentPage]);

    const preparedFilter = () => {
        const fl = [];
        if (searchText) {
            fl.push({ clause: "OPEN_BRACKET" },
                {
                    property: "templateReferenceId",
                    operator: "CONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "templateName",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "languageCode",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "countryCode",
                    operator: "CONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "messageStatus",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.whatsappNumber",
                    operator: "CONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.email",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.name",
                    operator: "ICONTAINS",
                    value: searchText,
                }, { clause: "CLOSE_BRACKET" }
            );
        }
        if (dateFilter && searchText) {
            filter.filter = [...fl, { clause: "AND" }, dateFilter];
        } else if (searchText) {
            filter.filter = fl;
        } else if (dateFilter) {
            filter.filter = [dateFilter];
        } else {
            filter.filter = [];
        }
        if (filter.filter?.length > 0) {
            filter.filter.push({ clause: "AND" });
        }
        filter.filter.push({ clause: "OPEN_BRACKET" }, {
            property: "templateName",
            operator: "ISNULL",
            not: true
        }, { clause: "CLOSE_BRACKET" });
        if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
            if (filter.filter?.length > 0) {
                filter.filter.push({ clause: "AND" });
            }
            filter.filter.push({ clause: "OPEN_BRACKET" }, {
                property: "contact.category",
                operator: "IN",
                not: true,
                value: "GENERIC_EXTERNAL",
            }, { clause: "CLOSE_BRACKET" });
        }
    }

    const templateDataSearch = () => {
        preparedFilter();
        dispatch(getConversation({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId,
            count: true,
            size: itemParPage,
            offset: (currentPage - 1) * itemParPage,
            orderBy: [{ property: "id", direction: "DESC" }]
        }, (d) => {
            setConversationData(d?.data ?? []);
            setTotalPages(Math.ceil(d?.count / itemParPage));
        }));
    };

    const onSearch = () => {
        if (currentPage === 1) {
            templateDataSearch();
        } else {
            setCurrentPage(1);
        }
    };

    const exportReport = () => {
        preparedFilter();
        dispatch(getConversation({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId,
            count: true,
            size: 1000000,
            offset: (currentPage - 1) * itemParPage,
            orderBy: [{ property: "id", direction: "DESC" }]
        }, (d) => {
            const data = d?.data?.map(m => {
                return {
                    waba: `${globalReducer.waba?.teamName ?? ""}  ${globalReducer.waba?.phoneCode ? "+" + globalReducer.waba?.phoneCode : ""} ${globalReducer.waba?.phone ?? ""}`,
                    ID: m.referenceId,
                    CustomerID: m?.contact?.customerId,
                    CustomerName: m.contact?.name,
                    PhoneNumber: m.contact?.whatsappNumber,
                    CustomerEmail: m.contact?.email,
                    TemplateID: m?.templateReferenceId,
                    TemplateName: m?.templateName,
                    Country: m.countryCode,
                    Language: m.languageCode,
                    Status: m.messageStatus,
                    Type: m.messageType,
                    FailedReason: m.remarks,
                    createdAt: m.createdAt,
                    ...m?.detail
                };
            })
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data ?? []);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Message Pushed Report");
            XLSX.writeFile(workbook, 'message-pushed-report.xlsx');
        }));
    };

    const selectDateRange = (dates) => {
        if (dates && dates.length > 0) {
            const [start, end] = dates;
            if (start && end) {
                setDateFilter({
                    property: "createdAt",
                    operator: "RANGE",
                    lowerBound: Date.parse(start.toDate()),
                    upperBound: Date.parse(end.add(1, "hour").toDate()),
                });
            } else {
                setDateFilter(null);
            }
        } else {
            setDateFilter(null);
        }
    };
    return (
        <div className="h-screen">
            <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
                <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <svg fill="#E92F2F" width="24px" height="24px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.5,68.5v-34H26.9a4.89,4.89,0,0,0-4.8,4.9V74.8a4.89,4.89,0,0,0,4.8,4.9H62.5a4.89,4.89,0,0,0,4.8-4.9V73.4h-34A4.89,4.89,0,0,1,28.5,68.5Z" /><path d="M46.4,30.2H64.1a1.58,1.58,0,0,0,1.6-1.6V25.3a4.89,4.89,0,0,0-4.8-4.9H49.6a4.82,4.82,0,0,0-4.8,4.9v3.3A1.64,1.64,0,0,0,46.4,30.2Z" />
                            <path d="M73,24.4H71.4a.74.74,0,0,0-.8.8v3.3a6.57,6.57,0,0,1-6.5,6.6H46.4a6.64,6.64,0,0,1-6.5-6.6V25.2a.74.74,0,0,0-.8-.8H37.5a4.82,4.82,0,0,0-4.8,4.9V64.6a4.89,4.89,0,0,0,4.8,4.9H73a4.82,4.82,0,0,0,4.8-4.9V29.4A4.85,4.85,0,0,0,73,24.4ZM60.9,55.5a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V53.9a1.58,1.58,0,0,1,1.6-1.6H59.3a1.58,1.58,0,0,1,1.6,1.6ZM69,47.3a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V45.7a1.58,1.58,0,0,1,1.6-1.6H67.4A1.58,1.58,0,0,1,69,45.7Z" />
                        </svg>
                        <div className="text-[14px] font-medium">
                            {t("Message Pushed Report")}
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button type='button' onClick={() => exportReport()} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]">
                            {t('Download as Report')}
                        </button>
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
                    <div className="grid grid-cols-12 gap-4 items-end">
                        <div className={`flex flex-col col-span-3`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t("Enter Message Details")}</label>
                            </div>
                            <input
                                type="text"
                                placeholder={t("Enter Message Details")}
                                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`flex flex-col col-span-2`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Type')}</label>
                            </div>
                            <select required className="border w-full text-[14px] border-gray-300 rounded-md py-2 px-4 cursor-pointer inputEqHeight"
                                onChange={(e) => {
                                    if (e.target.value) {
                                        setFilter({ criteria: { ...filter?.criteria, messageType: e.target.value } });
                                    } else delete filter.criteria.messageType;
                                }}>
                                <option value="" selected>All </option>
                                <option value="MARKETING"> MARKETING</option>
                                <option value="AUTHENTICATION"> AUTHENTICATION</option>
                                <option value="UTILITY"> UTILITY</option>
                                <option value="SERVICE"> SERVICE</option>
                                <option value="AUTHENTICATION_INTERNATIONAL"> AUTHENTICATION INTERNATIONAL</option>
                            </select>
                        </div>
                        <div className={`flex flex-col col-span-4`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Date Range')}</label>
                            </div>
                            <RangePicker defaultValue={[dayjs().startOf("month"), dayjs()]}
                                className={"h-10"}
                                onChange={selectDateRange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                        <div className={`flex flex-col w-full col-span-2`}>
                            <button type="button"
                                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                                onClick={onSearch} >
                                {t("Search")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col h-full justify-between grow">
                <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
                    {conversationData && conversationData.length > 0 &&
                        <>
                            <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                                <thead className="text-[14px] text-midBlack bg-gray-200">
                                    <tr>
                                        <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                            {t('Sr. No.')}
                                        </th>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            {t('Template ID')}
                                        </th>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            {t('Country')}
                                        </th>
                                        <th scope="col" className="font-[500] w-[160px]">
                                            {t('Mobile No.')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Language')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Status')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Type')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Created Date / Time')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Sent Date / Time')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Created By')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Action')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {conversationData.map((p, i) => {
                                        return (
                                            <tr className="bg-white border-b" key={i}>
                                                <td className="py-3 text-[13px] pl-2">
                                                    {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                                                </td>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.templateReferenceId ?? "-"}
                                                </td>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.countryCode ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.contact?.whatsappNumber ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.languageCode ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    <label className={`p-1.5 mr-1 text-[12px] rounded-lg bg-[${msgStatusColor[p?.messageStatus?.toLowerCase()]}]`}><span className={`text-[${msgStatusTextColor[p?.messageStatus?.toLowerCase()]}]`}> {p?.messageStatus ?? ""}</span></label>
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.messageType ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {moment.utc(p?.createdAt).tz(wabaTimezone).format("YYYY-MM-DD hh:mm A")}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.detail?.sent ? moment.utc(p?.detail?.sent).tz(wabaTimezone).format("YYYY-MM-DD hh:mm A") : "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.createdBy ?? "-"}
                                                </td>
                                                <td className=" py-3 text-[13px] font-normal">
                                                    <Dropdown
                                                        inline
                                                        arrowIcon={false}
                                                        inputMode
                                                        as="button"
                                                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                        label={<i className="fa-solid fa-ellipsis"></i>}
                                                    >
                                                        <Dropdown.Item
                                                            className="hover:text-theme"
                                                            onClick={() => { setSelectedConversation(p); setShowDetails(true); }}>
                                                            {t('Detail')}
                                                        </Dropdown.Item>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>}
                </div>
                {totalPages > 1 && conversationData && conversationData.length > 0 && (
                    <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
                        <div className="flex overflow-x-auto">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    </div>
                )}
            </div>
            <RightSidePanel
                CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showDetails}
                setIsShow={setShowDetails}
                ModalTitle={t("Details")}
                Component={
                    <ConversationDetail setIsShow={setShowDetails} conversation={selectedConversation} selectedWaba={globalReducer.waba} />
                }
            />
        </div>
    );
}

export default MessagePushedReport