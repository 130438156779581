import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import ColorPickerInput from "../../components/ColorPickerInput";
import { PARTNER_DETAILS, SHOW_SNACKBAR } from "../../utils/types";
import UploaderBase64 from "../../components/UploaderBase64";
import { updatePartner } from "../../store/actions/adminAction";
import { useTranslation } from "react-i18next";

function Setting({ showSidebar }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const [company, setCompany] = useState({ brandColor1: "", brandColor2: "" });

  useEffect(() => {
    if (partnerReducer.partnerDetails) {
      setCompany(partnerReducer.partnerDetails);
    }
  }, [partnerReducer.partnerDetails]);

  const onSaveSubmit = (e) => {
    e.preventDefault();
    if (company?.id) {
      dispatch(
        updatePartner(company, (a) => {
          dispatch({ type: PARTNER_DETAILS, payload: { ...company } });
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "success",
              message: "saved successfully",
              open: true,
            },
          });
        })
      );
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${
          showSidebar ? "fullWidth" : "fullWidth1"
        } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="my-auto font-medium text-black cursor-pointer text-left">
            <div className="text-[14px] font-medium">
              {t("Company information")}
            </div>
            <label className="text-[12px] text-gray-600">
              {t("Change setting like name, logo changes etc")}
            </label>
          </div>
          <div className="flex gap-2"></div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <form onSubmit={onSaveSubmit}>
          <div className="h-dvh px-5 pt-5 mt-[6rem] mb-[120px]">
            <div className="grid grid-cols-12 gap-4 border p-3 rounded-lg">
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Name")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={company?.name}
                  required
                  placeholder={t("Enter name")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setCompany({ ...company, name: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Subdomain")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={company?.subdomain}
                  required
                  placeholder={t("Enter Subdomain")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setCompany({ ...company, subdomain: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Website")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={company?.website}
                  required
                  placeholder={t("Enter Website")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setCompany({ ...company, website: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div disabled className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t("Country")}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <SelectCountry
                  height="40px"
                  required={true}
                  placeholder={t("Select Country")}
                  selectedCode={company?.countryCode}
                  countryList={globalReducer.country}
                  onSelectCountry={(country) => {
                    if (country) {
                      company.countryCode = company.code;
                    } else {
                      delete company.countryCode;
                    }
                    setCompany({ ...company });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
            </div>
            <div className="gap-4 border p-3 rounded-lg mt-3">
              <div className="w-[50%]">
                <div className="">
                  <p className="text-[14px] font-bold">{t("Branding")}</p>
                  <label className="text-[12px]">
                    {t("Change Logo, colors")}
                  </label>
                </div>
                <div className="mt-2">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label> {t("Add Logo")}</label>
                  </div>
                  <div className="flex w-full text-[12px]">
                    <UploaderBase64
                      selectedBase64={company?.logo}
                      onUploaded={(d) => {
                        setCompany({ ...company, logo: d });
                      }}
                      accept="image/png,image/jpeg"
                    />
                  </div>
                </div>
                <div className="col-span-4 mt-5">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                      {t('Brand Color1')}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                  </div>
                  <ColorPickerInput
                    required={true}
                    value={company?.brandColor1}
                    onChange={(a) => setCompany({ ...company, brandColor1: a })}
                  />
                </div>
                <div className="col-span-4">
                  <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                      {t('Brand Color 2')}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                  </div>
                  <ColorPickerInput
                    required={true}
                    value={company?.brandColor2}
                    onChange={(a) => setCompany({ ...company, brandColor2: a })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-5 flex gap-2 sticky bottom-0 justify-end w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
            <button
              type="submit"
              className="min-w-[100px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Setting;
