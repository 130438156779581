import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { categoryUpsert } from "../../store/actions/tenantAction";
import { useTranslation } from 'react-i18next';

function AddContactCategory({ setIsShow, onAdd, isEdit = false, defaultCategory = {} }) {
    let dispatch = useDispatch();
    const {t} = useTranslation()
    const [Category, setCategory] = useState(defaultCategory ?? { permission: "PUBLIC" });
    const globalReducer = useSelector((state) => state.globalReducer);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!Category.permission) {
            Category.permission = "PUBLIC";
        }
        if (!isEdit) {
            Category.tenantId = globalReducer.loginInfo?.tenantId;
            Category.uid = globalReducer.loginInfo?.uid;
        }
        dispatch(categoryUpsert(Category, (a) => {
            setIsShow(false);
            onAdd(a);
        }))
    }

    return (
        <form className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto" onSubmit={handleSubmit}>
            <div className="grid grid-cols-12 gap-3 w-full">
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Category Name')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <input type="text" placeholder={t("Enter Category Name")}
                        value={Category?.categoryName} onChange={(e) => setCategory({ ...Category, categoryName: e.target.value })}
                        className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]" required />
                </div>
                <div className="col-span-6">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Permission')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <select required value={Category?.permission} className="border w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
                        onChange={(e) => {
                            setCategory({ ...Category, permission: e.target.value })
                        }}>
                        <option value="PUBLIC" selected>{t('Public')}</option>
                        <option value="PRIVATE">{t('Private')}</option>
                    </select>
                </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
                <button type='button'
                    className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                    onClick={() => setIsShow(false)}>
                    {t('Cancel')}
                </button>
                <button type='submit'
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    {t('Save')}
                </button>
            </div>
        </form>
    )
}

export default AddContactCategory