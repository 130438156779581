import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountInformation } from "../../store/actions/tenantAction";
import { allPlanPermissions } from "../../utils/helper";
import moment from "moment";
import { useTranslation } from "react-i18next";

function AccountInformation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [account, setAccount] = useState({});
  const tenantReducer = useSelector((state) => state.tenantReducer);

  useEffect(() => {
    dispatch(getAccountInformation(setAccount));
  }, []);

  return (
    <div className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto">
      <div className="aa flex flex-col gap-3 text-[14px] font-medium text-black bg-white rounded">
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-box-archive text-theme"></i>
            <div>{t('Plan Name')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.planName ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-calendar-days text-theme"></i>
            <div>{t('Account Expiry')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {moment(account?.toDate).format("YYYY-MM-DD")}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-globe mt-1 text-theme"></i>
            <div> {t('Country')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.countryCode ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-globe mt-1 text-theme"></i>
            <div> {t('Currency')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.currency ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-wallet text-theme"></i>
            <div>{t('Amount')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.planRate ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-file-invoice text-theme"></i>
            <div>{t('Billing Frequency')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.billingFrequency ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-money-check text-theme mt-1"></i>
            <div> {t('No. of WABA Account')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.numWabaNumbers ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-users text-theme mt-1"></i>
            <div> {t('No. of Users')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.numUsers ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
            <div> {t('Campaign Broadcast')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.permissions?.some((f) => f == "CAMPAIGNS") ? "Yes" : "No"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-regular fa-address-book text-theme mt-1"></i>
            <div> {t('No. of Contacts')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.numContacts ?? "-"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
            <div> {t('Live Chat')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.permissions?.some((f) => f == "LIVE_CHAT") ? "Yes" : "No"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-regular fa-file-code text-theme mt-1"></i>
            <div> {t('API')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.permissions?.some((f) => f == "API") ? "Yes" : "No"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-brands fa-rocketchat text-theme mt-1"></i>
            <div> {t('Chatbot')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.permissions?.some((f) => f == "CHATBOT") ? "Yes" : "No"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-solid fa-file-fragment text-theme mt-1"></i>
            <div> {t('Reports')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.permissions?.some((f) => f == "FINANCE_ACCESS") ? "Yes" : "No"}
          </div>
        </div>
        <div className="flex gap-5 justify-between">
          <div className="flex gap-2 whitespace-nowrap justify-center items-center ">
            <i className="fa-regular fa-flag mt-1 text-theme"></i>
            <div> {t('Status')}</div>
          </div>
          <div className="text-gray-600 font-normal">
            {account?.active ? "Active" : "Not Active"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountInformation;
