import React, { useEffect, useState } from "react";
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useDispatch, useSelector } from "react-redux";
import {
  campaignQuery,
  getDashboardInfo,
  getWhatsAppMsgTemplate,
  messageCount,
  messageCountChart,
} from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import PopUp from "../../components/PopUp";
import AccountInformation from "../../layouts/tenant/AccountInformation";
import { useNavigate } from "react-router-dom";
import WalletRecharge from "../../layouts/tenant/WalletRecharge";
import { useTranslation } from "react-i18next";
import TopupHistory from "../../layouts/tenant/TopupHistory";

function Dashboard({ showSidebar }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [totalMessage, setTotalMessage] = useState({});
  const [months, setMonths] = useState([]);
  const [tickPlacement, setTickPlacement] = useState("middle");
  const [statistics, setStatistics] = useState([]);
  const [tickLabelPlacement, setTickLabelPlacement] = useState("middle");
  const [chartData, setChartData] = useState([]);
  const [dashboardInfo, setDashboardInfo] = useState({});
  const [walletRecharge, setWalletRecharge] = useState(false);
  const [showPlanRenew, setShowPlanRenew] = useState(false);
  const [showWalletHistory, setShowWalletHistory] = useState(false);
  const [walletHistoryFilter, setWalletHistoryFilter] = useState({});
  const [showAccountInformation, setShowAccountInformation] = useState(false);
  const [showLoginHistory, setShowLoginHistory] = useState(false);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [broadcasts, setBroadcasts] = useState([]);
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const globalReducer = useSelector((state) => state.globalReducer);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tenantTimezone =
    tenantReducer.tenantDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const chartSetting = {
    yAxis: [{ label: "Statistics" }],
    series: [{ dataKey: "count", label: "Today’s Statistics", valueFormatter }],
    height: 350,
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };
  
  useEffect(() => {
    if (!globalReducer.waba?.id) return;
    let messageDate = moment().tz(wabaTimezone);
    messageDate.subtract(1, "month");
    dispatch(
      messageCount(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          tenantWabaId: globalReducer.waba?.id,
          sendDate: messageDate.format("YYYY-MM-DD"),
        },
        (a) => {
          setTotalMessage(a);
          setStatistics([
            { count: a.totalScheduled, status: "Total" },
            { count: a.accepted, status: "Accepted" },
            { count: a.delivered, status: "Delivered" },
            { count: a.read, status: "Read" },
            { count: a.failed, status: "Failed" }
          ]);
        }
      )
    );
    const month = new Date().getMonth();
    const year = new Date().getFullYear(); // Current year
    const monthDates = [];
    for (var i = 6; i >= 0; i--) {
      const calculatedMonth = month - i; // Calculate the month offset
      const fromDate = new Date(year, calculatedMonth, 1); // Handles month overflow
      const toDate = new Date(year, calculatedMonth + 1, 1); // Next month's first day
      monthDates.push({
        text: moment(fromDate).tz(wabaTimezone).format("MMMM"),
        fromDate: fromDate,
        toDate: toDate,
      });
    }
    setMonths(monthDates);
    dispatch(
      messageCountChart(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          tenantWabaId: globalReducer.waba?.id,
          dates: monthDates,
        },
        (a) => {
          setChartData(a);
        }
      )
    );
    dispatch(getDashboardInfo(globalReducer.waba?.id, setDashboardInfo));
    // if (
    //   globalReducer.loginInfo?.roleDetails?.some((s) =>
    //     s.permissions?.includes("CAMPAIGNS")
    //   )
    // ) {
    //   dispatch(
    //     campaignQuery(
    //       {
    //         criteria: { active: true, tenantWabaId: globalReducer.waba?.id },
    //         tenantId: globalReducer.loginInfo?.tenantId,
    //         count: true,
    //         size: 5,
    //         offset: 0,
    //         orderBy: [{ property: "id", direction: "DESC" }],
    //       },
    //       (d) => setBroadcasts(d?.data ?? [])
    //     )
    //   );
    // }
    // if (
    //   globalReducer.loginInfo?.roleDetails?.some((s) =>
    //     s.permissions?.includes("TEMPLATES")
    //   )
    // ) {
    //   dispatch(
    //     getWhatsAppMsgTemplate(
    //       {
    //         criteria: { active: true, tenantWabaId: globalReducer.waba?.id },
    //         tenantId: globalReducer.loginInfo?.tenantId,
    //         count: true,
    //         size: 5,
    //         offset: 0,
    //         orderBy: [{ property: "id", direction: "DESC" }],
    //       },
    //       (d) => setMessageTemplates(d?.data ?? [])
    //     )
    //   );
    // }
  }, [globalReducer.waba?.id]);

  function valueFormatter(value) {
    return `${value} message`;
  }

  const getGreeting = () => {
    const currentHour = new Date().getHours(); // Get the current hour (0-23)
    if (currentHour < 12) {
      return (
        <span className="text-[#FFA500] font-medium">
          {t("Good Morning!")}&nbsp;
        </span>
      );
    } else if (currentHour < 18) {
      return (
        <span className="text-[#EA2F30] font-medium">
          {t("Good Afternoon!")}&nbsp;
        </span>
      );
    } else {
      return (
        <span className="text-[#00008B] font-medium">
          {t("Good Night!")}&nbsp;
        </span>
      );
    }
  };

  return (
    <div className="h-screen">
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 pt-5 mt-[1rem] mb-[20px]">
          <div className="pt-2 pb-4 flex justify-between items-center"
          >
            <div className="text-[14px]">
              <label>
                {getGreeting()}
                <span className="text-gray-500">

                  {globalReducer.loginInfo?.name}
                </span>
              </label>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 text-[14px]">
            <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
              <div className="flex justify-between">
                {globalReducer.waba?.accountType === "POSTPAID" ? (
                  <div>
                    <label className="text-gray-400">{t("Used Balance")}</label>
                    <br />
                    <label className="text-[#008743] text-[16px]">
                      {globalReducer.waba?.currencyCode}{" "} {dashboardInfo?.remainingBalance ? parseFloat(Math.abs(dashboardInfo?.remainingBalance ?? 0)).toFixed(3) : 0}
                    </label>
                  </div>
                ) : (
                  <div>
                    <label className="text-gray-400">
                      {t("Remaining Balance")}
                    </label>
                    <br />
                    <label className="text-[#008743] text-[16px]">
                      {globalReducer.waba?.currencyCode}{" "} {dashboardInfo?.remainingBalance ? parseFloat(dashboardInfo?.remainingBalance ?? 0).toFixed(3) : 0}
                    </label>
                  </div>
                )}
                <div>
                  <i className="fa-solid fa-suitcase text-[#008743] text-[24px]"></i>
                </div>
              </div>
              <div className="pt-2 border-t">
                <label onClick={() => { navigate("/tenant/account-statement") }} className="cursor-pointer hover:text-theme hover:underline">
                  <i className="fa-solid fa-receipt mr-1"></i>
                  {t("Account Statement")}
                </label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
              <div className="flex justify-between">
                <div>
                  <p className="text-gray-400">{t("Account Type")} </p>
                  <p className="text-[#37B3FF] text-[16px]">
                    {globalReducer.waba?.accountType}
                  </p>
                  {globalReducer.waba?.accountType === "POSTPAID" ? (
                    <div>
                      <p className="text-gray-400">{t("Credit Limit")}</p>
                      <p className="text-[#37B3FF] text-[16px]">
                        {globalReducer.waba?.currencyCode}{" "} {(
                          parseFloat(dashboardInfo?.remainingBalance ?? 0) +
                          parseFloat(globalReducer.waba?.walletLimit ?? 0)
                        ).toFixed(3)}
                      </p>
                    </div>
                  ) : (
                    <>
                      <label className="text-gray-400">
                        {t("Last Credit Topup")}
                      </label>
                      <br />
                      <label className="text-[#37B3FF] text-[16px]">
                        {globalReducer.waba?.currencyCode}{" "}  {dashboardInfo?.lastCreditTopUp ? parseFloat(dashboardInfo?.lastCreditTopUp ?? 0).toFixed(3) : 0}
                      </label>
                      <br />
                    </>
                  )}
                </div>
                <div>
                  <i className="fa-solid fa-cart-shopping text-[#37B3FF] text-[24px]"></i>
                </div>
              </div>
              <div className="pt-2 border-t flex justify-between">
                <label
                  onClick={() => {
                    setShowWalletHistory(true);
                    setWalletHistoryFilter({ walletTransactionType: "CREDIT" });
                  }}
                  className="cursor-pointer hover:text-theme hover:underline"
                >
                  <i className="fa-regular fa-eye mr-1"></i>
                  {t("View More")}
                </label>
                <label
                  onClick={() => setWalletRecharge(true)}
                  className="cursor-pointer text-theme hover:underline"
                >
                  Recharge
                </label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
              <div className="flex justify-between">
                <div className="mb-1">
                  <label className="text-gray-400 text-[14px]">
                    {t("Account Expiry Date")}
                  </label>
                  <br />
                  {dashboardInfo?.accountExpiryDate ? (
                    moment
                      .utc()
                      .isBefore(
                        moment.utc(dashboardInfo?.accountExpiryDate)
                      ) ? (
                      <label className="text-[#007C00] text-[16px]">
                        {moment
                          .utc(dashboardInfo?.accountExpiryDate)
                          .tz(tenantTimezone)
                          .format("DD-MM-YYYY")}
                      </label>
                    ) : (
                      <label className="text-[#D92D20] text-[16px]">
                        {moment
                          .utc(dashboardInfo?.accountExpiryDate)
                          .tz(tenantTimezone)
                          .format("DD-MM-YYYY")} {" "}
                        {t("Expired")}
                      </label>
                    )
                  ) : (
                    <label className="text-[#D92D20] text-[16px]">
                      {t("Not Active")}
                    </label>
                  )}
                  <br />
                  <label className="text-gray-400 text-[14px]">
                    {t("API Status")}
                  </label>
                  <br />
                  {dashboardInfo?.apiExpiryDate ? (
                    moment
                      .utc()
                      .isBefore(moment.utc(dashboardInfo?.apiExpiryDate)) ? (
                      <label className="text-[#007C00] text-[16px]">
                        Active
                      </label>
                    ) : (
                      <label className="text-[#D92D20] text-[16px]">
                        {t("Expired")}
                      </label>
                    )
                  ) : (
                    <label className="text-[#D92D20] text-[16px]">
                      {t("Not Active")}
                    </label>
                  )}
                </div>
                <div>
                  <i className="fa-regular fa-calendar-days text-[#D92D20] text-[24px]"></i>
                </div>
              </div>
              <div className="pt-2 border-t flex justify-between">
                {dashboardInfo?.accountExpiryDate && (
                  <label
                    onClick={() => setShowAccountInformation(true)}
                    className="cursor-pointer hover:text-theme hover:underline"
                  >
                    <i className="fa-regular fa-calendar mr-1"></i>
                    {t("Account Information")}
                  </label>
                )}
                {dashboardInfo?.accountExpiryDate &&
                  moment
                    .utc(dashboardInfo?.accountExpiryDate)
                    .isBefore(moment.utc().add(6, "days")) ? (
                  <label
                    className="cursor-pointer text-theme hover:underline"
                    onClick={() => setShowPlanRenew(true)}
                  >
                    {t("Renew")}
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
              <div className="flex justify-between">
                <div>
                  <label className="text-gray-400">
                    {t("Last Login Date Time")}
                  </label>
                  <br />
                  <label className="text-[#F7B400] text-[16px]">
                    {moment
                      .utc(dashboardInfo?.lastLoginDateTime)
                      .tz(tenantTimezone)
                      .format("YYYY-MM-DD hh:mm A")}
                  </label>
                  <br />
                </div>
                <div>
                  <i className="fa-regular fa-clock text-[#F7B400] text-[24px]"></i>
                </div>
              </div>
              <div className="pt-2 border-t">
                <label
                  onClick={() => {
                    navigate("/tenant/login-history")
                  }}
                  className="cursor-pointer hover:text-theme hover:underline"
                >
                  <i className="fa-solid fa-clock-rotate-left mr-1"></i>
                  {t("Login History")}
                </label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 text-[14px] mt-4">
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Scheduled")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.totalScheduled ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check text-gray-500"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Today Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Accepted")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.accepted ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check text-gray-500"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Today Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Delivered")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.delivered ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check-double text-gray-500"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Today Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Read")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.read ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check-double text-[#1291D0]"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Today Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Failed")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.failed ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-xmark text-[#D92D20]"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#D92D20]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Today Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Queued")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.queued ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-regular fa-clock text-[#F7B400]"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#F7B400]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Today Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Scheduled")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.totalScheduledByDate ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check text-gray-500"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Last 30 Days Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Accepted")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.acceptedByDate ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check text-gray-500"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Last 30 Days Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Delivered")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.deliveredByDate ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check-double text-gray-500"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Last 30 Days Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Read")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.readByDate ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-check-double text-[#1291D0]"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#008743]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Last 30 Days Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Failed")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.failedByDate ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-solid fa-xmark text-[#D92D20]"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#D92D20]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Last 30 Days Status")}</label>
              </div>
            </div>
            <div className="bg-white p-3 rounded-lg shadow-lg">
              <label className="text-gray-400">{t("Total Queued")}</label>
              <div className="flex justify-between">
                <div className="text-[16px] pt-0.5">
                  {new Intl.NumberFormat("en-US").format(totalMessage?.queuedByDate ?? 0)}
                </div>
                <div>
                  <button
                    type="button"
                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                  >
                    <i className="fa-regular fa-clock text-[#F7B400]"></i>
                  </button>
                </div>
              </div>
              <div className="flex text-[#F7B400]">
                <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                <label className="ml-1">{t("Last 30 Days Status")}</label>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 mt-5">
            <div className="col-span-8">
              {months.length > 0 && (
                <LineChart
                  height={350}
                  series={[
                    {
                      data: chartData.map((m) => m.totalScheduled),
                      label: "Total",
                      area: true,
                      stack: "total",
                      showMark: false,
                    },
                    {
                      data: chartData.map((m) => m.accepted),
                      label: "Accepted",
                      area: true,
                      stack: "total",
                      showMark: false,
                    },
                    {
                      data: chartData.map((m) => m.delivered),
                      label: "Delivered",
                      area: true,
                      stack: "total",
                      showMark: false,
                    },
                    {
                      data: chartData.map((m) => m.read),
                      label: "Read",
                      area: true,
                      stack: "total",
                      showMark: false,
                    },
                    {
                      data: chartData.map((m) => m.failed),
                      label: "Failed",
                      area: true,
                      stack: "total",
                      showMark: false,
                    },
                  ]}
                  xAxis={[
                    { scaleType: "point", data: months.map((m) => m.text) },
                  ]}
                  sx={{
                    [`& .${lineElementClasses.root}`]: {
                      display: "none",
                    },
                  }}
                />
              )}
            </div>
            <div className="col-span-4">
              <BarChart
                dataset={statistics}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "status",
                    tickPlacement,
                    tickLabelPlacement,
                  },
                ]}
                {...chartSetting}
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-5 mt-3 mb-3">
            {broadcasts?.length > 0 && (
              <div className="col-span-6">
                <p className="text-[16px] flex justify-between">
                  {t("Campaigns")}
                  <label
                    className="ml-2 text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                    onClick={() => navigate("/tenant/campaigns")}
                  >
                    {t("View More")}
                  </label>
                </p>
                <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                  <thead className="text-[14px] text-midBlack bg-gray-200">
                    <tr>
                      <th scope="col" className="font-[500] py-3 pl-2">
                        <div>{t("Broadcast Name")}</div>
                      </th>
                      <th scope="col" className="font-[500] w-[160px]">
                        <div> {t("Status")}</div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div>{t("Broadcast time")}</div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div>{t("Schedule Type")}</div>
                      </th>
                      {/* <th scope="col" className="font-[500]">
                      <div>Delivered</div>
                    </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {broadcasts.map((p, i) => {
                      return (
                        <tr className="bg-white border-b">
                          <td className={"py-3 text-[13px] font-normal pl-2"}>
                            {p?.campaignName ?? "-"}
                          </td>
                          <td className="py-3 font-normal text-[13px]">
                            {p?.status ?? "-"}
                          </td>
                          <td className="py-3 font-normal text-[13px]">
                            {moment
                              .utc(p?.scheduledTime)
                              .tz(wabaTimezone)
                              .format("YYYY-MM-DD hh:mm A") ?? "-"}
                          </td>
                          <td className="py-3 font-normal text-[13px]">
                            {p?.scheduleType ?? "-"}
                          </td>
                          {/* <td className="py-3 font-normal text-[13px]">
                          {p?.delivered ?? "100%"}
                        </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {messageTemplates?.length > 0 && (
              <div className="col-span-6">
                <p className="text-[16px] flex justify-between">
                  {t("Templates")}
                  <label
                    className="ml-2 text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                    onClick={() => navigate("/tenant/templates")}
                  >
                    {t("View More")}
                  </label>
                </p>
                <table className="text-sm text-left rtl:text-right text-midBlack w-full mt-1 overflow-x-auto">
                  <thead className="text-[14px] text-midBlack bg-gray-200">
                    <tr>
                      <th scope="col" className="font-[500] py-3 pl-2">
                        <div className="flex gap-2">
                          <div>{t("Name")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div>{t("Template Id")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500] w-[160px]">
                        <div className="flex gap-2">
                          <div> {t("Status")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t("Crated Date")}</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {messageTemplates?.map((mst, j) => {
                      return (
                        <tr key={j} className="bg-white border-b">
                          <td className={"py-3 text-[13px] font-normal pl-2"}>
                            {mst?.name ?? "-"}
                          </td>
                          <td className={"py-3 text-[13px] font-normal"}>
                            {mst?.referenceId ?? "-"}
                          </td>
                          <td className={"py-3 text-[13px] font-normal"}>
                            {mst?.status?.toLowerCase() === "approved" ? (
                              <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                                {mst?.status}
                              </label>
                            ) : (
                              <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                                {mst?.status}
                              </label>
                            )}
                          </td>
                          <td className={"py-3 text-[13px] font-normal"}>
                            {moment
                              .utc(mst?.createdAt)
                              .tz(wabaTimezone)
                              .format("YYYY-MM-DD hh:mm A")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Wallet History")}
          </span>
        }
        isShowModal={showWalletHistory}
        setIsShowModal={setShowWalletHistory}
        ModalBody={
          <TopupHistory
            setIsShow={setShowWalletHistory}
            walletHistoryFilter={walletHistoryFilter}
            tenantWabaId={globalReducer.waba?.id}
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Account Information")}
          </span>
        }
        isShowModal={showAccountInformation}
        setIsShowModal={setShowAccountInformation}
        ModalBody={<AccountInformation setIsShow={setShowAccountInformation} />}
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Recharge")}
          </span>
        }
        isShowModal={walletRecharge}
        setIsShowModal={setWalletRecharge}
        ModalBody={
          <WalletRecharge
            setIsShow={setWalletRecharge}
            selectedWaba={globalReducer.waba}
            paymentFor="RECHARGE"
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Subscription Renew")}
          </span>
        }
        isShowModal={showPlanRenew}
        setIsShowModal={setShowPlanRenew}
        ModalBody={
          <WalletRecharge
            setIsShow={setShowPlanRenew}
            paymentFor="RENEW"
            plan={tenantReducer?.tenantDetails?.plan}
          />
        }
        size="2xl"
      />
    </div>
  );
}

export default Dashboard;
