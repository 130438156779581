import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { allTenantRolePermissions } from '../../utils/helper';
import { Autocomplete, TextField } from '@mui/material';
import { upsertTenantRole } from '../../store/actions/partnerAction';
import { SHOW_SNACKBAR } from '../../utils/types';

function AddTenantRole({ setIsShow, selectedRole, onAdd }) {
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const [role, setRole] = useState({});

    useEffect(() => {
        if (selectedRole?.isEdit || selectedRole?.isView) {
            setRole({ ...selectedRole })
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (role.permissions?.length > 0) {
            role.displayName = role.name;
            dispatch(upsertTenantRole(role, (a) => {
                setIsShow(false);
                onAdd(a);
            }));
        } else {
            dispatch({
                type: SHOW_SNACKBAR,
                payload: {
                    type: "error",
                    message: "Please select at least one permission.",
                    open: true,
                },
            });
            return;
        }
    };

    const onEnterRoleName = (e) => {
        let name = e.target.value?.replace(/ /g, "_");
        const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
        if (!name || regex.test(name)) {
            setRole({ ...role, name: name });
        } else if (name) {
            setRole({ ...role, name: name?.replace(e.nativeEvent.data, "") });
        }
    };

    return (<form disabled={selectedRole?.isView} className="height30 flex flex-col justify-between pb-3" onSubmit={handleSubmit}>
        <div className="h-dvh overflow-y-auto p-4">
            <div className="grid grid-cols-12 gap-3 w-full mapPlan">
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Role Name')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <input
                        type="text"
                        placeholder={t("Enter Role Name")}
                        value={role?.name}
                        onChange={onEnterRoleName}
                        className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
                        required
                    />
                </div>
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Permissions')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <Autocomplete disabled={selectedRole?.isView}
                        multiple={true}
                        aria-required={true}
                        value={allTenantRolePermissions?.filter((m) =>
                            role.permissions?.some((s) => s === m.key)
                        )}
                        options={allTenantRolePermissions}
                        isOptionEqualToValue={(option, value) =>
                            option?.key === value?.key
                        }
                        getOptionLabel={(option) => option?.key ?? ""}
                        onChange={(e, value) => {
                            setRole({
                                ...role,
                                permissions: value?.map((c) => c.key) ?? [],
                            });
                        }}
                        sx={{
                            "& .MuiAutocomplete-inputRoot": {
                                padding: "0px",
                            },
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    fontSize: "13px", // Change font size of dropdown options
                                }
                            },
                            listbox: {
                                sx: {
                                    fontSize: "13px", // Change font size of listbox items
                                }
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Select Category"
                                className="custom-input"
                                sx={{
                                    "& .MuiInputBase-root": {
                                        padding: "0px",
                                        "&.Mui-focused fieldset": {
                                            borderColor: "none",
                                            border: "0px",
                                        },
                                        fontSize: "13px",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: "#FFFFFF",
                                        "& fieldset": {
                                            borderColor: "#ccc",
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#888",
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#1976d2",
                                        },
                                    },
                                }}
                            />
                        )}
                    />
                </div>
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Description')}</label>
                    </div>
                    <textarea
                        placeholder={t("Enter Description")}
                        rows="2"
                        value={role?.descr}
                        onChange={(e) => setRole({ ...role, descr: e.target.value })}
                        className="border border-gray-300 rounded-md text-[13px] px-3 h-[100px] py-1 w-[100%]"
                    />
                </div>
            </div>
        </div>
        <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
            <div className="flex justify-end gap-2 my-2">
                <button
                    type="submit"
                    className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                >
                    {t('Save')}
                </button>
            </div>
        </div>
    </form>)
}

export default AddTenantRole