import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'

function SelectDateTime({ required, className, value, onChange, timezone }) {
  const [formattedValue, setFormattedValue] = useState("");
  const [defaultTimezone, setDefaultTimezone] = useState(timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    setFormattedValue(value
      ? moment.utc(value).tz(defaultTimezone).format('YYYY-MM-DDTHH:mm')
      : moment.utc().tz(defaultTimezone).format('YYYY-MM-DDTHH:mm'))
  }, [defaultTimezone, value]);

  const onDateChange = (e) => {
    const localTime = e.target.value;
    if (localTime) {
      const zonedTime = moment.tz(localTime, defaultTimezone).utc();
      const tm = zonedTime.format('YYYY-MM-DDTHH:mm:ss');
      setFormattedValue(tm);
      onChange(tm);
    }
  };

  return (
    <input
      type="datetime-local"
      required={required}
      value={formattedValue}
      onChange={onDateChange}
      className={`w-full border-0 border-gray-300 rounded-md ${className ?? ''}`}
    />
  )
}

export default SelectDateTime