import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import RightSidePanel from "../../components/RightSidePanel";
import PopUp from "../../components/PopUp";
import AddPartner from "../../layouts/globalAdmin/AddPartner";
import PartnerDetails from "../../layouts/globalAdmin/PartnerDetails";
import { getPartners, changeStatus } from "../../store/actions/adminAction";
import { useDispatch, useSelector } from "react-redux";
import AddPartnerUser from "../../layouts/AddPartnerUser";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import MapPartnerRateCard from "../../layouts/globalAdmin/MapPartnerRateCard";
import { useTranslation } from "react-i18next";

function ManagePatner({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAddPartner, setShowAddPartner] = useState(false);
  const [showAddPartnerUser, setShowAddPartnerUser] = useState(false);
  const [showPartnerDtails, setShowPartnerDetails] = useState(false);
  const [viewMapRateCard, setViewMapRateCard] = useState(false);
  const [Partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ criteria: {} });
  const globalReducer = useSelector((state) => state.globalReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

  useEffect(() => {
    partnerSearch();
  }, [currentPage]);

  const partnerSearch = () => {
    dispatch(
      getPartners(
        {
          ...filter,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "createdAt", direction: "DESC" }]
        },
        (d) => {
          setPartners(d?.data ?? []);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      partnerSearch();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular text-[14px] fa fa-handshake-o text-theme"></i>
            <div className="text-[14px] font-medium">{t('Manage Partner')}</div>
          </div>
          <div className="flex gap-2">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedPartner({});
                setShowAddPartner(true);
              }}
            >
              {t('Add Partner')}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Partner Name')}</label>
              </div>
              <input
                type="text"
                placeholder={t('Name')}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    filter: [
                      {
                        property: "name",
                        operator: "ICONTAINS",
                        value: e.target.value,
                      },
                    ],
                  })
                }
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Country')}</label>
              </div>
              <SelectCountry
                countryList={globalReducer.country}
                onSelectCountry={(country) => {
                  if (country) {
                    filter.criteria.countryCode = country.code;
                  } else {
                    delete filter.criteria.countryCode;
                  }
                  setFilter({ ...filter });
                }}
                placeholder={t('Select Country')}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Status')}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {Partners && Partners?.length > 0 ? (
            <div className="h-full">
              <div className="mt-2 bg-white">
                <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                  <thead className="text-[14px] text-midBlack bg-gray-200">
                    <tr>
                      <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                        {t('Sr. No.')}
                      </th>
                      <th scope="col" className="font-[500] py-3 pl-2">
                        <div className="flex gap-2">
                          <div> {t('Partner Id')} </div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t('Partner Name')} </div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t("Country")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500] w-[160px]">
                        <div className="flex gap-2">
                          <div>{t('Status')}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div>{t('Action')}</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Partners.map((p, i) => {
                      return (
                        <tr className="bg-white border-b" key={i}>
                          <td className="py-3 text-[13px] pl-2">
                            {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                          </td>
                          <td className="py-3 text-[13px] pl-2 capitalize font-semibold">
                            {p?.id ?? "-"}
                          </td>
                          <th
                            scope="row"
                            className="py-3 text-[13px] font-medium hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                            onClick={() => {
                              setSelectedPartner(p);
                              setShowPartnerDetails(true);
                            }}
                          >
                            {p?.name ?? "-"}
                          </th>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.country?.name ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.active ? (
                              <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                                Active
                              </label>
                            ) : (
                              <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                                Not Active
                              </label>
                            )}
                          </td>
                          <td className=" py-3 text-[13px] font-normal">
                            <Dropdown
                              inline
                              arrowIcon={false}
                              inputMode
                              as="button"
                              className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                              label={<i className="fa-solid fa-ellipsis"></i>}
                            >
                              {p?.active ? (
                                <Dropdown.Item
                                  onClick={() => {
                                    dispatch(
                                      changeStatus(
                                        { id: p?.id, isActive: false },
                                        (a) => partnerSearch()
                                      )
                                    );
                                  }}
                                >
                                  {t('Deactivate')}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => {
                                    dispatch(
                                      changeStatus(
                                        { id: p?.id, isActive: true },
                                        (a) => partnerSearch()
                                      )
                                    );
                                  }}
                                >
                                  {t('Activate')}
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedPartner({ ...p, isEdit: true });
                                  setShowAddPartner(true);
                                }}
                              >
                                {t('Edit')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedPartner(p);
                                  setShowAddPartnerUser(true);
                                }}
                              >
                                {t('Add User')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setSelectedPartner(p);
                                  setViewMapRateCard(true);
                                }}
                              >
                                {t("Map Rate Card")}
                              </Dropdown.Item>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {totalPages > 1 ? (
                  <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                    <Pagination
                      page={currentPage}
                      count={totalPages}
                      onChange={(event, page) => setCurrentPage(page)}
                      shape="rounded"
                      color="secondary"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t("The space is empty . Add a Partner to get started.")}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => setShowAddPartner(true)}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t("Add Partner")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showPartnerDtails}
        setIsShow={setShowPartnerDetails}
        ModalTitle="Partner Details"
        Component={
          <PartnerDetails
            setIsShow={setShowAddPartner}
            partnerId={selectedPartner.id}
          />
        }
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t('Add Partner')}
          </span>
        }
        isShowModal={showAddPartner}
        setIsShowModal={setShowAddPartner}
        ModalBody={
          <AddPartner
            setIsShow={setShowAddPartner}
            onAdd={partnerSearch}
            isEdit={selectedPartner.isEdit}
            defaultPartner={selectedPartner}
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">{t('Add User')}</span>
        }
        isShowModal={showAddPartnerUser}
        setIsShowModal={setShowAddPartnerUser}
        ModalBody={
          <AddPartnerUser
            isSuperAdmin={true}
            setIsShow={setShowAddPartnerUser}
            onAdd={() => { }}
            partnerId={selectedPartner.id}
          />
        }
        size="2xl"
      />
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewMapRateCard}
        setIsShow={setViewMapRateCard}
        ModalTitle={t("Map Rate Card")}
        Component={
          <MapPartnerRateCard
            setIsShow={setViewMapRateCard}
            partner={selectedPartner}
            onAdd={() => { }}
          />
        }
      />
    </div>
  );
}

export default ManagePatner;
