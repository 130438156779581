import React from "react";
import WhatsAppMsgTemplates from "../../layouts/tenant/WhatsAppMsgTemplates";
import { useTranslation } from "react-i18next";

function Templates({ showSidebar }) {
  const { t } = useTranslation();
  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <svg fill="#E92F2F" width="24px" height="24px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.5,68.5v-34H26.9a4.89,4.89,0,0,0-4.8,4.9V74.8a4.89,4.89,0,0,0,4.8,4.9H62.5a4.89,4.89,0,0,0,4.8-4.9V73.4h-34A4.89,4.89,0,0,1,28.5,68.5Z" /><path d="M46.4,30.2H64.1a1.58,1.58,0,0,0,1.6-1.6V25.3a4.89,4.89,0,0,0-4.8-4.9H49.6a4.82,4.82,0,0,0-4.8,4.9v3.3A1.64,1.64,0,0,0,46.4,30.2Z" />
              <path d="M73,24.4H71.4a.74.74,0,0,0-.8.8v3.3a6.57,6.57,0,0,1-6.5,6.6H46.4a6.64,6.64,0,0,1-6.5-6.6V25.2a.74.74,0,0,0-.8-.8H37.5a4.82,4.82,0,0,0-4.8,4.9V64.6a4.89,4.89,0,0,0,4.8,4.9H73a4.82,4.82,0,0,0,4.8-4.9V29.4A4.85,4.85,0,0,0,73,24.4ZM60.9,55.5a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V53.9a1.58,1.58,0,0,1,1.6-1.6H59.3a1.58,1.58,0,0,1,1.6,1.6ZM69,47.3a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V45.7a1.58,1.58,0,0,1,1.6-1.6H67.4A1.58,1.58,0,0,1,69,45.7Z" />
            </svg>
            <div className="text-[14px] font-medium">{t('Manage Template')}</div>
          </div>
          <div className="flex gap-2">
            {/* <div
                            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                            onClick={() => { }}>Add User
                        </div> */}
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <WhatsAppMsgTemplates isPopup={false} />
      </div>
    </div>
  );
}

export default Templates;
