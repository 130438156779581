import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatbotResponsesUpsert } from "../../store/actions/tenantAction";
import { useTranslation } from "react-i18next";

function AddChatbotResponses({ setIsShow, isEdit, onAdd, selectedResponse }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [response, setResponse] = useState({ responses: [], keywords: [] });
  const [isDefault, setIsDefault] = useState(false);
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (isEdit && selectedResponse) {
      setResponse({
        ...selectedResponse,
        responses: selectedResponse?.responses ?? [],
        keywords: selectedResponse?.keywords ?? [],
      });
      if (selectedResponse?.intentName?.toLowerCase() === "default") {
        setIsDefault(true);
      }
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEdit) {
      response.tenantId = globalReducer.loginInfo?.tenantId;
      response.tenantWabaId = globalReducer.waba?.id;
      response.type = "text";
    }
    dispatch(
      chatbotResponsesUpsert(response, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="height30 flex flex-col justify-between pb-3"
      onSubmit={handleSubmit}
    >
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-1 gap-3 w-full">
          <div className="w-full">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label> {t("Intent Name")}</label>
            </div>
            <div className="flex justify-between gap-4">
              <div className="group relative w-full">
                <input
                  type="text"
                  disabled={isDefault}
                  placeholder={t("Enter intent Name")}
                  value={response?.intentName}
                  onChange={(e) =>
                    setResponse({ ...response, intentName: e.target.value })
                  }
                  className="border border-gray-300 rounded-md py-2 text-[14px] w-full"
                  data-maxlength={100}
                  onInput={OnInput}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                  <p className="text-xs bg-green-100 text-green-800 py-1 px-1 rounded">
                    {response?.intentName?.length}/100
                  </p>
                </div>
              </div>
              <div className="mt-2">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setIsDefault(!isDefault);
                      setResponse({
                        ...response,
                        intentName: isDefault ? "" : "default",
                      });
                    }}
                    checked={isDefault}
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                  <span className="ms-3 text-sm font-medium text-gray-900 ">
                    {t("IsDefault")}
                  </span>
                </label>
              </div>
            </div>
          </div>
          {!isDefault && (
            <div className="col-span-12 border-t border-gray-300 mt-2">
              <div className="text-gray-800 text-[13px] font-normal my-2 flex justify-between">
                <label>{t("Keywords")}</label>
                <button
                  type="button"
                  onClick={() => {
                    if (!Array.isArray(response.keywords)) {
                      response.keywords = [];
                    }
                    response.keywords.push("");
                    setResponse({ ...response });
                  }}
                  className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
                >
                  <i className="fa-solid fa-plus"></i>
                  {t("Add")}
                </button>
              </div>
              {response?.keywords?.map((b, i) => (
                <div key={i} className="flex gap-2 w-full items-center">
                  <div className="text-indigo-400">{`${i + 1}`}</div>
                  <div className="group relative w-full h-fit m-[2px]">
                    <input
                      required
                      maxLength={250}
                      type="text"
                      value={b}
                      placeholder={`Type keyword for ${i + 1}`}
                      className="border skipped w-full flex-grow border-gray-300 py-2 focus:border-none text-sm rounded-md bg-white"
                      onChange={(e) => {
                        response.keywords[i] = e.target.value;
                        setResponse({ ...response });
                      }}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-center pr-2">
                      <p className="text-xs bg-green-100 text-green-800 py-1 px-1  rounded">
                        {b?.length}/250
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        response.keywords?.splice(i, 1);
                        setResponse({ ...response });
                      }}
                      className="items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow absolute hidden group-hover:block top-[-8px] right-[-8px] hover:bg-red-500 hover:text-white h-fit rounded-full border text-[#374151] border-gray-300 bg-white p-0.5"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-x w-3.5 h-3.5 "
                      >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="col-span-12 border-t border-gray-300">
            <div className="text-gray-800 text-[13px] font-normal my-2 flex justify-between">
              <label>{t("Responses")}</label>
              <button
                type="button"
                onClick={() => {
                  if (!Array.isArray(response.responses)) {
                    response.responses = [];
                  }
                  response.responses.push("");
                  setResponse({ ...response });
                }}
                className="px-2 py-1 whitespace-nowrap gap-2 bg-[#EEF2FF] flex items-center border rounded-md border-gray-300 hover:text-theme text-[12px] font-medium text-[#9D8CF8]"
              >
                <i className="fa-solid fa-plus"></i>
                {t("Add")}
              </button>
            </div>
            {response?.responses?.map((b, i) => (
              <div key={i} className="flex gap-2 w-full items-center">
                <div className="text-indigo-400">{`${i + 1}`}</div>
                <div className="group relative w-full h-fit m-[2px]">
                  <textarea
                    value={b}
                    placeholder={`Type response for ${i + 1}`}
                    className="border border-gray-300 rounded-md p-1 w-[100%] text-[14px] skipped"
                    required
                    rows={4}
                    data-maxlength={1024}
                    onInput={OnInput}
                    onChange={(e) => {
                      response.responses[i] = e.target.value;
                      setResponse({ ...response });
                    }}
                  ></textarea>
                  <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-2 flex items-end pr-2">
                    <p className="text-xs bg-green-100 text-green-800 mb-2 py-1 px-1 rounded">
                      {b?.length}/1024
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      response.responses?.splice(i, 1);
                      setResponse({ ...response });
                    }}
                    className="items-center justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow absolute hidden group-hover:block top-[-8px] right-[-8px] hover:bg-red-500 hover:text-white h-fit rounded-full border text-[#374151] border-gray-300 bg-white p-0.5"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-x w-3.5 h-3.5 "
                    >
                      <path d="M18 6 6 18" />
                      <path d="m6 6 12 12" />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="button"
            className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
            onClick={() => setIsShow(false)}
          >
            {t("Cancel")}
          </button>
          <button
            type="submit"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddChatbotResponses;
