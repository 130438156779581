import { ColorPicker } from 'antd';
import React, { useEffect, useState } from 'react';

export default function ColorPickerInput({ onChange, value, required = false, placeholder = "Enter Code" }) {
    const [hexColor, setHexColor] = useState(value?.replace("#", "") ?? "");

    useEffect(() => {
        setHexColor(value?.replace("#", "") ?? "");
    }, [value])

    const onColorChange = (v) => {
        setHexColor(v);
        onChange(v);
    }
    const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
    return (
        <div className='flex border border-gray-300 rounded-md text-[14px] p-[1px]'>
            <label className='border-r border-gray-400 h-[30px] text-[20px] px-2'>#</label>
            <input type="text" placeholder={placeholder} value={hexColor} data-maxlength={6} onInput={OnInput}
                onChange={(a) => onColorChange(a.target.value?.replace("#", ""))} className="py-1 text-[14px] border-0 focus:border-0 w-full rounded-md" required={required} />
            <label className='border-l border-gray-400 h-[30px]'></label>
            <div className='w-8 text-center'>
                <ColorPicker className='border-0' value={hexColor} onChangeComplete={(c) => onColorChange(c.toHex())} />
            </div>
        </div>
    )
}
