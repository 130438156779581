import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function TestMessage({ setMobileNumbers }) {
    const [mobile, setMobile] = useState();
    const {t} = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        setMobileNumbers(mobile?.split(","));
    }
    return (
        <form onSubmit={handleSubmit} className='px-4'>
            <label className='text-[14px] text-gray-500'>{t('You have 20/20 test messages left.')}</label>
            <input type="text" placeholder='Enter WhatsApp numbar with country code e.g. 968972347' required className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight sm:text-sm"
                onChange={(e) => { setMobile(e.target.value) }} />
            <div className='flex justify-center pb-3'>
                <button type='submit' className="min-w-[76px] mt-2 text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    {t('Send')}
                </button>
            </div>
        </form>
    )
}

export default TestMessage