import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL, {
    reconnectionAttempts: 5,
    timeout: 10000,
    transports: ["websocket", "polling"],
});

socket.on("connect_error", (error) => {
    console.error("Connection error:", error);
});

export default socket;
