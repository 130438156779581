import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MultiSelect from '../../components/Dropdowns/MultiSelect';
import { getTenants, sendNotification } from '../../store/actions/partnerAction';
import { SHOW_SNACKBAR } from '../../utils/types';

function SendNotification({ setIsShow, selectedNotification, onSend }) {
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const [notification, setNotification] = useState(selectedNotification);
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState([]);

    useEffect(() => {
        dispatch(getTenants({
            criteria: { active: true },
            count: true,
            select: ["id", "name"],
            size: 500,
            offset: 0,
        }, (d) => {
            setCompanyList(d?.data ?? []);
        }));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const clickedButton = e.nativeEvent.submitter;
        if (clickedButton?.value === "All") {
            notification.tenantIds = companyList?.map(m => m.id);
        } else {
            if (selectedCompany?.length === 0) {
                dispatch({
                    type: SHOW_SNACKBAR,
                    payload: {
                        type: "error",
                        message: "please select at least one company.",
                        open: true,
                    },
                });
                return;
            }
            notification.tenantIds = selectedCompany?.map(m => m.id);
        }
        dispatch(sendNotification(notification, (a) => {
            setIsShow(false);
            onSend(a);
        }));
    };

    return (<form className="height30 flex flex-col justify-between pb-3" onSubmit={handleSubmit}>
        <div className="h-dvh overflow-y-auto p-4">
            <div className="grid gap-3 w-full mapPlan">
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Notification')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <textarea disabled placeholder={t("Enter Notification Content")} rows="2" required
                        value={notification?.content} className="border border-gray-300 text-gray-500 rounded-md text-[13px] px-3 h-[200px] py-1 w-[100%]"
                    />
                </div>
                <div className="col-span-12">
                    <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                        <label>{t('Company')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <MultiSelect list={companyList} selected={selectedCompany} selectedValueField="id" labelField="name" valueField="id" placeholder={t("Select Company Name")} onSelect={setSelectedCompany} />
                </div>
                <div className="flex gap-4 mt-4">
                    <div className="flex gap-2 my-2">
                        <button type="submit" value="Selected" className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                            {t('Send Notification')}
                        </button>
                    </div>
                    <div className="flex gap-2 my-2">
                        <button type="submit" value="All" className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                            {t('Send Notification to All Company')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    )
}

export default SendNotification