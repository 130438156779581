import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBspApiKey } from "../../store/actions/partnerAction";
import MobileInput from "../../components/MobileInput";
import { useTranslation } from "react-i18next";

function FetchApiKey({ setIsShow, onGetApiKey, selectedWaba }) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [waba, setWaba] = useState(selectedWaba ?? {});
  const globalReducer = useSelector((state) => state.globalReducer);

  const onFetchApiKey = (e) => {
    e.preventDefault();
    dispatch(
      fetchBspApiKey(waba, (a) => {
        onGetApiKey(a);
        setIsShow(false);
      })
    );
  };

  return (
    <form className="py-2 px-4" onSubmit={onFetchApiKey}>
      <div className="py-2">
        <div className="text-midBlack text-[13px] font-normal mb-1">
          <label>
            {t('Enter Phone Number')}<span className="text-red-500 text-[14px]">*</span>
          </label>
        </div>
        <MobileInput
          className={"py-1 h-10 text-[16px]"}
          required={true}
          countryList={globalReducer.country}
          phoneCode={waba?.phoneCode}
          phone={waba?.phone}
          setPhoneCode={(code) => setWaba({ ...waba, phoneCode: code })}
          setPhone={(phone) => setWaba({ ...waba, phone: phone })}
          placeholder={t('Enter Phone Number')}
        />
      </div>
      <div className="py-2 flex justify-center">
        <button
          type="submit"
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
        >
          {t('Get')}
        </button>
      </div>
    </form>
  );
}

export default FetchApiKey;
