import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from "@mui/material";
import { getagentMetricsReport } from '../../store/actions/tenantAction';
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import { msgStatusColor, msgStatusTextColor } from '../../utils/helper';
import dayjs from 'dayjs';
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function AgentMetrics({ showSidebar }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isBusinessHour, setIsBusinessHour] = useState(false);
    const [conversationData, setConversationData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchText, setSearchText] = useState("");
    const globalReducer = useSelector((state) => state.globalReducer);
    const itemParPage = 100;
    const [filter, setFilter] = useState({
        criteria: { tenantId: globalReducer.loginInfo.tenantId },
    });
    const [dateFilter, setDateFilter] = useState({
        property: "createdAt",
        operator: "RANGE",
        lowerBound: Date.parse(dayjs().startOf("month").toDate()),
        upperBound: Date.parse(dayjs().add(1, "hour").toDate())
    });
    const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if (globalReducer.waba?.id) {
            templateDataSearch();
        }
    }, [currentPage]);

    const preparedFilter = () => {
        const fl = [];
        if (searchText) {
            fl.push({ clause: "OPEN_BRACKET" },
                {
                    property: "contact.whatsappNumber",
                    operator: "CONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.email",
                    operator: "ICONTAINS",
                    value: searchText,
                },
                { clause: "OR" },
                {
                    property: "contact.name",
                    operator: "ICONTAINS",
                    value: searchText,
                }, { clause: "CLOSE_BRACKET" }
            );
        }
        if (dateFilter && searchText) {
            filter.filter = [...fl, { clause: "AND" }, dateFilter];
        } else if (searchText) {
            filter.filter = fl;
        } else if (dateFilter) {
            filter.filter = [dateFilter];
        } else {
            filter.filter = [];
        }
    }

    const templateDataSearch = () => {
        preparedFilter();
        dispatch(getagentMetricsReport({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId,
            count: true,
            size: itemParPage,
            offset: (currentPage - 1) * itemParPage,
            orderBy: [{ property: "id", direction: "DESC" }]
        }, (d) => {
            setConversationData(d?.data ?? []);
            setTotalPages(Math.ceil(d?.count / itemParPage));
        }));
    };

    const onSearch = () => {
        if (currentPage === 1) {
            templateDataSearch();
        } else {
            setCurrentPage(1);
        }
    };

    const exportReport = () => {
        preparedFilter();
        dispatch(getagentMetricsReport({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId,
            count: true,
            size: 1000000,
            offset: (currentPage - 1) * itemParPage,
            orderBy: [{ property: "id", direction: "DESC" }]
        }, (d) => {
            const data = d?.data?.map(m => {
                return {
                    waba: `${globalReducer.waba?.teamName ?? ""}  ${globalReducer.waba?.phoneCode ? "+" + globalReducer.waba?.phoneCode : ""} ${globalReducer.waba?.phone ?? ""}`,
                    ID: m.referenceId,
                    CustomerID: m?.contact?.customerId,
                    CustomerName: m.contact?.name,
                    PhoneNumber: m.contact?.whatsappNumber,
                    CustomerEmail: m.contact?.email,
                    TemplateID: m?.templateReferenceId,
                    TemplateName: m?.templateName,
                    Country: m.countryCode,
                    Language: m.languageCode,
                    Status: m.messageStatus,
                    Type: m.messageType,
                    FailedReason: m.remarks,
                    createdAt: m.createdAt,
                    ...m?.detail
                };
            })
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data ?? []);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Message Pushed Report");
            XLSX.writeFile(workbook, 'message-pushed-report.xlsx');
        }));
    };

    const selectDateRange = (dates) => {
        if (dates && dates.length > 0) {
            const [start, end] = dates;
            if (start && end) {
                setDateFilter({
                    property: "createdAt",
                    operator: "RANGE",
                    lowerBound: Date.parse(start.toDate()),
                    upperBound: Date.parse(end.add(1, "hour").toDate()),
                });
            } else {
                setDateFilter(null);
            }
        } else {
            setDateFilter(null);
        }
    };
    return (
        <div className="h-screen">
            <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
                <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <i className="fa-solid fa-chart-column text-theme text-[18px]"></i>
                        <div className="text-[14px] font-medium">
                            {t("Agent Metrics")}
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button type='button' onClick={() => exportReport()} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]">
                            {t('Download as Report')}
                        </button>
                    </div>
                </div>
                <div className='px-5 py-2 bg-gray-100'>
                    <div className='flex justify-between w-[50%]'>
                        <div>
                            <label className='text-[15px]'>
                                {t("Business Hour Only")}
                            </label>
                            <p className='text-[12px] text-gray-600'>
                                {t("See how your agents perform during the business hours")}
                            </p>
                        </div>
                        <div>
                            <label className="inline-flex items-center cursor-pointer">
                                <input checked={isBusinessHour} className="sr-only peer" type="checkbox" onChange={(e) => { setIsBusinessHour(e.target.checked) }} />
                                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                                <span className="ms-3 text-sm font-medium text-gray-900"> </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
                    <div className="grid grid-cols-12 gap-4 items-end">
                        <div className={`flex flex-col col-span-3`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t("Enter Agent Details")}</label>
                            </div>
                            <input
                                type="text"
                                placeholder={t("Enter Agent Details")}
                                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`flex flex-col col-span-4`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Date Range')}</label>
                            </div>
                            <RangePicker defaultValue={[dayjs().startOf("month"), dayjs()]}
                                className={"h-10"}
                                onChange={selectDateRange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                        <div className={`flex flex-col w-full col-span-2`}>
                            <button type="button"
                                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                                onClick={onSearch} >
                                {t("Search")}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-3 gap-4 text-[14px]'>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Avg. First Response Time")}
                            <Tooltip title={t("The average time required by Agents to respond to a Customer from the time a ticket was assigned to an agent")} arrow>
                                <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                            </Tooltip>
                        </label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(0)}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Avg. Resolution Time")}
                            <Tooltip title={t("The average time required by Agents to resolve a Customer's Ticket from the time the Agent started working on the Ticket")} arrow>
                                <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                            </Tooltip>
                        </label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(0)}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Avg. Response Time")}
                            <Tooltip title={t("The average time required by Agents to response a Customer's Ticket from the time the Agent started working on the Ticket")} arrow>
                                <i className="fa-solid mt-2 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                            </Tooltip>
                        </label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(0)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col h-full justify-between grow">
                <div className="px-5 py-2 mt-[19rem] mb-[20px]">
                    {conversationData && conversationData.length > 0 &&
                        <>
                            <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                                <thead className="text-[14px] text-midBlack bg-gray-200">
                                    <tr>
                                        <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                            {t('Sr. No.')}
                                        </th>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            {t('Agent name')}
                                        </th>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            {t('Assigned/Replied/Resolved')}
                                        </th>
                                        <th scope="col" className="font-[500] w-[160px]">
                                            {t('Avg. Resolution Time')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Avg. Response Time')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('Avg. First Response Time')}
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            {t('avg. csat')}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {conversationData.map((p, i) => {
                                        return (
                                            <tr className="bg-white border-b" key={i}>
                                                <td className="py-3 text-[13px] pl-2">
                                                    {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                                                </td>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.agentName ?? "-"}
                                                </td>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.assigned ?? 0}/{p?.replied ?? 0}/{p?.resolved ?? 0}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.contact?.avgResolutionTime ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.contact?.avgResponseTime ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.avgFirstResponseTime ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.avgCsat ?? "-"}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </>}
                </div>
                {totalPages > 1 && conversationData && conversationData.length > 0 && (
                    <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
                        <div className="flex overflow-x-auto">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default AgentMetrics