import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Uploader from "../../components/Uploader";
import {
  getPartnerBank,
  initiateTransaction,
} from "../../store/actions/tenantAction";
import { SHOW_SNACKBAR } from "../../utils/types";
import { fetchIP } from "../../store/actions/globalAction";
import { useTranslation } from "react-i18next";

function WalletRecharge({
  setIsShow,
  selectedWaba,
  paymentFor = "RECHARGE",
  plan,
}) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [recharge, setRecharge] = useState({});
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [filePrefix, setFilePrefix] = useState("");
  const [selectedFileUrl, setSelectedFileUrl] = useState();
  const [selectedFileUrl1, setSelectedFileUrl1] = useState();
  const [ipAddress, setIpAddress] = useState(null);
  const [bank, setBank] = useState({});
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    fetchIP().then((tmp) => setIpAddress(tmp?.ip));
    if (globalReducer.loginInfo?.partnerId) {
      dispatch(getPartnerBank(globalReducer.loginInfo?.partnerId, setBank));
    }
    if (paymentFor === "RENEW") {
      setRecharge({
        ...recharge,
        amount: plan?.planRate,
        currencyCode: plan?.currency,
      });
      setFilePrefix(`plan_renew/${plan?.tenantId}/${plan?.id}`);
    } else {
      setRecharge({ ...recharge, currencyCode: selectedWaba?.currencyCode });
      setFilePrefix(
        `wallet_topup/${selectedWaba?.tenantId}/${selectedWaba?.id}`
      );
    }
  }, []);

  const onRechargeSubmit = (e) => {
    e.preventDefault();
    const clickedButton = e.nativeEvent.submitter;
    if (paymentFor === "RENEW") {
      recharge.tenantPlanId = plan.id;
      recharge.tenantId = plan.tenantId;
      recharge.currencyCode = plan.currency;
    } else {
      recharge.tenantWabaId = selectedWaba.id;
      recharge.tenantId = selectedWaba.tenantId;
      recharge.currencyCode = selectedWaba.currencyCode;
    }
    if (showFileUpload) {
      if (!selectedFileUrl) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "success",
            message: "Please upload Payment Receipt ",
            open: true,
          },
        });
        return;
      } else if (!selectedFileUrl1) {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "success",
            message: "Please upload LPO",
            open: true,
          },
        });
        return;
      }
    }
    recharge.receiptPath = selectedFileUrl + ";" + selectedFileUrl1;
    recharge.detail = { paymentFor: paymentFor, ipAddress: ipAddress };
    switch (clickedButton.value) {
      case "DEBIT_CARD":
      case "PAYMENT_RECEIPT":
      case "CREDIT_CARD":
        dispatch(
          initiateTransaction(recharge, clickedButton.value, (a) => {
            if (clickedButton.value === "PAYMENT_RECEIPT") {
              dispatch({
                type: SHOW_SNACKBAR,
                payload: {
                  type: "success",
                  message: "Receipt submitted successfully",
                  open: true,
                },
              });
            } else {
              window.open(a.detail.paymentUrl, "_blank");
            }
            setIsShow(false);
          })
        );
        break;
      case "UPLOADDOCUMENTS":
        setShowFileUpload(true);
        break;
    }
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="px-6 pb-6 pt-4 bg-white max-h-[550px] overflow-auto">
      <form onSubmit={onRechargeSubmit}>
        <label></label>
        <div className="flex justify-center">
          <div className="flex border border-gray-300 rounded-md text-[14px] w-full">
            <label className="border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-1.5">
              {recharge?.currencyCode}
            </label>
            <input
              type="number"
              value={recharge?.amount}
              disabled={paymentFor === "RENEW"}
              placeholder="Enter Amount"
              data-maxlength={8}
              onInput={OnInput}
              onChange={(e) =>
                setRecharge({
                  ...recharge,
                  amount: e.target.value,
                  totalAmount: e.target.value,
                })
              }
              className="border-0 rounded-md py-1 w-[100%] h-9 text-[13px]"
              required
            />
          </div>
        </div>
        {showFileUpload && (
          <div className="">
            <div className="flex gap-2 pt-2">
              <div>
                <label className="text-gray-600 text-[14px]">
                  {t('Upload Payment Receipt')}
                </label>
                <Uploader
                  selectedFileUrl={selectedFileUrl}
                  onUploaded={setSelectedFileUrl}
                  filePrefix={filePrefix}
                  className="min-w-[290px]"
                />
              </div>
              <div>
                <label className="text-gray-600 text-[14px]">{t('LPO')}</label>
                <Uploader
                  selectedFileUrl={selectedFileUrl1}
                  onUploaded={setSelectedFileUrl1}
                  filePrefix={filePrefix}
                  className="min-w-[290px]"
                />
              </div>
            </div>
            <div className="h-7 bg-gray-200 text-center text-[14px] py-1 mt-3">
              {t('Bank Details')}
            </div>
            <div>
              <table className="border-collapse border bg-white border-slate-200 text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                <tbody className="px-5">
                  <tr>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {t('Bank Name')}
                    </td>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {bank?.bankName}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {t('Account Name')}
                    </td>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {bank?.accountName}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {t('Account Number')}
                    </td>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {bank?.accountNumber}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {t('Branch')}
                    </td>
                    <td className="py-2 px-2 text-[13px] font-normal border border-slate-200">
                      {bank?.branch}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {showFileUpload ? (
          <div className="flex justify-center gap-2">
            <button
              type="submit"
              value={"PAYMENT_RECEIPT"}
              className="justify-center mt-4 items-center flex px-5 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
            >
              {t('Send for Approval')}
            </button>
          </div>
        ) : (
          <div className="flex justify-center gap-3">
            <button
              type="submit"
              value={"DEBIT_CARD"}
              className="justify-center mt-4 items-center flex px-5 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
            >
              {t('Pay Online (Oman Debit Cards)')}
            </button>
            <button
              type="submit"
              value={"CREDIT_CARD"}
              className="justify-center mt-4 items-center flex px-5 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
            >
              {t('Pay Online (Oman Credit Cards)')}
            </button>
            <button
              type="submit"
              value={"UPLOADDOCUMENTS"}
              className="justify-center mt-4 items-center flex px-5 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
            >
              {t('Pay Offline/Upload Documents')}
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default WalletRecharge;
