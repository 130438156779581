import React, { useEffect, useState } from "react";
import FileUploader from "../../components/FileUploader";
import { useTranslation } from "react-i18next";
import LocationPicker from "react-leaflet-location-picker";

function SetTemplateVariableValue({
  setTemplate,
  template,
  carouselTemplate,
  setCarouselTemplate,
  tenantId,
  wabaId,
}) {
  const [mapLocation, setMapLocation] = useState({
    latitude: template?.header?.location?.latitude ?? 21.4292064,
    longitude: template?.header?.location?.longitude ?? 50.852764,
  });

  useEffect(() => {
    setMapLocation({
      latitude: template?.header?.location?.latitude ?? 21.4292064,
      longitude: template?.header?.location?.longitude ?? 50.852764,
    })
  }, [template?.header?.location])

  const { t } = useTranslation();
  const pointMode = {
    banner: true,
    control: {
      values: [[mapLocation.latitude, mapLocation.longitude]],
      onClick: async (point) => {
        setMapLocation({ latitude: point[0], longitude: point[1] });
        template.header["location"] = { latitude: point[0], longitude: point[1] };
        setTemplate({ ...template });
      },
      onRemove: (point) =>
        console.log("I've just been clicked for removal :(", point),
    },
  };
  const circleMode = { banner: false };
  return (
    <div>
      {template?.header?.format && template?.header?.format !== "" && (
        <>
          {template?.header?.format === "TEXT" ? (
            template?.header?.headerVariables?.length > 0 && (
              <>
                <label className="text-[14px]">{t('Header')}</label>
                <div className="border border-gray-300">
                  <div className="grid grid-cols-4 gap-2 p-2 text-center">
                    <label className="text-[14px]">{t('Template Variables')}</label>
                    <label className="text-[14px] col-span-3">
                      {t('Values')}<span className="text-red-500 text-[14px]">*</span>
                    </label>
                    {template?.header?.headerVariables?.map((h, i) => (
                      <>
                        <label className="text-[14px] py-1.5">{`{{${i + 1
                          }}}`}</label>
                        <input
                          type="text"
                          value={h?.text}
                          required
                          className="w-full col-span-3 bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none sm:text-sm"
                          onChange={(e) => {
                            template.header.headerVariables[i].text =
                              e.target.value;
                            setTemplate({ ...template });
                          }}
                        />
                      </>
                    ))}
                  </div>
                </div>
              </>
            )
          ) : template?.header?.format === "LOCATION" ? (<>
            <LocationPicker pointMode={pointMode} circleMode={circleMode} />
          </>) : (
            <>
              <label className="text-[14px]">{t('Header')}</label>
              <div className="border border-gray-300">
                <FileUploader
                  tenantId={tenantId}
                  wabaId={wabaId}
                  selectedFileUrl={template.header?.example?.thumbnailUrl}
                  name={template.header?.example?.filename}
                  isSessionUploder={false}
                  type={template?.header?.format}
                  filePrefix="template_media"
                  onUploaded={(file) => {
                    template.header["example"] = file;
                    setTemplate({ ...template });
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
      {template?.bodyVariables?.length > 0 && (
        <>
          <label className="text-[14px]">{t('Body')}</label>
          <div className="border border-gray-300 grid grid-cols-4 gap-2 p-2 text-center">
            <label className="text-[14px]">{t('Template Variables')}</label>
            <label className="text-[14px] col-span-3">
              Values<span className="text-red-500 text-[14px]">*</span>
            </label>
            {template?.bodyVariables?.map((b, i) => (
              <>
                <label className="text-[14px] py-1.5">{`{{${i + 1}}}`}</label>
                <input
                  type="text"
                  value={b?.text}
                  required
                  className="w-full col-span-3 bg-white text-gray-700 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none sm:text-sm"
                  onChange={(e) => {
                    template.bodyVariables[i].text = e.target.value;
                    setTemplate({ ...template });
                  }}
                />
              </>
            ))}
          </div>
        </>
      )}
      {template?.buttons?.length > 0 &&
        template?.buttons?.some(
          (f) => f.type === "URL" && f.variableValues?.length > 0
        ) && (
          <>
            <label className="text-[14px]">{t('URL Buttons')}</label>
            <div className="border border-gray-300 grid grid-cols-3 gap-2 p-2 text-center">
              <label className="text-[14px] col-span-2">{t('URL')}</label>
              <label className="text-[14px]">
                {t('Value')}<span className="text-red-500 text-[14px]">*</span>
              </label>
              {template?.buttons
                ?.filter((f) => f.type === "URL")
                .map((b, i) =>
                  b.variableValues?.map((v, j) => (
                    <>
                      <label className="text-[14px] py-1.5 col-span-2">{`${b.url}`}</label>
                      <input
                        type="text"
                        value={v}
                        required
                        className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight sm:text-sm"
                        onChange={(e) => {
                          b.variableValues = [e.target.value];
                          setTemplate({ ...template });
                        }}
                      />
                    </>
                  ))
                )}
            </div>
          </>
        )}
      {carouselTemplate?.length > 0 && (
        <div>
          {carouselTemplate.map((c, i) => (
            <>
              {carouselTemplate[i].format && (
                <>
                  <label className="text-[14px] text-semibold">
                    {t('Carousel')} {i + 1}
                  </label>
                  <div>
                    <FileUploader
                      tenantId={tenantId}
                      wabaId={wabaId}
                      selectedFileUrl={
                        carouselTemplate[i].example?.thumbnailUrl
                      }
                      name={carouselTemplate[i].example?.filename}
                      isSessionUploder={false}
                      type={carouselTemplate[i].format}
                      filePrefix="template_media"
                      onUploaded={(file) => {
                        carouselTemplate[i].example = file;
                        setCarouselTemplate([...carouselTemplate]);
                      }}
                    />
                  </div>
                </>
              )}
              {c?.buttons?.some(
                (f) => f.type === "URL" && f.variableValues?.length > 0
              ) && (
                  <>
                    <label className="text-[14px]">{t('URL Buttons')}</label>
                    <div className="border border-gray-300 grid grid-cols-3 gap-2 p-2 text-center">
                      <label className="text-[14px] col-span-2">{t('URL')}</label>
                      <label className="text-[14px]">
                        {t('Value')}<span className="text-red-500 text-[14px]">*</span>
                      </label>
                      {c?.buttons
                        ?.filter((f) => f.type === "URL")
                        .map((b, i) =>
                          b.variableValues?.map((v, j) => (
                            <>
                              <label className="text-[14px] py-1.5 col-span-2">{`${b.url}`}</label>
                              <input
                                type="text"
                                value={v}
                                required
                                className="border border-gray-300 rounded-md py-1.5 text-[14px] w-[100%] pr-10 rtl:pl-10 placeholder-gray-400 focus:outline-none inputEqHeight sm:text-sm"
                                onChange={(e) => {
                                  b.variableValues = [e.target.value];
                                  setCarouselTemplate([...carouselTemplate]);
                                }}
                              />
                            </>
                          ))
                        )}
                    </div>
                  </>
                )}
            </>
          ))}
        </div>
      )}
    </div>
  );
}

export default SetTemplateVariableValue;
