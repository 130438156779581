import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from "@mui/material";

function SelectTimezone({ TimezoneList, onSelectTimezone, selectedZone, required = false, height, disabled = false }) {
    const [selectedValue, setSelectedValue] = useState(null);

    useEffect(() => {
        setSelectedValue({ ...TimezoneList?.find(f => f.name === selectedZone) })
    }, [TimezoneList, selectedZone])

    return (<div>
        <Autocomplete disabled={disabled} value={selectedValue} options={TimezoneList} isOptionEqualToValue={(option, value) => option?.name === value?.name}
            getOptionLabel={(option) => option?.displayText ?? ""}
            onChange={(e, value) => { setSelectedValue(value); onSelectTimezone(value); }}
            sx={{
                '& .MuiAutocomplete-inputRoot': {
                    padding: '0px',
                }
            }}
            slotProps={{
                paper: {
                    sx: {
                        fontSize: "13px", // Change font size of dropdown options
                    }
                },
                listbox: {
                    sx: {
                        fontSize: "13px", // Change font size of listbox items
                    }
                }
            }}
            renderInput={(params) => (<TextField {...params} required={required} placeholder="Select Timezone" className="custom-input"
                sx={{
                    '& .MuiInputBase-root': {
                        padding: "0px",
                        height: height ?? "",
                        '&.Mui-focused fieldset': {
                            borderColor: 'none',
                            border: '0px',
                        },
                        fontSize: "13px",
                    },
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: '#FFFFFF',
                        "& fieldset": {
                            borderColor: '#ccc'
                        },
                        '&:hover fieldset': {
                            borderColor: '#888',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#1976d2',
                        }
                    }
                }}
            />)} />
    </div>)
}

export default SelectTimezone