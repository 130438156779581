import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    categoryView,
    getTenantContacts,
} from "../../store/actions/tenantAction";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";

function ChooseCategory({ setIsShow, onAdd }) {
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const [Contacts, setContacts] = useState([]);
    const [SelectedContacts, setSelectedContacts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [categories, setCategories] = useState([]);
    const globalReducer = useSelector((state) => state.globalReducer);
    const itemParPage = 6;

    useEffect(() => {
        ContactSearch();
    }, [currentPage, selectedCategory]);

    useEffect(() => {
        const fi = [];
        fi.push(
            { clause: "OPEN_BRACKET" },
            {
                property: "permission",
                operator: "ICONTAINS",
                value: "PUBLIC",
            },
            { clause: "OR" },
            { clause: "OPEN_BRACKET" },
            {
                property: "permission",
                operator: "ICONTAINS",
                value: "PRIVATE",
            },
            { clause: "AND" },
            {
                property: "uid",
                operator: "EQ",
                value: globalReducer.loginInfo?.uid,
            },
            { clause: "CLOSE_BRACKET" },
            { clause: "CLOSE_BRACKET" }
        );
        if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
            fi.push({ clause: "AND" }, { clause: "OPEN_BRACKET" }, {
                property: "categoryName",
                operator: "ICONTAINS",
                not: true,
                value: "GENERIC_EXTERNAL",
            }, { clause: "CLOSE_BRACKET" });
        }
        dispatch(
            categoryView(
                {
                    filter: fi,
                    tenantId: globalReducer.loginInfo?.tenantId,
                    criteria: {
                        active: true,
                        tenantId: globalReducer.loginInfo?.tenantId,
                    },
                    count: true,
                    size: 100,
                },
                (d) => {
                    setCategories(d?.data ?? []);
                    setSelectedCategory(Array.isArray(d?.data) ? [d?.data[0]] : [])
                }
            )
        );
    }, []);

    const ContactSearch = () => {
        dispatch(
            getTenantContacts(
                {
                    criteria: {
                        category: selectedCategory?.map(m => m.categoryName),
                        tenantId: globalReducer.loginInfo?.tenantId,
                        active: true,
                    },
                    tenantId: globalReducer.loginInfo?.tenantId,
                    count: true,
                    size: itemParPage,
                    offset: (currentPage - 1) * itemParPage,
                },
                (d) => {
                    setContacts(d?.data);
                    setTotalPages(Math.ceil(d?.count / itemParPage));
                }
            )
        );
    };

    return (
        <div className="height30 flex flex-col justify-between">
            <div className="p-4 flex gap-4">
                <div className="overflow-y-auto max-h-[350px] w-[35%]">
                    {categories && categories.length > 0 && (
                        <div className="">
                            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-auto">
                                <thead className="text-[14px] text-midBlack bg-gray-100">
                                    <tr>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            <div className="flex gap-2">
                                                <div>{t("Category")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500] pl-2">
                                            <div className="flex gap-2">
                                                <div>{t("Contacts")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500] pl-2 pr-2">
                                            <div className="flex gap-2">
                                                <div> {t("Select")}</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map((p, i) => {
                                        return (
                                            <tr className="bg-white border-b" key={i}>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.categoryName ?? "-"}
                                                </td>
                                                <td className={"py-3 text-[13px] font-normal"}>
                                                    {p?.contactCount ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    <input type="checkbox" onChange={(e) => {
                                                        if (e.target.checked) {
                                                            const con = selectedCategory.findIndex(f => f.id === p.id);
                                                            if (con > -1) {
                                                                selectedCategory[con] = p;
                                                                setSelectedCategory([...selectedCategory])
                                                            } else {
                                                                setSelectedCategory([...selectedCategory, p])
                                                            }
                                                        } else {
                                                            setSelectedCategory(selectedCategory.filter(f => f.id !== p.id));
                                                        }
                                                    }} checked={selectedCategory.some(s => s.id === p.id)} className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                <div className="overflow-y-auto max-h-[350px] w-full">
                    {Contacts && Contacts.length > 0 && (
                        <div className="">
                            <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-auto">
                                <thead className="text-[14px] text-midBlack bg-gray-100">
                                    <tr>
                                        <th scope="col" className="font-[500] py-3 pl-2">
                                            <div className="flex gap-2">
                                                <div>{t("Customer Id")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>{t("Name")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t("Email")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t("Phone")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t("Gender")}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500] pr-2">
                                            <div className="flex gap-2">
                                                <div> {t("Select")}</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Contacts.map((p, i) => {
                                        return (
                                            <tr className="bg-white border-b" key={i}>
                                                <td className={"py-3 text-[13px] pl-2 font-normal"}>
                                                    {p?.customerId ?? "-"}
                                                </td>
                                                <td className={"py-3 text-[13px] font-normal"}>
                                                    {p?.name ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.email ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {`+${p?.whatsappNumber}`}
                                                </td>
                                                <td className={"py-3 text-[13px] font-normal"}>
                                                    {p?.gender ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    <input type="checkbox" onChange={(e) => {
                                                        if (e.target.value) {
                                                            const con = SelectedContacts.findIndex(f => f.id === p.id);
                                                            if (con > -1) {
                                                                SelectedContacts[con] = p;
                                                                setSelectedContacts([...SelectedContacts])
                                                            } else {
                                                                setSelectedContacts([...SelectedContacts, p])
                                                            }
                                                        } else {
                                                            setSelectedContacts(SelectedContacts.filter(f => f.id !== p.id))
                                                        }
                                                    }} checked={SelectedContacts.some(s => s.id === p.id)} className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex items-center px-4 bg-white border-t-[1px] border-grayish relative">
                <div className="flex justify-between items-center gap-4 w-full">
                    {totalPages > 1 && (
                        <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                            <Pagination
                                page={currentPage}
                                count={totalPages}
                                onChange={(event, page) => setCurrentPage(page)}
                                shape="rounded"
                                color="secondary"
                            />
                        </div>
                    )}
                    <button type="button"
                        className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer"
                        onClick={() => {
                            onAdd(SelectedContacts);
                            setIsShow(false);
                        }}>
                        {t("Select")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ChooseCategory;
