import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignToTenantRateCard,
  getMessageType,
  rateCardQuery,
} from "../../store/actions/partnerAction";
import {
  getTenantRateCardDetails,
  updateTenantWaba,
  upsertTenantRateCardDetails,
} from "../../store/actions/tenantAction";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";

function MapTenantRateCard({
  setIsShow,
  onAdd,
  tenant = {},
  waba = {},
  isEdit = false,
}) {
  const { t } = useTranslation()
  let dispatch = useDispatch();
  const [rateCards, setRateCards] = useState([]);
  const [updateWaba, setUpdateWaba] = useState({});
  const [selectedRateCard, setSelectedRateCard] = useState({});
  const [selectedRateCardId, setSelectedRateCardId] = useState();
  const [tenantRateCards, setTenantRateCards] = useState([]);
  const [messageTypes, setMessageTypes] = useState([]);
  const [rateCardChanges, setRateCardChanges] = useState([]);
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    setUpdateWaba({
      id: waba?.id,
      tenantId: tenant?.id,
      accountType: waba?.accountType,
      walletLimit: waba?.walletLimit,
      currencyCode: waba?.currencyCode,
    });
    dispatch(getMessageType(setMessageTypes));
    dispatch(
      rateCardQuery({
        criteria: { active: true },
        size: 1000,
        offset: 0
      }, (a) => {
        setRateCards(a.data);
      })
    );
    refresh();
  }, []);

  useEffect(() => {
    if (selectedRateCardId && rateCards?.length > 0) {
      setSelectedRateCard(rateCards.find((f) => f.id == selectedRateCardId));
    }
  }, [selectedRateCardId, rateCards]);

  const refresh = () => {
    dispatch(getTenantRateCardDetails({ tenantId: tenant.id, criteria: { tenantWabaId: waba.id } }, a => {
      if (a && a.length > 0) {
        setSelectedRateCardId(a[0].rateCardId);
        const rates = [];
        a?.map(m => {
          const country = globalReducer.country.find(fi => fi.code === m.countryCode);
          if (country) {
            rates.push({ ...m, countryName: country?.name, currencyCode: country?.currencyCode });
          }
        })
        setTenantRateCards(rates);
      }
    }));
  }

  const RateReplicate = (rate) => {
    tenantRateCards?.map((d, c) => {
      if (d.currency !== "NATIVE") {
        messageTypes.map((m, i) => {
          if (!d.rates) {
            d.rates = {};
          }
          onRateCardChanges(d, m.messageType, rate?.rates?.[m.messageType]);
        });
      }
    });
    setTenantRateCards([...tenantRateCards]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      assignToTenantRateCard(
        {
          tenantId: tenant.id,
          tenantWabaId: waba.id,
          rateCard: selectedRateCard,
        },
        (a) => {
          setTenantRateCards([]);
          refresh();
          onAdd(a);
        }
      )
    );
  };

  const onSave = (e) => {
    e.preventDefault();
    if (rateCardChanges.length > 0) {
      dispatch(
        upsertTenantRateCardDetails(rateCardChanges, (a) => {
          dispatch(
            updateTenantWaba(updateWaba, (b) => {
              setIsShow(false);
              onAdd();
            })
          );
        })
      );
    } else {
      dispatch(
        updateTenantWaba(updateWaba, (b) => {
          setIsShow(false);
          onAdd();
        })
      );
    }
  };

  const onRateCardChanges = (c, messageType, rate) => {
    const fCard = rateCardChanges?.findIndex(
      (m) =>
        c.tenantId === m.tenantId &&
        c.tenantWabaId === m.tenantWabaId &&
        c.countryCode === m.countryCode &&
        c.messageType === messageType
    );
    console.log("onRateCardChanges", c, messageType, rate)
    if (fCard != -1) {
      rateCardChanges[fCard].messageRate = rate;
    } else {
      rateCardChanges.push({ ...c, messageRate: rate, id: null, messageType: messageType });
    }
    setRateCardChanges([...rateCardChanges]);
    const tc = tenantRateCards.findIndex(f => f.id === c.id);
    if (tc != -1) {
      tenantRateCards[tc].rates = { ...tenantRateCards[tc].rates, [messageType]: rate };
    }
    setTenantRateCards([...tenantRateCards]);
  };
  const onKeyDownForRate = (e) => {
    const newValue = e.target.value + e.key;
    const decimalRegex = /^\d*\.?\d*$/;
    if (e.key?.length === 1 && !decimalRegex.test(newValue)) {
      e.preventDefault();
    }
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form className="height30 flex flex-col justify-between pb-3" onSubmit={onSave}>
      <div className="h-dvh overflow-y-auto p-4">
        <div className="flex w-full">
          <div>
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Account Type')}</label>
            </div>
            <select required
              className="border border-gray-300 rounded-md min-w-[200px] py-1 h-9 px-4 cursor-pointer text-[14px]"
              value={updateWaba?.accountType}
              onChange={(e) => {
                setUpdateWaba({ ...updateWaba, accountType: e.target.value });
              }}
            >
              <option value="PREPAID">{t('Prepaid')}</option>
              <option value="POSTPAID">{t('Postpaid')}</option>
            </select>
          </div>
          {updateWaba?.accountType === "POSTPAID" && (
            <>
              <div className="ml-2">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Wallet Limit')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <div className="flex border border-gray-300 rounded-md text-[14px]">
                  <label className="border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-1.5">
                    {updateWaba?.currencyCode}
                  </label>
                  <input
                    type="number" required
                    placeholder={t("Enter Wallet Limit")}
                    value={updateWaba?.walletLimit}
                    data-maxlength={8}
                    onInput={OnInput}
                    onChange={(e) =>
                      setUpdateWaba({
                        ...updateWaba,
                        walletLimit: e.target.value,
                      })
                    }
                    className="border-0 rounded-md py-1 w-[100%] h-9 text-[13px]"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="grid grid-cols-12 gap-3 w-full items-end mt-3">
          <div className="col-span-10">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Rate Card')}</label>
            </div>
            <TypeableSelect height={"40px"} list={rateCards} selected={{ id: selectedRateCardId }} labelField="name" valueField="id" required={true} placeholder={t("Select Rate Card...")} onSelect={(s) => {
              setSelectedRateCardId(s?.id);
            }} />
          </div>
          <div className="col-span-2">
            <button
              type="button"
              className="justify-center w-full items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
              onClick={handleSubmit}
            >
              {t('Assign')}
            </button>
          </div>
        </div>
        {tenantRateCards?.length > 0 && (
          <>
            <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] my-2">
              {t('Domestic Rate')}
            </div>
            <table className="text-sm text-left rtl:text-right text-midBlack">
              <thead className="text-[14px] text-midBlack">
                <tr className="h-9 bg-gray-100">
                  <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                    <div>{t('Countries')}</div>
                  </th>
                  {messageTypes.map((m, i) => (
                    <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                      <div>{m.messageType}</div>
                    </th>
                  ))}
                </tr>
                {tenantRateCards
                  ?.filter((f) => f.currency === "NATIVE")
                  .map((f, r) => (
                    <tr key={r}>
                      <td className="py-2 font-normal text-[13px] pr-2">
                        {f?.countryName ?? "Domestic"} ({f?.currencyCode})
                      </td>
                      {messageTypes.map((m, i) => (
                        <td
                          key={i}
                          className="py-2 font-normal text-[13px] pr-1"
                        >
                          <input required
                            data-maxlength={8}
                            onInput={OnInput}
                            step="any"
                            type="text"
                            className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                            value={f?.rates?.[m.messageType]}
                            onChange={(e) => {
                              const decimalRegex = /^\d*\.?\d*$/;
                              if (decimalRegex.test(e.target.value)) {
                                onRateCardChanges(f, m.messageType, e.target.value);
                              }
                            }}
                            onKeyDown={onKeyDownForRate}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                <tr>
                  <td colSpan={messageTypes.length + 1}>
                    <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-1 mb-1 flex justify-between">
                      <label className="mt-1">
                        {t('International')} ({tenantRateCards[0]?.currency})
                      </label>
                      <input
                        type="text"
                        placeholder={t("Search Countries")}
                        className="border border-gray-300 rounded-md h-9 py-1"
                        onChange={(e) => {
                          const cn = e.target.value?.toLowerCase();
                          setTenantRateCards(
                            tenantRateCards.map((f) => {
                              if (
                                f.countryCode?.toLowerCase()?.includes(cn) ||
                                f.countryName?.toLowerCase()?.includes(cn)
                              ) {
                                return { ...f, isHide: false };
                              } else return { ...f, isHide: true };
                            })
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {tenantRateCards?.map((d, c) => (
                  <tr
                    className={
                      "border " +
                      (d.isHide || d.currency === "NATIVE" ? "hidden" : "")
                    }
                    key={c}
                  >
                    <td className="py-1 font-normal text-[13px] pl-2 pr-2">
                      {d?.countryName}
                      <p>
                        <a className="text-[10px] mt-[-2px] whitespace-nowrap transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                          onClick={() => RateReplicate(d)}>
                          {t("Replicate for all country")}
                        </a>
                      </p>
                    </td>
                    {messageTypes.map((m, i) => (
                      <td key={i} className="py-1 font-normal text-[13px] pr-1">
                        <input required
                          data-maxlength={8}
                          onInput={OnInput}
                          step="any"
                          type="text"
                          className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                          value={d?.rates?.[m.messageType]}
                          onChange={(e) => {
                            const decimalRegex = /^\d*\.?\d*$/;
                            if (decimalRegex.test(e.target.value)) {
                              onRateCardChanges(d, m.messageType, e.target.value)
                            }
                          }}
                          onKeyDown={onKeyDownForRate}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="submit"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
            {t('Save')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default MapTenantRateCard;
