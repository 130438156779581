import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getFile, getFileMediaId } from '../store/actions/globalAction';

function TemplateImagePreview({ url, className, mediaId, waba }) {
    let dispatch = useDispatch();
    const [previewfile, setPreviewfile] = useState();

    useEffect(() => {
        if (mediaId && mediaId != "") {
            getFileMediaId(waba?.tenantId, waba?.id, mediaId).then(md => { setPreviewfile(md) });
        }
        else if (url && url != "") {
            getFile(url).then(ul => { setPreviewfile(ul) });
        } else {
            setPreviewfile(null);
        }
    }, [url])

    return (
        <div className='h-[120px]'>
            {previewfile ?
                <img className={"h-full w-full object-contain rounded-md " + (className ?? "")} src={previewfile} /> :
                <div className='flex justify-center pt-10'><i className="fa-solid fa-image text-[24px] text-gray-400"></i></div>}
        </div>
    )
}

export default TemplateImagePreview