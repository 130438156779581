import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PartnerSidebar } from "../../utils/PartnerSidebar";
import { usePartnerSidebar } from "../../utils/PartnerSidebar";
import Dashboard from "../../pages/partner/Dashboard";
import Plans from "../../pages/partner/Plans";
import ManagePatner from "../../pages/globalAdmin/ManagePatner";
import ManageUsers from "../../pages/globalAdmin/ManageUsers";
import { getInfo, PARTNER_DETAILS } from "../../utils/types";
import { getPartnerDetails } from "../../store/actions/partnerAction";
import { FiZap, FiChevronDown, FiChevronUp } from "react-icons/fi";
import ROUTES from '../../utils/routesConstants';
import CompanyManagement from '../../pages/partner/CompanyManagement';
import ConversationRateCard from '../../pages/partner/ConversationRateCard';
import Country from '../../pages/globalAdmin/Country';
import MessageType from '../../pages/globalAdmin/MessageType';
import Bsp from '../../pages/globalAdmin/Bsp';
import ManageWaba from '../../pages/partner/ManageWaba';
import TenantTransactionHistory from '../../pages/partner/TenantTransactionHistory';
import TenantWalletTransfers from '../../pages/partner/TenantWalletTransfers';
import TenantWalletTopup from '../../pages/partner/TenantWalletTopup';
import BankDetails from '../../pages/partner/BankDetails';
import UserSetting from '../../pages/globalAdmin/UserSetting';
import Setting from '../../pages/globalAdmin/Setting';
import ManageNotifications from '../../pages/partner/ManageNotifications';
import RoleManagement from '../../pages/partner/RoleManagement';
import LoginHistory from '../../pages/LoginHistory';
import { Tooltip } from "flowbite-react";

function PartnerLeftPanel({ category, tabName, subtabName }) {
  const { SidebarTitle } = usePartnerSidebar();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarComp, setSidebarComp] = useState(null);
  const [sidebarData, setSidebarData] = useState([]);
  const [partnerDetail, setPartnerDetail] = useState();
  const globalReducer = useSelector((state) => state.globalReducer);
  const [partnerInfo, setPartnerInfo] = useState(getInfo());

  const componentMap = {
    "dashboard": { component: <Dashboard />, props: null, roles: ["SUPER_ADMIN"] },
    "login-history": { component: <LoginHistory />, props: null, roles: ["SUPER_ADMIN"] },
    "plans": { component: <Plans />, props: null, roles: ["SUPER_ADMIN"] },
    "manage-partner": { component: <ManagePatner />, props: null, roles: ["GLOBAL_ADMIN"] },
    "manage-users": { component: <ManageUsers />, props: null, roles: ["GLOBAL_ADMIN", "SUPER_ADMIN"] },
    "company-management": { component: <CompanyManagement />, props: null, roles: ["SUPER_ADMIN"] },
    "conversation-rate-card": { component: <ConversationRateCard />, props: null, roles: ["GLOBAL_ADMIN", "SUPER_ADMIN"] },
    "country": { component: <Country />, props: null, roles: ["GLOBAL_ADMIN"] },
    "message-type": { component: <MessageType />, props: null, roles: ["GLOBAL_ADMIN"] },
    "bsp": { component: <Bsp />, props: null, roles: ["GLOBAL_ADMIN"] },
    "manage-waba": { component: <ManageWaba />, props: null, roles: ["SUPER_ADMIN"] },
    "tenant-transaction-history": { component: <TenantTransactionHistory />, props: null, roles: ["SUPER_ADMIN"] },
    "tenant-wallet-topup": { component: <TenantWalletTopup />, props: null, roles: ["SUPER_ADMIN"] },
    "tenant-wallet-transfers": { component: <TenantWalletTransfers />, props: null, roles: ["SUPER_ADMIN"] },
    "bank-betails": { component: <BankDetails />, props: null, roles: ["SUPER_ADMIN"] },
    "user-setting": { component: <UserSetting />, props: null, roles: ["SUPER_ADMIN"], permission: "SETTINGS" },
    "setting": { component: <Setting />, props: null, roles: ["SUPER_ADMIN"], permission: "SETTINGS" },
    "manage-notifications": { component: <ManageNotifications />, props: null, roles: ["SUPER_ADMIN"] },
    "role-management": { component: <RoleManagement />, props: null, roles: ["SUPER_ADMIN"] },
  };

  useEffect(() => {
    setSidebarData(sidebarData);
  }, [sidebarData]);

  useEffect(() => {
    //If there is subTabName then use the subTab key
    let tab;
    if (subtabName) tab = componentMap[subtabName];
    else tab = componentMap[tabName];
    if (!tabName && !subtabName) {
      if (partnerInfo?.roleDetails?.some(s => s.name === category)) {
        tab = componentMap["manage-partner"];
      } else {
        tab = componentMap["dashboard"];
      }
    } else if (!tab || tab.roles?.filter(f => partnerInfo?.roleDetails?.some(s => s.name === f)).length == 0) {
      navigate(ROUTES.UN_AUTH);
    }
    setSidebarComp(tab);
  }, [tabName, subtabName]);

  useEffect(() => {
    if (partnerInfo) {
      const finalSideBarData = SidebarTitle?.filter(
        (item) => partnerInfo?.roleDetails?.some(s => s.name === item.role)
      );
      setSidebarData(finalSideBarData ?? []);
      dispatch(getPartnerDetails({ criteria: { id: partnerInfo.partnerId } }, (d) => {
        setPartnerDetail(d);
      }));
    }
  }, []);

  useEffect(() => {
    if (globalReducer.timezone?.length > 0 && partnerDetail) {
      dispatch({ type: PARTNER_DETAILS, payload: { ...partnerDetail, timezone: globalReducer.timezone.find(f => f.countryCode === partnerDetail?.countryCode)?.name } });
    }
  }, [globalReducer.timezone, partnerDetail]);

  const openSubtab = (mainIndex, tabIndex) => {
    setSidebarData((prevSidebarData) => {
      const newSidebarData = [...prevSidebarData];
      newSidebarData[mainIndex].tabName.map((tab1, i) => {
        if (tabIndex !== i)
          tab1.active = false;
      });
      const tab = newSidebarData[mainIndex].tabName[tabIndex];
      tab.active = !tab.active;
      return newSidebarData;
    });
  };

  const setOpenMainTab = (index) => {
    setSidebarData((prevSidebarData) => {
      const newSidebarData = [...prevSidebarData];
      const tab = newSidebarData[index];
      tab.active = !tab.active;
      return newSidebarData;
    });
  };

  return (
    <div>
      {showSidebar ? <div className="md:w-[16rem] w-full fixed z-[45] top-[30px] left-0 bottom-0  bg-[#f7f8fc] border-r-[1px] border-[#e9ebf0]">
        <div className="py-[8.3px] border-b border-[#e9ebf0]">
          <div className="flex px-4 gap-2 items-center">
            <img className="w-[37.5px] h-[37.5px] rounded-full p-1 box-border bg-white"
              src={(partnerInfo?.partnerId.includes("GLOBALADMIN") ? "/favicon.png" : partnerDetail?.logo) ?? ""}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/images/default.png`;
              }}
            />
            <div className={`text-[14px] font-medium md:w-[100%] text-black`}>
              {partnerDetail?.name ?? partnerInfo.name}
              <p className="text-[10px] text-midBlack">
                {partnerInfo?.partnerId.includes("GLOBALADMIN") ? "" : partnerInfo?.partnerId}
              </p>
            </div>
            <div>
              <i onClick={() => setShowSidebar(!showSidebar)} className="fa-solid fa-sliders text-gray-500 text-[18px] hover:text-theme cursor-pointer"></i>
            </div>
          </div>
        </div>
        <div className="overflow-auto height55 aside">
          {sidebarData.map((item, i) => {
            return (
              <>
                <div className={`flex justify-between rounded-md ${item?.active ? "" : ""}`}
                  onClick={() => setOpenMainTab(i)}
                  key={i}>
                  <div className={`text-[13px] font-medium py-2 ml-5 ${item?.active ? "text-theme" : "text-[#656f7d]"} `}>
                    {item?.value}
                  </div>
                </div>

                <ul key={i} className="flex flex-col space-y-1 pt-0 w-full pl-1 pr-2">
                  {item?.tabName.map((tab, j) => {
                    return (
                      <>
                        {tab?.subtab?.length > 0 ? (
                          <li key={j} className="w-full px-1">
                            <div
                              className={
                                "text-black h-[34px] px-2 justify-between relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] hover:text-theme border-l-4 border-transparent rounded pr-2 font-[400] cursor-pointer"
                              }
                              onClick={() => openSubtab(i, j)}
                            >
                              <div className="flex items-center">
                                <label className={"inline-flex justify-center items-center " + (tab?.subtab?.some(s => s.link === location.pathname) ? "text-theme" : "")}>
                                  {tab?.icon}
                                </label>
                                <label className={"text-[14px] " + (tab?.subtab?.some(s => s.link === location.pathname) ? "text-theme" : "")}>
                                  {tab?.title}
                                </label>
                              </div>
                              {tab?.active ? (
                                <FiChevronUp className='text-theme' />
                              ) : (
                                <FiChevronDown className='text-theme' />
                              )}
                            </div>
                          </li>
                        ) : (
                          <li key={j} className="w-full px-1">
                            <Link
                              to={tab?.link}
                              className={
                                (location.pathname === tab?.link
                                  ? "text-theme bg-[#FFFFFF]"
                                  : "text-black") +
                                " h-[34px] px-2 relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400] whitespace-nowrap"
                              }
                            >
                              {tab?.icon}
                              <label className="text-[14px] whitespace-nowrap">
                                {tab?.title}
                              </label>
                            </Link>
                          </li>
                        )}
                        {tab?.subtab?.length > 0 && tab?.active ? (
                          <ul className="top-[44px] w-full text-sm pl-2 pr-1">
                            {tab?.subtab?.map((subtab, i) => {
                              return (
                                <li key={i}>
                                  <Link
                                    to={subtab?.link}
                                    className={
                                      (location.pathname === subtab?.link
                                        ? "text-theme font-medium bg-[#FFFFFF]"
                                        : "text-black") +
                                      " py-2 my-1 px-4 text-wrap relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400]"
                                    }
                                  >
                                    <label className="inline-flex justify-center items-center ml-3">
                                      {subtab?.icon ?? <FiZap className="mr-2" />}
                                    </label>
                                    <label className="ml-2 text-[13px] whitespace-nowrap">
                                      {subtab?.title}
                                    </label>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </ul>
              </>
            );
          })}
        </div>
      </div> :
        <div className="md:w-fit fixed z-[45] top-[30px] left-0 bottom-0  bg-[#f7f8fc] border-r-[1px] border-[#e9ebf0]">
          <div className="py-[8.3px] border-b border-[#e9ebf0]">
            <div className="flex px-4 gap-2 items-center">
              <div>
                <i
                  onClick={() => setShowSidebar(!showSidebar)}
                  className="fa-solid fa-sliders text-gray-500 text-[18px] hover:text-theme cursor-pointer"
                ></i>
              </div>
            </div>
          </div>
          <div className="overflow-auto height55 aside">
            {sidebarData.map((item, i) => {
              return (
                <div className="aa" key={i}>
                  <div
                    className={`flex justify-between rounded-md ${item?.active ? "" : ""
                      }`}
                    onClick={() => setOpenMainTab(i)}
                  >
                    <div
                      className={`text-[13px] font-medium py-2 ml-5 ${item?.active ? "text-theme" : "text-[#656f7d]"
                        } `}
                    >
                      {item?.value}
                    </div>
                  </div>

                  <ul className="flex flex-col space-y-1 pt-0 w-full pl-1 pr-2">
                    {item?.tabName.map((tab, j) => {
                      return (
                        <>
                          {tab?.subtab?.length > 0 ? (
                            <Tooltip className="whitespace-nowrap" content={tab?.title} style="light" placement="right">
                              <li key={j} className="w-full px-1">
                                <div
                                  className={
                                    "text-black h-[34px] px-2 justify-between relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] hover:text-theme border-l-4 border-transparent rounded pr-2 font-[400] cursor-pointer"
                                  }
                                  onClick={() => openSubtab(i, j)}
                                >
                                  <div className="flex items-center">
                                    <label
                                      className={
                                        "inline-flex justify-center items-center " +
                                        (tab?.subtab?.some(
                                          (s) => s.link === location.pathname
                                        )
                                          ? "text-theme"
                                          : "")
                                      }
                                    >
                                      {tab?.icon}
                                    </label>
                                    {/* <label
                                            className={
                                              "text-[14px] " +
                                              (tab?.subtab?.some(
                                                (s) => s.link === location.pathname
                                              )
                                                ? "text-theme"
                                                : "")
                                            }
                                          >
                                            {tab?.title}
                                          </label> */}
                                  </div>

                                  {/* {tab?.active ? (
                                          <FiChevronUp className="text-theme" />
                                        ) : (
                                          <FiChevronDown className="text-theme" />
                                        )} */}
                                </div>
                              </li>
                            </Tooltip>
                          ) : (
                            <Tooltip className="whitespace-nowrap" content={tab?.title} style="light" placement="right">
                              <li key={j} className="w-full px-1">
                                <Link
                                  to={tab?.link}
                                  className={
                                    (location.pathname === tab?.link
                                      ? "text-theme bg-[#FFFFFF]"
                                      : "text-black") +
                                    " h-[34px] px-2 relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400] whitespace-nowrap"
                                  }
                                >
                                  {tab?.icon}
                                  {/* <label className="text-[14px]">
                                          {tab?.title}
                                        </label> */}
                                </Link>
                              </li>
                            </Tooltip>
                          )}
                          {tab?.subtab?.length > 0 && tab?.active ? (
                            <ul className="top-[44px] w-full text-sm pl-2 pr-1">
                              {tab?.subtab?.map((subtab, i) => {
                                return (
                                  <Tooltip className="whitespace-nowrap"
                                    content={subtab?.title}
                                    style="light" placement="right"
                                  >
                                    <li key={i}>
                                      <Link
                                        to={subtab?.link}
                                        className={
                                          (location.pathname === subtab?.link
                                            ? "text-theme font-medium bg-[#FFFFFF]"
                                            : "text-black") +
                                          " py-2 my-1 px-4 text-wrap relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400]"
                                        }
                                      >
                                        <label className="inline-flex justify-center items-center ml-3">
                                          {subtab?.icon ?? (
                                            <FiZap className="mr-2" />
                                          )}
                                        </label>
                                        {/* <label className="ml-2 text-[13px] whitespace-nowrap">
                                                {subtab?.title}
                                              </label> */}
                                      </Link>
                                    </li>
                                  </Tooltip>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      }
      <div className={`${showSidebar ? "md:pl-[16rem]" : ""} max-md:hidden flex flex-col h-screen w-full overflow-y-auto fixed right-0 bg-[#F3F4F6]`}>
        {sidebarComp &&
          React.cloneElement(sidebarComp?.component, { ...sidebarComp?.props, showSidebar: showSidebar })}
      </div>
    </div>
  )
}

export default PartnerLeftPanel