import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { getAllMessageCount, getAllMessageCountByDates, getConversationsCount, getConversationsCountByDates } from "../../store/actions/tenantAction";
import { getMessageType } from "../../store/actions/partnerAction";
import Tooltip1 from "@mui/material/Tooltip";
import * as XLSX from "xlsx";
import { getMonthsBetween, toTitleCase } from "../../utils/helper";
const { RangePicker } = DatePicker;

function AllConversationsInsights({ showSidebar }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [messageTypes, setMessageTypes] = useState([]);
    const [isMessage, setIsMessage] = useState(false);
    const [conversationsCount, setConversationsCount] = useState();
    const [paidGraph, setPaidGraph] = useState([]);
    const [allGraph, setAllGraph] = useState([]);
    const [freeGraph, setFreeGraph] = useState([]);
    const [messageGraph, setMessageGraph] = useState([]);
    const [messageCount, setMessageCount] = useState();
    const globalReducer = useSelector((state) => state.globalReducer);
    const [filter, setFilter] = useState({
        criteria: { tenantId: globalReducer.loginInfo?.tenantId, fromTimestamp: Date.parse(dayjs().startOf("month").toDate()), toTimestamp: Date.parse(dayjs().add(1, "hour").toDate()), countryCode: "" },
    });
    const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [months, setMonths] = useState([{ id: Date.parse(dayjs().startOf("month").toDate()), name: moment().tz(wabaTimezone).format("MMMM") }]);

    useEffect(() => {
        dispatch(getMessageType((mt) => {
            mt.map(a => {
                switch (a.messageType) {
                    case "SERVICE":
                        a.order = 5;
                        a.color = "#30835F";
                        break;
                    case "MARKETING":
                        a.order = 1;
                        a.color = "#33CDCD";
                        break;
                    case "AUTHENTICATION":
                        a.order = 3;
                        a.color = "#D35C43";
                        break;
                    case "UTILITY":
                        a.order = 2;
                        a.color = "#AC2E82";
                        break;
                    case "AUTHENTICATION_INTERNATIONAL":
                        a.order = 4;
                        a.color = "#7A553C";
                        break;
                }
                return a;
            })
            setMessageTypes(mt.sort((a, b) => a.order - b.order));
        }));
    }, []);

    useEffect(() => {
        onSearch();
        onMessageCount();
    }, [filter]);

    const onSearch = () => {
        dispatch(getConversationsCount({
            ...filter,
            criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
            tenantId: globalReducer.loginInfo?.tenantId
        }, (d) => {
            setMessageTypes((types) => types?.map(m => {
                const Paid = d.find(f => f.messageType === m.messageType);
                if (Paid) {
                    return { ...m, totalConversation: Paid.totalConversation };
                } else {
                    return { ...m, totalConversation: 0 };
                }
            }))
            setConversationsCount(d);
        }));
        const dateRange = [];
        for (let index = 0; index < months.length; index++) {
            if (months.length === index + 1) {
                dateRange.push({ fromTimestamp: months[index].id, toTimestamp: moment().utc().valueOf() });
            } else {
                dateRange.push({ fromTimestamp: months[index].id, toTimestamp: months[index + 1].id });
            }
        }

        dispatch(getConversationsCountByDates({
            tenantWabaId: globalReducer.waba?.id,
            tenantId: globalReducer.loginInfo?.tenantId,
            countryCode: filter?.criteria?.countryCode,
            dates: dateRange
        }, (d) => {
            //const pGraph = [];
            const fGraph = [];
            const aGraph = [];
            months?.forEach((m) => {
                const monthData = d[m.id];
                if (Array.isArray(monthData)) {
                    let mconversations = 0;
                    let uconversations = 0;
                    let aconversations = 0;
                    let iconversations = 0;
                    let sconversations = 0;
                    let fconversations = 0;
                    let totalConversations = 0;

                    monthData.forEach((item) => {
                        const messageType = item.messageType?.toLowerCase();
                        const conversationCount = item.totalConversation ?? 0;
                        totalConversations += conversationCount;

                        switch (messageType) {
                            case 'marketing':
                                mconversations += conversationCount;
                                break;
                            case 'utility':
                                uconversations += conversationCount;
                                break;
                            case 'authentication':
                                aconversations += conversationCount;
                                break;
                            case 'authentication_international':
                                iconversations += conversationCount;
                                break;
                            case 'service':
                                sconversations += conversationCount;
                                break;
                            case 'live_chat':
                                fconversations += conversationCount;
                                break;
                            default:
                                break;
                        }
                    });

                    fGraph.push({ ...m, conversations: fconversations });
                    aGraph.push({
                        ...m,
                        mconversations,
                        uconversations,
                        aconversations,
                        iconversations,
                        sconversations,
                        conversations: totalConversations,
                    });
                } else {
                    fGraph.push({
                        ...m, conversations: 0, mconversations: 0,
                        uconversations: 0,
                        aconversations: 0,
                        iconversations: 0,
                        sconversations: 0,
                    });
                    aGraph.push({ ...m, conversations: 0 });
                }
            });
            //setPaidGraph(pGraph);
            setFreeGraph(fGraph);
            setAllGraph(aGraph);
        }));
    }

    const onMessageCount = () => {
        const criteria = { tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId };
        if (filter?.criteria?.countryCode) {
            criteria.countryCode = filter?.criteria?.countryCode;
        }
        dispatch(getAllMessageCount({
            filter: [{
                property: "createdAt",
                operator: "RANGE",
                lowerBound: filter?.criteria?.fromTimestamp,
                upperBound: filter?.criteria?.toTimestamp
            }],
            criteria: criteria,
            tenantId: globalReducer.loginInfo?.tenantId
        }, (d) => {
            setMessageCount(d);
        }));

        const dateRange = [];
        for (let index = 0; index < months.length; index++) {
            if (months.length === index + 1) {
                dateRange.push({ fromTimestamp: months[index].id, toTimestamp: moment().utc().valueOf() });
            } else {
                dateRange.push({ fromTimestamp: months[index].id, toTimestamp: months[index + 1].id });
            }
        }
        dispatch(getAllMessageCountByDates({
            ...criteria,
            dates: dateRange
        }, (d) => {
            const mGraph = [];
            months?.map(m => {
                if (d[m.id]) {
                    mGraph.push({ ...m, sent: d[m.id]?.sent, delivered: d[m.id]?.delivered, received: d[m.id]?.received });
                } else {
                    mGraph.push({ ...m, sent: 0, delivered: 0, received: 0 });
                }
            })
            setMessageGraph(mGraph);
        }));
    }

    const exportReport = () => {
        const data = [];
        if (!isMessage) {
            data.push({
                key: "All Conversations",
                value: conversationsCount?.reduce((acc, item) => acc + item.totalConversation, 0)
            });
            messageTypes?.map((m, i) => {
                data.push({ key: toTitleCase(m.messageType), value: m?.totalConversation })
            });
            data.push({ key: "", value: "" })
            data.push({
                key: "Paid Conversations",
                value: conversationsCount?.filter(f => f.messageType?.toLowerCase() !== "live_chat")?.reduce((acc, item) => acc + item.totalConversation, 0)
            });
            messageTypes?.map((m, i) => {
                data.push({ key: toTitleCase(m.messageType), value: m?.totalConversation })
            });
            data.push({ key: "", value: "" })
            data.push({
                key: "Free Conversations",
                value: conversationsCount?.filter(f => f.messageType?.toLowerCase() === "live_chat")?.reduce((acc, item) => acc + item.totalConversation, 0)
            }, {
                key: "Free Tier:",
                value: conversationsCount?.filter(f => f.messageType?.toLowerCase() === "live_chat")?.reduce((acc, item) => acc + item.totalConversation, 0)
            });
        } else {
            data.push({
                key: "Messages sent",
                value: messageCount?.sent
            }, {
                key: "Messages delivered",
                value: messageCount?.delivered
            }, {
                key: "Messages received",
                value: messageCount?.received
            });
        }
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, "All Conversation");
        XLSX.writeFile(workbook, isMessage ? "all_message.xlsx" : 'all_Conversation.xlsx');
    }

    const selectDateRange = (dates) => {
        if (dates && dates.length > 0) {
            const [start, end] = dates;
            if (start && end) {
                setMonths(getMonthsBetween(start.toDate(), end.toDate()).map(m => {
                    return { id: moment(m).tz(wabaTimezone).valueOf(), name: moment(m).tz(wabaTimezone).format("MMMM") }
                }));
                setFilter({ criteria: { ...filter?.criteria, fromTimestamp: Date.parse(start.toDate()), toTimestamp: Date.parse(end.toDate()) } });
            }
        }
    };
    return (
        <div className="h-screen">
            <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
                <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <i className="fa-solid fa-chart-simple text-[16px] text-theme"></i>
                        <div className={`text-[14px] font-medium hover:bg-gray-200 ${isMessage ? "" : "bg-[#C3DCF5]"}`} onClick={() => setIsMessage(false)}><label className="px-3 py-4">{t('All Conversations')}</label></div>
                        <div className={`text-[14px] font-medium hover:bg-gray-200 ${isMessage ? "bg-[#C3DCF5]" : ""}`} onClick={() => setIsMessage(true)}><label className="px-3 py-4">{t('All messages')}</label></div>
                    </div>
                    <div className="flex gap-2">
                        <button type='button' onClick={() => exportReport()} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]">
                            {t('Download as Report')}
                        </button>
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
                    <div className="grid grid-cols-10 gap-4 items-end">
                        <div className={`flex flex-col col-span-2`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('All Country')}</label>
                            </div>
                            <SelectCountry height="40px" countryList={globalReducer.country} onSelectCountry={(country) => {
                                if (country?.code) {
                                    setFilter({ criteria: { ...filter?.criteria, countryCode: country?.code } });
                                } else {
                                    delete filter.criteria.countryCode;
                                }
                            }}
                                placeholder={t("Select Country")}
                            />
                        </div>
                        <div className={`flex flex-col col-span-4`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Date Range')}</label>
                            </div>
                            <RangePicker defaultValue={[dayjs().startOf("month"), dayjs()]}
                                className={"h-10"}
                                onChange={selectDateRange}
                                format="YYYY-MM-DD"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 mt-[10.5rem] bg-gray-100 grow">
                <div className="col-span-2 px-2 text-[14px]">
                    <label className="font-bold">Note: </label>
                    <label className="text-gray-500 text-[13px]">The Delivered and Read counts displayed here may be lower than the actual numbers in Meta Business Manager due to small variations in data processing. Credit adjustments will be made to the customer's account every 15 days.</label>
                </div>
                {!isMessage ? <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                        <div className="bg-white p-4 shadow rounded-lg">
                            <div className="flex justify-between">
                                <h3 className="text-[14px] font-semibold flex">
                                    <div className="py-2 pr-1.5">
                                        <svg height="3" width="22"><line stroke="#5C3BBF" strokelLinecap="round" strokeWidth="2" x1="1" x2="19" y1="1" y2="1"></line></svg>
                                    </div>
                                    All Conversations
                                </h3>
                                <p className="text-[14px] font-bold">{new Intl.NumberFormat("en-US").format((conversationsCount?.reduce((acc, item) => acc + item.totalConversation, 0)))}</p>
                            </div>
                            <ul className="text-gray-500 text-[13px]">
                                {messageTypes?.map((m, i) => {
                                    return (
                                        <li key={i}>
                                            <div className="flex justify-between pt-1">
                                                <p><span className={`text-[${m.color}] pr-2 text-[14px] font-semibold`}>---</span>{toTitleCase(m.messageType)}</p>
                                                <label className="text-gray-600">{new Intl.NumberFormat("en-US").format(m?.totalConversation ?? 0)}</label>
                                            </div>
                                        </li>)
                                })}
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow rounded-lg">
                            <div className="flex justify-between">
                                <h3 className="text-[14px] font-semibold flex">
                                    <div className="py-2 pr-1.5">
                                        <svg height="3" width="22"><line stroke="#5C3BBF" strokelLinecap="round" strokeWidth="2" x1="1" x2="19" y1="1" y2="1"></line></svg>
                                    </div>
                                    Paid Conversations
                                </h3>
                                <p className="text-[14px] font-bold">{new Intl.NumberFormat("en-US").format(conversationsCount?.filter(f => f.messageType?.toLowerCase() !== "live_chat")?.reduce((acc, item) => acc + item.totalConversation, 0))}</p>
                            </div>
                            <ul className="text-gray-500 text-[13px]">
                                {messageTypes?.map((m, i) => {
                                    return (
                                        <li key={i}>
                                            <div className="flex justify-between pt-1">
                                                <p><span className={`text-[${m.color}] pr-2 text-[14px] font-semibold`}>---</span>{toTitleCase(m.messageType)}</p>
                                                <label className="text-gray-600">{new Intl.NumberFormat("en-US").format(m?.totalConversation ?? 0)}</label>
                                            </div>
                                        </li>)
                                })}
                            </ul>
                        </div>
                        <div className="bg-white p-4 shadow rounded-lg">
                            <div className="flex justify-between">
                                <h3 className="text-[14px] font-semibold flex">
                                    <div className="py-2 pr-1.5">
                                        <svg height="3" width="22"><line stroke="#5C3BBF" strokelLinecap="round" strokeWidth="2" x1="1" x2="19" y1="1" y2="1"></line></svg>
                                    </div>
                                    Free Conversations
                                </h3>
                                <p className="text-[14px] font-bold">{new Intl.NumberFormat("en-US").format(conversationsCount?.filter(f => f.messageType?.toLowerCase() === "live_chat")?.reduce((acc, item) => acc + item.totalConversation, 0))}</p>
                            </div>
                            <ul className="text-gray-500 text-[13px]">
                                <li className="pt-1">
                                    <div className="flex justify-between">
                                        <p><span className="text-[#33CDCD] pr-2 text-[14px] font-semibold">---</span>Free Tier:</p>
                                        <label className="text-gray-600">{new Intl.NumberFormat("en-US").format(conversationsCount?.filter(f => f.messageType?.toLowerCase() === "live_chat")?.reduce((acc, item) => acc + item.totalConversation, 0))}</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex justify-between pt-1">
                                        <p><span className="text-[#AC2E82] pr-2 text-[14px] font-semibold">---</span>Free Entry Point:</p>
                                        <label className="text-gray-600">0</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-white p-6 shadow rounded-lg mt-4">
                        <h3 className="text-md font-semibold mb-4">All Conversations</h3>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={allGraph}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="conversations" name="All Conversations" stroke="#5C3BBF" strokeWidth={2} />
                                <Line type="monotone" dataKey="mconversations" name="Marketing" stroke="#33CDCD" strokeWidth={2} strokeDasharray="3 3" />
                                <Line type="monotone" dataKey="uconversations" name="Utility" stroke="#AC2E82" strokeWidth={2} strokeDasharray="3 3" />
                                <Line type="monotone" dataKey="aconversations" name="Authentication" stroke="#D35C43" strokeWidth={2} strokeDasharray="3 3" />
                                <Line type="monotone" dataKey="iconversations" name="Authentication International" stroke="#8884d8" strokeWidth={2} strokeDasharray="3 3" />
                                <Line type="monotone" dataKey="sconversations" name="Service" stroke="#30835F" strokeWidth={2} strokeDasharray="3 3" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    {/* <div className="bg-white p-6 shadow rounded-lg mt-4">
                        <h3 className="text-md font-semibold mb-4">Paid Conversations</h3>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={paidGraph}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="conversations" stroke="#8884d8" strokeWidth={2} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div> */}
                    <div className="bg-white p-6 shadow rounded-lg mt-4">
                        <h3 className="text-md font-semibold mb-4">Free Conversations</h3>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={freeGraph}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="conversations" name="Free Conversations" stroke="#5C3BBF" strokeWidth={2} />
                                <Line type="monotone" dataKey="conversations" name="Free Tier" stroke="#33CDCD" strokeWidth={2} strokeDasharray="3 3" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </> : <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                        <div className="bg-white p-4 shadow rounded-lg">
                            <div className="flex justify-between">
                                <h3 className="text-[14px] font-semibold flex">
                                    <div className="py-2.5 pr-1.5">
                                        <svg height="3" width="22"><line stroke="#0A2B60" strokelLinecap="round" strokeWidth="2" x1="1" x2="19" y1="1" y2="1"></line></svg>
                                    </div>
                                    Messages sent
                                    <Tooltip1 title="The number of messages your business sent to accounts on WhatsApp." arrow>
                                        <i className="fa-solid mt-1 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                                    </Tooltip1>
                                </h3>
                                <p className="text-[14px] font-bold">{new Intl.NumberFormat("en-US").format(messageCount?.sent)}</p>
                            </div>
                        </div>
                        <div className="bg-white p-4 shadow rounded-lg">
                            <div className="flex justify-between">
                                <h3 className="text-[14px] font-semibold flex">
                                    <div className="py-2.5 pr-1.5">
                                        <svg height="3" width="22"><line stroke="#1977F2" strokelLinecap="round" strokeWidth="2" x1="1" x2="19" y1="1" y2="1"></line></svg>
                                    </div>
                                    Messages delivered
                                    <Tooltip1 title="The number of messages your business sent to accounts on WhatsApp that were received. Some accounts may not have received it, for example if their device was out of service." arrow>
                                        <i className="fa-solid mt-1 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                                    </Tooltip1>
                                </h3>
                                <p className="text-[14px] font-bold">{new Intl.NumberFormat("en-US").format(messageCount?.delivered)}</p>
                            </div>
                        </div>
                        <div className="bg-white p-4 shadow rounded-lg">
                            <div className="flex justify-between">
                                <h3 className="text-[14px] font-semibold flex">
                                    <div className="py-2.5 pr-1.5">
                                        <svg height="3" width="22"><line stroke="#26B6FF" strokelLinecap="round" strokeWidth="2" x1="1" x2="19" y1="1" y2="1"></line></svg>
                                    </div>
                                    Messages received
                                    <Tooltip1 title="The number of messages your business received from accounts on WhatsApp." arrow>
                                        <i className="fa-solid mt-1 text-gray-400 px-1 text-[12px] fa-circle-info"></i>
                                    </Tooltip1>
                                </h3>
                                <p className="text-[14px] font-bold">{new Intl.NumberFormat("en-US").format(messageCount?.received)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white p-6 shadow rounded-lg mt-4">
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={messageGraph}>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Line type="monotone" dataKey="sent" name="Sent" stroke="#0A2B60" strokeWidth={2} />
                                <Line type="monotone" dataKey="delivered" name="Delivered" stroke="#1977F2" strokeWidth={2} />
                                <Line type="monotone" dataKey="received" name="Received" stroke="#26B6FF" strokeWidth={2} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </>}
            </div>
        </div>);
};

export default AllConversationsInsights;
