import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../store/actions/partnerAction";
import {
  getTenantWaba,
  walletTopUpByPartner,
} from "../../store/actions/tenantAction";
import { fetchIP } from "../../store/actions/globalAction";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";

function AddTenantTopup({ setIsShow, onAdd }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ipAddress, setIpAddress] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [wabas, setWabas] = useState([]);
  const [wallet, setWallet] = useState({});

  useEffect(() => {
    fetchIP().then((tmp) => setIpAddress(tmp?.ip));
    dispatch(
      getTenants(
        {
          criteria: { active: true },
          count: true,
          size: 500,
          offset: 0,
        },
        (d) => {
          setCompanyList(d?.data ?? []);
          if (d?.data?.length > 0) {
            setWallet({ ...wallet, tenantId: d?.data[0]?.id });
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    if (wallet?.tenantId) {
      dispatch(
        getTenantWaba(
          {
            tenantId: wallet?.tenantId,
            criteria: { active: true },
          },
          (d) => {
            setWabas(d?.data ?? []);
            if (d?.data?.length > 0) {
              setWallet({
                ...wallet,
                tenantWabaId: d?.data[0]?.id,
                currencyCode: d?.data[0]?.currencyCode,
              });
            }
          }
        )
      );
    }
  }, [wallet?.tenantId]);

  const onSubmit = (e) => {
    e.preventDefault();
    wallet.detail = { ipAddress: ipAddress };
    dispatch(
      walletTopUpByPartner(wallet, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="px-6 pb-6 pt-3 max-h-[580px] overflow-auto">
      <form onSubmit={onSubmit}>
        <div className="grid grid-cols-12 gap-2">
          <div className={`flex flex-col col-span-6`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Company')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <TypeableSelect list={companyList} selected={wallet} selectedValueField="tenantId" labelField="name" valueField="id" required={true} placeholder={t("Company Name")} height="40px" onSelect={(s) => {
              setWallet({ ...wallet, tenantId: s?.id });
            }} />
          </div>
          <div className={`flex flex-col col-span-6`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Waba')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select
              required
              className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
              onChange={(e) => {
                const wa = wabas.find((f) => f.id == e.target.value);
                setWallet({
                  ...wallet,
                  tenantWabaId: e.target.value,
                  currencyCode: wa?.currencyCode,
                });
              }}
            >
              {wabas?.map((w, i) => (
                <option
                  value={w.id}
                  key={i}
                >{`${w.teamName} - +${w.phoneCode} ${w.phone}`}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Amount')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <div className="flex border border-gray-300 rounded-md text-[14px]">
              <label className="border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-1.5">
                {wallet?.currencyCode}
              </label>
              <input
                type="number"
                placeholder={t("Enter Amount")}
                value={wallet?.balanceAmount}
                data-maxlength={8}
                onInput={OnInput}
                onChange={(e) =>
                  setWallet({ ...wallet, balanceAmount: e.target.value })
                }
                className="border-0 rounded-md py-1 w-[100%] h-9 text-[13px]"
                required
              />
            </div>
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Remarks')}</label>
            </div>
            <textarea
              value={wallet?.remarks}
              rows={3}
              placeholder={t("Remarks")}
              className="border border-gray-300 w-full rounded-md py-1 px-3 text-[13px] textArea"
              onChange={(e) =>
                setWallet({ ...wallet, remarks: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className={`flex justify-end gap-2 mt-6`}>
          <button
            type="submit"
            className="justify-center max-w-[200px] items-center flex px-5 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
          >
            {t('Submit')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTenantTopup;
