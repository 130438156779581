import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DatePicker } from "antd";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function AllMessages({ showSidebar }) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    criteria: {},
  });

  useEffect(() => {
    Usersearch();
  }, []);

  const Usersearch = () => { };

  const onSearch = () => {
    Usersearch();
  };

  const selectHolidayDate = (dates) => {
    const [start, end] = dates;
    // filter([
    //     moment(start).format("YYYY-MM-DD"),
    //     moment(end).format("YYYY-MM-DD"),
    // ]);
  };

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular fa-comment-dots text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('All Messages')}</div>
          </div>
          <div className="flex gap-2"></div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('All Phone number')}</label>
              </div>
              <select
                className="border border-gray-300 min-w-[200px] rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('All Country')}</label>
              </div>
              <select
                className="border border-gray-300 min-w-[200px] rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectHolidayDate}
                format="YYYY-MM-DD"
              />
            </div>

            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >

                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 pt-5 mt-[10.5rem] mb-[20px]">

        </div>
      </div>
    </div>
  );
}

export default AllMessages;
