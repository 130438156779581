import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createRateCard,
  getMessageType,
  rateCardDetailsQuery,
  updateRateCard,
} from "../../store/actions/partnerAction";
import moment from "moment";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import PopUp from "../../components/PopUp";
import ImportExcel from "../../components/ImportExcel";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

function AddRateCard({
  setIsShow,
  onAdd,
  isEdit = false,
  defaultRate,
  isView = false,
  duplicate = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUpload, setIsUpload] = useState(false);
  const [rateCard, setRateCard] = useState({});
  const [rateCardDetails, setRateCardDetails] = useState([]);
  const [messageTypes, setMessageTypes] = useState([]);
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const globalReducer = useSelector((state) => state.globalReducer);
  const [columnValidation, setColumnValidation] = useState([
    {
      name: "countryCode",
      displayName: "CountryCode",
      type: "text",
      isMandatory: true,
    },
    {
      name: "currency",
      displayName: "Currency",
      type: "text",
      isMandatory: true,
    },
  ]);

  useEffect(() => {
    dispatch(
      getMessageType((a) => {
        setColumnValidation(
          columnValidation.concat([
            ...a.map((m) => {
              return {
                name: m.messageType,
                displayName: m.messageType,
                type: "number",
                isMandatory: false,
              };
            }),
          ])
        );
        setMessageTypes(a);
      })
    );
    if (isEdit) {
      dispatch(
        rateCardDetailsQuery({ criteria: { id: defaultRate?.id } }, (d) => {
          setRateCard({
            ...d.data[0],
            countryName: d.data[0]?.country?.name,
            currencyCode: d.data[0]?.country?.currencyCode,
          });
          var isNative = false;
          var rCDetails = globalReducer.country.map((m) => {
            var dt = d.data[0]?.rateCardDetails?.find(
              (f) => f.countryCode === m.code
            );
            if (dt == null) {
              return {
                ...m,
                countryCode: m.code,
                countryName: m.name,
                currency: m.currencyCode,
              };
            } else {
              var cny = globalReducer.country.find(
                (f1) => f1.code === dt.countryCode
              );
              isNative = dt.currency === "NATIVE";
              return { ...dt, countryName: cny.name, id: cny.id };
            }
          });
          if (!isNative) {
            rCDetails = rCDetails.map((m1) => {
              if (
                m1.countryCode === partnerReducer.partnerDetails?.countryCode
              ) {
                return { ...m1, currency: "NATIVE" };
              } else return m1;
            });
          }
          setRateCardDetails(rCDetails);
        })
      );
    } else {
      setRateCardDetails(
        globalReducer.country?.map((m) => {
          if (m.code === partnerReducer.partnerDetails?.countryCode) {
            return {
              ...m,
              countryCode: m.code,
              countryName: m.name,
              currency: "NATIVE",
            };
          } else
            return {
              ...m,
              countryCode: m.code,
              countryName: m.name,
              currency: m.currencyCode,
            };
        })
      );
      setRateCard({
        ...rateCard,
        countryCode: partnerReducer.partnerDetails?.countryCode,
        countryName: partnerReducer.partnerDetails?.country?.name,
        currencyCode: partnerReducer.partnerDetails?.country?.currencyCode,
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    rateCard.rateCardDetails = rateCardDetails?.filter(
      (f) => Object.keys(f.rates ?? {}).length > 0
    );
    rateCard.rateCardDetails.map((m) => {
      if (m.currency !== "NATIVE") {
        m.currencyCode = rateCard.currencyCode;
        m.currency = rateCard.currencyCode;
      }
    });
    if (isEdit && !duplicate) {
      dispatch(
        updateRateCard(rateCard, (a) => {
          setIsShow(false);
          onAdd(a);
        })
      );
    } else {
      if (duplicate) {
        rateCard.id = null;
      }
      dispatch(
        createRateCard(rateCard, (a) => {
          setIsShow(false);
          onAdd(a);
        })
      );
    }
  };

  const RateReplicate = (rate) => {
    rateCardDetails?.map((d, c) => {
      if (d.currency !== "NATIVE") {
        messageTypes.map((m, i) => {
          if (!d.rates) {
            d.rates = {};
          }
          d.rates[m.messageType] = rate?.rates?.[m.messageType];
        });
      }
    });
    setRateCardDetails([...rateCardDetails]);
  };

  const updateRate = (id, key, value) => {
    setRateCardDetails([
      ...rateCardDetails.map((c, i) => {
        if (c.id === id) {
          return { ...c, rates: { ...c.rates, [key]: value } };
        } else {
          return c;
        }
      }),
    ]);
  };

  const exportToExcel = () => {
    const list = [];
    rateCardDetails
      ?.filter((f) => Object.keys(f.rates ?? {}).length > 0)
      .map((m) => {
        if (m.currency === "NATIVE") {
          list.unshift({
            countryCode: m.countryCode,
            currency: m.currency,
            ...m.rates,
          });
        } else {
          list.push({
            countryCode: m.countryCode,
            currency: m.currency,
            ...m.rates,
          });
        }
      });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(list);
    XLSX.utils.book_append_sheet(workbook, worksheet, "conversation_rate_card");
    XLSX.writeFile(workbook, "conversation_rate_card.xlsx");
  };

  const exportToExcelTemplate = () => {
    const list = [];
    const header = { countryCode: "", currency: "NATIVE" };
    messageTypes?.map((m) => {
      header[m.messageType] = "";
    });
    globalReducer.country?.map((c) => {
      if (c.code === partnerReducer.partnerDetails?.countryCode) {
        list.unshift({ ...header, countryCode: c.code, currency: "NATIVE" });
      } else {
        list.push({
          ...header,
          countryCode: c.code,
          currency: partnerReducer.partnerDetails?.country?.currencyCode ?? "",
        });
      }
    });
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(list);
    XLSX.utils.book_append_sheet(workbook, worksheet, "conversation_rate_card");
    XLSX.writeFile(workbook, "conversation_rate_card_template.xlsx");
  };
  const onKeyDownForRate = (e) => {
    const newValue = e.target.value + e.key;
    const decimalRegex = /^\d*\.?\d*$/;
    if (e.key?.length === 1 && !decimalRegex.test(newValue)) {
      e.preventDefault();
    }
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <>
      <>
        <div className="py-2.5 px-4 border-b-[1px] sticky top-0 w-full text-[16px] flex items-center justify-between  border-[#F0F1F3]">
          <span className="text-[14px] font-medium text-black">
            {isEdit
              ? isView
                ? t("Rate Card")
                : t("Edit Rate Card")
              : t("Add Rate Card")}
          </span>
          <div className="flex items-center gap-4">
            {!isView && (
              <a
                onClick={() => setIsUpload(true)}
                className="text-center whitespace-nowrap px-[2px] py-[4px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
              >
                {t('Upload')}
              </a>
            )}
            <a
              onClick={() => exportToExcel()}
              className="text-center whitespace-nowrap px-[2px] py-[4px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
            >
              {t('Download')}
            </a>
            <a
              onClick={() => exportToExcelTemplate()}
              className="text-center whitespace-nowrap px-[2px] py-[4px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
            >
              {t('Download Template')}
            </a>
            <button
              onClick={() => {
                setIsShow(false);
              }}
              type="button"
              className="text-gray-400 z-50 bg-transparent hover:bg-gray-200 hover:text-black rounded text-sm w-8 h-8 inline-flex items-center justify-center"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">{t('Close menu')}</span>
            </button>
          </div>
        </div>
        <form
          disabled={isView}
          className="height30 flex flex-col justify-between pb-3"
          onSubmit={handleSubmit}
        >
          <div className="h-dvh overflow-y-auto p-4">
            <div className="grid grid-cols-12 gap-3">
              <div className="flex flex-col col-span-3">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Rate Card Name')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="text"
                  placeholder={t("Enter Rate Card Name")}
                  value={rateCard?.name}
                  onChange={(e) => {
                    setRateCard({ ...rateCard, name: e.target.value });
                  }}
                  className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                  required
                />
              </div>
              <div className="flex flex-col col-span-3">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Country Name')}</label>
                </div>
                <SelectCountry
                  selectedCode={rateCard?.countryCode}
                  countryList={globalReducer.country}
                  onSelectCountry={(country) => {
                    if (country) {
                      rateCard.countryCode = country.code;
                      rateCard.countryName = country.name;
                      rateCard.currencyCode = country.currencyCode;
                      setRateCardDetails(
                        rateCardDetails.map((f) => {
                          if (f.countryCode == country.code) {
                            return { ...f, currency: "NATIVE" };
                          } else
                            return { ...f, currency: country.currencyCode };
                        })
                      );
                    } else {
                      delete rateCard.countryCode;
                      delete rateCard.countryName;
                      delete rateCard.currencyCode;
                      setRateCardDetails(
                        rateCardDetails.map((f) => {
                          if (
                            f.countryCode ==
                            partnerReducer.partnerDetails?.countryCode
                          ) {
                            return { ...f, currency: "NATIVE" };
                          } else
                            return {
                              ...f,
                              currency:
                                partnerReducer.partnerDetails?.country
                                  ?.currencyCode ?? "",
                            };
                        })
                      );
                    }
                    setRateCard({ ...rateCard });
                  }}
                  placeholder={t("Select Country")}
                />
              </div>
              <div className="flex flex-col col-span-3">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t("Applicable Date")}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="date"
                  value={moment(rateCard?.applicableDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setRateCard({
                      ...rateCard,
                      applicableDate: new Date(e.target.value),
                    });
                  }}
                  className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                  required
                />
              </div>
              <div className="flex flex-col col-span-3">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t("Other Details")}</label>
                </div>
                <input
                  type="text"
                  placeholder={t("Enter Other Details")}
                  value={rateCard?.remark}
                  onChange={(e) => {
                    setRateCard({ ...rateCard, remark: e.target.value });
                  }}
                  className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                />
              </div>
            </div>
            <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-4 mb-2">
              {t("Domestic Rate")}
            </div>
            <table className="text-sm text-left rtl:text-right text-midBlack">
              <thead className="text-[14px] text-midBlack">
                <tr className="h-9 bg-gray-100">
                  <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                    <div>{t("Countries")}</div>
                  </th>
                  {messageTypes.map((m, i) => (
                    <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                      <div>{m.messageType}</div>
                    </th>
                  ))}
                </tr>
                {rateCardDetails
                  .filter((f) => f.currency === "NATIVE")
                  .map((f, r) => (
                    <tr key={r}>
                      <td className="py-2 font-normal text-[13px] pr-2">
                        {rateCard?.countryName ?? "Domestic"} (
                        {rateCard?.currencyCode})
                      </td>
                      {messageTypes.map((m, i) => (
                        <td
                          key={i}
                          className="py-2 font-normal text-[13px] pr-1"
                        >
                          <input required
                            data-maxlength={8}
                            step="any"
                            onInput={OnInput}
                            type="text"
                            className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                            value={f?.rates?.[m.messageType]}
                            onChange={(e) => {
                              const decimalRegex = /^\d*\.?\d*$/;
                              if (decimalRegex.test(e.target.value)) {
                                updateRate(f.id, m.messageType, e.target.value);
                              }
                            }}
                            onKeyDown={onKeyDownForRate}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                <tr>
                  <td colSpan={messageTypes.length + 1}>
                    <div className="font-medium text-[13px] w-full text-[#2A2E34] my-4 flex items-center justify-between">
                      <label>
                        {t("International")} ({rateCard?.currencyCode})
                      </label>
                      <input
                        type="text"
                        placeholder={t("Search Countries")}
                        className="border border-gray-300 rounded-md h-9 py-1"
                        onChange={(e) => {
                          const cn = e.target.value?.toLowerCase();
                          setRateCardDetails(
                            rateCardDetails.map((f) => {
                              if (
                                f.countryCode?.toLowerCase()?.includes(cn) ||
                                f.countryName?.toLowerCase()?.includes(cn)
                              ) {
                                return { ...f, isHide: false };
                              } else return { ...f, isHide: true };
                            })
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {rateCardDetails?.map((d, c) => (
                  <tr
                    className={
                      "border " +
                      (d.isHide || d.currency === "NATIVE" ? "hidden" : "")
                    }
                    key={c}
                  >
                    <td className="py-0.5 font-normal text-[13px] pl-2 pr-2">
                      {d?.countryName}
                      <p>
                        <a
                          className="text-[10px] mt-[-2px] whitespace-nowrap transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                          onClick={() => RateReplicate(d)}
                        >
                          {t("Replicate for all country")}
                        </a>
                      </p>
                    </td>
                    {messageTypes.map((m, i) => (
                      <td
                        key={i}
                        className="py-1 font-normal text-[13px] pr-1"
                      >
                        <input required
                          data-maxlength={8}
                          step="any"
                          onInput={OnInput}
                          type="text"
                          className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                          value={d?.rates?.[m.messageType]}
                          onChange={(e) => {
                            const decimalRegex = /^\d*\.?\d*$/;
                            if (decimalRegex.test(e.target.value)) {
                              updateRate(d.id, m.messageType, e.target.value)
                            }
                          }}
                          onKeyDown={onKeyDownForRate}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
            <div className="flex justify-end gap-2 my-2">
              {!isView && (
                <button
                  type="submit"
                  className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[13px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                >
                  {t("Save")}
                </button>
              )}
            </div>
          </div>
        </form>
      </>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Upload Conversation Rate Card")}
          </span>
        }
        isShowModal={isUpload}
        setIsShowModal={setIsUpload}
        ModalBody={
          <div className="p-6 customPadding">
            <ImportExcel
              Title={t("Upload Conversation Rate Card")}
              setIsShow={setIsUpload}
              columnValidation={columnValidation}
              onImportClick={(con) => {
                con.forEach((f) => {
                  const fi = rateCardDetails.find(
                    (s) => s.countryCode === f.countryCode
                  );
                  if (fi != null) {
                    if (!fi.rates) {
                      fi.rates = {};
                    }
                    messageTypes.map((m) => {
                      fi.rates[m.messageType] = f[m.messageType];
                    });
                  }
                });
                setRateCardDetails(rateCardDetails);
                setIsUpload(false);
              }}
            />
          </div>
        }
        size="xl"
      />
    </>
  );
}

export default AddRateCard;
