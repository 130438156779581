import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { notificationUpsert } from '../../store/actions/partnerAction';

function AddNotification({ setIsShow, selectedNotification, onAdd }) {
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const [notification, setNotification] = useState({ status: "PENDING" });

    useEffect(() => {
        if (selectedNotification?.isEdit) {
            setNotification(selectedNotification);
        }
    }, [selectedNotification]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(notificationUpsert(notification, (a) => {
            setIsShow(false);
            onAdd(a);
        }));
    };

    return (<form disabled={selectedNotification?.isView} className="height30 flex flex-col justify-between pb-3" onSubmit={handleSubmit}>
        <div className="h-dvh overflow-y-auto p-4">
            <div className="grid grid-cols-12 gap-3 w-full mapPlan">
                {/* <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Status')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <select value={notification?.status} className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                        onChange={(e) => {
                            setNotification({ ...notification, status: e.target.value });
                        }}>
                        <option value={"PENDING"} selected>
                            {t('Pending')}
                        </option>
                        <option value={"SEND"}>{t('Send')}</option>
                    </select>
                </div> */}
                <div className="col-span-12">
                    <div className="text-midBlack text-[13px] font-normal mb-1">
                        <label>{t('Notification')}<span className="text-red-500 text-[14px]">*</span></label>
                    </div>
                    <textarea required
                        placeholder={t("Enter Notification Content")}
                        rows="2"
                        value={notification?.content}
                        onChange={(e) => setNotification({ ...notification, content: e.target.value })}
                        className="border border-gray-300 rounded-md text-[13px] px-3 h-[200px] py-1 w-[100%]"
                    />
                </div>
            </div>
        </div>
        <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
            <div className="flex justify-end gap-2 my-2">
                <button type="submit" className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    {t('Save')}
                </button>
            </div>
        </div>
    </form>)
}

export default AddNotification