import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import { Pagination } from "@mui/material";
import { getWalletTransfers } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import AddTenantTransfer from "../../layouts/partner/AddTenantTransfer";
import { useTranslation } from "react-i18next";
import RightSidePanel from "../../components/RightSidePanel";
import ViewTenantWalletTransfer from "../../layouts/partner/ViewTenantWalletTransfer";
const { RangePicker } = DatePicker;

function TenantWalletTransfers({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState({});
  const [showAddTransfer, setShowAddTransfer] = useState(false);
  const [walletTransfers, setWalletTransfers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ criteria: {} });
  const [dateFilter, setDateFilter] = useState();
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const partnerTimezone =
    partnerReducer.partnerDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    onSearch();
  }, [currentPage]);

  const onSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push(
        {
          property: "fromTeamName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "fromCompanyName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "fromPhone",
          operator: "CONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "toCompanyName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "toTeamName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "toPhone",
          operator: "CONTAINS",
          value: searchText,
        }
      );
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "OR" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }

    dispatch(
      getWalletTransfers(
        {
          ...filter,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setWalletTransfers(d?.data ?? []);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-money-bill-transfer text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('Manage Transfers')}</div>
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => setShowAddTransfer(true)}
            >
              {t('Add Transfer')}
            </button>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-11 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Transfer Details')}</label>
              </div>
              <input
                type="text"
                placeholder="Enter Transaction Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>

            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {walletTransfers && walletTransfers.length > 0 && (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('From Company Name')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('To Company Name')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('From WABA')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('To WABA')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Amount')}</div>
                      </div>
                    </th>
                    {/* <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Remarks')}</div>
                      </div>
                    </th> */}
                    {/* <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Date & Time')}</div>
                      </div>
                    </th> */}
                    <th scope="col" className="font-[500]">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {walletTransfers.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className="py-3 text-[13px] pl-2 font-normal">
                          {`${p.fromCompanyName}`}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {`${p.toCompanyName}`}
                        </td>
                        <td className="py-3 text-[13px] pl-2 font-normal">
                          {`${p.fromTeamName} (+${p.fromPhoneCode} ${p.fromPhone})`}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {`${p.toTeamName} (+${p.toPhoneCode} ${p.toPhone})`}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.currencyCode} {p?.amount}
                        </td>
                        {/* <td className="py-3 text-[13px] font-normal">
                          {p?.remarks}
                        </td> */}
                        {/* <td className="py-3 text-[13px] font-normal">
                          {moment
                            .utc(p?.createdAt)
                            .tz(partnerTimezone)
                            .format("YYYY-MM-DD hh:mm A")}
                        </td> */}
                        <td className="py-3 text-[13px] font-normal mr-2">
                          <div onClick={() => { setSelectedTransfer(p); setShowDetails(true) }}>
                            <i className="fa-solid fa-eye text-[22px] text-theme"></i>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t('Add Transfer')}
          </span>
        }
        isShowModal={showAddTransfer}
        setIsShowModal={setShowAddTransfer}
        ModalBody={
          <AddTenantTransfer setIsShow={setShowAddTransfer} onAdd={onSearch} />
        }
        size="xl"
      />
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showDetails}
        setIsShow={setShowDetails}
        ModalTitle={t("View Details")}
        Component={
          <ViewTenantWalletTransfer
            setIsShow={setShowDetails}
            transfer={selectedTransfer}
          />
        }
      />
    </div>
  );
}

export default TenantWalletTransfers;
