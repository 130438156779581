import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { liveChatGetContacts } from "../../store/actions/tenantAction";
import { useTranslation } from "react-i18next";

function SearchExistingTicket({ setSearchShow, agents, onSelect }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [allContacts, setAllContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [searchText, setSearchText] = useState("");
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    getLiveChatGetContacts();
  }, []);

  const getLiveChatGetContacts = () => {
    const filter = {};
    if (searchText) {
      filter.filter = [
        { property: "contact.name", operator: "ICONTAINS", value: searchText },
        { clause: "OR" },
        { property: "contact.email", operator: "ICONTAINS", value: searchText },
        { clause: "OR" },
        {
          property: "contact.whatsappNumber",
          operator: "CONTAINS",
          value: searchText,
        },
      ];
    }
    dispatch(
      liveChatGetContacts(filter, (s) => {
        setAllContacts(s ?? []);
        setContacts(s ?? []);
      })
    );
  };
  return (
    <div className="flex relative flex-col items-center justify-between w-full h-16 px-3 border-l-2 border-r-2 border-b border-solid lg:flex-row border-gray-200">
      <form
        className="w-full mx-auto"
        onSubmit={(e) => {
          e.preventDefault();
          getLiveChatGetContacts();
        }}
      >
        <label
          for="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          {t('Search')}
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            onChange={(e) => {
              setSearchText(e.target.value);
              if (e.target.value) {
                const text = e.target.value?.toLowerCase();
                setContacts(
                  allContacts?.filter(
                    (f) =>
                      f.contact?.name?.toLowerCase()?.includes(text) ||
                      f.contact?.email?.toLowerCase()?.includes(text) ||
                      f.contact?.whatsappNumber?.includes(text) ||
                      f.ticketNumber?.toLowerCase()?.includes(text)
                  )
                );
              } else {
                setContacts([...allContacts]);
              }
            }}
            id="default-search"
            className="block w-full p-2.5 ps-9 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
            placeholder="Search by name, email or phone number"
            required
          />
        </div>
      </form>
      <div className="p-[10px] bg-white rounded-[4px] text-[14px] mt-4 dropdown-shadow absolute w-[290px] max-h-[400px] h-auto overflow-y-auto top-[38px] z-[999]">
        <label className="text-[#4B5563]">
          Showing <strong className="text-[#0B132B]">{contacts?.length}</strong>{" "}
          results
        </label>
        <div className="flex flex-col gap-2 mt-4">
          {contacts?.map((c, i) => (
            <div
              key={i}
              onClick={() => onSelect(c)}
              className="w-full border-[0.8px] border-[#E5E7EB] rounded-[8px] bg-white p-[5px] hover:bg-gray-100"
            >
              <div className="flex items-start gap-4">
                <div className="relative flex items-center w-[25%]">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/default-avatar.png`}
                    className="object-cover w-9 h-9 content-between rounded-full"
                  />
                </div>
                <div className="flex flex-col gap-2 text-[#1F2937] font-semibold">
                  <div className="text-[14px]">{c.contact?.name}</div>
                  <div className="flex gap-2 items-center flex-wrap">
                    {/* <div className="p-[4px] text-[12px] bg-[#F3F4F6] rounded-[4px]">
                                        Infocomm
                                    </div> */}
                    <div className="p-[4px] text-[12px] bg-[#F3F4F6] rounded-[4px]">
                      <i className="fa-solid fa-user"></i>{" "}
                      {c.assignedUsers?.includes(globalReducer.loginInfo?.uid)
                        ? "You"
                        : agents?.find((f) => c.assignedUsers?.includes(f.uid))
                          ?.name}
                    </div>
                    <div className="p-[4px] text-[12px] bg-[#F3F4F6] rounded-[4px]">
                      <i className="fa-solid fa-ticket"></i> {c.ticketStatus}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {contacts.length <= 0 && (
          <div className="p-[16px] text-center flex flex-col gap-4">
            <img src="/images/nothing-found.svg" className="h-[54px]" alt="" />
            <div className="text-[14px] text-[#6B7280]">
              Nothing found related to {searchText}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchExistingTicket;
