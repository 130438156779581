import React from 'react'
import { sendTenantCredentials } from '../store/actions/tenantAction';
import { SHOW_SNACKBAR } from '../utils/types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sendPartnerCredentials } from '../store/actions/adminAction';

function SendCredentials({ setIsShow, type, tenantId, selectedUser, partnerId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onSubmit = (communicationType) => {
        if (type === "TENANT") {
            dispatch(sendTenantCredentials({ tenantId: tenantId, uid: selectedUser?.uid, communicationType: communicationType }, a => {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "success", message: "Credentials have been sent to the user.", open: true } });
                setIsShow(false);
            }));
        } else if (type === "PARTNER") {
            dispatch(sendPartnerCredentials({ partnerId: partnerId, uid: selectedUser?.uid, communicationType: communicationType }, a => {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "success", message: "Credentials have been sent to the user.", open: true } });
            }));
        }
    }

    return (
        <div className='px-6 pt-3 pb-6'>
            <p className='text-[14px]'><label className='text-gray-600'>Email : </label> <label className='text-gray-400'>{selectedUser?.email}</label></p>
            <p className='text-[14px]'><label className='text-gray-600'>Phone : </label> <label className='text-gray-400'>+{selectedUser?.phoneCode + " " + selectedUser?.phone}</label></p>
            <div className='flex gap-2 mt-2 w-full'>
                <button type="button" className="p-2 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                    onClick={() => onSubmit("EMAIL")}>
                    {t('Send Email')}
                </button>
                <button type="button" className="p-2 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                    onClick={() => onSubmit("SMS")}>
                    {t('Send SMS')}
                </button>
                <button type="button" className="p-2 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                    onClick={() => onSubmit("ALL")}>
                    {t('Send Both')}
                </button>
            </div>
        </div>
    )
}

export default SendCredentials