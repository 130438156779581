import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import { getConversation } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import RightSidePanel from "../../components/RightSidePanel";
import ConversationDetail from "../../layouts/tenant/ConversationDetail";
import { msgStatusColor, msgStatusTextColor } from "../../utils/helper";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function AllConversations({ showSidebar }) {
  const { t } = useTranslation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo?.tenantId },
  });
  const [dateFilter, setDateFilter] = useState();
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (globalReducer.waba?.id) {
      onSearch();
    }
  }, [currentPage, globalReducer.waba]);

  const onSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push({ clause: "OPEN_BRACKET" },
        {
          property: "contact.whatsappNumber",
          operator: "CONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "contact.email",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "contact.name",
          operator: "ICONTAINS",
          value: searchText,
        }, { clause: "CLOSE_BRACKET" }
      );
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
      if (filter.filter?.length > 0) {
        filter.filter.push({ clause: "AND" });
      }
      filter.filter.push({ clause: "OPEN_BRACKET" }, {
        property: "contact.category",
        operator: "IN",
        not: true,
        value: "GENERIC_EXTERNAL",
      }, { clause: "CLOSE_BRACKET" });
    }
    dispatch(getConversation({
      ...filter,
      criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
      tenantId: globalReducer.loginInfo?.tenantId,
      count: true,
      size: itemParPage,
      offset: (currentPage - 1) * itemParPage,
      orderBy: [{ property: "id", direction: "DESC" }]
    }, (d) => {
      setConversations(d?.data ?? []);
      setTotalPages(Math.ceil(d?.count / itemParPage));
    }));
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  const exportReport = () => {
    dispatch(getConversation({
      ...filter,
      criteria: { ...filter?.criteria, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
      tenantId: globalReducer.loginInfo?.tenantId,
      count: true,
      size: 10000,
      offset: 0,
      orderBy: [{ property: "id", direction: "DESC" }]
    }, (d) => {
      const data = d?.data?.map(m => {
        return {
          id: m.id,
          waba: `${globalReducer.waba?.teamName ?? ""}  ${globalReducer.waba?.phoneCode ? "+" + globalReducer.waba?.phoneCode : ""} ${globalReducer.waba?.phone ?? ""}`,
          whatsappNumber: m?.contact?.whatsappNumber,
          messageType: m.messageType,
          type: m.type,
          templateName: m.templateName,
          currencyCode: m.currencyCode,
          countryCode: m.countryCode,
          languageCode: m.languageCode,
          amount: m.amount,
          messageText: m.messageText,
          referenceId: m.referenceId,
          messageStatus: m.messageStatus,
          remarks: m.remarks,
          reply: m.reply,
          createdAt: m.createdAt,
        }
      })
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data ?? []);
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Conversation");
      XLSX.writeFile(workbook, 'all_Conversation.xlsx');
    }));
  };
  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular fa-comments text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('All Conversationss')}</div>
          </div>
          <div className="flex gap-2">
            <button type='button' onClick={() => exportReport()} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#FAAEAE]">
              {t('Download as Report')}
            </button>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Contact Details')}</label>
              </div>
              <input type="text" placeholder={t("Enter Contact Details")}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => { setSearchText(e.target.value) }} />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('All Country')}</label>
              </div>
              <SelectCountry height="40px" countryList={globalReducer.country} onSelectCountry={(country) => {
                setFilter({ criteria: { ...filter?.criteria, countryCode: country?.code } });
              }}
                placeholder={t("Select Country")}
              />
            </div>
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>

            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={() => {
                  if (currentPage === 1) {
                    onSearch();
                  } else {
                    setCurrentPage(1);
                  }
                }}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {conversations && conversations.length > 0 &&
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('Phone')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Template')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('MessageType')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] w-[160px]">
                      <div className="flex gap-2">
                        <div>{t('Status')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Date')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Action')}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {conversations.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className={"py-3 text-[13px] pl-2 font-normal"}>
                          +{p?.contact?.whatsappNumber ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.templateName ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.messageType}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          <label className={`p-1.5 mr-1 text-[12px] rounded-lg bg-[${msgStatusColor[p?.messageStatus?.toLowerCase()]}]`}><span className={`text-[${msgStatusTextColor[p?.messageStatus?.toLowerCase()]}]`}> {p?.messageStatus ?? ""}</span></label>
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {moment.utc(p?.createdAt).tz(wabaTimezone).format("YYYY-MM-DD hh:mm A")}
                        </td>
                        <td className=" py-3 text-[13px] font-normal">
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => { setSelectedConversation(p); setShowDetail(true) }}>
                              {t('Detail')}
                            </Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>}
        </div>
        <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
          {totalPages > 1 && (
            <div className="flex overflow-x-auto">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          )}
        </div>
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showDetail}
        setIsShow={setShowDetail}
        ModalTitle={t("Details")}
        Component={
          <ConversationDetail setIsShow={setShowDetail} conversation={selectedConversation} selectedWaba={globalReducer.waba} />
        }
      />
    </div>
  );
}

export default AllConversations;
