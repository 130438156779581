import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getNotification } from '../../store/actions/partnerAction';
import RightSidePanel from '../../components/RightSidePanel';
import { Dropdown } from "flowbite-react";
import { Pagination } from "@mui/material";
import moment from "moment-timezone";
import { DatePicker } from "antd";
import AddNotification from '../../layouts/partner/AddNotification';
import SendNotification from '../../layouts/partner/SendNotification';
const { RangePicker } = DatePicker;

function ManageNotifications({ showSidebar }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const [showSendNotification, setShowSendNotification] = useState(false);
    const [showAddNotification, setShowAddNotification] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState({});
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [filter, setFilter] = useState({ criteria: {} });
    const [dateFilter, setDateFilter] = useState();
    const partnerReducer = useSelector((state) => state.partnerReducer);
    const partnerTimezone =
        partnerReducer.partnerDetails?.timezone ??
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    const itemParPage = 10;

    useEffect(() => {
        onSearch();
    }, [currentPage]);

    const onNotificationSearch = () => {
        const fi = [
            { clause: "OPEN_BRACKET" },
            {
                property: "content",
                operator: "ICONTAINS",
                value: searchText,
            },
            { clause: "OR" },
            {
                property: "notificationType",
                operator: "ICONTAINS",
                value: searchText,
            }, { clause: "CLOSE_BRACKET" }];
        if (dateFilter && searchText) {
            filter.filter = [...fi, { clause: "AND" }, dateFilter];
        } else if (searchText) {
            filter.filter = fi;
        } else if (dateFilter) {
            filter.filter = [dateFilter];
        } else {
            filter.filter = [];
        }
        dispatch(getNotification(
            {
                ...filter,
                count: true,
                size: itemParPage,
                offset: (currentPage - 1) * itemParPage,
                orderBy: [{ property: "createdAt", direction: "DESC" }]
            },
            (d) => {
                setNotifications(d?.data);
                setTotalPages(Math.ceil(d?.count / itemParPage));
            }
        ));
    };

    const onSearch = () => {
        if (currentPage === 1) {
            onNotificationSearch();
        } else {
            setCurrentPage(1);
        }
    };

    const selectDate = (dates) => {
        if (dates && dates.length > 0) {
            const [start, end] = dates;
            if (start && end) {
                setDateFilter({
                    property: "createdAt",
                    operator: "RANGE",
                    lowerBound: Date.parse(start.toDate()),
                    upperBound: Date.parse(end.toDate()),
                });
            } else {
                setDateFilter(null);
            }
        } else {
            setDateFilter(null);
        }
    };
    return (
        <div className="h-screen">
            <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
                <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
                    <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
                        <i className="fa-solid fa-bell text-[14px] text-theme"></i>
                        <div className="text-[14px] font-medium">{t('Manage Notifications')}</div>
                    </div>
                    <div className="flex gap-2">
                        <div
                            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                            onClick={() => {
                                setSelectedNotification({});
                                setShowAddNotification(true);
                            }}
                        >
                            {t('Add Notification')}
                        </div>
                    </div>
                </div>
                <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
                    <div className="grid grid-cols-12 gap-2 items-end">
                        <div className={`flex flex-col col-span-4`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Notification Details')}</label>
                            </div>
                            <input
                                type="text"
                                placeholder="Enter Notification Details"
                                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </div>
                        <div className={`flex flex-col col-span-2`}>
                            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                                <label>{t('Status')}</label>
                            </div>
                            <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                                onChange={(e) => {
                                    setFilter({ ...filter, criteria: { ...filter.criteria, status: e.target.value } });
                                }}>
                                <option value={"PENDING"} selected>
                                    {t('Pending')}
                                </option>
                                <option value={"SEND"}>{t('send')}</option>
                            </select>
                        </div>
                        <div className={`flex flex-col col-span-3`}>
                            <div className="col-span-2 text-[#656F7D] py-2 text-[13px] font-normal">
                                <label className="whitespace-nowrap">{t('Created Date')}</label>
                            </div>
                            <RangePicker className="h-10 col-span-4" onChange={selectDate} format="YYYY-MM-DD" />
                        </div>
                        <div className={`flex flex-col`}>
                            <button type="button" className="justify-center items-center flex px-4 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                                onClick={onSearch}>
                                {t('Search')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col h-full justify-between grow">
                <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
                    {notifications && notifications?.length > 0 ? (
                        <>
                            <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                                <thead className="text-[14px] text-midBlack bg-gray-200">
                                    <tr>
                                        <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                            <div className="flex gap-2">
                                                <div> {t('Sr. No.')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t('Content')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t('Status')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t('TotalSent')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div> {t('TotalViewed')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>{t('Created Date & Time')}</div>
                                            </div>
                                        </th>
                                        <th scope="col" className="font-[500]">
                                            <div className="flex gap-2">
                                                <div>{t('Action')}</div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {notifications.map((p, i) => {
                                        return (
                                            <tr key={i} className="bg-white border-b">
                                                <td className="py-3 text-[13px] pl-2">
                                                    {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                                                </td>
                                                <td className="py-1 text-[13px] font-normal px-2">
                                                    <textarea
                                                        value={p?.content}
                                                        rows={3}
                                                        disabled
                                                        className="border-0 w-full"
                                                    ></textarea>
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.status ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.totalSent ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {p?.totalViewed ?? "-"}
                                                </td>
                                                <td className="py-3 text-[13px] font-normal">
                                                    {moment.utc(p?.createdAt)
                                                        .tz(partnerTimezone)
                                                        .format("YYYY-MM-DD hh:mm A") ?? "-"}
                                                </td>
                                                <td className=" py-3 text-[13px] font-normal">
                                                    <Dropdown inline arrowIcon={false} inputMode as="button"
                                                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                                        label={<i className="fa-solid fa-ellipsis"></i>}>
                                                        <Dropdown.Item onClick={() => {
                                                            setSelectedNotification({ ...p, isEdit: true });
                                                            setShowAddNotification(true);
                                                        }}>
                                                            {t('Edit')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setSelectedNotification(p);
                                                                setShowSendNotification(true);
                                                            }}
                                                        >
                                                            {t('Send Notifications')}
                                                        </Dropdown.Item>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {totalPages > 1 ? (
                                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                                    <Pagination
                                        page={currentPage}
                                        count={totalPages}
                                        onChange={(event, page) => setCurrentPage(page)}
                                        shape="rounded"
                                        color="secondary"
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <>
                            <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/noList.png`}
                                    alt="No List"
                                    className="w-[486px] h-[278px] "
                                />
                                <p className="my-[24px] ">
                                    {t('The space is empty . Add a Notification to get started.')}
                                </p>
                                <div className="flex gap-2.5 justify-center text-base font-medium">
                                    <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                                        <div
                                            className="flex gap-2"
                                            onClick={() => {
                                                setSelectedNotification({});
                                                setShowAddNotification(true);
                                            }}>
                                            <div className="">
                                                <span className="mr-2">
                                                    <i className="fa-solid fa-plus"></i>
                                                </span>
                                                {t('Add Notification')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <RightSidePanel
                CustomClass="fixed md:w-[45%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showAddNotification}
                setIsShow={setShowAddNotification}
                ModalTitle={selectedNotification?.isEdit ? "Edit Notification" : selectedNotification?.isView ? "View Notification" : "Add Notification"}
                Component={
                    <AddNotification setIsShow={setShowAddNotification} selectedNotification={selectedNotification} onAdd={onSearch} />
                }
            />
            <RightSidePanel
                CustomClass="fixed md:w-[45%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
                isShow={showSendNotification}
                setIsShow={setShowSendNotification}
                ModalTitle={"Send Notification"}
                Component={
                    <SendNotification setIsShow={setShowSendNotification} selectedNotification={selectedNotification} onSend={onSearch} />
                }
            />
        </div>
    )
}

export default ManageNotifications