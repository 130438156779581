import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { upsertBsp } from "../../store/actions/adminAction";
import { useTranslation } from "react-i18next";

function AddBsp({ setIsShow, onAdd, isEdit = false, defaultBsp = {} }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [Bsp, setBsp] = useState(
    defaultBsp ?? { integrationType: "GRAPH_API", config: {} }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!Bsp?.integrationType) {
      Bsp.integrationType = "GRAPH_API";
    }
    dispatch(
      upsertBsp(Bsp, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-12 gap-3 w-full">
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Name')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Name")}
            value={Bsp?.name}
            required
            onChange={(e) => setBsp({ ...Bsp, name: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Type')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <select
            required
            className="border border-gray-300 rounded-md w-full py-2 h-10 px-4 cursor-pointer text-[14px]"
            value={Bsp?.integrationType}
            onChange={(e) => {
              setBsp({ ...Bsp, integrationType: e.target.value });
            }}
          >
            <option value="GRAPH_API" selected>
              {t("Cloud Api")}
            </option>
            <option value="PINNACLE">{t("Pinnacle")}</option>
          </select>
        </div>
        <div className="col-span-10">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Url')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter url")}
            value={Bsp?.url}
            onChange={(e) => setBsp({ ...Bsp, url: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-2">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Api Version')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("version")}
            value={Bsp?.apiVersion}
            onChange={(e) => setBsp({ ...Bsp, apiVersion: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('App Id')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter App Id")}
            value={Bsp?.appId}
            onChange={(e) => setBsp({ ...Bsp, appId: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('App Secret')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter App Secret")}
            value={Bsp?.appSecret}
            required
            onChange={(e) => setBsp({ ...Bsp, appSecret: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Business Id')} <span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Business Id")}
            value={Bsp?.businessId}
            required
            onChange={(e) => setBsp({ ...Bsp, businessId: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Solution Id')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Business Id")}
            value={Bsp?.config?.solutionId}
            required
            onChange={(e) =>
              setBsp({
                ...Bsp,
                config: { ...Bsp?.config, solutionId: e.target.value },
              })
            }
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>
              {t('Config Id')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Business Id")}
            value={Bsp?.config?.configId}
            required
            onChange={(e) =>
              setBsp({
                ...Bsp,
                config: { ...Bsp?.config, configId: e.target.value },
              })
            }
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
          />
        </div>
        <div className="col-span-4 item-end mt-[2px]">
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              onChange={(e) => {
                setBsp({ ...Bsp, active: e.target.checked === true });
              }}
              checked={Bsp?.active}
              className="sr-only peer"
            />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 ">
              {t('Active')}
            </span>
          </label>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <button
          type="button"
          className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
          onClick={() => setIsShow(false)}
        >
          {t('Cancel')}
        </button>
        <button
          type="submit"
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
        >
          {t('Save')}
        </button>
      </div>
    </form>
  );
}

export default AddBsp;
