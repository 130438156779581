import React, { useEffect, useState } from "react";
import { SHOW_SNACKBAR } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerBank,
  savePartnerBank,
} from "../../store/actions/partnerAction";
import { useTranslation } from "react-i18next";

function BankDetails({ showSidebar }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [bank, setBank] = useState({});
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    if (globalReducer.loginInfo?.partnerId) {
      dispatch(getPartnerBank(globalReducer.loginInfo?.partnerId, setBank));
    }
  }, [globalReducer.loginInfo?.partnerId]);

  const onSaveSubmit = (e) => {
    e.preventDefault();
    bank.partnerId = globalReducer.loginInfo?.partnerId;
    bank.partnerUserUid = globalReducer.loginInfo?.uid;
    dispatch(
      savePartnerBank(bank, (a) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "success",
            message: "saved successfully",
            open: true,
          },
        });
      })
    );
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${
          showSidebar ? "fullWidth" : "fullWidth1"
        } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="my-auto font-medium text-black cursor-pointer text-left">
            <div className="text-[14px] font-medium">{t('Bank Details')}</div>
            {/* <label className="text-[12px] text-gray-600">
                            Team information like name, avatar changes etc
                        </label> */}
          </div>
          <div className="flex gap-2"></div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <form onSubmit={onSaveSubmit}>
          <div className="px-5 pt-5 mt-[6rem] mb-[20px]">
            <div className="grid grid-cols-12 gap-4 border-2 p-3 rounded-lg">
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t('Bank Name')}<span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={bank?.bankName}
                  required
                  placeholder={t("Enter Bank Name")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setBank({ ...bank, bankName: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t('Account Name')}<span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={bank?.accountName}
                  required
                  placeholder={t("Enter Account Name")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setBank({ ...bank, accountName: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t('Account Number')}
                    <span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={bank?.accountNumber}
                  required
                  placeholder={t("Enter Account Number")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setBank({ ...bank, accountNumber: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>
                    {t('Branch')}<span className="text-red-500 text-[14px]">*</span>
                  </label>
                </div>
                <input
                  type="text"
                  value={bank?.branch}
                  required
                  placeholder={t("Enter Branch")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setBank({ ...bank, branch: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <button
                type="submit"
                className="min-w-[150px] mt-2 text-center  py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              >
                {t('Save')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BankDetails;
