import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  liveChatUpdateContacts,
  sendLiveChatMessage,
} from "../../store/actions/tenantAction";
import EmojiPicker from "emoji-picker-react";
import PopUp from "../../components/PopUp";
import FileUploader from "../../components/FileUploader";
import LocationPicker from "react-leaflet-location-picker";
import { getAddress } from "../../utils/helper";
import AudioRecorder from "../../components/AudioRecorder";
import { mediaUploader } from "../../store/actions/globalAction";
import { SHOW_SNACKBAR } from "../../utils/types";
import { useTranslation } from "react-i18next";

function SendWhatsAppMessageView({ selectedContact, setSelectedContact, canSendMsg, setSendMessageTemplate }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSendEnable, setIsSendEnable] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showSelectLocation, setShowSelectLocation] = useState(false);
  const [showAudioRecord, setShowAudioRecord] = useState(false);
  const [audioData, setAudioData] = useState({});
  const [mapLocation, setMapLocation] = useState({
    latitude: 21.4292064,
    longitude: 50.852764,
  });
  const [uploadFileData, setUploadFileData] = useState();
  const [type, setType] = useState();
  const [contactNotes, setContactNotes] = useState({});
  const [typedMessage, setTypedMessage] = useState("");
  const [bodyTextCurrentIndex, setBodyTextCurrentIndex] = useState("");
  const dropdownEmojiRef = useRef(null);
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownEmojiRef.current &&
        !dropdownEmojiRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const onSendMessage = () => {
    setIsSending(true);
    setIsSendEnable(false);
    dispatch(
      sendLiveChatMessage(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          contact: selectedContact.contact,
          contactId: selectedContact.contact?.id,
          tenantWabaId: globalReducer.waba?.id,
          messageType: "LIVE_CHAT",
          type: "text",
          message: { text: { body: typedMessage } },
        },
        (a) => {
          setTypedMessage("");
          setIsSending(false);
        },
        false
      )
    );
  };

  const onFileSend = async (mediaId) => {
    let messageData = { id: uploadFileData?.id };
    if (type === "location") {
      const address = await getAddress(
        mapLocation?.latitude,
        mapLocation?.longitude
      );
      mapLocation.address = address.display_name;
      mapLocation.name = address.name;
      messageData = mapLocation;
    } else if (type === "audio") {
      messageData = { id: mediaId };
    } else if (type === "document") {
      messageData = { id: uploadFileData?.id, caption: uploadFileData?.filename };
    }
    dispatch(
      sendLiveChatMessage(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          contact: selectedContact.contact,
          contactId: selectedContact.contact?.id,
          tenantWabaId: globalReducer.waba?.id,
          messageType: "LIVE_CHAT",
          type: type,
          message: { [type]: messageData },
        },
        (a) => {
          setShowFileUpload(false);
          setShowSelectLocation(false);
          setShowAudioRecord(false);
        }
      )
    );
  };

  const sendAudioMessage = () => {
    const formData = new FormData();
    const audioFile = new File(
      [audioData.audioBlob],
      `recording${new Date().getTime()}.mp3`,
      { type: "audio/mp3" }
    );
    formData.append("file", audioFile);
    setIsSendEnable(false);
    dispatch(
      mediaUploader(
        formData,
        globalReducer.loginInfo?.tenantId,
        globalReducer.waba?.id,
        (a) => {
          if (a) {
            onFileSend(a?.id);
          }
        }
      )
    );
  };

  const insertText = (index, text, newText) => {
    return `${text.slice(0, index)}${newText}${text.slice(index)}`;
  };

  const onSaveNotes = () => {
    const data = {
      ids: [selectedContact?.id],
      detail: selectedContact?.detail,
    };
    dispatch(
      liveChatUpdateContacts(data, (a) => {
        dispatch({
          type: SHOW_SNACKBAR,
          payload: { type: "success", message: `saved`, open: true },
        });
      })
    );
  };

  const pointMode = {
    banner: true,
    control: {
      values: [[mapLocation?.latitude, mapLocation?.longitude]],
      onClick: async (point) => {
        setMapLocation({ latitude: point[0], longitude: point[1] });
        setIsSendEnable(true);
      },
      onRemove: (point) =>
        console.log("I've just been clicked for removal :(", point),
    },
  };
  const circleMode = { banner: false };
  return (
    <div className="w-full h-[25%] bg-[#FFFFFF] border-[0.8px] border-[#E5E7EB] overflow-hidden rounded-[8px]">
      <div className="absolute z-99 top-5" ref={dropdownEmojiRef}>
        <EmojiPicker
          open={showEmojiPicker}
          autoFocusSearch
          onEmojiClick={(em) => {
            setTypedMessage(
              insertText(
                bodyTextCurrentIndex ?? 0,
                typedMessage ?? "",
                em.emoji
              )
            );
            setBodyTextCurrentIndex(
              (bodyTextCurrentIndex ?? 0) + em.emoji?.length
            );
          }}
        />
      </div>
      <div className="border-b-[0.8px] h-[23%] bg-[#F9FAFB] border-[#E5E7EB] px-[20px] flex gap-3 items-end">
        <div
          onClick={() => setContactNotes({ ...contactNotes, show: false })}
          className={`${contactNotes?.show ? "text-gray-500" : "text-theme border-theme"
            } font-semibold border-b-[2px] px-0.5 py-1 cursor-pointer`}
        >
          {t("Chat")}
        </div>
        <div
          onClick={() => setContactNotes({ ...contactNotes, show: true })}
          className={`${contactNotes?.show ? "text-theme border-theme" : "text-gray-500"
            } font-semibold border-b-[2px] px-0.5 py-1 cursor-pointer`}
        >
          {t("Notes")}
        </div>
      </div>
      {contactNotes?.show ? (
        <>
          <div className="px-[20px] py-[5px] h-[50%] bg-white">
            <textarea
              value={selectedContact?.detail?.notes}
              onChange={(e) => {
                setSelectedContact({
                  ...selectedContact,
                  detail: { ...selectedContact?.detail, notes: e.target.value },
                });
              }}
              className="text-[#6B7280] w-full border-none outline-none resize-none skipthis"
              style={{ height: "100%" }}
              placeholder="Type to save notes."
            ></textarea>
          </div>
          <div className="px-[20px] h-[20%] pb-[5px] flex items-center justify-end">
            <button
              type="button"
              className={`bg-theme text-[#FFFFFF] rounded-[6px] px-[12px] py-[4px]`}
              onClick={onSaveNotes}
            >
              {t("Save")}
            </button>
          </div>
        </>
      ) : (
        <>
          <div>
            <div
              disabled={!(canSendMsg && selectedContact?.ticketStatus === "OPEN")}
              className="px-[20px] py-[5px] h-[50%] bg-white"
            >
              <textarea
                value={typedMessage}
                onChange={(e) => {
                  setTypedMessage(e.target.value);
                  setIsSendEnable(e.target.value ? true : false);
                }}
                className="text-[#6B7280] w-full border-none outline-none resize-none skipthis"
                style={{ height: "100%" }}
                placeholder="Type to send a new message."
                onClick={(e) => setBodyTextCurrentIndex(e.target.selectionStart)}
                onKeyUp={(e) => setBodyTextCurrentIndex(e.target.selectionStart)}
              ></textarea>
            </div>
            {!canSendMsg ? <div className="relative flex items-center w-full justify-center z-50">
              <div className="absolute rounded-lg mt-[-22px] bg-[#FFFFFF]">
                <div className="flex justify-center pb-5">
                  <button onClick={() => setSendMessageTemplate(true)} className="bg-red-500 text-white px-4 py-2 rounded">
                    Send a message template
                  </button>
                </div>
                <p className="text-[12px]">The 24 hr session is finished. Please send a message template to restart the conversation.</p>
              </div>
            </div> :
              selectedContact?.ticketStatus !== "OPEN" && <div className="relative flex items-center w-full justify-center z-50">
                <div className="absolute rounded-lg mt-[-22px] bg-[#FFFFFF]">
                  <p className="text-[12px]">Please open a ticket to send a message.</p>
                </div>
              </div>
            }
          </div>
          <div
            disabled={!(canSendMsg && selectedContact?.ticketStatus === "OPEN")}
            className="px-[20px] h-[20%] pb-[5px] flex items-center justify-between"
          >
            <div className="flex gap-[20px] items-center h-full">
              <div onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                <i className="fa-solid fa-face-smile text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
              </div>
              <div
                onClick={() => {
                  setIsSendEnable(false);
                  setShowFileUpload(true);
                  setType("image");
                }}
              >
                <i className="fa-solid fa-image text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
              </div>
              <div
                onClick={() => {
                  setIsSendEnable(false);
                  setShowFileUpload(true);
                  setType("video");
                }}
              >
                <i className="fa-solid fa-video text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
              </div>
              <div
                onClick={() => {
                  setIsSendEnable(false);
                  setShowFileUpload(true);
                  setType("document");
                }}
              >
                <i className="fa-solid fa-file text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
              </div>
              <div
                onClick={() => {
                  setIsSendEnable(false);
                  setShowAudioRecord(true);
                  setType("audio");
                }}
              >
                <i className="fa-solid fa-microphone text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
              </div>
              <div
                onClick={() => {
                  setIsSendEnable(false);
                  setShowSelectLocation(true);
                  setType("location");
                }}
              >
                <i className="fa-solid fa-location-dot text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
              </div>
              {/* <div>
                    <i className="fa-solid fa-bag-shopping text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
                    </div>
                    <div>
                        <i className="fa-solid fa-database text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
                    </div>
                    <div>
                        <i className="fa-solid fa-message text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
                    </div>
                    <div>
                        <i className="fa-solid fa-comment-medical text-[#9CA3AF] cursor-pointer hover:text-theme transition-all"></i>
                    </div> */}
            </div>
            <div>
              <button
                type="button"
                disabled={!isSendEnable}
                className={`${isSendEnable
                  ? "bg-theme text-[#FFFFFF]"
                  : "bg-[#E5E7EB] text-[#9CA3AF]"
                  } rounded-[6px] px-[12px] py-[4px]`}
                onClick={onSendMessage}
              >
                {t("Send")}
              </button>
            </div>
          </div>
        </>
      )}
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Upload File")}
          </span>
        }
        isShowModal={showFileUpload}
        setIsShowModal={setShowFileUpload}
        ModalBody={
          <div className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto">
            <FileUploader
              wabaId={globalReducer?.waba?.id}
              tenantId={globalReducer?.waba?.tenantId}
              isSessionUploder={false}
              type={type}
              name={uploadFileData?.filename}
              selectedFileUrl={uploadFileData?.thumbnailUrl}
              filePrefix="template_media"
              onUploaded={(file) => {
                setUploadFileData({ ...file, header_handle: [file?.h] });
                setIsSendEnable(true);
              }}
            />
            <div className="flex items-center justify-end mt-3">
              <button
                type="button"
                disabled={!isSendEnable}
                className={`${isSendEnable
                  ? "bg-theme text-[#FFFFFF]"
                  : "bg-[#E5E7EB] text-[#9CA3AF]"
                  } rounded-[6px] px-[12px] py-[4px]`}
                onClick={onFileSend}
              >
                {t("Send")}
              </button>
            </div>
          </div>
        }
        size="xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Select Location")}
          </span>
        }
        isShowModal={showSelectLocation}
        setIsShowModal={setShowSelectLocation}
        ModalBody={
          <div className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto mapDesign">
            <LocationPicker pointMode={pointMode} circleMode={circleMode} />
            <div className="flex items-center justify-end mt-3">
              <button
                type="button"
                disabled={!isSendEnable}
                className={`${isSendEnable
                  ? "bg-theme text-[#FFFFFF]"
                  : "bg-[#E5E7EB] text-[#9CA3AF]"
                  } rounded-[6px] px-[12px] py-[4px]`}
                onClick={onFileSend}
              >
                {t("Send")}
              </button>
            </div>
          </div>
        }
        size="xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Audio Recorder")}
          </span>
        }
        isShowModal={showAudioRecord}
        setIsShowModal={setShowAudioRecord}
        ModalBody={
          <div className="px-4 pb-4">
            <AudioRecorder
              onStop={(data) => {
                setAudioData(data);
                setIsSendEnable(true);
              }}
            />
            <div className="flex items-center justify-end">
              <button
                type="button"
                disabled={!isSendEnable}
                className={`${isSendEnable
                  ? "bg-theme text-[#FFFFFF]"
                  : "bg-[#E5E7EB] text-[#9CA3AF]"
                  } rounded-[6px] px-[12px] py-[4px]`}
                onClick={sendAudioMessage}
              >
                {isSending ? "Sending..." : "Send"}
              </button>
            </div>
          </div>
        }
        size="xl"
      />
    </div>
  );
}

export default SendWhatsAppMessageView;
