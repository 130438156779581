const ROUTES = {
  PARTNER: "/partner",
  TENANT: "/tenant",
  LOGIN: "/",
  GLOBAL_ADMIN: "/admin",
  UN_AUTH: "/unauthorised",
  SIGNUP: "register"
}

export default ROUTES;
