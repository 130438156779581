import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../store/actions/partnerAction";
import {
  getTenantWaba,
  addWalletTransfers,
} from "../../store/actions/tenantAction";
import { fetchIP } from "../../store/actions/globalAction";
import { SHOW_SNACKBAR } from "../../utils/types";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";

function AddTenantTransfer({ setIsShow, onAdd }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ipAddress, setIpAddress] = useState(null);
  const [fromCompanyList, setFromCompanyList] = useState([]);
  const [toCompanyList, setToCompanyList] = useState([]);
  const [fromWabas, setFromWabas] = useState([]);
  const [toWabas, setToWabas] = useState([]);
  const [wallet, setWallet] = useState({});

  useEffect(() => {
    fetchIP().then((tmp) => setIpAddress(tmp?.ip));
    dispatch(
      getTenants(
        {
          criteria: { active: true },
          count: true,
          size: 500,
          offset: 0,
        },
        (d) => {
          setFromCompanyList(d?.data ?? []);
          setToCompanyList(d?.data ?? []);
          if (d?.data?.length > 0) {
            setWallet({
              ...wallet,
              fromTenantId: d?.data[0]?.id,
              toTenantId: d?.data[0]?.id,
            });
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    if (wallet?.fromTenantId) {
      dispatch(
        getTenantWaba(
          {
            tenantId: wallet?.fromTenantId,
            criteria: { active: true },
          },
          (d) => {
            setFromWabas(d?.data ?? []);
            //setWallet({ ...wallet, fromTenantWabaId: null, currencyCode: null });
            if (d?.data?.length > 0) {
              setWallet({
                ...wallet,
                fromTenantWabaId: d?.data[0]?.id,
                currencyCode: d?.data[0]?.currencyCode,
              });
            }
          }
        )
      );
    }
  }, [wallet?.fromTenantId]);

  useEffect(() => {
    if (wallet?.toTenantId) {
      dispatch(
        getTenantWaba(
          {
            tenantId: wallet?.toTenantId,
            criteria: { active: true },
          },
          (d) => {
            setToWabas(d?.data ?? []);
            //setWallet({ ...wallet, toTenantWabaId: null, currencyCode: null });
            if (d?.data?.length > 0) {
              setWallet({
                ...wallet,
                toTenantWabaId: d?.data[0]?.id,
                currencyCode: d?.data[0]?.currencyCode,
              });
            }
          }
        )
      );
    }
  }, [wallet?.toTenantId]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      wallet?.fromTenantId === wallet?.toTenantId &&
      wallet?.fromTenantWabaId === wallet?.toTenantWabaId
    ) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "Please select different WABA account.",
          open: true,
        },
      });
      return;
    }
    if (!wallet?.currencyCode || wallet?.currencyCode == "") {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "currency should be same for both WABA account.",
          open: true,
        },
      });
      return;
    }
    if (!wallet?.fromTenantWabaId || wallet?.fromTenantWabaId == 0) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "Please select from WABA account.",
          open: true,
        },
      });
      return;
    }
    if (!wallet?.toTenantWabaId || wallet?.toTenantWabaId == 0) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "Please select to WABA account.",
          open: true,
        },
      });
      return;
    }
    wallet.ipAddress = ipAddress;
    dispatch(
      addWalletTransfers(wallet, (a) => {
        setIsShow(false);
        onAdd(a);
      })
    );
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="px-6 pb-6 pt-3 max-h-[580px] overflow-auto">
      <form onSubmit={onSubmit}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-12 text-[14px] font-bold">{t('From')}</div>
          <div className={`flex flex-col col-span-6`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Company')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <TypeableSelect list={fromCompanyList} selected={wallet} selectedValueField="fromTenantId" labelField="name" valueField="id" required={true} placeholder={t("Company Name")} height="40px" onSelect={(s) => {
              setWallet({ ...wallet, fromTenantId: s?.id });
            }} />
          </div>
          <div className={`flex flex-col col-span-6`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Waba')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select
              required
              value={wallet?.fromTenantWabaId}
              className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
              onChange={(e) => {
                if (wallet?.toTenantWabaId && wallet?.toTenantWabaId !== 0) {
                  const fromwa = fromWabas.find((f) => f.id == e.target.value);
                  const towa = toWabas.find(
                    (f) => f.id == wallet?.toTenantWabaId
                  );
                  if (fromwa?.currencyCode !== towa?.currencyCode) {
                    dispatch({
                      type: SHOW_SNACKBAR,
                      payload: {
                        type: "error",
                        message:
                          "currency should be same for both WABA account.",
                        open: true,
                      },
                    });
                    return;
                  } else {
                    setWallet({
                      ...wallet,
                      fromTenantWabaId: e.target.value,
                      currencyCode: fromwa?.currencyCode,
                    });
                  }
                } else {
                  setWallet({ ...wallet, fromTenantWabaId: e.target.value });
                }
              }}
            >
              <option value={""} selected disabled>
                {t('Seelct Waba')}
              </option>
              {fromWabas?.map((w, i) => (
                <option
                  value={w.id}
                  key={i}
                >{`${w.teamName} - +${w.phoneCode} ${w.phone}`}</option>
              ))}
            </select>
          </div>
          <div className="col-span-12 text-[14px] font-bold">To</div>
          <div className={`flex flex-col col-span-6`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Company')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <TypeableSelect list={toCompanyList} selected={wallet} selectedValueField="toTenantId" labelField="name" valueField="id" required={true} placeholder={t("Company Name")} height="40px" onSelect={(s) => {
              setWallet({ ...wallet, toTenantId: s?.id });
            }} />
          </div>
          <div className={`flex flex-col col-span-6`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Waba')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select
              required
              value={wallet?.toTenantWabaId}
              className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
              onChange={(e) => {
                if (
                  wallet?.fromTenantWabaId &&
                  wallet?.fromTenantWabaId !== 0
                ) {
                  const fromwa = fromWabas.find(
                    (f) => f.id == wallet?.fromTenantWabaId
                  );
                  const towa = toWabas.find((f) => f.id == e.target.value);
                  if (fromwa?.currencyCode !== towa?.currencyCode) {
                    dispatch({
                      type: SHOW_SNACKBAR,
                      payload: {
                        type: "error",
                        message:
                          "currency should be same for both WABA account.",
                        open: true,
                      },
                    });
                    return;
                  } else {
                    setWallet({
                      ...wallet,
                      toTenantWabaId: e.target.value,
                      currencyCode: towa?.currencyCode,
                    });
                  }
                } else {
                  setWallet({ ...wallet, toTenantWabaId: e.target.value });
                }
              }}
            >
              <option value={""} selected disabled>
                {t('Seelct Waba')}
              </option>
              {toWabas?.map((w, i) => (
                <option
                  value={w.id}
                  key={i}
                >{`${w.teamName} - +${w.phoneCode} ${w.phone}`}</option>
              ))}
            </select>
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Amount')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <div className="flex border border-gray-300 rounded-md text-[14px]">
              <label className="border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-1.5">
                {wallet?.currencyCode}
              </label>
              <input
                type="number"
                placeholder={t("Enter Amount")}
                value={wallet?.amount}
                data-maxlength={8}
                onInput={OnInput}
                onChange={(e) =>
                  setWallet({ ...wallet, amount: e.target.value })
                }
                className="border-0 rounded-md py-1 w-[100%] h-9 text-[13px]"
                required
              />
            </div>
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1"><label>{t('Remarks')}<span className="text-red-500 text-[14px]">*</span></label></div>
            <textarea
              required
              value={wallet?.remarks}
              rows={3}
              placeholder={t("Remarks")}
              className="border border-gray-300 w-full rounded-md py-1 px-3 text-[13px] textArea"
              onChange={(e) =>
                setWallet({ ...wallet, remarks: e.target.value })
              }
            ></textarea>
          </div>
        </div>
        <div className={`flex justify-end gap-2 mt-6`}>
          <button
            type="submit"
            className="justify-center max-w-[200px] items-center flex px-5 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
          >
            {t('Submit')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTenantTransfer;
