import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getFile, uploadFile } from '../store/actions/globalAction';
import { v4 as uuidv4 } from 'uuid';

function Uploader({ onUploaded, filePrefix, selectedFileUrl, required, className = "min-w-[355px]", accept = "image/png,image/jpeg,.pdf, .doc, .docx" }) {
    let dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [previewfile, setPreviewfile] = useState();
    const [error, setError] = useState(null);
    const IMAGE_MAX_FILE_SIZE = 10;

    useEffect(() => {
        if (selectedFileUrl && selectedFileUrl != "" && (selectedFileUrl?.toLowerCase()?.includes("png") || selectedFileUrl?.toLowerCase()?.includes("jpeg"))) {
            getFile(selectedFileUrl).then(sfl => { setPreviewfile(sfl) });
        }
    }, [selectedFileUrl])

    const handleUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            let fileSize = IMAGE_MAX_FILE_SIZE;
            if (files[0].size > (fileSize * 1024 * 1024)) {
                setError(`File size exceeds the limit of ${fileSize} MB.`);
            } else {
                setError(null);
                const formData = new FormData();
                files.forEach((file) => {
                    formData.append('file', file);
                });
                setFileName(files[0].name);
                setUploading(true);
                const newUuid = uuidv4();
                const key = `${(filePrefix ? (filePrefix + "/") : "")}${newUuid}.${files[0].name.split('.').pop()}`;
                dispatch(uploadFile(formData, key, a => {
                    setUploading(false);
                    if (a) {
                        onUploaded(key);
                    }
                }));
            }
        }
    };

    const removeFile = () => {
        onUploaded(null);
        setFileName("");
    }
    return (
        <div className={`border border-gray-400 rounded-md border-dashed ${className}`}>
            <div>{selectedFileUrl ?
                previewfile ? <>
                    <div className='flex justify-end relative'>
                        <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                        </button>
                    </div>
                    <img src={previewfile} className="w-fit rounded-t-lg border-none" />
                </> : <>
                    <div className='flex justify-end relative'>
                        <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                        </button>
                    </div>
                    <div className='gap-2 p-2 text-center py-2'>
                        <i className="fa-solid fa-file text-[30px] text-[#8d99ae] hover:text-theme"></i>
                        <p className="text-center text-[14px] mt-4 text-[#1E40AF]"> {selectedFileUrl?.split("/")?.pop()}</p>
                    </div>
                </> :
                <label tabIndex={-1} htmlFor="UploadImage" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[130px]">
                    <input id="UploadImage" onChange={handleUpload} required={required} accept={accept} type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                    <div className='flex gap-2'>
                        <i className="fa-solid fa-image text-[24px] hover:text-theme"></i>
                        {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                    </div>
                    <p className="w-[80%] text-center text-[14px]">
                        Upload or drag &amp; drop your document here. Maximum size 10 MB allowed &amp;
                        document type must be {accept}.
                    </p>
                    <p className="text-center text-[14px] text-theme"> {error}</p>
                    <p className="text-center text-[14px] text-[#1E40AF]"> {fileName ?? selectedFileUrl}</p>
                </label>}
            </div>
        </div>
    )
}

export default Uploader