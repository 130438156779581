import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignToTenantPlan,
  searchPLans,
} from "../../store/actions/partnerAction";
import {
  getTenantPlans,
  tenantPlanUpsert,
} from "../../store/actions/tenantAction";
import moment from "moment";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import { Autocomplete, TextField } from "@mui/material";
import { allIntegrationList } from "../../utils/helper";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";

function MapTenantPlan({ setIsShow, onAdd, tenant = {}, isEdit = false }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [allPlan, setAllPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPlanCode, setSelectedPlanCode] = useState();
  const [tenantPlan, setTenantPlan] = useState({});
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(
      searchPLans({
        criteria: { active: true },
        size: 1000,
        offset: 0
      }, (a) => {
        setAllPlan(a.data);
      })
    );
    dispatch(
      getTenantPlans({ tenantId: tenant.id }, (a) => {
        if (a.data && a.data?.length > 0) {
          setTenantPlan(a.data[0]);
          setSelectedPlan(a.data[0]);
          setSelectedPlanCode(a.data[0].planCode);
        }
      })
    );
  }, []);

  const assignPlan = () => {
    tenantPlan.tenantId = tenant.id;
    tenantPlan.planCode = selectedPlan.code;
    tenantPlan.planName = selectedPlan.name;
    tenantPlan.fromDate = new Date();
    const date = new Date();
    date.setMonth(new Date().getMonth() + selectedPlan.durationMonth);
    tenantPlan.toDate = date;
    tenantPlan.planRate = selectedPlan.rate;
    tenantPlan.billingFrequency = selectedPlan.billingFrequency;
    tenantPlan.countryCode = selectedPlan.countryCode;
    tenantPlan.currency = selectedPlan.currency;
    tenantPlan.permissions = selectedPlan.permissions;
    tenantPlan.numContacts = selectedPlan.numContacts;
    tenantPlan.numLiveChatAgent = selectedPlan.numLiveChatAgent;
    tenantPlan.numSessions = selectedPlan.numSessions;
    tenantPlan.numUsers = selectedPlan.numUsers;
    tenantPlan.numWabaNumbers = selectedPlan.numWabaNumbers;
    tenantPlan.messageParMinute = selectedPlan.messageParMinute;
    tenantPlan.availableIntegrations = selectedPlan.availableIntegrations;
    setTenantPlan({ ...tenantPlan });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      tenantPlanUpsert(tenantPlan, (a) => {
        // dispatch(getTenantPlans({ tenantId: tenant.id }, t => {
        //     if (a.data && a.data?.length > 0)
        //         setTenantPlan(a.data[0])
        // }));
        onAdd(a);
        setIsShow(false);
      })
    );
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="height30 flex flex-col justify-between pb-3"
      onSubmit={handleSubmit}
    >
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-3 w-full items-end mapPlan">
          <div className="col-span-10">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Plans')}</label>
            </div>
            <TypeableSelect height={"40px"} list={allPlan} selected={{ code: selectedPlanCode }} selectedValueField="code" labelField="name" valueField="code" required={true} placeholder={t("Select Plan...")} onSelect={(s) => {
              setSelectedPlan(s);
              setSelectedPlanCode(s?.code);
            }} />
          </div>
          <div className="col-span-2">
            <button
              type="button"
              className="justify-center w-full items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
              onClick={assignPlan}
            >
              {t('Assign')}
            </button>
          </div>
          {tenantPlan?.planCode && <div className="col-span-12">
            <label className="font-medium text-[13px] my-[10px] text-[#2A2E34]">{t('Active Plans')}</label>
            <div className="grid grid-cols-12 gap-3 w-full mt-2">
              <div className="col-span-4">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Plan Name')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="text"
                  readOnly
                  placeholder={t("Enter Plan Name")}
                  value={tenantPlan?.planName}
                  onChange={(e) =>
                    setTenantPlan({ ...tenantPlan, planName: e.target.value })
                  }
                  className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
                  required
                />
              </div>
              <div className="col-span-4">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('FromDate')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="date"
                  value={moment(tenantPlan?.fromDate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setTenantPlan({
                      ...tenantPlan,
                      fromDate: new Date(e.target.value),
                    })
                  }
                  className="border border-gray-300 rounded-md py-1 w-[100%] h-9 text-[13px]"
                  required
                />
              </div>
              <div className="col-span-4">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('ToDate')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="date"
                  value={moment(tenantPlan?.toDate).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setTenantPlan({
                      ...tenantPlan,
                      toDate: new Date(e.target.value),
                    })
                  }
                  className="border border-gray-300 rounded-md py-1 w-[100%] h-9 text-[13px]"
                  required
                />
              </div>
              <div className="col-span-4">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Amount')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <div className="flex border border-gray-300 rounded-md text-[14px] max-h-[36px]">
                  <label className="border-r border-gray-300 min-w-[40px] bg-gray-100 text-[14px] p-2 h-full">
                    {tenantPlan?.currency}
                  </label>
                  <input
                    type="number"
                    placeholder="Enter Amount"
                    value={tenantPlan?.planRate}
                    data-maxlength={8}
                    onInput={OnInput}
                    onChange={(e) =>
                      setTenantPlan({ ...tenantPlan, planRate: e.target.value })
                    }
                    className="border-0 rounded-md py-2 w-[100%] text-[13px] h-full"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col col-span-4">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Billing Frequency')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <select required
                  className="border border-gray-300 rounded-md py-1 px-4 cursor-pointer text-[13px] max-h-[36px] h-[36px]"
                  value={tenantPlan?.billingFrequency}
                  onChange={(e) => {
                    setTenantPlan({
                      ...tenantPlan,
                      billingFrequency: e.target.value,
                    });
                  }}
                >
                  <option value="MONTHLY" selected>
                    {t('Monthly')}
                  </option>
                  <option value="QUARTERLY">{t('Quarterly')}</option>
                  <option value="YEARLY">{t('Yearly')}</option>
                </select>
              </div>
              <div className="flex flex-col col-span-4">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Country Name')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <SelectCountry
                  required={true}
                  selectedCode={tenantPlan?.countryCode}
                  countryList={globalReducer.country}
                  onSelectCountry={(country) => {
                    if (country) {
                      tenantPlan.countryCode = country.code;
                      tenantPlan.currency = country.currencyCode;
                    } else {
                      delete tenantPlan.countryCode;
                      delete tenantPlan.currency;
                    }
                    setTenantPlan({ ...tenantPlan });
                  }}
                  placeholder={t('Select Country')}
                />
              </div>
              <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
                <div className="flex justify-between">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          if (
                            !tenantPlan?.permissions?.some(
                              (f) => f == "FINANCE_ACCESS"
                            )
                          ) {
                            tenantPlan.permissions?.push("FINANCE_ACCESS");
                            setTenantPlan({ ...tenantPlan });
                          }
                        } else {
                          setTenantPlan({
                            ...tenantPlan,
                            permissions: [
                              ...tenantPlan?.permissions?.filter(
                                (f) => f != "FINANCE_ACCESS"
                              ),
                            ],
                          });
                        }
                      }}
                      checked={tenantPlan?.permissions?.some(
                        (f) => f == "FINANCE_ACCESS"
                      )}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900">
                      {t('Finance & Report')}
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
                <div className="flex justify-between">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          if (
                            !tenantPlan?.permissions?.some((f) => f == "API")
                          ) {
                            tenantPlan.permissions?.push("API");
                            setTenantPlan({ ...tenantPlan });
                          }
                        } else {
                          setTenantPlan({
                            ...tenantPlan,
                            permissions: [
                              ...tenantPlan?.permissions?.filter(
                                (f) => f != "API"
                              ),
                            ],
                          });
                        }
                      }}
                      checked={tenantPlan?.permissions?.some((f) => f == "API")}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900">
                      {t('API')}
                    </span>
                  </label>
                  <div className="flex gap-2 items-center">
                    {tenantPlan?.permissions?.some((f) => f == "API") && (
                      <>
                        <div className="text-midBlack text-[13px] font-normal">
                          {t('Messages/Minute')}<span className="text-red-500 text-[14px]">*</span>
                        </div>
                        <input
                          type="number"
                          placeholder={t("Messages/Minute")}
                          value={tenantPlan?.messageParMinute}
                          data-maxlength={8}
                          onInput={OnInput}
                          onChange={(e) =>
                            setTenantPlan({
                              ...tenantPlan,
                              messageParMinute: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                          required
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
                <div className="flex justify-between">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          if (
                            !tenantPlan?.permissions?.some(
                              (f) => f == "CAMPAIGNS"
                            )
                          ) {
                            tenantPlan.permissions?.push("CAMPAIGNS");
                            setTenantPlan({ ...tenantPlan });
                          }
                        } else {
                          setTenantPlan({
                            ...tenantPlan,
                            permissions: [
                              ...tenantPlan?.permissions?.filter(
                                (f) => f != "CAMPAIGNS"
                              ),
                            ],
                          });
                        }
                      }}
                      checked={tenantPlan?.permissions?.some(
                        (f) => f == "CAMPAIGNS"
                      )}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900">
                      {t('Campaigns')}
                    </span>
                  </label>
                  <div className="flex gap-2 items-center">
                    {tenantPlan?.permissions?.some((f) => f == "CAMPAIGNS") && (
                      <>
                        <div className="text-midBlack text-[13px] font-normal">
                          {t('Number of Contacts')}<span className="text-red-500 text-[14px]">*</span>
                        </div>
                        <input
                          type="number"
                          placeholder={t("Enter Number of Contacts")}
                          value={tenantPlan?.numContacts}
                          data-maxlength={8}
                          onInput={OnInput}
                          onChange={(e) =>
                            setTenantPlan({
                              ...tenantPlan,
                              numContacts: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                          required
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
                <div className="flex justify-between">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          if (
                            !tenantPlan?.permissions?.some(
                              (f) => f == "LIVE_CHAT"
                            )
                          ) {
                            tenantPlan.permissions?.push("LIVE_CHAT");
                            setTenantPlan({ ...tenantPlan });
                          }
                        } else {
                          setTenantPlan({
                            ...tenantPlan,
                            permissions: [
                              ...tenantPlan?.permissions?.filter(
                                (f) => f != "LIVE_CHAT"
                              ),
                            ],
                          });
                        }
                      }}
                      checked={tenantPlan?.permissions?.some(
                        (f) => f == "LIVE_CHAT"
                      )}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900">
                      {t('Live Chat')}
                    </span>
                  </label>
                  <div className="flex gap-2 items-center">
                    {/* {tenantPlan?.permissions?.some((f) => f == "LIVE_CHAT") && (
                      <>
                        <div className="text-midBlack text-[13px] font-normal">
                          {t('Number of Live Chat Agent')}<span className="text-red-500 text-[14px]">*</span>
                        </div>
                        <input
                          type="number"
                          placeholder={t("Enter Number of Live Chat Agent")}
                          value={tenantPlan?.numLiveChatAgent}
                          data-maxlength={6}
                          onInput={OnInput}
                          onChange={(e) =>
                            setTenantPlan({
                              ...tenantPlan,
                              numLiveChatAgent: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                          required
                        />
                      </>
                    )} */}
                  </div>
                </div>
              </div>
              <div className="col-span-12 text-[13px] p-3 bg-[#f7f8fc] border rounded-lg">
                <div className="flex justify-between items-center">
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked === true) {
                          if (!tenantPlan?.permissions?.some((f) => f === "CHATBOT")) {
                            tenantPlan.permissions?.push("CHATBOT");
                            setTenantPlan({ ...tenantPlan });
                          }
                        } else {
                          setTenantPlan({ ...tenantPlan, permissions: [...tenantPlan?.permissions?.filter((f) => f !== "CHATBOT")] });
                        }
                      }}
                      checked={tenantPlan?.permissions?.some((f) => f === "CHATBOT")}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
                    <span className="ms-3 text-sm font-medium text-gray-900">
                      {t('Chatbot')}
                    </span>
                  </label>
                  {/* <div className="flex gap-2 items-center">
                    {tenantPlan?.permissions?.some((f) => f == "CHATBOT") && (
                      <>
                        <div className="text-midBlack text-[13px] font-normal">
                          {t('Number of Sessions')}<span className="text-red-500 text-[14px]">*</span>
                        </div>
                        <input
                          type="number"
                          placeholder={t("Enter Number of Sessions")}
                          value={tenantPlan?.numSessions}
                          data-maxlength={5}
                          onInput={OnInput}
                          onChange={(e) =>
                            setTenantPlan({
                              ...tenantPlan,
                              numSessions: e.target.value,
                            })
                          }
                          className="border border-gray-300 rounded-md py-1 h-9 text-[13px] w-[160px]"
                          required
                        />
                      </>
                    )}
                  </div> */}
                </div>
              </div>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Users')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="number"
                  placeholder={t("Enter Users")}
                  value={tenantPlan?.numUsers}
                  data-maxlength={5}
                  onInput={OnInput}
                  onChange={(e) =>
                    setTenantPlan({ ...tenantPlan, numUsers: e.target.value })
                  }
                  className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
                  required
                />
              </div>
              <div className="col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Number of WABA Account')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <input
                  type="number"
                  placeholder={t("Enter WABA")}
                  value={tenantPlan?.numWabaNumbers}
                  data-maxlength={8}
                  onInput={OnInput}
                  onChange={(e) =>
                    setTenantPlan({
                      ...tenantPlan,
                      numWabaNumbers: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded-md py-1 h-9 w-[100%] text-[13px]"
                  required
                />
              </div>
              <div className="col-span-12">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Available Integrations')}<span className="text-red-500 text-[14px]">*</span></label>
                </div>
                <Autocomplete
                  multiple={true}
                  aria-required={true}
                  value={allIntegrationList?.filter((m) =>
                    tenantPlan.availableIntegrations?.some((s) => s === m.type)
                  )}
                  options={allIntegrationList}
                  isOptionEqualToValue={(option, value) =>
                    option?.type === value?.type
                  }
                  getOptionLabel={(option) => option?.type ?? ""}
                  onChange={(e, value) => {
                    setTenantPlan({
                      ...tenantPlan,
                      availableIntegrations: value?.map((c) => c.type) ?? [],
                    });
                  }}
                  sx={{
                    "& .MuiAutocomplete-inputRoot": {
                      padding: "0px",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category"
                      className="custom-input"
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0px",
                          "&.Mui-focused fieldset": {
                            borderColor: "none",
                            border: "0px",
                          },
                          fontSize: "14px",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "#FFFFFF",
                          "& fieldset": {
                            borderColor: "#ccc",
                          },
                          "&:hover fieldset": {
                            borderColor: "#888",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#1976d2",
                          },
                        },
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>}
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white mb-3 border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="submit"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default MapTenantPlan;
