import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { changeTenantRoleStatus, tenantRoleQuery } from '../../store/actions/partnerAction';
import { Dropdown } from 'flowbite-react';
import RightSidePanel from '../../components/RightSidePanel';
import AddTenantRole from '../../layouts/partner/AddTenantRole';
import { useTranslation } from 'react-i18next';

function RoleManagement({ showSidebar }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});
  const [showAddRole, setShowAddRole] = useState(false);

  useEffect(() => {
    onSearch()
  }, []);

  const onSearch = () => {
    dispatch(tenantRoleQuery({
      size: 200,
      offset: 0,
    }, tr => {
      setRoles(tr.data);
    }));
  }

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-money-bill-transfer text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('Role Management')}</div>
          </div>
          <div className="flex gap-2">
            <button type="button" className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => { setSelectedRole({}); setShowAddRole(true); }}>
              {t('Add Role')}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-6 py-2 mt-[5rem] mb-[20px]">
          {roles && roles?.length > 0 && (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('Role Name')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] w-[160px]">
                      <div className="flex gap-2">
                        <div>{t('Status')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("View")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('Edit')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Action ')}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {roles.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {(i + 1)}
                        </td>
                        <td className="py-3 text-[13px] pl-2 font-normal">
                          {`${p.displayName}`}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.active ? (
                            <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                              Active
                            </label>
                          ) : (
                            <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                              Not Active
                            </label>
                          )}
                        </td>
                        <td className="py-3 text-[13px] font-normal mr-2">
                          <div onClick={() => { setSelectedRole({ ...p, isView: true }); setShowAddRole(true) }}>
                            <i className="fa-solid fa-eye text-[18px] text-theme cursor-pointer"></i>
                          </div>
                        </td>
                        <td className="py-3 text-[13px] font-normal mr-2">
                          {!["TENANT_ADMIN", "AGENT"].includes(p.name) &&
                            <div onClick={() => { setSelectedRole({ ...p, isEdit: true }); setShowAddRole(true) }}>
                              <i className="fa-solid fa-pen-to-square text-[18px] text-theme cursor-pointer"></i>
                            </div>}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {!["TENANT_ADMIN", "AGENT"].includes(p.name) &&
                            <Dropdown
                              inline
                              arrowIcon={false}
                              inputMode
                              as="button"
                              className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                              label={<i className="fa-solid fa-ellipsis"></i>}
                            >
                              {p?.active ? (
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    dispatch(
                                      changeTenantRoleStatus(
                                        {
                                          id: p.name,
                                          isActive: false,
                                        },
                                        onSearch
                                      )
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-link-slash pr-2"></i>
                                  {t('Deactivate')}
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    dispatch(
                                      changeTenantRoleStatus(
                                        {
                                          id: p?.name,
                                          isActive: true,
                                        },
                                        onSearch
                                      )
                                    );
                                  }}
                                >
                                  <i className="fa-solid fa-link pr-2"></i>
                                  {t('Activate')}
                                </Dropdown.Item>
                              )}
                            </Dropdown>
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showAddRole}
        setIsShow={setShowAddRole}
        ModalTitle={t(selectedRole?.isEdit ? "Edit Role" : selectedRole?.isView ? "View Details" : "Add Role")}
        Component={
          <AddTenantRole
            setIsShow={setShowAddRole}
            selectedRole={selectedRole}
            onAdd={onSearch}
          />
        }
      />
    </div>
  )
}

export default RoleManagement