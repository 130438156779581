import React, { useState } from "react";
import { receiptStatusUpdate } from "../../store/actions/tenantAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function UpdateTenantTransaction({
  setIsShow,
  statusType,
  selectedTransaction,
  onUpdate,
}) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [transaction, setTransaction] = useState({
    ...selectedTransaction,
    status: statusType,
  });

  const onUpdateSubmit = (e) => {
    e.preventDefault();
    dispatch(
      receiptStatusUpdate(transaction, (a) => {
        onUpdate(a);
        setIsShow(false);
      })
    );
  };

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="h-full flex flex-col pt-1 pb-3 px-1 justify-between"
      onSubmit={onUpdateSubmit}
    >
      <div className="h-full py-2 px-3">
        <div className={`flex flex-col mb-3`}>
          <div className="text-[#656F7D] text-[13px] font-normal mb-1">
            <label>
              {t('Status')}<span className="text-red-500 text-[14px]">*</span>
            </label>
          </div>
          <select
            required
            value={transaction?.status}
            className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
            onChange={(e) => {
              setTransaction({ ...transaction, status: e.target.value });
            }}
          >
            <option value={"REJECTED"}>{t('Reject')}</option>
            <option value={"APPROVED"}>{t('Approve')}</option>
          </select>
        </div>
        <div className="text-midBlack text-[13px] font-normal mb-2 flex justify-between">
          <label>
            {t('Remarks')}<span className="text-red-500 text-[14px]">*</span>
          </label>
          <label className="pr-2">
            {transaction?.message?.length ?? 0}/250
          </label>
        </div>
        <textarea
          className="border border-gray-300 rounded-md p-1 w-[100%] text-[14px] skipped"
          required
          rows={4}
          data-maxlength={250}
          onInput={OnInput}
          onChange={(e) => {
            setTransaction({ ...transaction, message: e.target.value });
          }}
        ></textarea>
      </div>
      <div className="justify-end flex gap-2 px-1">
        <button
          type="button"
          className="justify-center items-center min-w-[100px] flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
          onClick={() => setIsShow(false)}
        >
          {t('Cancel')}
        </button>
        <button
          type="submit"
          className="justify-center items-center min-w-[100px] flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
        >
          {transaction?.status === "REJECTED" ? t("Reject") : t("Approve")}
        </button>
      </div>
    </form>
  );
}

export default UpdateTenantTransaction;
