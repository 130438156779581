import { HttpUtil } from "../../utils/http-utils";
import {
    SHOW_SNACKBAR, IS_LOADING, getToken, COUNTRY,
    TIMEZONE, WABA, saveWaba
} from "../../utils/types";
const getApiLoaderDispatch = (res, dispatch) => {
    dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

export const fetchIP = async () => {
    try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching IP:", error);
    }
};

export const saveSelectedWaba = (waba) => (dispatch) => {
    saveWaba(waba);
    dispatch({ type: WABA, payload: waba });
}

function postAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOST(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeGET(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

export const getCountry = (request, action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/public/country`, true, {}, { Authorization: `bearer ${getToken()}` }, (a) => {
    dispatch({ type: COUNTRY, payload: a });
    action(a);
});

export const getTimezone = (action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/country/timezone`, true, {}, { Authorization: `bearer ${getToken()}` }, (a) => {
    dispatch({ type: TIMEZONE, payload: a });
    action(a);
});

export const uploadFile = (request, key, action) => (dispatch) => postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage?kay=${key}`, false, request, { Authorization: `bearer ${getToken()}`, "content-type": "multipart/form-data" }, action);

export const getFile = async (key) => {
    const response = await fetch(`${process.env.REACT_APP_PRODUCT_APIURL}/storage?kay=${key}`, {
        method: "GET",
        headers: { Authorization: `bearer ${getToken()}` },
    });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
}

export const getFileMediaId = async (tenantId, wabaId, mediaId) => {
    const response = await fetch(`${process.env.REACT_APP_PRODUCT_APIURL}/storage/media/${mediaId}?tenantId=${tenantId}&wabaId=${wabaId}&mediaId=${mediaId}`, {
        method: "GET",
        headers: { Authorization: `bearer ${getToken()}` },
    });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
}

export const getSessionId = (request, action) => (dispatch) => getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage/media/session-id`, false, request, { Authorization: `bearer ${getToken()}` }, action);

export const sessionUploader = (request, tenantId, wabaId, sessionId, action) => (dispatch) => postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage/media/session-uploader?tenantId=${tenantId}&wabaId=${wabaId}&sessionId=${sessionId}`, false, request, { Authorization: `bearer ${getToken()}`, "content-type": "multipart/form-data" }, action);

export const mediaUploader = (request, tenantId, wabaId, action) => (dispatch) => postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage/media/uploader?tenantId=${tenantId}&wabaId=${wabaId}`, false, request, { Authorization: `bearer ${getToken()}`, "content-type": "multipart/form-data" }, action);

export const mediaUploaderByUrl = async (tenantId, wabaId, url, fileName) => {
    const response = await fetch(`${process.env.REACT_APP_PRODUCT_APIURL}/storage/media/uploader-url?tenantId=${tenantId}&wabaId=${wabaId}&url=${url}&fileName=${encodeURIComponent(fileName)}`, {
        method: "POST",
        body: {},
        headers: { Authorization: `bearer ${getToken()}` }
    });
    return response.json();
}

export const excelUploader = (request, action) => (dispatch) => postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/storage/excel/uploader`, true, request, { Authorization: `bearer ${getToken()}`, "content-type": "multipart/form-data" }, action);
