import React from "react";
import { msgStatusColor, msgStatusTextColor } from "../../utils/helper";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

function ConversationDetail({ selectedWaba, setIsShow, conversation }) {
  const { t } = useTranslation();
  return (
    <div className="height30 flex flex-col justify-between pb-3">
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-4 gap-2 bg-gray-50 p-3 w-full text-[14px]">
          <div className="text-gray-500">{t("Channel")}</div>
          <div className="col-span-3">
            {`${selectedWaba?.teamName} +${selectedWaba?.phoneCode} ${selectedWaba?.phone}`}
          </div>
          <div className="text-gray-500">{t("ID")}</div>
          <div className="col-span-3">
            <label className="break-words">
              {conversation?.referenceId ?? "-"}
            </label>
          </div>
          <div className="text-gray-500">{t("Customer ID")}</div>
          <div className="col-span-3">
            <label className="break-words">
              {conversation?.contact?.customerId ?? "-"}
            </label>
          </div>
          <div className="text-gray-500">{t("Customer Name")}</div>
          <div className="col-span-3">
            <label className="break-words">
              {conversation?.contact?.name ?? "-"}
            </label>
          </div>
          <div className="text-gray-500">{t("Phone Number")}</div>
          <div className="col-span-3">
            {conversation?.contact?.whatsappNumber ?? "-"}
          </div>
          <div className="text-gray-500">{t("Customer Email")}</div>
          <div className="col-span-3">
            {conversation?.contact?.email ?? "-"}
          </div>
          <div className="text-gray-500">{t("Template Id")}</div>
          <div className="col-span-3">
            {conversation?.templateReferenceId ?? "-"}
          </div>
          <div className="text-gray-500">{t("Template Name")}</div>
          <div className="col-span-3">
            {conversation?.templateName ?? "-"}
          </div>
          <div className="text-gray-500">{t("MessageType")}</div>
          <div className="col-span-3">{conversation?.messageType ?? "-"}</div>
          <div className="text-gray-500">{t("Source ")}</div>
          <div className="col-span-3">{conversation?.source ?? "-"}</div>
          <div className="text-gray-500">{t("Status")}</div>
          <div className="col-span-3">
            <label
              className={`p-1.5 mr-1 rounded-lg bg-[${msgStatusColor[conversation?.messageStatus?.toLowerCase()]
                }]`}
            >
              <span
                className={`text-[${msgStatusTextColor[conversation?.messageStatus?.toLowerCase()]
                  }]`}
              >
                {" "}
                {conversation?.messageStatus ?? ""}
              </span>
            </label>
          </div>
          <div className="text-gray-500">{t("Created At")}</div>
          <div className="col-span-3">
            {moment
              .utc(conversation?.createdAt)
              .tz(selectedWaba?.timezone)
              .format("YYYY-MM-DD hh:mm A")}
          </div>
          <div className="text-gray-500">{t("Language")}</div>
          <div className="col-span-3">{conversation?.languageCode?.toUpperCase() ?? "-"}</div>
          <div className="text-gray-500">{t("Country")}</div>
          <div className="col-span-3">{conversation?.countryCode?.toUpperCase() ?? "-"}</div>
          <div className="text-gray-500">{t("Failed Reason")}</div>
          <div className="col-span-3">
            {conversation?.remarks}
          </div>
          <div className="text-gray-500">{t("remarks")}</div>
          <div className="col-span-3">
            {conversation?.reply ? "true" : "false"}
          </div>
          <div className="text-gray-500 col-span-4">{t("Status History")}</div>
          <div className="col-span-1"></div>
          <div className="col-span-3">
            {Object.keys(conversation?.detail ?? {})?.map((m, i) => {
              return <div className='flex justify-between' key={i}>
                <label>{m}</label>
                <label>{moment
                  .utc(conversation?.detail[m])
                  .tz(selectedWaba?.timezone)
                  .format("YYYY-MM-DD hh:mm A")}</label>
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConversationDetail;
