import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTenantUsers,
  tenantOnboard,
  tenantUpdate,
  searchBsp,
} from "../../store/actions/partnerAction";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import MobileInput from "../../components/MobileInput";
import Password from "../../components/Password";
import { useTranslation } from "react-i18next";
import { validatePassword } from "../../utils/helper";
import { SHOW_SNACKBAR } from "../../utils/types";

function AddAccount({ setIsShow, onAdd, defaultAccount, isEdit = false }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [bspList, setBspList] = useState([]);
  const [tenant, setTenant] = useState(defaultAccount ?? { shortName: "" });
  const [users, setUsers] = useState([{}]);
  const [logo, setLogo] = useState(
    isEdit ? { value: defaultAccount?.logo, name: "logo.png" } : {}
  );
  const [isOwnDB, setIsOwnDB] = useState(defaultAccount?.isOwnDb ?? false);
  const [ownDB, setOwnDB] = useState(defaultAccount?.tenantDb ?? {});
  const [passwordError, setPasswordError] = useState();
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    dispatch(searchBsp({}, (b) => setBspList(b.data)));
    if (isEdit) {
      dispatch(
        getTenantUsers(
          {
            tenantId: defaultAccount?.id,
            criteria: { isPrimary: true },
          },
          (d) => {
            setUsers(d?.data);
          }
        )
      );
    } else {
      const country = globalReducer.country.find(
        (f) => f.code === partnerReducer.partnerDetails.countryCode
      );
      if (country) {
        setUsers([{ phoneCode: country.phoneCode }]);
      }
    }
  }, []);

  const handleChange = (index, value, datakey) => {
    setUsers((prevDataArray) => {
      const newDataArray = [...prevDataArray];
      newDataArray[index] = { ...newDataArray[index], [datakey]: value };
      return newDataArray;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordError) {
      return;
    }
    tenant.isOwnDb = isOwnDB;
    tenant.contactPerson = users[0].name;
    tenant.contactEmail = users[0].email;
    tenant.contactPhone = users[0].phone;
    tenant.contactPhoneCode = users[0].phoneCode;
    if (users[0]?.username?.length < 8) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "The username should be at least 8 characters long.",
          open: true,
        },
      });
      return;
    }
    if (isEdit) {
      tenant.logo = logo.value;
      tenant.tenantUsers = users;
      dispatch(
        tenantUpdate(tenant, (a) => {
          setIsShow(false);
          onAdd(a);
        })
      );
    } else {
      tenant.logo = logo.value;
      users[0].roles = ["TENANT_ADMIN"];
      users[0].isPrimary = true;
      tenant.tenantUsers = users;
      if (isOwnDB) {
        tenant.tenantDb = ownDB;
      }
      dispatch(
        tenantOnboard(tenant, (a) => {
          setIsShow(false);
          onAdd(a);
        })
      );
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const passwordMessage = (password, confirmPassword) => {
    setPasswordError(validatePassword(password, confirmPassword));
  };
  const onEnterShortName = (e) => {
    let name = e.target.value?.toLowerCase();
    const regex = /^[a-z0-9_]+$/;
    if (!name || regex.test(name)) {
      setTenant({ ...tenant, shortName: name });
    }
  }
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <form
      className="height30 flex flex-col justify-between pb-3"
      onSubmit={handleSubmit}
    >
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-3">
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Company Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="text"
              placeholder={t('Company Name')}
              value={tenant?.name}
              onChange={(e) => {
                setTenant({ ...tenant, name: e.target.value });
              }}
              className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
              required
            />
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Country Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <SelectCountry
              required={true}
              selectedCode={tenant?.countryCode}
              countryList={globalReducer.country}
              onSelectCountry={(country) => {
                if (country) {
                  tenant.countryCode = country.code;
                } else {
                  delete tenant.countryCode;
                }
                setTenant({ ...tenant });
              }}
              placeholder={('Select Country')}
            />
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('BSP')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select
              required
              className="border border-gray-300 rounded-md py-1 px-4 cursor-pointer"
              value={tenant?.bspId}
              onChange={(e) => {
                setTenant({ ...tenant, bspId: e.target.value });
              }}
            >
              <option value="" selected disabled>
                {t('Select BSP')}
              </option>
              {bspList?.map((option) => {
                return <option value={option?.id}>{option?.name}</option>;
              })}
            </select>
          </div>
          <div className="flex flex-col col-span-6">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Status')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <select required value={tenant?.status}
              className="border border-gray-300 rounded-md py-1 px-4 cursor-pointer"
              onChange={(e) => {
                setTenant({ ...tenant, status: e.target.value });
              }}>
              <option value="PENDING" selected>Pending</option>
              <option value="APPROVE">Approve</option>
            </select>
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Website')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="text" required
              placeholder={t("Enter Website")}
              value={tenant.website}
              onChange={(e) =>
                setTenant({ ...tenant, website: e.target.value })
              }
              className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            />
          </div>
        </div>
        <div className="font-medium text-[13px] my-[10px] text-[#2A2E34]">
          {t('Contact Details')}
        </div>
        {users.map((u, i) => (
          <div className="grid grid-cols-12 gap-2 w-full">
            <div className="col-span-6">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label> {t('Name')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <input
                type="text"
                placeholder={t("Enter Name")}
                name="name"
                value={u.name}
                onChange={(e) => handleChange(i, e.target.value, "name")}
                className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                required
              />
            </div>
            <div className="col-span-6">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('Phone Number')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <MobileInput
                required={true}
                countryList={globalReducer.country}
                phoneCode={u.phoneCode}
                phone={u.phone}
                setPhoneCode={(phone) => handleChange(0, phone, "phoneCode")}
                setPhone={(phone) => handleChange(i, phone, "phone")}
                placeholder={t("Enter Phone Number")}
              />
            </div>
            <div className="col-span-6">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('Email')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <input
                type="email"
                placeholder={t("Enter Email")}
                name="email"
                value={u.email}
                onChange={(e) => handleChange(i, e.target.value, "email")}
                className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                required
              />
            </div>
            <div className="col-span-6">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('Username')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <input
                type="text"
                placeholder={t("Enter Username")}
                value={u.username} data-maxlength={30} onInput={OnInput}
                onChange={(e) => handleChange(i, e.target.value, "username")}
                className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                required
              />
            </div>
            <div className="col-span-6">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('Password')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <Password
                isError={passwordError ? true : false}
                onChange={(a) => {
                  passwordMessage(a, u.confirmPassword);
                  handleChange(i, a, "password");
                }}
                value={u.password}
                required={true}
                placeholder={t("Enter Password")}
              />
            </div>
            <div className="col-span-6">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('Confirm Password')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <Password
                isError={passwordError ? true : false}
                onChange={(a) => {
                  passwordMessage(u.password, a);
                  handleChange(i, a, "confirmPassword");
                }}
                value={u.confirmPassword}
                required={true}
                placeholder={t("Enter Confirm Password")}
              />
            </div>
            <div className="col-span-12 h-1 mt-[-10px]">
              {passwordError && (
                <label className="text-[12px] text-[#f10808]">
                  {passwordError}
                </label>
              )}
            </div>
          </div>
        ))}
        {/* <div className="font-medium text-[13px] my-[10px] text-[#2A2E34]">
        Branding
      </div>
      <div className="grid grid-cols-12 gap-3 w-full text-[13px]">
        <div className="col-span-4">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label> Add Logo</label>
          </div>
          <div className="flex w-full text-[12px]">
            <label htmlFor="addressProofAttachment" className="flex flex-col items-center justify-center w-full h-9 rounded-lg cursor-pointer bg-[#F7F8F9] hover:bg-gray-100 ">
              <div className="flex px-2 py-2">
                {logo?.name ? <><svg width="19" height="18" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.08215 1.94509C8.35088 2.67635 7.62624 3.01585 6.5837 3.01585C5.67342 3.01585 4.37832 2.83931 3.66695 3.55666C2.96121 4.26833 3.13697 5.5581 3.13697 6.46258C3.13697 7.50515 2.79744 8.22979 2.06617 8.96107C0.847584 10.1797 0.238292 10.7889 0.238281 11.5461C0.2383 12.3032 0.847599 12.9126 2.0662 14.1311C2.8854 14.9503 3.13697 15.4793 3.13697 16.6296C3.13697 17.5399 2.96043 18.835 3.6778 19.5463C4.38947 20.2521 5.67922 20.0763 6.58369 20.0763C7.69392 20.0763 8.22856 20.2935 9.02091 21.0858C9.69562 21.7605 10.6001 22.975 11.6672 22.975C12.7343 22.975 13.6388 21.7605 14.3135 21.0858C15.1058 20.2935 15.6405 20.0763 16.7507 20.0763C17.6552 20.0763 18.9449 20.2521 19.6566 19.5463C20.374 18.835 20.1974 17.5399 20.1974 16.6296C20.1974 15.4793 20.449 14.9503 21.2682 14.1311C22.4868 12.9126 23.0961 12.3032 23.0961 11.5461C23.0961 10.7889 22.4868 10.1797 21.2682 8.96107C20.449 8.14186 20.1974 7.61293 20.1974 6.46258C20.1974 5.55231 20.374 4.25723 19.6566 3.54586C18.945 2.84009 17.6552 3.01585 16.7507 3.01585C15.7078 3.01585 14.9833 2.6762 14.2522 1.94509C13.0336 0.726489 12.4243 0.117188 11.6672 0.117188C10.91 0.117188 10.3007 0.726489 9.08215 1.94509Z" fill="#EC4748" />
                  <path d="M7.33594 12.5767C7.33594 12.5767 9.06832 13.3295 9.93451 14.4328C9.93451 14.4328 12.5331 10.1019 15.9978 8.6582" stroke="#EC4748" stroke-width="1.52385" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                  <p className="ml-1 text-gray-500">
                    {logo?.name}
                    <span className="font-semibold text-theme"> Upload complete</span>
                  </p>
                </> : <>
                  <svg width="17" height="19" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.6213 0.802734H10.8811C13.9874 0.802734 15.5405 0.802734 16.6192 1.56267C16.9282 1.78041 17.2025 2.03863 17.4339 2.32949C18.2413 3.34464 18.2413 4.80643 18.2413 7.73001V10.1546C18.2413 12.977 18.2413 14.3882 17.7947 15.5153C17.0766 17.3273 15.558 18.7565 13.6328 19.4323C12.4352 19.8527 10.9358 19.8527 7.93701 19.8527C6.2234 19.8527 5.36659 19.8527 4.68228 19.6125C3.58215 19.2263 2.71439 18.4096 2.30406 17.3742C2.04883 16.7301 2.04883 15.9237 2.04883 14.3109V10.3277" stroke="#EC4748" stroke-width="1.524" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M18.2418 10.3281C18.2418 12.0816 16.8203 13.5031 15.0668 13.5031C14.4326 13.5031 13.685 13.392 13.0684 13.5572C12.5206 13.704 12.0927 14.1319 11.9459 14.6798C11.7807 15.2963 11.8918 16.044 11.8918 16.6781C11.8918 18.4316 10.4703 19.8531 8.7168 19.8531" stroke="#EC4748" stroke-width="1.524" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M8.7157 4.61273L1.0957 4.61273M4.9057 0.802734V8.42273" stroke="#EC4748" stroke-width="1.524" stroke-linecap="round" />
                  </svg>
                  <p className="ml-1 text-gray-500">
                    <span className="font-semibold font-medium text-midBlack text-[12px]">Click to upload </span>
                  </p></>}
              </div>
              <input id="addressProofAttachment" type="file" className="hidden" accept=".jpg,.jpeg,.png,.svg" multiple={false} onChange={async (e) =>
                setLogo({ name: e.target.files[0].name, value: await convertBase64(e.target.files[0]) })
              } />
            </label>
          </div>
        </div>
        <div className="col-span-4 text-[13px]">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>Brand Color1</label>
          </div>
          <input
            type="text"
            name="brandColor1"
            value={tenant.brandColor1}
            data-maxlength={10}
            onInput={OnInput}
            onChange={(e) => setTenant({ ...tenant, brandColor1: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-4 text-[13px]">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>Brand Color 2</label>
          </div>
          <input
            type="text"
            name="brandColor1"
            value={tenant.brandColor2}
            onChange={(e) => setTenant({ ...tenant, brandColor2: e.target.value })}
            className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
            required
          />
        </div>
      </div> */}
        <div className="font-medium text-[13px] my-[16px] text-[#2A2E34]">
          {t('Database Server')}
        </div>
        <div className="flex gap-4">
          <div className="flex items-center">
            <input
              id="auto-radio-1"
              checked={!isOwnDB}
              type="radio"
              onChange={(e) => {
                setIsOwnDB(!e.target.checked);
              }}
              name="auto-radio"
              className="w-4 h-4 text-theme bg-gray-300 shadow-input border-none focus:ring-transparent focus:ring-none "
            />
            <label
              for="auto-radio-1"
              className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
            >
              {t('iOmniHub Database Server')}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="auto-radio-2"
              type="radio"
              checked={isOwnDB}
              onChange={(e) => {
                setIsOwnDB(e.target.checked);
              }}
              name="auto-radio"
              className="w-4 h-4 text-theme bg-gray-300 shadow-input border-none focus:ring-transparent focus:ring-none"
            />
            <label
              for="auto-radio-2"
              className="ms-2 text-sm font-medium text-gray-900 cursor-pointer"
            >
              {t('Client Database Server')}
            </label>
          </div>
        </div>
        {isOwnDB && (
          <div className="grid grid-cols-12 gap-3 w-full mt-3">
            <div className="col-span-4">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('DB Url')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <input
                type="text"
                placeholder={t("Enter Url")}
                name="name"
                value={ownDB.url}
                onChange={(e) => setOwnDB({ ...ownDB, url: e.target.value })}
                className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                required
              />
            </div>
            <div className="col-span-4">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('DB Username')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <input
                type="text"
                placeholder={t("Enter username")}
                value={ownDB.username}
                onChange={(e) =>
                  setOwnDB({ ...ownDB, username: e.target.value })
                }
                className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                required
              />
            </div>
            <div className="col-span-4 text-[13px]">
              <div className="text-midBlack text-[13px] font-normal mb-1">
                <label>{t('DB Password')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              <input
                type="text"
                placeholder={t("Enter password")}
                value={ownDB.password}
                onChange={(e) =>
                  setOwnDB({ ...ownDB, password: e.target.value })
                }
                className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]"
                required
              />
            </div>
          </div>
        )}
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="submit"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[13px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default AddAccount;
