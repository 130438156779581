import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { userUpsert } from "../store/actions/adminAction";
import MobileInput from '../components/MobileInput';
import Password from '../components/Password';
import { useTranslation } from 'react-i18next';
import { validatePassword } from '../utils/helper';
import { SHOW_SNACKBAR } from '../utils/types';

function AddPartnerUser({ setIsShow, onAdd, isEdit = false, defaultUser = {}, partnerId, isSuperAdmin = false }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [user, setUser] = useState(defaultUser ?? {});
  const [showRole, setShowRole] = useState(false);
  const globalReducer = useSelector((state) => state.globalReducer);
  const [passwordError, setPasswordError] = useState();
  const partnerReducer = useSelector((state) => state.partnerReducer);

  useEffect(() => {
    if (!isEdit) {
      const country = globalReducer.country.find(f => f.code === partnerReducer.partnerDetails.countryCode);
      if (country) {
        setUser({ ...user, phoneCode: country.phoneCode });
      }
    }
    setShowRole(globalReducer.loginInfo?.roles.some(s => s === "SUPER_ADMIN") || isSuperAdmin);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!showRole) {
      if (globalReducer.loginInfo?.roles.some(s => s === "GLOBAL_ADMIN")) {
        user.roles = ["GLOBAL_ADMIN"];
      }
    }
    if (!isEdit) {
      user.partnerId = partnerId
    }
    if (user?.username?.length < 8) {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: {
          type: "error",
          message: "The username should be at least 8 characters long.",
          open: true,
        },
      });
      return;
    }
    dispatch(userUpsert(user, (a) => {
      setIsShow(false);
      onAdd(a);
    }))
  }

  const passwordMessage = (password, confirmPassword) => {
    setPasswordError(validatePassword(password, confirmPassword));
  }

  const OnInput = (e) => { e.target.value = e.target.value.slice(0, e.target.dataset.maxlength) }
  return (
    <form className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto" onSubmit={handleSubmit}>
      <div className="grid grid-cols-12 gap-3 w-full">
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label> {t('Name')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <input type="text" placeholder={t("Enter Name")} name="name"
            value={user?.name}
            onChange={(e) => setUser({ ...user, name: e.target.value })}
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]" required />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Phone Number')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <MobileInput className={"py-2 text-[16px]"} placeholder={t('Enter Phone Number')} required={true} countryList={globalReducer.country} phoneCode={user.phoneCode} phone={user.phone} setPhoneCode={(code) => setUser({ ...user, phoneCode: code })} setPhone={(phone) => setUser({ ...user, phone: phone })} />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Email')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <input
            type="email"
            placeholder={t("Enter Email")}
            name="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Username')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <input
            type="text"
            placeholder={t("Enter Username")}
            name="username"
            value={user?.username} data-maxlength={30} onInput={OnInput}
            onChange={(e) => setUser({ ...user, username: e.target.value })}
            className="border border-gray-300 rounded-md py-2 text-[14px] w-[100%]"
            required
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Password')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <Password size='medium' isError={passwordError ? true : false} onChange={(a) => {
            passwordMessage(a, user.confirmPassword);
            setUser({ ...user, password: a });
          }} value={user.password} required={true}
            placeholder={t('Enter Password')}
          />
        </div>
        <div className="col-span-6">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Confirm Password')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <Password size='medium' isError={passwordError ? true : false} onChange={(a) => {
            passwordMessage(user.password, a);
            setUser({ ...user, confirmPassword: a });
          }} value={user.confirmPassword} required={true}
            placeholder={t('Enter Confirm Password')}
          />
        </div>
        <div className="col-span-12 h-1 mt-[-15px]">
          {passwordError && <label className='text-[12px] text-[#f10808]'>{passwordError}</label>}
        </div>
        {showRole && <div className="col-span-12">
          <div className="text-midBlack text-[13px] font-normal mb-1">
            <label>{t('Role')}<span className="text-red-500 text-[14px]">*</span></label>
          </div>
          <select name='roles' defaultValue={isEdit ? user?.roles[0] ?? "" : ""} className="border h-10 w-full border-gray-300 rounded-md py-2 px-4 cursor-pointer"
            onChange={(e) => {
              setUser({ ...user, roles: [e.target.value] })
            }}>
            <option value="" selected disabled>
              {('Select role...')}
            </option>
            <option value="SUPER_ADMIN">{t('Super Admin')}</option>
            {/* <option value="GENERAL">{t("General")}</option> */}
          </select>
        </div>}
        <div className="col-span-4 item-end mt-[2px]">
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" onChange={(e) => {
              setUser({ ...user, active: e.target.checked === true })
            }} checked={user?.active} className="sr-only peer" />
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-theme"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 ">Active</span>
          </label>
        </div>
      </div>
      <div className="flex justify-end gap-2 mt-4">
        <button type='button'
          className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
          onClick={() => setIsShow(false)}>
          {t('Cancel')}
        </button>
        <button type='submit'
          className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
          {t('Save')}
        </button>
      </div>
    </form>
  )
}

export default AddPartnerUser