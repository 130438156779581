import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import AddTenantUser from "../../layouts/AddTenantUser";
import {
  getTenantUsers,
  changeUserStatus,
} from "../../store/actions/tenantAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function ManageUsers({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAddUser, setshowAddUser] = useState(false);
  const [Users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const globalReducer = useSelector((state) => state.globalReducer);
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo.tenantId },
  });
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

  useEffect(() => {
    Usersearch();
  }, [currentPage]);

  const Usersearch = () => {
    dispatch(
      getTenantUsers(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantId: globalReducer.loginInfo.tenantId,
          },
          tenantId: globalReducer.loginInfo.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "createdAt", direction: "ASC" }],
        },
        (d) => {
          setUsers(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      Usersearch();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular text-[14px] fa-solid fa-user-plus text-theme"></i>
            <div className="text-[14px] font-medium">{t("Manage Users")}</div>
          </div>
          <div className="flex gap-2">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedUser({});
                setshowAddUser(true);
              }}
            >
              {t("Add User")}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("User Details")}</label>
              </div>
              <input
                type="text"
                placeholder={t("Enter User Details")}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  const fl = [];
                  if (!isNaN(e.target.value)) {
                    fl.push({
                      property: "phone",
                      operator: "CONTAINS",
                      value: e.target.value,
                    });
                  } else if (e.target.value?.includes("@")) {
                    fl.push({
                      property: "email",
                      operator: "ICONTAINS",
                      value: e.target.value,
                    });
                  } else {
                    fl.push({
                      property: "name",
                      operator: "ICONTAINS",
                      value: e.target.value,
                    });
                  }
                  setFilter({ ...filter, filter: fl });
                }}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Status")}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t("Active")}
                </option>
                <option value={false}>{t("Not Active")}</option>
              </select>
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {Users && Users.length > 0 ? (
            <div className="h-full">
              <div className="mt-2 bg-white">
                <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                  <thead className="text-[14px] text-midBlack bg-gray-200">
                    <tr>
                      <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                        {t("Sr. No.")}
                      </th>
                      <th scope="col" className="font-[500] py-3 pl-2">
                        <div className="flex gap-2">
                          <div>{t("Username")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500] py-3 pl-2">
                        <div className="flex gap-2">
                          <div>{t("Name")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t("Email")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t("Phone")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div> {t("Role")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500] w-[160px]">
                        <div className="flex gap-2">
                          <div>{t("Status")}</div>
                        </div>
                      </th>
                      <th scope="col" className="font-[500]">
                        <div className="flex gap-2">
                          <div>{t("Action")}</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Users.map((p, i) => {
                      return (
                        <tr className="bg-white border-b" key={i}>
                          <td className="py-3 text-[13px] pl-2">
                            {currentPage === 1
                              ? i + 1
                              : itemParPage * (currentPage - 1) + (i + 1)}
                          </td>
                          <td
                            className={
                              "py-3 text-[13px] pl-2 capitalize " +
                              (p?.isPrimary ? "font-bold" : "font-semibold")
                            }
                          >
                            {p?.username ?? "-"}
                          </td>
                          <td
                            className={
                              "py-3 text-[13px] pl-2 capitalize " +
                              (p?.isPrimary ? "font-bold" : "font-semibold")
                            }
                          >
                            {p?.name ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.email ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.phone ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.roles?.map(m => {
                              return m.replace("TENANT_", "").replace("MARKETING", "MARKETER ");
                            }).join() ?? "-"}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {p?.active ? (
                              <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                                Active
                              </label>
                            ) : (
                              <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                                Not Active
                              </label>
                            )}
                          </td>
                          <td className="py-3 text-[13px] font-normal">
                            {!p?.isPrimary && (
                              <Dropdown
                                inline
                                arrowIcon={false}
                                inputMode
                                as="button"
                                className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                                label={<i className="fa-solid fa-ellipsis"></i>}
                              >
                                {p?.active ? (
                                  <Dropdown.Item
                                    className="hover:text-theme"
                                    onClick={() => {
                                      dispatch(
                                        changeUserStatus(
                                          {
                                            id: p?.uid,
                                            tenantId:
                                              globalReducer.loginInfo.tenantId,
                                            isActive: false,
                                          },
                                          (a) => Usersearch()
                                        )
                                      );
                                    }}
                                  >
                                    <i className="fa-solid fa-link-slash pr-2"></i>
                                    {t("Deactivate")}
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    className="hover:text-theme"
                                    onClick={() => {
                                      dispatch(
                                        changeUserStatus(
                                          {
                                            id: p?.uid,
                                            tenantId:
                                              globalReducer.loginInfo.tenantId,
                                            isActive: true,
                                          },
                                          (a) => Usersearch()
                                        )
                                      );
                                    }}
                                  >
                                    <i className="fa-solid fa-link pr-2"></i>
                                    {t("Activate")}
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item
                                  className="hover:text-theme"
                                  onClick={() => {
                                    setSelectedUser({
                                      ...p,
                                      isEdit: true,
                                      confirmPassword: p.password,
                                    });
                                    setshowAddUser(true);
                                  }}
                                >
                                  <i className="fa-regular fa-pen-to-square pr-2"></i>
                                  {t("Edit")}
                                </Dropdown.Item>
                              </Dropdown>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t("The space is empty . Add a Company user to get started.")}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => setshowAddUser(true)}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t("Add User")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {totalPages > 1 &&
          Users &&
          Users.length >
          0(
            <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
              <div className="flex overflow-x-auto">
                <Pagination
                  page={currentPage}
                  count={totalPages}
                  onChange={(event, page) => setCurrentPage(page)}
                  shape="rounded"
                  color="secondary"
                />
              </div>
            </div>
          )}
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {selectedUser?.isEdit ? t("Update Users") : t("Add Users")}
          </span>
        }
        isShowModal={showAddUser}
        setIsShowModal={setshowAddUser}
        ModalBody={
          <AddTenantUser
            setIsShow={setshowAddUser}
            onAdd={Usersearch}
            defaultUser={selectedUser}
            isEdit={selectedUser.isEdit}
            tenantId={globalReducer.loginInfo.tenantId}
          />
        }
        size="2xl"
      />
    </div>
  );
}

export default ManageUsers;
