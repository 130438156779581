import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { tenantNotificationViewed } from '../../store/actions/tenantAction';

function Notifications() {
    let dispatch = useDispatch();
    const globalReducer = useSelector((state) => state.globalReducer);

    const markeViewd = (nt) => {
        if (nt?.status !== "VIEWED") {
            dispatch(tenantNotificationViewed(nt, (d) => { }));
        }
    }

    return (
        <div className="relative">
            <div className="p-[16px] bg-white rounded-[4px] text-[14px] mt-2 dropdown-shadow absolute w-[290px] min-h-[300px] z-[99] right-[-40px] max-h-[50vh] overflow-y-auto">
                <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                    <tbody>
                        {globalReducer?.notifications?.map((nt, i) => {
                            return (
                                <tr key={i} className="bg-white border-b">
                                    <td className="py-1 text-[13px] font-normal px-2 bg-gray-50">
                                        <textarea onClick={() => markeViewd(nt)} value={nt?.content} rows={4} readOnly
                                            className={`border-0 w-full bg-gray-50 ${nt?.status !== "VIEWED" ? "text-gray-900 font-semibold" : ""}`}>
                                        </textarea>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Notifications