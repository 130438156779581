import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import { Pagination } from "@mui/material";
import { getTransactionHistory } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import PopUp from "../../components/PopUp";
import ViewFile from "../../components/ViewFile";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function TransactionHistory({ showSidebar }) {
  const { t } = useTranslation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [searchText, setSearchText] = useState("");
  const [showFile, setShowFile] = useState(false);
  const [showLPO, setShowLPO] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo?.tenantId },
  });
  const [dateFilter, setDateFilter] = useState();
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone =
    globalReducer.waba?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    onSearch();
  }, [currentPage, globalReducer.waba]);

  const onSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push(
        {
          property: "paymentId",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "transactionId",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "paymentOrderId",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "transactionMedium",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "paymentGatewayType",
          operator: "ICONTAINS",
          value: searchText,
        }
      );
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "OR" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }

    dispatch(
      getTransactionHistory(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantId: globalReducer.loginInfo?.tenantId,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setTransactionHistory(d?.data ?? []);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${
          showSidebar ? "fullWidth" : "fullWidth1"
        } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-book text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">
              {t("Transaction History")}
            </div>
          </div>
          <div className="flex gap-2"></div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Transaction Details")}</label>
              </div>
              <input
                type="text"
                placeholder="Enter Transaction Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Status")}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: { ...filter.criteria, status: e.target.value },
                  });
                }}
              >
                <option value={null}>{t("Select Status")}</option>
                <option value={"REQUESTED"}>{t("Requested")}</option>
                <option value={"SUCCESS"}>{t("Success")}</option>
                <option value={"REJECTED"}>{t("Rejected")}</option>
                <option value={"APPROVED"}>{t("Approved")}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-4`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Date Range")}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>

            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {transactionHistory && transactionHistory.length > 0 && (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t("Sr. No.")}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t("TransactionId")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Payment Medium")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500] w-[160px]">
                      <div className="flex gap-2">
                        <div>{t("Status")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Amount")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Payment For")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Date")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Action")}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactionHistory.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1
                            ? i + 1
                            : itemParPage * (currentPage - 1) + (i + 1)}
                        </td>
                        <td className="py-3 text-[13px] pl-2 font-normal">
                          {p?.transactionId ?? "-"}
                        </td>
                        {p?.transactionMedium === "PAYMENT_RECEIPT" ? (
                          <th
                            scope="row"
                            className="py-3 pl-2 font-medium text-[13px] hover:underline hover:text-theme cursor-pointer text-theme"
                            onClick={() => {
                              const paymentReceipt =
                                p?.receiptPath?.split(";")[0];
                              setSelectedTransaction({
                                ...p,
                                paymentReceipt: paymentReceipt,
                              });
                              setShowFile(true);
                            }}
                          >
                            {p?.transactionMedium ?? "-"}
                          </th>
                        ) : (
                          <td className="py-3 text-[13px] font-normal">
                            {p?.transactionMedium}
                          </td>
                        )}
                        <td className="py-3 text-[13px] font-normal">
                          {p?.status}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.currencyCode} {p?.totalAmount}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.detail?.paymentFor}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {moment
                            .utc(p?.createdAt)
                            .tz(wabaTimezone)
                            .format("YYYY-MM-DD hh:mm A")}
                        </td>
                        <td className=" py-3 text-[13px] font-normal">
                          {p?.transactionMedium === "PAYMENT_RECEIPT" && (
                            <Dropdown
                              inline
                              arrowIcon={false}
                              inputMode
                              as="button"
                              className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                              label={<i className="fa-solid fa-ellipsis"></i>}
                            >
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  const paymentReceipt =
                                    p?.receiptPath?.split(";")[0];
                                  setSelectedTransaction({
                                    ...p,
                                    paymentReceipt: paymentReceipt,
                                  });
                                  setShowFile(true);
                                }}
                              >
                                <i className="fa-solid fa-receipt pr-2"></i>
                                {t("View Receipt")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="hover:text-theme"
                                onClick={() => {
                                  try {
                                    const paymentLPO =
                                      p?.receiptPath?.split(";")[1];
                                    setSelectedTransaction({
                                      ...p,
                                      paymentLPO: paymentLPO,
                                    });
                                    setShowLPO(true);
                                  } catch {}
                                }}
                              >
                                <i className="fa-solid fa-receipt pr-2"></i>{" "}
                                {t("View LPO")}
                              </Dropdown.Item>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
          {totalPages > 1 && (
            <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          )}
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Payment Receipt")}
          </span>
        }
        isShowModal={showFile}
        setIsShowModal={setShowFile}
        ModalBody={
          <ViewFile
            setIsShow={setShowFile}
            fileName={selectedTransaction.transactionId + "_payment_receipt"}
            selectedFileUrl={selectedTransaction?.paymentReceipt}
          />
        }
        size="xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">{t("LPO")}</span>
        }
        isShowModal={showLPO}
        setIsShowModal={setShowLPO}
        ModalBody={
          <ViewFile
            setIsShow={setShowLPO}
            fileName={
              selectedTransaction.transactionId + "_payment_receipt_lpo"
            }
            selectedFileUrl={selectedTransaction?.paymentLPO}
          />
        }
        size="xl"
      />
    </div>
  );
}

export default TransactionHistory;
