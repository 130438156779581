import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react'

function SelectTime({ required, className, value, onChange, timezone }) {
  const [formattedValue, setFormattedValue] = useState("");
  const [defaultTimezone, setDefaultTimezone] = useState(timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    if (timezone) setDefaultTimezone(timezone);
  }, [timezone]);

  useEffect(() => {
    // Format value for display in the selected timezone
    const formatted = value
      ? moment.utc("2000-01-01T" + value).tz(defaultTimezone).format('HH:mm')
      : "";
    setFormattedValue(formatted);
  }, [value, defaultTimezone]);

  const onDateChange = (e) => {
    const localTime = e.target.value;
    if (localTime) {
      const zonedTime = moment.tz(localTime, "HH:mm", defaultTimezone); // Convert to timezone-aware moment object
      const utcTime = zonedTime.utc().format('HH:mm'); // Convert to UTC
      setFormattedValue(utcTime); // Update displayed value
      onChange(utcTime); // Pass UTC value to parent
    }
  };

  return (
    <input
      type="time"
      required={required}
      value={formattedValue}
      onChange={onDateChange}
      className={`w-full border border-gray-300 rounded-md ${className ?? ''}`}
    />
  )
}

export default SelectTime