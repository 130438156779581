import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Password from "../components/Password";
import ROUTES from "../utils/routesConstants";
import {
  forgotPassword,
  validateForgotLink,
} from "../store/actions/loginAction";
import { useTranslation } from "react-i18next";
import CustomCaptcha from "../components/CustomCaptcha";
import { validatePassword } from "../utils/helper";

function PasswordResetLink() {
  const { t } = useTranslation();
  const { link } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inValid, setInValid] = useState(true);
  const [expired, setExpired] = useState(false);
  const [success, setSuccess] = useState(false);
  const [user, setUser] = useState({});
  const [passwordError, setPasswordError] = useState(false);
  const captcha = useRef(null);

  useEffect(() => {
    if (link) {
      dispatch(
        validateForgotLink(link, (a) => {
          setInValid(a === false);
        })
      );
    } else {
      setInValid(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captcha.current.validate()) {
      return;
    }
    if (link && !passwordError) {
      dispatch(
        forgotPassword(user, link, (a) => {
          setSuccess(a);
        })
      );
    }
  };

  const gotoLogin = () => {
    navigate(ROUTES.LOGIN);
  };
  const passwordMessage = (password, confirmPassword) => {
    setPasswordError(validatePassword(password, confirmPassword));
  };
  return (
    <div className="flex flex-col h-screen bg-slate-100 max-md:px-5 p-4">
      <div className="flex justify-between">
        <div>
          <img
            alt=""
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/iomnihub-logo.svg`}
            className=" w-40 max-w-full aspect-[3.85] max-md:mt-10"
          />
        </div>
      </div>
      <div className="flex justify-center">
        {inValid ? (
          <div className="py-4 mt-15">
            <p className="mt-5 ml-3">
              <i className="fa-solid fa-xmark text-[50px] text-theme"></i>
            </p>
            <p className="text-[18px]">
              {t('It looks like the password reset link you tried to use is invalid. This can happen if:')}
              <ul className="text-gray-500 list-disc ml-7">
                <li>{t('The link has already been used.')}</li>
                <li>{t('The link has expired.')}</li>
                <li>{t('The link was copied incorrectly.')}</li>
              </ul>
            </p>
            <p className="text-[16px] text-gray-500 mt-3">
              {t('Don’t worry! You can request a new password reset link.')}
            </p>
          </div>
        ) : success ? (
          <div className="py-4 mt-15">
            <p className="mt-5 ml-3">
              <i className="fa-regular fa-circle-check text-[50px] text-theme"></i>
            </p>
            <p className="text-[18px] mt-2">
              {t('Your password was successfully changed.')}
              <br />
              {t('You can now log in to your account using your new password.')}
            </p>
            <button
              type="button"
              onClick={gotoLogin}
              className="justify-center cursor-pointer items-center p-[12px] mt-7 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-[200px]"
            >
              {t('Log In to Your Account')}
            </button>
            <p className="text-[16px] text-gray-500 mt-3">
              {t('If you did not make this change,')}
              <br />
              {t('please contact our support team immediately to secure your account.')}
            </p>
          </div>
        ) : (
          <div className="flex flex-col min-h-[80vh] justify-center items-center bg-slate-100 max-md:px-5">
            <div className="flex justify-center items-center">
              <form onSubmit={handleSubmit} action="POST">
                <div className="flex flex-col gap-2 p-[30px] bg-white rounded-[16px] shadow-input max-w-[750px] max-md:px-5 w-[450px]">
                  <div className="justify-center items-center pb-[32px] text-[24px] font-[600] text-center text-black max-md:px-5 max-md:max-w-full tracking-[-0.02em] leading-[28px]">
                    {t('Reset Password')}
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="text-midBlack text-[13px] font-normal">
                      <label>
                        {t('New Password')}
                        <span className="text-red-500 text-[14px]">*</span>
                      </label>
                    </div>
                    <Password placeholder="Enter new Password"
                      size="medium"
                      isError={passwordError}
                      onChange={(a) => {
                        passwordMessage(a, user.confirmPassword);
                        setUser({ ...user, password: a });
                      }}
                      value={user.password}
                      required={true}
                    />
                    <div className="text-midBlack text-[13px] font-normal">
                      <label>
                        {t('Confirm Password')}
                        <span className="text-red-500 text-[14px]">*</span>
                      </label>
                    </div>
                    <Password placeholder="Enter confirm password"
                      size="medium"
                      isError={passwordError}
                      onChange={(a) => {
                        passwordMessage(user.password, a);
                        setUser({ ...user, confirmPassword: a });
                      }}
                      value={user.confirmPassword}
                      required={true}
                    />
                    <div className="h-1 mt-[-10px]">
                      {passwordError && (
                        <label className="text-[12px] text-[#f10808] capitalize rounded-[4px] w-full block">
                          {passwordError}
                        </label>
                      )}
                    </div>
                    <div className="mt-[15px]">
                      <CustomCaptcha ref={captcha} />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="justify-center cursor-pointer items-center p-[12px] mt-7 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                  >
                    {t('Submit')}
                  </button>
                </div>
              </form>
            </div>
            <div className="w-full flex  flex-col justify-center items-center mt-[20px]">
              <a href="https://www.i-grp.com/" target="blank">
                <img
                  src={`${process.env.PUBLIC_URL}/images/Infocomm-Logo-on-Powered.svg`}
                  width="128"
                  height="128"
                  alt="hello"
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PasswordResetLink;
