import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryQuery,
  getTenantContacts,
} from "../../store/actions/tenantAction";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";

function ChooseContact({ setIsShow, onAdd }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [Contacts, setContacts] = useState([]);
  const [SelectedContacts, setSelectedContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [categories, setCategories] = useState([]);
  const globalReducer = useSelector((state) => state.globalReducer);
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo?.tenantId, active: true },
  });
  const itemParPage = 6;

  useEffect(() => {
    ContactSearch();
  }, [currentPage]);

  useEffect(() => {
    const fi = [];
    fi.push(
      { clause: "OPEN_BRACKET" },
      {
        property: "permission",
        operator: "ICONTAINS",
        value: "PUBLIC",
      },
      { clause: "OR" },
      { clause: "OPEN_BRACKET" },
      {
        property: "permission",
        operator: "ICONTAINS",
        value: "PRIVATE",
      },
      { clause: "AND" },
      {
        property: "uid",
        operator: "EQ",
        value: globalReducer.loginInfo?.uid,
      },
      { clause: "CLOSE_BRACKET" },
      { clause: "CLOSE_BRACKET" }
    );
    if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
      fi.push({ clause: "AND" }, { clause: "OPEN_BRACKET" }, {
        property: "categoryName",
        operator: "ICONTAINS",
        not: true,
        value: "GENERIC_EXTERNAL",
      }, { clause: "CLOSE_BRACKET" });
    }
    dispatch(
      categoryQuery(
        {
          filter: fi,
          tenantId: globalReducer.loginInfo?.tenantId,
          criteria: {
            active: true,
            tenantId: globalReducer.loginInfo?.tenantId,
          },
          count: true,
          size: 100,
        },
        (d) => {
          setCategories(d?.data ?? []);
        }
      )
    );
  }, []);

  const ContactSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push({ clause: "OPEN_BRACKET" },
        {
          property: "whatsappNumber",
          operator: "CONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "email",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "name",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "location",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "category",
          operator: "IN",
          value: searchText,
        }, { clause: "CLOSE_BRACKET" }
      );
    }
    filter.filter = fl;
    if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
      if (filter.filter?.length > 0) {
        filter.filter.push({ clause: "AND" });
      }
      filter.filter.push({ clause: "OPEN_BRACKET" }, {
        property: "category",
        operator: "IN",
        not: true,
        value: "GENERIC_EXTERNAL",
      }, { clause: "CLOSE_BRACKET" });
    }
    dispatch(
      getTenantContacts(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantId: globalReducer.loginInfo?.tenantId,
            active: true,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
        },
        (d) => {
          setContacts(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  return (
    <div className="height30 flex flex-col justify-between">
      <div className="p-4">
        <div className="grid grid-cols-5 gap-4 items-end">
          <div className={`flex flex-col col-span-2`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t("Contact Details")}</label>
            </div>
            <input
              type="text"
              placeholder={t("Enter Contact Details")}
              className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
              onChange={(e) => { setSearchText(e.target.value); }}
            />
          </div>
          <div className={`flex flex-col col-span-2`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t("Category")}</label>
            </div>
            <select
              className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
              onChange={(e) => {
                e.target.value
                  ? setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      category: [e.target.value],
                    },
                  })
                  : delete filter.criteria.category;
              }}
            >
              <option value="" selected>
                {t("All")}
              </option>
              {categories.map((m, i) => (
                <option key={i} value={m.categoryName}>
                  {m.categoryName}
                </option>
              ))}
            </select>
          </div>
          <div className={`flex flex-col`}>
            <button
              type="button"
              className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
              onClick={() => {
                if (currentPage === 1) {
                  ContactSearch();
                } else {
                  setCurrentPage(1);
                }
              }}
            >
              {" "}
              {t("Search")}
            </button>
          </div>
        </div>
        <div className="overflow-y-auto max-h-[300px] mt-4">
          {Contacts && Contacts.length > 0 && (
            <div className="mt-2">
              <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-auto">
                <thead className="text-[14px] text-midBlack">
                  <tr>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t("Customer Id")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Name")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Email")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Phone")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Gender")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Select")}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Contacts.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className={"py-3 text-[13px] pl-2 font-normal"}>
                          {p?.customerId ?? "-"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {p?.name ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.email ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {`+${p?.whatsappNumber}`}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {p?.gender ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          <input type="checkbox" onChange={(e) => {
                            const con = SelectedContacts.findIndex(f => f.id === p.id);
                            if (con > -1) {
                              SelectedContacts[con] = p;
                              setSelectedContacts([...SelectedContacts])
                            } else {
                              setSelectedContacts([...SelectedContacts, p])
                            }
                          }} checked={SelectedContacts.some(s => s.id === p.id)} className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-between items-center gap-4 w-full">
          {totalPages > 1 && (
            <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          )}
          <button
            type="button"
            className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer"
            onClick={() => {
              onAdd(SelectedContacts);
              // dispatch(
              //   getTenantContacts(
              //     {
              //       ...filter,
              //       tenantId: globalReducer.loginInfo?.tenantId,
              //       count: true,
              //       size: 100000,
              //     },
              //     (d) => {
              //       onAdd(d?.data);
              //     }
              //   )
              // );
              setIsShow(false);
            }}
          >
            {t("Select")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChooseContact;
