import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  assignToPartnerRateCard,
  getMessageType,
  rateCardQuery,
  upsertPartnerRateCardDetails,
} from "../../store/actions/partnerAction";
import { getPartnerRateCardDetails } from "../../store/actions/partnerAction";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";

function MapPartnerRateCard({
  setIsShow,
  onAdd,
  partner = {},
  isEdit = false,
}) {
  let dispatch = useDispatch();
  const {t} = useTranslation();
  const [rateCards, setRateCards] = useState([]);
  const [selectedRateCard, setSelectedRateCard] = useState({});
  const [selectedRateCardId, setSelectedRateCardId] = useState();
  const [partnerRateCards, setpartnerRateCards] = useState([]);
  const [messageTypes, setMessageTypes] = useState([]);
  const [rateCardChanges, setRateCardChanges] = useState([]);
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    dispatch(getMessageType(setMessageTypes));
    dispatch(
      rateCardQuery({
        criteria: { active: true }, size: 1000,
        offset: 0
      }, (a) => {
        setRateCards(a.data);
      })
    );
    refresh();
  }, []);

  useEffect(() => {
    if (selectedRateCardId && rateCards?.length > 0) {
      setSelectedRateCard(rateCards.find((f) => f.id == selectedRateCardId));
    }
  }, [selectedRateCardId, rateCards]);

  const refresh = () => {
    dispatch(
      getPartnerRateCardDetails(
        { criteria: { partnerId: partner.id } },
        (a) => {
          if (a && a.length > 0) {
            setSelectedRateCardId(a[0].rateCardId);
            setpartnerRateCards(
              a?.map((m) => {
                const country = globalReducer.country.find(
                  (fi) => fi.code === m.countryCode
                );
                return {
                  ...m,
                  countryName: country?.name,
                  currencyCode: country?.currencyCode,
                };
              })
            );
          }
        }
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      assignToPartnerRateCard(
        { partnerId: partner.id, rateCard: selectedRateCard },
        (a) => {
          refresh();
          onAdd(a);
        }
      )
    );
  };

  const onSave = (e) => {
    if (rateCardChanges.length > 0) {
      dispatch(
        upsertPartnerRateCardDetails(rateCardChanges, (a) => {
          setIsShow(false);
        })
      );
    } else {
      setIsShow(false);
    }
  };

  const onRateCardChanges = (c, messageType, rate) => {
    let fCard = rateCardChanges?.find(
      (m) =>
        c.partnerId === m.partnerId &&
        c.countryCode === m.countryCode &&
        c.messageType === messageType
    );
    if (fCard) {
      fCard.messageRate = rate;
      setRateCardChanges([...rateCardChanges]);
    } else {
      fCard = { ...c, messageRate: rate, id: null, messageType: messageType };
      setRateCardChanges([...rateCardChanges, fCard]);
    }
  };
  const onKeyDownForRate = (e) => {
    const newValue = e.target.value + e.key;
    const decimalRegex = /^\d*\.?\d*$/;
    if (e.key?.length === 1 && !decimalRegex.test(newValue)) {
      e.preventDefault();
    }
  }
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="height30 flex flex-col justify-between pb-3">
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-2 w-full items-end">
          <div className="col-span-10">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Rate Card')}</label>
            </div>
            <TypeableSelect list={rateCards} selected={{ id: selectedRateCardId }} labelField="name" valueField="id" required={true} placeholder={t("Select Rate Card...")} onSelect={(s) => {
              setSelectedRateCardId(s?.id);
            }} />
          </div>
          <div className="col-span-2">
            <button
              type="button"
              className="w-full px-[16px] py-[10px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={handleSubmit}
            >
              {t('Assign')}
            </button>
          </div>
        </div>
        {partnerRateCards?.length > 0 && (
          <>
            <div className="font-medium text-[13px] w-[100%] text-[#2A2E34] mt-4 mb-2">
             {t('Domestic Rate')}
            </div>
            <>
              <table className="text-sm text-left rtl:text-right text-midBlack">
                <thead className="text-[14px] text-midBlack">
                  <tr className="h-9 bg-gray-100">
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      <div>{t('Countries')}</div>
                    </th>
                    {messageTypes.map((m, i) => (
                      <th key={i} scope="col" className="pb-1 pr-1 font-[500]">
                        <div>{m.messageType}</div>
                      </th>
                    ))}
                  </tr>
                  {partnerRateCards
                    ?.filter((f) => f.currency === "NATIVE")
                    .map((f, r) => (
                      <tr key={r}>
                        <td className="py-2 font-normal text-[13px] pr-2">
                          {f?.countryName ?? "Domestic"} ({f?.currencyCode})
                        </td>
                        {messageTypes.map((m, i) => (
                          <td
                            key={i}
                            className="py-2 font-normal text-[13px] pr-1"
                          >
                            <input
                              data-maxlength={8}
                              onInput={OnInput}
                              step="any"
                              type="text"
                              className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                              defaultValue={f?.rates?.[m.messageType]}
                              onChange={(e) =>
                                onRateCardChanges(
                                  f,
                                  m.messageType,
                                  e.target.value
                                )
                              }
                              onKeyDown={onKeyDownForRate}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  <tr>
                    <td colSpan={messageTypes.length + 1}>
                      <div className="font-medium text-[13px] w-full text-[#2A2E34] my-4 flex items-center justify-between">
                        <label>{t('International')} ({partnerRateCards[0]?.currency})</label>
                        <input
                          type="text"
                          placeholder={t("Search Countries")}
                          className="border border-gray-300 rounded-md h-9 py-1"
                          onChange={(e) => {
                            const cn = e.target.value;
                            setpartnerRateCards(
                              partnerRateCards.map((f) => {
                                if (
                                  f.countryCode?.includes(cn) ||
                                  f.countryName?.includes(cn)
                                ) {
                                  return { ...f, isHide: false };
                                } else return { ...f, isHide: true };
                              })
                            );
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {partnerRateCards?.map((d, c) => (
                    <tr
                      className={
                        "border " +
                        (d.isHide || d.currency === "NATIVE" ? "hidden" : "")
                      }
                      key={c}
                    >
                      <td className="py-1 font-normal text-[13px] pl-2 pr-2">
                        {d?.countryName}
                      </td>
                      {messageTypes.map((m, i) => (
                        <td key={i} className="py-1 font-normal text-[13px] pr-1">
                          <input
                            data-maxlength={8}
                            onInput={OnInput}
                            step="any"
                            type="text"
                            className="border-0 border-gray-300 bg-gray-100 rounded-md py-1 h-9 w-[100%]"
                            defaultValue={d?.rates?.[m.messageType]}
                            onChange={(e) =>
                              onRateCardChanges(d, m.messageType, e.target.value)
                            }
                            onKeyDown={onKeyDownForRate}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          </>
        )}
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="button"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            onClick={onSave}
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MapPartnerRateCard;
