import { Dropdown, Datepicker } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { FiInbox } from "react-icons/fi";
import PopUp from "../../components/PopUp";
import RightSidePanel from '../../components/RightSidePanel';
import LiveChatUserInfo from "../../layouts/globalAdmin/LiveChatUserInfo";
import { useDispatch, useSelector } from "react-redux";
import socket from "../../utils/socket";
import { getChatHistory, getConversation, getTenantUsers, liveChatGetContacts, liveChatUpdateContacts } from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import LiveChatPreview from "../../layouts/tenant/LiveChatPreview";
import SendWhatsAppMessageView from "../../layouts/tenant/SendWhatsAppMessageView";
import SendTemplateMessageView from "../../layouts/tenant/SendTemplateMessageView";
import FilterAgents from "../../layouts/tenant/FilterAgents";
import SearchExistingTicket from "../../layouts/tenant/SearchExistingTicket";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import { allTags, priorityTextColor, toTitleCase } from "../../utils/helper";

function LiveChat({ showSidebar }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [searchShow, setSearchShow] = useState(false);
  const [checkedShow, setCheckedShow] = useState(false);
  const [showAssignTicket, setShowAssignTicket] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showPriorityPopup, setShowPriorityPopup] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [closeChat, setCloseChat] = useState(false);
  const [canSendMsg, setCanSendMsg] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [viewUserInfo, setViewUserInfo] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [agents, setAgents] = useState([]);
  const [assignTicket, setAssignTicket] = useState({});
  const [contactFilter, setContactFilter] = useState({ direction: "DESC", criteria: {}, orderBy: [{ property: "lastMessageDate", direction: "DESC" }] });
  const [selectedContact, setSelectedContact] = useState({});
  const [messageList, setMessageList] = useState([]);
  const [isConnected, setIsConnected] = useState("");
  const globalReducer = useSelector((state) => state.globalReducer);
  const wabaTimezone = globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    dispatch(getTenantUsers({
      tenantId: globalReducer.loginInfo?.tenantId,
      filter: [{
        property: "roles",
        operator: "IN",
        not: true,
        value: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      }],
      criteria: { active: true }
    }, a => setAgents(a.data ?? [])))
    if (globalReducer.loginInfo?.tenantId && globalReducer.waba?.id) {
      if (socket?.connected) {
        socket.emit("messageInitiate", {
          tenantId: globalReducer.loginInfo?.tenantId,
          wabaId: globalReducer.waba?.id, userUid: globalReducer.loginInfo?.uid
        });
      } else {
        socket.on("connect", () => {
          setIsConnected(true);
          socket.emit("messageInitiate", {
            tenantId: globalReducer.loginInfo?.tenantId,
            wabaId: globalReducer.waba?.id, userUid: globalReducer.loginInfo?.uid
          });
        });
      }
    }

    socket.on("disconnect", () => setIsConnected(false));

    socket.on("message", (msg) => {
      const msgObj = JSON.parse(msg);
      if (msgObj && msgObj.messageText) {
        msgObj.messageText = JSON.parse(msgObj.messageText);
        setMessageList((prevMessages) => [...prevMessages, msgObj]);
      }
    });

    socket.on("live_chat_contact", (con) => {
      const conObj = JSON.parse(con);
      setContacts((prevContacts) => {
        const contIndex = prevContacts.findIndex(c => c.id === conObj.id);
        if (contIndex !== -1) {
          // Create a new array to trigger re-render
          const updatedContacts = [...prevContacts];
          updatedContacts[contIndex] = {
            ...updatedContacts[contIndex],
            lastMessageDate: conObj.lastMessageDate,
            lastMessage: conObj.lastMessage,
            ticketStatus: conObj.ticketStatus,
            agentReplied: conObj.agentReplied,
            assignedUsers: conObj.assignedUsers
          };
          setSelectedContact(updatedContacts[contIndex]);
          return updatedContacts;
        } else {
          // Fetch new contact if not found
          getLiveChatGetContacts(contactFilter, conObj.id);
          return prevContacts; // No changes needed in state
        }
      });
    });

    return () => {
      socket.off("message");
      socket.off("live_chat_contact");
    };
  }, []);

  useEffect(() => {
    if (selectedContact?.id) {
      dispatch(getChatHistory({
        criteria: {
          contactId: selectedContact?.contact?.id
        }, orderBy: [{ property: "id", direction: "DESC" }],
        size: 100, offset: 0,
      }, globalReducer.waba?.id, (h) => {
        if (Array.isArray(h)) {
          setMessageList(h.map(m => {
            m.messageText = JSON.parse(m.messageText);
            return m;
          })?.sort((a, b) => moment(a.createdAt) - moment(b.createdAt)));
        }
      }));
      setSelectedTags(allTags?.map(m => { return { text: m.text, checked: selectedContact?.tags?.includes(m.text) } }));
    }
  }, [selectedContact?.id]);

  useEffect(() => { getLiveChatGetContacts(contactFilter); }, [contactFilter]);

  const getLiveChatGetContacts = (filter, selectedId = 0) => {
    dispatch(liveChatGetContacts({ ...filter, size: 100000, offset: 0 }, s => {
      if (Array.isArray(s)) {
        setContacts(s);
        if (selectedId !== 0) {
          setSelectedContact(s.find(f => f.id === selectedId) ?? s[0]);
        } else {
          setSelectedContact(s.find(f => f.id === selectedContact?.id) ?? s[0]);
        }
      } else {
        setContacts([]);
        setSelectedContact({});
      }
    }));
  }

  const updateTicket = (data) => {
    dispatch(liveChatUpdateContacts(data, (a) => {
      getLiveChatGetContacts(contactFilter);
      setCloseChat(false);
      setShowAssignTicket(false);
      setShowPriorityPopup(false);
    }));
  }

  const updateReadMessage = (data, selectedContact) => {
    dispatch(liveChatUpdateContacts(data, (a) => {
      contacts.find(f => f.id === selectedContact?.id).agentReplied = true;
      setContacts([...contacts]);
    }));
  }

  const assignedFilter = (e) => {
    const filter = [];
    switch (e.target.value) {
      case "ALL_ASSIGNED":
        filter.push({ property: "assignedUsers", not: true, operator: "ISNULL" });
        break;
      case "NOT_ASSIGNED":
        filter.push({ property: "assignedUsers", not: false, operator: "ISNULL" });
        break;
      case "ALL_CLOSED":
        filter.push({ property: "ticketStatus", operator: "EQ", value: "CLOSED" });
        break;
      case "ALL_OPEN":
        filter.push({ property: "ticketStatus", operator: "EQ", value: "OPEN" });
        break;
      case "MY_ASSIGNED":
        filter.push({ property: "assignedUsers", operator: "IN", value: [globalReducer.loginInfo.uid] });
        break;
      case "MY_CLOSED":
        filter.push({ property: "assignedUsers", operator: "IN", value: [globalReducer.loginInfo.uid] }, { clause: "AND" }, { property: "ticketStatus", operator: "EQ", value: "CLOSED" });
        break;
      case "MY_OPEN":
        filter.push({ property: "assignedUsers", operator: "IN", value: [globalReducer.loginInfo.uid] }, { clause: "AND" }, { property: "ticketStatus", operator: "EQ", value: "OPEN" });
        break;
    }
    setContactFilter({ ...contactFilter, filter: filter });
  }

  const exportReport = () => {
    dispatch(getConversation({
      criteria: { contactId: selectedContact?.contact?.id, tenantWabaId: globalReducer.waba?.id, tenantId: globalReducer.loginInfo?.tenantId },
      tenantId: globalReducer.loginInfo?.tenantId,
      count: true,
      size: 10000,
      offset: 0,
      orderBy: [{ property: "id", direction: "DESC" }]
    }, (d) => {
      const data = d?.data?.map(m => {
        return {
          id: m.id,
          waba: `${globalReducer.waba?.teamName ?? ""}  ${globalReducer.waba?.phoneCode ? "+" + globalReducer.waba?.phoneCode : ""} ${globalReducer.waba?.phone ?? ""}`,
          whatsappNumber: m?.contact?.whatsappNumber,
          messageType: m.messageType,
          type: m.type,
          templateName: m.templateName,
          currencyCode: m.currencyCode,
          countryCode: m.countryCode,
          languageCode: m.languageCode,
          amount: m.amount,
          messageText: m.messageText,
          referenceId: m.referenceId,
          messageStatus: m.messageStatus,
          remarks: m.remarks,
          reply: m.reply,
          createdAt: m.createdAt,
        }
      })
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data ?? []);
      XLSX.utils.book_append_sheet(workbook, worksheet, "live_chat");
      XLSX.writeFile(workbook, 'live_chat_report.xlsx');
    }));
  };

  const getLatestMessage = () => {
    if (Array.isArray(messageList)) {
      const msg = messageList[messageList.length - 1];
      switch (msg?.type?.toLowerCase()) {
        case "text":
          return Array.isArray(msg?.messageText) ? msg?.messageText[0]?.text?.body : msg?.messageText?.text?.body;
        default:
          return msg?.type?.toLowerCase();
      }
    }
  }

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <FiInbox className="text-theme" />
            <div className="text-[14px] font-medium">{t('Live Chat')}</div>
          </div>
          <div className="flex justify-end gap-2">
            <div className="flex items-center flex-none">
              <div className="flex content-center justify-between w-full px-4">
                <div className="flex justify-start w-1/2 gap-2">
                  <div className="relative inline-flex items-center">
                    <button onClick={() => setShowTags(!showTags)} className="inline-flex items-center rounded-md p-1.5 pr-1 text-center justify-center font-medium outline-none focus:outline-none transition ease-out duration-200 bg-white mr-1.5">
                      <svg width={20} height={20} fill="#6B7280" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#6B7280" fillRule="evenodd" d="M19.248 9.151a1.2 1.2 0 0 1 0 1.697l-8.4 8.4a1.2 1.2 0 0 1-1.697 0l-8.4-8.4A1.196 1.196 0 0 1 .4 10V4A3.6 3.6 0 0 1 4 .4h6c.307 0 .614.117.848.351l8.4 8.4ZM4 5.2a1.2 1.2 0 1 0 0-2.4 1.2 1.2 0 0 0 0 2.4Z" clipRule="evenodd" />
                      </svg>
                      <div className="flex gap-1 pl-2">
                        {selectedContact?.tags?.map((tg, i) => <div key={i} className="px-1 bg-gray-100 text-sm font-medium text-gray-800 content-center rounded">{tg}</div>)}
                      </div>
                    </button>
                    {showTags && <div className="absolute left-0 right-0 top-8 z-20 mt-2 origin-top-right bg-white rounded-md shadow-lg max-w-[650px] min-w-[255px] ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100">
                      <div data-headlessui-state="">
                        <div className="p-3">
                          <div className="flex justify-between">
                            <label className="block text-sm font-semibold text-gray-800">
                              Tags
                            </label>
                            <div className="bg-white" onClick={() => setShowTags(false)}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-gray-500 cursor-pointer">
                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                        {/* <div className="px-3">
                          <div className="relative w-full">
                            <div className="absolute inset-y-0 flex items-center pointer-events-none left-0 rtl:right-2 pl-3">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="w-5 h-5 text-gray-400">
                                <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                              </svg>
                            </div>
                            <input type="text" placeholder="Search or add new tags" className="w-full py-2 pl-10 bg-white border border-gray-300 rounded-md shadow-sm pr-10 rtl:pr-8 focus:ring-1 focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                            />
                          </div>
                        </div> */}
                        <div className="bg-white rounded-md shadow-lg sm:text-sm">
                          <div className="px-3">
                            <div className="text-[#0078CF] cursor-pointer" onClick={() => { selectedTags?.map(st => { st.checked = false }); setSelectedTags([...selectedTags]); }}> Unselect all</div>
                          </div>
                          <div className="items-center overflow-auto max-h-60">
                            {selectedTags?.map((st, i) =>
                              <div className="flex px-3 py-2.5" key={i}>
                                <div className="flex items-center h-5">
                                  <input type="checkbox" checked={st?.checked} className="w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary"
                                    onChange={(e) => { selectedTags[i].checked = e.target.checked; setSelectedTags([...selectedTags]); }} />
                                </div>
                                <div className="flex w-full ml-2 mr-2 text-sm text-left">
                                  <div className="w-[95%]">
                                    <span className="block truncate text-sm font-normal text-gray-900">
                                      {st.text}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="sticky flex w-full h-[46px] p-1.5 bg-white border-t justify-between rounded-b-md">
                            <div className="content-center justify-start px-1" />
                            <div className="justify-end flex gap-3">
                              <button className="inline-flex items-center text-center px-2.5 py-1.5 text-xs rounded  border font-medium outline-none border-gray-300 bg-white hover:border-primary focus:outline-none transition ease-out duration-200 text-gray-700" onClick={() => setShowTags(false)}>
                                Cancel
                              </button>
                              <button className="inline-flex items-center text-center px-2.5 py-1.5 text-xs rounded  border font-medium outline-none border-gray-300 bg-white hover:border-primary focus:outline-none transition ease-out duration-200 text-white bg-theme"
                                onClick={(e) => { updateTicket({ "ids": [selectedContact?.id], "tags": selectedTags?.filter(f => f.checked).map(m => m.text) }); setShowTags(false); }}>Save Changes</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    <label className="text-[14px] ml-1">{selectedContact?.ticketNumber}</label>
                  </div>
                  <button type="button" aria-haspopup="menu" className="flex items-center px-2 space-x-2 bg-white border border-dashed rounded-md shadow-sm focus:outline-none focus:ring-none">
                    <div className="flex gap-2" onClick={() => setShowPriorityPopup(!showPriorityPopup)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide pt-1 lucide-flag w-6 h-6">
                        <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z" />
                        <line x1={4} x2={4} y1={22} y2={15} />
                      </svg>
                      <label className={"text-[14px] text-" + priorityTextColor[selectedContact?.priority]}>{toTitleCase(selectedContact?.priority?.toLowerCase())}</label>
                      {showPriorityPopup && <div className="relative"> <div className="absolute z-50 mt-2 w-[200px] top-6 origin-top-right right-0 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {["LOW", "MEDIUM", "HIGH", "URGENT"].map((item, i) => (
                            <div key={i} className={`px-4 py-2 flex justify-between items-center border-b hover:bg-gray-50`} onClick={() => updateTicket({ "ids": [selectedContact?.id], "priority": item })}>
                              <p className={"text-[14px] text-" + priorityTextColor[item]}>{toTitleCase(item.toLowerCase())}</p>
                              <p className={"text-[14px] text-" + priorityTextColor[item]}>{item === selectedContact?.priority && <i className="fa-solid fa-check"></i>}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                      </div>}
                    </div>
                    <div className="shrink-0 bg-border border-l w-[1px] h-3.5 mx-2 text-background-container" />
                    <div className="flex items-center px-2 py-[1.5px] space-x-2 border rounded-md border-border-light h-[22px] w-fit">
                      <span className={`w-[6.25px] h-[6.25px] rounded-full ${selectedContact?.ticketStatus === "OPEN" ? "bg-[#04B25F]" : "bg-gray-500"}`} />
                      <span className={`text-xs ${selectedContact?.ticketStatus === "OPEN" ? "text-[#04B25F]" : "text-gray-500"}`}>{selectedContact?.ticketStatus}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <button className="flex gap-2 px-3 py-1.5 rounded-md bg-[#EED1D2] text-theme" onClick={() => setCloseChat(true)}>
                {t('Close Chat')}
              </button>
            </div>
            <div className="border w-9 h-9 p-2.5 border-gray-300 rounded-md">
              <Dropdown inline arrowIcon={false} inputMode as="button" label={<i className="fa-solid fa-ellipsis"></i>}
                className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white">
                <Dropdown.Item className="hover:text-theme" onClick={() => { updateTicket({ "ids": [selectedContact?.id], "ticket_status": "HOLD" }) }}>
                  <i className="fa-regular fa-circle-pause pr-2"></i> {t('Hold Ticket')}
                </Dropdown.Item>
                <Dropdown.Item className="hover:text-theme" onClick={() => exportReport()}>
                  <i className="fa-solid fa-table pr-2"></i> {t('Download as Report')}
                </Dropdown.Item>
                <Dropdown.Item className="hover:text-theme" onClick={() => setShowAssignTicket(true)}>
                  <i className="fa-solid fa-message pr-2"></i> Assign Ticket
                </Dropdown.Item>
                {/* <Dropdown.Item className="hover:text-theme">
                  <i className="fa-solid fa-copy pr-2"></i> Copy Link
                </Dropdown.Item> */}
              </Dropdown>
            </div>
            <div className="border w-9 h-9 p-3 border-gray-300 rounded-md cursor-pointer" onClick={() => setViewUserInfo(!viewUserInfo)}>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.74995 1.0835C8.08325 0.750196 8.58325 0.750196 8.91655 1.0835C9.24995 1.4168 9.24995 1.9168 8.91655 2.2502L6.16655 5.0002L8.91655 7.7502C9.08325 7.9168 9.16655 8.0835 9.16655 8.3335C9.16655 8.8335 8.83325 9.1668 8.33325 9.1668C8.08325 9.1668 7.91655 9.0835 7.74995 8.9168L4.41655 5.5835C4.08325 5.2502 4.08325 4.7502 4.41655 4.4168L7.74995 1.0835ZM1.66655 0.833496C2.16655 0.833496 2.49995 1.1668 2.49995 1.6668V8.3335C2.49995 8.8335 2.16655 9.1668 1.66655 9.1668C1.16655 9.1668 0.833252 8.8335 0.833252 8.3335V1.6668C0.833252 1.1668 1.16655 0.833496 1.66655 0.833496Z" fill="#6B7280" />
              </svg>
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full h-full overflow-hidden bg-white rounded-lg shadow-sm">
          <div className="w-1/4 h-full">
            <div className="h-auto z-[99]">
              <div className="flex flex-col items-center justify-between w-full h-16 px-3 border-l-2 border-r-2 border-b border-solid lg:flex-row border-gray-200">
                <div className="relative inline-block text-left" data-headlessui-state="">
                  <div className="flex gap-2 items-center">
                    <select className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]" onChange={assignedFilter}>
                      <option value={""} selected>{t('Select status')}</option>
                      {globalReducer.loginInfo?.roleDetails?.some((s) => ["TENANT_SUPER_ADMIN", "TENANT_ADMIN"].includes(s.name)) && <>
                        <option value={"ALL_ASSIGNED"}>{t('All Assigned')}</option>
                        <option value={"NOT_ASSIGNED"}>{t('Not Assigned')}</option>
                        <option value={"ALL_CLOSED"}>{t('All Closed')}</option>
                        <option value={"ALL_OPEN"}>{t('All Open')}</option>
                      </>}
                      <option value={"MY_ASSIGNED"}>{t('My Assigned')}</option>
                      <option value={"MY_CLOSED"}>{t('My Closed')}</option>
                      <option value={"MY_OPEN"}>{t('My Open')}</option>
                    </select>
                    <div className="font-medium rounded inline">
                      <span className="bg-[#FFECE5] py-1 px-2.5 text-gray-500 rounded-[16px]">
                        {new Intl.NumberFormat("en-US").format(contacts?.length ?? 0)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex gap-1.5">
                  {globalReducer.loginInfo?.roles?.some(s => ["TENANT_ADMIN", "TENANT_SUPER_ADMIN", "SUPERVISOR"].includes(s)) && <>
                    <button className="p-1 active:text-primary" onClick={() => setAddNumber(true)}><i className="fa-solid fa-pen-to-square text-[#6B7280]"></i></button>
                    <button className="p-1 cursor-pointer block" onClick={() => { setCheckedShow(!checkedShow); setAssignTicket({ ...assignTicket, show: false }) }}>
                      <svg width={17} height={17} className="w-4 h-4" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M3 1.67H14C14.7345 1.67 15.33 2.26546 15.33 3V14C15.33 14.7345 14.7345 15.33 14 15.33H3C2.26546 15.33 1.67 14.7345 1.67 14V3C1.67 2.26546 2.26546 1.67 3 1.67ZM0 3C0 1.34315 1.34315 0 3 0H14C15.6569 0 17 1.34315 17 3V14C17 15.6569 15.6569 17 14 17H3C1.34315 17 0 15.6569 0 14V3ZM12.0904 6.59043C12.4165 6.26435 12.4165 5.73565 12.0904 5.40957C11.7643 5.08348 11.2357 5.08348 10.9096 5.40957L6.85714 9.46199L5.59043 8.19528C5.26435 7.86919 4.73565 7.86919 4.40957 8.19528C4.08348 8.52137 4.08348 9.05006 4.40957 9.37615L6.26671 11.2333C6.5928 11.5594 7.12149 11.5594 7.44758 11.2333L12.0904 6.59043Z"
                          fill="#6B7280" />
                      </svg>
                    </button>
                    {checkedShow && <div className="p-[16px] bg-white rounded-[4px] text-[14px] mt-4 dropdown-shadow absolute w-[290px] min-h-[100px] top-[35px] z-[99] left-[280px]">
                      <div className="flex items-center justify-between">
                        <div className="font-semibold text-[16px] text-[#374151]">
                          {contacts?.filter(f => f.selected)?.length} {t('conversations selected')}
                        </div>
                        <button onClick={() => { setCheckedShow(false) }} aria-label="Close"
                          className="aa ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                          type="button">
                          <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                          </svg>
                        </button>
                      </div>
                      {assignTicket?.show ? <div className="gap-2 my-[10px]">
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          onChange={(a) => setAssignTicket({ ...assignTicket, agentId: a.target.value })}>
                          <option selected value={""}>{t('Select Agents')}</option>
                          {agents?.map((a, i) => <option key={i} value={a.uid}>{a.name}</option>)}
                        </select>
                        <button type='button' onClick={() => { updateTicket({ "ids": contacts?.filter(f => f.selected)?.map(m => m.id), "assigned_users": [assignTicket?.agentId?.toString()] }) }} className="w-full mb-2 mt-2 bg-theme text-white rounded-[6px] h-[40px] px-[12px] py-[4px]">
                          {t('Assign')}
                        </button>
                      </div> : <div className="my-[10px]">
                        <div className="text-gray-300 text-[14px] font-semibold mb-[8px] text-left">
                          <label>{t('Select multiple tickets to perform a bulk action')}</label>
                        </div>
                        <div className="flex gap-5">
                          {contacts?.filter(f => f.selected).length > 0 ? <p className="text-theme cursor-pointer" onClick={() => {
                            contacts?.map(m => m.selected = false);
                            setContacts([...contacts]);
                          }}> Unselect All</p> : <p className="text-theme cursor-pointer" onClick={() => {
                            contacts?.map(m => m.selected = true);
                            setContacts([...contacts]);
                          }}>Select All</p>}
                        </div>
                        <div className="text-left flex  gap-2">
                          <button type='button' onClick={() => { setAssignTicket({ ...assignTicket, show: true }) }} className="w-full mb-2 bg-theme text-white rounded-[6px] h-[40px] px-[12px] py-[4px]">
                            {t('Assign')}
                          </button>
                          <button type='button' onClick={() => { updateTicket({ "ids": contacts?.filter(f => f.selected)?.map(m => m.id), "ticket_status": "CLOSED" }) }} className="w-full mb-2 bg-theme text-white rounded-[6px] h-[40px] px-[12px] py-[4px]">
                            {t('Close Chat')}
                          </button>
                        </div>
                      </div>}
                    </div>}
                  </>}
                  <button className="p-1 aa cursor-pointer" onClick={() => setSearchShow(!searchShow)}>
                    <svg width={17} height={17} className="w-4 h-4" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.55703 0C3.3834 0 0 3.3834 0 7.55703C0 11.7306 3.3834 15.1141 7.55703 15.1141C9.3235 15.1141 10.9484 14.508 12.2352 13.4924L15.4823 16.7395C15.8295 17.0867 16.3924 17.0867 16.7396 16.7395C17.0868 16.3923 17.0868 15.8294 16.7396 15.4822L13.4925 12.2351C14.508 10.9484 15.1141 9.32342 15.1141 7.55703C15.1141 3.3834 11.7306 0 7.55703 0ZM1.77812 7.55703C1.77812 4.36543 4.36543 1.77812 7.55703 1.77812C10.7487 1.77812 13.3359 4.36543 13.3359 7.55703C13.3359 10.7487 10.7487 13.3359 7.55703 13.3359C4.36543 13.3359 1.77812 10.7487 1.77812 7.55703Z" fill="#6B7280" />
                    </svg>
                  </button>
                </div>
              </div>
              {searchShow && <SearchExistingTicket agents={agents} onSelect={(s) => {
                const con = contacts.findIndex(f => f.id === s.id);
                if (con > -1) {
                  contacts[con] = s;
                  setContacts([...contacts]);
                } else {
                  setContacts([...contacts, s]);
                }
                setSelectedContact(s);
                setSearchShow(false);
              }} />}
              <div className="flex justify-between">
                <div className="relative inline-block w-full text-left">
                  <button className="w-full" type="button">
                    <div className="flex justify-between  pl-3 pr-3 py-1 text-gray-500 bg-gray-50 border-l-2 border-r-2 border-b-2 border-solid border-gray-100 w-full focus:ring-0 focus:ring-offset-0">
                      <div className="inline-flex gap-2 items-center" onClick={() => {
                        setContactFilter({ ...contactFilter, direction: contactFilter?.direction === "DESC" ? "ASC" : "DESC", orderBy: [{ property: "lastMessageDate", direction: contactFilter?.direction === "DESC" ? "ASC" : "DESC" }] });
                      }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9CA3AF" className="inline-block float-left w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                        </svg>
                        <span className="float-right">{contactFilter?.direction === "DESC" ? "Newest" : "Oldest"}</span>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="relative w-full">
                  <button className="w-full focus:ring-0 focus:ring-offset-0" type="button">
                    <div className="flex relative justify-end items-center pr-4 pl-4 py-1 text-gray-500 bg-gray-50 border-r-1 border-l border-b-2 border-solid border-gray-100 w-full focus:ring-0 focus:ring-offset-0">
                      <div className="inline-flex gap-2 items-center" onClick={() => setFilterShow(!filterShow)}>
                        <svg width={16} height={16} className="inline-block float-left w-5 h-5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.66602 4.66675H13.3327" stroke="#6B7280" strokeWidth="1.33333" strokeLinecap="round" />
                          <path d="M4 8H12" stroke="#6B7280" strokeWidth="1.33333" strokeLinecap="round" />
                          <path d="M6 11.3333H10" stroke="#6B7280" strokeWidth="1.33333" strokeLinecap="round" />
                        </svg>
                        <span className="float-right font-medium text-gray-500">
                          {t('Filter')}
                        </span>
                      </div>
                      {filterShow && (<FilterAgents agents={agents} setFilterShow={setFilterShow} filterShow={filterShow} onFilter={(f) => {
                        if (f.agentId) {
                          contactFilter.criteria.assignedUsers = [f.agentId]
                        } else {
                          delete contactFilter.criteria.assignedUsers;
                        }
                        if (f.fromDate && f.toDate) {
                          contactFilter.filter = [{
                            property: "lastMessageDate",
                            operator: "RANGE",
                            lowerBound: Date.parse(f.fromDate),
                            upperBound: Date.parse(f.toDate),
                          }];
                        } else {
                          contactFilter.filter = [];
                        }
                        if (f.tags && f.tags?.length > 0) {
                          contactFilter.criteria.tags = f.tags;
                        }
                        else {
                          delete contactFilter.criteria.tags;
                        }
                        setContactFilter({ ...contactFilter });
                      }} />)}
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap gap-1 text-[14px] px-2 py-1 border-b-2 border-gray-100 border-l-2 border-r-2">
                <button className={`px-2 py-1 rounded-3xl text-gray-500 hover:bg-gray-50 ${contactFilter.criteria.agentReplied === null || contactFilter.criteria.agentReplied === undefined ? "font-semibold bg-gray-100" : "font-medium"} `} onClick={() => {
                  delete contactFilter.criteria.agentReplied; setContactFilter({ ...contactFilter });
                }}>
                  {t('All Chats')}
                </button>
                <button className={`px-2 py-1 rounded-3xl text-gray-500 hover:bg-gray-50 ${contactFilter.criteria.agentReplied === true ? "font-semibold bg-gray-100" : "font-medium"} `} onClick={() => {
                  setContactFilter({ ...contactFilter, criteria: { agentReplied: true } });
                }}>
                  {t('Replied')}
                </button>
                <button className={`px-2 py-1 rounded-3xl text-gray-500 hover:bg-gray-50 ${contactFilter.criteria.agentReplied === false ? "font-semibold bg-gray-100" : "font-medium"} `} onClick={() => {
                  setContactFilter({ ...contactFilter, criteria: { agentReplied: false } });
                }}>
                  {t('Unreplied')}
                </button>
              </div>
            </div>
            <div className={`overflow-y-auto border-l-2 border-l-gray-100 ${searchShow ? "lc_leftpanel_fix2" : "lc_leftpanel_fix1"}`}>
              {contacts?.map((c, i) =>
                <div key={i} className="relative cursor-pointer border-r-2 border-gray-100">
                  <div className={`flex p-3 items-center gap-2 border-b border-l-0 hover:bg-[#F5F6FA] ${c.id === selectedContact?.id ? "bg-gray-200" : ""}`}>
                    {checkedShow && (
                      <div className="w-[5%]">
                        <div className="flex items-center mb-4">
                          <input id="checked-checkbox" type="checkbox" checked={c.selected} className="w-4 h-4 text-theme cursor-pointer bg-gray-100 border-gray-300 rounded-sm"
                            onChange={(e) => { c.selected = e.target.checked; setContacts([...contacts]) }} />
                        </div>
                      </div>
                    )}
                    <div className="relative flex items-center w-[15%]" onClick={() => { updateReadMessage({ "ids": [selectedContact?.id], "agent_replied": true }, selectedContact); setSelectedContact(c); }}>
                      <img src={`${process.env.PUBLIC_URL}/images/default-avatar.png`} className="object-cover w-9 h-9 content-between rounded-full" />
                    </div>
                    <div className="flex-1 m-auto overflow-hidden leading-6" onClick={() => { updateReadMessage({ "ids": [selectedContact?.id], "agent_replied": true }, selectedContact); setSelectedContact(c); }}>
                      <div className="w-full">
                        <div className="justify-end flex flex-shrink-0 items-center">
                          <div className={`inline-block w-[6px] h-[6px] rounded-full shadow-sm ${c?.agentReplied ? "bg-gray-500" : "bg-[#0078CF]"} ml-2`} />
                          <span className="text-[12px] font-normal ml-2 text-[#111827]">
                            {moment.utc(c.lastMessageDate).tz(wabaTimezone).fromNow()}
                          </span>
                          {/* <div>
                            <svg width={13} height={14} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.8125 10.5469L3.35417 13.0728" stroke="#A1A1AA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M1.27637 8.50509L8.34891 12.5884L8.86224 11.6993C8.97067 11.5113 9.02449 11.2967 9.01766 11.0798C9.01083 10.8628 8.94361 10.6521 8.82357 10.4713L8.18685 9.49745C8.06681 9.31662 7.9996 9.10588 7.99277 8.88894C7.98594 8.672 8.03976 8.45745 8.14818 8.26942L9.53652 5.86476L10.0417 6.15643C10.3097 6.31114 10.6281 6.35306 10.927 6.27298C11.2259 6.19289 11.4807 5.99736 11.6354 5.7294C11.7901 5.46143 11.832 5.14299 11.7519 4.84411C11.6719 4.54523 11.4763 4.29041 11.2084 4.1357L7.16691 1.80237C6.89895 1.64766 6.5805 1.60573 6.28162 1.68582C5.98275 1.7659 5.72793 1.96143 5.57322 2.2294C5.41851 2.49736 5.37658 2.81581 5.45666 3.11469C5.53675 3.41356 5.73228 3.66838 6.00025 3.82309L6.50543 4.11476L5.11709 6.51942C5.00847 6.70734 4.84958 6.86122 4.65829 6.96378C4.46699 7.06633 4.25088 7.11349 4.03426 7.09995L2.87254 7.03545C2.65591 7.02191 2.4398 7.06906 2.24851 7.17162C2.05722 7.27417 1.89832 7.42806 1.7897 7.61597L1.27637 8.50509Z"
                                stroke="#A1A1AA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          </div> */}
                        </div>
                        <span className={`flex-grow text-sm mt-[-10px] leading-6 flex justify-between text-gray-900 ${c?.agentReplied ? "" : "font-bold"}`}>
                          <span className="flex w-full line-clamp-1">
                            {c.contact?.name}
                          </span>
                        </span>
                      </div>
                      <div className="flex-1 w-[92%] overflow-hidden">
                        <p className={`text-[12px] truncate ${c?.agentReplied ? "" : "font-semibold"} text-gray-900`}>
                          {c?.lastMessage}
                        </p>
                      </div>
                      <div className="flex items-center justify-between my-1">
                        <div className="flex items-center rounded max-w-[85%] gap-1">
                          <span className="flex w-full text-[10px] px-1 line-clamp-1 font-medium rounded text-gray-900 bg-gray-100">
                            {c.assignedUsers?.includes(globalReducer.loginInfo?.uid) ? "You" : agents?.find(f => c.assignedUsers?.includes(f.uid))?.name}
                          </span>
                          <div className="flex items-center px-2 py-[1.5px] space-x-2 border rounded-md border-border-light h-[22px] w-fit">
                            <span className={`w-[6.25px] h-[6.25px] rounded-full ${c.ticketStatus === "OPEN" ? "bg-[#04B25F]" : "bg-gray-500"}`} />
                            <span className={`text-[10px] ${c.ticketStatus === "OPEN" ? "text-[#04B25F]" : "text-gray-500"} font-medium`}>
                              {c.ticketStatus}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
          <div className="flex-1 border-l rc_rightpanel_fix1 flex flex-col gap-2 border-r w-1/2 p-[12px] bg-[#F9FAFB]">
            <div className="w-full px-2 pt-2 h-[77%] bg-white rounded-[8px] overflow-auto" style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/w-icons.png")`, backgroundSize: "auto", backgroundRepeat: "repeat-x", backgroundColor: "#E5DDD5", backgroundBlendMode: "soft-light" }}>
              <LiveChatPreview messageList={messageList} contact={selectedContact} setCanSendMsg={setCanSendMsg} />
            </div>
            <SendWhatsAppMessageView canSendMsg={canSendMsg} selectedContact={selectedContact} setSelectedContact={setSelectedContact} setSendMessageTemplate={setAddNumber} />
          </div>
        </div>
      </div>
      <RightSidePanel ModalTitle={t("Send Message")}
        CustomClass="fixed md:w-[60%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={addNumber}
        setIsShow={setAddNumber}
        Component={
          <SendTemplateMessageView setIsShow={setAddNumber} isLiveChat={true} />
        }
      />
      {/* Close Chat Popup */}
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t('Are you sure to close chat?')}
            </div>
            <div className="font-medium text-[14px] text-[#6E6C6D]">
              {t('You can open this chat anytime.')}
            </div>
          </>
        }
        isShowModal={closeChat}
        setIsShowModal={setCloseChat}
        ModalBody={
          <div className="flex justify-end gap-4 p-6" setCloseChat={setCloseChat}>
            <button onClick={() => setCloseChat(false)} className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]">
              {t('Cancel')}
            </button>
            <button onClick={() => updateTicket({ "ids": [selectedContact?.id], "ticket_status": "CLOSED" })} className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
              {t('Close Chat')}
            </button>
          </div>
        }
        size="md"
      />

      <PopUp
        ModalHeader={
          <div className="font-medium text-[18px] text-black">
            {t('Assign Ticket')}
          </div>
        }
        isShowModal={showAssignTicket}
        setIsShowModal={setShowAssignTicket}
        ModalBody={
          <div className="gap-2 my-5 px-7">
            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              onChange={(a) => setAssignTicket({ ...assignTicket, agentId: a.target.value })}>
              <option selected value={""}>{t('Select Agents')}</option>
              {agents?.map((a, i) => <option key={i} value={a.uid}>{a.name}</option>)}
            </select>
            <button type='button' onClick={() => { updateTicket({ "ids": [selectedContact?.id], "assigned_users": [assignTicket?.agentId.toString()] }) }} className="w-full mb-2 mt-2 bg-theme text-white rounded-[6px] h-[40px] px-[12px] py-[4px]">
              {t('Assign')}
            </button>
          </div>
        }
        size="sm"
      />
      {/* User Information */}
      <RightSidePanel
        CustomClass="fixed md:w-[30%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewUserInfo}
        setIsShow={setViewUserInfo}
        Component={
          <LiveChatUserInfo setViewUserInfo={setViewUserInfo} contact={selectedContact} />
        }
      />
    </div >
  );
}

export default LiveChat;
