import React, { useEffect } from "react";
import WhatsAppTemplatePreview from "../../components/WhatsAppTemplatePreview";
import { useState } from "react";
import {
  calculateBroadcastPayment,
  campaignCreate,
  categoryQuery,
  getCampaignContact,
  getTenantContacts,
  saveAsDraft,
  searchWhatsAppMsgTemplate,
  sendWhatsAppMessage,
} from "../../store/actions/tenantAction";
import { useDispatch, useSelector } from "react-redux";
import SetTemplateVariableValue from "./SetTemplateVariableValue";
import TestMessage from "./TestMessage";
import PopUp from "../../components/PopUp";
import { SHOW_SNACKBAR } from "../../utils/types";
import RightSidePanel from "../../components/RightSidePanel";
import ImportExcel from "../../components/ImportExcel";
import AddContact from "./AddContact";
import ChooseContact from "./ChooseContact";
import SelectDateTime from "../../components/SelectDateTime";
import { Autocomplete, TextField } from "@mui/material";
import { dayMap } from "../../utils/helper";
import MultiSelect from "../../components/Dropdowns/MultiSelect";
import SelectTime from "../../components/SelectTime";
import {
  getVariableComponent,
  onSelectTemplate,
} from "../../utils/TemplateHelper";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ChooseCategory from "./ChooseCategory";
import SelectAudiences from "./SelectAudiences";

function CreateBroadcast({
  setIsShow,
  onAdd,
  isEdit,
  selectedBroadcast,
  selectedTemplateId,
  selectedCategory,
  selectedContactIds,
  onAddInList,
  isDynamicValue = false
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [contacts, setContacts] = useState([]);
  const [paymentInformation, setPaymentInformation] = useState({});
  const [showSendTestMessage, setShowSendTestMessage] = useState(false);
  const [showContactUpload, setShowContactUpload] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showChooseContacts, setShowChooseContacts] = useState(false);
  const [showChooseCategory, setShowChooseCategory] = useState(false);
  const [carouselTemplate, setCarouselTemplate] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([{ categoryName: "GENERIC_EXTERNAL" }]);
  const [template, setTemplate] = useState({
    templateType: "GENERIC",
    category: "MARKETING",
    language: "English",
    header: { type: "HEADER", format: "" },
    body: "",
    footer: "",
    buttons: [],
  });
  const [broadcast, setBroadcast] = useState({
    campaignType: "INTERNAL",
    scheduleType: "IMMEDIATE",
    broadcastType: "ONEGO",
    detail: {},
  });
  const [step, setStep] = useState(1);
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const globalReducer = useSelector((state) => state.globalReducer);
  const columnValidation = [
    {
      name: "customerId",
      displayName: "Customer Id",
      type: "text",
      isMandatory: false,
    },
    { name: "name", displayName: "Name", type: "text", isMandatory: false },
    { name: "gender", displayName: "Gender", type: "text", isMandatory: false },
    { name: "phone", displayName: "Phone", type: "text", isMandatory: true },
    { name: "email", displayName: "Email", type: "email", isMandatory: false },
    {
      name: "location",
      displayName: "location",
      type: "text",
      isMandatory: false,
    },
  ];

  useEffect(() => {
    dispatch(
      searchWhatsAppMsgTemplate(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          criteria: {
            active: true,
            tenantWabaId: globalReducer.waba?.id,
            status: "APPROVED",
          },
          size: 100000,
          offset: 0,
          orderBy: [{ property: "id", direction: "DESC" }]
        },
        (swt) => {
          setTemplates(swt.data ?? []);
        }
      )
    );
    if (isEdit) {
      setBroadcast(selectedBroadcast);
      dispatch(
        getCampaignContact(
          selectedBroadcast.tenantId,
          selectedBroadcast.id,
          (c) => {
            setContacts(
              c.campaignContacts?.map((m) => {
                return { ...m.contact, campaignDetailsId: m.id };
              })
            );
          }
        )
      );
    } else {
      setBroadcast({ ...broadcast, templateId: selectedTemplateId });
    }
    dispatch(
      categoryQuery(
        {
          tenantId: globalReducer.loginInfo.tenantId,
          count: true,
          size: 100,
          filter: [
            { clause: "OPEN_BRACKET" },
            {
              property: "permission",
              operator: "CONTAINS",
              value: "PUBLIC",
            },
            { clause: "OR" },
            { clause: "OPEN_BRACKET" },
            {
              property: "permission",
              operator: "CONTAINS",
              value: "PRIVATE",
            },
            { clause: "AND" },
            {
              property: "uid",
              operator: "EQ",
              value: globalReducer.loginInfo?.uid,
            },
            { clause: "CLOSE_BRACKET" },
            { clause: "CLOSE_BRACKET" },
          ],
        },
        (d) => {
          setCategories(d?.data ?? []);
        }
      )
    );
  }, [globalReducer.waba]);

  useEffect(() => {
    if (broadcast?.templateId && templates?.length > 0) {
      onSelectTemplate(
        templates,
        setSelectedTemplate,
        setTemplate,
        setCarouselTemplate,
        broadcast?.templateId
      );
    }
  }, [broadcast?.templateId, templates]);

  useEffect(() => {
    const filter = [];
    const criteria = {};
    if (selectedContactIds?.length > 0) {
      filter.push({ property: "id", operator: "IN", value: selectedContactIds });
    }
    if (selectedCategory) {
      criteria.tenantId = globalReducer.loginInfo?.tenantId;
      criteria.active = true;
      criteria.category = selectedCategory;
    }
    if (Object.keys(criteria)?.length > 0 || filter?.length > 0) {
      dispatch(getTenantContacts(
        {
          filter: filter,
          criteria: criteria,
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: 10000000,
          offset: 0,
        },
        (d) => {
          setContacts(d?.data ?? []);
        }
      ));
    }
  }, [selectedCategory, selectedContactIds?.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const clickedButton = e.nativeEvent.submitter;
    if (clickedButton?.value === "TestMessage") {
      setShowSendTestMessage(true);
    } else if (clickedButton?.value === "Next") {
      if (step === 1) {
        dispatch(saveAsDraft({
          ...broadcast,
          scheduledTime: broadcast?.scheduledTime,
          templateId: parseInt(broadcast.templateId),
          tenantId: globalReducer.loginInfo?.tenantId,
          tenantWabaId: globalReducer.waba?.id,
          waba: globalReducer.waba,
          template: selectedTemplate,
          templateValue: {
            components: await getVariableComponent(
              dispatch,
              globalReducer.loginInfo,
              globalReducer.waba,
              template,
              carouselTemplate
            ),
          },
        }, (a) => {
          if (!broadcast?.id) {
            onAddInList(a);
          }
          setBroadcast({ ...broadcast, id: a?.id });
          setStep(step < 3 ? step + 1 : step);
        }));
      }
      if (step === 2) {
        if (contacts?.length == 0) {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              message: "please add at least one contact",
              open: true,
            },
          });
          return;
        }
        dispatch(
          calculateBroadcastPayment(
            {
              ...broadcast,
              scheduledTime: broadcast?.scheduledTime,
              templateId: parseInt(broadcast.templateId),
              tenantId: globalReducer.loginInfo?.tenantId,
              tenantWabaId: globalReducer.waba?.id,
              //waba: globalReducer.waba,
              template: selectedTemplate,
              campaignDetails: getUniqueContact(),
            },
            (a) => {
              setStep(step < 3 ? step + 1 : step);
              setPaymentInformation(a);
            }
          )
        );
      } else if (step === 3) {
        delete selectedTemplate?.waba;
        dispatch(
          campaignCreate(
            {
              ...broadcast,
              scheduledTime: broadcast?.scheduledTime,
              templateId: parseInt(broadcast.templateId),
              tenantId: globalReducer.loginInfo?.tenantId,
              tenantWabaId: globalReducer.waba?.id,
              waba: globalReducer.waba,
              template: selectedTemplate,
              templateValue: {
                components: await getVariableComponent(
                  dispatch,
                  globalReducer.loginInfo,
                  globalReducer.waba,
                  template,
                  carouselTemplate
                ),
              },
              campaignDetails: getUniqueContact(),
            },
            (a) => {
              setStep(step < 3 ? step + 1 : step);
              setIsShow(false);
              onAdd(a);
            }
          )
        );
      }
    }
  };

  const onSaveAsDraft = async () => {
    dispatch(saveAsDraft({
      ...broadcast,
      scheduledTime: broadcast?.scheduledTime,
      templateId: parseInt(broadcast.templateId),
      tenantId: globalReducer.loginInfo?.tenantId,
      tenantWabaId: globalReducer.waba?.id,
      waba: globalReducer.waba,
      template: selectedTemplate,
      templateValue: {
        components: await getVariableComponent(
          dispatch,
          globalReducer.loginInfo,
          globalReducer.waba,
          template,
          carouselTemplate
        ),
      },
    }, (a) => {
      if (!broadcast?.id) {
        onAddInList(a);
      }
      setBroadcast({ ...broadcast, id: a?.id });
    }));
  }

  const getUniqueContact = () => {
    const UniqueContacts = [];
    contacts.map((c) => {
      if (!UniqueContacts.some(s => s.whatsappNumber == c.whatsappNumber)) {
        UniqueContacts.push(
          {
            id: c.campaignDetailsId,
            tenantId: globalReducer.loginInfo?.tenantId,
            contactId: c.id,
            contact: {
              ...c,
              whatsappNumber: parseInt(
                (c.whatsappNumber + "")?.replace("+", "")
              ),
            },
          });
      }
    })
    return UniqueContacts;
  }

  const exportInvalidContact = (invalidContacts) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(
      invalidContacts?.map((m) => {
        return {
          customerId: m.customerId,
          whatsappNumber: m.whatsappNumber,
          name: m.name,
          gender: m.gender,
          email: m.email,
          location: m.location,
          category: m.category?.join(),
        };
      })
    );
    XLSX.utils.book_append_sheet(workbook, worksheet, `Contacts`);
    XLSX.writeFile(workbook, "invalid_contacts.xlsx");
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (<>
    <form className="height30 flex flex-col justify-between pb-3" onSubmit={handleSubmit}>
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-3 gap-2 p-2">
          <div
            className={`border-t border-2 ${step === 1 ? "border-theme" : "border-gray-300"
              }`}
          />
          <div
            className={`border-t border-2 ${step === 2 ? "border-theme" : "border-gray-300"
              }`}
          />
          <div
            className={`border-t border-2 ${step === 3 ? "border-theme" : "border-gray-300"
              }`}
          />
          <div>
            <p
              className={`text-[14px] ${step === 1 ? "text-theme" : "text-gray-500"
                }`}
            >
              {step > 1 ? t("DONE") : t("STEP 1")}
            </p>
            <p className="text-[14px]">{t("Broadcast Details")}</p>
          </div>
          <div>
            <p
              className={`text-[14px] ${step === 2 ? "text-theme" : "text-gray-500"
                }`}
            >
              {step > 2 ? t("DONE") : t("STEP 2")}
            </p>
            <p className="text-[14px]">{t("Select Audiences")}</p>
          </div>
          <div>
            <p
              className={`text-[14px] ${step === 3 ? "text-theme" : "text-gray-500"
                }`}
            >
              {t("STEP 3")}
            </p>
            <p className="text-[14px]">{t("Summary")}</p>
          </div>
        </div>
        <div>
          <div className={`${step === 1 ? "" : "hidden"} grid grid-cols-12`}>
            <div className="px-3 py-2 overflow-y-auto col-span-7">
              <div className="" style={{ height: "calc(-230px + 100vh)" }}>
                <div className="grid gap-3 w-full pb-2">
                  <div className="w-full">
                    <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                      {t("Campaign Name")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <input
                        required
                        value={broadcast?.campaignName}
                        type="text"
                        className="block w-full py-1.5 rounded-md border-gray-300 pr-10 rtl:pl-10 placeholder-gray-400 focus:border-primary focus:outline-none sm:text-sm"
                        placeholder="e.g. My First Broadcast"
                        onChange={(e) =>
                          setBroadcast({
                            ...broadcast,
                            campaignName: e.target.value,
                          })
                        }
                        data-maxlength={24}
                        onInput={OnInput}
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 rtl:left-0 flex items-center pr-3 rtl:pl-3">
                        <p className="text-xs text-gray-400 py-1 px-1 bg-gray-50 rounded">
                          {broadcast?.campaignName?.length}/24
                        </p>
                      </div>
                    </div>
                  </div>
                  {globalReducer.loginInfo?.roleDetails?.some(
                    (s) => s.name === "TENANT_SUPER_ADMIN"
                  ) && (
                      <div className="w-full">
                        <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                          {t("Campaign Type")}
                          <span className="text-red-500 text-[14px]">*</span>
                        </label>
                        <select
                          required
                          value={broadcast?.campaignType}
                          className="border w-full py-1.5 border-gray-300 rounded-md px-4 cursor-pointer"
                          onChange={(e) => {
                            setBroadcast({
                              ...broadcast,
                              campaignType: e.target.value,
                            });
                          }}
                        >
                          <option value="INTERNAL" selected>
                            {t("Internal")}
                          </option>
                          <option value="EXTERNAL">{"External"}</option>
                        </select>
                      </div>
                    )}
                  <div className="w-full">
                    <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                      {t("Broadcast Campaign (Immediate Schedule)")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <div className="w-full gap-1 flex justify-between rounded-md border border-gray-300 bg-white shadow-sm">
                      <select
                        required
                        value={broadcast?.scheduleType}
                        className="border-0 w-full text-[14px] border-gray-300 rounded-md py-1.5 px-4 cursor-pointer"
                        onChange={(e) => {
                          setBroadcast({
                            ...broadcast,
                            scheduleType: e.target.value,
                          });
                        }}
                      >
                        <option value="IMMEDIATE" selected>
                          {t("Immediate")}
                        </option>
                        <option value="SCHEDULED">{t("Schedule")}</option>
                      </select>
                      {broadcast?.scheduleType === "SCHEDULED" && (
                        <>
                          <div className="border-l border" />
                          <SelectDateTime className={"py-1.5"}
                            timezone={globalReducer.waba?.timezone}
                            required={true}
                            value={broadcast?.scheduledTime}
                            onChange={(e) =>
                              setBroadcast({ ...broadcast, scheduledTime: e })
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                      {t("Broadcast Type")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <select
                      required
                      value={broadcast?.broadcastType}
                      className="border w-full border-gray-300 rounded-md py-1.5 px-4 cursor-pointer text-[14px]"
                      onChange={(e) => {
                        setBroadcast({
                          ...broadcast,
                          broadcastType: e.target.value,
                        });
                      }}
                    >
                      <option value="ONEGO" selected>
                        {t("One Go")}
                      </option>
                      <option value="DRIP">{t("Drip")}</option>
                    </select>
                  </div>
                  {broadcast?.broadcastType === "DRIP" && (
                    <div className="w-full grid grid-cols-4 gap-2">
                      <div className="col-span-2">
                        <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-1">
                          {t("From Date")}
                          <span className="text-red-500 text-[14px]">*</span>
                        </label>
                        <input
                          type="date"
                          min={moment().add(1, 'day').format('YYYY-MM-DD')}
                          required={true}
                          value={broadcast?.detail?.dripFromDate}
                          onChange={(e) =>
                            setBroadcast({
                              ...broadcast,
                              detail: {
                                ...broadcast?.detail,
                                dripFromDate: e.target.value,
                              },
                            })
                          }
                          className="border w-full border-gray-300 rounded-md py-1 px-4 cursor-pointer"
                        />
                      </div>
                      <div className="col-span-2">
                        <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-1">
                          {t("To Date")}
                          <span className="text-red-500 text-[14px]">*</span>
                        </label>
                        <input
                          type="date"
                          min={moment().add(1, 'day').format('YYYY-MM-DD')}
                          required={true}
                          value={broadcast?.detail?.dripToDate}
                          onChange={(e) =>
                            setBroadcast({
                              ...broadcast,
                              detail: {
                                ...broadcast?.detail,
                                dripToDate: e.target.value,
                              },
                            })
                          }
                          className="border w-full border-gray-300 rounded-md py-1 px-4 cursor-pointer"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-1">
                          {t("Days on which broadcast should go")}
                          <span className="text-red-500 text-[14px]">*</span>
                        </label>
                        <MultiSelect
                          list={dayMap}
                          onSelect={(d) => {
                            setBroadcast({
                              ...broadcast,
                              detail: { ...broadcast?.detail, dripDays: d },
                            });
                          }}
                          selected={broadcast?.detail?.dripDays}
                          labelField="value"
                          valueField="key"
                          placeholder="Select Days"
                        />
                      </div>
                      <div>
                        <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-1">
                          {t("Time of Broadcast")}
                          <span className="text-red-500 text-[14px]">*</span>
                        </label>
                        <SelectTime
                          className={"py-1"}
                          required={true}
                          value={broadcast?.detail?.dripTime}
                          onChange={(e) =>
                            setBroadcast({
                              ...broadcast,
                              detail: { ...broadcast?.detail, dripTime: e },
                            })
                          }
                          timezone={globalReducer.waba?.timezone}
                        />
                      </div>
                    </div>
                  )}
                  <div className="w-full">
                    <label
                      className="text-sm text-gray-600 font-medium"
                      id="headlessui-listbox-label-:rq:"
                    >
                      {t("Message Template")}
                      <span className="text-red-500 text-[14px]">*</span>
                    </label>
                    <TypeableSelect
                      list={templates}
                      selected={broadcast}
                      selectedValueField="templateId"
                      labelField="name"
                      valueField="id"
                      required={true}
                      placeholder="Select Template"
                      onSelect={(s) => {
                        setBroadcast({ ...broadcast, templateId: s?.id });
                      }}
                    />
                  </div>
                  <div className="w-full mt-3 pt-3 border-t border-gray-400 mapDesign">
                    <label className="block text-sm rtl:text-right text-gray-600 font-medium mb-2">
                      {t("Set Template Variable Value")}
                    </label>
                    <SetTemplateVariableValue
                      tenantId={globalReducer.loginInfo?.tenantId}
                      wabaId={globalReducer.waba?.id}
                      setTemplate={setTemplate}
                      template={template}
                      carouselTemplate={carouselTemplate}
                      setCarouselTemplate={setCarouselTemplate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="relative flex flex-col justify-between gap-2 items-center bg-white p-2 col-span-5"
              style={{ height: "calc(-220px + 100vh)" }}
            >
              <h1 className="text-[14px] flex justify-end font-medium leading-7 capitalize text-gray-900">
                {t("Preview")}
              </h1>
              <div className="overflow-auto">
                <WhatsAppTemplatePreview
                  wabaIco={globalReducer.waba?.teamImage}
                  waba={globalReducer.waba}
                  timezone={globalReducer.waba?.timezone}
                  chatPhone={`+${globalReducer.waba?.phoneCode} ${globalReducer.waba?.phone}`}
                  template={template}
                  carouselTemplate={carouselTemplate}
                />
              </div>
              <button
                type="submit"
                value={"TestMessage"}
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              >
                {t("Send a Test Message")}
              </button>
            </div>
          </div>
          <div className={`${step === 2 ? "" : "hidden"}`}>
            <div className="">
              {isDynamicValue ?
                <div className="mt-2 bg-gray-50">
                  <SelectAudiences header={template?.header} bodyVariables={template?.bodyVariables} onUpload={(path, d) => {
                    setBroadcast({ ...broadcast, excelUploadedPath: path });
                    setContacts(d?.map(m => { return { ...m, isExcelUpload: true, whatsappNumber: String(m.phone), customerId: String(m.customerId), category: Array.isArray(m.category) ? m.category : [m.category] } }))
                  }} />
                </div>
                :
                <div className="mt-2 bg-gray-50">
                  <div className="flex justify-end">
                    <a
                      onClick={() => setShowAddContact(true)}
                      className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                    >
                      {t("Add Contact")}
                    </a>
                    <a
                      onClick={() => setShowContactUpload(true)}
                      className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                    >
                      {t("Upload Contact")}
                    </a>
                    <a
                      onClick={() => setShowChooseContacts(true)}
                      className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                    >
                      {t("Choose from Contacts")}
                    </a>
                    <a
                      onClick={() => setShowChooseCategory(true)}
                      className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                    >
                      {t("Choose from Category")}
                    </a>
                  </div>
                  <div className="h-[63vh] max-h-[550px] overflow-y-auto">
                    {contacts?.length > 0 && (
                      <table className="text-sm text-left rtl:text-right text-midBlack w-full">
                        <thead className="text-[14px] py-1 text-midBlack bg-gray-100 w-full">
                          <tr>
                            <th scope="col" className="py-1 pl-3 font-[500]">
                              <div className="flex gap-2">
                                <div>{t("Name")}</div>
                              </div>
                            </th>
                            <th scope="col" className="py-1 font-[500]">
                              <div className="flex gap-2">
                                <div> {t("Phone")}</div>
                              </div>
                            </th>
                            <th scope="col" className="py-1 font-[500]">
                              <div className="flex gap-2">
                                <div>{t("Category")}</div>
                              </div>
                            </th>
                            <th scope="col" className="py-1 font-[500]">
                              <div className="flex gap-2"></div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {contacts.map((p, i) => {
                            return (
                              <tr className="bg-white border-b">
                                <td
                                  className={"py-1 pl-3 text-[13px] font-normal"}
                                >
                                  {p?.name ?? "-"}
                                </td>
                                <td className="py-1 font-normal text-[13px]">
                                  {`${p?.whatsappNumber}`}
                                </td>
                                <td className="py-1 font-normal text-[13px]">
                                  {p?.category?.join(",") ?? "-"}
                                </td>
                                <td className="py-1 font-normal text-[13px]">
                                  <a
                                    onClick={() => {
                                      contacts.splice(i, 1);
                                      setContacts([...contacts]);
                                    }}
                                    className="min-w-[76px] text-center px-[10px] py-[6px] text-[14px] transition-all ease-in-out rounded cursor-pointer text-theme hover:underline"
                                  >
                                    Remove
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>}
            </div>
          </div>
          <div className={`${step === 3 ? "" : "hidden"}`}>
            <div className="h-[65vh]">
              <div className="grid grid-cols-2 gap-5 px-2 py-3">
                <div>
                  <p className="text-[14px]">
                    {broadcast?.campaignName} | {t("Broadcast Summary")}
                  </p>
                  <div className="bg-white border border-gray-300 p-3 text-[14px] rounded-lg min-h-[150px]">
                    <p>
                      <label className="text-gray-500">{t("Sent from")}</label>{" "}
                      <label>
                        +
                        {globalReducer.waba?.phoneCode +
                          globalReducer.waba?.phone}
                      </label>
                    </p>
                    <p className="mt-2">
                      <label className="text-gray-500">
                        {t("Message template")}
                      </label>{" "}
                      <label>{selectedTemplate?.name}</label>
                    </p>
                    <p className="mt-2">
                      <label className="text-gray-500">
                        {t("Scheduled to")}
                      </label>{" "}
                      <label>
                        {paymentInformation.totalContacts}{" "}
                        {t("Customers")}
                      </label>
                    </p>
                    <p className="mt-2">
                      <label className="text-gray-500 mt-5">
                        {t("Invalid")}
                      </label>{" "}
                      <label
                        onClick={() => {
                          exportInvalidContact(
                            paymentInformation?.invalidContactList
                          );
                        }}
                        className="text-theme underline cursor-pointer"
                      >
                        {paymentInformation?.invalidContacts ?? 0}{" "}
                        {t("Customers")}
                      </label>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="text-[14px]">{t("Payment Information")}</p>
                  <div className="bg-white border border-gray-300 p-3 text-[14px] rounded-lg min-h-[150px]">
                    <label className="text-gray-500">
                      {t(
                        "Your estimated cost for this broadcast against your account/credits"
                      )}
                    </label>
                    <div className="grid grid-cols-2 justify-between gap-2 pt-1">
                      <p>{t("Estimated Cost")}</p>
                      <p>
                        {paymentInformation?.currencyCode ??
                          tenantReducer.tenantDetails?.country
                            ?.currencyCode}{" "}
                        {paymentInformation?.estimatedAmount?.toFixed(2) ?? 0}
                      </p>
                      <p>{t("Account Type")}</p>
                      <p>{globalReducer.waba?.accountType}</p>
                      {globalReducer.waba?.accountType === "POSTPAID" ? (
                        <>
                          <p>{t("Available Credits")}</p>
                          <p>
                            {paymentInformation?.currencyCode ??
                              tenantReducer.tenantDetails?.country
                                ?.currencyCode}{" "}
                            {(
                              parseFloat(
                                paymentInformation?.availableAmount ?? 0
                              ) + parseFloat(globalReducer.waba?.walletLimit)
                            ).toFixed(2)}
                            {t("(Credit Limit)")}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>{t("Available Credits")}</p>
                          <p>
                            {paymentInformation?.currencyCode ??
                              tenantReducer.tenantDetails?.country
                                ?.currencyCode}{" "}
                            {paymentInformation?.availableAmount ?? 0}{" "}
                            {t("(Wallet Balance)")}
                          </p>
                        </>
                      )}
                      {/* <p>Additional Credits Required</p><p>{paymentInformation?.currencyCode ?? tenantReducer.tenantDetails?.country?.currencyCode} {paymentInformation?.additionalAmount ?? 0}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-between gap-2 my-2">
          <div>
            <button type="button" onClick={onSaveAsDraft}
              className="min-w-[76px] text-center px-[16px] py-[6px] text-gray-500 font-medium text-[14px] transition-all ease-in-out rounded cursor-pointer"
            >{t("Save as Draft")}
            </button>
          </div>
          <div className="px-2 pb-2 gap-3 flex justify-start">
            {step === 1 ? (
              <button
                type="button"
                onClick={() => setIsShow(false)}
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              >
                {t("Cancel")}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => setStep(step > 1 ? step - 1 : step)}
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              >
                {t("Back")}
              </button>
            )}
            <button
              type="submit"
              value={"Next"}
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              {step === 3 ? t("Submit") : t("Next")}
            </button>
          </div>
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Send Test Message")}
          </span>
        }
        isShowModal={showSendTestMessage}
        setIsShowModal={setShowSendTestMessage}
        ModalBody={
          <TestMessage
            setIsShow={setShowSendTestMessage}
            setMobileNumbers={(m) => {
              m?.map((mobile) => {
                getVariableComponent(
                  dispatch,
                  globalReducer.loginInfo,
                  globalReducer.waba,
                  template,
                  carouselTemplate
                ).then((componentsVal) => {
                  if (componentsVal) {
                    dispatch(
                      sendWhatsAppMessage(
                        {
                          tenantId: globalReducer.loginInfo?.tenantId,
                          contact: {
                            uid: globalReducer.loginInfo?.uid,
                            tenantId: globalReducer.loginInfo?.tenantId,
                            whatsappNumber: parseInt(
                              (mobile + "")?.replace("+", "")
                            ),
                          },
                          tenantWabaId: globalReducer.waba?.id,
                          //waba: globalReducer.waba,
                          templateId: parseInt(broadcast.templateId),
                          template: selectedTemplate,
                          languageCode: selectedTemplate.language,
                          messageType: selectedTemplate.messageType,
                          type: "template",
                          templateValue: { components: componentsVal },
                        },
                        (a) => {
                          dispatch({
                            type: SHOW_SNACKBAR,
                            payload: {
                              type: "success",
                              message: `Message sent`,
                              open: true,
                            },
                          });
                          setShowSendTestMessage(false);
                        }
                      )
                    );
                  }
                });
              });
            }}
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Add Contact")}
          </span>
        }
        isShowModal={showAddContact}
        setIsShowModal={setShowAddContact}
        ModalBody={
          <AddContact
            setIsShow={setShowAddContact}
            onlyGetContact={true}
            onAdd={(c) => {
              if (
                !contacts.some((s) => s.whatsappNumber == c.whatsappNumber)
              ) {
                setContacts([...contacts, c]);
              }
            }}
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Choose Contacts")}
          </span>
        }
        isShowModal={showChooseContacts}
        setIsShowModal={setShowChooseContacts}
        ModalBody={
          <ChooseContact
            setIsShow={setShowChooseContacts}
            onAdd={(a) => {
              a?.map((c) => {
                if (
                  !contacts.some((s) => s.whatsappNumber == c.whatsappNumber)
                ) {
                  contacts.push(c);
                }
              });
              setContacts([...contacts]);
            }}
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t("Choose Category")}
          </span>
        }
        isShowModal={showChooseCategory}
        setIsShowModal={setShowChooseCategory}
        ModalBody={
          <ChooseCategory
            setIsShow={setShowChooseCategory}
            onAdd={(a) => {
              a?.map((c) => {
                if (
                  !contacts.some((s) => s.whatsappNumber == c.whatsappNumber)
                ) {
                  contacts.push(c);
                }
              });
              setContacts([...contacts]);
            }}
          />
        }
        size="4xl"
      />
    </form>
    <RightSidePanel
      ModalTitle={t("Upload Contacts")}
      setIsShow={setShowContactUpload}
      isShow={showContactUpload}
      downloadFile="contact_template.xlsx"
      fileUrl="/files/contact_template.xlsx"
      Component={
        <div className="height30 flex flex-col justify-between pb-3">
          <div className="customPadding">
            <div className="w-full mt-4 px-4">
              <Autocomplete
                multiple={true}
                aria-required={true}
                options={categories} value={selectedCategories}
                isOptionEqualToValue={(option, value) =>
                  option?.categoryName === value?.categoryName
                }
                getOptionLabel={(option) => option?.categoryName ?? ""}
                onChange={(e, value) => {
                  setSelectedCategories(value);
                }}
                sx={{
                  "& .MuiAutocomplete-inputRoot": {
                    padding: "0px",
                  },
                }}
                slotProps={{
                  paper: {
                    sx: {
                      fontSize: "13px", // Change font size of dropdown options
                    },
                  },
                  listbox: {
                    sx: {
                      fontSize: "13px", // Change font size of listbox items
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Category"
                    className="custom-input"
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "0px",
                        "&.Mui-focused fieldset": {
                          borderColor: "none",
                          border: "0px",
                        },
                        fontSize: "13px",
                      },
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "#FFFFFF",
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#888",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#1976d2",
                        },
                      },
                    }}
                  />
                )}
              />
            </div>
            <ImportExcel
              Title={t("Upload Contacts")}
              setIsShow={setShowContactUpload}
              columnValidation={columnValidation}
              onImportClick={(con) => {
                const categories = selectedCategories?.map((c) => c.categoryName) ?? [];
                if (!categories || categories?.length <= 0) {
                  dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "Please select category", open: true } });
                  return;
                }
                con.map((m) => {
                  m.tenantId = globalReducer.loginInfo.tenantId;
                  m.uid = globalReducer.loginInfo.uid;
                  m.whatsappNumber = parseInt(
                    (m.phone + "")?.replace("+", "")
                  );
                  m.category = categories;
                  //m.isExcelUpload = true;
                  if (!contacts.some((s) => s.whatsappNumber == m.whatsappNumber)) {
                    contacts.push(m);
                  }
                });
                setContacts([...contacts]);
                setShowContactUpload(false);
              }}
            />
          </div>
        </div>
      }
      CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
    />
  </>);
}

export default CreateBroadcast;
