import { Dropdown } from "flowbite-react";
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateBroadcast from "../../layouts/tenant/CreateBroadcast";
import RightSidePanel from "../../components/RightSidePanel";
import {
  campaignQuery,
  getWalletByWaba,
  updateCampaignStatus,
} from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import { DatePicker } from "antd";
import { useLocation } from "react-router-dom";
import BroadcastReport from "../../layouts/tenant/BroadcastReport";
import { broadcastStatusColor, getPercentage } from "../../utils/helper";
import PopUp from "../../components/PopUp";
import { SHOW_SNACKBAR } from "../../utils/types";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

function Campaigns({ showSidebar, showAsReport = false }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [broadcasts, setBroadcasts] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    property: "createdAt",
    operator: "RANGE",
    lowerBound: Date.parse(dayjs().startOf("month").toDate()),
    upperBound: Date.parse(dayjs().add(1, "hour").toDate())
  });
  const [selectedBroadcast, setSelectedBroadcast] = useState({});
  const [wallet, setWallet] = useState({});
  const [showCreateBroadcast, setShowCreateBroadcast] = useState(false);
  const [showDynamicCreateBroadcast, setShowDynamicCreateBroadcast] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [showRemoveBroadcast, setShowRemoveBroadcast] = useState(false);
  const [removeBroadcastType, setRemoveBroadcastType] = useState("cancel");
  const [showBroadcastReport, setShowBroadcastReport] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ criteria: {} });
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const globalReducer = useSelector((state) => state.globalReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const wabaTimezone =
    globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (!showAsReport) {
      if (location?.state?.isCreate) {
        setSelectedTemplateId(location?.state?.template?.id);
        setSelectedContactIds(location?.state?.contactIds);
        setSelectedCategory(location?.state?.categoryNames);
        setShowCreateBroadcast(true);
        setShowDynamicCreateBroadcast(false);
      }
    }
    if (globalReducer.waba?.id) {
      dispatch(
        getWalletByWaba({ tenantWabaId: globalReducer.waba?.id }, globalReducer.loginInfo?.tenantId, (w) => {
          setWallet(w);
        })
      );
    }
  }, [globalReducer.waba]);

  useEffect(() => {
    if (globalReducer.waba?.id) {
      onSearch();
    }
  }, [currentPage, globalReducer.waba]);

  const onSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push(
        {
          property: "campaignName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "campaignType",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "broadcastType",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "scheduleType",
          operator: "ICONTAINS",
          value: searchText,
        }
      );
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    dispatch(
      campaignQuery(
        {
          ...filter, criteria: { ...filter.criteria, tenantWabaId: globalReducer.waba?.id },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }]
        },
        (d) => {
          setBroadcasts(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.add(1, "hour").toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };
  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-1 min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <svg
              width={20}
              height={21}
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="text-theme"
            >
              <path
                d="M2.5 9.96647L17.5 5.7998V15.7998L2.5 12.4665V9.96647Z"
                stroke="#D84E55"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.66677 14.7996C9.57922 15.117 9.43002 15.414 9.22769 15.6737C9.02535 15.9334 8.77385 16.1507 8.48754 16.3132C8.20122 16.4757 7.8857 16.5802 7.559 16.6208C7.23229 16.6614 6.90079 16.6372 6.58343 16.5496C6.26607 16.4621 5.96906 16.3129 5.70936 16.1106C5.44966 15.9082 5.23236 15.6567 5.06985 15.3704C4.90735 15.0841 4.80283 14.7686 4.76227 14.4419C4.7217 14.1152 4.74588 13.7837 4.83343 13.4663"
                stroke="#D84E55"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="text-[14px] font-medium">{t('Broadcast')}</div>
          </div>
          <div className="flex">
            <div className="text-[13px] text-end pr-2 text-gray-600">
              <label className="bg-[#FCCECD] p-1 rounded-lg">
                {t('Account Type')} {" "}
                <span className="font-bold px-1">
                  {globalReducer.waba?.accountType}
                </span>
              </label>
              <br />
              {globalReducer.waba?.accountType === "POSTPAID" ? <label className="bg-[#D9FAE2] p-1 rounded-lg">
                {t('Credit Limit')}
                <span className="font-bold px-1">
                  {wallet?.currencyCode ??
                    globalReducer.waba?.currencyCode}
                  {"  "}{(parseFloat(wallet?.availableWallet ?? 0) + parseFloat(globalReducer.waba?.walletLimit ?? 0)).toFixed(3)}
                </span>
              </label> :
                <label className="bg-[#D9FAE2] p-1 rounded-lg">
                  {t('Wallet Balance')}
                  <span className="font-bold px-1.5">
                    {wallet?.currencyCode ??
                      globalReducer.waba?.currencyCode}
                    {"  "}{parseFloat(wallet?.availableWallet).toFixed(3)}
                  </span>
                </label>}
            </div>
            {!showAsReport && <>
              <div className="border-l border-1 border-gray-400" />
              <button
                type="button"
                className="justify-center ml-3 mt-[5px] items-center flex px-4 h-9 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={() => {
                  if (moment.utc(tenantReducer.tenantDetails?.plan?.toDate).isBefore(moment.utc())) {
                    dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "subscription expired, please renew for uninterrupted service", open: true } });
                  } else {
                    setSelectedBroadcast({});
                    setShowCreateBroadcast(true);
                    setShowDynamicCreateBroadcast(false);
                  }
                }}
              >
                <i className="fa-solid fa-plus mr-2"></i>
                {t('Create Broadcast')}
              </button>
              <button
                type="button"
                className="justify-center ml-3 mt-[5px] items-center flex px-4 h-9 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={() => {
                  if (moment.utc(tenantReducer.tenantDetails?.plan?.toDate).isBefore(moment.utc())) {
                    dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "subscription expired, please renew for uninterrupted service", open: true } });
                  } else {
                    setSelectedBroadcast({});
                    setShowCreateBroadcast(true);
                    setShowDynamicCreateBroadcast(true);
                  }
                }}
              >
                <i className="fa-solid fa-plus mr-2"></i>
                {t('Create Broadcast Dynamic Value')}
              </button>
            </>}
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Enter Details")}</label>
              </div>
              <input
                type="text"
                placeholder="Enter Contact Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Status")}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      status: e.target.value
                    },
                  });
                }}
              >
                <option value={""} selected>
                  {t("Select Status")}
                </option>
                <option value={"DRAFT"}>
                  {t("Draft")}
                </option>
                <option value={"PANDING"}>
                  {t("Panding")}
                </option>
                <option value={"COMPLETED"}>{t("Completed")}</option>
                <option value={"IN_PROGRESS"}>{t("In Progress")}</option>
                <option value={"FAILED"}>{t("Failed")}</option>
                <option value={"SUSPEND"}>{t("Suspend")}</option>
                <option value={"CANCEL"}>{t("Cancel")}</option>
              </select>
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Date Range")}</label>
              </div>
              <RangePicker defaultValue={[dayjs().startOf("month"), dayjs()]}
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center ml-3 items-center flex px-5 h-9 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={() => {
                  if (currentPage === 1) {
                    onSearch();
                  } else {
                    setCurrentPage(1);
                  }
                }}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[10px]">
          {broadcasts && broadcasts.length > 0 ? (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div>{t('Broadcast Name')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div> {t('Channel')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Scheduled Date / Time')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div> {t('Status')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Broadcast Type')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Campaign Type')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Scheduled')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Delivered')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Action')}</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {broadcasts.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className={"py-3 text-[13px] pl-2 font-normal"}>
                          {p?.campaignName ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {`${globalReducer.waba?.teamName} +${globalReducer.waba?.phoneCode} ${globalReducer.waba?.phone}`}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {moment
                            .utc(p?.scheduledTime)
                            .tz(wabaTimezone)
                            .format("YYYY-MM-DD hh:mm A") ?? "-"}
                        </td>
                        <td className="py-3 font-normal text-[13px]">
                          <label className={`p-1 rounded-lg bg-[${broadcastStatusColor[p?.status?.toLowerCase()]}]`}>{p?.status}</label>
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.broadcastType ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.campaignType ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {new Intl.NumberFormat("en-US").format(p?.totalCount ?? 0)}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {new Intl.NumberFormat("en-US").format(p?.deliveredCount ?? 0)} <span className="">({getPercentage(p?.totalCount, p?.deliveredCount)})</span>
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            {(p.campaignType?.toUpperCase() === "INTERNAL" || (p.campaignType?.toUpperCase() === "EXTERNAL") && globalReducer.loginInfo?.roleDetails?.some(s => s.name === "TENANT_SUPER_ADMIN")) ? <>
                              {["draft", "pending"].includes(p?.status?.toLowerCase()) && <>
                                <Dropdown.Item className="hover:text-theme" onClick={() => {
                                  setSelectedBroadcast({ ...p, isEdit: true }); setShowCreateBroadcast(true); setShowDynamicCreateBroadcast(false);
                                }}>
                                  <i className="fa-regular fa-pen-to-square pr-2"></i>
                                  {t('Edit')}
                                </Dropdown.Item>
                                <Dropdown.Item className='hover:text-theme' onClick={() => { setShowRemoveBroadcast(true); setRemoveBroadcastType("cancel"); setSelectedBroadcast({ ...p }); }}>
                                  <i className="fa-solid fa-chart-simple pr-2"></i> {t('Cancel')}
                                </Dropdown.Item>
                                <Dropdown.Item className='hover:text-theme' onClick={() => { setShowRemoveBroadcast(true); setRemoveBroadcastType("delete"); setSelectedBroadcast({ ...p }); }}>
                                  <i className="fa-solid fa-chart-simple pr-2"></i> {t('Delete')}
                                </Dropdown.Item></>}
                              {["in_progress", "completed", "failed", "partially_completed"].includes(p?.status?.toLowerCase()) && <Dropdown.Item className='hover:text-theme' onClick={() => { setShowBroadcastReport(true); setSelectedBroadcast(p) }}>
                                <i className="fa-solid fa-chart-simple pr-2"></i> {t('Report')}
                              </Dropdown.Item>}
                              {p?.status?.toLowerCase() === "in_progress" && <Dropdown.Item className='hover:text-theme' onClick={() => { dispatch(updateCampaignStatus({ tenantId: p.tenantId, id: p?.id, status: "SUSPEND" }, onSearch)) }}>
                                <i className="fa-solid fa-chart-simple pr-2"></i> {t('Suspend')}
                              </Dropdown.Item>}
                              {p?.status?.toLowerCase() === "suspend" && <><Dropdown.Item className='hover:text-theme' onClick={() => { dispatch(updateCampaignStatus({ tenantId: p.tenantId, id: p?.id, status: "DRAFT" }, onSearch)) }}>
                                <i className="fa-solid fa-chart-simple pr-2"></i> {t('Resume')}
                              </Dropdown.Item>
                                <Dropdown.Item className='hover:text-theme' onClick={() => { dispatch(updateCampaignStatus({ tenantId: p.tenantId, id: p?.id, status: "CALL_OFF" }, onSearch)) }}>
                                  <i className="fa-solid fa-chart-simple pr-2"></i> {t('Call Off')}
                                </Dropdown.Item></>}
                            </> : <>
                              {["in_progress", "completed", "failed", "partially_completed"].includes(p?.status?.toLowerCase()) && <Dropdown.Item className='hover:text-theme' onClick={() => { setShowBroadcastReport(true); setSelectedBroadcast(p) }}>
                                <i className="fa-solid fa-chart-simple pr-2"></i> {t('Report')}
                              </Dropdown.Item>}
                            </>}
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="text-[14px]">
                <label className="font-bold">Note: </label>
                <label className="text-gray-500"> The Delivered and Read counts displayed here may be lower than the actual numbers in Meta Business Manager due to small variations in data processing. Credit adjustments will be made to the customer's account every 15 days.</label>
              </div>
            </>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t('The space is empty . create broadcast to get started.')}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => { setShowCreateBroadcast(true); setShowDynamicCreateBroadcast(false); }}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t('Create Broadcast')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {totalPages > 1 && broadcasts && broadcasts.length > 0 && (
          <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
            <div className="flex overflow-x-auto">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>

          </div>
        )}
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[70%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showCreateBroadcast}
        setIsShow={setShowCreateBroadcast}
        ModalTitle={showCreateBroadcast?.isEdit ? t("Edit Broadcast") : t("Create Broadcast")}
        Component={
          <CreateBroadcast isDynamicValue={showDynamicCreateBroadcast} isEdit={selectedBroadcast?.isEdit} selectedBroadcast={selectedBroadcast} selectedCategory={selectedCategory}
            setIsShow={setShowCreateBroadcast} selectedTemplateId={selectedTemplateId} selectedContactIds={selectedContactIds}
            onAdd={() => {
              onSearch();
              dispatch({ type: SHOW_SNACKBAR, payload: { type: "success", message: `Broadcast created successfully.`, open: true } });
            }} onAddInList={(ne) => broadcasts.unshift(ne)}
          />
        }
      />
      <RightSidePanel IsCustomize={true} isShow={showBroadcastReport} setIsShow={setShowBroadcastReport}
        CustomClass="fixed md:w-[70%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        Component={
          <BroadcastReport waba={globalReducer.waba} setIsShow={setShowBroadcastReport} campaign={selectedBroadcast} />
        }
      />
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              Are you want to {removeBroadcastType} the broadcast?
            </div>
          </>
        }
        isShowModal={showRemoveBroadcast}
        setIsShowModal={setShowRemoveBroadcast}
        ModalBody={
          <div className="flex justify-end gap-4 p-6">
            <button
              className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
              onClick={() => setShowRemoveBroadcast(false)}
            >

              {t('Cancel')}
            </button>
            <button
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                if (removeBroadcastType === "cancel") {
                  dispatch(updateCampaignStatus({ tenantId: selectedBroadcast?.tenantId, id: selectedBroadcast?.id, status: "CANCEL" }, (a) => {
                    onSearch();
                    setShowRemoveBroadcast(false);
                  }))
                }
                else if (removeBroadcastType === "delete") {
                  dispatch(updateCampaignStatus({ tenantId: selectedBroadcast?.tenantId, id: selectedBroadcast?.id, status: "DELETE" }, (a) => {
                    onSearch();
                    setShowRemoveBroadcast(false);
                  }))
                }
              }}>
              {removeBroadcastType}
            </button>
          </div>
        }
        size="md"
      />
    </div>
  );
}

export default Campaigns;
