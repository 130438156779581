import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)  // Use backend to load translations
  .use(initReactI18next)
  .init({
    lng: 'en',        // Default language
    fallbackLng: 'en',
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',  // Access from public folder
    },
    interpolation: { escapeValue: false },
  });

export default i18n;
