import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MobileInput from "../../components/MobileInput";
import { optOutContactUpsert } from "../../store/actions/tenantAction";

function AddOptOutContact({ setIsShow,
    onAdd,
    isEdit = false,
    defaultContact = {}}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const globalReducer = useSelector((state) => state.globalReducer);
    const tenantReducer = useSelector((state) => state.tenantReducer);
    const [selectedContact, setSelectedContact] = useState(defaultContact ?? {});


    useEffect(() => {
        if (!isEdit) {
            const country = globalReducer.country.find(
                (f) => f.code === tenantReducer.tenantDetails.countryCode
            );
            if (country) {
                setSelectedContact({ ...selectedContact, phoneCode: country.phoneCode });
            }
        }
    }, []);

    return (<form
        className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto"
        onSubmit={(e) => {
            e.preventDefault();
            selectedContact.tenantId = globalReducer.loginInfo?.tenantId;
            dispatch(
                optOutContactUpsert(selectedContact, (s) => {
                    setIsShow(false);
                    onAdd();
                })
            );
        }}
    >
        <div className="grid grid-cols-12 gap-3 items-end w-full">
            <div className="col-span-12">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                    <label>
                        {t('Phone Number')}<span className="text-red-500 text-[14px]">*</span>
                    </label>
                </div>
                <MobileInput
                    className={"py-1 h-10 text-[16px]"}
                    required={true}
                    countryList={globalReducer.country}
                    phoneCode={selectedContact?.phoneCode}
                    phone={selectedContact?.phone}
                    setPhoneCode={(code) =>
                        setSelectedContact({ ...selectedContact, phoneCode: code })
                    }
                    setPhone={(phone) =>
                        setSelectedContact({ ...selectedContact, phone: phone })
                    }
                    placeholder={t('Select Phone Number')}
                />
            </div>
        </div>
        <div className="flex justify-end gap-2 mt-4">
            <button
                type="button"
                className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                onClick={() => setIsShow(false)}
            >
                {t('Cancel')}
            </button>
            <button
                type="submit"
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
                {t('Save')}
            </button>
        </div>
    </form>)
}

export default AddOptOutContact;