import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { allTags } from "../../utils/helper";

function FilterAgents({ setFilterShow, filterShow, agents, onFilter }) {
  const { t } = useTranslation();
  const [filter, setFiletr] = useState({});
  const globalReducer = useSelector((state) => state.globalReducer);

  return (
    <div className="p-[16px] bg-white rounded-[4px] text-[14px] mt-4 dropdown-shadow absolute w-[290px] min-h-[300px] top-[20px] z-[99] left-0">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-[16px] text-[#374151]">{t('Filters')}</div>
        <button
          onClick={() => setFilterShow(!filterShow)}
          aria-label="Close"
          className="aa ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900"
          type="button"
        >
          <svg
            stroke="currentColor"
            fill="none"
            stroke-width="2"
            viewBox="0 0 24 24"
            aria-hidden="true"
            className="h-5 w-5"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      {/* Agents */}
      {globalReducer.loginInfo?.roles?.some((s) =>
        ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"].includes(s)
      ) && (
          <div className="mt-[16px]">
            <div className="text-[#4B5563] text-[14px] font-semibold mb-[8px] text-left">
              <label>{t('Agents')}</label>
            </div>
            <div className="w-full">
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(a) =>
                  setFiletr({ ...filter, agentId: a.target.value })
                }
              >
                <option selected value={""}>
                  {t('Select Agents')}
                </option>
                {agents?.map((a, i) => (
                  <option key={i} value={a.uid}>
                    {a.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      <div className="mt-[16px]">
        <div className="text-[#4B5563] text-[14px] font-semibold mb-[8px] text-left">
          <label>{t('Tags')}</label>
        </div>
        <div className="w-full">
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            onChange={(a) =>
              setFiletr({ ...filter, tags: a.target.value ? [a.target.value] : null })
            }
          >
            <option selected value={""}>
              {t('Select Tag')}
            </option>
            {allTags?.map((a, i) => (
              <option key={i} value={a.text}>
                {a?.text}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* Date */}
      <div className="my-[16px]">
        <div className="text-[#4B5563] text-[14px] font-semibold mb-[8px] text-left">
          <label>{t('Date')}</label>
        </div>
        <div className="text-left flex flex-col gap-2">
          <label>{t('From')}</label>
          <input
            type="date"
            className="w-full border-0 border-gray-300 rounded-md"
            onChange={(e) => setFiletr({ ...filter, fromDate: e.target.value })}
          />
          {/* <Datepicker className="datePicker" onSelectedDateChanged={(c) => console.log("Datepicker", c)} /> */}
        </div>
        <div className="text-left flex flex-col gap-2">
          <label>{t('To')}</label>
          <input
            type="date"
            className="w-full border-0 border-gray-300 rounded-md"
            onChange={(e) => setFiletr({ ...filter, toDate: e.target.value })}
          />
          {/* <Datepicker className="datePicker" onSelectedDateChanged={(c) => console.log("Datepicker", c)} /> */}
        </div>
      </div>
      {/* Apply Filter Button */}
      <button
        type="button"
        onClick={() => onFilter(filter)}
        className="w-full mb-2 bg-theme text-white rounded-[6px] h-[40px] px-[12px] py-[4px]"
      >
        {t('Apply Filters')}
      </button>
    </div>
  );
}

export default FilterAgents;
