import { COUNTRY, TIMEZONE, LOGIN_INFO, WABA, NOTIFICATIONS } from "../../utils/types";

const initialState = {
  country: [],
  timezone: [],
  loginInfo: {},
  waba: {},
  notifications: []
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY: {
      return {
        ...state,
        country: action.payload,
      };
    }
    case TIMEZONE: {
      return {
        ...state,
        timezone: action.payload,
      };
    }
    case LOGIN_INFO: {
      return {
        ...state,
        loginInfo: action.payload,
      };
    }
    case WABA: {
      return {
        ...state,
        waba: action.payload,
      };
    }
    case NOTIFICATIONS:
      {
        return {
          ...state,
          notifications: action.payload,
        };
      }
    default:
      return state;
  }
};

export default globalReducer