import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import {
  deleteWhatsAppMsgTemplate,
  getWhatsAppMsgTemplate,
  updateTemplateStatus,
} from "../../store/actions/tenantAction";
import RightSidePanel from "../../components/RightSidePanel";
import CreateWhatsAppTemplate from "./CreateWhatsAppTemplate";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import PopUp from "../../components/PopUp";
import { useNavigate } from "react-router-dom";
import { SHOW_SNACKBAR } from "../../utils/types";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function WhatsAppMsgTemplates({ isPopup = false }) {
  const { t } = useTranslation();
  const globalReducer = useSelector((state) => state.globalReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ criteria: {} });
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const [dateFilter, setDateFilter] = useState();
  const wabaTimezone =
    globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if (globalReducer.waba?.id) {
      templateSearch();
    }
  }, [currentPage, globalReducer.waba]);

  const templateSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push({
        property: "messageType",
        operator: "ICONTAINS",
        value: searchText,
      }, { clause: "OR" }, {
        property: "referenceId",
        operator: "ICONTAINS",
        value: searchText,
      }, { clause: "OR" }, {
        property: "name",
        operator: "ICONTAINS",
        value: searchText,
      }, { clause: "OR" }, {
        property: "templateType",
        operator: "ICONTAINS",
        value: searchText,
      });
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "AND" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }
    dispatch(getWhatsAppMsgTemplate(
      {
        tenantId: globalReducer.loginInfo?.tenantId,
        ...filter,
        criteria: { ...filter.criteria, tenantWabaId: globalReducer.waba?.id },
        count: true,
        size: itemParPage,
        offset: (currentPage - 1) * itemParPage,
        orderBy: [{ property: "id", direction: "DESC" }]
      },
      (d) => {
        setTemplates(d?.data ?? []);
        setTotalPages(Math.ceil(d?.count / itemParPage));
      }
    ));
  };

  const onSearch = () => {
    if (currentPage === 1) {
      templateSearch();
    } else {
      setCurrentPage(1);
    }
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };
  return (
    <>
      <div className="height30 flex flex-col justify-between pb-3">
        <div className={isPopup ? "h-dvh overflow-y-auto p-4" : "px-6 py-2 mt-[5rem] mb-[20px]"}>
          <div className="grid grid-cols-12 gap-4 mb-5">
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Template Details')}</label>
              </div>
              <input
                type="text"
                placeholder="Search template by name, id"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => { setSearchText(e.target.value) }} />
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <button
                type="button"
                className="justify-center items-center mt-[23px] flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
            <div className={`flex flex-col col-span-3`}>
              <button
                type="button"
                className="min-w-[76px] w-fit text-center mt-[23px] px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627] h-10"
                onClick={() => {
                  setShowAddTemplate(true);
                  setSelectedTemplate({});
                }}
              >
                + {t('Create Template')}
              </button>
            </div>
          </div>
          <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
            <thead className="text-[14px] text-midBlack bg-gray-200">
              <tr>
                <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                  {t('Sr. No.')}
                </th>
                <th scope="col" className="font-[500] py-3 pl-2">
                  <div>{t('Template ID')}</div>
                </th>
                <th scope="col" className="font-[500]">
                  <div>{t('Template Name')}</div>
                </th>
                {!isPopup && (
                  <>
                    <th scope="col" className="font-[500]">
                      <div>{t('Category')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Template Type')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Language')}</div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div>{t('Created/Updated Date & Time ')}</div>
                    </th>
                  </>
                )}
                <th scope="col" className="font-[500] w-[120px]">
                  <div> {t('Status')}</div>
                </th>
                <th scope="col" className="font-[500]">
                  <div>{t('Action')}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {templates?.map((tmp, j) => {
                return (
                  <tr key={j} className="bg-white border-b">
                    <td className="py-3 text-[13px] pl-2">
                      {currentPage === 1 ? (j + 1) : (itemParPage * (currentPage - 1)) + (j + 1)}
                    </td>
                    <td className={"py-3 text-[13px] pl-2 font-normal"}>
                      {tmp?.referenceId ?? "-"}
                    </td>
                    <td className={"py-3 text-[13px] font-normal"}>
                      {tmp?.name ?? "---"}
                    </td>
                    {!isPopup && (
                      <>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {tmp?.messageType ?? "-"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {tmp?.templateType ?? "-"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {tmp?.language?.toUpperCase() ?? "-"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {moment
                            .utc(tmp?.updatedAt)
                            .tz(wabaTimezone)
                            .format("YYYY-MM-DD hh:mm A")}
                        </td>
                      </>
                    )}
                    <td className={"py-3 text-[13px] font-normal"}>
                      {tmp?.status?.toLowerCase() === "approved" ? (
                        <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                          {tmp?.status}
                        </label>
                      ) : (
                        <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                          {tmp?.status}
                        </label>
                      )}
                    </td>
                    <td className="py-3 text-[13px] font-normal">
                      <Dropdown
                        inline
                        arrowIcon={false}
                        inputMode
                        as="button"
                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                        label={<i className="fa-solid fa-ellipsis"></i>}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTemplate(tmp);
                            setShowDelete(true);
                          }}
                        >
                          {t('Delete')}
                        </Dropdown.Item>
                        {["approved", "rejected", "paused"].includes(
                          tmp?.status?.toLowerCase()
                        ) && (
                            <Dropdown.Item
                              onClick={() => {
                                if (moment.utc(tmp?.createdAt).add(24, "hours").isBefore(moment.utc())) {
                                  dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: t("Edit message template is not allowed after 24 hours."), open: true } });
                                  return;
                                }
                                setSelectedTemplate({ ...tmp, isEdit: true });
                                setShowAddTemplate(true);
                              }}
                            >
                              {t('Edit')}
                            </Dropdown.Item>
                          )}
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedTemplate({
                              ...tmp,
                              isEdit: true,
                              isView: true,
                            });
                            setShowAddTemplate(true);
                          }}
                        >
                          {t('View')}
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => { setSelectedTemplate({ ...tmp, isEdit: true, isView: true }); setShowAddTemplate(true) }}>
                                        Send Test
                                    </Dropdown.Item> */}
                        {tmp?.status?.toLowerCase() === "approved" &&
                          <Dropdown.Item
                            onClick={() => {
                              if (moment.utc(tenantReducer.tenantDetails?.plan?.toDate).isBefore(moment.utc())) {
                                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: "subscription expired, please renew for uninterrupted service", open: true } });
                              } else {
                                navigate("/tenant/campaigns", {
                                  state: { isCreate: true, template: tmp },
                                });
                              }
                            }}
                          >
                            {t('Send Broadcast')}
                          </Dropdown.Item>}
                        {tmp?.status?.toLowerCase() === "pending" &&
                          <Dropdown.Item onClick={() => {
                            dispatch(updateTemplateStatus({ ...tmp, status: "APPROVED" }, a => {
                              templateSearch();
                            }))
                          }}>
                            {t('Approved')}
                          </Dropdown.Item>}
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          )}
        </div>

      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[70%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showAddTemplate}
        setIsShow={setShowAddTemplate}
        ModalTitle={`${selectedTemplate?.isView
          ? "View"
          : selectedTemplate?.isEdit
            ? "Edit"
            : "Add"
          } Message Template`}
        Component={
          <CreateWhatsAppTemplate
            waba={globalReducer.waba}
            tenantId={globalReducer.loginInfo?.tenantId}
            setIsShow={setShowAddTemplate}
            selectedTemplate={selectedTemplate}
            isEdit={selectedTemplate?.isEdit}
            isView={selectedTemplate?.isView}
            onAdd={() => templateSearch()}
          />
        }
      />
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t('Are you want to delete the template?')}
            </div>
          </>
        }
        isShowModal={showDelete}
        setIsShowModal={setShowDelete}
        ModalBody={
          <div className="flex justify-end gap-4 p-6">
            <button
              className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
              onClick={() => setShowDelete(false)}
            >
              {t('Cancel')}
            </button>
            <button
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                dispatch(
                  deleteWhatsAppMsgTemplate(
                    {
                      tenantId: selectedTemplate?.tenantId,
                      id: selectedTemplate?.id,
                    },
                    (a) => {
                      onSearch();
                      setShowDelete(false);
                    }
                  )
                );
              }}
            >
              {t('Delete')}
            </button>
          </div>
        }
        size="md"
      />
    </>
  );
}

export default WhatsAppMsgTemplates;
