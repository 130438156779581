import React from 'react'

function WebhookLogDetail({ setIsShow, log }) {
    return (
        <div>
            <div className='grid bg-gray-50 w-full text-[14px]'>
                <textarea value={log?.logText} rows={20} disabled className='border-0'></textarea>
            </div>
        </div>
    )
}

export default WebhookLogDetail