import React, { useState } from "react";

function Password({
  onChange,
  value,
  defaultValue,
  required = false,
  placeholder,
  isError = false,
  className,
  size = "small",
}) {
  const [type, setType] = useState("password");

  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div
      className={`flex border ${
        isError ? "border-[#f10808]" : "border-gray-300"
      } rounded-md text-[14px] p-[1px]`}
    >
      <input
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        data-maxlength={30}
        onInput={OnInput}
        onChange={(a) => onChange(a.target.value)}
        className={`${
          size === "small" ? "py-1 text-[14px]" : "py-2 text-[16px]"
        } ${className} border-0 focus:border-0 w-full rounded-md`}
        required={required}
      />
      <label
        className={`border-l border-gray-400 ${
          size === "small" ? "h-[30px]" : "h-[40px]"
        }`}
      ></label>
      <div className={`w-8 text-center ${size === "small" ? "pt-1" : "pt-2"}`}>
        {type === "password" ? (
          <i
            className="fa-solid fa-eye-slash"
            onClick={() => setType("text")}
          ></i>
        ) : (
          <i
            className="fa-solid fa-eye"
            onClick={() => setType("password")}
          ></i>
        )}
      </div>
    </div>
  );
}

export default Password;
