import React from 'react'
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

function TemplateReportDetail({ selectedWaba, template }) {
    const { t } = useTranslation();
    return (
        <div className="height30 flex flex-col justify-between pb-3">
            <div className="h-dvh overflow-y-auto p-4">
                <div className="grid grid-cols-4 gap-2 bg-gray-50 p-3 w-full text-[14px]">
                    <div className="text-gray-500">{t("Channel")}</div>
                    <div className="col-span-3">
                        {`${selectedWaba?.teamName} +${selectedWaba?.phoneCode} ${selectedWaba?.phone}`}
                    </div>
                    <div className="text-gray-500">{t("Template ID")}</div>
                    <div className="col-span-3">
                        {template?.referenceId ?? "-"}
                    </div>
                    <div className="text-gray-500">{t("Template Name")}</div>
                    <div className="col-span-3">{template?.name ?? "-"}</div>
                    <div className="text-gray-500">{t("Language")}</div>
                    <div className="col-span-3">{template?.language ?? "-"}</div>
                    <div className="text-gray-500">{t("Status")}</div>
                    <div className="col-span-3">
                        {template?.status ?? ""}
                    </div>
                    <div className="text-gray-500">{t("Type")}</div>
                    <div className="col-span-3">{template?.templateType ?? "-"}</div>
                    <div className="text-gray-500">{t("Remarks")}</div>
                    <div className="col-span-3">{template?.remarks ?? "-"}</div>
                    <div className="text-gray-500">{t("delivered")}</div>
                    <div className="col-span-3">{template?.delivered ?? "-"}</div>
                    <div className="text-gray-500">{t("read")}</div>
                    <div className="col-span-3">{template?.read ?? "-"}</div>
                    <div className="text-gray-500">{t("Created At")}</div>
                    <div className="col-span-3">
                        {moment
                            .utc(template?.createdAt)
                            .tz(selectedWaba?.timezone)
                            .format("YYYY-MM-DD hh:mm A")}
                    </div>
                    {/* <div className="text-gray-500 col-span-4">{t("Status History")}</div>
                    <div className="col-span-1"></div>
                    <div className="col-span-3">
                        {Object.keys(template?.statusHistory ?? {})?.map((m, i) => {
                            return <div className='flex justify-between' key={i}>
                                <label>{m}</label>
                                <label>{moment
                                    .utc(template?.statusHistory[m])
                                    .tz(selectedWaba?.timezone)
                                    .format("YYYY-MM-DD hh:mm A")}</label>
                            </div>
                        })}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default TemplateReportDetail