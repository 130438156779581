import { json } from "react-router-dom";

//LOCAL STORAGE GET ITEM
export const getToken = () => localStorage.getItem("sessionToken");
export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const getTenantToken = () => localStorage.getItem("tenantSessionToken");
export const getTenantRefreshToken = () => localStorage.getItem("tenantRefreshToken");
export const getWaba = () => {
  const tenantWaba = localStorage.getItem("tenantWaba");
  if (tenantWaba && tenantWaba != undefined && tenantWaba != null && tenantWaba != "undefined") {
    return JSON.parse(tenantWaba ?? "{}");
  }
  return null;
};
export const removeToken = () => {
  localStorage.removeItem("sessionToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tenantWaba");
}

export const getInfo = () => {
  if (getToken()) {
    const [header, payload, signature] = localStorage.getItem("sessionToken").split(".");
    const decodedPayload = JSON.parse(atob(payload));
    return JSON.parse(atob(decodedPayload?.info));
  }
};

export const saveWaba = (value) => localStorage.setItem("tenantWaba", JSON.stringify(value));

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const IS_LOADING = "IS_LOADING";
export const IS_PAGELOADING = "IS_PAGELOADING";
export const IS_SHOWOPACITY = "IS_SHOWOPACITY";
export const PARTNER_DETAILS = "PARTNER_DETAILS";
export const TENANT_DETAILS = "TENANT_DETAILS";
export const COUNTRY = "COUNTRY";
export const TIMEZONE = "TIMEZONE";
export const LOGIN_INFO = "LOGIN_INFO";
export const WABA = "WABA";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";