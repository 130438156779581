import React, { useState } from "react";
import mime from "mime";
import { read, utils } from "xlsx";
import { SHOW_SNACKBAR } from "../utils/types";
import { useDispatch } from "react-redux";
import { excelUploader } from "../store/actions/globalAction";

function ImportExcel({
  setIsShow,
  Title,
  onImportClick,
  columnValidation = [],
  isUploadToServer = false,
}) {
  let dispatch = useDispatch();
  const [ReportData, setReportData] = useState({ contentType: "" });
  const [ExcelRows, setExcelRows] = useState([]);

  function processExcel(data) {
    const workbook = read(data, { type: "binary" });
    const rows = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
    setExcelRows([...rows]);
  }
  const readData = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (fileReader.readAsBinaryString && file) {
        fileReader.onload = (e) => {
          if (isUploadToServer) {
            dispatch(excelUploader(fileReader.result), (a) => {
              setExcelRows(a.data);
            });
          } else {
            processExcel(fileReader.result);
          }
          resolve();
        };
        fileReader.onerror = (error) => reject(error);
        fileReader.readAsBinaryString(file);
      }
    });
  };
  const onImport = (e) => {
    e.preventDefault();
    if (ExcelRows.length > 0) {
      if (onImportClick) onImportClick(ExcelRows);
    } else {
      dispatch({
        type: SHOW_SNACKBAR,
        payload: { type: "error", message: "Please select file", open: true },
      });
    }
  };
  return (
    <form onSubmit={onImport}>
      <div className="h-[72vh] overflow-y-auto p-4">
        <div className="flex gap-4">
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-25 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
          >
            <div className="flex flex-col items-center justify-center pt-1 pb-3">
              <svg
                className="w-6 h-6 text-theme"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-1 text-sm text-gray-500">
                <span className="hover:underline text-theme">
                  Click to upload&nbsp;
                </span>
                or drag and drop
              </p>
              <p className="text-xs text-gray-500">
                *Please Upload File in xlsx, xls, xlt, xlsm, csv Format Only.
              </p>
              {ReportData?.name ? (
                <p className="text-xs text-gray-500">
                  File Name : {ReportData?.name}
                </p>
              ) : (
                ""
              )}
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept=".xlsx,.xls,.xlt,.xlsm,.csv"
              multiple={false}
              required
              onChange={async (e) => {
                await readData(e.target.files[0]);
                setReportData({
                  name: e.target.files[0]?.name,
                  contentType: mime.getType(e.target.files[0]?.name),
                });
              }}
            />
          </label>

          <div className="flex justify-end gap-2">
            <button
              type="submit"
              className="min-w-[136px] h-25 text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              Import
            </button>
          </div>
        </div>
        <div className="relative overflow-x-auto mt-3">
          <table className="text-sm text-left rtl:text-right text-midBlack w-full">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                {columnValidation.map((h, i) => (
                  <th scope="col" className="px-3 py-3" key={i}>
                    <div className="flex gap-2">
                      <div> {h.displayName}</div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {ExcelRows?.map((r, i) => (
                <tr key={i} className="bg-white border-b">
                  {columnValidation.map((c, j) => (
                    <td key={j} className="p-0.5">
                      {c.type === "enum" ? (
                        <select
                          className="border-0 text-[14px] border-gray-300 bg-gray-100 rounded-md h-8 w-[100%] px-2"
                          required={c.isMandatory}
                          value={r[c.name]}
                          onChange={(e) => {
                            ExcelRows[i][c.name] = e.target.value;
                            setExcelRows([...ExcelRows]);
                          }}
                        >
                          {c.member.map((e, ei) => (
                            <option key={ei} value={e}>
                              {e}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          className="border-0 text-[14px] border-gray-300 bg-gray-100 rounded-md h-8 w-[100%]"
                          value={r[c.name]}
                          type={c.type}
                          required={c.isMandatory}
                          onChange={(e) => {
                            ExcelRows[i][c.name] = e.target.value;
                            setExcelRows([...ExcelRows]);
                          }}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
}

export default ImportExcel;
