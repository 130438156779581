import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import { Pagination } from "@mui/material";
import {
  getTenantWaba,
  walletHistoryQuery,
} from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import { getTenants } from "../../store/actions/partnerAction";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import RightSidePanel from "../../components/RightSidePanel";
import AddTenantWalletTopup from "../../layouts/partner/AddTenantWalletTopup";
import TenantWalletDetail from "../../layouts/partner/TenantWalletDetail";
import TypeableSelect from "../../components/Dropdowns/TypeableSelect";
import { useTranslation } from "react-i18next";
const { RangePicker } = DatePicker;

function TenantWalletTopup({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showAddTopup, setShowAddTopup] = useState(false);
  const [walletHistory, setwalletHistory] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState({});
  const [showWalletDetail, setShowWalletDetail] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [wabas, setWabas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    criteria: { amountSource: ["TOP_UP", "TRANSFER", "RECHARGE"] },
  });
  const [dateFilter, setDateFilter] = useState();
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const partnerTimezone =
    partnerReducer.partnerDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    dispatch(
      getTenants(
        {
          criteria: { active: true },
          count: true,
          size: 500,
          offset: 0,
        },
        (d) => {
          setCompanyList(d?.data ?? []);
          if (d?.data?.length > 0) {
            setFilter({
              ...filter,
              criteria: { ...filter.criteria, tenantId: d?.data[0]?.id },
            });
          }
        }
      )
    );
  }, []);

  useEffect(() => {
    if (filter?.criteria?.tenantId) {
      dispatch(
        getTenantWaba(
          {
            tenantId: filter?.criteria?.tenantId,
            criteria: { active: true },
          },
          (d) => {
            setWabas(d?.data ?? []);
            if (d?.data?.length > 0) {
              setFilter({
                ...filter,
                criteria: { ...filter.criteria, tenantWabaId: d?.data[0]?.id },
              });
            }
          }
        )
      );
    }
  }, [filter?.criteria?.tenantId]);

  useEffect(() => {
    if (companyList?.length > 0 && wabas?.length > 0) onSearch();
  }, [currentPage, companyList, wabas]);

  const onSearch = () => {
    const fl = [];
    if (searchText) {
      fl.push(
        {
          property: "amountSource",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "remarks",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "OR" },
        {
          property: "transactionId",
          operator: "ICONTAINS",
          value: searchText,
        }
      );
    }
    if (dateFilter && searchText) {
      filter.filter = [...fl, { clause: "OR" }, dateFilter];
    } else if (searchText) {
      filter.filter = fl;
    } else if (dateFilter) {
      filter.filter = [dateFilter];
    } else {
      filter.filter = [];
    }

    dispatch(
      walletHistoryQuery(
        {
          ...filter,
          tenantId: filter?.criteria?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setwalletHistory(
            d?.data?.map((m) => {
              const comp = companyList?.find((f) => f.id === m?.tenantId);
              return {
                ...m,
                wabaDisplayName: getWabaDisplayName(m?.tenantWabaId),
                companyName: comp?.name,
              };
            })
          );
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const selectDateRange = (dates) => {
    if (dates && dates.length > 0) {
      const [start, end] = dates;
      if (start && end) {
        setDateFilter({
          property: "createdAt",
          operator: "RANGE",
          lowerBound: Date.parse(start.toDate()),
          upperBound: Date.parse(end.toDate()),
        });
      } else {
        setDateFilter(null);
      }
    } else {
      setDateFilter(null);
    }
  };

  const getWabaDisplayName = (tenantWabaId) => {
    const wa = wabas?.find((f) => f.id === tenantWabaId);
    return `${wa?.teamName ?? ""}  +${wa?.phoneCode} ${wa?.phone}`;
  };

  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-book text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">{t('Manage Top up / Recharge')}</div>
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => setShowAddTopup(true)}
            >
              {t('Add Topup')}
            </button>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-12 gap-4 items-end">
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>Company</label>
              </div>
              <TypeableSelect
                list={companyList}
                selected={filter.criteria}
                selectedValueField="tenantId"
                labelField="name"
                valueField="id"
                required={true}
                placeholder={t("Company Name")}
                onSelect={(s) => {
                  if (s?.id) {
                    setFilter({
                      ...filter,
                      criteria: { ...filter.criteria, tenantId: s.id },
                    });
                  } else {
                    delete filter.criteria.tenantId;
                  }
                }}
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('WABA')}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      tenantWabaId: e.target.value,
                    },
                  });
                }}
              >
                {wabas?.map((w, i) => (
                  <option value={w.id} key={i}>{`${w?.teamName ?? ""}  +${w?.phoneCode
                    } ${w?.phone}`}</option>
                ))}
              </select>
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Wallet Details')}</label>
              </div>
              <input
                type="text"
                placeholder="Enter Transaction Details"
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Date Range')}</label>
              </div>
              <RangePicker
                className={"h-10"}
                onChange={selectDateRange}
                format="YYYY-MM-DD"
              />
            </div>

            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {walletHistory && walletHistory.length > 0 && (
            <>
              <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
                <thead className="text-[14px] text-midBlack bg-gray-200">
                  <tr>
                    <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                      {t('Sr. No.')}
                    </th>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t('Company Name')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('WABA Number')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Balance Amount')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Transaction Amount')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Source')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Date')}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t('Action')}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {walletHistory.map((p, i) => {
                    return (
                      <tr className="bg-white border-b" key={i}>
                        <td className="py-3 text-[13px] pl-2">
                          {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                        </td>
                        <td className="py-3 text-[13px] pl-2 font-normal">
                          {p?.companyName ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.wabaDisplayName}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.currencyCode} {p?.balanceAmount}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.currencyCode}{" "}
                          {p?.walletTransactionType === "DEBIT" ? (
                            <label className="text-theme text-[16px]">-</label>
                          ) : (
                            <label className="text-[#008000] text-[16px]">
                              +
                            </label>
                          )}
                          {p?.transactionAmount}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.amountSource}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {moment
                            .utc(p?.createdAt)
                            .tz(partnerTimezone)
                            .format("YYYY-MM-DD hh:mm A")}
                        </td>
                        <td className=" py-3 text-[13px] font-normal">
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedWallet(p);
                                setShowWalletDetail(true);
                              }}
                            >
                              <i className="fa-solid fa-circle-info pr-2"></i>
                              {t('Detail View')}
                            </Dropdown.Item>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">{t('Add Top Up')}</span>
        }
        isShowModal={showAddTopup}
        setIsShowModal={setShowAddTopup}
        ModalBody={
          <AddTenantWalletTopup setIsShow={setShowAddTopup} onAdd={onSearch} />
        }
        size="xl"
      />
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showWalletDetail}
        setIsShow={setShowWalletDetail}
        ModalTitle={t("Wallet Details")}
        Component={
          <TenantWalletDetail
            timezone={partnerTimezone}
            setIsShow={setShowWalletDetail}
            details={selectedWallet}
          />
        }
      />
    </div>
  );
}

export default TenantWalletTopup;
