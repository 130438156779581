import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTenantContacts } from "../../store/actions/tenantAction";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";

function ViewContact({ category, setIsShow }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [Contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const globalReducer = useSelector((state) => state.globalReducer);
  const [filter, setFilter] = useState({
    criteria: { tenantId: globalReducer.loginInfo?.tenantId, active: true },
  });
  const itemParPage = 6;

  useEffect(() => {
    ContactSearch();
  }, [currentPage]);

  const ContactSearch = () => {
    dispatch(
      getTenantContacts(
        {
          ...filter,
          criteria: {
            ...filter?.criteria,
            tenantId: globalReducer.loginInfo?.tenantId,
            category: category,
            active: true,
          },
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
        },
        (d) => {
          setContacts(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  return (
    <div className="height30 flex flex-col justify-between pb-3">
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-5 gap-4 items-end">
          <div className={`flex flex-col col-span-2`}>
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t("Contact Details")}</label>
            </div>
            <input
              type="text"
              placeholder="Enter Contact Details"
              className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
              onChange={(e) => {
                const fl = [];
                if (!isNaN(e.target.value)) {
                  fl.push({
                    property: "whatsappNumber",
                    operator: "CONTAINS",
                    value: e.target.value,
                  });
                } else if (e.target.value?.includes("@")) {
                  fl.push({
                    property: "email",
                    operator: "ICONTAINS",
                    value: e.target.value,
                  });
                } else {
                  fl.push({
                    property: "name",
                    operator: "ICONTAINS",
                    value: e.target.value,
                  });
                }
                setFilter({ ...filter, filter: fl });
              }}
            />
          </div>
          <div className={`flex flex-col`}>
            <button
              type="button"
              className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
              onClick={() => {
                if (currentPage === 1) {
                  ContactSearch();
                } else {
                  setCurrentPage(1);
                }
              }}
            >
              {" "}
              {t("Search")}
            </button>
          </div>
        </div>
        <div>
          {Contacts && Contacts.length > 0 && (
            <div className="mt-2">
              <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-auto">
                <thead className="text-[14px] text-midBlack">
                  <tr>
                    <th scope="col" className="font-[500] py-3 pl-2">
                      <div className="flex gap-2">
                        <div>{t("Customer Id")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div>{t("Name")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Email")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Gender")}</div>
                      </div>
                    </th>
                    <th scope="col" className="font-[500]">
                      <div className="flex gap-2">
                        <div> {t("Phone")}</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Contacts.map((p, i) => {
                    return (
                      <tr className="bg-white border-b">
                        <td className={"py-3 text-[13px] pl-2 font-normal"}>
                          {p?.customerId ?? "-"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {p?.name ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {p?.email ?? "-"}
                        </td>
                        <td className={"py-3 text-[13px] font-normal"}>
                          {p?.gender ?? "-"}
                        </td>
                        <td className="py-3 text-[13px] font-normal">
                          {`+${p?.whatsappNumber}`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-center gap-2">
        {totalPages > 1 && (
                <div className="flex overflow-x-auto sm:justify-center bottom-0 my-2">
                  <Pagination
                    page={currentPage}
                    count={totalPages}
                    onChange={(event, page) => setCurrentPage(page)}
                    shape="rounded"
                    color="secondary"
                  />
                </div>
              )}
        </div>
      </div>
    </div>
  );
}

export default ViewContact;
