import React, { useEffect, useState } from "react";
import { FiCreditCard } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "flowbite-react";
import { Pagination } from "@mui/material";
import RightSidePanel from "../../components/RightSidePanel";
import AddRateCard from "../../layouts/partner/AddRateCard";
import {
  changeRateCardStatus,
  getPartnerRateCardDetails,
  rateCardQuery,
} from "../../store/actions/partnerAction";
import moment from "moment-timezone";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import ViewPartnerRateCard from "../../layouts/partner/ViewPartnerRateCard";
import { useTranslation } from "react-i18next";

function ConversationRateCard({ showSidebar }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [rateCards, setRateCards] = useState([]);
  const [partnerRateCard, setPartnerRateCard] = useState();
  const [viewParterRateCard, setViewParterRateCard] = useState(false);
  const [selectedRateCard, setSelectedRateCard] = useState({});
  const [showAddRateCard, setShowAddRateCard] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({ criteria: {} });
  const globalReducer = useSelector((state) => state.globalReducer);
  const partnerReducer = useSelector((state) => state.partnerReducer);
  const partnerTimezone =
    partnerReducer.partnerDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);

  useEffect(() => {
    onSearch();
  }, [currentPage]);

  useEffect(() => {
    if (globalReducer.country?.length > 0) {
      dispatch(
        getPartnerRateCardDetails(
          { criteria: { partnerId: globalReducer.loginInfo.partnerId } },
          (a) => {
            if (a && a.length > 0) {
              const pRateCard = a?.map((m) => {
                const country = globalReducer.country.find(
                  (fi) => fi.code === m.countryCode
                );
                return {
                  ...m,
                  countryName: country?.name,
                  currencyCode: country?.currencyCode,
                };
              });
              setPartnerRateCard({
                rateCardName: pRateCard[0].rateCardName,
                applicableDate: pRateCard[0].applicableDate,
                createdAt: pRateCard[0].createdAt,
                countryName: pRateCard.filter((f) => f.currency === "NATIVE")[0]
                  .countryName,
                rateCard: pRateCard,
              });
            }
          }
        )
      );
    }
  }, [globalReducer.country]);

  const onSearch = () => {
    dispatch(
      rateCardQuery(
        {
          ...filter,
          criteria: { ...filter.criteria, partnerId: globalReducer.loginInfo?.partnerId },
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }]
        },
        (a) => {
          setRateCards(a?.data ?? []);
          setTotalPages(Math.ceil(a?.count / itemParPage));
        }
      )
    );
  };

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <FiCreditCard className="text-theme text-[14px]" />
            <div className="text-[14px] font-medium">{t('Configurations')}</div>
          </div>
          <div className="flex justify-center items-center">
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedRateCard(null);
                setShowAddRateCard(true);
              }}
            >
              {t('Add Message Rate Card')}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Company Name')}</label>

              </div>
              <input
                type="text"
                placeholder={t("Name")}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    filter: [
                      {
                        property: "name",
                        operator: "ICONTAINS",
                        value: e.target.value,
                      },
                    ],
                  })
                }
              />
            </div>
            <div className={`flex flex-col col-span-2`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Country')}</label>
              </div>
              <SelectCountry
                countryList={globalReducer.country}
                onSelectCountry={(country) => {
                  if (country) {
                    filter.criteria.countryCode = country.code;
                  } else {
                    delete filter.criteria.countryCode;
                  }
                  setFilter({ ...filter });
                }}
                placeholder={t('Select Country')}
              />
            </div>
            <div className={`flex flex-col col-span-1`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Status')}</label>
              </div>
              <select
                className="border border-gray-300 rounded py-[8px] px-[12px]  focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px] text-black h-[40px]"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    criteria: {
                      ...filter.criteria,
                      active: e.target.value == "true",
                    },
                  });
                }}
              >
                <option value={true} selected>
                  {t('Active')}
                </option>
                <option value={false}>{t('Not Active')}</option>
              </select>
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >

                {t('Search')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          <>
            <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
              <thead className="text-[14px] text-midBlack bg-gray-200">
                <tr>
                  <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                    {t('Sr. No.')}
                  </th>
                  <th scope="col" className="font-[500] py-3 pl-2">
                    {t('Rate Card Name')}
                  </th>
                  <th scope="col" className="font-[500]">
                    {t('Country Name')}
                  </th>
                  <th scope="col" className="font-[500]">
                    {t('Applicable Date')}
                  </th>
                  <th scope="col" className="font-[500]">
                    {t('Created Date')}
                  </th>
                  <th scope="col" className="font-[500] w-[160px]">
                    {t('Status')}
                  </th>
                  <th scope="col" className="font-[500]">
                    {t('Action')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {partnerRateCard && (
                  <tr className="bg-gray-100 border-b">
                    <td className="py-3 text-[13px] pl-2">

                    </td>
                    <th
                      scope="row"
                      className="py-3 text-[13px] pl-2 font-bold hover:underline hover:text-theme cursor-pointer text-theme"
                      onClick={() => {
                        setViewParterRateCard(true);
                      }}
                    >
                      {partnerRateCard?.rateCardName ?? "-"}
                    </th>
                    <td className="py-3 text-[13px] font-normal">
                      {partnerRateCard?.countryName ?? "-"}
                    </td>
                    <td className="py-3 text-[13px] font-normal">
                      {moment
                        .utc(partnerRateCard?.applicableDate)
                        .tz(partnerTimezone)
                        .format("YYYY-MM-DD") ?? "-"}
                    </td>
                    <td className="py-3 text-[13px] font-normal">
                      {moment
                        .utc(partnerRateCard?.createdAt)
                        .tz(partnerTimezone)
                        .format("YYYY-MM-DD hh:mm A") ?? "-"}
                    </td>
                    <td className="py-3 text-[13px] font-normal">
                      <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                        {t("Active")}
                      </label>
                    </td>
                    <td className=" py-3 text-[13px] font-normal">
                      <Dropdown
                        inline
                        arrowIcon={false}
                        inputMode
                        as="button"
                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                        label={<i className="fa-solid fa-ellipsis"></i>}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setViewParterRateCard(true);
                          }}
                        >
                          {t('View')}
                        </Dropdown.Item>
                      </Dropdown>
                    </td>
                  </tr>
                )}
                {rateCards.map((p, i) => {
                  return (
                    <tr className="bg-white border-b" key={i}>
                      <td className="py-3 text-[13px] pl-2">
                        {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                      </td>
                      <th
                        scope="row"
                        className="py-3 pl-2 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme"
                        onClick={() => {
                          setSelectedRateCard({
                            ...p,
                            isEdit: true,
                            isView: true,
                          });
                          setShowAddRateCard(true);
                        }}
                      >
                        {p?.name ?? "-"}
                      </th>
                      <td className="py-3 text-[13px] font-normal">
                        {p?.country?.name ?? "-"}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {moment
                          .utc(p?.applicableDate)
                          .tz(partnerTimezone)
                          .format("YYYY-MM-DD") ?? "-"}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {moment
                          .utc(p?.createdAt)
                          .tz(partnerTimezone)
                          .format("YYYY-MM-DD hh:mm A") ?? "-"}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {p?.active ? (
                          <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                            Active
                          </label>
                        ) : (
                          <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                            Not Active
                          </label>
                        )}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        <Dropdown
                          inline
                          arrowIcon={false}
                          inputMode
                          as="button"
                          className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                          label={<i className="fa-solid fa-ellipsis"></i>}
                        >
                          {p?.active ? (
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(
                                  changeRateCardStatus(
                                    { id: p?.id, isActive: false },
                                    (a) => onSearch()
                                  )
                                );
                              }}
                            >
                              {t('Deactivate')}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() => {
                                dispatch(
                                  changeRateCardStatus(
                                    { id: p?.id, isActive: true },
                                    (a) => onSearch()
                                  )
                                );
                              }}
                            >
                              {t('Activate')}
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedRateCard({ ...p, isEdit: true });
                              setShowAddRateCard(true);
                            }}
                          >
                            {t('Edit')}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedRateCard({
                                ...p,
                                isEdit: true,
                                duplicate: true,
                              });
                              setShowAddRateCard(true);
                            }}
                          >
                            {t('Duplicate')}
                          </Dropdown.Item>
                        </Dropdown>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {totalPages > 1 ? (
              <div className="px-5 flex gap-2 sticky bottom-0 justify-end w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
                <Pagination
                  page={currentPage}
                  count={totalPages}
                  onChange={(event, page) => setCurrentPage(page)}
                  shape="rounded"
                  color="secondary"
                />
              </div>
            ) : (
              ""
            )}
          </>
        </div>
      </div>
      <RightSidePanel
        IsCustomize={true}
        CustomClass="fixed md:w-[70%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showAddRateCard}
        setIsShow={setShowAddRateCard}
        Component={
          <AddRateCard
            setIsShow={setShowAddRateCard}
            onAdd={onSearch}
            isEdit={selectedRateCard?.isEdit}
            isView={selectedRateCard?.isView}
            duplicate={selectedRateCard?.duplicate}
            defaultRate={selectedRateCard}
          />
        }
      />

      <RightSidePanel
        CustomClass="fixed md:w-[70%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewParterRateCard}
        ModalTitle={t("Conversation Rate Card")}
        setIsShow={setViewParterRateCard}
        Component={
          <ViewPartnerRateCard
            setIsShow={setViewParterRateCard}
            selectedRateCard={partnerRateCard?.rateCard}
          />
        }
      />
    </div>
  );
}

export default ConversationRateCard;
