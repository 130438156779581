import React, { useEffect, useState } from 'react'
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardInfo, getTenants } from '../../store/actions/partnerAction';
import moment from 'moment-timezone';
import PopUp from '../../components/PopUp';
import UpcomingAccount from '../../layouts/partner/UpcomingAccount';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import TypeableSelect from '../../components/Dropdowns/TypeableSelect';
import { getTenantWaba, messageCount, messageCountChart } from '../../store/actions/tenantAction';

function Dashboard({ showSidebar }) {
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [companyList, setCompanyList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [wabas, setWabas] = useState([]);
    const [selectedWaba, setSelectedWaba] = useState({});
    const [showAccounts, setShowAccounts] = useState(false);
    const [showAccountsType, setShowAccountsType] = useState("");
    const [dashboardInfo, setDashboardInfo] = useState({});
    const [totalMessage, setTotalMessage] = useState({});
    const [months, setMonths] = useState([]);
    const [tickPlacement, setTickPlacement] = useState('middle');
    const [statistics, setStatistics] = useState([]);
    const [tickLabelPlacement, setTickLabelPlacement] = useState('middle');
    const [chartData, setChartData] = useState([]);
    const partnerReducer = useSelector((state) => state.partnerReducer);
    const globalReducer = useSelector((state) => state.globalReducer);
    const partnerTimezone = partnerReducer.partnerDetails?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    const chartSetting = {
        yAxis: [{ label: 'Statistics' }],
        series: [{ dataKey: 'count', label: 'Today’s Statistics', valueFormatter }],
        height: 350,
        sx: { [`& .${axisClasses.directionY} .${axisClasses.label}`]: { transform: 'translateX(-10px)' } },
    };

    useEffect(() => {
        dispatch(getTenants({
            criteria: { active: true },
            count: true,
            size: 500,
            offset: 0,
        }, (d) => {
            setCompanyList(d?.data ?? []);
            if (d?.data?.length > 0) {
                setSelectedCompany({ ...d?.data[0], tenantId: d?.data[0]?.id });
            }
        }));
        dispatch(getDashboardInfo({}, setDashboardInfo));
    }, []);

    useEffect(() => {
        if (selectedCompany?.tenantId) {
            dispatch(getTenantWaba(
                {
                    tenantId: selectedCompany?.tenantId,
                    criteria: { active: true },
                }, (d) => {
                    setWabas(d?.data ?? []);
                    if (d?.data?.length > 0) {
                        setSelectedWaba(d?.data[0]);
                    }
                }));
        }
    }, [selectedCompany?.tenantId]);

    useEffect(() => {
        if (selectedWaba?.id) {
            let messageDate = moment().tz(partnerTimezone);
            messageDate.subtract(1, "month");
            dispatch(messageCount(
                {
                    tenantId: selectedCompany?.tenantId,
                    tenantWabaId: selectedWaba?.id,
                    sendDate: messageDate.format("YYYY-MM-DD"),
                }, (a) => {
                    setTotalMessage(a);
                    setStatistics([
                        { count: a.totalScheduled, status: "Total" },
                        { count: a.accepted, status: "Accepted" },
                        { count: a.delivered, status: "Delivered" },
                        { count: a.read, status: "Read" },
                        { count: a.failed, status: "Failed" }
                    ]);
                })
            );
            const month = new Date().getMonth();
            const year = new Date().getFullYear(); // Current year
            const monthDates = [];
            for (var i = 6; i >= 0; i--) {
                const calculatedMonth = month - i; // Calculate the month offset
                const fromDate = new Date(year, calculatedMonth, 1); // Handles month overflow
                const toDate = new Date(year, calculatedMonth + 1, 1); // Next month's first day
                monthDates.push({
                    text: moment(fromDate).tz(partnerTimezone).format("MMMM"),
                    fromDate: fromDate,
                    toDate: toDate,
                });
            }
            setMonths(monthDates);
            dispatch(messageCountChart(
                {
                    tenantId: selectedCompany?.tenantId,
                    tenantWabaId: selectedWaba?.id,
                    dates: monthDates,
                }, (a) => {
                    setChartData(a);
                }
            ));
        }
    }, [selectedWaba?.id]);

    function valueFormatter(value) {
        return `${value} message`;
    }
    const getGreeting = () => {
        const currentHour = new Date().getHours(); // Get the current hour (0-23)
        if (currentHour < 12) {
            return <span className="text-[#FFA500] font-medium">{t("Good Morning")}!&nbsp;</span>;
        } else if (currentHour < 18) {
            return (
                <span className="text-[#EA2F30] font-medium">{t("Good Afternoon")}!&nbsp;</span>
            );
        } else {
            return <span className="text-[#00008B] font-medium">{t("Good Night")}!&nbsp;</span>;
        }
    };
    return (<div className='h-screen'>
        <div className='flex flex-col h-full justify-between grow'>
            <div className="px-5 pt-5 mt-[1rem] mb-[20px]">
                <div className={`${showSidebar ? "" : "ml-5"} pt-2 pb-4 flex justify-between items-center`}>
                    <div className="text-[14px]">
                        <label>
                            {getGreeting()}
                            <span className="text-gray-500"> {globalReducer.loginInfo?.name}</span>
                        </label>
                    </div>
                </div>
                <div className='grid grid-cols-4 gap-4 text-[14px]'>
                    <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
                        <div className="flex justify-between">
                            <div>
                                <label className="text-gray-400">{t("Pending Account")}</label>
                                <br />
                                <label className="text-[#008743] text-[16px]">
                                    {dashboardInfo?.pendingAccount}
                                </label>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="pt-2 border-t">
                            <label onClick={() => { setShowAccounts(true); setShowAccountsType("PENDING") }} className="cursor-pointer hover:text-theme hover:underline">
                                <i className="fa-regular fa-eye mr-1"></i>{t("View More")}
                            </label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
                        <div className="flex justify-between">
                            <div>
                                <p className="text-gray-400">{t("Upcoming Account")}</p>
                                <p className="text-[#37B3FF] text-[16px]">{dashboardInfo?.upcomingAccounts}</p>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="pt-2 border-t flex justify-between">
                            <label onClick={() => { setShowAccounts(true); setShowAccountsType("UPCOMING") }} className="cursor-pointer hover:text-theme hover:underline">
                                <i className="fa-regular fa-eye mr-1"></i>{t("View More")}
                            </label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
                        <div className="flex justify-between">
                            <div className="mb-1">
                                <label className="text-gray-400 text-[14px]">
                                    {t("Expired Account")}
                                </label>
                                <br />
                                <label className="text-[#D92D20] text-[16px]">
                                    {dashboardInfo?.expiredAccounts}
                                </label>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="pt-2 border-t flex justify-between">
                            <label onClick={() => { setShowAccounts(true); setShowAccountsType("EXPIRED") }} className="cursor-pointer hover:text-theme hover:underline">
                                <i className="fa-regular fa-eye mr-1"></i>{t("View More")}
                            </label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg flex flex-col justify-between">
                        <div className="flex justify-between">
                            <div>
                                <label className="text-gray-400">{t("Last Login Date Time")}</label>
                                <br />
                                <label className="text-[#F7B400] text-[16px]">
                                    {moment.utc(dashboardInfo?.lastLoginDateTime)
                                        .tz(partnerTimezone)
                                        .format("YYYY-MM-DD hh:mm A")}
                                </label>
                                <br />
                            </div>
                            <div>
                                <i className="fa-regular fa-clock text-[#F7B400] text-[24px]"></i>
                            </div>
                        </div>
                        <div className="pt-2 border-t">
                            <label onClick={() => {
                                navigate("/partner/login-history")
                            }} className="cursor-pointer hover:text-theme hover:underline">
                                <i className="fa-solid fa-clock-rotate-left mr-1"></i>{t("Login History")}
                            </label>
                        </div>
                    </div>
                </div>
                <div className='mt-4 flex justify-end'>
                    <div className='flex justify-between gap-4'>
                        <label className='text-[14px] py-2.5 text-gray-600'>
                            {t("Select Company and WABA")}
                        </label>
                        <div className='min-w-[300px]'>
                            <TypeableSelect list={companyList} selected={selectedCompany} selectedValueField="tenantId" labelField="name" valueField="id" required={true} placeholder={t("Company Name")} height="40px" onSelect={(s) => {
                                setSelectedCompany({ ...selectedCompany, tenantId: s?.id });
                            }} />
                        </div>
                        <div className='min-w-[150px]'>
                            <select required
                                value={selectedWaba?.id}
                                className="border border-gray-300 min-w-[150px] rounded py-[8px] px-[12px] focus:outline-none focus:ring-[0.5px] focus:ring-theme text-[14px]"
                                onChange={(e) => {
                                    setSelectedWaba({ ...selectedWaba, id: e.target.value })
                                }}>
                                {wabas?.map((w, i) => <option value={w.id} key={i} >{`${w.teamName} - +${w.phoneCode} ${w.phone}`}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-6 gap-4 text-[14px] mt-4">
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Scheduled")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.totalScheduled ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check text-gray-500"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Today Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Accepted")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.accepted ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check text-gray-500"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Today Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Delivered")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.delivered ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check-double text-gray-500"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Today Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Read")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.read ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check-double text-[#1291D0]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Today Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Failed")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.failed ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-xmark text-[#D92D20]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#D92D20]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Today Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Queued")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.queued ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-regular fa-clock text-[#F7B400]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#F7B400]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Today Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Scheduled")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.totalScheduledByDate ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check text-gray-500"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Last 30 Days Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Accepted")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.acceptedByDate ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check text-gray-500"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Last 30 Days Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Delivered")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.deliveredByDate ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check-double text-gray-500"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Last 30 Days Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Read")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.readByDate ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-check-double text-[#1291D0]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#008743]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Last 30 Days Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Failed")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.failedByDate ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-solid fa-xmark text-[#D92D20]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#D92D20]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Last 30 Days Status")}</label>
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg shadow-lg">
                        <label className="text-gray-400">{t("Total Queued")}</label>
                        <div className="flex justify-between">
                            <div className="text-[16px] pt-0.5">
                                {new Intl.NumberFormat("en-US").format(totalMessage?.queuedByDate ?? 0)}
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="px-[16px] py-[6px] text-white font-medium text-[14px] bg-gray-100 transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                                >
                                    <i className="fa-regular fa-clock text-[#F7B400]"></i>
                                </button>
                            </div>
                        </div>
                        <div className="flex text-[#F7B400]">
                            <i className="fa-solid fa-caret-up pt-1.5 text-[16px]"></i>
                            <label className="ml-1">{t("Last 30 Days Status")}</label>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-12 mt-5">
                    <div className="col-span-8">
                        {months.length > 0 && (
                            <LineChart
                                height={350}
                                series={[
                                    {
                                        data: chartData.map((m) => m.totalScheduled),
                                        label: "Total",
                                        area: true,
                                        stack: "total",
                                        showMark: false,
                                    },
                                    {
                                        data: chartData.map((m) => m.accepted),
                                        label: "Accepted",
                                        area: true,
                                        stack: "total",
                                        showMark: false,
                                    },
                                    {
                                        data: chartData.map((m) => m.delivered),
                                        label: "Delivered",
                                        area: true,
                                        stack: "total",
                                        showMark: false,
                                    },
                                    {
                                        data: chartData.map((m) => m.read),
                                        label: "Read",
                                        area: true,
                                        stack: "total",
                                        showMark: false,
                                    },
                                    {
                                        data: chartData.map((m) => m.failed),
                                        label: "Failed",
                                        area: true,
                                        stack: "total",
                                        showMark: false,
                                    },
                                ]}
                                xAxis={[
                                    { scaleType: "point", data: months.map((m) => m.text) },
                                ]}
                                sx={{
                                    [`& .${lineElementClasses.root}`]: {
                                        display: "none",
                                    },
                                }}
                            />
                        )}
                    </div>
                    <div className="col-span-4">
                        <BarChart
                            dataset={statistics}
                            xAxis={[
                                {
                                    scaleType: "band",
                                    dataKey: "status",
                                    tickPlacement,
                                    tickLabelPlacement,
                                },
                            ]}
                            {...chartSetting}
                        />
                    </div>
                </div>
            </div>
        </div>
        <PopUp
            ModalHeader={
                <span className="font-medium text-[18px] text-black">
                    {showAccountsType === "PENDING" ? t("Pending Account") : showAccountsType === "UPCOMING" ? t("Upcoming Account") : t("Expired Account")}
                </span>
            }
            isShowModal={showAccounts}
            setIsShowModal={setShowAccounts}
            ModalBody={<UpcomingAccount setIsShow={setShowAccounts} type={showAccountsType} />}
            size="2xl"
        />
    </div>)
}

export default Dashboard;
