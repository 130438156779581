import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTenantSidebar } from "../../utils/TenantSidebar";
import Dashboard from "../../pages/tenant/Dashboard";
import Integration from "../../pages/tenant/Integration";
import { getInfo, removeToken } from "../../utils/types";
import { getTenantDetails } from "../../store/actions/tenantAction";
import { FiZap, FiChevronDown, FiChevronUp } from "react-icons/fi";
import ROUTES from "../../utils/routesConstants";
import ManageUsers from "../../pages/tenant/ManageUsers";
import Contacts from "../../pages/tenant/Contacts";
import APIDocs from "../../pages/tenant/ApiDocs";
import Campaigns from "../../pages/tenant/Campaigns";
import LiveChat from "../../pages/tenant/LiveChat";
import ManageCategories from "../../pages/tenant/ManageCategories";
import Templates from "../../pages/tenant/Templates";
import Chatbot from "../../pages/tenant/Chatbot";
import Settings from "../../pages/tenant/Settings";
import Reports from "../../pages/tenant/Reports";
import AllConversations from "../../pages/tenant/AllConversations";
import AllMessages from "../../pages/tenant/AllMessages";
import TeamInformation from "../../pages/tenant/TeamInformation";
import UserSetting from "../../pages/tenant/UserSetting";
import WebhookLog from "../../pages/tenant/WebhookLog";
import OptOutContact from "../../pages/tenant/OptOutContact";
import TransactionHistory from "../../pages/tenant/TransactionHistory";
import CompanySetting from "../../pages/tenant/CompanySetting";
import AccountStatement from "../../pages/tenant/AccountStatement";
import LoginHistory from "../../pages/LoginHistory";
import { Tooltip } from "flowbite-react";
import QuickSingleMessage from "./QuickSingleMessage";
import AllConversationsInsights from "../../pages/tenant/AllConversationsInsights";
import LiveChatAudience from "../../pages/tenant/LiveChatAudience";
import WhatsAppTemplateReports from "../../pages/tenant/WhatsAppTemplateReports";
import PopUp from "../../components/PopUp";
import { useTranslation } from "react-i18next";
import BroadcastReportPage from "../../pages/tenant/BroadcastReportPage";
import APIMessageReport from "../../pages/tenant/APIMessageReport";
import MessagePushedReport from "../../pages/tenant/MessagePushedReport";
import AgentMetrics from "../../pages/tenant/AgentMetrics";

function TenantLeftPanel({ tabName, subtabName }) {
  const { SidebarTitle } = useTenantSidebar();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [logoutPopup, setLogoutPopup] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const [sidebarComp, setSidebarComp] = useState(null);
  const [sidebarData, setSidebarData] = useState([]);
  const [tenantDetail, setTenantDetail] = useState();
  const [tenantInfo, setTenantInfo] = useState(getInfo());
  const componentMap = {
    dashboard: {
      component: <Dashboard />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "DASHBOARD",
    },
    "account-statement": {
      component: <AccountStatement />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "DASHBOARD",
    },
    "login-history": {
      component: <LoginHistory />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "DASHBOARD",
    },
    "manage-users": {
      component: <ManageUsers />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "TEAM_SETTINGS",
    },
    integrated: {
      component: <Integration />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "INTEGRATION",
    },
    contacts: {
      component: <Contacts />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "CONTACTS",
    },
    "api-doc": {
      component: <APIDocs />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "API",
      permission: "API",
    },
    campaigns: {
      component: <Campaigns />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "CAMPAIGNS",
      permission: "CAMPAIGNS",
    },
    "quick-single-message": {
      component: <QuickSingleMessage />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "CAMPAIGNS",
      permission: "CAMPAIGNS",
    },
    "live-chat": {
      component: <LiveChat />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "LIVE_CHAT",
      permission: "LIVE_CHAT",
    },
    "manage-categories": {
      component: <ManageCategories />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "CONTACTS",
    },
    templates: {
      component: <Templates />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "TEMPLATES",
    },
    settings: {
      component: <Settings />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "SETTINGS",
    },
    chatbot: {
      component: <Chatbot />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "CHATBOT",
    },
    reports: {
      component: <Reports />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "FINANCE_ACCESS",
      permission: "REPORTS",
    },
    allConversations: {
      component: <AllConversations />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "FINANCE_ACCESS",
      permission: "REPORTS",
    },
    insights: {
      component: <AllConversationsInsights />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "FINANCE_ACCESS",
      permission: "REPORTS",
    },
    allMessages: {
      component: <AllMessages />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      planKey: "FINANCE_ACCESS",
      permission: "REPORTS",
    },
    "user-setting": {
      component: <UserSetting />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "SETTINGS",
    },
    "team-information": {
      component: <TeamInformation />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "TEAM_SETTINGS",
    },
    "activity-log": {
      component: <WebhookLog />,
      props: null,
      roles: ["TENANT_SUPER_ADMIN"],
      permission: "ACTIVITY_LOGS",
    },
    "optout-contacts": {
      component: <OptOutContact />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "CONTACTS",
    },
    "transaction-history": {
      component: <TransactionHistory />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "REPORTS",
    },
    "whatsApp-template-reports": {
      component: <WhatsAppTemplateReports />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "REPORTS",
    },
    "message-pushed-report": {
      component: <MessagePushedReport />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "REPORTS",
    },
    "broadcast-report": {
      component: <BroadcastReportPage />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "REPORTS",
    },
    "api-message-report": {
      component: <APIMessageReport />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "REPORTS",
    },
    "agent-metrics": {
      component: <AgentMetrics />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "REPORTS",
    },
    setting: {
      component: <CompanySetting />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "SETTINGS",
    },
    "live-chat-data": {
      component: <LiveChatAudience />,
      props: null,
      roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
      permission: "LIVE_CHAT",
    },
  };

  useEffect(() => {
    setSidebarData(sidebarData);
  }, [sidebarData]);

  useEffect(() => {
    //If there is subTabName then use the subTab key
    let tab;
    if (subtabName) tab = componentMap[subtabName];
    else tab = componentMap[tabName];
    if (!tabName && !subtabName) {
      tab = componentMap["dashboard"];
    } else if (
      !tab ||
      (tab.roles?.filter((f) =>
        tenantInfo?.roleDetails?.some((s) => s.name === f)
      ).length == 0 &&
        !tenantInfo?.roleDetails?.some((s) =>
          s.permissions?.includes(tab.permission)
        ))
    ) {
      navigate(ROUTES.UN_AUTH);
    } else if (
      !tab ||
      (tab.planKey &&
        tenantDetail?.plan?.permissions &&
        !tenantDetail?.plan?.permissions?.includes(tab.planKey))
    ) {
      navigate(ROUTES.UN_AUTH);
    }
    setSidebarComp(tab);
  }, [tabName, subtabName]);

  useEffect(() => {
    if (tenantInfo) {
      dispatch(
        getTenantDetails({ tenantId: tenantInfo.tenantId }, (d) => {
          setTenantDetail(d);

          const finalSideBarData = [];
          SidebarTitle.map((item) => {
            if (
              !tenantInfo?.roleDetails?.some((s) => item.roles.includes(s.name))
            ) {
              item.tabName = item.tabName?.filter((f) =>
                tenantInfo?.roleDetails?.some((s) =>
                  s.permissions?.includes(f.permission)
                )
              );
            }
            finalSideBarData.push(item);
          });

          if (d?.plan?.id) {
            finalSideBarData.map((m) => {
              m.tabName = m.tabName.filter(
                (f) => !f.planKey || d?.plan?.permissions?.includes(f.planKey)
              );
            });
          } else {
            finalSideBarData.map((m) => {
              m.tabName = m.tabName.filter((f) => !f.planKey);
            });
          }
          setSidebarData(finalSideBarData ?? []);
        })
      );
    }
  }, []);

  const openSubtab = (mainIndex, tabIndex) => {
    setSidebarData((prevSidebarData) => {
      const newSidebarData = [...prevSidebarData];
      newSidebarData[mainIndex].tabName.map((tab1, i) => {
        if (tabIndex !== i) tab1.active = false;
      });
      const tab = newSidebarData[mainIndex].tabName[tabIndex];
      tab.active = !tab.active;
      return newSidebarData;
    });
  };

  const setOpenMainTab = (index) => {
    setSidebarData((prevSidebarData) => {
      const newSidebarData = [...prevSidebarData];
      const tab = newSidebarData[index];
      tab.active = !tab.active;
      return newSidebarData;
    });
  };

  const logOut = () => {
    removeToken();
    navigate(ROUTES.LOGIN);
  };

  return (
    <div>
      {showSidebar ? (
        <div className="md:w-[16rem] w-full fixed z-[45] top-[30px] left-0 bottom-0  bg-[#f7f8fc] border-r-[1px] border-[#e9ebf0]">
          <div className="py-[8.3px] sticky z-[30] top-0 border-b border-[#e9ebf0] flex px-4 gap-2 bg-[#F3F4F6] items-center">
            <img
              className="w-[37.5px] h-[37.5px] rounded-full p-1 box-border bg-white"
              src={tenantDetail?.logo ?? ""}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = `${process.env.PUBLIC_URL}/images/default.png`;
              }}
            />
            <div className={`text-[14px] font-medium md:w-[100%] text-black`}>
              {tenantDetail?.name}
              <p className="text-[10px] text-midBlack">
                {tenantInfo?.roleDetails?.some(
                  (s) => s.name === "TENANT_SUPER_ADMIN"
                )
                  ? tenantInfo?.tenantId
                  : ""}
              </p>
            </div>
            <div>
              <i
                onClick={() => setShowSidebar(!showSidebar)}
                className="fa-solid fa-sliders text-gray-500 text-[18px] hover:text-theme cursor-pointer"
              ></i>
            </div>
          </div>
          <div className="aside z-[10] navSideBar-Height h-[80vh] overflow-auto">
            {sidebarData.map((item, i) => {
              return (
                <div key={i}>
                  <div
                    className={`flex justify-between rounded-md ${
                      item?.active ? "" : ""
                    }`}
                    onClick={() => setOpenMainTab(i)}
                  >
                    <div
                      className={`text-[13px] font-medium py-2 ml-5 ${
                        item?.active ? "text-theme" : "text-[#656f7d]"
                      } `}
                    >
                      {item?.value}
                    </div>
                  </div>

                  <ul className="flex flex-col space-y-1 pt-0 w-full pl-1 pr-2">
                    {item?.tabName.map((tab, j) => {
                      return (
                        <>
                          {tab?.subtab?.length > 0 ? (
                            <li key={j} className="w-full px-1">
                              <div
                                className={
                                  "text-black h-[34px] px-2 justify-between relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] hover:text-theme border-l-4 border-transparent rounded pr-2 font-[400] cursor-pointer"
                                }
                                onClick={() => openSubtab(i, j)}
                              >
                                <div className="flex items-center">
                                  <label
                                    className={
                                      "inline-flex justify-center items-center " +
                                      (tab?.subtab?.some(
                                        (s) => s.link === location.pathname
                                      )
                                        ? "text-theme"
                                        : "")
                                    }
                                  >
                                    {tab?.icon}
                                  </label>
                                  <label
                                    className={
                                      "text-[14px] " +
                                      (tab?.subtab?.some(
                                        (s) => s.link === location.pathname
                                      )
                                        ? "text-theme"
                                        : "")
                                    }
                                  >
                                    {tab?.title}
                                  </label>
                                </div>
                                {tab?.active ? (
                                  <FiChevronUp className="text-theme" />
                                ) : (
                                  <FiChevronDown className="text-theme" />
                                )}
                              </div>
                            </li>
                          ) : (
                            <li key={j} className="w-full px-1">
                              <Link
                                to={tab?.link}
                                className={
                                  (location.pathname === tab?.link
                                    ? "text-theme bg-[#FFFFFF]"
                                    : "text-black") +
                                  " h-[34px] px-2 relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400] whitespace-nowrap"
                                }
                              >
                                {tab?.icon}
                                <label className="text-[14px]">
                                  {tab?.title}
                                </label>
                              </Link>
                            </li>
                          )}
                          {tab?.subtab?.length > 0 && tab?.active ? (
                            <ul className="top-[44px] w-full text-sm pl-2 pr-1">
                              {tab?.subtab?.map((subtab, i) => {
                                return (
                                  <li key={i}>
                                    <Link
                                      to={subtab?.link}
                                      className={
                                        (location.pathname === subtab?.link
                                          ? "text-theme font-medium bg-[#FFFFFF]"
                                          : "text-black") +
                                        " py-2 my-1 px-4 text-wrap relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400]"
                                      }
                                    >
                                      <label className="inline-flex justify-center items-center ml-3">
                                        {subtab?.icon ?? (
                                          <FiZap className="mr-2" />
                                        )}
                                      </label>
                                      <label className="ml-2 text-[13px] whitespace-nowrap">
                                        {subtab?.title}
                                      </label>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="bg-[#ecedf1] flex absolute w-full bottom-0 py-2">
            <label
              onClick={() => setLogoutPopup(true)}
              className="flex pl-5 cursor-pointer"
            >
              <span>{t("Logout")}</span>
              <i className="fas fa-sign-out-alt text-theme pl-2 pt-1 cursor-pointer"></i>
            </label>
          </div>
        </div>
      ) : (
        <div className="md:w-fit overflow-auto fixed z-[45] top-[30px] left-0 bottom-0  bg-[#f7f8fc] border-r-[1px] border-[#e9ebf0]">
          <div className="py-[8.3px] border-b border-[#e9ebf0]">
            <div className="flex px-4 gap-2 items-center">
              <div>
                <i
                  onClick={() => setShowSidebar(!showSidebar)}
                  className="fa-solid fa-sliders text-gray-500 text-[18px] hover:text-theme cursor-pointer"
                ></i>
              </div>
            </div>
          </div>
          <div className="overflow-auto height55 aside">
            {sidebarData.map((item, i) => {
              return (
                <div className="aa" key={i}>
                  <div
                    className={`flex justify-between rounded-md ${
                      item?.active ? "" : ""
                    }`}
                    onClick={() => setOpenMainTab(i)}
                  >
                    <div
                      className={`text-[13px] font-medium py-2 ml-5 ${
                        item?.active ? "text-theme" : "text-[#656f7d]"
                      } `}
                    >
                      {item?.value}
                    </div>
                  </div>

                  <ul className="flex flex-col space-y-1 pt-0 w-full pl-1 pr-2">
                    {item?.tabName.map((tab, j) => {
                      return (
                        <>
                          {tab?.subtab?.length > 0 ? (
                            <Tooltip
                              className="whitespace-nowrap"
                              content={tab?.title}
                              style="light"
                              placement="right"
                            >
                              <li key={j} className="w-full px-1">
                                <div
                                  className={
                                    "text-black h-[34px] px-2 justify-between relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] hover:text-theme border-l-4 border-transparent rounded pr-2 font-[400] cursor-pointer"
                                  }
                                  onClick={() => openSubtab(i, j)}
                                >
                                  <div className="flex items-center">
                                    <label
                                      className={
                                        "inline-flex justify-center items-center " +
                                        (tab?.subtab?.some(
                                          (s) => s.link === location.pathname
                                        )
                                          ? "text-theme"
                                          : "")
                                      }
                                    >
                                      {tab?.icon}
                                    </label>
                                    {/* <label
                                    className={
                                      "text-[14px] " +
                                      (tab?.subtab?.some(
                                        (s) => s.link === location.pathname
                                      )
                                        ? "text-theme"
                                        : "")
                                    }
                                  >
                                    {tab?.title}
                                  </label> */}
                                  </div>

                                  {/* {tab?.active ? (
                                  <FiChevronUp className="text-theme" />
                                ) : (
                                  <FiChevronDown className="text-theme" />
                                )} */}
                                </div>
                              </li>
                            </Tooltip>
                          ) : (
                            <Tooltip
                              className="whitespace-nowrap"
                              content={tab?.title}
                              style="light"
                              placement="right"
                            >
                              <li key={j} className="w-full px-1">
                                <Link
                                  to={tab?.link}
                                  className={
                                    (location.pathname === tab?.link
                                      ? "text-theme bg-[#FFFFFF]"
                                      : "text-black") +
                                    " h-[34px] px-2 relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400] whitespace-nowrap"
                                  }
                                >
                                  {tab?.icon}
                                  {/* <label className="text-[14px]">
                                  {tab?.title}
                                </label> */}
                                </Link>
                              </li>
                            </Tooltip>
                          )}
                          {tab?.subtab?.length > 0 && tab?.active ? (
                            <ul className="top-[44px] w-full text-sm pl-2 pr-1">
                              {tab?.subtab?.map((subtab, i) => {
                                return (
                                  <Tooltip
                                    className="whitespace-nowrap"
                                    content={subtab?.title}
                                    style="light"
                                    placement="right"
                                  >
                                    <li key={i}>
                                      <Link
                                        to={subtab?.link}
                                        className={
                                          (location.pathname === subtab?.link
                                            ? "text-theme font-medium bg-[#FFFFFF]"
                                            : "text-black") +
                                          " py-2 my-1 px-4 text-wrap relative flex flex-row items-center focus:outline-none hover:bg-[#FFFFFF] border-l-4 border-transparent hover:text-theme rounded font-[400]"
                                        }
                                      >
                                        <label className="inline-flex justify-center items-center ml-3">
                                          {subtab?.icon ?? (
                                            <FiZap className="mr-2" />
                                          )}
                                        </label>
                                        {/* <label className="ml-2 text-[13px] whitespace-nowrap">
                                        {subtab?.title}
                                      </label> */}
                                      </Link>
                                    </li>
                                  </Tooltip>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="bg-gray-200 flex sticky bottom-0 mt-4 py-2">
            <label
              onClick={() => setLogoutPopup(true)}
              className="flex pl-2 cursor-pointer"
            >
              <i className="fas fa-sign-out-alt text-theme pl-2 pt-1 cursor-pointer"></i>
            </label>
          </div>
        </div>
      )}
      <div
        className={`${
          showSidebar ? "md:pl-[16rem]" : "md:pl-[4rem]"
        } max-md:hidden flex flex-col h-screen w-full overflow-y-auto fixed right-0 bg-[#F3F4F6]`}
      >
        {sidebarComp &&
          React.cloneElement(sidebarComp?.component, {
            ...sidebarComp?.props,
            showSidebar: showSidebar,
          })}
      </div>
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t("Are you want to quit?")}
            </div>
            <div className="font-medium text-[14px] text-[#6E6C6D]">
              {t("Any unsaved changes will be lost.")}
            </div>
          </>
        }
        isShowModal={logoutPopup}
        setIsShowModal={setLogoutPopup}
        ModalBody={
          <>
            <div className="flex justify-end gap-4 p-6">
              <button
                className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                onClick={() => setLogoutPopup(false)}
              >
                {t("Cancel")}
              </button>
              <button
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                onClick={() => logOut()}
              >
                {t("Logout")}
              </button>
            </div>
          </>
        }
        size="md"
      />
    </div>
  );
}

export default TenantLeftPanel;
