import React, { useEffect, useState } from 'react'

function UploaderBase64({ onUploaded, selectedBase64, required, className = "min-w-[355px]", accept = "image/png,image/jpeg,.pdf, .doc, .docx" }) {
    const [uploading, setUploading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [previewfile, setPreviewfile] = useState();
    const [selectedFileUrl, setSelectedFileUrl] = useState();
    const [error, setError] = useState(null);
    const IMAGE_MAX_FILE_SIZE = 10;

    useEffect(() => {
        if (selectedBase64 && selectedBase64 != "") {
            const extension = getFileExtensionFromBase64(selectedBase64)
            if (selectedBase64?.toLowerCase()?.includes("png") || selectedBase64?.toLowerCase()?.includes("jpeg")) {
                setPreviewfile(selectedBase64);
                setSelectedFileUrl(new Date().getTime().toString() + extension);
            }
        } else {
            setPreviewfile("");
            setSelectedFileUrl("");
        }
    }, [selectedBase64])

    const handleUpload = async (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            let fileSize = IMAGE_MAX_FILE_SIZE;
            if (files[0].size > (fileSize * 1024 * 1024)) {
                setError(`File size exceeds the limit of ${fileSize} MB.`);
            } else {
                setError(null);
                onUploaded(await convertBase64(files[0]));
                setFileName(files[0].name);
            }
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const removeFile = () => {
        onUploaded(null);
        setFileName("");
    }
    const getFileExtensionFromBase64 = (base64String) => {
        const match = base64String?.match(/^data:(.+);base64,/);
        if (match) {
            const mimeType = match[1]; // e.g., "image/png"
            return mimeType.split("/")[1]; // Extracts "png"
        }
        return null;
    };
    return (
        <div className={`border border-gray-400 rounded-md border-dashed ${className}`}>
            <div>{selectedFileUrl ?
                previewfile ? <>
                    <div className='flex justify-end relative'>
                        <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                        </button>
                    </div>
                    <img src={previewfile} className="w-fit rounded-t-lg border-none" />
                </> : <>
                    <div className='flex justify-end relative'>
                        <button type='button' onClick={removeFile} className="absolute inline-block right-[10px] top-[10px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x w-3.5 h-3.5 "><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                        </button>
                    </div>
                    <div className='gap-2 p-2 text-center py-2'>
                        <i className="fa-solid fa-file text-[30px] text-[#8d99ae] hover:text-theme"></i>
                        <p className="text-center text-[14px] mt-4 text-[#1E40AF]"> {selectedFileUrl?.split("/")?.pop()}</p>
                    </div>
                </> :
                <label tabIndex={-1} htmlFor="UploadImage" className="flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[130px]">
                    <input id="UploadImage" onChange={handleUpload} required={required} accept={accept} type="file" multiple={false} tabIndex={-1} style={{ display: "none" }} />
                    <div className='flex gap-2'>
                        <i className="fa-solid fa-image text-[24px] hover:text-theme"></i>
                        {uploading && <div className="relative w-6 h-6 rounded-full animate-spin border-2 border-solid border-theme border-t-transparent" />}
                    </div>
                    <p className="w-[80%] text-center text-[14px]">
                        Upload or drag &amp; drop your document here. Maximum size {IMAGE_MAX_FILE_SIZE} MB allowed &amp;
                        document type must be {accept}.
                    </p>
                    <p className="text-center text-[14px] text-theme"> {error}</p>
                    <p className="text-center text-[14px] text-[#1E40AF]"> {fileName ?? selectedFileUrl}</p>
                </label>}
            </div>
        </div>
    )
}

export default UploaderBase64