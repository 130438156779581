import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { walletHistoryQuery } from "../../store/actions/tenantAction";
import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

function TopupHistory({ setIsShow, tenantWabaId, walletHistoryFilter }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tenantReducer = useSelector((state) => state.tenantReducer);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [wallets, setWallets] = useState([]);
    const [filter, setFilter] = useState({
        criteria: { tenantWabaId: tenantWabaId },
    });
    const itemParPage = 5;
    const globalReducer = useSelector((state) => state.globalReducer);
    const wabaTimezone =
        globalReducer.waba?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        onSearch();
    }, [currentPage]);

    const onSearch = () => {
        if (walletHistoryFilter) {
            filter.criteria.walletTransactionType =
                walletHistoryFilter.walletTransactionType;
        }
        filter.filter = [{
            property: "amountSource",
            operator: "IN",
            not: true,
            value: ["BULK_BROADCAST", "SEND_WHATSAPP_MESSAGE"]
        }];
        dispatch(
            walletHistoryQuery(
                {
                    ...filter,
                    tenantId: tenantReducer.tenantDetails.id,
                    count: true,
                    size: itemParPage,
                    offset: (currentPage - 1) * itemParPage,
                    orderBy: [{ property: "id", direction: "DESC" }],
                },
                (d) => {
                    setWallets(d?.data ?? []);
                    setTotalPages(Math.ceil(d?.count / itemParPage));
                }
            )
        );
    };

    return (
        <>
            <div className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto">
                {wallets?.length > 0 ?
                    <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
                        <thead className="text-[14px] text-midBlack bg-gray-100">
                            <tr>
                                <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                                    {t('Sr. No.')}
                                </th>
                                <th scope="col" className="font-[500] py-3 px-2">
                                    {t('Currency')}
                                </th>
                                <th scope="col" className="font-[500] py-3 px-2">
                                    {t('Top up Amount')}
                                </th>
                                <th scope="col" className="font-[500] py-3 px-2">
                                    {t('Top Date & Time')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {wallets?.map((p, i) => {
                                return (
                                    <tr className="bg-white border-b">
                                        <td className="py-3 text-[13px] pl-2">
                                            {currentPage === 1 ? (i + 1) : (itemParPage * (currentPage - 1)) + (i + 1)}
                                        </td>
                                        <td className={"pl-2 py-3 font-normal text-[13px]"}>
                                            {p?.currencyCode ?? "-"}
                                        </td>
                                        <td className="pl-2 py-3 font-normal text-[13px]">
                                            {p?.transactionAmount}
                                        </td>
                                        <td className="pl-2 py-3 font-normal text-[13px]">
                                            {moment
                                                .utc(p?.createdAt)
                                                .tz(wabaTimezone)
                                                .format("YYYY-MM-DD hh:mm A") ?? "-"}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    : <div className="text-center py-4">
                        <label>There hasn't been a top-up yet.</label>
                    </div>}
                {totalPages > 1 && (
                    <div className="flex overflow-x-auto sm:justify-center bottom-0 mt-2">
                        <Pagination
                            page={currentPage}
                            count={totalPages}
                            onChange={(event, page) => setCurrentPage(page)}
                            shape="rounded"
                            color="secondary"
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default TopupHistory;
