import React, { useEffect, useState } from "react";
import { updateTeam } from "../../store/actions/tenantAction";
import { SHOW_SNACKBAR } from "../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import SelectTimezone from "../../components/Dropdowns/SelectTimezone";
import { saveSelectedWaba } from "../../store/actions/globalAction";
import { useTranslation } from "react-i18next";

function TeamInformation({ showSidebar }) {
  const {t} = useTranslation();
  let dispatch = useDispatch();
  const [teamInfo, setTeamInfo] = useState({});
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    setTeamInfo(globalReducer.waba);
  }, [globalReducer.waba]);

  const onSaveSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateTeam(teamInfo, (a) => {
        dispatch(saveSelectedWaba(teamInfo));
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {
            type: "success",
            message: "saved successfully",
            open: true,
          },
        });
      })
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setTeamInfo({ ...teamInfo, teamImage: fileReader.result });
      };
      fileReader.onerror = (error) => { };
    }
  };

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="my-auto font-medium text-black cursor-pointer text-left">
            <div className="text-[14px] font-medium">{t('Team information')}</div>
            <label className="text-[12px] text-gray-600">
              {t('Team information like name, avatar changes etc')}
            </label>
          </div>
          <div className="flex gap-2"></div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <form onSubmit={onSaveSubmit}>
          <div className="px-5 pt-5 mt-[6rem] mb-[20px]">
            <div className="grid grid-cols-12 gap-4 border p-3 rounded-lg">
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>{t('Team Name')}<span className="text-red-500 text-[14px]">*</span></label>

                </div>
                <input
                  type="text"
                  value={teamInfo?.teamName}
                  required
                  placeholder={t("Enter team name")}
                  className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                  onChange={(e) => {
                    setTeamInfo({ ...teamInfo, teamName: e.target.value });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                  <label>{t('Team Description')}<span className="text-red-500 text-[14px]">*</span></label>

                </div>
                <textarea
                  required
                  placeholder={t("Enter team description")}
                  rows="3"
                  value={teamInfo?.teamDescription}
                  onChange={(e) =>
                    setTeamInfo({
                      ...teamInfo,
                      teamDescription: e.target.value,
                    })
                  }
                  className="border border-gray-300 rounded-md text-[13px] px-1 py-1 w-[100%]"
                />
              </div>
              <div className="col-span-6"></div>
              <div className="gap-4 border p-3 rounded-lg mt-3 col-span-12">
                <div className="flex flex-col col-span-12">
                  <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                    <label>{t('Team Avatar')}</label>

                  </div>
                  {teamInfo?.teamImage ? (
                    <div className="border-2 border-gray-300 border-dashed rounded-lg w-full max-h-[220px]">
                      <div className="flex justify-end relative">
                        <button
                          type="button"
                          onClick={() => {
                            setTeamInfo({ ...teamInfo, teamImage: null });
                          }}
                          className="absolute inline-block right-[10px] top-[10px]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-x w-3.5 h-3.5 "
                          >
                            <path d="M18 6 6 18" />
                            <path d="m6 6 12 12" />
                          </svg>
                        </button>
                      </div>
                      <img
                        src={teamInfo?.teamImage}
                        alt="Preview"
                        className="mt-1 max-h-[210px]"
                        style={{ height: "auto", borderRadius: "10px" }}
                      />
                    </div>
                  ) : (
                    <label
                      htmlFor="avatar-dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-45 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-5">
                        <svg
                          className="w-8 h-8 text-theme"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500">
                          <span className="hover:underline text-theme">
                            {t('Click to upload')}&nbsp;
                          </span>
                          {t('or drag and drop')}
                        </p>
                        <p className="text-xs text-gray-500">
                          {t('*Please Upload File in png, jpeg, jpg Format Only.')}
                        </p>
                        {/* {ReportData?.name ? <p className="text-xs text-gray-500">File Name : {ReportData?.name}</p> : ""} */}
                      </div>
                      <input
                        id="avatar-dropzone-file"
                        type="file"
                        className="hidden"
                        accept=".png,.jpeg,.jpg"
                        multiple={false}
                        onChange={handleImageChange}
                      />
                    </label>
                  )}
                </div>
              </div>
              <div className="flex flex-col col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Country Name')}<span className="text-red-500 text-[14px]">*</span></label>
                  <p className="text-[12px] text-gray-400">
                    {t('Your e-commerce related data will have this country/currency by defalut.')}
                  </p>
                </div>
                <SelectCountry
                  required={true}
                  selectedCode={teamInfo?.countryCode}
                  countryList={globalReducer.country}
                  onSelectCountry={(country) => {
                    if (country) {
                      teamInfo.countryCode = country.code;
                      teamInfo.currencyCode = country.currencyCode;
                    } else {
                      delete teamInfo.countryCode;
                      delete teamInfo.currencyCode;
                    }
                    setTeamInfo({ ...teamInfo });
                  }}
                  placeholder={t('Select Country')}
                />
              </div>
              <div className="col-span-6"></div>
              <div className="flex flex-col col-span-6">
                <div className="text-midBlack text-[13px] font-normal mb-1">
                  <label>{t('Timezone')}<span className="text-red-500 text-[14px]">*</span></label>
                  <p className="text-[12px] text-gray-400">
                    {t('Your e-commerce related data will have this timezone by defalut.')}
                  </p>
                </div>
                <SelectTimezone
                  required={true}
                  TimezoneList={globalReducer.timezone}
                  selectedZone={teamInfo?.timezone}
                  onSelectTimezone={(zone) => {
                    if (zone) {
                      teamInfo.timezone = zone.name;
                    } else {
                      delete teamInfo.timezone;
                    }
                    setTeamInfo({ ...teamInfo });
                  }}
                />
              </div>
              <div className="col-span-6"></div>
            </div>
          </div>
          <div className="px-5 flex gap-2 sticky bottom-0 justify-end w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
            <button
              type="submit"
              className="min-w-[100px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
            >
              {t('Save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TeamInformation;
