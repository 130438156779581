import { HttpUtil } from "../../utils/http-utils";
import {
    SHOW_SNACKBAR,
    IS_LOADING,
    getToken,
    getRefreshToken, PARTNER_DETAILS
} from "../../utils/types";

const getApiLoaderDispatch = (res, dispatch) => {
    dispatch({ type: IS_LOADING, payload: { type: IS_LOADING, payload: res } });
};

function postAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOST(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch((e) => {
            console.log("e", e);
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function postParamsAction(dispatch, url, isApiLoader, request, Params, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makePOSTwithParams(url, request, Params, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

function getAction(dispatch, url, isApiLoader, request, header, action) {
    if (isApiLoader) {
        getApiLoaderDispatch(isApiLoader, dispatch);
    }
    return HttpUtil.makeGET(url, request, header)
        .then((res) => {
            if (res?.status === 200) {
                action(res?.data);
            } else {
                dispatch({ type: SHOW_SNACKBAR, payload: { type: "error", message: res?.data?.description ?? "Some Error Occurred", open: true } });
            }
            if (isApiLoader) {
                getApiLoaderDispatch(false, dispatch);
            }
        })
        .catch(() => {
            getApiLoaderDispatch(false, dispatch);
            dispatch({ type: SHOW_SNACKBAR, payload: { type: "#104d89", message: "Please contact with administrator", open: true } });
        });
}

export const getPartnerDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/query`, true, request, { Authorization: `bearer ${getToken()}` }, (a) => {
        if (a?.data && a?.data?.length > 0) {
            action(a?.data[0] ?? {});
        }
    });

export const getTenants = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/tenant-query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const loginAsTenantUser = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/login-as-tenant-user`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const loginBackToSuperAdmin = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/login-back-super-admin`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantDetails = (request, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/${request.tenantId}/details`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const tenantOnboard = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/onboard`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const publicTenantOnboard = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/public/onboard`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const tenantUpdate = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeTenantStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantUsers = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/${request.tenantId}/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantUserDetail = (id, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/detail/${id}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const tenantUserUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantRoles = (tenantId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/roles/${tenantId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const tenantRoleQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/role-query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeTenantRoleStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/role/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertTenantRole = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/tenant-user/role/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getMessageType = (action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/message-type`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const searchBsp = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/master-data/bsp/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const createRateCard = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/create`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const updateRateCard = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/update`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const rateCardQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const rateCardDetailsQuery = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/details/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeRateCardStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changePlanStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/plan/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getPLans = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/plan/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const searchPLans = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/plan/search`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const planUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/plan/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const assignToTenantPlan = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/plan/assign-tenant`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const assignToTenantRateCard = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/assign-tenant`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getPartnerRateCardDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/rate-card/details`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const assignToPartnerRateCard = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/rate-card/assign-partner`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const messageCount = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/dashboard/message-count`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const messageCountChart = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/dashboard/message-count-chart`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getDashboardInfo = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/dashboard/info`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getTenantPlanView = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/dashboard/tenant-plan`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const partnerSearchWaba = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/search-waba`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const partnerWabaUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/upsert-waba`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const upsertPartnerRateCardDetails = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/rate-card/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const savePartnerBank = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/bank`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getPartnerBank = (partnerId, action) => (dispatch) =>
    getAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/bank/${partnerId}`, true, {}, { Authorization: `bearer ${getToken()}` }, action);

export const fetchBspApiKey = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/fetchApiKey`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const getNotification = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/notification/query`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const notificationUpsert = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/notification/upsert`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const changeNotificationStatus = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/notification/change-status`, true, request, { Authorization: `bearer ${getToken()}` }, action);

export const sendNotification = (request, action) => (dispatch) =>
    postAction(dispatch, `${process.env.REACT_APP_PRODUCT_APIURL}/partner/notification/send-to-company`, true, request, { Authorization: `bearer ${getToken()}` }, action);
