import React from "react";
import { useTranslation } from "react-i18next";
import {
  FiUsers,
  FiBarChart,
  FiHome,
  FiGrid,
  FiInbox,
  FiSettings,
} from "react-icons/fi";

export const useTenantSidebar = () => {
  const { t } = useTranslation();

  return {
    SidebarTitle: [
      {
        value: "",
        active: true,
        roles: ["TENANT_ADMIN", "TENANT_SUPER_ADMIN"],
        tabName: [
          {
            permission: "DASHBOARD",
            title: t("Dashboard (Home)"),
            link: "/tenant/dashboard",
            icon: <FiHome className="mr-2" />,
            active: true,
          },
          {
            permission: "INTEGRATION",
            title: t("Integrations"),
            link: "/tenant/integrated",
            icon: <FiGrid className="mr-2" />,
            active: false,
          },
          {
            permission: "LIVE_CHAT",
            planKey: "LIVE_CHAT",
            title: t("Inbox / Live Chat"),
            link: "",
            icon: <FiInbox className="mr-2" />,
            active: false,
            subtab: [
              {
                title: t("Inbox"),
                link: "/tenant/live-chat",
                icon: <FiInbox className="mr-2" />,
                active: false,
              },
              {
                title: t("Inbox Data"),
                link: "/tenant/live-chat-data",
                icon: <i className="fa-solid fa-file-fragment"></i>,
                active: false,
              }
            ]
          },
          {
            permission: "CONTACTS",
            title: t("Contacts"),
            link: "",
            icon: <i className="fa-regular fa-address-card"></i>,
            active: false,
            subtab: [
              {
                title: t("Manage Contacts"),
                link: "/tenant/contacts",
                icon: <i className="fa-regular fa-address-card"></i>,
                active: false,
              },
              {
                title: t("Manage Categories"),
                link: "/tenant/manage-categories",
                icon: <i className="fa-solid fa-code-branch"></i>,
                active: false,
              },
              {
                title: t("Opt-In / Opt-Out"),
                link: "/tenant/optout-contacts",
                icon: <i className="fa-regular fa-address-card"></i>,
                active: false,
              },
            ],
          },
          {
            permission: "TEMPLATES",
            title: t("Manage Templates"),
            link: "/tenant/templates",
            icon: <svg fill="none" className="ml-[-4px]" width="24px" height="24px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.5,68.5v-34H26.9a4.89,4.89,0,0,0-4.8,4.9V74.8a4.89,4.89,0,0,0,4.8,4.9H62.5a4.89,4.89,0,0,0,4.8-4.9V73.4h-34A4.89,4.89,0,0,1,28.5,68.5Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
              <path d="M46.4,30.2H64.1a1.58,1.58,0,0,0,1.6-1.6V25.3a4.89,4.89,0,0,0-4.8-4.9H49.6a4.82,4.82,0,0,0-4.8,4.9v3.3A1.64,1.64,0,0,0,46.4,30.2Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
              <path d="M73,24.4H71.4a.74.74,0,0,0-.8.8v3.3a6.57,6.57,0,0,1-6.5,6.6H46.4a6.64,6.64,0,0,1-6.5-6.6V25.2a.74.74,0,0,0-.8-.8H37.5a4.82,4.82,0,0,0-4.8,4.9V64.6a4.89,4.89,0,0,0,4.8,4.9H73a4.82,4.82,0,0,0,4.8-4.9V29.4A4.85,4.85,0,0,0,73,24.4ZM60.9,55.5a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V53.9a1.58,1.58,0,0,1,1.6-1.6H59.3a1.58,1.58,0,0,1,1.6,1.6ZM69,47.3a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V45.7a1.58,1.58,0,0,1,1.6-1.6H67.4A1.58,1.58,0,0,1,69,45.7Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
            </svg>,
            active: false,
          },
          {
            permission: "CAMPAIGNS",
            planKey: "CAMPAIGNS",
            title: t("Campaign / Broadcast"),
            link: "",
            icon: (
              <svg
                width={20}
                height={21}
                viewBox="0 0 20 21"
                fill="none"
                className="mr-1 ml-[-2px]"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.5 9.96647L17.5 5.7998V15.7998L2.5 12.4665V9.96647Z"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.66677 14.7996C9.57922 15.117 9.43002 15.414 9.22769 15.6737C9.02535 15.9334 8.77385 16.1507 8.48754 16.3132C8.20122 16.4757 7.8857 16.5802 7.559 16.6208C7.23229 16.6614 6.90079 16.6372 6.58343 16.5496C6.26607 16.4621 5.96906 16.3129 5.70936 16.1106C5.44966 15.9082 5.23236 15.6567 5.06985 15.3704C4.90735 15.0841 4.80283 14.7686 4.76227 14.4419C4.7217 14.1152 4.74588 13.7837 4.83343 13.4663"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ),
            active: false,
            subtab: [
              {
                title: t("Quick / Single Message"),
                link: "/tenant/quick-single-message",
                icon: <i className="fa-solid fa-message"></i>,
                active: false,
              },
              {
                title: t("Bulk Broadcast"),
                link: "/tenant/campaigns",
                icon: (
                  <svg
                    width={20}
                    height={21}
                    viewBox="0 0 20 21"
                    fill="none"
                    className="mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 9.96647L17.5 5.7998V15.7998L2.5 12.4665V9.96647Z"
                      stroke="currentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.66677 14.7996C9.57922 15.117 9.43002 15.414 9.22769 15.6737C9.02535 15.9334 8.77385 16.1507 8.48754 16.3132C8.20122 16.4757 7.8857 16.5802 7.559 16.6208C7.23229 16.6614 6.90079 16.6372 6.58343 16.5496C6.26607 16.4621 5.96906 16.3129 5.70936 16.1106C5.44966 15.9082 5.23236 15.6567 5.06985 15.3704C4.90735 15.0841 4.80283 14.7686 4.76227 14.4419C4.7217 14.1152 4.74588 13.7837 4.83343 13.4663"
                      stroke="currentColor"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ),
                active: false,
              }
            ],
          },
          {
            permission: "CHATBOT",
            title: t("Chatbot"),
            link: "/tenant/chatbot",
            icon: <i className="fa-brands fa-rocketchat"></i>,
            active: false,
          },
          {
            permission: "API",
            planKey: "API",
            title: t("API Docs"),
            link: "/tenant/api-doc",
            icon: <i className="fa-regular fa-file-code"></i>,
            active: false,
          },
          {
            permission: "REPORTS",
            planKey: "FINANCE_ACCESS",
            title: t("Reports"),
            link: "",
            icon: <i className="fa-solid fa-file-fragment"></i>,
            active: false,
            subtab: [
              // {
              //   title: t("All Conversations"),
              //   link: "/tenant/allConversations",
              //   icon: <i className="fa-regular fa-comments"></i>,
              //   active: false,
              // },
              {
                title: t("Broadcast Report"),
                link: "/tenant/broadcast-report",
                icon: <svg
                  width={20}
                  height={21}
                  viewBox="0 0 20 21"
                  fill="none"
                  className="mr-1 ml-[-2px]"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 9.96647L17.5 5.7998V15.7998L2.5 12.4665V9.96647Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.66677 14.7996C9.57922 15.117 9.43002 15.414 9.22769 15.6737C9.02535 15.9334 8.77385 16.1507 8.48754 16.3132C8.20122 16.4757 7.8857 16.5802 7.559 16.6208C7.23229 16.6614 6.90079 16.6372 6.58343 16.5496C6.26607 16.4621 5.96906 16.3129 5.70936 16.1106C5.44966 15.9082 5.23236 15.6567 5.06985 15.3704C4.90735 15.0841 4.80283 14.7686 4.76227 14.4419C4.7217 14.1152 4.74588 13.7837 4.83343 13.4663"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>,
                active: false,
              },
              {
                title: t("Insights"),
                link: "/tenant/insights",
                icon: <i className="fa-solid fa-chart-simple"></i>,
                active: false,
              },
              {
                title: t("Transaction History"),
                link: "/tenant/transaction-history",
                icon: <i className="fa-solid fa-book"></i>,
                active: false,
              },
              {
                title: t("Api Message Report"),
                link: "/tenant/api-message-report",
                icon: <i className="fa-regular fa-file-code"></i>,
                active: false,
              },
              {
                title: t("Template Reports"),
                link: "/tenant/whatsApp-template-reports",
                icon: <svg fill="none" className="ml-[-4px]" width="24px" height="24px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.5,68.5v-34H26.9a4.89,4.89,0,0,0-4.8,4.9V74.8a4.89,4.89,0,0,0,4.8,4.9H62.5a4.89,4.89,0,0,0,4.8-4.9V73.4h-34A4.89,4.89,0,0,1,28.5,68.5Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
                  <path d="M46.4,30.2H64.1a1.58,1.58,0,0,0,1.6-1.6V25.3a4.89,4.89,0,0,0-4.8-4.9H49.6a4.82,4.82,0,0,0-4.8,4.9v3.3A1.64,1.64,0,0,0,46.4,30.2Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
                  <path d="M73,24.4H71.4a.74.74,0,0,0-.8.8v3.3a6.57,6.57,0,0,1-6.5,6.6H46.4a6.64,6.64,0,0,1-6.5-6.6V25.2a.74.74,0,0,0-.8-.8H37.5a4.82,4.82,0,0,0-4.8,4.9V64.6a4.89,4.89,0,0,0,4.8,4.9H73a4.82,4.82,0,0,0,4.8-4.9V29.4A4.85,4.85,0,0,0,73,24.4ZM60.9,55.5a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V53.9a1.58,1.58,0,0,1,1.6-1.6H59.3a1.58,1.58,0,0,1,1.6,1.6ZM69,47.3a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V45.7a1.58,1.58,0,0,1,1.6-1.6H67.4A1.58,1.58,0,0,1,69,45.7Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
                </svg>,
                active: false,
              },
              {
                title: t("Message Pushed Report"),
                link: "/tenant/message-pushed-report",
                icon: <svg fill="none" className="ml-[-4px]" width="24px" height="24px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                  <path d="M28.5,68.5v-34H26.9a4.89,4.89,0,0,0-4.8,4.9V74.8a4.89,4.89,0,0,0,4.8,4.9H62.5a4.89,4.89,0,0,0,4.8-4.9V73.4h-34A4.89,4.89,0,0,1,28.5,68.5Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
                  <path d="M46.4,30.2H64.1a1.58,1.58,0,0,0,1.6-1.6V25.3a4.89,4.89,0,0,0-4.8-4.9H49.6a4.82,4.82,0,0,0-4.8,4.9v3.3A1.64,1.64,0,0,0,46.4,30.2Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
                  <path d="M73,24.4H71.4a.74.74,0,0,0-.8.8v3.3a6.57,6.57,0,0,1-6.5,6.6H46.4a6.64,6.64,0,0,1-6.5-6.6V25.2a.74.74,0,0,0-.8-.8H37.5a4.82,4.82,0,0,0-4.8,4.9V64.6a4.89,4.89,0,0,0,4.8,4.9H73a4.82,4.82,0,0,0,4.8-4.9V29.4A4.85,4.85,0,0,0,73,24.4ZM60.9,55.5a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V53.9a1.58,1.58,0,0,1,1.6-1.6H59.3a1.58,1.58,0,0,1,1.6,1.6ZM69,47.3a1.58,1.58,0,0,1-1.6,1.6H43.1a1.58,1.58,0,0,1-1.6-1.6V45.7a1.58,1.58,0,0,1,1.6-1.6H67.4A1.58,1.58,0,0,1,69,45.7Z" fill="currentColor" strokeWidth="1.66667" strokeLinecap="round" />
                </svg>,
                active: false,
              },
              // {
              //   title: t("Agent Metrics"),
              //   link: "/tenant/agent-metrics",
              //   icon: <i className="fa-solid fa-chart-column"></i>,
              //   active: false,
              // }
            ],
          },
          // {
          //   permission: "ACTIVITY_LOGS",
          //   planKey: "ACTIVITY_LOGS",
          //   title: t("Activity Logs"),
          //   link: "/tenant/activity-log",
          //   icon: <i className="fa-solid fa-warehouse text-[12px]"></i>,
          //   active: false
          // },
          {
            permission: "SETTINGS",
            title: t("Settings"),
            link: "",
            icon: <FiSettings className="mr-2" />,
            active: false,
            subtab: [
              {
                title: t("Setting"),
                link: "/tenant/setting",
                icon: <FiSettings />,
                active: false,
              },
              {
                title: t("User Setting"),
                link: "/tenant/user-setting",
                icon: <i className="fa-solid fa-user-shield"></i>,
                active: false,
              },
            ],
          },
          {
            permission: "TEAM_SETTINGS",
            title: t("Team Settings"),
            link: "",
            icon: <i className="fa-solid fa-gears"></i>,
            active: false,
            subtab: [
              {
                title: t("User Management"),
                link: "/tenant/manage-users",
                icon: <FiUsers className="mr-2" />,
                active: false,
              },
              {
                title: t("Team Information"),
                link: "/tenant/team-information",
                icon: <i className="fa-solid fa-people-group"></i>,
                active: false,
              },
            ],
          },
        ],
      },
    ],
  };
};
