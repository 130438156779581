import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getTransaction,
  getWalletTopUp,
  getWalletTransfers,
} from "../../store/actions/tenantAction";
import moment from "moment-timezone";
import PopUp from "../../components/PopUp";
import ViewFile from "../../components/ViewFile";
import { useTranslation } from "react-i18next";

function TenantWalletDetail({ setIsShow, details, timezone }) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [walletSource, setWalletSource] = useState({});
  const [showFile, setShowFile] = useState(false);

  useEffect(() => {
    switch (details?.amountSource) {
      case "RECHARGE":
        dispatch(
          getTransaction(
            details.tenantId,
            details.transactionId,
            setWalletSource
          )
        );
        break;
      case "TOP_UP":
        // dispatch(getWalletTopUp({
        //     criteria: { id: details?.detail?.TenantWalletTopUpId },
        //     size: 1,
        //     offset: 0,
        // }, a => {
        //     if (a?.data?.length > 0)
        //         setWalletSource(a?.data[0]);
        // }));
        break;
      case "TRANSFER":
        dispatch(
          getWalletTransfers(
            {
              criteria: { id: details?.detail?.tenantWalletTransferId },
              size: 1,
              offset: 0,
            },
            (a) => {
              if (a?.data?.length > 0) setWalletSource(a?.data[0]);
            }
          )
        );
        break;
    }
  }, []);

  return (
    <div>
      <label></label>
      <div className="grid grid-cols-3 gap-4 mt-2 text-[14px]">
        {details?.amountSource === "TOP_UP" && (
          <>
            <div className="text-[14px] text-gray-500">{t('Company')}</div>
            <div className="col-span-2">{details?.companyName}</div>
            <div className="text-[14px] text-gray-500">{t('WABA')}</div>
            <div className="col-span-2">{details?.wabaDisplayName}</div>
            <div className="text-[14px] text-gray-500">{t('User who topped up')}</div>
            <div className="col-span-2">{details?.detail?.partnerUserName}</div>
          </>
        )}
        {details?.amountSource === "TRANSFER" && (
          <>
            <div className="text-[14px] text-gray-500">{t('From Company')}</div>
            <div className="col-span-2">{walletSource?.fromCompanyName}</div>
            <div className="text-[14px] text-gray-500">{t('From WABA')}</div>
            <div className="col-span-2">{`${
              walletSource?.fromTeamName ?? ""
            }  +${walletSource?.fromPhoneCode} ${
              walletSource?.fromPhone
            }`}</div>
            <div className="text-[14px] text-gray-500">{t('To Company')}</div>
            <div className="col-span-2">{walletSource?.toCompanyName}</div>
            <div className="text-[14px] text-gray-500">{t('To WABA')}</div>
            <div className="col-span-2">{`${walletSource?.toTeamName ?? ""}  +${
              walletSource?.toPhoneCode
            } ${walletSource?.toPhone}`}</div>
            <div className="text-[14px] text-gray-500">{t('User who transfer')}</div>
            <div className="col-span-2">{details?.detail?.partnerUserName}</div>
          </>
        )}
        {details?.amountSource === "RECHARGE" && (
          <>
            <div className="text-[14px] text-gray-500">{t('Company')}</div>
            <div className="col-span-2">{details?.companyName}</div>
            <div className="text-[14px] text-gray-500">{t('WABA')}</div>
            <div className="col-span-2">{details?.wabaDisplayName}</div>
            <div className="text-[14px] text-gray-500">{t('User who approved')}</div>
            <div className="col-span-2">{details?.detail?.partnerUserName}</div>
            <div className="text-[14px] text-gray-500">{t('Transaction ID')}</div>
            <div className="col-span-2">{walletSource?.transactionId}</div>
            <div className="text-[14px] text-gray-500">{t('Transaction Medium')}</div>
            <div className="col-span-2">{walletSource?.transactionMedium}</div>
            {walletSource?.transactionMedium === "PAYMENT_RECEIPT" && (
              <>
                <div className="text-[14px] text-gray-500">{t('Payment Receipt')}</div>
                <div className="col-span-2">
                  <label
                    onClick={() => setShowFile(true)}
                    className="py-3 pl-2 font-medium text-[14px] hover:underline hover:text-theme cursor-pointer text-theme whitespace-nowrap"
                  >
                    {t('View')}
                  </label>
                </div>
              </>
            )}
          </>
        )}
        <div className="text-[14px] text-gray-500">{t('Amount')}</div>
        <div className="col-span-2">
          {details?.currencyCode}{" "}
          {details?.walletTransactionType === "DEBIT" ? (
            <label className="text-theme text-[16px]">-</label>
          ) : (
            <label className="text-[#008000] text-[16px]">+</label>
          )}
          {details?.transactionAmount}
        </div>
        <div className="text-[14px] text-gray-500">{t('IP Address')}</div>
        <div className="col-span-2">{details?.detail?.ipAddress}</div>
        <div className="text-[14px] text-gray-500">{t('Date')}</div>
        <div className="col-span-2">
          {moment
            .utc(details?.createdAt)
            .tz(timezone)
            .format("YYYY-MM-DD hh:mm A")}
        </div>
        <div className="text-[14px] text-gray-500">{t('Remarks')}</div>
        <div className="col-span-2">{details?.remarks}</div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {t('Payment Receipt')}
          </span>
        }
        isShowModal={showFile}
        setIsShowModal={setShowFile}
        ModalBody={
          <ViewFile
            setIsShow={setShowFile}
            fileName={walletSource?.transactionId + "_payment_receipt"}
            selectedFileUrl={walletSource?.receiptPath}
          />
        }
        size="xl"
      />
    </div>
  );
}

export default TenantWalletDetail;
