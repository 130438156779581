import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { Dropdown } from "flowbite-react";
import PopUp from "../../components/PopUp";
import AddContactCategory from "../../layouts/tenant/AddContactCategory";
import {
  bulkCategoryDelete,
  categoryDelete,
  categoryQuery,
  categoryView,
  getTenantContacts,
  getTenantOptOutContacts,
  tenantContactBulkUpload,
} from "../../store/actions/tenantAction";
import { useDispatch, useSelector } from "react-redux";
import RightSidePanel from "../../components/RightSidePanel";
import ImportExcel from "../../components/ImportExcel";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import ViewContact from "../../layouts/tenant/ViewContact";
import { useTranslation } from "react-i18next";

function ManageCategories({ showSidebar }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddCategory, setshowAddCategory] = useState(false);
  const [uploadContacts, setUploadContacts] = useState(false);
  const [downloadContacts, setDownloadContacts] = useState([]);
  const [deleteContact, setDeleteContact] = useState(false);
  const [viewContacts, setViewContacts] = useState(false);
  const [ContactHeaders, setContactHeaders] = useState([
    { label: t("customerId"), key: "customerId" },
    { label: t("name"), key: "name" },
    { label: t("gender"), key: "gender" },
    { label: t("whatsappNumber"), key: "whatsappNumber" },
    { label: t("email"), key: "email" },
    { label: t("location"), key: "location" },
    { label: t("category"), key: "category" },
  ]);
  const [Category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({});
  const [searchText, setSearchText] = useState();
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const globalReducer = useSelector((state) => state.globalReducer);
  const tenantTimezone =
    tenantReducer.tenantDetails?.timezone ??
    Intl.DateTimeFormat().resolvedOptions().timeZone;
  const itemParPage = parseInt(process.env.REACT_APP_ITEMS_PER_PAGE);
  const columnValidation = [
    {
      name: "customerId",
      displayName: "Customer Id",
      type: "text",
      isMandatory: false,
    },
    { name: "name", displayName: "Name", type: "text", isMandatory: false },
    { name: "gender", displayName: "Gender", type: "text", isMandatory: false },
    { name: "phone", displayName: "Phone", type: "text", isMandatory: true },
    { name: "email", displayName: "Email", type: "email", isMandatory: false },
    {
      name: "location",
      displayName: "location",
      type: "text",
      isMandatory: false,
    },
  ];

  useEffect(() => {
    Categorysearch();
  }, [currentPage]);

  const Categorysearch = () => {
    var fl = [];
    if (searchText) {
      fl.push(
        {
          property: "categoryName",
          operator: "ICONTAINS",
          value: searchText,
        },
        { clause: "AND" }
      );
    }
    fl.push(
      { clause: "OPEN_BRACKET" },
      {
        property: "permission",
        operator: "ICONTAINS",
        value: "PUBLIC",
      },
      { clause: "OR" },
      { clause: "OPEN_BRACKET" },
      {
        property: "permission",
        operator: "ICONTAINS",
        value: "PRIVATE",
      },
      { clause: "AND" },
      {
        property: "uid",
        operator: "EQ",
        value: globalReducer.loginInfo?.uid,
      },
      { clause: "CLOSE_BRACKET" },
      { clause: "CLOSE_BRACKET" }
    );
    filter.filter = fl;
    if (!globalReducer.loginInfo?.roleDetails?.some((s) => s.name === "TENANT_SUPER_ADMIN")) {
      if (filter.filter?.length > 0) {
        filter.filter.push({ clause: "AND" });
      }
      filter.filter.push({ clause: "OPEN_BRACKET" }, {
        property: "categoryName",
        operator: "IN",
        not: true,
        value: "GENERIC_EXTERNAL",
      }, { clause: "CLOSE_BRACKET" });
    }
    dispatch(
      categoryView(
        {
          ...filter,
          tenantId: globalReducer.loginInfo?.tenantId,
          count: true,
          size: itemParPage,
          offset: (currentPage - 1) * itemParPage,
          orderBy: [{ property: "id", direction: "DESC" }],
        },
        (d) => {
          setCategory(d?.data);
          setTotalPages(Math.ceil(d?.count / itemParPage));
        }
      )
    );
  };

  const onSearch = () => {
    if (currentPage === 1) {
      Categorysearch();
    } else {
      setCurrentPage(1);
    }
  };

  const downloadContactsByCategory = (category) => {
    const filter = [{ clause: "OPEN_BRACKET" }, {
      property: "categoryName",
      operator: "IN",
      not: true,
      value: "GENERIC_EXTERNAL",
    }, { clause: "CLOSE_BRACKET" }];
    dispatch(
      getTenantContacts(
        {
          filter: filter,
          tenantId: globalReducer.loginInfo?.tenantId,
          criteria: { category: category },
          size: 1000000,
        },
        (d) => {
          const data = d?.data?.map((m) => {
            return {
              ContactNo: m.whatsappNumber,
              "CustomerID ": m.customerId,
              name: m?.name,
              gender: m.gender,
              location: m.location,
              email: m.email,
              CategoryName: m.category?.join(","),
            };
          });
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data ?? []);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
          XLSX.writeFile(workbook, "contacts.xlsx");
        }
      )
    );
  };

  const downloadOptOutContactsByCategory = (category) => {
    dispatch(
      getTenantOptOutContacts(
        {
          tenantId: globalReducer.loginInfo?.tenantId,
          size: 1000000,
          criteria: { category: category },
        },
        (d) => {
          const data = d?.data?.map((m) => {
            return {
              phone: m.phoneCode + m.phone,
              name: m?.name,
              category: m.category?.join(","),
            };
          });
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(data ?? []);
          XLSX.utils.book_append_sheet(workbook, worksheet, "OptOutContacts");
          XLSX.writeFile(workbook, "OptOutContacts.xlsx");
        }
      )
    );
  };
  return (
    <div className="h-screen">
      <div
        className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"
          } transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}
      >
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-solid fa-code-branch text-[14px] text-theme"></i>
            <div className="text-[14px] font-medium">
              {t("Manage Category")}
            </div>
          </div>
          <div className="flex gap-2">
            <div>
              <div
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                onClick={() => {
                  dispatch(
                    getTenantContacts(
                      {
                        tenantId: globalReducer.loginInfo?.tenantId,
                        size: 1000000,
                      },
                      (d) => {
                        setDownloadContacts(d.data);
                        setTimeout(() => {
                          document.getElementById("ContactsDownload").click();
                        }, 500);
                      }
                    )
                  );
                }}
              >
                {t("Download All Contacts")}
              </div>
              <CSVLink
                filename="Contacts"
                data={downloadContacts}
                headers={ContactHeaders}
                id="ContactsDownload"
              ></CSVLink>
            </div>
            <div
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                setSelectedCategory({});
                setshowAddCategory(true);
              }}
            >
              {t("Add Category")}
            </div>
          </div>
        </div>
        <div className="bg-white text-black font-medium px-5 pt-[6px] pb-[10px] box-border border-b">
          <div className="grid grid-cols-10 gap-4 items-end">
            <div className={`flex flex-col col-span-3`}>
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t("Category Details")}</label>
              </div>
              <input
                type="text"
                placeholder={t("Enter Category Details")}
                className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className={`flex flex-col`}>
              <button
                type="button"
                className="justify-center items-center flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
                onClick={onSearch}
              >
                {t("Search")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        {selectedCategories?.length > 0 && (
          <div className="p-[16px] bg-white rounded-[4px] text-[14px] mt-4 dropdown-shadow absolute w-[290px] min-h-[100px] top-[20px] z-[99] left-[280px]">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-[16px] text-[#374151]">
                {selectedCategories?.length} {t("Contacts Categories")}
              </div>
            </div>
            <ul className="mt-2 w-full gap-2 text-[#374151]">
              <li
                className="w-full py-1.5 px-2 hover:bg-theme hover:text-white bg-gray-50 cursor-pointer"
                onClick={() => {
                  setDeleteContact(true);
                }}
              >
                <div className="flex items-center gap-2">
                  <i className="fa-solid fa-trash-can pr-2"></i>
                  <div className="text-[14px]">{t("Delete")}</div>
                </div>
              </li>
              <li
                className="w-full py-1.5 px-2 hover:bg-theme hover:text-white bg-gray-50 cursor-pointer"
                onClick={() => {
                  downloadContactsByCategory(
                    selectedCategories.map((m) => m.categoryName)
                  );
                  setSelectedCategories([]);
                }}
              >
                <div className="flex items-center gap-2">
                  <i className="fa-solid fa-download pr-2"></i>
                  <div className="text-[14px]">{t("Download Contacts")}</div>
                </div>
              </li>
              <li
                className="w-full py-1.5 px-2 hover:bg-theme hover:text-white bg-gray-50 cursor-pointer"
                onClick={() => {
                  downloadOptOutContactsByCategory(
                    selectedCategories.map((m) => m.categoryName)
                  );
                  setSelectedCategories([]);
                }}
              >
                <div className="flex items-center gap-2">
                  <i className="fa-solid fa-download pr-2"></i>
                  <div className="text-[14px]">
                    {t("Download Opt-Out Contacts")}
                  </div>
                </div>
              </li>
              <li
                className="w-full py-1.5 px-2 hover:bg-theme hover:text-white bg-gray-50 cursor-pointer"
                onClick={() => {
                  navigate("/tenant/campaigns", {
                    state: {
                      isCreate: true,
                      categoryNames: selectedCategories.map(
                        (m) => m.categoryName
                      ),
                    },
                  });
                }}
              >
                <div className="flex items-center gap-2">
                  <i className="fa-solid fa-message pr-2"></i>
                  <div className="text-[14px]">{t("Send Broadcast")}</div>
                </div>
              </li>
            </ul>
          </div>
        )}
        <div className="px-5 py-2 mt-[10.5rem] mb-[20px]">
          {Category && Category.length > 0 ? (
            <table className="text-sm text-left mt-1 rtl:text-right text-midBlack w-full overflow-x-auto">
              <thead className="text-[14px] text-midBlack bg-gray-200">
                <tr>
                  <th
                    scope="col"
                    className="pb-1 font-[500] pr-2 pl-2 w-5"
                  ></th>
                  <th scope="col" className="pb-1 font-[500] pr-2 pl-2">
                    {t("Sr. No.")}
                  </th>
                  <th scope="col" className="font-[500] py-3 pl-2">
                    <div className="flex gap-2">
                      <div>{t("Category Name")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div>{t("Date Created/Updated")}</div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>{t("Permission")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>{t("No. of Contacts")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>{t("Opt-Out Count")}</div>
                    </div>
                  </th>
                  <th scope="col" className="font-[500]">
                    <div className="flex gap-2">
                      <div>{t("Action")}</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {Category.map((p, i) => {
                  return (
                    <tr className="bg-white border-b" key={i}>
                      <td className="py-3 pl-3 text-[13px] w-7">
                        <input
                          id="checked-checkbox"
                          type="checkbox"
                          checked={selectedCategories.some(
                            (m) => m.id === p.id
                          )}
                          className="w-4 h-4 text-theme cursor-pointer bg-gray-100 border-gray-300 rounded-sm"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCategories([
                                ...selectedCategories,
                                p,
                              ]);
                            } else {
                              setSelectedCategories(
                                selectedCategories.filter(
                                  (m) => m.id !== p.id
                                )
                              );
                            }
                          }}
                        />
                      </td>
                      <td className="py-3 text-[13px] pl-2">
                        {currentPage === 1
                          ? i + 1
                          : itemParPage * (currentPage - 1) + (i + 1)}
                      </td>
                      <td className={"py-3 text-[13px] pl-2 font-normal"}>
                        {p?.categoryName ?? "-"}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {moment
                          .utc(p?.contactUpdatedDate)
                          .tz(tenantTimezone)
                          .format("YYYY-MM-DD hh:mm A") ?? "-"}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {p?.permission ?? "-"}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {new Intl.NumberFormat("en-US").format(p?.contactCount ?? 0)}
                      </td>
                      <td className="py-3 text-[13px] font-normal">
                        {new Intl.NumberFormat("en-US").format(p?.optOutCount ?? 0)}
                      </td>
                      <td className="py-3 text-[13px] font-normal flex">
                        <div>
                          <Dropdown
                            inline
                            arrowIcon={false}
                            inputMode
                            as="button"
                            className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                            label={<i className="fa-solid fa-ellipsis"></i>}
                          >
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedCategory({ ...p, isEdit: true });
                                setshowAddCategory(true);
                              }}
                            >
                              {t("Edit")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedCategory({ ...p });
                                setDeleteContact(true);
                              }}
                            >
                              {t("Delete")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedCategory({ ...p });
                                setViewContacts(true);
                              }}
                            >
                              {" "}
                              {t("View Contacts")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                downloadContactsByCategory([p.categoryName]);
                              }}
                            >
                              {t("Download Contacts")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                downloadOptOutContactsByCategory([
                                  p.categoryName,
                                ]);
                              }}
                            >
                              Download Opt-Out Contacts
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                setSelectedCategory({ ...p });
                                setUploadContacts(true);
                              }}
                            >
                              {t("Upload Contacts")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-theme"
                              onClick={() => {
                                navigate("/tenant/campaigns", {
                                  state: {
                                    isCreate: true,
                                    categoryNames: [p?.categoryName],
                                  },
                                });
                              }}
                            >
                              {t("Send Broadcast")}
                            </Dropdown.Item>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <>
              <div className="m-[4rem] text-center flex flex-col items-center gap-2">
                <img
                  src={`${process.env.PUBLIC_URL}/images/noList.png`}
                  alt="No List"
                  className="w-[486px] h-[278px] "
                />
                <p className="my-[24px] ">
                  {t("The space is empty . Add a Category to get started.")}
                </p>
                <div className="flex gap-2.5 justify-center text-base font-medium">
                  <div className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                    <div
                      className="flex gap-2"
                      onClick={() => setshowAddCategory(true)}
                    >
                      <div className="">
                        <span className="mr-2">
                          <i className="fa-solid fa-plus"></i>
                        </span>
                        {t("Add Category")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {totalPages > 1 && selectedCategories?.length > 0 && (
          <div className="flex sticky bottom-0 sm:justify-center w-full bg-white py-[10px] border-t-[1px] border-[#eeeeee] z-[10]">
            <div className="flex overflow-x-auto">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(event, page) => setCurrentPage(page)}
                shape="rounded"
                color="secondary"
              />
            </div>
          </div>
        )}
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {selectedCategory.isEdit ? t("Update Category") : t("Add Category")}
          </span>
        }
        isShowModal={showAddCategory}
        setIsShowModal={setshowAddCategory}
        ModalBody={
          <AddContactCategory
            setIsShow={setshowAddCategory}
            onAdd={Categorysearch}
            defaultCategory={selectedCategory}
            isEdit={selectedCategory.isEdit}
          />
        }
        size="2xl"
      />
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t("Are you sure you want to delete?")}
            </div>
          </>
        }
        isShowModal={deleteContact}
        setIsShowModal={setDeleteContact}
        ModalBody={
          <>
            <div className="flex justify-end gap-4 p-6">
              <button
                className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
                onClick={() => setDeleteContact(false)}
              >
                {t("Cancel")}
              </button>
              <button
                className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
                onClick={() => {
                  if (selectedCategories.length > 0) {
                    dispatch(
                      bulkCategoryDelete(
                        { ids: selectedCategories.map((m) => m.id) },
                        (a) => {
                          setDeleteContact(false);
                          Categorysearch();
                          setSelectedCategories([]);
                        }
                      )
                    );
                  } else {
                    dispatch(
                      categoryDelete(selectedCategory?.id, (a) => {
                        setDeleteContact(false);
                        Categorysearch();
                      })
                    );
                  }
                }}
              >
                {t("Delete")}
              </button>
            </div>
          </>
        }
        size="md"
      />
      <RightSidePanel
        ModalTitle={t("Upload Contacts")}
        setIsShow={setUploadContacts}
        isShow={uploadContacts}
        downloadFile="contact_template.xlsx"
        fileUrl="/files/contact_template.xlsx"
        Component={
          <div className="height30 flex flex-col justify-between pb-3">
            <ImportExcel
              Title="Upload Contacts"
              setIsShow={setUploadContacts}
              columnValidation={columnValidation}
              onImportClick={(con) => {
                con.map((m) => {
                  m.tenantId = globalReducer.loginInfo?.tenantId;
                  m.uid = globalReducer.loginInfo?.uid;
                  m.whatsappNumber = m.phone;
                  m.category = [selectedCategory.categoryName];
                });
                dispatch(
                  tenantContactBulkUpload(con, (a) => {
                    Categorysearch();
                    setUploadContacts(false);
                  })
                );
              }}
            />
          </div>
        }
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
      />
      <RightSidePanel
        ModalTitle={t("View Contacts")}
        setIsShow={setViewContacts}
        isShow={viewContacts}
        Component={
          <ViewContact
            setIsShow={setViewContacts}
            category={[selectedCategory?.categoryName]}
          />
        }
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
      />
    </div>
  );
}

export default ManageCategories;
