import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { getForgotLink } from "../store/actions/loginAction";
import { useTranslation } from "react-i18next";
import CustomCaptcha from "../components/CustomCaptcha";

function ForgotPassword({ setIsShow, organizationId, config }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [forgotUsername, setForgotUsername] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const captcha = useRef(null);

  const sendForgotLink = (e) => {
    e.preventDefault();
    if (!captcha.current.validate()) {
      return;
    }
    dispatch(
      getForgotLink(
        {
          address: forgotUsername,
          addressType: "USERNAME",
          tenantId: organizationId,
        },
        config?.client_id,
        (a) => {
          setLinkSent(true);
        }
      )
    );
  };

  return linkSent ? (
    <div className="py-4 px-6 text-center">
      <p className="text-[14px]">
        {t('We’ve sent a password reset link to your registered email address')}
      </p>
      <p className="text-[13px] text-gray-500 mt-1 text-center">
        {t(' Please check your inbox (and your spam/junk folder just in case) for the reset link. Click the link in the email to reset your password.')}
      </p>
      <p className="text-[13px] text-gray-500 mt-1 text-center">
        {t(' If you didn’t request a password reset, you can safely ignore this email.')}
      </p>
      <button
        type="button"
        onClick={() => setIsShow(false)}
        className="justify-center cursor-pointer items-center p-[12px] mt-5 mb-3 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
      >
        {t("OK")}
      </button>
    </div>
  ) : (
    <form className="py-4 px-6" onSubmit={sendForgotLink}>
      <label className="text-[14px]">{t("Enter Username")}</label>
      <input
        required
        onChange={(e) => setForgotUsername(e.target.value)}
        type="text"
        placeholder={t("Enter Username")}
        className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
      />
      <div className="mt-[10px]">
        <CustomCaptcha ref={captcha} />
      </div>
      <button
        type="submit"
        className="justify-center cursor-pointer items-center p-[12px] mt-5 mb-3 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
      >
        {t("Submit")}
      </button>
    </form>
  );
}

export default ForgotPassword;
