import React from "react";
import { msgStatusColor, msgStatusTextColor } from "../../utils/helper";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

function LiveChatAudienceDetails({ selectedWaba, setIsShow, conversation }) {
    const { t } = useTranslation();
    return (
        <div className="height30 flex flex-col justify-between pb-3">
            <div className="h-dvh overflow-y-auto p-4">
                <div className="grid grid-cols-2 gap-2 bg-gray-50 p-3 w-full text-[14px]">
                    <div className="text-gray-500">{t("Channel")}</div>
                    <div className="">
                        {`${selectedWaba?.teamName} +${selectedWaba?.phoneCode} ${selectedWaba?.phone}`}
                    </div>
                    <div className="text-gray-500">{t("Name")}</div>
                    <div className="">
                        {conversation?.contact?.name ?? "-"}
                    </div>
                    <div className="text-gray-500">{t("Phone")}</div>
                    <div className="">
                        +{conversation?.contact?.whatsappNumber ?? "-"}
                    </div>
                    <div className="text-gray-500">{t("Email")}</div>
                    <div className="">
                        {conversation?.contact?.email ?? "-"}
                    </div>
                    <div className="text-gray-500">{t("Last Message Text")}</div>
                    <div className="">{["image", "location", "reaction", "sticker", "audio", "video"].includes(conversation.lastMessage) ? "The customer sent a " + conversation.lastMessage : conversation.lastMessage}</div>
                    <div className="text-gray-500">{t("Last Message Received")}</div>
                    <div className="">{moment
                        .utc(conversation?.lastMessageReceived)
                        .tz(selectedWaba?.timezone)
                        .format("YYYY-MM-DD hh:mm A")}</div>
                    <div className="text-gray-500">{t("First Message Received")}</div>
                    <div className="">{moment
                        .utc(conversation?.firstMessageReceived)
                        .tz(selectedWaba?.timezone)
                        .format("YYYY-MM-DD hh:mm A")}</div>
                    <div className="text-gray-500">{t("Type")}</div>
                    <div className="">
                        {conversation?.messageType ?? "-"}
                    </div>
                    <div className="text-gray-500">{t("Business Hours")}</div>
                    <div className="">{conversation?.businessHours === true ? "TRUE" : "FALSE"}</div>
                </div>
            </div>
        </div>
    );
}

export default LiveChatAudienceDetails;
