import React, { useEffect, useState } from "react";
import { updateTeam } from "../../store/actions/tenantAction";
import { useDispatch, useSelector } from "react-redux";
import SelectCountry from "../../components/Dropdowns/SelectCountry";
import SelectTimezone from "../../components/Dropdowns/SelectTimezone";
import { saveSelectedWaba } from "../../store/actions/globalAction";
import { useTranslation } from "react-i18next";

function EditWaba({ waba, setIsShow, onSave }) {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [teamInfo, setTeamInfo] = useState({});
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    setTeamInfo({ ...waba });
  }, [waba]);

  const onSaveSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateTeam(teamInfo, (a) => {
        if (a && globalReducer.waba?.id == waba?.id) {
          dispatch(saveSelectedWaba(a));
        }
        onSave(a);
        setIsShow(false);
      })
    );
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setTeamInfo({ ...teamInfo, teamImage: fileReader.result });
      };
      fileReader.onerror = (error) => { };
    }
  };

  return (
    <form
      onSubmit={onSaveSubmit}
      className="height30 flex flex-col justify-between pb-3"
    >
      <div className="h-dvh overflow-y-auto p-4">
        <div className="grid grid-cols-12 gap-4 border p-3 rounded-lg mb-4">
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Team Name')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <input
              type="text"
              value={teamInfo?.teamName}
              required
              placeholder={t("Enter team name")}
              className="text-gray-500 py-[8px] px-[12px] text-[14px] h-10 font-normal border-gray-300 w-full rounded"
              onChange={(e) => {
                setTeamInfo({ ...teamInfo, teamName: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('Team Description')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <textarea
              required
              placeholder={t("Enter team description")}
              rows="3"
              value={teamInfo?.teamDescription}
              onChange={(e) =>
                setTeamInfo({ ...teamInfo, teamDescription: e.target.value })
              }
              className="border border-gray-300 rounded-md text-[13px] px-1 py-1 w-[100%]"
            />
          </div>
          {/* <div className="flex flex-col col-span-12">
            <div className="text-[#656F7D] text-[13px] font-normal mb-1">
              <label>{t('About')}<span className="text-red-500 text-[14px]">*</span></label>
            </div>
            <textarea
              required
              placeholder={t("Enter About")}
              rows="2"
              value={teamInfo?.about}
              onChange={(e) =>
                setTeamInfo({ ...teamInfo, about: e.target.value })
              }
              className="border border-gray-300 rounded-md text-[13px] px-1 py-1 w-[100%]"
            />
          </div> */}
          <div className="gap-4 border p-3 rounded-lg mt-3 col-span-12">
            <div className="flex flex-col col-span-12">
              <div className="text-[#656F7D] text-[13px] font-normal mb-1">
                <label>{t('Team Avatar')}<span className="text-red-500 text-[14px]">*</span></label>
              </div>
              {teamInfo?.teamImage ? (
                <div className="border-2 border-gray-300 border-dashed rounded-lg w-full max-h-[220px] relative">
                  <button
                    type="button"
                    className="absolute inline-block right-[10px] top-[10px]"
                    onClick={() => {
                      setTeamInfo({ ...teamInfo, teamImage: null });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-x w-3.5 h-3.5 "
                    >
                      <path d="M18 6 6 18" /> <path d="m6 6 12 12" />
                    </svg>
                  </button>
                  <img
                    src={teamInfo?.teamImage}
                    alt="Preview"
                    className="max-h-[220px] scale-75"
                    style={{ height: "auto", borderRadius: "10px" }}
                  />
                </div>
              ) : (
                <label
                  htmlFor="avatar-dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-45 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-5">
                    <svg
                      className="w-8 h-8 text-theme"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="hover:underline text-theme">
                        {t('Click to upload')}&nbsp;
                      </span>
                      {t('or drag and drop')}
                    </p>
                    <p className="text-xs text-gray-500">
                      {t('*Please Upload File in png, jpeg, jpg Format Only.')}
                    </p>
                    {/* {ReportData?.name ? <p className="text-xs text-gray-500">File Name : {ReportData?.name}</p> : ""} */}
                  </div>
                  <input
                    id="avatar-dropzone-file"
                    type="file"
                    className="hidden"
                    accept=".png,.jpeg,.jpg"
                    multiple={false}
                    onChange={handleImageChange}
                  />
                </label>
              )}
            </div>
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Country Name')}<span className="text-red-500 text-[14px]">*</span></label>
              <p className="text-[12px] text-gray-400">
                {t('Your e-commerce related data will have this country/currency by defalut.')}
              </p>
            </div>
            <SelectCountry
              required={true}
              selectedCode={teamInfo?.countryCode}
              countryList={globalReducer.country}
              onSelectCountry={(country) => {
                if (country) {
                  teamInfo.countryCode = country.code;
                } else {
                  delete teamInfo.countryCode;
                }
                setTeamInfo({ ...teamInfo });
              }}
              placeholder={t("Select Country")}
            />
          </div>
          <div className="flex flex-col col-span-12">
            <div className="text-midBlack text-[13px] font-normal mb-1">
              <label>{t('Timezone')}<span className="text-red-500 text-[14px]">*</span></label>
              <p className="text-[12px] text-gray-400">
                {t('Your e-commerce related data will have this timezone by defalut.')}
              </p>
            </div>
            <SelectTimezone
              required={true}
              TimezoneList={globalReducer.timezone}
              selectedZone={teamInfo?.timezone}
              onSelectTimezone={(zone) => {
                if (zone) {
                  teamInfo.timezone = zone.name;
                } else {
                  delete teamInfo.timezone;
                }
                setTeamInfo({ ...teamInfo });
              }}
            />
          </div>
        </div>
      </div>
      <div className="h-[10vh] px-4 bg-white border-t-[1px] border-grayish relative">
        <div className="flex justify-end gap-2 my-2">
          <button
            type="submit"
            className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </form>
  );
}

export default EditWaba;
