import { Dropdown } from "flowbite-react";
import React, { useEffect, useState } from "react";
import WhatsAppIntegration from "../../layouts/tenant/WhatsAppIntegration";
import PopUp from "../../components/PopUp";
import RightSidePanel from "../../components/RightSidePanel";
import AccountInfo from "../../layouts/tenant/AccountInfo";
import { allIntegrationList } from "../../utils/helper";
import WhatsAppMsgTemplates from "../../layouts/tenant/WhatsAppMsgTemplates";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTenantWabaStatus,
  searchTenantWaba,
} from "../../store/actions/tenantAction";
import ViewTenantRateCard from "../../layouts/tenant/ViewTenantRateCard";
import EditWaba from "../../layouts/tenant/EditWaba";
import { saveSelectedWaba } from "../../store/actions/globalAction";
import { useTranslation } from "react-i18next";

function Integration({ showSidebar }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const [IntegratedList, setIntegratedList] = useState([]);
  const [availableList, setAvailableList] = useState([]);
  const [showEditWaba, setShowEditWaba] = useState(false);
  const [showConnectWhatsApp, setshowConnectWhatsApp] = useState(false);
  const [showDisconnect, setShowDisconnect] = useState(false);
  const [showWhatsAppMsgTemplates, setShowWhatsAppMsgTemplates] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [showRateCard, setShowRateCard] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const tenantReducer = useSelector((state) => state.tenantReducer);
  const globalReducer = useSelector((state) => state.globalReducer);

  useEffect(() => {
    onSearch();
  }, []);

  const onClickAvailableIntegrations = () => {
    if (
      Array.isArray(tenantReducer.tenantDetails?.plan?.availableIntegrations)
    ) {
      setAvailableList(
        allIntegrationList.filter((f) =>
          tenantReducer.tenantDetails?.plan?.availableIntegrations?.some(
            (s) => s === f.type
          )
        )
      );
    }
    setShowIntegrations(true);
  };

  const onSearch = () => {
    dispatch(
      searchTenantWaba({ tenantId: globalReducer.loginInfo?.tenantId, orderBy: [{ property: "id", direction: "DESC" }] }, (a) => {
        if (a && Array.isArray(a.data)) {
          const wb = a.data?.find(s => s.id === globalReducer.waba?.id);
          if (wb) {
            dispatch(saveSelectedWaba(wb));
          } else {
            dispatch(saveSelectedWaba(a.data[0]));
          }
          setIntegratedList(
            a.data.map((m) => {
              if (["GRAPH_API", "PINNACLE"].includes(m.bsp?.integrationType)) {
                return {
                  ...m,
                  name: m.teamName ?? "WhatsApp Business",
                  title: `connected with +${m.phoneCode} ${m.phone}`,
                  iconPath: `${process.env.PUBLIC_URL}/images/WhatsApp-Business.png`,
                };
              } else {
                return {};
              }
            })
          );
        } else {
          setIntegratedList([]);
          dispatch(saveSelectedWaba(null));
          onClickAvailableIntegrations();
        }
      })
    );
  };

  return (
    <div className="h-screen">
      <div className={`fixed top-[30px] ${showSidebar ? "fullWidth" : "fullWidth1"} transition-all duration-200 ease-out z-30 bg-white mb-[0px]`}>
        <div className="flex gap-5 justify-between px-5 py-[8.5px] min-h-[53.8px] text-base text-center bg-white border-b-[1px] border-[#e9ebf0] border-solid max-md:flex-wrap">
          <div className="flex gap-2 items-center my-auto font-medium text-black whitespace-nowrap cursor-pointer">
            <i className="fa-regular text-[14px] fa-solid fa-link text-theme"></i>
            <div className="text-[14px] font-medium">{t('Integration Setup')}</div>
          </div>
          <div className="flex gap-2"></div>
        </div>
        <div className="bg-white text-black font-medium px-5 min-h-[50.4px] box-border border-b flex justify-between items-end">
          <div className="flex text-[14px] gap-4">
            <button
              className={
                " text-gray-500 px-1 pb-2 " +
                (showIntegrations
                  ? "border-b-2 border-transparent"
                  : " border-theme text-theme afterline border-b-[2px]")
              }
              onClick={(a) => setShowIntegrations(false)}
            >
              {t('Integrated')}
            </button>
            <button
              className={
                " text-gray-500 px-1 pb-2 " +
                (showIntegrations
                  ? " border-theme text-theme afterline border-b-[2px]"
                  : "border-b-2 border-transparent")
              }
              onClick={(a) => {
                onClickAvailableIntegrations();
              }}
            >
              {t('Available Integrations')}
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col h-full justify-between grow">
        <div className="px-5 py-2 mt-[140px] mb-[20px]">
          {showIntegrations ? (
            <div className="grid grid-cols-3 gap-4">
              {availableList.map((p, i) => (
                <div key={i} className="bg-[#FFFFFF] p-5 rounded-lg shadow-lg">
                  <div className="flex justify-between gap-2 text-[14px]">
                    <div className="min-w-[42px]">
                      <img
                        className="w-[37.5px] h-[37.5px] box-border"
                        src={p?.iconPath}
                      />
                    </div>
                    <div>
                      <div>{p.name}</div>
                      <div className="text-gray-500">{p.title}</div>
                    </div>
                    <div>
                      <Dropdown
                        inline
                        arrowIcon={false}
                        inputMode
                        as="button"
                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                        label={<i className="fa-solid fa-ellipsis"></i>}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setshowConnectWhatsApp(true);
                          }}
                        >
                          Connect
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}
              {availableList.length === 0 && (
                <div className="text-center col-span-3 mt-10 text-center text-[14px] text-gray-500">
                  {t('Please contact Infocomm for activating the Plan (+968 24151020 / 21)')}
                </div>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-3 gap-4">
              {IntegratedList.map((p, i) => (
                <div key={i} className="bg-[#FFFFFF] p-5 rounded-lg shadow-lg">
                  <div className="flex justify-between gap-2 text-[14px]">
                    <div className="flex gap-2">
                      <div className="min-w-[42px]">
                        <img
                          className="w-[37.5px] h-[37.5px] box-border"
                          src={p?.iconPath}
                        />
                      </div>
                      <div>
                        <div>{p.name}</div>
                        <div className="text-gray-500">{p.title}</div>
                      </div>
                    </div>
                    <div>
                      <Dropdown
                        inline
                        arrowIcon={false}
                        inputMode
                        as="button"
                        className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                        label={<i className="fa-solid fa-ellipsis"></i>}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedAccount(p);
                            setShowDisconnect(true);
                          }}
                          className="hover:text-theme"
                        >
                          <i className="fa-solid fa-link-slash pr-2"></i>
                          {t('Disconnect')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowEditWaba(true);
                            setSelectedAccount(p);
                          }}
                          className="hover:text-theme"
                        >
                          <i className="fa-regular fa-pen-to-square pr-2"></i>
                          {t('Edit')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedAccount(p);
                            setShowAccountInfo(true);
                          }}
                          className="hover:text-theme"
                        >
                          <i className="fa-solid fa-circle-info pr-2"></i>
                          {t('Account Info')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowWhatsAppMsgTemplates(true);
                          }}
                          className="hover:text-theme"
                        >
                          <i className="fa-solid fa-table-list pr-2"></i> {t('Manage Templates')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowRateCard(true);
                            setSelectedAccount(p);
                          }}
                          className="hover:text-theme"
                        >
                          <i className="fa-solid fa-table-list pr-2"></i> {t('View Conversation Rate Card')}
                        </Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black flex">
            <img
              className="w-[30px] h-[30px] box-border mr-2"
              src={selectedAccount?.iconPath}
            />
            {t('Account Info')}
          </span>
        }
        isShowModal={showAccountInfo}
        setIsShowModal={setShowAccountInfo}
        ModalBody={
          <AccountInfo
            setIsShow={setShowAccountInfo}
            account={selectedAccount}
          />
        }
        size="2xl"
      />
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showConnectWhatsApp}
        setIsShow={setshowConnectWhatsApp}
        ModalTitle={t("Integrate WhatsApp")}
        Component={
          <WhatsAppIntegration
            setIsShow={setshowConnectWhatsApp}
            onIntegrated={() => { onSearch(); setShowIntegrations(false); }}
          />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showWhatsAppMsgTemplates}
        setIsShow={setShowWhatsAppMsgTemplates}
        ModalTitle={t("WhatsApp Message Templates")}
        Component={
          <WhatsAppMsgTemplates
            isPopup={true}
            setIsShow={setShowWhatsAppMsgTemplates}
          />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showRateCard}
        setIsShow={setShowRateCard}
        ModalTitle={t("Conversation Rate Card")}
        Component={
          <ViewTenantRateCard
            setIsShow={setShowRateCard}
            waba={selectedAccount}
          />
        }
      />
      <RightSidePanel
        CustomClass="fixed md:w-[40%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={showEditWaba}
        setIsShow={setShowEditWaba}
        ModalTitle={t("Edit Waba")}
        Component={
          <EditWaba setIsShow={setShowEditWaba} waba={selectedAccount} onSave={onSearch} />
        }
      />
      <PopUp
        ModalHeader={
          <>
            <div className="font-medium text-[18px] text-black">
              {t('Are you want to Disconnect?')}
            </div>
            <div className="font-medium text-[14px] text-[#6E6C6D]">
              {t('Any unsaved changes will be lost.')}
            </div>
          </>
        }
        isShowModal={showDisconnect}
        setIsShowModal={setShowDisconnect}
        ModalBody={
          <div className="flex justify-end gap-4 p-6">
            <button
              className="px-[16px] py-[6px] text-[#644d81] font-medium text-[14px] bg-[#ECF0F7] transition-all ease-in-out rounded cursor-pointer hover:bg-[#e4e8ef]"
              onClick={() => setShowDisconnect(false)}
            >

              {t('Cancel')}
            </button>
            <button
              className="min-w-[76px] text-center px-[16px] py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]"
              onClick={() => {
                dispatch(
                  changeTenantWabaStatus(
                    {
                      id: selectedAccount.id,
                      tenantId: selectedAccount.tenantId,
                      isActive: false,
                    },
                    (a) => {
                      onSearch();
                      setShowDisconnect(false);
                    }
                  )
                );
              }}
            >
              {t('Disconnect')}
            </button>
          </div>
        }
        size="md"
      />
    </div>
  );
}

export default Integration;
