import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ROUTES from "../utils/routesConstants";
import {
  getUUid,
  sendOTP,
  verifyUser,
  generateToken,
} from "../store/actions/loginAction";
import { fetchIP } from "../store/actions/globalAction";
import { getInfo, IS_LOADING, SHOW_SNACKBAR } from "../utils/types";
import Password from "../components/Password";
import PopUp from "../components/PopUp";
import ForgotPassword from "../layouts/ForgotPassword";
import { switchLanguage } from "../store/reducers/languageReducer";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
import ForgotUsername from "../layouts/ForgotUsername";
import i18n from "../i18n";
import { systemLanguages, validatePassword } from "../utils/helper";
import CustomCaptcha from "../components/CustomCaptcha";
import UnblockAccount from "../layouts/UnblockAccount";

function Login() {
  const dispatch = useDispatch();
  const authInfo = getInfo();
  const [uuid, setUuid] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [verifyType, setVerifyType] = useState("USERNAME");
  const [formData, setFormData] = useState({});
  const [passwordError, setPasswordError] = useState();
  const [isShowPassword, setIsShowPassword] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [forgotUsername, setForgotUsername] = useState(false);
  const [showUnblockAccount, setShowUnblockAccount] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const [tenantId, setTenantId] = useState();
  const [organizationId, setOrganizationId] = useState("partner");
  const [config, setConfig] = useState({});
  const { clientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const language = useSelector((state) => state.languageReducer.currentLanguage);
  const captcha = useRef(null);

  const authorizeRoute = () => {
    if (
      !(
        authInfo &&
        authInfo.roles &&
        Array.isArray(authInfo.roles) &&
        authInfo.roles?.length > 0
      )
    ) {
      return false;
    }
    if (!authInfo.partnerId && !authInfo.tenantId) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchIP().then((tmp) => setIpAddress(tmp?.ip));
    if (authorizeRoute()) {
      if (authInfo.roles.some((s) => s === "GLOBAL_ADMIN")) {
        navigate(ROUTES.GLOBAL_ADMIN);
      } else if (authInfo.roles.some((s) => s === "SUPER_ADMIN")) {
        navigate(ROUTES.PARTNER);
      } else {
        navigate(ROUTES.TENANT);
      }
    } else {
      setConfig(JSON.parse(process.env.REACT_APP_PARTNER_PORTAL_CONFIG));
      // setOrganizationId(clientId);
      // if (clientId?.toLowerCase() === "partner") {
      //   setConfig(JSON.parse(process.env.REACT_APP_PARTNER_PORTAL_CONFIG));
      // } else if (clientId) {
      //   setConfig(JSON.parse(process.env.REACT_APP_TENANT_PORTAL_CONFIG));
      //   setTenantId(clientId.toUpperCase());
      // }
    }
  }, []);

  const handleLanguageChange = (value) => {
    dispatch(switchLanguage(value.key));
    i18n.changeLanguage(value.key);
    document.body.dir = value.direction;
  };

  const passwordMessage = (password) => {
    //setPasswordError(validatePassword(password, null));
  };

  const gotoSignUp = () => {
    navigate(ROUTES.SIGNUP);
  };

  const sendOtp = () => {
    dispatch(
      getUUid(config, (d) => {
        setUuid(d);
        if (verifyType !== "EMAIL" && formData?.mobile?.length === 10) {
          dispatch(
            sendOTP(
              {
                tenantId: tenantId,
                address: formData?.mobile,
                addressType: verifyType,
              },
              { uuid: d },
              (d) => {
                setIsOTP(d);
              }
            )
          );
        } else if (verifyType === "EMAIL") {
          dispatch(
            sendOTP(
              {
                tenantId: tenantId,
                address: formData?.email,
                addressType: verifyType,
              },
              { uuid: d },
              (d) => {
                setIsOTP(d);
              }
            )
          );
        } else {
          dispatch({
            type: SHOW_SNACKBAR,
            payload: {
              type: "error",
              message: "Please Enter a valid mobile number.",
              open: true,
            },
          });
        }
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!captcha.current.validate()) {
      return;
    }
    dispatch({
      type: IS_LOADING,
      payload: { type: IS_LOADING, payload: true },
    });
    let cong;
    let tntId;
    if (
      organizationId?.toLowerCase() === "partner" ||
      organizationId?.toLowerCase() === "owner"
    ) {
      cong = JSON.parse(process.env.REACT_APP_PARTNER_PORTAL_CONFIG);
      setConfig(cong);
    } else if (organizationId) {
      cong = JSON.parse(process.env.REACT_APP_TENANT_PORTAL_CONFIG);
      setConfig(cong);
      tntId = organizationId.toUpperCase();
      setTenantId(tntId);
    }
    if (!isOTP) {
      dispatch(
        getUUid(cong, (d) => {
          setUuid(d);
          dispatch(
            verifyUser(
              {
                tenantId: tntId?.trim(),
                address: formData?.userName?.trim(),
                secret: formData?.password,
                addressType: verifyType,
              },
              { uuid: d },
              "PWD",
              (code) => {
                if (code === true || code === "true") {
                  setIsOTP(true);
                  dispatch({
                    type: IS_LOADING,
                    payload: { type: IS_LOADING, payload: false },
                  });
                } else {
                  getLoginToken(code, tntId, cong);
                }
              }
            )
          );
        })
      );
    } else {
      const verifyParams = { uuid: uuid };
      dispatch(
        verifyUser(
          {
            tenantId: tntId,
            address: "",
            secret: formData?.otp,
            addressType: "PHONE",
          },
          verifyParams,
          "OTP",
          (code) => getLoginToken(code, tntId, cong)
        )
      );
    }
  };

  const getLoginToken = (code, tntId, conf) => {
    const tokenParams = {
      ...conf,
      code: code,
      tenantId: tntId,
      client_ip: ipAddress,
    };
    dispatch(
      generateToken({}, tokenParams, (d) => {
        localStorage.setItem("sessionToken", d?.access_token);
        localStorage.setItem("refreshToken", d?.refresh_token);

        const [header, payload, signature] = d?.access_token?.split(".");
        const decodedPayload = JSON.parse(atob(payload));
        const InfoParse = JSON.parse(atob(decodedPayload?.info));
        if (InfoParse && InfoParse.roles && Array.isArray(InfoParse.roles)) {
          if (InfoParse.roles.some((s) => s === "GLOBAL_ADMIN")) {
            window.location.replace(
              conf.redirect_uri.replace("partner", "admin")
            );
          } else {
            window.location.replace(conf.redirect_uri.replace(/\/[^/]*$/, "/" + InfoParse.userType?.toLowerCase()));
          }
        }
      })
    );
  };

  return (
    <>
      <div className="h-screen flex overflow-hidden bg-white items-center">
        <div className="h-full w-full flex flex-col justify-center items-center py-[20px] px-[15%]">
          <div className="absolute justify-center top-10 left-[47%] text-[18px] cursor-pointer">
            <div className="flex gap-2"> Language
              <Dropdown
                arrowIcon={false}
                inline
                size="sm"
                label={
                  language === "en" ? (
                    <span className="px-2 bg-white rounded-md ring-2 p-0.5 text-[15px] ring-gray-300">
                      English
                    </span>
                  ) : language === "ar" ? (
                    <span className="px-2 bg-white rounded-md ring-2 p-0.5 text-[15px] ring-gray-300">
                      Arabic
                    </span>
                  ) : (
                    <i className="fa-solid fa-language rounded-md px-3 bg-white ring-2 p-2 text-[32px] ring-gray-300"></i>
                  )
                }
              >
                {systemLanguages.map((lang, index) =>
                  <Dropdown.Item key={index}
                    className="py-1 text-[16px]"
                    onClick={() => handleLanguageChange(lang)}
                  >
                    {lang?.value}
                  </Dropdown.Item>)}
              </Dropdown>
            </div>
          </div>

          <img
            alt=""
            loading="lazy"
            src="/iomnihub-logo.svg"
            className="text-center w-40 max-w-full aspect-[3.85] max-md:mt-10"
          />
          <div className="justify-center mt-[36px] mb-[12px] items-center text-[30px] font-[600] text-center text-black max-md:px-5 max-md:max-w-full tracking-[-0.02em] leading-[28px]">
            {t("Welcome Back")}
          </div>
          <p className="text-[#5B5772] mb-[28px]">
            {t("Login into your account to start your journey.")}
          </p>

          <form onSubmit={onSubmit} action="POST" className="w-full">
            <div className="flex flex-col gap-2">
              {verifyType === "EMAIL" && (
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                      {t("Email")}
                    </div>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, email: e.target.value })
                      }
                      type="Email"
                      id="email"
                      placeholder="Email"
                      className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                      autoComplete="NA"
                    />
                  </div>
                  {isOTP ? (
                    <button
                      type="submit"
                      className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      onClick={sendOtp}
                    >
                      Generate OTP
                    </button>
                  ) : (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em] mt-[20px]">
                          {t("OTP")}
                        </div>
                        <div className="relative hidden-field mt-2">
                          <div className="absolute inset-y-0 right-0 flex items-center justify-center px-2">
                            {isShowPassword ? (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOff.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() => setIsShowPassword(!isShowPassword)}
                              />
                            ) : (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOn.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() => setIsShowPassword(!isShowPassword)}
                              />
                            )}
                          </div>

                          <input
                            className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                            id="password"
                            placeholder="•••• "
                            //   value={OTP}
                            type={isShowPassword ? "password" : "number"}
                            data-maxlength={4}
                            onChange={(e) =>
                              setFormData({ ...formData, otp: e.target.value })
                            }
                            autoComplete="off"
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.dataset.maxlength
                              );
                            }}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="justify-center cursor-pointer items-center p-[12px]  mt-9 text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full"
                      >
                        {t("Submit")}
                      </button>
                    </>
                  )}
                  <div className="mt-6 text-center text-gray-500">Or</div>
                  <div
                    className="flex justify-center cursor-pointer items-center px-16 py-3 mt-6 text-base leading-6 text-violet-600 bg-white rounded border border-violet-600 border-solid max-md:px-5"
                    onClick={() =>
                      setVerifyType(verifyType === "PHONE" ? "EMAIL" : "PHONE")
                    }
                  >
                    <div className="flex gap-2.5 justify-center">
                      <img
                        alt=""
                        loading="lazy"
                        src={`${process.env.PUBLIC_URL}/images/cusemail.svg`}
                        className="shrink-0 w-5 aspect-square"
                      />
                      <div>{t("Login with Mobile")}</div>
                    </div>
                  </div>
                </div>
              )}
              {verifyType === "PHONE" && (
                <div>
                  <div className="flex flex-col gap-2">
                    <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                      {t("Phone Number")}
                    </div>
                    <input
                      onChange={(e) =>
                        setFormData({ ...formData, mobile: e.target.value })
                      }
                      type="phone"
                      id="email"
                      data-maxlength={10}
                      placeholder={t("Mobile")}
                      className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                      autoComplete="NA"
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.dataset.maxlength
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {verifyType === "USERNAME" && (
                <div>
                  <div className="flex flex-col gap-2">
                    {/* <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                    Organization ID
                    <span className="text-red-500 text-[14px]">*</span>
                  </div>
                  <input
                    value={organizationId}
                    required
                    onChange={(e) => setOrganizationId(e.target.value)}
                    type="text"
                    placeholder="Organization ID *"
                    id="OrganizationID"
                    name="OrganizationID"
                    className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                    autoComplete="NA"
                  /> */}
                    <div className="flex justify-between">
                      <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                        {t("Username")}<span className="text-red-500 text-[14px]">*</span>
                      </div>
                      <label
                        className="text-[#2467DE] text-[12px] font-[500] leading-[16px] tracking-[-0.02em] cursor-pointer"
                        onClick={() => setForgotUsername(true)}
                      >
                        {t("Forgot Username?")}
                      </label>
                    </div>
                    <input
                      required
                      onChange={(e) =>
                        setFormData({ ...formData, userName: e.target.value })
                      }
                      type="text"
                      placeholder={`${t("Username")} *`}
                      className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                      autoComplete="NA"
                    />
                    <div className="flex justify-between">
                      <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em]">
                        {t("Password")}<span className="text-red-500 text-[14px]">*</span>
                      </div>
                      <label
                        className="text-[#2467DE] text-[12px] font-[500] leading-[16px] tracking-[-0.02em] cursor-pointer"
                        onClick={() => setForgotPassword(true)}
                      >
                        {t("Forgot Password?")}
                      </label>
                    </div>
                    <Password isError={passwordError ? true : false}
                      size="medium" placeholder={"Enter Password"}
                      onChange={(a) => {
                        passwordMessage(a);
                        setFormData({ ...formData, password: a });
                      }}
                      required={true}
                    />
                    <div className="col-span-12 h-1 mt-[-10px]">
                      {passwordError && (
                        <label className="text-[12px] text-[#f10808]">
                          {passwordError}
                        </label>
                      )}
                    </div>
                    <div className="mt-[10px]">
                      <CustomCaptcha ref={captcha} />
                    </div>
                  </div>
                  {isOTP && (
                    <>
                      <div className="flex flex-col gap-2">
                        <div className="text-[12px] font-[500] leading-[16px] text-label tracking-[-0.02em] mt-[20px]">
                          {t('OTP')}
                        </div>
                        <div className="relative hidden-field">
                          <div className="absolute inset-y-0 right-0 flex items-center justify-center px-2">
                            {isShowPassword ? (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOff.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() => setIsShowPassword(!isShowPassword)}
                              />
                            ) : (
                              <img
                                alt=""
                                loading="lazy"
                                src={`${process.env.PUBLIC_URL}/images/eyeOn.svg`}
                                className="w-4 aspect-square cursor-pointer"
                                onClick={() => setIsShowPassword(!isShowPassword)}
                              />
                            )}
                          </div>

                          <input
                            className="text-[14px] h-[42px] min-h-[42px] w-full justify-center p-[10px] font-[500] bg-[#F7F8F9] rounded-[8px] shadow-input outline-none border-none text-black tracking-[-0.02em] placeholder:text-[#9FA1A7] focus:bg-white"
                            id="password"
                            placeholder="•••••• "
                            //   value={OTP}
                            type={isShowPassword ? "password" : "number"}
                            data-maxlength={6}
                            onChange={(e) =>
                              setFormData({ ...formData, otp: e.target.value })
                            }
                            autoComplete="off"
                            onInput={(e) => {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.dataset.maxlength
                              );
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="justify-end text-end mt-5">
                    <label className="text-[#2467DE] text-[12px] font-[500] mb-2 leading-[16px] cursor-pointer"
                      onClick={() => setShowUnblockAccount(true)}>
                      {t("Unblock Account?")}
                    </label>
                    <button type="submit" className="justify-center cursor-pointer items-center p-[12px] text-[14px] font-[600] leading-[20px] text-center text-white whitespace-nowrap bg-theme rounded-[10px] max-md:px-5 w-full">
                      {t("Submit")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </form>

          {/* <div className="text-[#5B5772] my-[13px]">{t("OR")}</div>
          <div className="text-[#5B5772] ">
            {t(" Already have an account")}{" "}
            <strong className="text-theme tracking-[-0.02em] cursor-pointer" onClick={gotoSignUp}>{t("Sign up")}</strong>
          </div> */}

          <div className="w-full flex  flex-col justify-center items-center mt-[30px]">
            <a href="https://www.i-grp.com/" target="blank">
              <img
                src={`${process.env.PUBLIC_URL}/images/Infocomm-Logo-on-Powered.svg`}
                width="128"
                height="128"
                alt="hello"
              />
            </a>
          </div>
        </div>
        <div className="h-full w-full bg-[#FFE7E7] flex items-center justify-center">
          <img src="/images/login-imgg.svg" className="h-[500px]" alt="" />
        </div>
      </div>
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {"Forgot Password"}
          </span>
        }
        isShowModal={forgotPassword}
        setIsShowModal={setForgotPassword}
        ModalBody={<ForgotPassword setIsShow={setForgotPassword} organizationId={organizationId} config={config} />}
        size="md" />
      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {"Forgot Username"}
          </span>
        }
        isShowModal={forgotUsername}
        setIsShowModal={setForgotUsername}
        ModalBody={<ForgotUsername setIsShow={setForgotUsername} organizationId={organizationId} config={config} />}
        size="lg" />

      <PopUp
        ModalHeader={
          <span className="font-medium text-[18px] text-black">
            {"Unblock Account"}
          </span>
        }
        isShowModal={showUnblockAccount}
        setIsShowModal={setShowUnblockAccount}
        ModalBody={<UnblockAccount setIsShow={setShowUnblockAccount} organizationId={organizationId} config={config} />}
        size="lg" />
    </>
  );
}

export default Login;
