import React, { useState, useRef, useEffect } from "react";
import RecordRTC from "recordrtc";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from '@ffmpeg/util';

const AudioRecorder = ({ onStop }) => {
    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const [timer, setTimer] = useState(0);
    const mediaRecorder = useRef(null);
    const timerRef = useRef(null);

    useEffect(() => {
        if (recording) {
            timerRef.current = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
        }
        return () => clearInterval(timerRef.current);
    }, [recording]);

    const convertToMp3 = async (oggBlob) => {
        const ffmpeg = new FFmpeg();
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
        const inputName = "input.ogg";
        const outputName = "output.mp3";
        await ffmpeg.writeFile(inputName, await fetchFile(oggBlob));
        await ffmpeg.exec(["-i", inputName, "-b:a", "192k", outputName]);
        const data = await ffmpeg.readFile(outputName);
        return new Blob([data], { type: "audio/mp3" });
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new RecordRTC(stream, {
                type: "audio",
                mimeType: "audio/ogg",
                recorderType: RecordRTC.MediaStreamRecorder,
                sampleRate: 44100,
                audioBitsPerSecond: 192000,
            });
            mediaRecorder.current.startRecording();
            setRecording(true);
            setTimer(0);
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stopRecording(async () => {
                const audioBlob = mediaRecorder.current.getBlob();
                const mp3Blob = await convertToMp3(audioBlob);
                const mp3URL = URL.createObjectURL(mp3Blob);
                setAudioURL(mp3URL);
                onStop({ url: mp3URL, audioBlob: mp3Blob });
            });
            setRecording(false);
        }
    };

    return (
        <div className="p-4 flex flex-col items-center space-y-4">
            <button onClick={recording ? stopRecording : startRecording} className="px-4 py-2 bg-blue-500 text-white rounded">
                {recording ? "Stop Recording" : "Start Recording"}
            </button>
            {recording && <p className="text-lg font-semibold">Recording: {timer} sec</p>}
            {audioURL && (
                <audio controls>
                    <source src={audioURL} type="audio/mp3" />
                    Your browser does not support the audio tag.
                </audio>
            )}
        </div>
    );
};

export default AudioRecorder;
