import React, { useEffect, useState } from "react";
import { getFile } from "../store/actions/globalAction";
import { useDispatch } from "react-redux";
import { IS_LOADING } from "../utils/types";
import { useTranslation } from "react-i18next";

function ViewFile({
  setIsShow,
  fileName,
  selectedFileUrl,
  showDownloadClosebtn = true,
  className = "rounded float-start max-h-[60vh]",
}) {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const [extension, setExtension] = useState();
  const [previewfile, setPreviewfile] = useState();

  useEffect(() => {
    if (selectedFileUrl && selectedFileUrl != "") {
      dispatch({
        type: IS_LOADING,
        payload: { type: IS_LOADING, payload: true },
      });
      setExtension(selectedFileUrl.split(".").pop());
      //if (selectedFileUrl?.toLowerCase()?.includes("png") || selectedFileUrl?.toLowerCase()?.includes("jpeg"))
      getFile(selectedFileUrl).then((sfl) => {
        setPreviewfile(sfl);
      });
      dispatch({
        type: IS_LOADING,
        payload: { type: IS_LOADING, payload: false },
      });
    }
  }, [selectedFileUrl]);

  const getViewer = () => {
    if (previewfile) {
      switch (extension) {
        case "png":
        case "jpeg":
        case "svg":
          return (
            <img
              src={previewfile}
              className={`w-full rounded-t-lg border-none object-contain ${className}`}
            />
          );
        case "pdf":
        case "doc":
        case "docx":
          return (
            <iframe
              src={previewfile}
              frameBorder="0"
              width="100%"
              height="100%"
              className={className}
            ></iframe>
          );
        default:
          return (
            <img
              src={previewfile}
              className={`w-full rounded-t-lg border-none object-contain ${className}`}
            />
          );
      }
    }
  };

  return (
    <div className="h-full flex flex-col py-3 px-1 justify-between">
      <div className="h-full py-1">{getViewer()}</div>
      {showDownloadClosebtn ? (
        <div className="justify-end flex gap-2 px-1">
          <a
            href="#"
            className="justify-center items-center min-w-[100px] flex px-2 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
            onClick={() => setIsShow(false)}
          >
            {t('Close')}
          </a>
          <a
            download={fileName ? fileName + "." + extension : previewfile}
            href={previewfile}
            className="justify-center items-center min-w-[100px] flex px-2 h-10 py-1 font-semibold text-[14px] text-white bg-theme rounded cursor-pointer"
          >
            {t('Download')}
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ViewFile;
