import React from "react";
import {
  FiUsers,
  FiPackage,
  FiBriefcase,
  FiMessageSquare,
  FiCreditCard,
  FiBarChart,
  FiGlobe,
  FiSettings,
} from "react-icons/fi";
import { useTranslation } from "react-i18next";

export const usePartnerSidebar = () => {
  const { t } = useTranslation();
  return {
    SidebarTitle: [
      {
        active: true,
        role: "GLOBAL_ADMIN",
        tabName: [
          {
            title: t("Manage Partner"),
            link: "/admin/manage-partner",
            icon: <i className="fa fa-handshake-o mr-2"></i>,
            active: true,
          },
          {
            title: t("Manage Master Data"),
            link: "",
            icon: <FiPackage className="mr-2" />,
            active: false,
            subtab: [
              {
                title: t("Country"),
                link: "/admin/country",
                icon: <FiGlobe className="mr-2" />,
                active: false,
              },
              {
                title: t("Message Type"),
                link: "/admin/message-type",
                icon: <FiMessageSquare className="mr-2" />,
                active: false,
              },
              {
                title: t("BSP"),
                link: "/admin/bsp",
                icon: <i className="fa-solid fa-briefcase"></i>,
                active: false,
              },
              {
                title: t("Conversation Rate Card"),
                link: "/partner/conversation-rate-card",
                icon: <FiCreditCard className="mr-2" />,
                active: false,
              },
            ],
          },
          {
            title: t("Manage Users"),
            link: "/admin/manage-users",
            icon: <FiUsers className="mr-2" />,
            active: false,
          },
        ],
      },
      {
        value: "",
        active: true,
        role: "SUPER_ADMIN",
        tabName: [
          {
            title: t("Dashboard (Home)"),
            link: "/partner/dashboard",
            icon: <FiBarChart className="mr-2" />,
            active: true,
          },
          {
            title: t("Manage Company"),
            link: "/partner/company-management",
            icon: <i className="fa-solid fa-landmark mr-2"></i>,
            active: false,
          },
          {
            title: t("Manage WABA"),
            link: "/partner/manage-waba",
            icon: <i className="fa-regular mr-2 fa fa-handshake-o"></i>,
            active: false,
          },
          {
            title: t("Configurations"),
            link: "",
            icon: <FiPackage className="mr-2" />,
            active: false,
            subtab: [
              {
                title: t("Manage Plans"),
                link: "/partner/plans",
                icon: <FiBriefcase className="mr-2" />,
                active: false,
              },
              {
                title: t("Manage Message Rate Card "),
                link: "/partner/conversation-rate-card",
                icon: <FiCreditCard className="mr-2" />,
                active: false,
              },
              {
                title: t("Bank Details"),
                link: "/partner/bank-betails",
                icon: <i className="fa-solid fa-building-columns"></i>,
                active: false,
              },
            ],
          },
          {
            title: t("Manage Top up / Recharge"),
            link: "/partner/tenant-wallet-topup",
            icon: <i className="fa-solid fa-bolt"></i>,
            active: false,
            // subtab: [
            //   {
            //     title: t("Company Wallet"),
            //     link: "/partner/tenant-wallet-topup",
            //     icon: <i className="fa-solid fa-bolt"></i>,
            //     active: false,
            //   },
            //   {
            //     title: t("Transaction History"),
            //     link: "/partner/tenant-transaction-history",
            //     icon: <i className="fa-solid fa-book"></i>,
            //     active: false,
            //   },
            // ],
          },
          {
            title: t("Manage Transfers"),
            link: "/partner/tenant-wallet-transfers",
            icon: <i className="fa-solid mr-2 fa-money-bill-transfer"></i>,
            active: false,
          },
          {
            title: t("Manage Notifications"),
            link: "/partner/manage-notifications",
            icon: <i className="fa-solid mr-1 fa-bell"></i>,
            active: false,
          },
          {
            permission: "SETTINGS",
            title: t("Settings"),
            link: "",
            icon: <FiSettings className="mr-2" />,
            active: false,
            subtab: [
              {
                title: t("Account Setting"),
                link: "/admin/setting",
                icon: <FiSettings />,
                active: false,
              },
              {
                title: t("User Setting"),
                link: "/admin/user-setting",
                icon: <i className="fa-solid fa-user-shield"></i>,
                active: false,
              },
            ],
          },
          {
            permission: "TEAM_SETTINGS",
            title: t("Team Settings"),
            link: "",
            icon: <i className="fa-solid fa-gears mr-2"></i>,
            active: false,
            subtab: [
              {
                title: t("Role Management"),
                link: "/admin/role-management",
                icon: <i className="fa-solid fa-person-circle-question mr-2"></i>,
                active: false,
              },
              {
                title: t("User Management"),
                link: "/admin/manage-users",
                icon: <FiUsers className="mr-2" />,
                active: false,
              },
            ],
          },
          {
            title: t("Reports"),
            link: "",
            icon: <i className="fa-solid fa-file-export mr-1"></i>,
            active: false,
            subtab: [
              {
                title: t("Online Transactions"),
                link: "/partner/tenant-transaction-history",
                icon: <i className="fa-solid fa-book"></i>,
                active: false,
              },
              // {
              //   title: t("Pending Messages"),
              //   link: "/admin/manage-users",
              //   icon: <FiUsers className="mr-2" />,
              //   active: false,
              // },
            ],
          },
        ],
      },
    ],
  };
};
