import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeTenantWabaStatus,
  getTenantWaba,
  upsertTenantWaba,
} from "../../store/actions/tenantAction";
import { Dropdown } from "flowbite-react";
import RightSidePanel from "../../components/RightSidePanel";
import MapTenantRateCard from "../partner/MapTenantRateCard";
import { useTranslation } from "react-i18next";

function ViewWABAAccount({ setIsShow, tenant }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [WABAAccounts, setWABAAccounts] = useState([]);
  const [waba, setWaba] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [viewMapRateCard, setViewMapRateCard] = useState(false);

  useEffect(() => {
    onSearch();
  }, []);

  const onSearch = () => {
    dispatch(
      getTenantWaba(
        {
          tenantId: tenant.id,
        },
        (a) => {
          setWABAAccounts(a?.data);
        }
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    waba.tenantId = tenant.id;
    dispatch(
      upsertTenantWaba(waba, (a) => {
        setWaba({ ...waba, wabaNumber: "", bspId: "", id: null, active: null });
        setIsEdit(false);
        onSearch();
      })
    );
  };
  const OnInput = (e) => {
    e.target.value = e.target.value.slice(0, e.target.dataset.maxlength);
  };
  return (
    <div className="height30 flex flex-col justify-between pb-3">
      {/* <form className="px-6 pb-6 pt-3 bg-white max-h-[550px] overflow-auto" onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-3 w-full mb-1">
                    <div className="col-span-5">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>Mobile Number</label>
                        </div>
                        <input type="number" placeholder="Enter Mobile Number" value={waba?.wabaNumber} data-maxlength={10} onInput={OnInput}
                            onChange={(e) => setWaba({ ...waba, wabaNumber: e.target.value })} className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]" required />
                    </div>
                    <div className="col-span-5">
                        <div className="text-midBlack text-[13px] font-normal mb-1">
                            <label>BSP</label>
                        </div>
                        <input type="text" placeholder="Enter BSP" value={waba?.bspId}
                            onChange={(e) => setWaba({ ...waba, bspId: e.target.value })} className="border border-gray-300 rounded-md py-1 text-[14px] w-[100%]" required />
                    </div>
                    <div className="col-span-2 mt-6">
                        <button type='submit' className="min-w-[76px] text-center px-[16px] h-10 py-[6px] text-white font-medium text-[14px] bg-theme transition-all ease-in-out rounded cursor-pointer hover:bg-[#D82627]">
                            {isEdit ? "Update" : "Add"}
                        </button>
                    </div>
                </div>
            </form> */}
      <div className="h-dvh overflow-y-auto p-4">
        <table className="text-sm text-left rtl:text-right text-midBlack w-full overflow-x-auto">
          <thead className="text-[14px] text-midBlack bg-gray-200">
            <tr>
              <th scope="col" className="font-[500] py-3 pl-2">
                <div className="flex gap-2">
                  <div>{t("WABA Phone Number")}</div>
                </div>
              </th>
              <th scope="col" className="font-[500]">
                <div className="flex gap-2">
                  <div> {t("WABA ID")}</div>
                </div>
              </th>
              <th scope="col" className="font-[500] w-[160px]">
                <div className="flex gap-2">
                  <div> {t("BSP")}</div>
                </div>
              </th>
              <th scope="col" className="font-[500] w-[160px]">
                <div className="flex gap-2">
                  <div> {t("Status")}</div>
                </div>
              </th>
              <th scope="col" className="font-[500]">
                <div className="flex gap-2">
                  <div>{t("Action")}</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {WABAAccounts?.map((wa, j) => {
              return (
                <tr key={j} className="bg-white border-b">
                  <td className={"py-3 text-[13px] pl-2 font-normal"}>
                    +{wa?.phoneCode} {wa?.phone ?? "---"}
                  </td>
                  <td className={"py-3 text-[13px] pl-2 font-normal"}>
                    {wa?.wabaNumber ?? "-"}
                  </td>
                  <td className={"py-3 text-[13px] font-normal"}>
                    {wa?.bspName ?? "-"}
                  </td>
                  <td className={"py-3 text-[13px] font-normal"}>
                    {wa?.active ? (
                      <label className="bg-[#D4F8D3] px-[16px] pt-[4px] pb-[5px] rounded-full">
                        Active
                      </label>
                    ) : (
                      <label className="bg-[#F8F4D3] px-[16px] pt-[4px] pb-[6px] rounded-full">
                        Deactivate
                      </label>
                    )}
                  </td>
                  <td className=" py-3 font-normal text-[13px]">
                    <Dropdown
                      inline
                      arrowIcon={false}
                      inputMode
                      as="button"
                      className="font-medium text-[14px] text-gray-600 whitespace-nowrap hover:text-[#7A14FF] cursor-pointer bg-white"
                      label={<i className="fa-solid fa-ellipsis"></i>}
                    >
                      {wa?.active ? (
                        <Dropdown.Item
                          onClick={() => {
                            dispatch(
                              changeTenantWabaStatus(
                                {
                                  id: wa.id,
                                  tenantId: tenant.id,
                                  isActive: false,
                                },
                                onSearch
                              )
                            );
                          }}
                        >
                          {t("Deactivate")}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => {
                            dispatch(
                              changeTenantWabaStatus(
                                {
                                  id: wa?.id,
                                  tenantId: tenant.id,
                                  isActive: true,
                                },
                                onSearch
                              )
                            );
                          }}
                        >
                          {t("Activate")}
                        </Dropdown.Item>
                      )}
                      {/* <Dropdown.Item onClick={() => { setIsEdit(true); setWaba(wa) }}>
                                        Edit
                                    </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => {
                          setWaba(wa);
                          setViewMapRateCard(true);
                        }}
                      >
                        {t("Map Rate Card")}
                      </Dropdown.Item>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <RightSidePanel
        CustomClass="fixed md:w-[50%] border-l-[1px] top-[30px] right-0 z-[40] height30 transition-transform bg-white"
        isShow={viewMapRateCard}
        setIsShow={setViewMapRateCard}
        ModalTitle="Map Rate Card"
        Component={
          <MapTenantRateCard
            setIsShow={setViewMapRateCard}
            tenant={tenant}
            waba={waba}
            onAdd={() => { }}
          />
        }
      />
    </div>
  );
}

export default ViewWABAAccount;
