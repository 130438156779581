import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { excelUploader } from "../../store/actions/globalAction";
import * as XLSX from "xlsx";

function SelectAudiences({ bodyVariables = [], header = [], onUpload }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ExcelRows, setExcelRows] = useState([]);
    const [ExcelColumns, setExcelColumns] = useState([]);

    useEffect(() => {
        const arr = ["phone"];
        if (header && header?.format === "TEXT") {
            header?.headerVariables?.map((v, i) => arr.push("header" + (i + 1)));
        }
        if (bodyVariables?.length > 0) {
            bodyVariables?.map((v, i) => arr.push("body" + (i + 1)));
        }
        setExcelColumns([...arr]);
    }, [bodyVariables?.length, header?.length]);

    const DownloadSampleFormat = () => {
        const data = ExcelColumns?.map(m => { return { [m]: "" } });
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data ?? []);
        XLSX.utils.book_append_sheet(workbook, worksheet, "contacts");
        XLSX.writeFile(workbook, 'contacts.xlsx');
    }

    return (<div>
        {ExcelRows.length > 0 ? <div>
            <table className="text-sm text-left rtl:text-right text-midBlack w-full">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        {ExcelColumns.map((h, i) => (
                            <th scope="col" className="px-3 py-3" key={i}>
                                <div className="flex gap-2">
                                    <div> {h}</div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {ExcelRows?.map((r, i) => (
                        <tr key={i} className="bg-white border-b">
                            {ExcelColumns.map((c, j) => (
                                <td key={j} className="p-0.5">
                                    {r[c] ?? ""}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div> : <><label htmlFor="uploadpexcelfile" className="flex flex-col items-center justify-center w-full h-25 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-1 pb-3">
                <svg
                    className="w-6 h-6 text-theme"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                </svg>
                <p className="mb-1 text-sm text-gray-500">
                    <span className="hover:underline text-theme">
                        {t("Click to upload")}&nbsp;
                    </span>
                    {t("or drag and drop")}
                </p>
                <p className="text-xs text-gray-500">
                    {t("*Please Upload File in xlsx, xls, csv Format Only.")}
                </p>
            </div>
            <input
                id="uploadpexcelfile"
                type="file"
                className="hidden"
                accept=".xlsx,.xls,.csv"
                multiple={false}
                onChange={async (e) => {
                    const formData = new FormData();
                    formData.append('file', e.target.files[0]);
                    dispatch(excelUploader(formData, a => {
                        const phoneList = a.data?.filter(f => f.phone && f.phone != '');
                        setExcelRows(phoneList);
                        onUpload(a.filePath, phoneList);
                    }));
                }}
            />
        </label>
            <div className="text-center mt-3">
                <label className="text-[16px] text-theme hover:underline" onClick={DownloadSampleFormat}>Download Sample Format</label>
            </div></>}
    </div>);

}
export default SelectAudiences;